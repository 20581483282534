export function getCache(key) {
  try {
    let value = localStorage.getItem(key);
    return JSON.parse(value);
  } catch (e) {
    console.log("caught error", e);
  }
}

export function setCache(key, value) {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.log("caught error", e);
  }
}

export function rmCache(key) {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.log("caught error", e);
  }
}
