import { varCurrency } from "../modules/common/defaults";

export default function formatMoney(product) {
  const { price } = product;
  const options = {
    style: "currency",
    currency: varCurrency().code || "EGP",
    minimumFractionDigits: 2,
  };
  // if its a whole, {dollar} amount, leave off the .00
  if (price % 100 === 0) options.minimumFractionDigits = 0;
  const formatter = new Intl.NumberFormat("en", options);
  return formatter.format(price);
}
