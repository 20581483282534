import { makeVar, useReactiveVar } from "@apollo/client";
import { useEffect, useState } from "react";
import { setCache, getCache } from "../../utlis/cacheService";

const initState = {
  isLogin: false,

  user: {},
  token: "",
};

const auth = makeVar(initState);
export const varAuth = (newVal, reset) => {
  if (reset) {
    setCache("auth", initState);
    return auth(initState);
  }
  if (typeof newVal !== "undefined") {
    setCache("auth", newVal);
    return auth(newVal);
  }
  return auth();
};

export const useVarAuth = () => {
  const [data, setData] = useState(initState);
  const newData = useReactiveVar(auth);
  useEffect(() => {
    setData(newData);
  }, [newData]);
  return data;
};

export const setAuthDefaults = (client, ctx) => {
  const authCache = getCache("auth");

  auth(authCache || initState);
};
