import { Form, Button, Space, Row, Col, Typography, message as notification } from "antd";
import qs from "qs";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useResponsive } from "../../../lib/useResponsive";
import MyImage from "../../../components/common/image";
import validateImg from "../../../assets/imgs/validateimg.svg";
import { useTranslation } from "react-i18next";
import ReactCodeInput from "react-verification-code-input";
import { CURRENT_USER } from "../../../utlis/Me";
import { useEffect, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import Navbar from "../../../components/layout/navbar";
import { authMutation } from "../../../modules/auth/resolvers";
import { varDomain } from "../../../modules/common/defaults";

const { Text } = Typography;

const VALIDATE_CODE = gql`
  mutation REQUEST_RESET($email: String!, $code: String!) {
    validateCode(email: $email, type: vendor, code: $code) {
      token
      user {
        name
        id
        shopDomain
      }
    }
  }
`;

const ValidateCode = () => {
  const { xl, lg } = useResponsive();
  let history = useHistory();
  const query = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  });
  const { t } = useTranslation();
  const [validateCode, setValidateCode] = useState("");
  const trans = "auth.validate";

  const { data } = useQuery(CURRENT_USER, {
    fetchPolicy: "cache-first",
  });

  const [requestReset, { loading }] = useMutation(VALIDATE_CODE, {
    onCompleted: (tokenData) => {
      console.log("🚀 ~ file: index.tsx ~ line 46 ~ ValidateCode ~ tokenData", tokenData);
      notification.success(t(`${trans}.validateDone`));
      authMutation.asyncAuth({ token: tokenData?.validateCode?.token });
      varDomain(tokenData?.validateCode.user.shopDomain);

      history.push("/reset");
    },
  });

  useEffect(() => {
    if (data?.me) history.push("/");
    else if (!query?.email) history.push("/signin");
  }, [data]);

  const onFinish = () => {
    if (validateCode.length < 4) {
      notification.error(t(`${trans}.passwordError`));
      return;
    }

    requestReset({
      variables: {
        email: query.email,
        code: validateCode,
      },
    });
  };

  return (
    <Container>
      <div className="row-container">
        <Row gutter={[0, 23]}>
          <Col xs={{ span: 24 }}>
            <Row align="middle" gutter={xl ? [124, 30] : lg ? [30, 30] : ([0, 10] as any)}>
              <Col span={20} />
              <Col span={4}>
                <Navbar />
              </Col>
              <Col lg={{ span: 12 }} xs={{ span: 24 }} className="form">
                <div className="card-form">
                  <Form
                    name="basic"
                    labelCol={{ span: 24 }}
                    initialValues={{
                      remember: false,
                      password: "",
                      confirmPassword: "",
                    }}
                    onFinish={onFinish}>
                    <Form.Item className="text">
                      <Text className="header">{t(`${trans}.title`)}</Text>
                    </Form.Item>
                    <Form.Item className="text-sub">
                      <Text className="header">{t(`${trans}.subTitle`)}</Text>
                    </Form.Item>

                    <Form.Item
                      name="validateCode"
                      rules={[
                        {
                          required: true,
                          message: t(`${trans}.passwordError`),
                        },
                      ]}>
                      <div className="custom-styles">
                        <ReactCodeInput
                          type="number"
                          className="validation-code"
                          autoFocus
                          fields={4}
                          onChange={(val) => {
                            setValidateCode(val);
                          }}
                        />
                      </div>
                    </Form.Item>

                    <Form.Item>
                      <Button
                        htmlType="submit"
                        loading={loading}
                        disabled={loading}
                        block
                        type="primary">
                        {t(`${trans}.button`)}
                      </Button>
                    </Form.Item>
                  </Form>

                  <Space direction="vertical" size={24}>
                    <div
                      style={{
                        paddingBottom: "3.0rem",
                      }}></div>
                  </Space>
                </div>
              </Col>
              <Col lg={{ span: 12 }} xs={{ span: 0 }}>
                <MyImage width={434} src={validateImg} />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

const Container = styled.div`
  padding-top: 30px;
  background: ${(props) => props.theme.backgroundColors.lightGray};

  .form {
  }
  .ant-image {
    text-align: ${(props) => (props.theme.isBreakpoints.lg ? "initial" : "center")};
  }

  .card-form {
    background: ${(props) => props.theme.customColors.white};
    box-shadow: 0px 0px 16px rgba(51, 51, 51, 0.12);
    border-radius: 8px;
    padding: ${(props) => (props.theme.isBreakpoints.md ? "46px 40px" : "46px 24px")};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 80px;
  }

  .text {
    text-align: ${(props) => (props.theme.isBreakpoints.lg ? "center" : "initial")};
    display: block;
    margin-bottom: 16px;
    .header {
      font-weight: 700;
      font-size: ${(props) => (props.theme.isBreakpoints.md ? "24px" : "20px")};
      color: ${(props) => props.theme.colors.black3};
    }
  }
  .text-sub {
    text-align: ${(props) => (props.theme.isBreakpoints.lg ? "center" : "initial")};
    margin-top: -20px;
    .header {
      font-weight: #828282;
      font-size: ${(props) => (props.theme.isBreakpoints.md ? "16px" : "11px")};
      color: ${(props) => props.theme.colors.Gray1};
    }
  }

  .ant-input {
    height: 48px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
  }
  /* #basic_password {
	  height: 38px;
	} */
  .ant-input-password {
    padding: 0 11px;
  }
  .ant-form-item-label > label {
    font-weight: 700;
    font-size: 16px;
    color: ${(props) => props.theme.customColors.Gray2};
  }
  .ant-form-rtl .ant-form-item-label {
    text-align: right;
  }
  .ant-btn {
    height: 56px;
    font-size: 20px;
  }

  .forget {
    font-weight: 400;
    font-size: 16px;
    color: ${(props) => props.theme.colors.red};
    cursor: pointer;
  }

  .footer {
    text-align: center;
    .text {
      font-weight: 400;
      font-size: 16px;
      color: ${(props) => props.theme.colors.Gray2};
    }
    .ant-btn {
      border: 1px solid ${(props) => props.theme.customColors.primary};
      color: ${(props) => props.theme.customColors.primary};
      font-weight: 500;
      border-radius: 4px;
      max-width: 375px;
    }
    .ant-btn:hover {
      background: ${(props) => props.theme.customColors.primary};
      color: ${(props) => props.theme.customColors.white};
    }
  }

  .custom-styles {
    --ReactInputVerificationCode-itemWidth: 2.5rem;
    --ReactInputVerificationCode-itemHeight: 3.5rem;
    width: 100%;
    margin: 1.5rem 0px;
    direction: ltr !important;

    .validation-code {
      width: 100% !important;
      display: flex;
      justify-content: space-around;
      align-items: center;
      input {
        margin: 0px 5px;
        border: 2px solid ${({ theme }) => theme.colors.Gray1};
        border-radius: 4px;
      }
    }
  }
`;

export default ValidateCode;
