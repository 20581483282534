import React, { useState } from "react";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import styled from "styled-components";
import { uploadImage } from "../../utlis/upload";

const convertHtmlToDraft = (content) => {
  const blocksFromHtml = htmlToDraft(content);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  return contentState;
};

const convertDraftToHtml = (editorState) => {
  return draftToHtml(convertToRaw(editorState.getCurrentContent()));
};

const DraftJsHtmlEditor = ({ content, update, ...props }) => {
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(convertHtmlToDraft(content || "")),
  );
  const onEditorStateChange = (estate) => {
    setEditorState(estate);
  };

  const handleUpdate = () => {
    const htmlContent = convertDraftToHtml(editorState);
    if (!htmlContent) {
      return;
    }
    update(htmlContent);
  };

  const handleUploadImage = async (file, callback) => {
    const imgRes = await uploadImage(file);

    return new Promise((resolve, reject) => {
      resolve({ data: { link: imgRes.data.original } });
      if (imgRes && imgRes.data && imgRes.data.original) {
        resolve({ data: { link: imgRes.data.original } });
      } else {
        reject();
      }
    });
  };

  const toolbar = {
    textAlign: { inDropdown: true },
    image: {
      uploadCallback: handleUploadImage,
      previewImage: true,
      alt: { present: true, mandatory: false },
      inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
      defaultSize: { width: "100%" },
    },
  };

  return (
    <Container className="draft_html_editor" dir="ltr">
      <Editor
        editorState={editorState}
        // defaultContentState={htmlToDraft("<p>Hello Worled</p>")}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={onEditorStateChange}
        onBlur={handleUpdate}
        toolbar={toolbar}
        {...props}
      />
    </Container>
  );
};

const Container = styled.div`
  .rdw-editor-wrapper {
    height: inherit;
    min-height: 300px;
    max-height: 1000px;
    overflow: auto;
    border: 1px solid #f4f4f4;
  }
  .demo-editor.rdw-editor-main {
    padding-inline: 0.75rem;
  }
`;

export default DraftJsHtmlEditor;
