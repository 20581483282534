import React, { useState, useEffect, useMemo } from "react";

import {
  PlusCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons";

import { useMutation, gql } from "@apollo/client";

import { Button, Input, Form, Collapse, message, Upload, Space, Popconfirm, Select } from "antd";
import JoditEditor from "../../../../components/common/joditEditor";
import { uploadImage } from "../../../../utlis/upload";
import { SLIDES } from "../table";
import GetAction, { slideActions } from "../../../../components/common/getAction";
import { useTranslation } from "react-i18next";
const { Panel } = Collapse;
const { Option } = Select;
export const EDIT_SLIDE = gql`
  mutation EDIT_SLIDE(
    $id: Int!
    $href: String
    $text: MultiLangText
    $image: MultiLangImage
    $category: Int
    $action: SlideActions
  ) {
    updateSlide(
      href: $href
      id: $id
      text: $text
      image: $image
      category: $category
      action: $action
    ) {
      id
      text {
        id
        ar
        en
      }
      action
      category {
        id
        name {
          id
          auto
        }
      }
      image {
        id
        ar {
          id
          sm
        }
        en {
          id
          sm
        }
      }
      href
    }
  }
`;

export const ADD_Slide = gql`
  mutation ADD_Slide(
    $type: String! = "mainSlider"
    $href: String
    $text: MultiLangText
    $image: MultiLangImage!
    $category: Int
    $action: SlideActions
  ) {
    addSlide(
      type: $type
      href: $href
      text: $text
      image: $image
      category: $category
      action: $action
    ) {
      id
      text {
        id
        ar
        en
      }
      action
      category {
        id
        name {
          id
          auto
        }
      }
      image {
        id
        ar {
          id
          sm
        }
        en {
          id
          sm
        }
      }

      href
    }
  }
`;
export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export const uploadButton = (loading, t, mediaType) => (
  <div style={{ color: "#4A94A5" }}>
    {loading ? <LoadingOutlined /> : <CloudUploadOutlined style={{ fontSize: 25 }} />}
    <div className="ant-upload-text">
      {mediaType == "VIDEO" ? t("products.addVideo") : t("products.upload")}
      {/* {i18n == " en" ? "uplaod" : "أضف صورة المنتج"} */}
    </div>
  </div>
);

const AddEditCollapse = ({ editableRecord, seteditableRecord, isEn }) => {
  const [images, setImages] = useState(null);
  const [loading, setLoading] = useState(false);
  const [enImageUrl, setEnIamgeUrl] = useState(null);
  const [arImageUrl, setArIamgeUrl] = useState(null);
  const [action, setAction] = useState(slideActions[0]);
  const { t, i18n } = useTranslation();

  const validateMessages = {
    required: t("customers.required"),
  };

  const [text, setText] = useState("");
  const [textAr, setTextAr] = useState("");

  const [form] = Form.useForm();
  const [editSlide, editSlideProps] = useMutation(EDIT_SLIDE, {
    update(cache, { data: { updateSlide } }) {
      const { slides } = cache.readQuery({
        query: SLIDES,
      });
      cache.writeQuery({
        query: SLIDES,
        data: {
          slides: {
            count: slides.count,
            nodes: slides.nodes.map((slide) => (slide.id === updateSlide.id ? updateSlide : slide)),
          },
        },
      });
    },
  });
  const [addSlide, addSlideProps] = useMutation(ADD_Slide, {
    update(cache, { data: { addSlide } }) {
      const { slides } = cache.readQuery({
        query: SLIDES,
      });
      cache.writeQuery({
        query: SLIDES,
        data: {
          slides: {
            count: slides.count + 1,
            nodes: slides.nodes.concat([addSlide]),
          },
        },
      });
    },
  });

  const emptyFields = () => {
    seteditableRecord(null);
    setText("");
    setTextAr("");
    setArIamgeUrl("");
    setEnIamgeUrl("");
    setAction(slideActions[0]);

    form.resetFields();
  };

  useEffect(() => {
    if (editableRecord) {
      setText(editableRecord.text.en);
      setTextAr(editableRecord.text.ar);
      setArIamgeUrl(editableRecord.image.ar?.sm);
      setEnIamgeUrl(editableRecord.image.en?.sm);
      setAction(editableRecord.action);

      form.setFieldsValue({
        href: editableRecord.href,
        action: editableRecord.action,
        category: editableRecord.category?.id
          ? {
              value: editableRecord.category?.id,
              key: editableRecord.category?.id,
              label: editableRecord.category?.name.auto,
            }
          : undefined,
      });
    }
  }, [editableRecord]);

  const handleImage = async ({ fileList }, lang) => {
    const last = fileList.length - 1;
    if (!fileList[0].url && !fileList[last].preview) {
      fileList[last].preview = await getBase64(fileList[last].originFileObj);
    }
    lang === "ar"
      ? setArIamgeUrl(fileList[last].url || fileList[last].preview)
      : setEnIamgeUrl(fileList[last].url || fileList[last].preview);

    // if (info.file.status === "uploading") {
    //   setLoading(true);
    //   return;
    // }
    // if (info.file.status === "done") {
    //   // Get this url from response in real world.
    //   getBase64(info.file.originFileObj, (imageUrl) => {
    //     lang === "ar" ? setArIamgeUrl(imageUrl) : setEnIamgeUrl(imageUrl);
    //     setLoading(false);
    //   });
    // }
  };

  const MemoizedEmailEditor = useMemo(
    () => <JoditEditor style={{ height: 500 }} content={text} update={setText} />,
    [text],
  );
  const MemoizedEmailEditorAr = useMemo(
    () => <JoditEditor style={{ height: 500 }} content={textAr} update={setTextAr} />,
    [textAr],
  );
  const onFinish = async (variables) => {
    setLoading(true);

    const arImage = images?.ar?.file ? await uploadImage(images?.ar?.file) : null;
    const enImage = images?.en?.file ? await uploadImage(images?.en?.file) : null;
    if (!enImage && !arImage && !editableRecord) {
      setLoading(false);

      return message.error(t("products.mustAddImage"));
    }
    console.log("onFinish -> enImage", enImage);
    console.log("onFinish -> arImage", arImage);
    editableRecord
      ? await editSlide({
          variables: {
            ...variables,
            id: editableRecord.id,
            category: variables.category?.value || variables.category,

            text: {
              en: text,
              ar: textAr,
            },
            image: (arImage?.data || enImage?.data) && {
              ar: arImage?.data,
              en: enImage?.data,
            },
          },
        })
      : await addSlide({
          variables: {
            ...variables,
            category: variables.category?.value || variables.category,

            text: {
              en: text,
              ar: textAr,
            },
            image: {
              ar: arImage?.data || enImage?.data,
              en: enImage?.data || arImage?.data,
            },
          },
        });
    emptyFields();
    setLoading(false);
  };

  return (
    <Collapse defaultActiveKey={["1", "2", "3"]}>
      <Panel
        header={editableRecord ? t("products.editSlide") : t("products.addSlide")}
        key="1"

        // extra={editableRecord&& <Popconfirm
        //   title="Are you sure delete this slide?"
        //   onConfirm={() =>
        //     removeSlide({ variables: { where: { id: editableRecord .id } } })
        //   }
        //   okText="Yes"
        //   cancelText="No"
        // >
        //   <DeleteOutlined style={{ color: "red" }} />
        // </Popconfirm>}
      >
        <Form
          validateMessages={validateMessages}
          name="addSlide"
          form={form}
          onFinish={onFinish}
          initialValues={{ action: slideActions[0] }}
          labelCol={{ span: 24 }}
          labelAlign="left"
          onValuesChange={(val) => {
            console.log("val", val);
            val.action && setAction(val.action);
          }}>
          {isEn ? (
            <>
              <Form.Item
                name={["image", "en"]}
                // valuePropName="fileList"
                // getValueFromEvent={normFile}
                // label="Image"
                // rules={[{ required: true }]}
                noStyle>
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={() => {
                    // handleImage(file, "en");
                    return false;
                  }}
                  onChange={(file) => {
                    setImages({ ...images, en: file });

                    handleImage(file, "en");
                  }}

                  // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                >
                  {enImageUrl ? (
                    <img src={enImageUrl} alt="avatar" style={{ width: "100%" }} />
                  ) : (
                    uploadButton(loading, t)
                  )}
                </Upload>
              </Form.Item>

              <Form.Item label="text">{MemoizedEmailEditor}</Form.Item>
            </>
          ) : (
            <>
              <Form.Item
                name={["image", "ar"]}
                // valuePropName="fileList"
                // getValueFromEvent={normFile}
                noStyle>
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={() => {
                    // handleImage(file, "ar");
                    return false;
                  }}
                  onChange={(file) => {
                    setImages({ ...images, ar: file });

                    handleImage(file, "ar");
                  }}

                  // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                >
                  {arImageUrl ? (
                    <img src={arImageUrl} alt="avatar" style={{ width: "100%" }} />
                  ) : (
                    uploadButton(loading, t)
                  )}
                </Upload>
              </Form.Item>

              <Form.Item label="الوصف">{MemoizedEmailEditorAr}</Form.Item>
            </>
          )}
          <GetAction action={action} setAction={setAction} />
          <Space>
            <Button
              htmlType="submit"
              type="primary"
              loading={addSlideProps.loading || loading || editSlideProps.loading}
              disabled={addSlideProps.loading || loading || editSlideProps.loading}
              icon={editableRecord ? <EditOutlined /> : <PlusCircleOutlined />}>
              {editableRecord ? t("products.editSlide") : t("products.addSlide")}
            </Button>
            {editableRecord && (
              <Button onClick={emptyFields} type="link">
                {t("products.cancel")}
              </Button>
            )}
          </Space>
        </Form>
      </Panel>
    </Collapse>
  );
};

export default AddEditCollapse;
