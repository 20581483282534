export function GetNewPageData(pagination, setPagination) {
  if (!pagination || pagination.total === 0) return;

  const oldPagesCount = Math.ceil((pagination.total + 1 || 1) / 10);

  const newPagesCount = Math.ceil((pagination.total - 1 || 1) / 10);

  if (oldPagesCount > newPagesCount) {
    setPagination({ ...pagination, current: newPagesCount });
  }
}
