import { Input } from "antd";
import styled from "styled-components";
const { Search } = Input;
const CustomSearch = styled(Search)`
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(51, 51, 51, 0.12);
  border-radius: 6px;
  .ant-input-search-button {
    height: 40px;
    border-radius: 4px 0 0 4px !important;
    border-right: ${(props) => (props.theme.i18n.language == "ar" ? "0" : "auto")};
    border-left: ${(props) => (props.theme.i18n.language == "en" ? "0" : "auto")};
  }

  .ant-input-affix-wrapper, .ant-btn {
    border: 0;
  }

  .ant-input-search-button:active,
  .ant-input-search-button:focus {
    outline: none;
    border-color: #d9d9d9;
  }

  .ant-input {
    background: white;
    border-radius: 4px;
    width: 100% !important;
    outline: none;
    border-left: ${(props) => (props.theme.i18n.language == "ar" ? "0" : "auto")};
    border-right: ${(props) => (props.theme.i18n.language == "en" ? "0" : "auto")};
  }

  .ant-input:focus,
  .ant-input:active {
    outline: none;
  }

  .ant-select-selection-item {
    color: ${(props) => props.theme.colors.Gray2};
    text-align: center !important;
    display: flex;
    justify-content: center;
  }

  .ant-input-search .ant-input:hover,
  .ant-input-search .ant-input:focus {
    border-color: #e0e0e0;
    box-shadow: none;
  }
  .ant-input-group-addon {
    background: transparent;
    .anticon-search {
      color: #1985A1;
    }
  }
  .ant-input-search
    .ant-input:focus
    + .ant-input-group-addon
    .ant-input-search-button:not(.ant-btn-primary) {
    border-color: #e0e0e0;
  }
`;
export default CustomSearch;