import styled from "styled-components";
import { ChatItem } from "react-chat-elements";
import { Typography, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { Room as RoomType } from "../../../../types";
import { truncate, withToolTip } from "../../../../lib/helper";
import { useParams } from "react-router-dom";
const { Text } = Typography;

interface Props {
  room: RoomType;
  setCurrentRoom: (room: RoomType) => void;
  currentRoom?: RoomType;
  showMessage?: () => void;
}

const Room = ({
  room,
  setCurrentRoom,
  // currentRoom,
  showMessage,
}: Props) => {
  const { notSeenNum, slug, roomName, messages } = room;
  const lastMessage = room.lastMessage ||
    (messages && messages[messages.length]) || { message: " ", createdAt: new Date() };

  const { t } = useTranslation();
  //@ts-ignore
  const { roomSlug } = useParams();
  return (
    <Container
      selected={roomSlug === slug}
      onClick={() => {
        setCurrentRoom(room);
        showMessage();
      }}>
      <ChatItem
        title={
          <Text className="user-name">
            {
              // lastMessage.user?.name
              roomName || t("chat.userName")
            }
          </Text>
        }
        subtitle={
          <>
            {withToolTip(
              lastMessage?.message,
              <Text className="user-message"> {truncate(lastMessage?.message, 15)}</Text>,
              15,
            )}
          </>
        }
        date={new Date(lastMessage?.createdAt) || ""}
        unread={notSeenNum || 0}
      />
    </Container>
  );
};

const Container = styled.div<{ selected: boolean }>`
  .rce-citem {
    height: 88px;
  }
  .rce-container-citem {
    padding: 0 16px;
  }

  .rce-container-citem,
  .rce-citem-body {
    background-color: ${({ selected, theme }) => (selected ? theme.customColors.primary : "white")};
  }

  .user-name,
  .user-message {
    color: ${({ selected, theme }) => (selected ? "white" : "black")};
  }

  .rce-citem-avatar {
    display: none;
  }
  .rce-citem-body {
    border-bottom: 1px solid ${(props) => props.theme.borderColors.gray};
  }
  .rce-citem-body--top {
    align-items: center;
    gap: 10px;
  }
  .rce-citem-body--top-title {
    flex: initial;
  }
  .rce-citem-body--bottom-status span {
    width: fit-content;
    min-width: 18px;
    padding: 10px 2px;
  }
  .rce-citem-body--top-time {
    color: ${({ selected, theme }) => (selected ? "white" : "black")};
  }
`;

export default Room;
