import moment from "moment";

export const typeRender = (type) => {
  switch (type) {
    case "PLAN":
      return "تجديد الاشتراك";
    case "SERVICE":
      return "شراء خدمة";
    case "ADD_TO_BALANCE":
      return "إضافة رصيد";
    case "DUE_AMOUNT":
      return "سحب المبالغ المستحقة";
    default:
      break;
  }
};

export const getDiffranceDate = (start, end) => {
  var startDate = moment(start, "YYYY-MM-DD");
  var endDate = moment(end, "YYYY-MM-DD");
  return moment.duration(endDate.diff(startDate)).asDays();
};
