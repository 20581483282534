import { CURRENT_CURRENCY } from "../../../../modules/common/queries";
import React, { useState, useMemo, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Upload,
  Button,
  InputNumber,
  Divider,
  Row,
  Col,
  Checkbox,
  Switch,
  Spin,
  notification,
  Space,
  Typography,
} from "antd";
import { useMutation, useQuery, gql } from "@apollo/client";
import {
  InboxOutlined,
  PlusOutlined,
  PlusCircleOutlined,
  ClockCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { FormCard } from "./style";
import JoditEditor from "../../../../components/common/joditEditor";
import VariantCard from "./cards/variants";
import { PRODUCTS } from "..";
import Organization from "./cards/organization";
import Media from "./cards/media";
import axios from "axios";
import makeSlug from "slug-arabic"; // by B7r :)
import { uploadGallery } from "../../../../utlis/upload";
import { validateMessages } from "./add";
import { useHistory, useParams } from "react-router-dom";
import TitleCard from "./cards/titleCard";
import QuantityCard from "./cards/quantityCard";
import PriceCard from "./cards/priceCard";
import EditVariantCard from "./cards/variantsEdit";
import ShippingCard from "./cards/shippingCard";
import ChooseImg from "./cards/chooseImage";
import { useTranslation } from "react-i18next";
import SuccessNotification from "../../../../components/common/successNotification";
import errorNotification from "../../../../components/common/errorNotification";
import SeoCard from "../../../../components/common/seoCard";
import CommonError from "../../../error";
import { CURRENT_USER } from "../../../../utlis/Me";
import { PreviewButton } from "./PreviewButton";
import { SETTINGS } from "../../../settings/generals/card/defaultLanguage";
import MetaTags from "../../../posts/addEdit/metaTags";
import HasFeature from "../../../../components/hasFuture";
import FeaturesNames from "../../../../enums/featuresNames";
import Schedular from "./cards/schedular";
import EditThemeProduct from "./cards/editThemeProduct";
import PublishSwitchComponent from "./cards/publishSwitch";
import ProductSettingSubMenu from "./cards/productSettingMenu";
import AddEditStickyContainer from "../../../../components/layout/header/addEditSticky";
import { convirtToVideoIfImg, isVideo } from "../../../../components/common/image";
import BackButton from "../../../../components/common/backBtn";
import ProductisDigitalCard from "./cards/isDigitalCard";

// const CREATE_PRODUCT_FACEBOOK = gql`
//   mutation CREATE_PRODUCT_FACEBOOK($id: Int!) {
//     createProductFacebook(id: $id) {
//       id
//     }
//   }
// `;
const EDIT_PRODUCT = gql`
  mutation EDIT_PRODUCT(
    $id: Int!
    $title: MultiLangText
    # $slug: MultiLangText
    $price: Float
    $realPrice: Float
    $comparingPrice: Float
    # $quantities: [quantitiesInput!]
    $barcode: String
    $publishAt: DateTime
    $SKU: String
    $description: MultiLangText
    $trackQuantity: Boolean
    $editVariants: [CustomVariantUpdateWithoutProductInput!]
    $categories: [CategoryWhereUniqueInput!]
    $tags: [String!]
    # $type: ProductTypeWhereUniqueInput
    $brand: BrandWhereUniqueInput
    $doContinueOutOfStock: Boolean # $gallery: [ImageInput!] # $options: [OptionsInput!]
    $isPhysical: Boolean
    $published: Boolean
    $shipping: ShippingInput
    $mainImage: Int
    $seo: SeoItemArg
    $digitalLink: String
  ) {
    updateProduct(
      id: $id
      title: $title
      mainImage: $mainImage
      seo: $seo
      # quantities: $quantities
      price: $price
      realPrice: $realPrice
      comparingPrice: $comparingPrice
      description: $description
      trackQuantity: $trackQuantity
      isPhysical: $isPhysical
      shipping: $shipping
      publishAt: $publishAt
      published: $published

      SKU: $SKU
      # gallery: $gallery
      # slug: $slug
      barcode: $barcode
      editVariants: $editVariants
      tags: $tags
      # type: $type
      brand: $brand
      categories: $categories
      doContinueOutOfStock: $doContinueOutOfStock # options: $options
      digitalLink: $digitalLink
    ) {
      id
    }
  }
`;

export const SINGLE_PRODUCT = gql`
  query SINGLE_PRODUCT($id: Int!) {
    product(where: { id: { equals: $id } }) {
      id
      theme {
        id
      }
      title {
        id
        ar
        en
        auto
      }
      ratingCount
      ratingsAvg
      slug
      doContinueOutOfStock
      trackQuantity
      quantities {
        locationId
        quantity
      }
      holdedQuantity
      digitalLink
      # quantityInLocations {
      #   id
      #   quantity
      #   location {
      #     id
      #   }
      # }
      seo {
        id
        title {
          id
          ar
          en
        }
        slug {
          id
          ar
          en
        }
        body {
          id
          ar
          en
        }
      }
      price
      realPrice
      comparingPrice
      hasVariants
      publishAt
      published
      productType
      parentId
      isPhysical
      mainImage {
        id
        md
      }
      shipping {
        id
        weight
        length
        height
        width
        weightUnit
        distanceUnit
      }
      description {
        id
        ar
        en
      }
      SKU
      gallery {
        id
        cloudId
        md
        xl
      }
      # slug {
      #   id
      #   ar
      #   en
      # }
      barcode
      variants {
        id
        price
        # quantityInLocations {
        #   id
        #   quantity
        #   location {
        #     id
        #   }
        # }
        selectedOptions {
          id
          option: attribute {
            id
            name
          }
          optionValue {
            id
            name
          }
        }
      }
      tags {
        id
        name
      }
      # type {
      #   id
      #   name
      # }
      brand {
        id
        name
      }
      categories {
        id
        name {
          id
          auto
        }
      }

      options {
        id
        option: attribute {
          id
          name
        }
        values {
          id
          name
        }
      }
    }
  }
`;

const ProductLoading = (
  <Spin
    style={{
      position: "absolute",

      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    }}
  />
);
/**
 * renderComponent
 * @component
 * @name addCompanyModal
 * @memberof PRODUCTS
 * @summary admin can add company
 * @return {React} React component view
 */

const EditProduct = () => {
  let { id } = useParams();
  const maxId = 2147483647;
  const parsedId = parseInt(id);
  const { data: meData, loading: loadingMe } = useQuery(CURRENT_USER, {
    fetchPolicy: "cache-first",
  });
  const domain = meData.me.currentShop?.domain;
  console.log("🚀 ~ file: edit.js ~ line 278 ~ EditProduct ~ domain", domain);
  if (parsedId > maxId) id = maxId;

  const { t } = useTranslation();

  const [editProduct, productProps] = useMutation(EDIT_PRODUCT, {
    // awaitRefetchQueries: true,
    // refetchQueries: [
    //   {
    //     query: SINGLE_PRODUCT,
    //     variables: { id: Number.isInteger(parsedId) && parsedId <= maxId ? parsedId : maxId },
    //   },
    // ],
  });
  // const [createProductFacebook] = useMutation(CREATE_PRODUCT_FACEBOOK);
  console.log("editProduct -> id", id);
  const { data, ...singleProduct } = useQuery(SINGLE_PRODUCT, {
    variables: {
      id: Number.isInteger(parsedId) && parsedId <= maxId ? parsedId : maxId,
    },
  });
  console.log("editProduct -> data", data);
  const { push, replace } = useHistory();

  // const { data, loading, error } = useQuery(STATES);
  const [profit, setProfit] = useState(null);
  const [moment, setMoment] = useState(undefined);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [profitMargin, setProfitMargin] = useState(null);
  const [trackQuantity, setTrackQuantity] = useState([]);
  const [hasVariants, setHasVariants] = useState([]);
  const [options, setOptions] = useState([]);
  const [images, setImages] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEn, setIsEn] = useState();
  // const [slug, setSlug] = useState({ ar: "", en: "" });
  const [isPhysical, setIsPhysical] = useState(false);
  const [digital, setDigital] = useState(false);
  const [publishedAtState, setPublishedAt] = useState(null);
  const [variants, setVariants] = useState([]);
  const [seoTitle, setSeoTitle] = useState({});
  const [description, setDescription] = useState("");
  const [descriptionAr, setDescriptionAr] = useState("");
  const [mainImage, setMainImage] = useState(null);
  console.log("mainImage", mainImage);
  const { data: currencyData, loading: loadingCurrency } = useQuery(CURRENT_CURRENCY, {
    fetchPolicy: "cache-first",
  });

  const { data: defaultLanguage, loading: defaultLanguageLoading } = useQuery(SETTINGS);

  const [form] = Form.useForm();

  /**
   * @description admin can add new company from this modal
   */

  const onFinish = async (variables) => {
    console.log("🚀 ~ file: edit.js ~ line 364 ~ onFinish ~ variables", variables);
    const {
      variants,
      // type,
      brand,
      categories,
      shipping,
      // quantities,
    } = variables;
    console.log("onFinish -> variables", variables);
    // setLoading(true);
    const getShipping = () => {
      if (shipping) {
        const { weight, length, height, width } = shipping;
        return {
          ...shipping,
          weight: Number(weight),
          length: Number(length),
          height: Number(height),
          width: Number(width),
        };
      }
    };
    try {
      await editProduct({
        variables: {
          ...variables,
          // quantities:
          //   quantities &&
          //   Object.keys(quantities)
          //     .filter((location) => quantities[location])
          //     .map((location) => ({
          //       quantity: quantities[location],
          //       location: Number(location) || undefined,
          //     })),
          mainImage: mainImage?.uid,
          shipping: getShipping(),
          id: parseInt(id),
          // publishAt: moment,
          title: form.getFieldValue("title"),
          // slug: form.getFieldValue("slug"),
          trackQuantity: variables.trackQuantity?.includes("trackQuantity"),

          // options: variables.options
          //   ?.map((opt) =>
          //     opt?.label && opt?.value.length > 0
          //       ? { id: opt.label, values: opt.value }
          //       : null
          //   )
          //   .filter((opt) => opt),
          description: {
            en: description,
            ar: descriptionAr,
          },
          editVariants:
            variants &&
            Object.keys(variants).map((id) => {
              console.log("onFinish -> variants[id].selectedOptions", variants[id].selectedOptions);
              return {
                id: parseInt(id),
                ...variants[id],
                // quantities: Object.keys(variants[id].quantities)
                //   .filter((location) => variants[id].quantities[location])
                //   .map((location) => ({
                //     quantity: variants[id].quantities[location],
                //     location: Number(location) || undefined,
                //   })),
                selectedOptions: Object.keys(variants[id].selectedOptions).map((option) => ({
                  id: parseInt(option),
                  value: variants[id].selectedOptions[option].value,
                })),
              };
            }),
          brand: brand && { id: brand },
          // type: type && { id: type },
          categories: categories?.map(({ value }) => ({ id: value })),

          doContinueOutOfStock: variables.trackQuantity?.includes("outOfStock"),
          digitalLink: digital ? variables.digitalLink : null,
          // gallery: finalGallery,
        },
        // refetchQueries: [{ query: PRODUCTS, variables: { filter: {} } }],
      });
      SuccessNotification({
        defaultTitle: t("products.successDefaultMessage"),
        title: t("products.successEditProductMessage"),
        type: "success",
      });
    } catch (error) {
      // errorNotification({
      //   defaultTitle: t("products.errorDefaultMessage"),
      //   title: t("products.errorProductMessage"),
      //   type: "error",
      // });

      console.log("editProduct -> error", error);
      // setLoading(false);
    }
    // setLoading(false);
  };

  const validateMessages = {
    required: t("customers.required"),
  };

  useEffect(() => {
    console.log("🚀 ~ file: edit.js ~ line 362 ~ useEffect ~ data?.product", data?.product);
    if (data?.product) {
      const {
        description,
        realPrice,
        price,
        gallery,
        publishAt,
        doContinueOutOfStock,
        productType,
        parentId,
        title,
        digitalLink,
        ...rest
        // trackQuantity,
      } = data?.product;
      if (digitalLink) setDigital(true);
      console.log("🚀 ~ file: edit.js ~ line 351 ~ useEffect ~ parentId", parentId);
      console.log("🚀 ~ file: edit.js ~ line 351 ~ useEffect ~ isVariant", productType);
      if (productType == "VARIANT") return replace(`/product/${parentId}/variants/${id}`);
      setImages(gallery);
      // setMoment(new Date(publishAt));
      setDescription(description.en);
      setDescriptionAr(description.ar);
      setSeoTitle(title);
      setProfit(price - realPrice);
      setIsPhysical(rest.isPhysical);
      setMainImage({
        uid: rest.mainImage?.id,
        url: rest.mainImage?.md,
      });
      // const arSlugWithoutIdArray = rest.slug.ar.split("-");

      // arSlugWithoutIdArray.pop();
      // const enSlugWithoutIdArray = rest.slug.en.split("-");

      // enSlugWithoutIdArray.pop();

      // setSlug({
      //   ar: arSlugWithoutIdArray.join("-"),
      //   en: enSlugWithoutIdArray.join("-"),
      // });
      // form.setFieldsValue({
      //   slug: {
      //     ar: arSlugWithoutIdArray.join("-"),
      //     en: enSlugWithoutIdArray.join("-"),
      //   },
      // });

      // setSlug({ ar: rest.slug?.ar, en: rest.slug?.en });
      // form.setFieldsValue({
      //   slug: { ar: rest.slug?.ar, en: rest.slug?.en },
      // });
      setProfitMargin(((price - realPrice) / price) * 100);
      rest.variants && setVariants(rest.variants);
      let track = [];
      console.log(
        "🚀 ~ file: edit.js ~ line 480 ~ useEffect ~ !track.includ",
        !track.includes("trackQuantity"),
      );

      (rest.trackQuantity || trackQuantity.includes("trackQuantity")) &&
        track.push("trackQuantity");
      (doContinueOutOfStock || trackQuantity.includes("outOfStock")) && track.push("outOfStock");
      console.log("track", track);

      setTrackQuantity(track);
      // setMoment(publishAt);
    }
    // form.setFieldsValue(data?.product);
  }, [data]);

  if (singleProduct.loading || defaultLanguageLoading) return ProductLoading;

  const isLoading = (loading && !data.product?.id) || loadingCurrency;
  // const isLoading = (loading && !data.product?.id) || productProps.loading || loadingCurrency;

  if (isLoading) return ProductLoading;
  else if (!data.product) return <CommonError />;
  console.log("🚀 ~ file: edit.js ~ line 480 ~ EditProduct ~ data.product", data.product);
  const {
    title,
    gallery,
    quantities,
    price,
    realPrice,
    comparingPrice,
    SKU,
    barcode,
    doContinueOutOfStock,
    seo,
    published,
    ...rest
  } = data.product;
  console.log("🚀 ~ file: edit.js ~ line 559 ~ EditProduct ~ data", data);
  console.log("🚀 ~ file: edit.js ~ line 549 ~ EditProduct ~ published", published);
  let track = [];
  rest.trackQuantity && track.push("trackQuantity");
  doContinueOutOfStock && track.push("outOfStock");

  const makeEditVariants = () => {
    const variants = {};

    rest.variants.map(
      ({
        id,
        selectedOptions,
        price,
        // quantityInLocations
      }) => {
        variants[id] = {
          ...variants[id],
          price,
          // quantities: quantityInLocations,
          // quantities: quantityInLocations.reduce(
          //   (prev, { quantity, location }) => {
          //     prev[location?.id || "0"] = quantity;
          //     return prev;
          //   },
          //   []
          // ),
        };

        selectedOptions.map(
          ({ optionValue, ...option }) =>
            (variants[id] = {
              ...variants[id],
              selectedOptions: {
                ...variants[id].selectedOptions,
                [option.id]: {
                  value: optionValue?.id,
                  label: optionValue?.name,
                  key: optionValue?.id,
                },
              },
            }),
        );
      },
    );

    console.log("makeEditVariants -> variants", variants);
    return variants;
  };
  console.log("quantities", quantities);
  return (
    <>
      <Form
        validateMessages={validateMessages}
        name="editProduct"
        form={form}
        className="login-form"
        onFinish={onFinish}
        initialValues={{
          title: { ar: title.ar, en: title.en },
          gallery: gallery.map(({ id, md }) => ({ uid: id, url: convirtToVideoIfImg(md) })),
          // slug: { ar: rest.slug.ar, en: rest.slug.en },
          quantities: quantities,
          seo: {
            title: { ar: seo?.title?.ar, en: seo?.title?.en },
            slug: { ar: seo?.slug?.ar, en: seo?.slug?.en },
            body: { ar: seo?.body?.ar, en: seo?.body?.en },
          },
          // quantities: quantityInLocations.reduce(
          //   (prev, { quantity, location }) => {
          //     prev[location?.id || "0"] = quantity;
          //     return prev;
          //   },
          //   []
          // ),
          // seo: rest.seo,
          published,
          price,
          realPrice,
          comparingPrice,
          SKU,
          barcode,
          trackQuantity: track,
          // type: rest.type?.id,
          brand: rest.brand?.id,
          categories: rest.categories?.map(({ id, ...rest }) => ({
            value: id,
            id,
            key: id,
            label: rest.name.auto,
            ...rest,
          })),
          tags: rest.tags?.map(({ name }) => name),
          variants: makeEditVariants(),
          shipping: rest.shipping || {
            weight: 0,
            length: 0,
            height: 0,
            width: 0,

            weightUnit: "kg",
            distanceUnit: "m",
          },
          isPhysical: rest.isPhysical,
          digitalLink: rest.digitalLink,

          // hasVariants: rest.hasVariants ? ["hasVariants"] : [],
          // options: rest.options.map(({ option, values }) => ({
          //   label: option.id,
          //   value: values.map(({ name }) => name),
          // })),
        }}
        labelCol={{ span: 24 }}
        labelAlign="left"
        onValuesChange={(val) => {
          console.log("editProduct -> val", val);
          console.log("editProduct -> valuess", form.getFieldsValue());
          val.title && setSeoTitle({ ...seoTitle, ...val.title });
          setImages(form.getFieldValue("gallery"));
          // val.title?.en &&
          //   form.setFieldsValue({
          //     slug: {
          //       ...form.getFieldValue("slug"),
          //       en: makeSlug(val?.title?.en),
          //     },
          //   });
          // val.title?.ar &&
          //   form.setFieldsValue({
          //     slug: {
          //       ...form.getFieldValue("slug"),
          //       ar: makeSlug(val?.title?.ar),
          //     },
          //   });
          // val.title && setSlug({ ...slug, ...val.title });

          // val.slug && setSlug({ ...slug, ...val.slug });
          // form.setFieldsValue({
          //   slug: {
          //     ...form.getFieldValue("slug"),
          //     en: makeSlug(val?.slug?.en),
          //   },
          // });
          // val.slug?.ar &&
          // form.setFieldsValue({
          //   slug: {
          //     ...form.getFieldValue("slug"),
          //     ar: makeSlug(val?.slug?.ar),
          //   },
          // });
          setIsPhysical(form.getFieldValue("isPhysical"));

          setOptions(form.getFieldValue("options"));
          setTrackQuantity(form.getFieldValue("trackQuantity"));
          setHasVariants(form.getFieldValue("hasVariants"));

          if (form.getFieldValue("price") && form.getFieldValue("realPrice")) {
            setProfit(form.getFieldValue("price") - form.getFieldValue("realPrice"));
            setProfitMargin(
              ((form.getFieldValue("price") - form.getFieldValue("realPrice")) /
                form.getFieldValue("price")) *
                100,
            );
          } else {
            setProfit(null);
            setProfitMargin(null);
          }
        }}>
        <AddEditStickyContainer
          children={
            <Row justify="space-between">
              <Col>
                <BackButton text={t("products.products")} url={"/products"} />
                <h2 style={{ marginLeft: "20px", fontWeight: "bold" }}>
                  {t("products.editProduct")}
                </h2>
              </Col>
              <Col>
                <div>
                  <PreviewButton url={`/product/${data?.product?.slug}`} />
                  {/* <Button type="text">
                {" "}
                <a
                  href={`https://${domain}${`/product/${data?.product?.id}`}`}
                  style={{ color: "#163663" }}
                  target="_blank"
                  rel="noreferrer">
                  {t("products.preview")} <EyeOutlined />
                </a>
              </Button> */}
                  <Button type="text" onClick={() => setIsModalVisible(true)}>
                    {t("products.schedular")}
                    <ClockCircleOutlined />
                  </Button>
                  <Button
                    type="primary"
                    loading={isLoading || productProps.loading}
                    disabled={isLoading || productProps.loading}
                    // icon={<PlusCircleOutlined />}
                    htmlType="submit">
                    {t("settings.save")}
                  </Button>
                  {isModalVisible && (
                    <Schedular
                      defaultMomentValue={data?.product?.publishAt}
                      setMoment={setMoment}
                      isEdit={true}
                      currrentMoment={moment}
                      setIsModalVisible={setIsModalVisible}
                      isModalVisible={isModalVisible}
                      page="product"
                    />
                  )}
                </div>
                {/* 
          <div>
            <Button
              type="primary"
              loading={loading || productProps.loading}
              disabled={loading || productProps.loading}
              icon={<PlusCircleOutlined />}
              onClick={() => {
                createProductFacebook({ variables: { id: parseInt(id) } });
              }}>
              {t("products.createProductFacebook")}
            </Button>
          </div> */}

                {/* <Switch
            style={{ marginTop: "20px" }}
            checkedChildren="AR"
            unCheckedChildren="EN"
            onChange={() => setIsEn(!isEn)}
          /> */}
              </Col>
            </Row>
          }
        />

        <Row gutter={16} style={{ marginTop: 44 }}>
          <Col span={24} md={16}>
            <TitleCard
              isEn={isEn}
              description={description}
              setDescription={setDescription}
              descriptionAr={descriptionAr}
              setDescriptionAr={setDescriptionAr}
              // slug={slug}
              pageName="products"
              form={form}
            />

            {/* <Media images={images} id={id} uploading={loading} setLoading={setLoading} form={form} /> */}
            {!rest.hasVariants ? (
              <>
                {/* <PriceCard profitMargin={profitMargin} profit={profit} currency={curr} /> */}
                {!loadingCurrency && (
                  <PriceCard
                    profitMargin={profitMargin}
                    profit={profit}
                    currency={currencyData?.setting?.currencyBase}
                  />
                )}
                <QuantityCard
                  trackQuantity={trackQuantity}
                  profit={profit}
                  form={form}
                  product={data.product}
                  isEditing
                />
                <ProductisDigitalCard digital={digital} setDigital={setDigital} form={form} />
                {!digital && <ShippingCard isPhysical={isPhysical} />}
              </>
            ) : (
              !isLoading && (
                <EditVariantCard
                  form={form}
                  setVariants={setVariants}
                  options={rest.options}
                  variants={variants}
                  id={id}
                  currency={currencyData?.setting?.currencyBase}
                />
              )
            )}
            {/* <VariantCard
            form={form}
            hasVariants={hasVariants}
            options={options}
            dataSource={dataSource}
            setDataSource={setDataSource}
          /> */}
            <SeoCard
              form={form}
              type="product"
              lang={isEn ? "en" : "ar"}
              isEdit
              seoItemId={data?.product?.seo?.id}
              defaultSeoValue={{
                title: seoTitle,
                body: { en: description, ar: descriptionAr },
                slug: { en: data?.product?.seo?.slug?.en, ar: data?.product?.seo?.slug?.ar },
              }}
            />
            <ProductSettingSubMenu id={data?.product.id} />
          </Col>
          <Col span={24} md={8}>
            {/* <EditThemeProduct /> */}
            <PublishSwitchComponent />
            <EditThemeProduct
              pageId={data?.product.id}
              pageType={"PRODUCT"}
              theme={data?.product.theme}
            />
            <FormCard style={{ padding: "16px 0" }}>
              <Space direction="vertical" size={16} style={{ width: "100%", padding: "0 16px" }}>
                <h2>{t("products.mainImage")}</h2>
                <Typography.Title level={4} className="upload-img">
                  {t("products.productImage")}
                </Typography.Title>
                <ChooseImg
                  setChossingImage={setMainImage}
                  //  mainImage={}
                  // addImage={addMainImage}
                  defaultImage={mainImage}
                  // visible,
                  // setVisible,
                  // firstImages,
                />
                <Media
                  images={images}
                  id={id}
                  uploading={loading}
                  setLoading={setLoading}
                  form={form}
                />
              </Space>
            </FormCard>
            <Organization mainImage={mainImage} form={form} />
          </Col>
        </Row>
      </Form>

      {/* <HasFeature feature={FeaturesNames.seo}>
        <Col span={24} md={16} className="login-form">
          <MetaTags
            seoId={data?.product?.seo?.id}
            // {...{ setFaceBookImage, facebookImage, setTwitterImage, twitterImage }}
          />
        </Col>
      </HasFeature> */}
    </>
  );
};

export default EditProduct;
