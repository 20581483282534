import { gql, useQuery } from "@apollo/client";
import { Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CustomSearch from "../../../../components/common/customSearch";
import formatMoney from "../../../../utlis/formatMoney";

const ORDER = gql`
  query ORDER($where: OrderWhereInput, $orderBy: OrderOrderByWithRelationInput, $skip: Int = 0) {
    orders(where: $where, orderBy: $orderBy, skip: $skip, take: 10) {
      count
      nodes {
        id
        total
        createdAt
        dueAmount
      }
    }
  }
`;

const AmountsDueTabComponent = () => {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState({ current: 1, total: 0 });
  const [search, setSearch] = useState(0);

  const orArr = [];
  const searchId = Number(search);
  if (Number.isSafeInteger(searchId) && searchId < 999999999 && searchId)
    orArr.push({ id: { equals: searchId } });

  const variables = {
    skip: (pagination.current - 1) * 10,
    orderBy: {
      createdAt: "desc",
    },
    where: {
      AND: [
        { OR: orArr },
        {
          status: { not: { in: ["CANCELED", "ARCHIVED"] } },
          // OR: [
          //   {
          //     paymentStatus: { equals: "PAID" },
          //   },
          //   {
          //     fulfillmentStatus: { in: ["FULFILLED", "PARTIALLY_FULFILLED"] },
          //   },
          // ],
          // status:{in:["OPEN","COMPLETED"]}
        },
      ],
    },
  };

  const { data, loading: invoicesLoading } = useQuery(ORDER, {
    variables: variables,
  });

  console.log("🚀 ~ file: balance.tsx ~ line 17 ~ BalanceTabComponent ~ data", data);
  useEffect(() => {
    setPagination({
      ...pagination,
      total: data?.orders?.count,
    });
  }, [data]);
  const balanceCol = [
    {
      title: t("order.orderNumber"),
      align: "center",
      render: (record) => <Link to={`/order/${record.id}`}>#{record.id}</Link>,
    },
    {
      title: t("order.date"),
      align: "center",
      dataIndex: "createdAt",
      render: (record) => moment(record).format("DD/MM/YYYY h:mmA"),
    },
    {
      title: t("order.totalOrder"),
      align: "center",
      dataIndex: "total",
      render: (record) => formatMoney({ price: record }),
    },
    {
      title: t("users.deserveMoney"),
      align: "center",
      dataIndex: "dueAmount",
      render: (record) => record + "$",
    },
  ];
  const handleTableChange = (pagination) => {
    setPagination({ ...pagination, current: pagination.current });
  };
  const onSearch = (value) => {
    setSearch(value);
  };
  return (
    <div>
      <CustomSearch
        placeholder={t("users.searchForOperation")}
        onSearch={onSearch}
        allowClear
        style={{ maxWidth: 395 }}
      />
      <Table
        bordered
        scroll={{ x: 500 }}
        className="operation-table"
        columns={balanceCol}
        dataSource={data?.orders.nodes}
        onChange={handleTableChange}
        loading={invoicesLoading}
        pagination={{ ...pagination, hideOnSinglePage: true, position: ["bottomCenter"] }}
      />
    </div>
  );
};

export default AmountsDueTabComponent;
