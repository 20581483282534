import { CalendarOutlined } from "@ant-design/icons";
import { Card, Col, Divider, Row, Skeleton, Space, Spin, Tag, Typography } from "antd";
import { formatDistanceToNow } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import MyImage from "../../../../common/image";
import { gql, useQuery } from "@apollo/client";
import AddReviewComponent from "./addReview";
import { useInView } from "react-cool-inview";
import InfiniteScroll from "react-infinite-scroll-component";

export const B7R_NOTIFICATIONS = gql`
  query Query(
    $where: B7rNotificationWhereInput
    $orderBy: B7rNotificationOrderByWithRelationInput
    $skip: Int
    $take: Int
  ) {
    b7rNotifications(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
      count
      nodes {
        id
        title
        desc
        createdAt
        # isSeen
        # ratingCount
        # ratingsAvg
        tags
        type
        # updatedAt
        # willAppearTo
      }
    }
  }
`;

const { Text, Title } = Typography;
const perPage = 5;
const FeatursTabComponent = () => {
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState([]);
  const [page, setpage] = useState(0);
  // const [records, setRecords] = useState([]);
  // console.log("🚀 ~ file: featursTab.tsx ~ line 44 ~ FeatursTabComponent ~ records", records);
  // const inputRef = useRef();

  const {
    loading,
    refetch,
    fetchMore,
    previousData,
    data = previousData,
  } = useQuery(B7R_NOTIFICATIONS, {
    onCompleted: (data) => {
      setNotifications([...notifications, ...data.b7rNotifications.nodes]);
    },
    variables: {
      take: perPage,
      skip: perPage * page,
      where: {
        type: {
          notIn: "NOTIFICATION",
        },
      },
      orderBy: {
        createdAt: "desc",
      },
    },
    // onCompleted: (data) => {
    //   setRecords([...records, ...data.myB7rLoyaltyPoints.nodes]);
    // },
  });
  // const { observe } = useInView({
  //   root: inputRef.current,
  //   onEnter: (ada) => {
  //     console.log("🚀 ~ file: rewardRecord.tsx ~ line 68 ~ useEffect ~ ada", ada);

  //     !loading && page * perPage < data?.myB7rLoyaltyPoints?.count && setPage(page + 1); // only run once
  //   },
  // });
  // if (loading) return <></>;
  console.log("🚀 ~ file: featursTab.tsx ~ line 37 ~ FeatursTabComponent ~ data", data);
  const typeTag = (type) => {
    switch (type) {
      case "FEATURE":
        return <Text className={"tag" + " feature"}>{t("home.feature")}</Text>;
      case "UPDATE":
        return <Text className={"tag" + " update"}>{t("home.update")}</Text>;
      case "FIX":
        return <Text className={"tag" + " fix"}>{t("home.fix")}</Text>;

      default:
        break;
    }
  };
  const loadMoreData = () => {
    console.log("🚀 ~ file: notificationTab.tsx ~ line 63 ~ loadMoreData ~ loadMoreData");

    !loading &&
      fetchMore({
        variables: {
          skip: (page + 1) * 20,
        },
      }).then(({ data }) => {
        setpage(page + 1);
        setNotifications([...notifications, ...data.b7rNotifications.nodes]);
      });
  };

  return (
    <Container>
      <div
        id="scrollableDivForNotification"
        style={{
          height: "100vh",
          overflow: "auto",
          padding: "0 16px",
          // border: "1px solid rgba(140, 140, 140, 0.35)",
        }}>
        <InfiniteScroll
          dataLength={notifications?.length || 20}
          next={loadMoreData}
          pullDownToRefresh={true}
          refreshFunction={refetch}
          hasMore={loading || notifications?.length < data?.b7rNotifications?.count}
          loader={<Skeleton paragraph={{ rows: 1 }} active />}
          endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
          scrollableTarget="scrollableDivForNotification">
          {notifications.map((item) => (
            <Card  key={item?.id} bodyStyle={{ padding: "18px 12px" }}>
              <Space direction="vertical" style={{ width: "100%" }}>
                {typeTag(item.type)}
                <Space className="date-time">
                  <CalendarOutlined />
                  {formatDistanceToNow(new Date(item?.createdAt), { addSuffix: true })}
                </Space>
                <Title level={5} style={{ margin: 0 }}>
                  {item?.title}
                </Title>
                <span dangerouslySetInnerHTML={{ __html: item?.desc }} className="description" />
                <Row>
                  <Col>
                    {item?.tags.map((tg, i) => (
                      <Tag color="blue" key={i}>
                        {tg}
                      </Tag>
                    ))}
                  </Col>
                </Row>
                <AddReviewComponent id={item?.id} />
              </Space>
            </Card>
          ))}
        </InfiniteScroll>
      </div>
      {/* <div ref={observe} style={{ textAlign: "center", padding: 10 }}>
        <Spin spinning={loading} />
      </div> */}
    </Container>
  );
};

export default FeatursTabComponent;

const Container = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  .ant-card {
    border-radius: 4px;
    box-shadow: 0px 0px 8px 0px #3333331c;
  }
  .tag {
    padding: 2px 12px;
    border-radius: 17px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }
  .feature {
    background-color: rgba(44, 184, 122, 0.16);
    color: ${(props) => props.theme.customColors.green};
  }
  .update {
    background-color: rgba(251, 156, 0, 0.16);
    color: #fb9c00;
  }
  .fix {
    background-color: rgba(236, 102, 61, 0.16);
    color: ${(props) => props.theme.customColors.lightOrange};
  }
  .description {
    margin: 0;
    font-size: 12px;
    line-height: 18px;
  }
  .date-time {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: ${(props) => props.theme.colors.Gray1};
  }
`;
