export function scrollOnError() {
  setTimeout(() => {
    let elements = document.querySelectorAll(".ant-form-item-has-error");
    console.log("🚀 ~ file: scrollOnError.js ~ line 3 ~ scrollOnError ~ elements", elements);
    if (elements.length > 0)
      elements[0].scrollIntoView({
        behavior: "smooth",
      });
  }, 2);
}
