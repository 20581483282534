import { Button, Modal, Space } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { getDiffranceDate, typeRender } from "./usableUtils";

const TransferOrderDetailsPopup = ({ data }) => {
  console.log(
    "🚀 ~ file: transferOrderDetails.tsx ~ line 7 ~ TransferOrderDetailsPopup ~ data",
    data,
  );
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleCancel = () => setIsModalVisible(false);
  const showModal = () => setIsModalVisible(true);
  // const { dueAmount, createdAt, total } = data?.orders;
  // let totalDueAmount = data.orders.reduce((pre, curr) => pre + curr.dueAmount, 0);
  var startDate = moment(data.start, "YYYY-MM-DD");
  var endDate = moment(data.end, "YYYY-MM-DD");
  const getOperationDetails = (type) => {
    switch (type) {
      case "PLAN":
        return (
          <>
            <span>{t("plans.plan") + " : " + data.plan}</span>
            {data.plan === "payOnce" && (
              <span>{"مدة الاشتراك : " + getDiffranceDate(data.start, data.end)}</span>
            )}
          </>
        );
      case "SERVICE":
        return <span>{t("apps.affialte.service") + " : " + data.service.name.auto}</span>;
      case "ADD_TO_BALANCE":
        return <span>{t("apps.affialte.paymentMethod") + " : " + data.method}</span>;
      default:
        break;
    }
  };
  return (
    <div>
      <Button type="link" onClick={showModal}>
        {t("users.transferDetails")}
      </Button>
      <Container
        title={<span style={{ fontWeight: 700 }}>{t("users.transferDetails")}</span>}
        bodyStyle={{ padding: 12, maxHeight: "60vh", overflowY: "auto" }}
        footer={null}
        visible={isModalVisible}
        onCancel={handleCancel}>
        <Space direction="vertical">
          <span className="operation-date" dir="ltr">
            {moment(data.createdAt).format("DD/MM/YYYY, h:mm A")}
          </span>
          <span className="operation-type">
            {t("users.operationType") + " : " + typeRender(data.type)}
          </span>
          {getOperationDetails(data.type)}
          <span className="amount">{t("apps.cash") + " : " + data.value + "$"}</span>
        </Space>
        {data?.orders.map((item) => (
          <div className="flex-col">
            <span className="order-id">#{item.id}</span>
            <span className="order-date" dir="ltr">
              {moment(item.createdAt).format("DD/MM/YYYY, h:mm A")}
            </span>
            <div className="flex-between">
              <span className="order-value">{t("order.totalOrder") + ": " + item.total}</span>
              <span className="order-due">
                {t("users.deserveMoney") + ": " + item.dueAmount + " $ "}
              </span>
            </div>
          </div>
        ))}
      </Container>
    </div>
  );
};

export default TransferOrderDetailsPopup;

const Container = styled(Modal)`
  .flex-col {
    margin: 5px 0;
    width: 100%;
    padding: 12px;
    display: inline-flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    background: ${(props) => props.theme.customColors.lightGray};
  }
  .flex-between {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
  }
  .order-id {
    font-weight: 700;
    color: ${(props) => props.theme.customColors.lightBlue};
  }
  .order-date {
    font-size: 12px;
    color: ${(props) => props.theme.colors.Gray6};
  }
  .order-value {
    color: ${(props) => props.theme.colors.Gray1};
  }
  .order-due {
    color: ${(props) => props.theme.colors.Gray2};
  }
  .operation-date {
    font-weight: 600;
    font-size: 12px;
    color: ${(props) => props.theme.colors.Gray1};
  }
`;
