import { Image, Space, Tooltip, Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import MyImage from "../../common/image";
import discount from "../../../assets/imgs/layout/discount.svg";
import Logo from "../../../assets/imgs/layout/discount";

import { Breakpoints } from "../../../constants";

const { Text, Paragraph } = Typography;

const AnnoncementBarComponent = ({ code }) => {
  const { t } = useTranslation();
  const [copyUrl, setCopyUrl] = useState(false);
  const toolTipText = copyUrl ? "تم النسخ" : "انسخ";
  return (
    <>
      <Container>
        <Space align="center" size={4}>
          <div className="logo">
            <Logo />
          </div>
          {/* <Image preview={false} src={discount} width={20} className="logo" /> */}
          <Text className="content">
            قم باستخدام كود خصم{" "}
            {
              <Tooltip placement="bottom" title={toolTipText}>
                <Text
                  className="promo-code"
                  onClick={() => {
                    navigator.clipboard.writeText(code);
                    setCopyUrl(true);
                  }}>
                  {code}
                </Text>
              </Tooltip>
            }{" "}
            واستمتع بخصم 30% عند الاشتراك في أحد الباقات
          </Text>
        </Space>
      </Container>
      <div style={{ height: 43 }} />
    </>
  );
};

export default AnnoncementBarComponent;

const Container = styled.div`
  width: 100%;
  padding: 6.5px;
  text-align: center;
  font-weight: 600;
  position: fixed;
  z-index: 999;
  background-color: ${(props) => props.theme.borderColors.darkRed};
  .content {
    color: #fff;
    margin-bottom: 0;
  }
  .promo-code {
    color: #ffdb6c;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
  }
  .logo {
    margin-top: 2px;
    @media (max-width: ${Breakpoints.sm}) {
      display: none;
    }
  }
`;
