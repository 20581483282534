enum FeaturesNames {
  liveChat = "liveChat",
  changeDomain = "changeDomain",
  locations = "locations",
  analytics = "analytics",
  checkoutSetting = "checkoutSetting",
  productSetting = "productSetting",
  importProducts = "importProducts",
  blog = "blog",
  pages = "pages",
  productsFilter = "productsFilter",
  coupons = "coupons",
  reviews = "reviews",
  seo = "seo",
  archivedOrders = "archivedOrders",
  ai = "ai",
}

export default FeaturesNames;
