import { gql, useMutation, useQuery } from "@apollo/client";
import { varDomain } from "../../modules/common/defaults";
import CustomSider from "./sider";
import { Layout, Spin } from "antd";

import { CURRENT_USER } from "../../utlis/Me";

import { useHistory } from "react-router-dom";
import PhoneNumberPopup from "../home/phoneNumPopup";
import { authMutation } from "../../modules/auth/resolvers";
import AnnoncementBarComponent from "./header/annoncementBar";
import ActevationBarAlert from "./header/actevationBarAlert";
import ChargeWalletModalComponent from "../../pages/profile/addToBalance/chargeWallet";
import HugeReqs from "./common/hugeReqs";

const { Content } = Layout;
export const SIGN_OUT = gql`
  mutation SIGN_OUT {
    signOut
  }
`;
const whitePathes = [
  "/apps/app/info",
  "/apps/facebook/info",
  "/apps/reviews/info",
  "/apps/popups/info",
  "/apps/knawat/info",
  "/apps/seo/info",
  "/apps/sendstrap/info",
  "/apps/gameball/info",
  "/apps/message/info",
  "/apps/taager/info",
  "/apps/affiliate/info",
  "/apps/aliExpress/info",
  "/apps/loyalty/info",
];
const Page = (props) => {
  const [signOut] = useMutation(SIGN_OUT, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: CURRENT_USER }],
  });
  const history = useHistory();

  const { data, loading } = useQuery(CURRENT_USER, {
    fetchPolicy: "cache-first",
  });
  let {
    location: { pathname },
  } = useHistory();

  const LoadingSpin = (
    <Spin
      style={{
        position: "absolute",

        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    />
  );
  if (loading) return LoadingSpin;

  if (!data?.me) return props.children;

  if (data.me?.role === "CUSTOMER") {
    signOut().then(() => {
      authMutation.signOut();
      history.push("/signin");
    });
    return LoadingSpin;
  }
  const pathCon = pathname === "/themes/dev/new";
  if (pathCon) return props.children;
  if (!varDomain() && data?.me?.stores.length >= 2) history.push("/shops");
  console.log(
    "🚀 ~ file: index.js ~ line 71 ~ Page ~ data?.me?.stores.length >= 2",
    data?.me?.stores.length >= 2,
  );
  const isWhiteBackground = whitePathes.includes(pathname);
  const isValuedToShowOnBoarding =
    !loading &&
    // me?.shop?.onBoardingFlag &&
    (data?.me?.currentShop?.onBoardingFlag !== 12 || data?.me?.currentShop?.onBoardingFlag == null);
  console.log("🚀 ~ file: index.js ~ line 40 ~ Page ~ data", data);

  return (
    <>
      {/* {data?.me?.currentShop?.isInTrial ? <AnnoncementBarComponent code={"WELCOME"} /> : undefined} */}
      <CustomSider>
        <Content
          className="content-container"
          style={{ backgroundColor: isWhiteBackground ? "#fff" : "#fbfbfb" }}>
          {data?.me?.currentShop?.balance < 0 && data?.me?.currentShop?.status == "STOPPED" && (
            <ChargeWalletModalComponent shouldPay={data?.me?.currentShop?.balance} />
          )}
          {!data?.me?.phone && !isValuedToShowOnBoarding ? <PhoneNumberPopup /> : null}
          <HugeReqs />
          {/* <Navbar /> */}
          {data?.me?.currentShop?.isInTrial && <ActevationBarAlert />}
          {props.children}
          {/* <Footer /> */}
        </Content>
      </CustomSider>
    </>
  );
};

export default Page;
