import React, { useState, useEffect } from "react";

import {
  Table,
  Button,
  Space,
  Popconfirm,
  Row,
  Col,
  Dropdown,
  Modal,
  Menu,
  Tabs,
  Typography,
} from "antd";
import filterIcon from "../../assets/imgs/filter.svg";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import { GetNewPageData } from "../../lib/GetNewPageData";
import BackButton from "../../components/common/backBtn";
import {
  PlusCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  DownOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import EmptyPage from "../../components/common/empty";
import noUser from "../../assets/imgs/nouser.svg";
import AddImage from "../../assets/imgs/plus-square.svg";
import RemoveImage from "../../assets/imgs/x-square.svg";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import qs from "qs";

import styled from "styled-components";
import { AddTags } from "../orders/tag";
import { SEARCH_FILTERS } from "../../enums/searchFilters";
import FilterDrawer from "../../components/filter/filterDrawer";
import { GenerateHandleParams } from "../../lib/generateHandleParams";
import CustomLoading from "../../components/common/customLoading";
import MyImage from "../../components/common/image";
import CustomSearch from "../../components/common/customSearch";
import trash from "../../assets/imgs/trash-2.svg";

const { TabPane } = Tabs;
export const USERS = gql`
  query USERS($skip: Int, $filter: UserWhereInput) {
    users(
      skip: $skip
      take: 10
      orderBy: { id: desc }
      # where: { role: { not: { equals: VENDOR } } }
      where: $filter
    ) {
      count
      nodes {
        id
        name
        phone
        role
        tags {
          name
        }
      }
    }
  }
`;

export const BULK_TAGS_ACTION = gql`
  mutation BULK_TAGS_ACTION(
    $bulkTagsActionIds: [Int!]!
    $bulkTagsActionAction: bulkTagsActionType
    $bulkTagsActionTags: [String!]
    $bulkTagsActionType: TagType
  ) {
    bulkTagsAction(
      ids: $bulkTagsActionIds
      action: $bulkTagsActionAction
      tags: $bulkTagsActionTags
      type: $bulkTagsActionType
    ) {
      id
    }
  }
`;

export const DELETE_USER = gql`
  mutation DELETE_USER($where: UserWhereUniqueInput!) {
    deleteOneUser(where: $where) {
      id
    }
  }
`;

export const DELETE_MANY_USER = gql`
  mutation DELETE_MANY_USER($deleteManyUserIds: [Int!]!) {
    deleteManyUser(ids: $deleteManyUserIds)
  }
`;
const removeTags = "remove";
const addTags = "add";
const UsersTable = () => {
  const [pagination, setPagination] = useState({ current: 1 });
  const [filterSearch] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [modelState, setModelState] = useState({ open: false, type: "" });
  const [newTags, setNewTags] = useState([]);
  const [visible, setVisible] = useState(false);
  const [firstLoadFinished, setFirstLoadFinished] = useState(false);
  const [filterLoaded, setFilterLoaded] = useState(false);
  const [currentTab, setCurrentTab] = useState("1");
  const route = useHistory();
  const query = qs.parse(route.location.search, {
    ignoreQueryPrefix: true,
  });
  const [search, setSearch] = useState(query.search || null);
  const [drawerFilter, setDrawerFilter] = useState([]);
  const [searchVal, setSearchVal] = useState(query.search || "");
  const orArr = [];

  // address {
  //   firstName
  //   lastName

  // }
  const currentRole = {
    role: { equals: "CUSTOM" },
  };

  const searchId = Number(search);
  if (Number.isSafeInteger(searchId) && searchId < 999999999 && searchId) {
    orArr.push({ id: { equals: searchId } });
  }
  if (search?.length)
    orArr.push(
      { name: { contains: search, mode: "insensitive" } },
      { phone: { contains: searchId.toString() } },
    );
  const variables = {
    skip: (pagination.current - 1) * 10,
    filter: {
      // AND: [{ OR: orArr }].concat(drawerFilter),
      AND: [{ OR: orArr }]
        .concat(drawerFilter)
        .concat([{ role: { not: { equals: "VENDOR" } } }, currentRole]),
      // ...currentState,
    },
  };
  // const variables = {
  //   skip: (pagination.current - 1) * 10,
  //   filter: {
  //     // ...currentState,
  //   },
  // };

  // const {
  //   location: { pathname, search: searchQ },
  //   push,
  // } = useHistory();
  const {
    location: { pathname, search: searchQ },
    push,
  } = route;
  const handleParams = GenerateHandleParams(push, pathname, query);
  const handleCancel = () => {
    // setIsRemoveTagModalVisible(false);
    // setIsAddTagModalVisible(false);
    setModelState({ open: false, type: "" });
  };
  const onSearch = (value) => {
    setSearch(value);
    handleParams({ search: value || null, page: 1 });
  };
  const { t } = useTranslation();

  const onSelectChange = (selectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
  };

  const variabless = {
    skip: (pagination.current - 1) * 10,
    filter: filterSearch,
  };
  const { data, loading } = useQuery(USERS, {
    variables,
    onCompleted() {
      !firstLoadFinished && setFirstLoadFinished(true);
    },
  });
  console.log("🚀 ~ file: index.js ~ line 160 ~ UsersTable ~ data", data);
  console.log("🚀 ~ file: index.js ~ line 131 ~ UsersTable ~  variables", variables);

  const [removeUser] = useMutation(DELETE_USER, {
    onCompleted: () => {
      GetNewPageData(pagination, setPagination);
    },
    update(cache, { data: { deleteOneUser } }) {
      const { users } = cache.readQuery({
        query: USERS,
        variables: variables,
      });
      cache.writeQuery({
        query: USERS,
        variables: variables,

        data: {
          users: {
            count: users.count - 1,
            nodes: users.nodes.filter(({ id }) => deleteOneUser.id !== id),
          },
        },
      });
    },
  });

  const [deleteUsers] = useMutation(DELETE_MANY_USER, {
    update(cache, { data: { deleteManyUser } }) {
      const { users } = cache.readQuery({
        query: USERS,
        variables: variables,
      });
      cache.writeQuery({
        query: USERS,
        variables: variables,

        data: {
          users: {
            count: users.count - selectedRowKeys.length,
            nodes: users.nodes.filter(({ id }) => !selectedRowKeys.includes(id)),
          },
        },
      });
    },
    onCompleted: () => setSelectedRowKeys([]),
  });
  console.log(selectedRowKeys);

  useEffect(() => {
    setPagination({
      ...pagination,
      total: data?.users?.count,
    });
  }, [data]);

  const handleTableChange = (pagination) => {
    setPagination({ ...pagination, current: pagination.current });
    route.push({
      pathname: route.location.pathname,
      search: qs.stringify(
        {
          ...query,
          page: pagination.current || 1,
        },
        { arrayFormat: "comma", encode: false },
      ),
    });
  };

  const columns = () => {
    const cols = [
      {
        title: t("users.user"),
        width: "150px",
        align: "center",
        // ...getColumnSearchProps("Companies"),
        render: (record) => {
          console.log("🚀 ~ file: index.js ~ line 241 ~ columns ~ record", record);
          return (
            <Space>
              <Link to={`/user/${record.id}`}>{record.name}</Link>
            </Space>
          );
        },
      },
      // {
      //   title: t("users.role"),
      //   width: "150px",
      //   align: "center",
      //   dataIndex: "role",
      // },
      {
        // title: t("users.user"),
        title: "رقم الهاتف",

        width: "150px",
        align: "center",
        dataIndex: "phone",
      },
      {
        title: t("users.tags"),
        width: "150px",
        align: "center",
        // ...getColumnSearchProps("Companies"),
        render: (record) => (
          <div
            size="small"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              flexWrap: "wrap",
              alignContent: "center",
            }}>
            {record?.tags?.map((tagName, i) => (
              <div
                key={i}
                style={{
                  backgroundColor: "#FAFAFA",
                  padding: "5px",
                  borderRadius: "4px",
                  margin: "0 3px",
                }}>
                {tagName?.name}
              </div>
            ))}
          </div>
        ),
      },
      {
        align: "center",
        width: "50px",
        render: (record) => (
          <Space style={{ marginRight: 20 }}>
            <Link to={`/user/${record.id}`}>
              <EditOutlined />
            </Link>
          </Space>
        ),
      },
      {
        align: "center",
        width: "50px",
        render: (record) => (
          <Popconfirm
            title={t("users.deleteUser")}
            onConfirm={() => removeUser({ variables: { where: { id: record.id } } })}
            okText={t("posts.yes")}
            cancelText={t("posts.no")}>
            <MyImage src={trash} width={16} />
          </Popconfirm>
        ),
      },
    ];

    return cols;
  };

  useEffect(() => {
    if (query?.page) setPagination((old) => ({ ...old, current: parseInt(query.page) }));
  }, [query?.page]);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  function handleMenuClick(e) {
    console.log("click", e);
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      {/* <Menu.Item
        style={{ lineHeight: "36px" }}
        key="1"
        icon
        onClick={() => {
          printOrders({
            variables: {
              filter: {
                id: {
                  in: selectedRowKeys,
                },
              },
            },
          });
        }}>
        <img src={PrinterImage} /> &nbsp;&nbsp; {t("order.print")}
      </Menu.Item> */}
      <Menu.Item
        style={{ lineHeight: "36px" }}
        key="2"
        onClick={() =>
          //  setIsAddTagModalVisible(true)

          setModelState({ open: true, type: addTags })
        }>
        <img src={AddImage} /> &nbsp;&nbsp; {t("order.addTag")}
      </Menu.Item>
      <Menu.Item
        style={{ lineHeight: "36px" }}
        key="3"
        onClick={() =>
          //  setIsRemoveTagModalVisible(true)

          setModelState({ open: true, type: removeTags })
        }>
        <img src={RemoveImage} /> &nbsp;&nbsp; {t("order.removeTag")}
      </Menu.Item>
      <Menu.Item
        style={{ lineHeight: "36px" }}
        key="4"
        onClick={() => {
          deleteUsers({
            variables: {
              deleteManyUserIds: selectedRowKeys,
            },
            // refetchQueries: [{ query: USERS, variables: variables }],
          });
        }}>
        <img src={RemoveImage} /> &nbsp;&nbsp; {t("order.remove")}
      </Menu.Item>
    </Menu>
  );

  const [updateUsersTags, { loading: updateTagsLoading }] = useMutation(BULK_TAGS_ACTION, {
    refetchQueries: [{ query: USERS }],
    onCompleted() {
      setModelState({ open: false, type: "" });
    },
    update(cache, { data: { bulkTagsAction } }) {
      const { users } = cache.readQuery({
        query: USERS,
        variables: variables,
      });
      // cache.writeQuery({
      //   query: USERS,
      //   variables: variables,
      //   data: {
      //     users: {
      //       count: users.count,
      //       nodes: users.nodes.map((user) => {
      //         const { id } = user;
      //         if (!selectedRowKeys.includes(id)) return user;
      //         const newOrder = { ...order };
      //         newOrder.tags =
      //           modelState.type === "add"
      //             ? [...new Set([...newOrder.tags, ...newTags])]
      //             : newOrder.tags.filter((tag) => !newTags.includes(tag));
      //         return newOrder;
      //       }),
      //     },
      //   },
      // });
    },
  });
  const onClose = () => {
    setVisible(false);
  };

  const onFinishTags = (tags) => {
    console.log("🚀 ~ file: index.js ~ line 336 ~ onFinishTags ~ tags", tags);
    if (!tags || !modelState.type) return;

    updateUsersTags({
      variables: {
        bulkTagsActionIds: selectedRowKeys,
        bulkTagsActionAction: modelState.type,
        bulkTagsActionTags: tags,
        bulkTagsActionType: "USER",
      },
    });
  };

  // const isEmpty = data?.users?.count <= 0;
  // return;
  // false ? (
  //   <EmptyPage
  //     image={noUser}
  //     description={t("users.nousers")}
  //     buttonLink="/users/new"
  //     buttonText={t("users.addUser")}
  //   />
  // ) :
  return (
    <>
      {(loading || !filterLoaded) && !firstLoadFinished && <CustomLoading />}
      {/* <EmptyPage orderImg={isNoOrderImg} orderText={isNoOrderText} /> */}
      <FilterDrawer
        searchFilters={[SEARCH_FILTERS.TAGS, SEARCH_FILTERS.EMAIL]}
        // currentTab={currentTab}
        onClose={onClose}
        visible={visible}
        setVisible={setVisible}
        drawerFilter={drawerFilter}
        pagination={pagination}
        setDrawerFilter={setDrawerFilter}
        handleParams={handleParams}
        setFilterLoaded={setFilterLoaded}
        page="order"
      />
      <Container>
        <Row justify="space-between">
          <Col>
            {/* <Typography.Title>{t("users.CUSTOM")}</Typography.Title> */}
            <BackButton text={t("settings.settings")} url="/settings" />
            <Typography.Title level={3}>فريق العمل</Typography.Title>
          </Col>
          <Col>
            <Button onClick={() => route.push("/users/new")} type="primary" icon={<PlusOutlined />}>
              {t("users.addUser")}
            </Button>
          </Col>
        </Row>
        <Row style={{ marginBottom: "24px" }}>
          <Col span={24}>
            {/* <Row justify="space-between"> */}
            {/* <Col span={18}> */}
            <Row>
              <Col flex="300px">
                <CustomSearch
                  placeholder={t("users.search")}
                  onSearch={onSearch}
                  value={searchVal}
                  allowClear
                  onChange={(val) => {
                    setSearchVal(val.target.value);
                  }}
                  className="search-field"
                />
              </Col>
              <Col style={{ marginBottom: "12px" }} flex="50px">
                <Button
                  onClick={() => {
                    setVisible(!visible);
                  }}
                  // type="primary"
                  type="default"
                  icon={<MyImage src={filterIcon} width={12} />}
                  // icon={<PlusCircleOutlined />}
                  style={{
                    marginRight: "10px",
                    marginLeft: "10px",
                    height: 46,
                  }}>
                  {/* {t("order.filter")} */}
                  {t("sellChannel.filter")}
                  {/* <PlusOutlined style={{ fontSize: 14 }} /> */}
                </Button>
              </Col>
            </Row>
            {/* </Col>
            <Col span={6}> */}

            {/* </Col> */}
            {/* </Row> */}
          </Col>

          <Col span={24}>
            {selectedRowKeys.length > 0 && (
              <Row justify="end">
                <Col>
                  <div style={{ lineHeight: "36px", color: "#8e8e8e", margin: "0 10px" }}>
                    {t("order.selectedNumber")}{" "}
                    {selectedRowKeys.length > 0 && selectedRowKeys.length}
                  </div>
                </Col>
                <Col>
                  <Dropdown trigger={["click"]} placement="bottomCenter" overlay={menu}>
                    <Button type="text" style={{ color: "#4a9aca" }}>
                      {t("order.process")} <DownOutlined />
                    </Button>
                  </Dropdown>
                </Col>
              </Row>
            )}
          </Col>
        </Row>

        <Table
          rowSelection={rowSelection}
          scroll={{ x: 1000 }}
          loading={loading}
          rowKey={(record) => record.id}
          columns={columns()}
          size="small"
          bordered
          dataSource={data?.users.nodes}
          onChange={handleTableChange}
          pagination={{ ...pagination, hideOnSinglePage: true, position: ["bottomCenter"] }}
        />
        <Modal
          title={t(modelState.type === addTags ? "order.addTag" : "order.removeTag")}
          visible={modelState.open}
          // onOk={onFinishTags}
          onCancel={handleCancel}
          footer={false}>
          <AddTags
            onFinish={onFinishTags}
            type="USER"
            loading={updateTagsLoading}
            // setNewTags={setNewTags}
          />
          {/* <AddTags onFinish={onFinishTags} setNewTags={setNewTags} /> */}
        </Modal>
      </Container>
    </>
  );
};

const Container = styled.div`
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: none;
  }

  .ant-table-wrapper-rtl .ant-table-thead > tr > th {
    text-align: center;
  }
  .search-field {
    margin-bottom: 20px;
    max-width: 300px;
  }
`;

export default UsersTable;
