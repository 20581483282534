
export enum PaidStatues {
  paid = "PAID",
  unPaid = "UN_PAID",
}

export function getOrderPaidStatuses(t) {
  return [
    { value: PaidStatues.paid, text: t("order.paid") },
    { value: PaidStatues.unPaid, text: t("order.notPaid") },
  ];
}
