import qs from "qs";

export function GenerateHandleParams(push, pathname, query) {
  return (q) => {
    console.log(
      "🚀 ~ file: generateHandleParams.js ~ line 4 ~ GenerateHandleParams ~ query",
      query,
    );
    console.log("🚀 ~ file: handleParamsF.js ~ line 5 ~ return ~ q", q);
    if (q === "reset") {
      push({
        pathname,
        search: qs.stringify({ page: 1 }, { arrayFormat: "comma", encode: false }),
      });
      return;
    }
    push({
      pathname,
      search: qs.stringify(
        {
          ...query,
          ...q,
        },
        { arrayFormat: "comma", encode: false, skipNulls: true },
      ),
    });
  };
}
