import { Spin } from "antd";

const Loading = () => (
  <Spin
    size="large"
    style={{
      position: "fixed",
      right: "45%",
      top: "45%",
    }}
  />
);

export default Loading;
