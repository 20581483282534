import React, { useState, useEffect, useRef, useCallback } from "react";

import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import { useQuery, useMutation, gql } from "@apollo/client";

import { Button, Table, Space, Popconfirm } from "antd";
import { DndProvider, createDndContext } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import DragableBodyRow from "./dragableRow";
const RNDContext = createDndContext(HTML5Backend);

export const SLIDES = gql`
  query SLIDES($type: String! = "mainSlider") {
    slides(orderBy: { index: asc }, where: { type: { equals: $type } }) {
      count
      nodes {
        id
        text {
          id
          ar
          en
        }
        action
        category {
          id
          name {
            id
            auto
          }
        }
        image {
          id
          ar {
            id
            sm
          }
          en {
            id
            sm
          }
        }

        href
      }
    }
  }
`;

export const UPDATE_SLIDES_ORDER = gql`
  mutation UPDATE_SLIDES_ORDER($slideOrders: [SlideOrderInput!]!) {
    updateSlideOrder(slideOrders: $slideOrders) {
      id
    }
  }
`;

export const DELETE_SLIDE = gql`
  mutation DELETE_SLIDE($where: SlideWhereUniqueInput!) {
    deleteOneSlide(where: $where) {
      id
    }
  }
`;

const DataTable = ({ seteditableRecord, isEn }) => {
  const { data, loading } = useQuery(SLIDES);
  const [dataSource, setDataSource] = useState([]);
  const [updateSlideOrder] = useMutation(UPDATE_SLIDES_ORDER);

  const [removeSlide] = useMutation(DELETE_SLIDE, {
    update(cache, { data: { deleteOneSlide } }) {
      const { slides } = cache.readQuery({
        query: SLIDES,
      });
      cache.writeQuery({
        query: SLIDES,
        data: {
          slides: {
            count: slides.count - 1,
            nodes: slides.nodes.filter(({ id }) => deleteOneSlide.id !== id),
          },
        },
      });
    },
  });

  useEffect(() => {
    data?.slides && setDataSource(data?.slides.nodes);
  }, [data]);

  const save = () => {
    updateSlideOrder({
      variables: {
        slideOrders: dataSource.map(({ id }, index) => ({
          id,
          index: index + 1,
        })),
      },
    });
  };

  const components = {
    body: {
      row: DragableBodyRow,
    },
  };
  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = dataSource[dragIndex];
      setDataSource(
        update(dataSource, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }),
      );
    },
    [dataSource],
  );
  const manager = useRef(RNDContext);

  const columns = () => {
    const cols = [
      {
        title: `Slide`,
        // align: "center",
        // ...getColumnSearchProps("Companies"),
        render: (record) => (
          <Space>
            {
              <img
                style={{ width: 50 }}
                src={isEn ? record.image.en?.sm : record.image.ar?.sm}
                alt="img"
              />
            }
            <div
              dangerouslySetInnerHTML={{
                __html: isEn ? record.text.en : record.text.ar,
              }}
            />

            {}
          </Space>
        ),
      },
      {
        title: `Action`,
        // align: "center",
        // ...getColumnSearchProps("Companies"),
        render: (record) => record.action,
      },
      {
        title: `Href`,
        // align: "center",
        // ...getColumnSearchProps("Companies"),
        render: (record) => record.href || record.category?.auto,
      },

      {
        align: "end",

        render: (record) => (
          <Space style={{ marginRight: 20 }}>
            <EditOutlined
              onClick={() => {
                seteditableRecord(record);

                window.scrollTo(0, 150);
              }}
            />
            <Popconfirm
              title="Are you sure delete this slide?"
              onConfirm={() => removeSlide({ variables: { where: { id: record.id } } })}
              okText="Yes"
              cancelText="No">
              <DeleteOutlined style={{ color: "red" }} />
            </Popconfirm>
          </Space>
        ),
      },
    ];

    return cols;
  };

  return (
    <>
      <DndProvider manager={manager.current.dragDropManager}>
        <Table
          scroll={{ x: 1000 }}
          loading={loading}
          rowKey={(record) => record.id}
          columns={columns()}
          size="small"
          dataSource={dataSource}
          pagination={false}
          components={components}
          onRow={(record, index) => ({
            index,
            moveRow,
          })}
        />
      </DndProvider>

      <Button onClick={save}> save </Button>
    </>
  );
};

export default DataTable;
