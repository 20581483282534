import { useTranslation } from "react-i18next";
import { Select, Form } from "antd";
import FormItem from "antd/lib/form/FormItem";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useEffect, useState } from "react";

const { Option } = Select;

export const SETTINGS = gql`
  query SETTINGS {
    setting(where: { name: { equals: "web" } }) {
      id
      languages
      defaultLanguage
    }
  }
`;

const DefaultLanguage = ({ languages, setLanguages }: any) => {
  console.log(
    "🚀 ~ file: defaultLanguage.tsx ~ line 21 ~ toreLanguage ~ newValue ~ languages",
    languages,
  );
  const { t } = useTranslation();
  const { data, loading } = useQuery(SETTINGS, {
    fetchPolicy: "cache-first",
  });
  console.log("datadatadta", data);
  const [Langs, setLangs] = useState([]);
  console.log("🚀 ~ file: defaultLanguage.tsx ~ line 28 ~ DefaultLanguage ~ Langs", Langs);

  useEffect(() => {
    if (!languages && data) {
      setLangs(data?.setting?.languages);
    } else {
      setLangs(languages.map(({ value }) => value));
    }
  }, [languages, data]);

  return (
    <Form.Item label={t("settings.defaultLang")} name="defaultLanguage">
      <Select>
        {Langs &&
          Langs.map((language) => (
            <Option key={language} value={language}>
              {language}
            </Option>
          ))}
      </Select>
    </Form.Item>
  );
};

export default DefaultLanguage;
