import { Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import MyImage from "../components/common/image";
import error404 from "../assets/imgs/404.svg";
import { useHistory } from "react-router";

interface Props {}

const Container = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .not-title {
    margin-top: 20px;
  }
`;
const NotAllows = (props: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const currentPath = history.location.pathname;
  if (currentPath.includes("beVendor")) return null;
  return (
    <Container>
      <MyImage width={"auto"} src={error404} className="error-img" />
      <Typography.Title className="not-title">{t("common.NotAllows")}</Typography.Title>
    </Container>
  );
};

export default NotAllows;
