import { gql, makeVar, useQuery } from "@apollo/client";
import { Button, notification, Space } from "antd";
import styled from "styled-components";
export const MY_HUGE_REQS = gql`
  query MY_HUGE_REQS($currentHugereqs: [Int!]) {
    myHugeReq(currentHugereqs: $currentHugereqs) {
      id
      type
      status
    }
  }
`;
export const currentHugereqsVar = makeVar([]);
const HugeReqs = () => {
  const importMsgs = (status) => {
    console.log(status, "statusmldsam");
    if (status === "RUNNING") {
      return "إستيراد المنتجات قيد التنفيذ";
    } else if (status === "FAILED") {
      return "فشل إستيراد المنتجات";
    } else if (status === "WAITING") {
      return "إستيراد المنتجات قيد الانتظار";
    } else if (status === "SUCCESS") {
      return "إستيراد المنتجات تم بنجاح ";
    }
  };
  const { data, refetch, loading } = useQuery(MY_HUGE_REQS, {
    variables: { currentHugereqs: currentHugereqsVar().map(({ id }) => id) },
    fetchPolicy: "cache-and-network",
    pollInterval: 1000 * 60 * 2,
    notifyOnNetworkStatusChange: true,

    onCompleted(data) {
      console.log("🚀 ~ file: hugeReqs.tsx ~ line 18 ~ const{data}=useQuery ~ data", data);
      currentHugereqsVar(data.myHugeReq);
      notification.destroy();

      data.myHugeReq.map(({ status, type, id }) => {
        console.log(status, "dkasdmkasd");
        notification.open({
          onClose() {
            currentHugereqsVar(currentHugereqsVar().filter((item) => id !== item.id));
          },
          duration: 0,
          type: status == "FAILED" ? "error" : status == "SUCCESS" ? "success" : "info",
          message: importMsgs(status),
          // `${type} is ${status}`,
          description: (
            <Button
              style={{ border: "none" }}
              className="custom-btn"
              loading={loading}
              disabled={loading}
              onClick={() => refetch()}>
              <Space align="center" size={8}>
                <RefreshIcon />
                <span style={{ color: "#1985a1" }} className="refresh-text">
                  تحديث
                </span>
              </Space>
            </Button>
          ),
          placement: "bottomRight",
        });
      });
    },
  });

  console.log("🚀 ~ file: hugeReqs.tsx ~ line 15 ~ HugeReqs ~ data", data);

  return <Container></Container>;
};

export default HugeReqs;

const Container = styled.div`
  .custom-btn {
    border: none !important;
  }
`;

const RefreshIcon = () => {
  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.375 2V5.75H10.625"
        stroke="#1985A1"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M0.625 12V8.25H4.375"
        stroke="#1985A1"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.19375 5.12547C2.51073 4.22972 3.04946 3.42885 3.75967 2.79761C4.46988 2.16637 5.32842 1.72533 6.25518 1.51564C7.18194 1.30594 8.14672 1.33444 9.0595 1.59846C9.97227 1.86248 10.8033 2.35342 11.475 3.02547L14.375 5.75047M0.625 8.25047L3.525 10.9755C4.19672 11.6475 5.02773 12.1385 5.94051 12.4025C6.85328 12.6665 7.81806 12.695 8.74482 12.4853C9.67159 12.2756 10.5301 11.8346 11.2403 11.2033C11.9505 10.5721 12.4893 9.77123 12.8063 8.87547"
        stroke="#1985A1"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
