import {
  CheckOutlined,
  DownOutlined,
  EyeOutlined,
  SelectOutlined,
  QuestionCircleOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Avatar,
  Badge,
  Button,
  Col,
  Divider,
  Drawer,
  Dropdown,
  Empty,
  Layout,
  Menu,
  Modal,
  Popover,
  Row,
  Space,
  Typography,
} from "antd";
import { useTranslation } from "react-i18next";
import helpIcon from "../../../assets/imgs/help-icon.svg";
import { Link, useHistory, useLocation } from "react-router-dom";
import alignLeft from "../../../assets/imgs/align-left.svg";
import shopsIcon from "../../../assets/imgs/layout/shops.svg";
import smallShopsIcon from "../../../assets/imgs/layout/small-shops.svg";
import helpIcon1 from "../../../assets/imgs/help-center/icon1.svg";
import helpIcon2 from "../../../assets/imgs/help-center/icon2.svg";
import helpIcon3 from "../../../assets/imgs/help-center/icon3.svg";
import helpIcon4 from "../../../assets/imgs/help-center/icon4.svg";
import bill from "../../../assets/imgs/bill.svg";
import logImg from "../../../assets/imgs/log-img.svg";
import logo from "../../../assets/imgs/NewUpdatedLogo.svg";
import notificationImg from "../../../assets/imgs/notification.svg";
import star from "../../../assets/imgs/star.svg";
import user from "../../../assets/imgs/user.svg";
import { useResponsive } from "../../../lib/useResponsive";
import { varDomain } from "../../../modules/common/defaults";
import { getHasPermission } from "../../../utlis/getHasPermission";
import { CURRENT_USER } from "../../../utlis/Me";
import { isAdmin } from "../../../utlis/permission";
import { routes } from "../../../utlis/routes";
import MyImage from "../../common/image";
import AdminNotifications from "../sider/notifications/adminNotifications";
import InventoryNotifications from "../sider/notifications/inventoryNotifications";
import {
  AdvancedContainer,
  GoldContainer,
  LogoContainer,
  MdLogo,
  SilverContainer,
  UpgradeContainer,
  IntrialContainer,
} from "../sider/sider.styles";
import { changeShop } from "../../../utlis/changeShop";
import { SIGN_OUT } from "../sider";
import i18n from "../../../i18n";
import moment from "moment";
import styled from "styled-components";
import { authMutation } from "../../../modules/auth/resolvers";
import AnnoncementBarComponent from "./annoncementBar";
import Notifications from "../sider/notifications";
import { useState } from "react";
import wallet from "../../../assets/imgs/wallet.svg";

import Paragraph from "antd/lib/skeleton/Paragraph";
import HelpDrawerComp from "./cards/help";
const { SubMenu } = Menu;
const { Text, Title } = Typography;
const { Header, Sider } = Layout;
const changeLanguageHandler = () => i18n.changeLanguage(i18n.language === "ar" ? "en" : "ar");

const getNotifications = (role) => {
  switch (role) {
    case "SUPER_ADMIN":
    case "ADMIN":
    case "VENDOR":
      return (
        <>
          <InventoryNotifications isAdmin />
          <AdminNotifications />
        </>
      );
    case "INVENTORY":
      return <InventoryNotifications />;

    default:
      return <Empty description="No notifications" />;
  }
};
const CustomHeader = ({ me, children, setCollapsed, collapsed, setVisible }) => {
  const { xxl, md, sm, xs, lg } = useResponsive();

  const { data = { me: {} } } = useQuery(CURRENT_USER, {
    fetchPolicy: "cache-first",
  });
  console.log(
    "🚀 ~ file: index.js ~ line 201 ~ CustomHeader ~ data?.me?.currentShop?.subscripeWillEndAt",
    data?.me?.currentShop?.subscripeWillEndAt,
  );

  const [upgradeModal, setUpgradeModal] = useState();
  const daysLeft = moment(data?.me?.currentShop?.subscripeWillEndAt).diff(new Date(), "d") || 0;
  const planStatus = ({ plan }) => {
    switch (plan) {
      case "basic":
        return (
          <UpgradeContainer>
            <Button block={true} className="upgrade">
              <Link to={"/upgrade"}>
                {t("home.upgrade")} <MyImage src={star} width={15} />
              </Link>
            </Button>
          </UpgradeContainer>
        );
      case "advanced":
        return (
          <AdvancedContainer>
            <Button block={true} className="advanced" type="link">
              <Link to={"/upgrade"}>{t("plans.advancedPlan")}</Link>
            </Button>
          </AdvancedContainer>
        );
      case "silver":
        return (
          <SilverContainer>
            <Button block={true} className="silver">
              <Link to={"/upgrade"}>
                {t("plans.silverPlan")} <MyImage src={star} width={15} />
              </Link>
            </Button>
          </SilverContainer>
        );
      case "gold":
        return (
          <GoldContainer>
            <Button block={true} className="gold" type="text">
              <Link to={"/upgrade"}>{t("plans.goldPlan")}</Link>
            </Button>
          </GoldContainer>
        );
      // case "trial":
      //   return daysLeft ? (
      //     <IntrialContainer>
      //       <Button
      //         block={true}
      //         danger={daysLeft <= 4}
      //         className={daysLeft > 4 && "upgrade"}
      //         type="ghost">
      //         <Link to={"/upgrade"}>{`متبقي لك ${daysLeft} ايام من النسخه التجريبية`}</Link>
      //       </Button>
      //     </IntrialContainer>
      //   ) : null;
      // case "payAsUGo":
      //   return daysLeft ? (
      //     <IntrialContainer>
      //       <Button
      //         block={true}
      //         danger={daysLeft <= 4}
      //         className={daysLeft > 4 && "upgrade"}
      //         type="ghost">
      //         <Link to={"/upgrade"}>{`متبقي لك ${daysLeft} ايام من النسخه التجريبية`}</Link>
      //       </Button>
      //     </IntrialContainer>
      //   ) : null;
      case "payAsUGo":
        return (
          <UpgradeContainer>
            <Button className="upgrade">
              <Link to={"/upgrade"}>
                {"Pay As You Go"} <MyImage src={star} width={15} />
              </Link>
            </Button>
          </UpgradeContainer>
        );
      case "payOnce":
        return (
          <UpgradeContainer>
            <Button style={{ background: "#004EBF" }} className="upgrade">
              <Link to={"/upgrade"}>
                {"Pay Once"} <MyImage src={star} width={15} />
              </Link>
            </Button>
          </UpgradeContainer>
        );

      default:
        return (
          <UpgradeContainer>
            <Button
              // onClick={() => {
              //   Modal.confirm({
              //     okText: "موافق",
              //     cancelText: "الغاء",
              //     onOk: () => {},
              //     title: "سيتم الترقيه الي باقة ادفع مره",
              //     // icon: <MyImage src={star} width={15} />,
              //     // content: (
              //     //   <>
              //     //      </>
              //     // ),
              //   });
              //   //  setUpgradeModal(true)
              // }}
              block={true}
              className="upgrade">
              <Link to={"/upgrade"}>
                {t("home.upgrade")} <MyImage src={star} width={15} />
              </Link>
            </Button>
          </UpgradeContainer>
        );
    }
  };

  const [signOut] = useMutation(SIGN_OUT, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: CURRENT_USER }],
  });

  const history = useHistory();
  const { t, i18n } = useTranslation();
  console.log("🚀 ~ file: index.js ~ line 156 ~ CustomHeader ~ me.stores", me);

  const shopsMenu = (
    <Menu selectedKeys={[]}>
      {me.stores.map(({ id, domain, name }) => (
        <Menu.Item
          onClick={async () => {
            changeShop(domain);
          }}
          icon={
            varDomain() == domain ? (
              <CheckOutlined style={{ color: "#2CB87A" }} />
            ) : (
              <CheckOutlined style={{ opacity: 0 }} />
            )
          }>
          <Text style={{ margin: "0 10px", fontWeight: 600 }}>{name}</Text>
        </Menu.Item>
      ))}
    </Menu>
  );
  const menu = (
    <Menu selectedKeys={[]}>
      <Menu.Item style={{ cursor: "unset" }}>
        <Space direction="vertical" size={0} style={{ margin: "0 10px" }}>
          <Text style={{ fontWeight: 600, fontSize: 16 }}>{me?.name}</Text>
          <a
            href={`https://${me?.currentShop?.domain}`}
            target="_blank"
            style={{ fontSize: 12, wordBreak: "break-all" }}>
            {me?.currentShop?.domain}
            <SelectOutlined style={{ margin: "0 4px" }} />
          </a>
        </Space>
        <Divider style={{ margin: "6px 0" }} />
      </Menu.Item>
      <Menu.Item
        onClick={() => history.push("/myProfile")}
        icon={<MyImage src={user} width={12} />}>
        <Text style={{ margin: "0 10px", fontWeight: 600 }}>{t("home.profilPage")}</Text>
      </Menu.Item>
      <Menu.Item
        onClick={() => history.push("/shops")}
        icon={<MyImage width={16} src={smallShopsIcon} />}>
        <Text style={{ margin: "0 10px", fontWeight: 600 }}>{t("home.myStores")}</Text>
      </Menu.Item>
      <Menu.Item
        onClick={() => history.push("/addToBalance")}
        icon={<WalletOutlined style={{ fontSize: 15 }} />}>
        <Text style={{ fontWeight: 600 }}>{t("home.wallet")}</Text>
      </Menu.Item>
      <Menu.Item onClick={() => history.push("/invoices")} icon={<MyImage src={bill} width={16} />}>
        <Text style={{ margin: "0 10px", fontWeight: 600 }}>{t("home.subscAndInvoices")}</Text>
      </Menu.Item>
      {/* <Menu.Item onClick={changeLanguageHandler}>
          <LanguageChanger disable />
        </Menu.Item> */}
      <Menu.Item
        onClick={() =>
          signOut().then(() => {
            authMutation.signOut();
            // client.cache.reset();
            // history.push("/signin");
          })
        }
        danger
        icon={<MyImage src={logImg} width={12} />}>
        {t("home.logout")}
      </Menu.Item>
    </Menu>
  );

  const userOptions = (
    <>
      <Space align="center">
        <Button block={true} type="primary" className="view-store-boarding">
          <a href={"https://" + me.currentShop?.domain} rel="noreferrer" target="_blank">
            {t("home.showStore")} <EyeOutlined />
          </a>
        </Button>
        {/* {planStatus({ plan: me.currentShop?.isInTrial ? "" : data?.me?.currentShop?.plan?.slug })} */}
        {planStatus({ plan: data?.me?.currentShop?.plan?.slug })}
        {/* {planStatus({ plan: "trial" })} */}
        <Button type="primary" ghost onClick={() => history.push("/addToBalance")}>
          <Space align="center">
            {t("home.myBalance")}
            {Math.round((me?.currentShop?.balance + Number.EPSILON) * 100) / 100}&#36;
          </Space>
        </Button>
      </Space>
    </>
  );

  const showDrawer = () => {
    setVisible(true);
  };

  const showSider = () => {
    setCollapsed(!collapsed);
  };

  const [drawerVisible, setDrawervisible] = useState(false);
  const showHelpDrawer = () => {
    setDrawervisible(true);
  };
  const onClose = () => {
    setDrawervisible(false);
  };

  return (
    <>
      {/* <Modal visible={upgradeModal} onCancel={()=>setUpgradeModal(false)}>


    </Modal> */}
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            padding: 0,
            boxShadow: "0px 4px 12px rgba(51, 51, 51, 0.03)",
          }}>
          <Row align="middle" justify="space-between" style={{ padding: "0 40px " }}>
            <Col>
              <Row>
                <Space
                  align="center"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}>
                  <div
                    className="trigger"
                    style={{
                      marginLeft: i18n.language == "en" ? (collapsed ? 0 : 200) : "unset",
                      marginRight: i18n.language == "ar" ? (collapsed ? 0 : 200) : "unset",
                      transform: i18n.language == "en" ? "scaleX(-1)" : "unset",
                      cursor: "pointer",
                    }}
                    onClick={lg ? showSider : showDrawer}>
                    {/* <Space
                        align="center"
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          height: "100%",
                          backgroundColor: "red",
                        }}> */}
                    <div style={{ display: "flex" }}>
                      {collapsed ? (
                        <MyImage src={alignLeft} width={24} />
                      ) : (
                        <MyImage src={alignLeft} width={24} />
                      )}
                    </div>
                    {/* </Space> */}
                  </div>
                  {lg && userOptions}
                </Space>
              </Row>

              {/* {React.createElement(
                  collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                  collapsed ? (
                    <MyImage src={alignLeft} width={24} />
                  ) : (
                    <MyImage src={alignLeft} width={24} />
                  ),
                  {
                    className: "trigger",
                    style: {
                      marginLeft:
                        language == "en"
                          ? collapsed
                            ? 0
                            : 200
                          : "unset",
                      marginRight:
                        language == "ar"
                          ? collapsed
                            ? 0
                            : 200
                          : "unset",
                      transform:
                        language == "ar" ? "scaleX(-1)" : "unset",
                    },
                    onClick: lg ? showSider : showDrawer,
                  },
                )} */}
            </Col>

            {!lg && (
              <Col>
                <LogoContainer>
                  <Link to={"/"}>
                    <MdLogo
                      collapsed={collapsed}
                      src={
                        logo
                        // collapsed
                        //   ? logo
                        //   : logo
                      }
                      alt="logo"
                    />
                  </Link>
                </LogoContainer>
              </Col>
            )}

            <Col>
              <Space
                align="center"
                style={{
                  justifyContent: "center",
                }}>
                {/* {lg && userOptions} */}
                {/* <LanguageChanger2
                      icon={<GlobalOutlined />}
                      type="text"
                      onClick={changeLanguageHandler}>
                      {i18n.language === "ar" ? "EN" : "العربية"}
                    </LanguageChanger2> */}
                {me.stores.length > 1 && (
                  <div>
                    <Dropdown
                      overlay={shopsMenu}
                      trigger={["click"]}
                      overlayClassName="user-dropdown"
                      arrow={true}
                      overlayStyle={{ width: 200, height: 140 }}>
                      <Button type="text" style={{ height: "unset" }}>
                        <Space align="center" size={"small"}>
                          <DownOutlined style={{ fontSize: 10 }} />
                          <MyImage icon="xs" src={shopsIcon} />
                        </Space>
                      </Button>
                    </Dropdown>
                  </div>
                )}
                {/* <Popover
                  content={() => getNotifications(me.role)}
                  placement="bottomRight"
                  trigger={["click"]}
                  title="Notifications">
                  <div style={{ display: "flex" }}>
                    <Badge dot>
                      <BellOutlined onClick={() => {}} />
                      <MyImage src={notificationImg} width={15} />
                    </Badge>
                  </div>
                </Popover> */}
                <Notifications />
                {/* <Notifications /> */}
                {/* <QuestionCircleOutlined style={{ fontSize: "23px" }} /> */}
                <HelpDrawerComp />
                {lg && (
                  <div className="admin-boarding">
                    <Dropdown
                      overlay={menu}
                      trigger={["click"]}
                      overlayClassName="user-dropdown"
                      arrow={true}
                      overlayStyle={{ width: 200, height: 140 }}>
                      <Button type="text" style={{ height: "unset" }}>
                        <Space>
                          <Avatar src={me?.avatar?.sm}>
                            {me.name?.split(" ")[0]?.charAt(0).toUpperCase()}
                            {me.name?.split(" ")[1]?.charAt(0).toUpperCase()}
                          </Avatar>
                          {/* {me.name} */}
                        </Space>
                      </Button>
                    </Dropdown>
                  </div>
                )}
              </Space>
            </Col>
          </Row>
        </Header>
        <div
          style={{
            marginRight: i18n.language == "ar" ? (collapsed ? 0 : 180) : "unset",
            marginLeft: i18n.language == "en" ? (collapsed ? 0 : 180) : "unset",
          }}>
          {children}
        </div>
      </Layout>
    </>
  );
};

const HelpDrawer = styled.div`
  .help-container {
    display: flex;
    align-items: center;
    padding: 13px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    gap: 14px;
    margin-bottom: 20px;
    cursor: pointer;
  }
  .help-title {
    font-size: 18px;
    font-weight: 600;
    display: block;
  }
  .help-desc {
    font-size: 12px;
    color: #828282;
  }
`;
export default CustomHeader;

const helpData = [
  {
    title: "تذاكر الدعم",
    desc: "إذا كنت تواجه أي مشكلة أرسل لنا وسنقوم بحلها بأسرع وقت",
    id: 1,
    src: helpIcon1,
  },
  {
    title: "إقترح ميزة جديدة",
    desc: "ساعدنا في تحسين المنصة من خلال اقتراح ميزات جديدة",
    id: 2,
    src: helpIcon2,
  },
  {
    title: "إعادة تعيين المرشد التوجيهي",
    desc: "إعادة عرض الخطوات الإرشادية للميزات الموجودة في بحر",
    id: 3,
    src: helpIcon3,
  },
  {
    title: "مركز المساعدة",
    desc: "إذا كانت لديك استفسارات او اسئلة ",
    id: 4,
    src: helpIcon4,
    onClick: () => {
      window.open("https://www.b7r.store/helpCenter", "_blank");
    },
  },
];
