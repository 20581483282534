// This is client side config only - don't put anything in here that shouldn't be public!

export const endpoint = `http://localhost:4000/graphql`;
// export const endpoint = `https://dev.b7r.store/graphql`;

// export const prodEndpoint = `https://www.b7r.store/graphql`;
// export const prodEndpoint = `https://www.wateen.b7r.store/graphql`;
export const stageEndpoint = `https://api.b7r.live/graphql`;

export const prodEndpoint = `${process.env.REACT_APP_DOMAIN || "https://api.b7r.store"}/graphql`;

export const perPage = "kvnS1zgCQ@yEQKhHsJXV";

//export const endpointSub = `ws://localhost:4000/graphql`;

export const endpointSub = endpoint.replace("https:", "wss:");
export const stageEndpointSub = `wss://api.b7r.live/graphql`;

export const prodEndpointSub = `wss://api.b7r.store/graphql`;
