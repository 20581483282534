import { makeVar, useLazyQuery, useQuery, useReactiveVar, useSubscription } from "@apollo/client";
import { Col, Row, Spin } from "antd";
import qs from "qs";
import { useEffect, useState } from "react";
import "react-chat-elements/dist/main.css";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import Loading from "../../../components/common/Loading";
import { makeArrayUniqe } from "../../../lib/makeArrayUniqe";
import { S_NEW_MESSAGE } from "../../../modules/common/subscriptions";
import { Room as RoomType } from "../../../types";
import Messages from "./messages/index";
import NoSelectedRoom from "./noSelectedRoom";
import { GET_ROOMS } from "./queries";
import Rooms from "./rooms/index";
const takeNum = 15;

export const varRoomsState = makeVar([]);

const MyChat = () => {
  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useState("");
  const roomsState = useReactiveVar(varRoomsState);
  console.log("🚀 ~ file: index.tsx ~ line 24 ~ MyChat ~ roomsState 12112", roomsState);
  const setRooms = varRoomsState;
  const [skip, setSkip] = useState(0);
  console.log("🚀 ~ file: index.tsx ~ line 27 ~ MyChat ~ skip", skip);

  // const [currentRoomSlug, setCurrentRoomSlug] = useState(null);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [showMessages, setShowMessages] = useState(false);
  // const [currentRoom, setCurrentRoom] = useState<null | RoomType>(null);
  // const { data: subNewMessageData } = useSubscription(S_NEW_MESSAGE);
  // const [isEmpty, setIsEmpty] = useState(false);
  // const [isFirstLoaded, setIsFirstLoaded] = useState(false);
  let { path } = useRouteMatch();

  // const [roomsState, setRooms] = useState(varRoomsState());

  const [filterdRoomsState, setFilterdRooms] = useState([]);

  const { push, location, replace } = useHistory();
  // @ts-ignore
  const queries: { skip: string } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const searchQuery = {
    messages: search.trim()
      ? {
          some: {
            OR: [
              {
                user: {
                  name: {
                    contains: search.trim(),
                    mode: "insensitive",
                  },
                },
              },
              {
                message: {
                  contains: search.trim(),
                  mode: "insensitive",
                },
              },
            ],
          },
        }
      : undefined,
  };
  const variables = {
    where: searchQuery,
    take: takeNum,
    skip: skip * takeNum,
  };
  const { networkStatus, data, loading, fetchMore, client, refetch } = useQuery(GET_ROOMS, {
    variables,
    fetchPolicy: "cache-first",
    // notifyOnNetworkStatusChange: true,
    onCompleted({ rooms }) {
      console.log("🚀 ~ file: index.tsx ~ line 77 ~ onCompleted ~ rooms", rooms);
      if (searchQuery.messages) return setFilterdRooms(rooms?.nodes);
      if (!roomsState.some((r) => rooms?.nodes[0].slug == r.slug)) {
        setRooms(makeArrayUniqe([...roomsState, ...rooms?.nodes], "slug", "lastMessage.createdAt"));
      }
      setLoadMoreLoading(false);
      // if (!isFirstLoaded) if (!rooms?.count) setIsEmpty(true);
      // !isFirstLoaded && setIsFirstLoaded(true);
    },
  });
  console.log("🚀 ~ file: index.tsx ~ line 85 ~ MyChat ~ networkStatus", networkStatus);

  // const [getRooms, { networkStatus, data, loading, fetchMore, client, refetch }] = useLazyQuery(
  //   GET_ROOMS,
  //   {
  //     onCompleted({ rooms }) {
  //       if (!isFirstLoaded) if (!rooms?.count) setIsEmpty(true);
  //       !isFirstLoaded && setIsFirstLoaded(true);
  //     },
  //   },
  // );

  // useEffect(() => {
  //   const logData = data?.rooms
  //     ? (data?.rooms?.nodes as any[]).filter((room) => {
  //         return room.roomName;
  //       })
  //     : [];
  //   console.log("🚀 ~ file: index.tsx ~ line 54 ~ useEffect ~ logData", logData);
  //   setLoadMoreLoading(false);
  // }, [data]);

  const onSearch = () => {
    // replace({
    //   pathname: "/chat",
    //   search: `?skip=${1}`,
    // });
    setSkip(0);
    setSearch(searchText);
    // getRooms({
    //   variables: {
    //     where: search.trim() ? searchQuery : undefined,
    //     take: 30,
    //     skip: 0,
    //   },
    // });
  };
  // useEffect(() => {
  //   console.log(
  //     "🚀 ~ file: index.tsx ~ line 84 ~ useEffect ~ subNewMessageData",
  //     subNewMessageData,
  //   );

  //   if (!subNewMessageData?.newMessageInRooms) return;
  //   const rooms = data?.rooms?.nodes;
  //   const isRoomFound = rooms.find(
  //     (room) => room.id === subNewMessageData?.newMessageInRooms.room.id,
  //   );
  //   const vars = {
  //     where: search.trim() ? searchQuery : undefined,
  //     take: takeNum,
  //     skip: (parseInt(queries?.skip) || 0) * takeNum,
  //   };
  //   if (isRoomFound) return;
  //   const reData = client.readQuery({
  //     query: GET_ROOMS,
  //     variables: vars,
  //   });
  //   if (!reData) refetch();
  //   client.writeQuery({
  //     data: {
  //       rooms: {
  //         ...reData,
  //         nodes: [{ ...subNewMessageData?.newMessageInRooms.room }, ...reData.rooms.nodes],
  //       },
  //     },
  //     query: GET_ROOMS,
  //     variables: vars,
  //   });
  // }, [subNewMessageData]);

  const LoadMoreRooms = () => {
    // const skip = parseInt(queries?.skip) + 1 || 1;
    setSkip((skip) => skip + 1);

    // const skipNum = skip * takeNum;
    // replace({
    //   pathname: location.pathname,
    //   search: `?skip=${skip}`,
    // });
    setLoadMoreLoading(true);
    // fetchMore({
    //   variables: {
    //     ...variables,
    //     take: takeNum,
    //     skip: skipNum,
    //   },
    // });
  };
  // const loadMoreData = () => {
  //   console.log("🚀 ~ file: notificationTab.tsx ~ line 63 ~ loadMoreData ~ loadMoreData");

  //   !loading &&
  //     fetchMore({
  //       variables: {
  //         skip: (page + 1) * 20,
  //       },
  //     }).then(({ data }) => {
  //       setpage(page + 1);
  //       setNotifications([...notifications, ...data.b7rNotifications.nodes]);
  //     });
  // };

  // useEffect(() => {
  //   // client.cache.evict({ id: "GET_ROOMS", fieldName: "rooms" });
  //   const skipNum = parseInt(queries?.skip);
  //   replace({
  //     pathname: location.pathname,
  //     search: `?skip=${skipNum || 1}`,
  //   });
  //   getRooms({
  //     variables: {
  //       where: undefined,
  //       take: skipNum * takeNum || takeNum,
  //       skip: 0,
  //     },
  //   });
  // }, []);

  // useEffect(() => {
  //   currentRoomSlug &&
  //     data?.rooms &&
  //     setCurrentRoom(data?.rooms.nodes.find(({ slug }) => slug == currentRoomSlug));
  // }, [currentRoomSlug, data]);

  const toggleMessage = () => {
    setShowMessages(!showMessages);
  };
  // useEffect(() => {
  //   const path = location.pathname;
  //   if (path === "/chat" || path === "/chat/") setShowMessages(false);
  // }, [location]);
  // useEffect(() => {
  //   if (!currentRoom?.notSeenNum) return;
  //   const vars = {
  //     where: search.trim() ? searchQuery : undefined,
  //     take: takeNum,
  //     skip: (parseInt(queries?.skip) || 0) * takeNum,
  //   };
  //   const reData = client.readQuery({
  //     query: GET_ROOMS,
  //     variables: vars,
  //   });
  //   console.log("🚀 ~ file: index.tsx ~ line 176 ~ useEffect ~ reData", reData);

  //   console.log("🚀 ~ file: index.tsx ~ line 172 ~ useEffect ~ vars", vars);

  //   if (!reData) return refetch();
  //   const newNodes = reData.rooms?.nodes?.map((room: RoomType) => {
  //     if (room.id === currentRoom.id) return { ...room, notSeenNum: 0 };
  //     return room;
  //   });
  //   client.writeQuery({
  //     data: {
  //       rooms: {
  //         ...reData,
  //         nodes: [...newNodes],
  //       },
  //     },
  //     query: GET_ROOMS,
  //     variables: vars,
  //   });
  //   console.log("🚀 ~ file: index.tsx ~ line 154 ~ MyChat ~ currentRoom", currentRoom);
  // }, [currentRoom]);
  const showLoadMore =
    !loading && data?.rooms?.count > (parseInt(queries?.skip) + 1 || 1) * takeNum;
  if (loading && !roomsState.length) return <Loading />;
  const finalRooms = search ? filterdRoomsState : roomsState;
  console.log("🚀 ~ file: index.tsx ~ line 254 ~ MyChat ~ roomsState", roomsState);
  const roomsComp = (
    <Col xl={7} md={{ span: 10 }} xs={{ span: !showMessages ? 24 : 0 }}>
      <Rooms
        loadMoreLoading={loadMoreLoading}
        showMessage={toggleMessage}
        loading={loading}
        networkStatus={networkStatus}
        // setCurrentRoom={(room) =>
        //   push(`${path}/${room.slug}${queries?.skip ? "?skip=" + queries?.skip : ""}`)
        // }
        setCurrentRoom={(room) => push(`${path}/${room.slug}`)}
        // rooms={data?.rooms || {}}
        rooms={{ ...data?.rooms, nodes: finalRooms }}
        refetch={refetch}
        // currentRoom={currentRoom}
        onSearch={onSearch}
        search={searchText}
        setSearch={setSearchText}
        increaseRoomsSkip={LoadMoreRooms}
        showLoadMore={showLoadMore}
      />
    </Col>
  );
  return (
    <Container>
      {!roomsState.length ? (
        <NoSelectedRoom noRooms={!data?.rooms?.nodes?.length} />
      ) : (
        <Row>
          <Switch>
            <Route exact path={path}>
              {roomsComp}
              <Col xl={17} md={{ span: 14 }} xs={{ span: showMessages ? 24 : 0 }}>
                <NoSelectedRoom noRooms={!data?.rooms?.nodes?.length} />
              </Col>
            </Route>
            <Route path={`${path}/:roomSlug`}>
              {roomsComp}

              <Col xl={17} md={{ span: 14 }} xs={{ span: showMessages ? 24 : 0 }}>
                <Messages
                  // CurrentRoomMessages={currentRoom}
                  // setCurrentRoomSlug={setCurrentRoomSlug}
                  // subNewMessageData={subNewMessageData}
                  backToChat={toggleMessage}
                />
              </Col>
            </Route>
          </Switch>
        </Row>
      )}
    </Container>
  );
};

const Container = styled.div``;

export default MyChat;
