import React, { useState, useMemo, useEffect } from "react";
import makeSlug from "slug-arabic"; // by B7r :)

import {
  Form,
  Input,
  Select,
  Upload,
  Button,
  InputNumber,
  Divider,
  Row,
  Col,
  Checkbox,
  Switch,
  TreeSelect,
  Modal,
  Space,
  Typography,
  Spin,
} from "antd";
import ColorPicker from "rc-color-picker";
// import { validateMessages } from "../../../products/addEdit/add";
import { gql, useMutation } from "@apollo/client";
import { OPTIONS } from "../";
import { OPTIONS_VALUES } from "../";
import ChooseImg from "../../../products/addEdit/cards/chooseImage";
import { useTranslation } from "react-i18next";
import { LabelContainer } from "./style";
import { AppLanguageSwitch } from "../../../../../components/common/websiteLanguage";
import styled from "styled-components";
import i18next from "i18next";
import { Breakpoints } from "../../../../../constants";
import SuccessNotification from "../../../../../components/common/successNotification";
export const ADD_ATTR_VALUE = gql`
  mutation ADD_ATTR_VALUE($slug: String!, $value: String, $name: MultiLangText!, $attrId: Int!) {
    createOneOptionValue(data: { name: $name, slug: $slug, value: $value, attrId: $attrId }) {
      id
      name
      slug
      mName {
        id
        ar
        en
      }
      value
    }
  }
`;

export const EDIT_ATTR_VALUE = gql`
  mutation EDIT_ATTR_VALUE(
    $id: Int!
    $value: String
    $name: MultiLangText!
    $attrId: Int!
    $slug: String!
  ) {
    updateOneOptionValue(
      where: { id: $id }
      data: { name: $name, slug: $slug, value: $value, attrId: $attrId }
    ) {
      id
      name
      mName {
        id
        ar
        en
      }
      value
      slug
    }
  }
`;

export const ADD_ATTR = gql`
  mutation ADD_ATTR($type: String!, $name: MultiLangText!, $slug: String!) {
    createOneAttribute(data: { name: $name, type: $type, slug: $slug }) {
      id
      name
      type
      mName {
        id
        ar
        en
      }
      slug
    }
  }
`;
export const EDIT_ATTR = gql`
  mutation EDIT_ATTR($id: Int!, $name: MultiLangText!, $type: String!, $slug: String!) {
    updateOneAttribute(where: { id: $id }, data: { name: $name, type: $type, slug: $slug }) {
      id
      name
      type
      mName {
        id
        ar
        en
      }
      slug
    }
  }
`;
const { Option } = Select;
/**
 * renderComponent
 * @component
 * @name addCompanyModal
 * @memberof PRODUCTS
 * @summary admin can add company
 * @return {React} React component view
 */

const attrTypes = ["text", "image", "color"];

const EditModal = ({
  editableRecord,
  handleCancel,
  visible,
  isAttr,
  attr,
  variables,
  optionsValuesQuery,
}) => {
  console.log("🚀 ~ file: index.js ~ line 128 ~ attr", attr);

  const [editValue, editValueProps] = useMutation(EDIT_ATTR_VALUE, {
    update(cache, { data: { updateOneOptionValue } }) {
      const { optionValues } = cache.readQuery({
        query: OPTIONS_VALUES,
        variables,
      });
      cache.writeQuery({
        query: OPTIONS_VALUES,
        variables,
        data: {
          optionValues: optionValues.map((attr) =>
            attr.id === updateOneOptionValue.id ? updateOneOptionValue : attr,
          ),
        },
      });
    },
    onCompleted() {
      SuccessNotification({
        defaultTitle: t("products.successDefaultEditMessage"),
        title: t("products.successDefaultEditMessage"),
        type: "success",
      });
    },
  });
  const [addValue, addValueProps] = useMutation(ADD_ATTR_VALUE, {
    update(cache, { data: { createOneOptionValue } }) {
      if (variables) {
        const { optionValues } = cache.readQuery({
          query: OPTIONS_VALUES,
          variables,
        });
        cache.writeQuery({
          query: OPTIONS_VALUES,
          variables,
          data: {
            optionValues: [...optionValues, createOneOptionValue],
          },
        });
      }
    },
    onCompleted() {
      SuccessNotification({
        defaultTitle: t("products.successDefaultMessage"),
        title: t("products.successDefaultMessage"),
        type: "success",
      });
    },
  });
  const [editAttr, editAttrProps] = useMutation(EDIT_ATTR, {
    update(cache, { data: { updateOneAttribute } }) {
      const { attributes } = cache.readQuery({
        query: OPTIONS,
        variables: variables,
      });
      cache.writeQuery({
        query: OPTIONS,
        variables: variables,

        data: {
          attributes: {
            count: attributes.count,
            nodes: attributes.nodes.map((attr) =>
              attr.id === updateOneAttribute.id ? updateOneAttribute : attr,
            ),
          },
        },
      });
    },
    onCompleted() {
      SuccessNotification({
        defaultTitle: t("products.successDefaultEditMessage"),
        title: t("products.successDefaultEditMessage"),
        type: "success",
      });
    },
  });
  const [addAttr, addAttrProps] = useMutation(ADD_ATTR, {
    update(cache, { data: { createOneAttribute } }) {
      const { attributes } = cache.readQuery({
        query: OPTIONS,
        variables: variables,
      });
      cache.writeQuery({
        query: OPTIONS,
        variables: variables,

        data: {
          attributes: {
            count: attributes.count + 1,
            nodes: [...attributes.nodes, createOneAttribute],
          },
        },
      });
    },
    onCompleted() {
      SuccessNotification({
        defaultTitle: t("products.successDefaultMessage"),
        title: t("products.successDefaultMessage"),
        type: "success",
      });
    },
  });
  const [isEn, setIsEn] = useState();
  const [optionValue, setOptionValue] = useState(null);
  const handleChange = () => {
    setIsEn(!isEn);
  };
  const [newImage, setNewImage] = useState(null);
  const [currentColor, setCurrentColor] = useState("#000");

  const getOptionVal = ({ type }) => {
    console.log("🚀 ~ file: index.js ~ line 179 ~ getOptionVal ~ type", type);
    switch (type) {
      case "color":
        return (
          <ColorPicker
            placement="topLeft"
            color={attr?.optionValue?.value ? attr?.optionValue?.value : currentColor.color}
            style={{ zIndex: 9999 }}
            onChange={(color) => {
              console.log("color", color);
              form.setFieldsValue({ value: color.color });

              setCurrentColor(color);
            }}
            //onClose={({ color: value }) => setOptionValue(value)}
          >
            <span className="rc-color-picker-trigger" />
          </ColorPicker>
        );
      case "image":
        return (
          <ChooseImg
            setChossingImage={(file) => {
              console.log("🚀 ~ file: getImage.js ~ line 133 ~ GetImage ~ file", file);
              form.setFieldsValue({ value: file.url });
              setNewImage(file);
            }}
            defaultImage={
              newImage ? newImage : editableRecord?.value && { url: editableRecord?.value }
            }
            // addImage={addMainImage}
            // visible,
            // setVisible,
            // firstImages,
          />
        );

      default:
        return null;
    }
  };
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const validateMessages = {
    required: t("customers.required"),
  };
  useEffect(() => {
    if (editableRecord) {
      console.log("🚀 ~ file: index.js ~ line 201 ~ useEffect ~ editableRecord", editableRecord);
      form.setFieldsValue(editableRecord);
      setCurrentColor({ color: editableRecord.value });
      // setName(editableRecord.name);
      // setType(editableRecord.type);
      // setSlug(editableRecord.slug);
    }
  }, [editableRecord]);
  const onFinish = async (values) => {
    console.log("🚀 ~ file: index.js ~ line 212 ~ onFinish ~ values", values);
    values = {
      ...values,
      name: form.getFieldValue("name"),

      // value: values.value?.color,
    };
    // setVisible(false);
    if (editableRecord) {
      isAttr
        ? await editAttr({
            variables: { id: editableRecord.id, ...values },
          })
        : await editValue({
            variables: { id: editableRecord.id, attrId: attr.id, ...values },
          });
    } else {
      isAttr
        ? await addAttr({ variables: { ...values } })
        : await addValue({ variables: { ...values, attrId: attr.id } });
    }
    handleCancel();

    form.resetFields();
    setNewImage(null);
    setCurrentColor("#f00");

    // setVisible(false);
    // handleOk().then(() => {
    //   // setType("text");
    //   // setName("");
    //   // setSlug("");
    // });
  };
  const [slug, setSlug] = useState("");
  const [firstLangLoad, setFirstLangLoad] = useState(false);
  const getSlug = () => {
    form.setFieldsValue({
      slug: makeSlug(slug),
    });
  };

  return (
    <Form
      validateMessages={validateMessages}
      onFinish={onFinish}
      form={form}
      labelCol={{ span: 24 }}
      labelAlign="left"
      initialValues={{
        name: {
          ar: "",
          en: "",
        },
        slug: "",
        type: "text",
        value: "#F00",
      }}
      onValuesChange={(val) => {
        console.log("editProduct -> val", val);
        console.log("editProduct -> valuess", form.getFieldsValue());
        val.value && form.setFieldsValue({ value: val.value.color });
        val.name?.ar && setSlug(val.name.ar);
        val.name?.en && setSlug(val.name.en);

        val.slug && setSlug(val.slug);
      }}>
      <Modal
        width={468}
        className="variant-model"
        title={
          editableRecord
            ? ` ${t("settings.edit")} ${editableRecord?.name.en || editableRecord?.name.ar}`
            : ` ${t("products.add")} ${attr?.name ? `${attr?.name}` : ""} ${t("products.new")}`
        }
        visible={visible}
        onOk={() => form.submit()}
        okText={editableRecord ? t("settings.save") : t("settings.add")}
        cancelText={t("order.cancel")}
        okButtonProps={{
          loading:
            addAttrProps.loading ||
            addValueProps.loading ||
            editAttrProps.loading ||
            editValueProps.loading,
        }}
        onCancel={() => {
          handleCancel();
          form.resetFields();

          // setName("");
          // setType("text");
        }}>
        <AppLanguageSwitchContainer language={i18next.language}>
          <AppLanguageSwitch
            input={true}
            isEn={isEn}
            setIsEn={setIsEn}
            firstLangLoad={firstLangLoad}
            setFirstLangLoad={setFirstLangLoad}
          />
          {!firstLangLoad ? (
            <Spin
              style={{
                position: "absolute",
                marginTop: "50px",
                width: "100%",
                height: "100%",
                zIndex: 999,
                background: "white",
              }}
            />
          ) : isEn ? (
            <>
              <Form.Item
                label={`${attr?.name ? `${attr?.name}` : `${t("products.Name")}`}`}
                // label={t("products.Name")}
                name={["name", "en"]}
                rules={[{ required: true }]}
                onBlur={getSlug}>
                <Input className="input-switch" style={{ zIndex: 10 }} />
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item
                label="اسم"
                name={["name", "ar"]}
                rules={[{ required: true }]}
                onBlur={getSlug}>
                <Input className="input-switch" style={{ zIndex: 10 }} />
              </Form.Item>
            </>
          )}
        </AppLanguageSwitchContainer>

        {/* <Form.Item name={"name"} label={"Name"}>
          <Input onChange={(e) => setName(e.target.value)} value={name} />
        </Form.Item> */}
        <Form.Item name={"slug"} rules={[{ required: true }]} label={t("products.slug")}>
          <Input
            style={{ marginBottom: 20 }}
            // onChange={(e) => setSlug(e.target.value)}
            // value={slug}
            onBlur={getSlug}
            disabled
          />
        </Form.Item>

        {isAttr && (
          <Form.Item name={"type"} label={t("products.selectType")}>
            <Select
              showSearch
              style={{ width: "100%" }}
              optionFilterProp="children"
              // onChange={(type) => setType(type)}
              // value={type}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }>
              {attrTypes.map((val) => (
                <Option key={val} value={val}>
                  {val}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item>
          {attr && getOptionVal(attr) && (
            <Form.Item name={"value"} label={t("products.value")} valuePropName="color">
              <div style={{ margin: "0 20px" }}>{getOptionVal(attr)}</div>
            </Form.Item>
          )}
        </Form.Item>

        {/* {attr && getOptionVal(attr) && (
          <Form.Item name={"value"} label={t("products.value")} valuePropName="color">
            {getOptionVal(attr)}
          </Form.Item>
        )} */}
        <Form.Item
          style={{ visibility: "hidden" }}
          name={"slug"}
          rules={[{ required: true }]}
          label={t("products.slug")}>
          <Input
            style={{ marginBottom: 20 }}
            // onChange={(e) => setSlug(e.target.value)}
            // value={slug}
            onBlur={getSlug}
          />
        </Form.Item>
      </Modal>
    </Form>
  );
};

const AppLanguageSwitchContainer = styled.div`
  position: relative;
  padding: 0;

  .input-switch {
    @media (max-width: ${Breakpoints.sm}) {
      margin-top: 39px;
    }
  }
`;

export default EditModal;
