import React, { useState, useEffect, useMemo } from "react";

import { Select, Space, TreeSelect } from "antd";
import { gql, useQuery } from "@apollo/client";
import debounce from "lodash/debounce";
import { useTranslation } from "react-i18next";

const { Option } = Select;

export const TAGS = gql`
  query TAGS($where: TagWhereInput!) {
    tags(where: $where, take: 10) {
      nodes {
        id
        name
      }
    }
  }
`;
const TagsSelector = ({
  style = {},
  type = "PRODUCT",
  valueType = "name",
  mode = "tags",
  ...props
}) => {
  // console.log("🚀 ~ file: tagsSelectors.js ~ line 27 ~ props", props);
  const [search, setSearch] = useState("");
  const { t } = useTranslation();

  const { data, loading } = useQuery(TAGS, {
    // fetchPolicy: "cache-and-network",
    variables: {
      where: {
        type: { equals: type },
        id: props.value && valueType == "id" ? { notIn: props.value } : undefined,

        name: {
          contains: search,
          mode: "insensitive",
        },
      },
    },
  });
  const { loading: valuesLoading, ...valueRest } = useQuery(TAGS, {
    skip: valueType !== "id" || !props.value,
    variables: {
      where: {
        id: props.value ? { in: props.value } : undefined,
      },
    },
  });
  const valueData = valueRest.data ?? valueRest.previousData;

  return (
    <Select
      placeholder={t("products.tagsPlaceholder")}
      {...props}
      style={{ width: "100%", minWidth: 150, ...style }}
      onSearch={debounce((val) => setSearch(val), 200)}
      showSearch
      loading={loading || valuesLoading}
      mode={mode}
      filterOption={false}
      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}>
      {[...(valueData?.tags?.nodes || []), ...(data?.tags.nodes || [])].map((value) => (
        <Option key={value.id} value={valueType == "id" ? value.id : value.name}>
          {value.name}
        </Option>
      ))}
    </Select>
  );
};

export default React.memo(TagsSelector);
