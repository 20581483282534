import React, { useState, useEffect } from "react";
import {
  Form,
  Select,
  Button,
  InputNumber,
  Divider,
  Checkbox,
  Table,
  AutoComplete,
  Row,
  Col,
  Popconfirm,
} from "antd";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import {
  MinusCircleOutlined,
  PlusOutlined,
  DeleteOutlined,
  UndoOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { FormCard } from "../style";
import { OPTIONS_VALUES, OPTIONS } from "./variants";
import { useHistory } from "react-router-dom";
import debounce from "lodash/debounce";
import { QuantityItem } from "./quantityCard";
import { useTranslation } from "react-i18next";

const REMOVE_PRODUCT = gql`
  mutation REMOVE_PRODUCT($id: Int!) {
    deleteOneProduct(where: { id: $id }) {
      id
    }
  }
`;
/**
 * renderComponent
 * @component
 * @name addCompanyModal
 * @memberof PRODUCTS
 * @summary admin can add company
 * @return {React} React component view
 */
const { Option } = Select;

const EditVariantCard = ({
  setVariants,
  options,
  form,
  id,
  currency,

  variants,
}) => {
  console.log("🚀 ~ file: variantsEdit.js ~ line 264 ~ options", options);
  console.log("🚀 ~ file: variantsEdit.js ~ line 264 ~ variants", variants);
  // const optionsQuery = useQuery(OPTIONS);
  const [filter, setFilter] = useState("");
  const [attr, setAttr] = useState(null);
  const currencyCode = currency?.code;
  const code = currencyCode || "$";

  const [removeProduct] = useMutation(REMOVE_PRODUCT, {
    onCompleted: ({ deleteOneProduct }) => {
      setVariants(variants.filter(({ id }) => id !== deleteOneProduct.id));
      const variantsWithOutDeleted = form.getFieldValue("variants");
      delete variantsWithOutDeleted[deleteOneProduct.id];
      form.setFieldsValue({
        variants: variantsWithOutDeleted,
      });
    },
  });
  const search = debounce((val) => setFilter(val), 200);

  const { t } = useTranslation();

  const optionsValuesQuery = useQuery(OPTIONS_VALUES, {
    fetchPolicy: "network-only",
    skip: !attr,
    variables: {
      filter,
      attr,
    },
  });

  const route = useHistory();

  const columns = [
    {
      title: t("products.price"),
      render: (text, record) => {
        // if (
        //   !form.getFieldValue("variants") ||
        //   !form.getFieldValue("variants")[
        //     record.options.map((option) => Object.values(option)).toString()
        //   ]?.price
        // )
        //   form.setFieldsValue({
        //     variants: {
        //       ...form.getFieldValue("variants"),
        //       [record.options
        //         .map((option) => Object.values(option))
        //         .toString()]: { price: form.getFieldValue("price") },
        //     },
        //   });
        // if (record.valid)
        return (
          <Form.Item
            style={{
              marginBottom: "0",
            }}
            name={["variants", record.id.toString(), "price"]}>
            <InputNumber
              // onChange={val => {
              //   console.log("AddProduct -> val", val);

              //   let d = dataSource;
              //   console.log("AddProduct -> dataSource", dataSource[index].price);
              //   d[index].price = val;
              //   setDataSource(d);
              // }}
              // // defaultValue={form.getFieldValue("price")}
              // value={dataSource[index].price}
              formatter={(value) => `${code} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              parser={(value) => value.replace(/([A-z])|\$\s?|(,*)/g, "")}
              style={{
                width: 100,
              }}
            />
          </Form.Item>
        );
      },
    },
    // {
    //   title: "Quantity",
    //   render: (text, record) => {
    //     return (
    //       <QuantityItem
    //         inTable
    //         form={form}
    //         faildName={["variants", record.id.toString()]}
    //       />
    //       // <Form.Item
    //       //   style={{
    //       //     marginBottom: "0",
    //       //   }}
    //       //   name={}
    //       // >
    //       //   <InputNumber
    //       //     parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
    //       //     style={{
    //       //       width: 100,
    //       //     }}
    //       //   />
    //       // </Form.Item>
    //     );
    //   },
    // },
    {
      // fixed: "right",

      render: (text, record, index) => {
        if (variants?.length <= 1) return null;
        return (
          <Popconfirm
            title={t("products.undone")}
            onConfirm={async () => {
              await removeProduct({
                variables: { id: parseInt(record.id) },
              });
            }}
            okText={t("products.deleteVariant")}
            cancelText={t("products.cancel")}
            okType="danger">
            <Button icon={<DeleteOutlined />} danger />
          </Popconfirm>
        );
      },
    },
    {
      fixed: "right",

      render: (text, record, index) => {
        return (
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              route.push(`/product/${id}/variants/${record.id}`);
            }}
          />
        );
      },
    },
  ];

  // useEffect(() => {
  //   options &&
  //     options.map(({ option }) => columns.unshift({ title: option.name }));
  // }, [options]);
  options.map(({ option, values }) =>
    columns.unshift({
      title: option.name,
      render: (text, record) => {
        console.log("record", record);
        // let optionValues = values;
        // if (optionsValuesQuery.data?.optionValues) {
        //   const obj = optionsValuesQuery.data?.optionValues.reduce(function (
        //     acc,
        //     cur,
        //     i
        //   ) {
        //     acc[cur.id] = cur;
        //     return acc;
        //   },
        //   {});

        //   const optionValuesobj = optionValues.reduce(function (acc, cur, i) {
        //     acc[cur.id] = cur;
        //     return acc;
        //   }, {});
        //   const setObj = { ...obj, ...optionValuesobj };
        //   optionValues = Object.values(setObj);
        // }

        return (
          <Form.Item
            style={{
              marginBottom: "0",
              width: 100,
            }}
            onClick={() => route.push(`/product/${id}/variants/${record.id}`)}
            name={[
              "variants",
              record.id.toString(),
              "selectedOptions",
              record.selectedOptions.find(({ option: { id } }) => id == option.id)?.id.toString(),
            ]}>
            <Select
              rules={[{ required: true }]}
              loading={optionsValuesQuery.loading}
              filterOption={false}
              disabled
              // notFoundContent={
              //   optionsValuesQuery.loading ? (
              //     <Spin size="small" />
              //   ) : null
              // }
              labelInValue
              onSearch={search}
              showSearch
              onFocus={(e) => {
                setAttr(option.id);
                setFilter(e.target.value || "");
              }}
              placeholder="options">
              {optionsValuesQuery.data?.optionValues.map((value) => (
                <Option key={value.id} value={value.id}>
                  {value.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        );
      },
    }),
  );
  // const onSearch = (searchText) => {
  //   console.log("onSearch -> searchText", );

  // };
  // const onSelect = (data) => {
  //   console.log("onSelect", data);
  // };

  return (
    <FormCard>
      <Row align="middle" justify="space-between">
        <Col>
          <h2>{t("products.variants")}</h2>
        </Col>
        <Col>
          <Button type="link" onClick={() => route.push(`/product/${id}/variant/new`)}>
            {t("products.addVariant")}
          </Button>
        </Col>
      </Row>

      <Table scroll={{ x: true }} dataSource={variants} columns={columns} pagination={false} />
    </FormCard>
  );
};

export default EditVariantCard;
