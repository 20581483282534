import { useState, useEffect } from "react";
import { ClockCircleOutlined } from "@ant-design/icons";
import { Button, DatePicker, Input, Modal, Typography, Spin } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import SuccessNotification from "../../../../../components/common/successNotification";
import moment from "moment";
import { useParams } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { SINGLE_PRODUCT } from "../edit";
import { SINGLE_POST } from "../../../../posts/addEdit/edit";

const dateFormat = "YYYY/MM/DD";

const RESET_PRODUCT_DATE = gql`
  mutation RESET_PRODUCT_DATE($id: Int!, $publishAt: DateTime) {
    updateProduct(
      id: $id

      publishAt: $publishAt
    ) {
      id
    }
  }
`;

const RESET_POST_DATE = gql`
  mutation RESET_POST_DATE($id: Int!, $publishAt: DateTime) {
    updatePost(id: $id, publishAt: $publishAt) {
      id
    }
  }
`;

const Schedular = ({
  isModalVisible,
  setIsModalVisible,
  isEdit,
  defaultMomentValue,
  setMoment,
  currrentMoment,
  page,
}) => {
  console.log("🚀 ~ file: schedular.jsx ~ line 44 ~ currrentMoment", defaultMomentValue);
  const { t } = useTranslation();
  const [time, setTime] = useState(new Date().getHours() + ":" + new Date().getMinutes());
  const [showPicker, setShowPicker] = useState(false);
  const [newMoment, setNewMoment] = useState(moment(defaultMomentValue));

  const { id } = useParams();
  console.log("router", id);

  // const [updateProduct] = useMutation(RESET_PRODUCT_DATE, {
  //   variables: {
  //     id: Number(id),
  //     publishAt: new Date(),
  //   },
  //   refetchQueries: [
  //     {
  //       query: SINGLE_PRODUCT,
  //       variables: { id: parseInt(id) },
  //     },
  //   ],
  // });

  // const [updatePost] = useMutation(RESET_POST_DATE, {
  //   variables: {
  //     id: Number(id),
  //     publishAt: new Date(),
  //   },
  //   refetchQueries: [{ query: SINGLE_POST, variables: { id: parseInt(id) } }],
  // });

  const [editProduct] = useMutation(RESET_PRODUCT_DATE, {
    variables: {
      id: Number(id),
      publishAt: currrentMoment,
    },
    refetchQueries: [
      {
        query: SINGLE_PRODUCT,
        variables: { id: parseInt(id) },
      },
    ],
  });

  const [editPost] = useMutation(RESET_POST_DATE, {
    variables: {
      id: Number(id),
      publishAt: currrentMoment,
    },
    refetchQueries: [{ query: SINGLE_POST, variables: { id: parseInt(id) } }],
  });

  const handleOk = () => {
    setMoment(newMoment);

    setIsModalVisible(false);
    // console.log("moment", moment);
    SuccessNotification({
      // defaultTitle: t("products.successDefaultMessage"),
      title: t("products.sheduleSuccess"),
      type: "success",
    });

    if (id) {
      if (page === "product") {
        editProduct({ variables: { publishAt: newMoment } });
      } else {
        editPost({ variables: { publishAt: newMoment } });
      }
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (isModalVisible) {
      setTimeout(() => {
        setShowPicker(true);
      }, 200);
    }
    if (currrentMoment) {
      let defaulTime = new Date(currrentMoment).toLocaleString().slice(-11, -6);
      console.log("🚀 ~ file: schedular.jsx ~ line 48 ~ useEffect ~ defaulTime", defaulTime);
      setTime(defaulTime);
    }
    if (id) {
      if (defaultMomentValue) {
        if (currrentMoment) {
          let defaulTime = new Date(currrentMoment).toLocaleString().slice(-10, -5);
          console.log("🚀 ~ file: schedular.jsx ~ line 48 ~ useEffect ~ defaulTime", defaulTime);
          setTime(defaulTime);
          console.log("🚀 ~ file: schedular.jsx ~ line 131 ~ useEffect ~ defaulTime", defaulTime);
        } else {
          let defaulTime = new Date(defaultMomentValue).toLocaleString().slice(-10, -6);
          console.log("🚀 ~ file: schedular.jsx ~ line 48 ~ useEffect ~ defaulTime", defaulTime);
          setTime(defaulTime);
        }
      }
    }
  }, [isModalVisible, defaultMomentValue, currrentMoment]);

  // useEffect(() => {
  //   if (new Date(moment).toLocaleString()) {
  //     setDisabled(true);
  //   }
  // }, [moment]);

  const CustomLoading = () => (
    <div
      style={{
        position: "fixed",
        maxHeight: "100%",
        maxWidth: "100%",
        backgroundColor: "white",
        zIndex: 22,
      }}>
      <Spin
        size="large"
        style={{
          position: "fixed",
          right: "50%",
          top: "50%",
        }}
      />
    </div>
  );

  return (
    <Container>
      <Modal
        style={{ width: "fit-content" }}
        title={isEdit ? t("products.editScheduleProduct") : t("products.scheduleProduct")}
        visible={isModalVisible}
        onOk={handleOk}
        bodyStyle={{ height: 480 }}
        cancelButtonProps={false}
        onCancel={handleCancel}
        footer={[
          <Button
            type="text"
            style={{ color: "red" }}
            onClick={() => {
              setMoment(undefined);
              // console.log("momentvvv", moment);

              if (id) {
                if (page === "product") {
                  editProduct({ variables: { publishAt: new Date() } });
                } else {
                  editPost({ variables: { publishAt: new Date() } });
                }
              }
              SuccessNotification({
                // defaultTitle: t("products.successDefaultMessage"),
                title: t("products.sheduleFailed"),
                type: "success",
              });
              setIsModalVisible(false);
            }}>
            {t("products.cancelSchedule")}
          </Button>,
          <Button type="primary" onClick={handleOk}>
            {!isEdit ? t("products.scheduleProduct") : t("products.save")}
          </Button>,
        ]}>
        {showPicker ? (
          <>
            <Typography.Text style={{ color: "#828282" }}>
              {page === "product" ? t("products.publishTime") : t("posts.publishTime")}
            </Typography.Text>

            <DatePickerContainer>
              <div className="picker__container">
                <Typography.Text style={{ display: "block" }}>{t("products.date")}</Typography.Text>
                {isModalVisible && showPicker && (
                  <DatePicker
                    style={{ marginTop: 10 }}
                    panelRender={(c) => <div className="date__picker__schedule">{c}</div>}
                    showNow={false}
                    inputReadOnly
                    clearIcon={false}
                    open={true}
                    showTime={{ format: "h:mm:ss A" }}
                    showSecond={false}
                    placeholder={false}
                    value={newMoment || currrentMoment}
                    defaultValue={
                      !id
                        ? moment(new Date())
                        : currrentMoment
                        ? currrentMoment
                        : defaultMomentValue !== null && moment(defaultMomentValue)
                    }
                    format={dateFormat}
                    onSelect={(e) => {
                      let hour = e.hour() > 12 ? e.hour() % 12 : e.hour();
                      setTime(
                        `${hour < 10 ? `0${hour}` : hour}:${
                          e.minute() < 10 ? `0${e.minute()}` : e.minute()
                        }`,
                      );
                      setNewMoment(e);
                      // console.log("moment", moment);
                    }}
                  />
                )}
              </div>
              <div>
                <Typography.Text>{t("products.time")}</Typography.Text>
                <Input
                  style={{ marginTop: 10 }}
                  readOnly
                  value={time}
                  suffix={<ClockCircleOutlined />}
                />
              </div>
            </DatePickerContainer>
          </>
        ) : (
          <CustomLoading />
        )}
      </Modal>
    </Container>
  );
};

const Container = styled.div``;

const DatePickerContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 20px;
  .ant-picker {
    width: 100%;
  }

  .picker__container {
    width: 100%;
  }

  .ant-picker-panel-container {
    box-shadow: unset;
  }
`;

export default Schedular;
