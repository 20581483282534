import React, { useState } from "react";
import {
  Avatar,
  Badge,
  Button,
  Col,
  Dropdown,
  Layout,
  List,
  Menu,
  Popover,
  Row,
  Space,
} from "antd";

import { Link, useHistory } from "react-router-dom";

import { gql, useQuery } from "@apollo/client";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
const LOGS = gql`
  query LOGS {
    logs(take: 5, orderBy: { id: desc }) {
      id
      log
      createdAt
    }
  }
`;

const AdminNotifications = (props) => {
  const { data, loading } = useQuery(LOGS, {
    fetchPolicy: "network-only",
    pollInterval: 10000,
  });
  const history = useHistory();
  // return null;
  return (
    <List
      // footer={<Link to="/history">View all</Link>}
      // bordered
      itemLayout="horizontal"
      loading={loading}
      dataSource={[]}
      renderItem={(item) => (
        <List.Item actions={[formatDistanceToNow(new Date(item.createdAt), { addSuffix: true })]}>
          {item.log}
        </List.Item>
      )}
    />
  );
};

export default AdminNotifications;
