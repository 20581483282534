export enum Breakpoints {
  xxl = `1600px`,
  xl = `1200px`,
  lg = `992px`,
  md = `768px`,
  sm = `576px`,
  xs = `320px`,
}

export const b7rPlatformUrl =
  process.env.NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV_STAGING === "dev"
    ? "https://www.b7r.shop/"
    : process.env.REACT_APP_NODE_ENV_STAGING === "staging"
    ? "https://www.b7r.live/"
    : "https://www.b7r.store/";

export const CHECKOUT_SETTINGS_NAMES = [
  "nearestLandmark",
  "email",
  // "forceSignUp",
  "name",
  "firstName",
  "lastName",
  "address",
  "address2",
  "apartmentNo",
  "area",
  "city",
  // "country",
  "floorNo",
  "landline",
  "note",
  "phone",
  "postcode",
  // "state",
  "streetNo",
];

const permissions = [
  "orders 0",
  "addOrder 0",
  "removeOrder ",
  "order 0",
  "editOrder 0",
  "users 0",
  "pages 0",
  "chatScreen 0",
  // "bestSelling",
  "analytics 0",
  "discounts 0",
  "addDiscount 0",
  "updateDiscount 0",
  "removeDiscount",
  "addProduct 0",
  "importProducts", //!
  "updateProduct 0",
  "removeProduct ",
  "inventories 0",
  "theming 0",
  "navigation 0",
  "blog 0",
  "updateSettings 0",
  "locations 0",
  "shippingZones 0",
  "domains 0",
];
const features = {
  liveChat: true, //*
  changeDomain: true, //*
  locations: true, //*
  analytics: true, //*
  checkoutSetting: true, // D // *
  importProducts: true, // D // *
  blog: true, //*
  pages: true, //*
  productsFilter: true,
  coupons: true, //*
  reviews: true, //*
  seo: true, // D // *
  archivedOrders: true, // D  // *
  ai: true, // D // *
};
