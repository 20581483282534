import { gql, useMutation, useQuery } from "@apollo/client";
import { Button, Divider, Form, Input, Space, Typography, Modal, message } from "antd";
import { useTranslation } from "react-i18next";
import { CURRENT_USER } from "../../../../utlis/Me";
import { authMutation } from "../../../../modules/auth/resolvers";
import { useHistory } from "react-router-dom";
import CopyIcon from "../../../../assets/imgs/copy-clipboard.svg";

const DELETE_MY_STORE = gql`
  mutation DeleteMyStore($name: String!) {
    deleteMyStore(name: $name) {
      id
      name
    }
  }
`;

const SIGN_OUT = gql`
  mutation SIGN_OUT {
    signOut
  }
`;
const DeleteStoreModal = ({ isModalOpen, handleCancel }) => {
  const { push } = useHistory();
  const [form] = Form.useForm();
  const [deleteStore, { loading }] = useMutation(DELETE_MY_STORE);
  const [signOut] = useMutation(SIGN_OUT, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: CURRENT_USER }],
  });
  const { data } = useQuery(CURRENT_USER, {
    fetchPolicy: "cache-first",
  });
  const { t } = useTranslation();
  const storeName = data?.me.currentShop.name;
  const storesNum = data?.me.stores.length;
  const onFinish = async (variables) => {
    try {
      await deleteStore({
        variables: { ...variables },
      });
      storesNum > 0
        ? push("/shops")
        : signOut().then(() => {
            authMutation.signOut();
            push("/signin");
          });
    } catch (error) {
      console.log(error, "deleteStoreError");
    }
    form.resetFields();
  };
  return (
    <>
      <Modal
        title={t("settings.deleteStore")}
        destroyOnClose
        visible={isModalOpen}
        open={isModalOpen}
        footer={null}
        // onOk={handleOk}
        // cancelText="الغاء"
        // okText={t("settings.deleteStore")}
        onCancel={handleCancel}>
        <Typography.Text style={{ marginBottom: "17px", display: "block" }}>
          {/* {t("settings.deleteStoreData")} */}
          سيتم حذف بيانات متجرك{" "}
          <span style={{ fontWeight: "bolder" }}>
            {storeName}
            <img
              style={{ cursor: "pointer", margin: "0 4px" }}
              onClick={() => {
                navigator.clipboard.writeText(storeName);
                message.success(t("settings.copiedToClipboard"));
              }}
              src={CopyIcon}
              alt="copy icon"
              width={14}
              height={14}
            />
          </span>{" "}
          بالكامل ولا يمكن التراجع عن هذا الاجراء. هل انت متأكد انك تريد حذف المتجر ؟
        </Typography.Text>
        <Typography.Text style={{ display: "block", marginBottom: "12px" }}>
          {t("settings.confirmStoreName")}
        </Typography.Text>
        <Form id="myForm" onFinish={onFinish}>
          <Form.Item
            name="name"
            rules={[{ required: true, message: t("settings.pleaseAddStoreName") }]}>
            <Input
              style={{ width: "100%" }}
              onChange={(e) => {
                console.log(e.target.value, "ASDKASD");
              }}
            />
          </Form.Item>
        </Form>
        <Divider />
        <Space style={{ width: "100%", justifyContent: "end" }}>
          <Form.Item style={{ margin: "unset" }}>
            <Button
              form="myForm"
              key="submit"
              htmlType="submit"
              loading={loading}
              disabled={loading}
              style={{ background: "#EB5757", border: "unset" }}
              type="primary">
              {t("settings.deleteStore")}
            </Button>
          </Form.Item>
          <Form.Item style={{ margin: "unset" }}>
            <Button onClick={handleCancel}>{"الغاء"}</Button>
          </Form.Item>
        </Space>
      </Modal>
    </>
  );
};
export default DeleteStoreModal;
