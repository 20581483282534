import React, { useState, useEffect, useMemo } from "react";

import { Button, Divider, Input, TreeSelect } from "antd";
import { gql, useQuery, useMutation } from "@apollo/client";
import debounce from "lodash/debounce";
import { PlusOutlined } from "@ant-design/icons";
import makeSlug from "slug-arabic"; // by B7r :)

export const ADD_TYPE = gql`
  mutation ADD_TYPE($name: String!, $slug: String!) {
    addProductType(name: $name, slug: $slug) {
      id
    }
  }
`;
export const PRODUCT_TYPES = gql`
  query PRODUCT_TYPES($filter: ProductTypeWhereInput) {
    productTypes(where: $filter, take: 10) {
      nodes {
        id
        name
        slug
      }
    }
  }
`;

const ProductTypeSelector = ({
  style = {},
  checkable,
  initialSlugs,
  canAdd,
  isValueId,
  ...props
}) => {
  const [search, setSearch] = useState("");
  const [treeData, setTreeData] = useState([]);
  const [typeName, setTypeName] = useState("");
  const variables = {
    filter: {
      OR: [{ ...initialSlugs }, { name: { contains: search, mode: "insensitive" } }],
    },
  };
  const [addType, typeprops] = useMutation(ADD_TYPE, {
    refetchQueries: [{ query: PRODUCT_TYPES, variables }],
  });
  const { data, loading } = useQuery(PRODUCT_TYPES, {
    variables,
  });
  useEffect(() => {
    const treeData = data?.productTypes.nodes.map(({ slug, name, id, ...rest }) => ({
      value: isValueId ? id : slug,
      label: name,
      key: slug,
      ...rest,
    }));
    treeData && setTreeData(treeData);
  }, [data]);
  console.log("🚀 ~ file: ProductTypeSelector.js ~ line 71 ~ defaultValue", props.value);

  return (
    <TreeSelect
      {...props}
      style={{ width: "100%", ...style }}
      // treeDataSimpleMode
      onSearch={debounce((val) => setSearch(val), 200)}
      showSearch
      loading={loading}
      treeCheckable={checkable}
      allowClear
      // defaultValue={[{ label: "as", value: "s" }]}
      // showCheckedStrategy={SHOW_ALL}
      filterTreeNode={false}
      treeCheckStrictly={checkable}
      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
      treeData={treeData}
      // placeholder="Search for Types"
      placeholder="e.g. Shirts"
      dropdownRender={(menu) => (
        <div>
          {menu}

          {canAdd ? (
            <>
              <Divider style={{ margin: "4px 0" }} />
              <div
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  padding: 8,
                }}>
                <Input
                  style={{ flex: "auto" }}
                  value={typeName}
                  onChange={(e) => setTypeName(e.target.value)}
                />
                <Button
                  icon={<PlusOutlined />}
                  loading={typeprops.loading}
                  type="link"
                  onClick={() => {
                    typeName &&
                      addType({
                        variables: { name: typeName, slug: makeSlug(typeName) },
                      });

                    setTypeName("");
                  }}>
                  Add
                </Button>
              </div>
            </>
          ) : null}
        </div>
      )}
    />
  );
};

export default React.memo(ProductTypeSelector);
