import styled from "styled-components";
import { Breakpoints } from "../../../../constants";

export const FormCard = styled.div`
  /* width: 100%; */

  background-color: ${(props) => props.theme.customColors.white};
  padding: 16px;
  box-shadow: 0px 0px 8px rgba(51, 51, 51, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  margin-top: 40px;
  position: relative;
  /* margin: 20px; */
  z-index: 20 !important;
  .ant-form-item-label > label {
    width: 100%;
    font-weight: 14px;
    font-weight: 600;
    color: #333333;
  }
  .ant-input-number-input {
    height: 40px;
  }

  .form__title {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
    line-height: 30px;
  }
  .deleting-price,
  .upload-text {
    color: #828282;
    font-weight: 400px;
    font-size: 12px;
  }
  .ant-checkbox + span {
    color: #828282;
    font-weight: 600px;
    font-size: 14px;
  }
  .upload-img {
    color: #333;
    font-weight: 400px;
    font-size: 14px;
  }
  .ant-form-item .ant-upload.ant-upload-drag {
    height: 112px;
    margin-bottom: 20px;
  }
  .ant-select-selector {
    /* height: 40px; */
    border: 1px solid #e0e0e0;
  }

  .shipping-type {
    text-align: end;
    .ant-select.ant-select-single .ant-select-selector .ant-select-selection-item {
      text-align: center;
      color: ${(props) => props.theme.customColors.white};
    }
    .ant-select-selector {
      background: ${(props) => props.theme.customColors.lightBlue};
    }
  }

  .select-order {
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    .ant-select.ant-select-single .ant-select-selector .ant-select-selection-item {
      text-align: center;
    }
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: #d9d9d9;
      box-shadow: unset;
    }
    .ant-select-item-option-content {
      font-size: 500px;
      color: red;
    }
  }

  label[for="addProduct_categories"] {
    .label-text {
      font-weight: 400;
      font-size: 14px;
      color: #333;
    }
    .label-btn {
      font-weight: 600;
      font-size: 14px;
      /* color: #1985a1; */
      padding: 0;
    }
    &:after {
      display: none;
    }
  }
  .settings-title {
    color: ${(props) => props.theme.colors.black3};
    @media (max-width: ${Breakpoints.sm}) {
      font-size: 14px;
    }
  }
  .settings-desc {
    color: ${(props) => props.theme.colors.Gray1};
    line-height: 18px;
    @media (max-width: ${Breakpoints.sm}) {
      font-size: 12px;
      max-width: 300px;
      color: ${(props) => props.theme.colors.Gray2};
    }
    .editTheme-btn {
      padding: 1px 12px;
      background: ${(props) => props.theme.customColors.lightBlue};
      font-weight: 600;
      @media (max-width: ${Breakpoints.sm}) {
        font-size: 12px;
      }
    }
    .editTheme-btn {
      padding: 1px 12px;
      background: ${(props) => props.theme.customColors.lightBlue};
      font-weight: 600;
      @media (max-width: ${Breakpoints.sm}) {
        font-size: 12px;
      }
    }
    .editTheme-title {
      font-weight: bold;
      color: ${(props) => props.theme.colors.black3};
      @media (max-width: ${Breakpoints.sm}) {
        font-size: 12px;
      }
    }
  }
`;
