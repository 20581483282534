import React, { useEffect, useState } from "react";
import { Button, Divider, Form, Input, Spin, Switch, Typography } from "antd";
import ChooseImage from "../../products/products/addEdit/cards/chooseImage";
import { FormCard } from "../../products/products/addEdit/style";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useMutation, gql, useLazyQuery, useQuery } from "@apollo/client";
import { SEO_ITEM } from "../../../modules/common/queries";
import { SEOItem } from "../../../types";

const CustomSwitch = styled(Switch)`
  margin: 0px 17px;
`;
enum MetaFormNames {
  addMeta = "addMeta",
  imageFT = "imageFT",
  titleFT = "titleFT",
  descFT = "descFT",
  twitterUser = "twitterUser",
  canonicalUrl = "canonicalUrl",
  follow = "follow",
  indexed = "indexed",
}
const UPDATE_SEO_ITEM = gql`
  mutation UPDATE_SEO_ITEM(
    $updateSeoItemId: Int!
    $mainSeo: SeoItemArg!
    $socialItems: [SocialItemArg!]!
  ) {
    updateSeoItem(id: $updateSeoItemId, mainSeo: $mainSeo, socialItems: $socialItems) {
      id
      extendTitle
    }
  }
`;
function MetaTags({ seoId, closeModal = null }) {
  console.log("🚀 ~ file: metaTags.tsx ~ line 38 ~ MetaTags ~ seoId", seoId);
  const [editSeo, seoProps] = useMutation(UPDATE_SEO_ITEM);
  // const { data: meData, loading: loadingMe } = useQuery(ME, { fetchPolicy: "cache-first" });
  const { data: seoItemData, loading: seoLoading } = useQuery(SEO_ITEM, {
    skip: !seoId,
    variables: { seoItemWhere: { id: { equals: Number(seoId) } } },
  });
  const [form] = Form.useForm();
  const [mainImage, setMainImage] = useState(null);
  const { t } = useTranslation();

  console.log("🚀 ~ file: metaTags.tsx ~ line 29 ~ MetaTags ~ seoItemData", seoItemData);
  const onFinish = async (vars) => {
    const mainSeo = { indexed: vars[MetaFormNames.indexed], follow: vars[MetaFormNames.follow] };
    console.log("🚀 ~ file: metaTags.tsx ~ line 49 ~ onFinish ~ vars", vars);
    console.log("🚀 ~ file: metaTags.tsx ~ line 73 ~ onFinish ~ mainSeo", mainSeo);
    await editSeo({
      variables: {
        updateSeoItemId: Number(seoId),
        mainSeo,
        socialItems: [
          {
            title: { en: vars[MetaFormNames.titleFT] },
            name: "FACEBOOK",
            body: { en: vars[MetaFormNames.descFT] },
            // enable: vars[MetaFormNames.addMeta],
            image: mainImage?.uid,
          },
          {
            title: { en: vars[MetaFormNames.twitterUser] },
            name: "TWITTER",
            body: { en: "" },
            // enable: true,
            // image: null,
          },
        ],
      },
    });
    {
      closeModal && closeModal();
    }
  };

  useEffect(() => {
    const face: any = seoItemData?.seoItem?.socialItems?.find(
      (social) => social?.name === "FACEBOOK",
    );
    if (face?.image)
      setMainImage({
        uid: face?.image?.id,
        url: face?.image?.lg,
      });
  }, [seoItemData]);
  if (!seoId) return null;
  if (seoLoading) return <Spin />;

  const seoItem: SEOItem = seoItemData?.seoItem;
  const openGraphItem = seoItem?.socialItems?.find((social) => social.name === "FACEBOOK");
  const twitterItem = seoItem?.socialItems?.find((social) => social.name === "TWITTER");
  return (
    <Container>
      <Form
        form={form}
        // labelCol={{ span: 24 }}
        initialValues={{
          [MetaFormNames.titleFT]: openGraphItem?.title?.en ?? "",
          [MetaFormNames.descFT]: openGraphItem?.body?.en ?? "",
          [MetaFormNames.twitterUser]: twitterItem?.title?.en ?? "",
          // [MetaFormNames.canonicalUrl]: "", // ! TEMP Needed
          // [MetaFormNames.addMeta]: openGraphItem?.enable ?? false,
          [MetaFormNames.follow]: seoItem?.follow ?? true,
          [MetaFormNames.indexed]: seoItem?.indexed ?? true,
          // sitemap: settingsData?.sitemap ?? true,
          // fivicon: settingsData.fivicon.id || null,
        }}
        onFinish={onFinish}>
        {/* <FormCard> */}
        <Typography.Title level={2}>Meta tags</Typography.Title>
        {/* <Form.Item
          label={t("common.addMetaTagsFT")}
          labelCol={{ span: 10 }}
          name={MetaFormNames.addMeta}
          valuePropName="checked">
          <span>
            <CustomSwitch />
          </span>
        </Form.Item> */}
        <Form.Item name={MetaFormNames.imageFT} label={t("common.facebookImageFT")}>
          <div style={{ maxWidth: "250px" }}>
            <ChooseImage
              firstImages={undefined}
              setChossingImage={setMainImage}
              defaultImage={mainImage}
            />
          </div>
        </Form.Item>
        <Form.Item
          labelCol={{ span: 24 }}
          label={t("common.openGraphTitleFT")}
          name={MetaFormNames.titleFT}>
          <Input />
        </Form.Item>
        <Form.Item
          labelCol={{ span: 24 }}
          label={t("common.openGraphDescriptionFT")}
          name={MetaFormNames.descFT}>
          <Input />
        </Form.Item>
        {/* <Divider /> */}
        {/* <Form.Item label={t("common.addTwitter")} name="twitter" valuePropName="checked">
          <CustomSwitch />
        </Form.Item> */}
        <Form.Item
          labelCol={{ span: 24 }}
          label={t("common.twitterTitleUserName")}
          name={MetaFormNames.twitterUser}>
          <Input />
        </Form.Item>
        {/* <Form.Item label={t("common.twitterDescription")} name="twitterDescription">
          <Input />
        </Form.Item> */}

        {/* <Form.Item name={"twitterImage"} label={t("common.twitterImage")}>
          <div style={{ maxWidth: "150px" }}>
            <ChooseImage
              firstImages={undefined}
              setChossingImage={setTwitterImage}
              defaultImage={twitterImage}
            />
          </div>
        </Form.Item> */}
        <Divider />
        {/* <Form.Item label={t("common.canonicalUrl")} name={MetaFormNames.canonicalUrl}>
          <Input />
        </Form.Item> */}
        <Form.Item
          label={t("common.innerFollow")}
          name={MetaFormNames.follow}
          valuePropName="checked">
          <CustomSwitch />
        </Form.Item>
        <Form.Item
          label={t("common.innerIndexed")}
          name={MetaFormNames.indexed}
          valuePropName="checked">
          <CustomSwitch />
        </Form.Item>
        <Button
          type="primary"
          loading={seoProps.loading}
          disabled={seoProps.loading}
          // icon={<PlusCircleOutlined />}
          // htmlType="submit"

          onClick={() => {
            form.submit();
          }}>
          {t("settings.save")}
        </Button>
        {/* </FormCard> */}
      </Form>
    </Container>
  );
}

const Container = styled.div``;
export default MetaTags;
