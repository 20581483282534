import React, { useState, useEffect, useMemo } from "react";

import { Select, Space, TreeSelect } from "antd";
import { gql, useQuery } from "@apollo/client";
import debounce from "lodash/debounce";
import { useTranslation } from "react-i18next";

const { Option } = Select;

export const PAGES_SEARCH = gql`
  query PAGES_SEARCH($where: PageWhereInput!) {
    pages(take: 10, orderBy: { id: asc }, where: $where) {
      count
      nodes {
        id
        slug
        title: mTitle
      }
    }
  }
`;

const PagesSelector = ({ style = {}, mode, filterId, valueType, ...props }) => {
  const [search, setSearch] = useState("");
  const { t } = useTranslation();

  const { data, loading } = useQuery(PAGES_SEARCH, {
    fetchPolicy: "cache-and-network",
    variables: {
      where: {
        title: {
          OR: [
            { ar: { contains: search, mode: "insensitive" } },
            { en: { contains: search, mode: "insensitive" } },
          ],
        },
      },
    },
  });

  return (
    <Select
      {...props}
      style={{ width: "100%", minWidth: 150, ...style }}
      onSearch={debounce((val) => setSearch(val), 200)}
      showSearch
      loading={loading}
      mode={mode}
      allowClear
      filterOption={false}
      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
      placeholder={t("products.searchForPages")}
    >
      {data?.pages?.nodes.map(({ id, title, slug }) => (
        <Option key={id} value={valueType == "slug" ? slug : id}>
          {title}
        </Option>
      ))}
    </Select>
  );
};

export default React.memo(PagesSelector);
