import { Menu, Checkbox, Button } from "antd";
import { useTranslation } from "react-i18next";
import { OrderFilterContext } from "../filterDrawer";
import { FilterFieldsNames } from "../../../types";
import { useContext } from "react";

interface Props {
  options: {
    value: string;
    text: string;
  }[];
  selectedOptions: string[];
  filedName: FilterFieldsNames;
}

const SubMenuFilter = ({ options, selectedOptions, filedName }: Props) => {
  const { t } = useTranslation();
  const { addFilterField, reset } = useContext(OrderFilterContext);
  const restore = () => reset(filedName);

  return (
    <>
      <Menu.ItemGroup>
        {options.map(({ text, value }, index) => (
          <Menu.Item key={value}>
            <Checkbox
              checked={!!selectedOptions.find((option) => option === value)}
              onChange={(e) => {
                addFilterField(filedName, value);
              }}>
              {text}
            </Checkbox>
          </Menu.Item>
        ))}
      </Menu.ItemGroup>
      <div className="reset-btn">
        <Button type="text" onClick={restore}>
          {t("sellChannel.restore")}
        </Button>
      </div>
    </>
  );
};

export default SubMenuFilter;
