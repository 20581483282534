import { useTranslation } from "react-i18next";
import { Select, Form } from "antd";
export const LangValues = [
  { value: "AR", text: "العربية" },
  { value: "EN", text: "English" },
];
const { Option } = Select;
const StoreLanguage = ({ languages, setLanguages }) => {
  const { t } = useTranslation();

  return (
    <Form.Item label={t("settings.storeLang")} name="languages">
      <Select
        // style={{ width: "100%", minWidth: 150, ...style }}
        // onSearch={debounce((val) => setSearch(val), 200)}
        showSearch
        // loading={loading || valuesLoading}
        mode={"tags"}
        filterOption={false}
        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
        onChange={(newValue) => {
          console.log(
            "🚀 ~ file: storeLanguage.tsx ~ line 24 ~ StoreLanguage ~ newValue",
            newValue,
          );

          setLanguages(LangValues.filter(({ value }) => newValue.includes(value)));
        }}
        // placeholder={t("products.tagsPlaceholder")}
      >
        {LangValues.map(({ value, text }) => (
          <Option key={value} value={value}>
            {text}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default StoreLanguage;
