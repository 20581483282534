import {
  Button,
  Divider,
  Drawer,
  Input,
  Menu,
  Radio,
  Select,
  Slider,
  Space,
  Typography,
} from "antd";
import moment from "moment";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { SEARCH_FILTERS } from "../../enums/searchFilters";
import { getFirstOfDay, getLastOfDay } from "../../lib/getDayOf";
import { getOrderExecutionStatuses } from "../../lib/getOrderExecutionStatuses";
import { getOrderPaidStatuses } from "../../lib/getOrderPaidStatuses";
import { getOrderStatuses } from "../../lib/getOrderStatuses";
import { Statues } from "../../pages/orders/hooks/useOrderFilter";
import { FilterFieldsNames } from "../../types";
import BrandSelector from "../common/selectors/BrandSelector";
import CategorySelector from "../common/selectors/categorySelector";
import CountriesSelect from "../common/selectors/countryRegionsSelectors";
import { radioStyle } from "../common/hooks/useFilter";
import ProductsTreeSelector from "../common/selectors/productsTreeSelector";
import { USER_ROLES } from "./constants";
import SubMenuFilter from "./options/subMenuFilter";
import { SubMenuContainer } from "./styled/SubMenuContainer";
const { SubMenu } = Menu;
const { Option } = Select;
const stockStatuses = (t) => [
  {
    text: t("analytics.inStock"),
    value: "inStock",
  },
  {
    text: t("analytics.outOfStock"),
    value: "outOfStock",
  },
  {
    text: t("analytics.backOrder"),
    value: "Backorder",
  },
  {
    text: t("analytics.notTracking"),
    value: "notTracking",
  },
];
interface Props {
  setInventoryStock;
  inventoryStock;
  onClose: any;
  visible: any;
  reset: (filedName?: FilterFieldsNames) => void;
  onFinish: () => void;
  currentTab: any;
  searchFilters: SEARCH_FILTERS[];
  orderStatus: string[];
  setTagFilter;
  setTags;
  tags: string[];
  tagsQuery;
  productOrCategory;
  setProductOrCategory;
  inventoriesData: any;
  inventories: number[];
  setBrands;
  brands: number[];
  setCategories;
  categories: number[];
  products: number[];
  paidStatus: string[];
  executionStatus: string[];
  setStartDate;
  setCountry;
  setRegion;
  region;
  country;
  setEndDate;
  setUserRole;
  email;
  setEmail;
  userRole;
  startDate: any;
  onChange: (dates: any) => void;
  endDate: any;
  prices: {
    highPrice: number;
    lowPrice: number;
  };
  onChangePrices: (values: any) => void;
  setProducts: (values: any) => void;
  setPoints: (values: any) => void;
  points: any;
  moreFilters?: any;
}

export default function FilterComponents(props: Props) {
  const {
    onClose,
    visible,
    currentTab,
    searchFilters,
    orderStatus,
    setTagFilter,
    inventoryStock,
    setTags,
    tags,
    tagsQuery,
    inventoriesData,
    inventories,
    setBrands,
    brands,
    setCategories,
    categories,
    paidStatus,
    executionStatus,
    setStartDate,
    setEndDate,
    startDate,
    endDate,
    prices,
    setInventoryStock,
    products,
    productOrCategory,
    email,
    userRole,
    setUserRole,

    setCountry,
    setRegion,
    region,
    setRegionCode,
    regionCode,
    country,
    setEmail,
    onChange,
    onChangePrices,
    reset,
    onFinish,
    setProducts,
    setProductOrCategory,
    moreFilters,
    setPoints,
    points,
  } = props;
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const SelectCountryProps = {
    checkable: undefined,
    getPerfix: undefined,
    statesOnly: undefined,
    whiteList: undefined,
  };

  const getFilters = (key, i) => {
    console.log("🚀 ~ file: filterDrawerComponents.tsx ~ line 161 ~ getFilters ~ key", key);
    console.log(
      "🚀 ~ file: filterDrawerComponents.tsx ~ line 164 ~ getFilters ~ SEARCH_FILTERS.PRICING",
      SEARCH_FILTERS.PRICING,
    );

    switch (key) {
      case SEARCH_FILTERS.PRICING:
        return (
          <>
            <SubMenu key={key + i + "sub55"} title={t("products.price")}>
              <Space>
                <Input
                  placeholder={t("analytics.fromTable")}
                  value={prices.lowPrice}
                  allowClear
                  type="number"
                  onChange={(e) => {
                    const val = e.target.value;
                    if (isNaN(Number(val))) return;
                    onChangePrices([val, prices.highPrice]);
                  }}
                />
                <Input
                  // placeholder="الي"
                  placeholder={t("analytics.for")}
                  value={prices.highPrice}
                  allowClear
                  type="number"
                  onChange={(e) => {
                    const val = e.target.value;
                    if (isNaN(Number(val))) return;
                    onChangePrices([prices.lowPrice, val]);
                  }}
                />
              </Space>
              <Slider
                range={{ draggableTrack: true }}
                min={0}
                max={10000}
                value={[prices.lowPrice, prices.highPrice]}
                onChange={onChangePrices}
              />
            </SubMenu>
            <Divider />
          </>
        );
      case SEARCH_FILTERS.EARNED_POINTS:
      case SEARCH_FILTERS.USED_POINTS:
        return (
          <>
            <SubMenu
              key={key + i + "sub55"}
              title={
                key == SEARCH_FILTERS.EARNED_POINTS
                  ? t("apps.earnedPoints")
                  : t("apps.exchangePoints")
              }>
              <Space>
                <Input
                  placeholder={t("analytics.fromTable")}
                  value={points[key]?.low}
                  allowClear
                  type="number"
                  onChange={(e) => {
                    const val = e.target.value;
                    if (isNaN(Number(val))) return;
                    setPoints({ ...points, [key]: { ...points[key], low: Number(val) } });
                  }}
                />
                <Input
                  // placeholder="الي"
                  placeholder={t("analytics.for")}
                  value={points[key]?.high}
                  allowClear
                  type="number"
                  onChange={(e) => {
                    const val = e.target.value;
                    if (isNaN(Number(val))) return;
                    setPoints({ ...points, [key]: { ...points[key], high: Number(val) } });
                  }}
                />
              </Space>
              <Slider
                range={{ draggableTrack: true }}
                min={0}
                max={10000}
                value={[points[key]?.low, points[key]?.high]}
                onChange={(val) =>
                  setPoints({ ...points, [key]: { ...points[key], high: val[1], low: val[0] } })
                }
              />
            </SubMenu>
            <Divider />
          </>
        );

      default:
        return;
        break;
    }
  };

  return (
    <Drawer
      title={
        t("sellChannel.filter")
        // <>
        //   {t("sellChannel.filter")}
        //   <Divider />
        // </>
      }
      placement={language == "ar" ? "left" : "right"}
      closable={true}
      onClose={onClose}
      visible={visible}
      className="filter-drawer"
      // headerStyle={{ border: 0, paddingBottom: 0 }}
      footerStyle={{ border: 0 }}
      width={370}
      footer={
        <Space style={{ justifyContent: "space-between", width: "100%" }}>
          <Button
            className="link"
            type="link"
            onClick={() => {
              reset();
            }}>
            {t("sellChannel.restore")}
          </Button>
          <Button className="submit" type="primary" onClick={onFinish}>
            {t("sellChannel.submit")}
          </Button>
        </Space>
      }>
      <SubMenuContainer
        style={{
          width: "100%",
        }}>
        <Menu
          // onClick={handleClick}
          style={{ width: "100%" }}
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub334"]}
          mode="inline">
          {currentTab == 1 && searchFilters?.includes(SEARCH_FILTERS.ORDER_STATUS) && (
            <>
              <SubMenu key={"sub1"} title={t("order.orderStatus")}>
                <SubMenuFilter
                  options={getOrderStatuses(t, [Statues.archived])}
                  selectedOptions={orderStatus}
                  filedName="orderStatus"
                />
              </SubMenu>
              <Divider />
            </>
          )}
          {(searchFilters?.includes(SEARCH_FILTERS.EMAIL) ||
            searchFilters?.includes(SEARCH_FILTERS.USER_EMAIL)) && (
            <>
              <SubMenu key={"sub172"} title={t("users.email")} style={{}}>
                <Input
                  placeholder={t("users.email")}
                  value={email}
                  allowClear
                  type="text"
                  onChange={(e) => {
                    const val = e.target.value;
                    setEmail(val);
                  }}
                />
              </SubMenu>
              <Divider />
            </>
          )}

          {searchFilters?.includes(SEARCH_FILTERS.USER_ROLES) && (
            <>
              <SubMenu key={"sub512"} title={t("users.role")} style={{}}>
                <Select
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("users.role")}
                  mode="multiple"
                  onChange={(data) => {
                    setUserRole(data as any);
                  }}
                  value={userRole}>
                  {USER_ROLES.map((value) => (
                    <Option key={value} value={value}>
                      {t(`users.${value}`)}
                    </Option>
                  ))}
                </Select>
              </SubMenu>
              <Divider />
            </>
          )}
          {searchFilters?.includes(SEARCH_FILTERS.TAGS) && (
            <>
              <SubMenu key={"sub5"} title={t("home.tags")} style={{}}>
                <Select
                  style={{
                    width: "100%",
                  }}
                  showSearch
                  onSearch={(val) => setTagFilter(val)}
                  placeholder={t("order.tagsTypes")}
                  onChange={(data) => {
                    setTags(data as any);
                  }}
                  value={tags}
                  mode="tags">
                  {tagsQuery.data?.tags.nodes.map((value) => (
                    <Option key={value.id} value={value.name}>
                      {value.name}
                    </Option>
                  ))}
                </Select>
              </SubMenu>
              <Divider />
            </>
          )}

          {searchFilters?.includes(SEARCH_FILTERS.PRODUCTS) && (
            <>
              <SubMenu key={"sub6"} title={t("home.products")} style={{}}>
                {
                  //@ts-ignore

                  <ProductsTreeSelector
                    noDetalis
                    onChange={(values) => {
                      console.log(
                        "🚀 ~ file: filterDrawerComponents.tsx ~ line 201 ~ FilterComponents ~ values",
                        values,
                      );

                      setProducts(values);
                    }}
                    value={products}
                    checkable
                    valueType="id"
                  />
                }
              </SubMenu>
              <Divider />
            </>
          )}
          {/*  {searchFilters?.includes(SEARCH_FILTERS.PRODUCT_OR_CATEGORY) && (
            <>
              <SubMenu key={"sub17"} title={t("common.productOrCategory")} style={{}}>
                <Input
                  placeholder={t("common.productOrCategoryPlaceholder")}
                  value={productOrCategory}
                  allowClear
                  type="text"
                  onChange={(e) => {
                    const val = e.target.value;
                    setProductOrCategory(val);
                  }}
                />
              </SubMenu>
              <Divider />
            </>
          )} */}

          {searchFilters?.includes(SEARCH_FILTERS.INVENTORIES_STOCK) && (
            <>
              <SubMenu key="sub552" title={t("order.inventories")}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: language === "ar" ? "right" : "left",
                  }}>
                  <Space>
                    <Radio.Group
                      onChange={(e) => {
                        setInventoryStock(e.target.value);
                      }}
                      value={inventoryStock}>
                      {stockStatuses(t).map(({ text, value }) => (
                        <Radio style={{ ...radioStyle }} key={value} value={value}>
                          {text}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Space>
                </div>
                <div className="reset-btn">
                  <Button type="text" onClick={() => reset("inventoryStock")}>
                    {t("sellChannel.restore")}
                  </Button>
                </div>
              </SubMenu>
              <Divider />
            </>
          )}
          {searchFilters?.includes(SEARCH_FILTERS.INVENTORIES) && (
            <>
              <SubMenu key="sub45" title={t("order.inventories")}>
                <SubMenuFilter
                  options={
                    inventoriesData?.inventories?.map((inv) => ({
                      value: inv?.id,
                      text: inv?.name,
                    })) || []
                  }
                  selectedOptions={inventories as any}
                  filedName="inventories"
                />
              </SubMenu>
              <Divider />
            </>
          )}
          {searchFilters?.includes(SEARCH_FILTERS.BRANDS) && (
            <>
              <SubMenu key="sub44" title={t("order.brands")}>
                <BrandSelector
                  onChange={(values) => setBrands(values.map(({ value }) => value))}
                  value={brands}
                  checkable
                  isValueId
                />
              </SubMenu>
              <Divider />
            </>
          )}
          {searchFilters?.includes(SEARCH_FILTERS.CATEGORIES) && (
            <>
              <SubMenu key="sub77" title={t("order.categories")}>
                <CategorySelector
                  onChange={(values) => setCategories(values.map(({ value }) => value))}
                  value={categories}
                  checkable
                  valueType="id"
                />
              </SubMenu>
              <Divider />
            </>
          )}
          {searchFilters?.includes(SEARCH_FILTERS.PAYOFF_STATUS) && (
            <>
              <SubMenu key="sub2" title={t("order.payingOff")}>
                <SubMenuFilter
                  options={getOrderPaidStatuses(t)}
                  selectedOptions={paidStatus}
                  filedName="paidStatus"
                />
              </SubMenu>
              <Divider />
            </>
          )}
          {searchFilters?.includes(SEARCH_FILTERS.EXECUTION_STATUS) && (
            <>
              <SubMenu key="sub3" title={t("order.execution")}>
                <SubMenuFilter
                  options={getOrderExecutionStatuses(t)}
                  selectedOptions={executionStatus}
                  filedName="excitonStatus"
                />
              </SubMenu>
              <Divider />
            </>
          )}
          {searchFilters?.includes(SEARCH_FILTERS.DATE_RANGE) && (
            <>
              <SubMenu key="sub4" title={t("order.date")}>
                <Space
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: "20px",
                  }}
                  size="large">
                  <Button
                    onClick={() => {
                      setStartDate(getFirstOfDay(new Date()));
                      setEndDate(getLastOfDay(new Date()));
                    }}>
                    {t("order.today")}
                  </Button>
                  <Button
                    onClick={() => {
                      const startOfMonth = getFirstOfDay(moment().startOf("month").toDate());

                      const endOfMonth = getLastOfDay(moment().endOf("month").toDate());

                      setStartDate(startOfMonth);
                      setEndDate(endOfMonth);
                    }}>
                    {t("order.month")}
                  </Button>
                  <Button
                    onClick={() => {
                      const startOfWeek = getFirstOfDay(
                        moment().startOf("week").isoWeekday(6).toDate(),
                      );
                      const endOfWeek = getLastOfDay(moment().endOf("week").isoWeekday(6).toDate());
                      endOfWeek.setDate(endOfWeek.getDate() - 1);
                      setStartDate(startOfWeek);
                      setEndDate(endOfWeek);
                    }}>
                    {t("order.week")}
                  </Button>
                </Space>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <DatePicker
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    inline
                  />
                </div>
                <div className="reset-btn">
                  <Button
                    type="text"
                    onClick={() => {
                      reset("orderDateRange");
                    }}>
                    {t("sellChannel.restore")}
                  </Button>
                </div>
              </SubMenu>
              <Divider />
            </>
          )}
          {searchFilters.map((key, i) => getFilters(key, i))}

          {searchFilters?.includes(SEARCH_FILTERS.COUNTRY_AND_STATES) && (
            <>
              <SubMenu key="sub334" title={t("settings.address")}>
                {
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      // alignItems: "center",
                    }}>
                    {
                      <Space
                        direction="vertical"
                        size="large"
                        style={{ marginTop: "10px", marginBottom: "20px" }}>
                        <Typography.Text>{t("settings.country")}</Typography.Text>
                        <CountriesSelect
                          {...SelectCountryProps}
                          onChange={(val) => {
                            setCountry(val);
                            setRegion(null);
                          }}
                          countriesOnly
                          value={country}
                        />
                      </Space>
                    }
                    {
                      <Space direction="vertical" size="large">
                        <Typography.Text>{t("settings.state")}</Typography.Text>
                        <CountriesSelect
                          countriesOnly={undefined}
                          {...SelectCountryProps}
                          onChange={(val, [name]) => {
                            setRegion(name);
                            setRegionCode(val);
                          }}
                          statesOnly
                          whiteList={country && [country]}
                          value={region}
                        />
                      </Space>
                    }
                  </div>
                }
                <div className="reset-btn">
                  <Button type="text" onClick={() => reset("countryAndRegion")}>
                    {t("sellChannel.restore")}
                  </Button>
                </div>
              </SubMenu>
              <Divider />
            </>
          )}

          {moreFilters}
        </Menu>
      </SubMenuContainer>
    </Drawer>
  );
}
