import styled from "styled-components";
import { Form, Row } from "antd";
export const EditContainer = styled.div`
  width: 100%;
  display: flex;
  background-color: #fff;
  flex-direction: column;
  padding: ${(props) => props.theme.isBreakpoints.md ? " 20px 50px" : " 20px 16px"};
  box-shadow: 0 0 10px 0 rgba(199, 221, 247, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 20px;
`;

export const FieldTitle = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 40px;
  width: 140px;
  margin-top: -10px
  /* display: flex;
  align-items: center;
  justify-content:center; */
`;
export const CustomFormItem = styled(Form.Item)`
  height: fit-content;
  margin: 0px;
`;
export const CustomRow = styled(Row)`
  margin-bottom: 35px;
`;
