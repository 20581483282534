import React, { useState, useEffect } from "react";

import {
  Table,
  Button,
  Space,
  Popconfirm as PopConfirm,
  Switch,
  Typography,
  Row,
  Col,
  Input,
} from "antd";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";

import { PlusCircleOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { GetNewPageData } from "../../lib/GetNewPageData";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import qs from "qs";
import styled from "styled-components";
import moment from "moment";
import EmptyPage from "../../components/common/empty";
import noBlog from "../../assets/imgs/noblog.svg";
import Loading from "../../components/common/Loading";
import CustomSearch from "../../components/common/customSearch";

const { Search } = Input;

export const POSTS = gql`
  query POSTS($skip: Int = 0, $filter: PostWhereInput) {
    posts(skip: $skip, take: 10, orderBy: { id: desc }, where: $filter) {
      count
      nodes {
        id
        title {
          id
          auto
        }
        updatedAt
        published
        category {
          id
          productsCount
          name {
            auto
          }
        }
      }
    }
  }
`;
export const DELETE_POST = gql`
  mutation DELETE_POST($where: PostWhereUniqueInput!) {
    deleteOnePost(where: $where) {
      id
    }
  }
`;
export const UPDATE_POST_PUBLISHED = gql`
  mutation UPDATE_POST_PUBLISHED($updatePostId: Int!, $updatePostPublished: Boolean) {
    updatePost(id: $updatePostId, published: $updatePostPublished) {
      id
      published
      updatedAt
    }
  }
`;
const PostsTable = () => {
  const [pagination, setPagination] = useState({ current: 1 });
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const { t } = useTranslation();
  const variables = {
    skip: (pagination.current - 1) * 10,
    filter: {
      title: {
        OR: [
          { ar: { contains: searchKeyword, mode: "insensitive" } },
          { en: { contains: searchKeyword, mode: "insensitive" } },
        ],
      },
    },
  };
  const { data, loading } = useQuery(POSTS, {
    variables: variables,
  });

  console.log("posts: ", data);

  const [updatePostPublished] = useMutation(UPDATE_POST_PUBLISHED, {
    update(cache, { data: { updatePost } }) {
      const { posts } = cache.readQuery({
        query: POSTS,
        variables: variables,
      });
      cache.writeQuery({
        query: POSTS,
        variables: variables,
        data: {
          posts: {
            count: posts.count,
            nodes: posts.nodes.map((post) => {
              let newPost = { ...post };
              if (newPost.id === updatePost.id) return { ...newPost, ...updatePost };
              return newPost;
            }),
          },
        },
      });
    },
  });
  const [removePost] = useMutation(DELETE_POST, {
    onCompleted: () => {
      GetNewPageData(pagination, setPagination);
    },
    update(cache, { data: { deleteOnePost } }) {
      const { posts } = cache.readQuery({
        query: POSTS,
        variables: variables,
      });
      cache.writeQuery({
        query: POSTS,
        variables: variables,

        data: {
          posts: {
            count: posts.count - 1,
            nodes: posts.nodes.filter(({ id }) => deleteOnePost.id !== id),
          },
        },
      });
    },
  });
  useEffect(() => {
    setPagination({
      ...pagination,
      total: data?.posts?.count,
    } as any);
  }, [data]);

  const handleTableChange = (pagination) => {
    setPagination({ ...pagination, current: pagination.current });
    route.push({
      pathname: route.location.pathname,
      search: qs.stringify({
        page: pagination.current || 1,
      }),
    });
  };

  const columns = () => {
    const cols = [
      {
        title: t("posts.post"),
        align: "center",
        className: "titleCell",
        // ...getColumnSearchProps("Companies"),
        render: (record) => (
          <Space style={{ height: "56px" }}>
            <Link to={`/post/${record.id}`}>
              <a
                style={{ color: "#000", fontWeight: "bold", fontSize: "12px", lineHeight: "18px" }}>
                {record.title.auto}
              </a>
            </Link>
          </Space>
        ),
      },
      {
        title: t("posts.cat"),
        align: "center",
        render: (record) => <Typography.Text>{record?.category?.name?.auto}</Typography.Text>,
      },
      {
        title: t("posts.date"),
        align: "center",
        // render: (record) => <Typography.Text>{record.updatedAt}</Typography.Text>,
        render: (record) => moment(record.updatedAt).format("h:mmA - DD/MM/YYYY"),
      },
      {
        title: t("posts.publish"),
        align: "center",
        className: "publishCell",
        render: (record) => (
          <Switch
            defaultChecked={record.published}
            onChange={(published) =>
              updatePostPublished({
                variables: {
                  updatePostPublished: published,
                  updatePostId: record.id,
                },
              })
            }
          />
        ),
      },
      {
        align: "center",
        className: "delete__and__edit",
        render: (record) => (
          <Space>
            <Link to={`/post/${record.id}`}>
              <EditOutlined />
            </Link>
          </Space>
        ),
      },
      {
        align: "center",
        className: "delete__and__edit",
        render: (record) => (
          <Space>
            <PopConfirm
              title={t("posts.deletePost")}
              onConfirm={() =>
                removePost({
                  variables: { where: { id: record.id } },
                })
              }
              okText={t("posts.yes")}
              cancelText={t("posts.no")}>
              <DeleteOutlined style={{ color: "red" }} />
            </PopConfirm>
          </Space>
        ),
      },
    ];

    return cols;
  };

  const route = useHistory();

  const query: any = qs.parse(route.location.search, {
    ignoreQueryPrefix: true,
  });
  useEffect(() => {
    if (query?.page) setPagination((old) => ({ ...old, current: parseInt(query.page) }));
  }, [query?.page]);
  const onSearch = (value) => {
    setSearchKeyword(value);
  };
  if (loading) return <Loading />;
  const isEmpty = data?.posts?.count == 0 && searchKeyword == "";
  return isEmpty ? (
    <EmptyPage
      image={noBlog}
      buttonLink="/posts/new"
      description={t("posts.noBlog")}
      buttonText={t("posts.addPost")}
    />
  ) : (
    <Container>
      <Row justify="space-between">
        <Col>
          <Typography.Title level={3}>{t("posts.blogs")}</Typography.Title>
        </Col>
        <Col>
          <Button
            onClick={() => route.push("/posts/new")}
            type="primary"
            style={{ marginBottom: 25 }}
            icon={<PlusCircleOutlined />}>
            {t("posts.addPost")}
          </Button>
        </Col>
      </Row>
      <Row style={{ marginBottom: "30px" }}>
        <Col xs={24} lg={12}>
          <SearchContainer>
            <CustomSearch
              placeholder={t("posts.findArticle")}
              // onSearch={(value) => setSearchKeyword(value)}
              onSearch={onSearch}
              value={searchVal}
              allowClear
              onChange={(val) => {
                setSearchVal(val.target.value);
                setPagination({ current: 1 });
              }}
              style={{ marginBottom: "20px", maxWidth: "300px" }}
            />
          </SearchContainer>
        </Col>
      </Row>
      <>
        <Table
          scroll={{ x: 1000 }}
          loading={loading}
          rowKey={(record) => record.id}
          columns={columns() as any}
          size="small"
          bordered
          dataSource={data?.posts.nodes}
          onChange={handleTableChange}
          pagination={{
            ...pagination,
            showSizeChanger: false,
            hideOnSinglePage: true,
            position: ["bottomCenter"],
          }}
        />
      </>
    </Container>
  );
};

const Container = styled.div`
  .titleCell {
    width: 200px;
    max-width: 300px;
    overflow: hidden;
    padding: 0 5px;
    white-space: pre-line;
  }

  .publishCell {
    width: 160px;
  }

  .delete__and__edit {
    width: 50px;
  }

  .ant-input-search > .ant-input-group > .ant-input-affix-wrapper:hover,
  .ant-input-search > .ant-input-group > .ant-input-affix-wrapper-focused,
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused,
  .ant-input-search .ant-input:hover,
  .ant-input-search .ant-input:focus {
    border-color: #d9d9d9;
    box-shadow: unset;
  }
`;

const SearchContainer = styled.div`
  .ant-input {
    background-color: #fff;
  }

  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button:not(.ant-btn-primary) {
    color: #1985a1;
  }
`;

export default PostsTable;
