import { Button, Modal, Space, Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import { makeProductsCSV } from "./importProducts";
import UploadCsv from "./uploadCsv";
import { MainProducts } from "./mainProducts";

interface Props {
  handleOk;
  handleCancel;
  isModalVisible;
  uploadProps;
  uploadProducts;
  csvFile;
}

const ImportPopUp = ({
  uploadProps,
  handleOk,
  handleCancel,
  isModalVisible,
  uploadProducts,
  csvFile,
}: Props) => {
  const { t } = useTranslation();

  const products = MainProducts;
  return (
    <Modal
      title={t("products.importProduct")}
      visible={isModalVisible}
      onOk={handleCancel}
      onCancel={handleCancel}
      footer={false}>
      <Typography.Text>
        {t("products.csvFile")}
        {/* {t("products.downloadCsv")} */}
        <CSVLink filename="products.csv" data={makeProductsCSV(products) || []}>
          <Button type="link">
            <Typography.Text
              style={{
                color: "#538B93",
                textDecorationStyle: "solid",
                textDecoration: "underline",
              }}>
              {t("products.downloadCsv")}
            </Typography.Text>
          </Button>
        </CSVLink>
        {t("products.csvExample")}
      </Typography.Text>
      <UploadCsv
        uploadProps={uploadProps}

        // uploadProducts={uploadProducts}
      />
      <div style={{ textAlign: "end", marginTop: "20px" }}>
        <Space>
          <Button onClick={handleOk} type="default">
            {t("products.cancel")}
          </Button>
          <Button
            disabled={!csvFile}
            onClick={() => {
              uploadProducts(csvFile);
            }}
            type="primary">
            {t("products.add")}
          </Button>
        </Space>
      </div>
    </Modal>
  );
};

export default ImportPopUp;
