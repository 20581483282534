import { Card } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import AmountsDueTabComponent from "./tabs/amountsDue";
import BalanceTabComponent from "./tabs/balance";

const BalanceRecord = () => {
  const { t } = useTranslation();
  const [activeTabKey, setActiveTabKey] = useState<string>("balance");
  const tabList = [
    {
      key: "balance",
      tab: t("apps.amonut"),
    },
    {
      key: "amountsDue",
      tab: "المبالغ المستحقة",
    },
  ];
  const contentList: Record<string, React.ReactNode> = {
    balance: <BalanceTabComponent />,
    amountsDue: <AmountsDueTabComponent />,
  };
  return (
    <Card
      style={{ width: "100%" }}
      tabList={tabList}
      activeTabKey={activeTabKey}
      onTabChange={(key) => setActiveTabKey(key)}>
      {contentList[activeTabKey]}
    </Card>
  );
};

export default BalanceRecord;
