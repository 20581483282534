import { useQuery } from "@apollo/client";
import qs from "qs";
import { createContext, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import styled from "styled-components";
import { SEARCH_FILTERS } from "../../enums/searchFilters";
import { getFirstOfDay, getLastOfDay } from "../../lib/getDayOf";
import { MY_INVENTORIES } from "../../pages/settings/locations/index";
import { FilterFieldsNames } from "../../types";
import FilterComponents from "./filterDrawerComponents";
import { addQueriesToFilter } from "./helpers/addQueriesToFilter";
import { InventoryFilters, UserRole } from "./helpers/enums";
import { TAGS } from "./helpers/queries";

export type OrderFilterContextProps = {
  orderStatus: string[];
  paidStatus: string[];
  executionStatus: string[];
  inventories: number[];
  reset: (filedName?: FilterFieldsNames) => void;
  addFilterField: (filedName: FilterFieldsNames, value: string) => void;
};

export const OrderFilterContext = createContext<Partial<OrderFilterContextProps>>({});

interface Props {
  onClose;
  visible;
  setDrawerFilter;
  setVisible;
  currentTab;
  handleParams;
  page;
  searchFilters: SEARCH_FILTERS[];
  setFilterLoaded: (isLoading) => void;
  moreFilters?;
}

const FilterDrawer = ({
  onClose,
  visible,
  setDrawerFilter,
  setVisible,
  currentTab,
  handleParams,
  searchFilters,
  page,
  setFilterLoaded,
  moreFilters,
}: Props) => {
  const {
    location: { search: searchQ },
  } = useHistory();
  const query: any = qs.parse(searchQ, {
    ignoreQueryPrefix: true,
  });
  const [orderStatus, setOrderStatus] = useState<string[]>([]);
  const [prices, setPrices] = useState({ highPrice: null, lowPrice: null });
  const [paidStatus, setPaidStatus] = useState<string[]>([]);
  const [executionStatus, setExcitonStatus] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [categories, setCategories] = useState<number[]>([]);
  const [inventories, setInventories] = useState<number[]>([]);
  const [brands, setBrands] = useState<number[]>([]);
  // const [value, setValue] = useState<any>();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [firstTab, setFirstTab] = useState(true);
  const [inventoryStock, setInventoryStock] = useState<null | InventoryFilters>(null);
  const [products, setProducts] = useState<any>([]);
  const [productOrCategory, setProductOrCategory] = useState<any>("");
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [regionCode, setRegionCode] = useState("");

  const [points, setPoints] = useState({});

  const [userRole, setUserRole] = useState<UserRole[]>([]);
  const [email, setEmail] = useState<null | "">(null);
  const [tagFilter, setTagFilter] = useState("");

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const onChangePrices = useCallback((value) => {
    setPrices((old) => ({ ...old, highPrice: value[1], lowPrice: value[0] }));
  }, []);

  const onChange = useCallback((dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }, []);

  const tagsQuery = useQuery(TAGS, {
    variables: {
      filter: tagFilter,
    },
    skip: !searchFilters?.includes(SEARCH_FILTERS.TAGS),
  });

  const { data: inventoriesData } = useQuery(MY_INVENTORIES, {
    skip: !searchFilters?.includes(SEARCH_FILTERS.INVENTORIES),
  });

  const resetAll = useCallback(() => {
    setPaidStatus([]);
    setOrderStatus([]);
    setExcitonStatus([]);
    setStartDate(null);
    setEndDate(null);
    // setValue(null);
    setInventoryStock(null);
    setTags([]);
    setCategories([]);
    setProducts([]);

    setInventories([]);
    setBrands([]);
    setCountry("");
    setRegion("");
    setRegionCode("");
    setPoints({});

    setPrices({ highPrice: null, lowPrice: null });
    setEmail(null);
    setUserRole([]);
    // setResetFilter(true);
  }, []);

  const reset = useCallback(
    (filedName?: FilterFieldsNames) => {
      switch (filedName) {
        case "orderStatus":
          setOrderStatus([]);
          break;
        case "inventoryStock":
          setInventoryStock(null);
          break;
        case "paidStatus":
          setPaidStatus([]);
          break;
        case "excitonStatus":
          setExcitonStatus([]);
          break;
        case "orderDateRange":
          setStartDate(null);
          setEndDate(null);
          // setValue(null);
          break;
        case "inventories":
          setInventories([]);
          break;
        case "categories":
          setInventories([]);
          break;
        case "brands":
          setInventories([]);
          break;
        case "products":
          setProducts([]);
          break;
        case "countryAndRegion":
          setCountry("");
          setRegion("");
          setRegionCode("");
          break;
        default:
          resetAll();
          setDrawerFilter([]);
          setIsLoaded(false);
          handleParams("reset");
          setVisible(false);
          break;
      }
    },
    [handleParams, resetAll, setDrawerFilter, setVisible],
  );

  const addFilterField = (filedName: FilterFieldsNames, value: string) => {
    switch (filedName) {
      case "orderStatus":
        updateState(orderStatus, value, setOrderStatus);
        break;
      case "paidStatus":
        updateState(paidStatus, value, setPaidStatus);
        break;
      case "excitonStatus":
        updateState(executionStatus, value, setExcitonStatus);
        break;
      case "inventories":
        updateState(inventories, value, setInventories);
        break;
    }
  };

  const updateState = (state: any[], value: string, setState) => {
    if (!state.find((option) => option === value)) return setState((old) => [...old, value]);
    setState((old) => old.filter((option) => option !== value));
  };

  const orderFilterStore: OrderFilterContextProps = {
    paidStatus,
    orderStatus,
    executionStatus,
    inventories,
    reset,
    addFilterField,
  };

  const onFinish = () => {
    let filter = [];
    let queries: any = {};
    const { newFilter, newQueries } = addQueriesToFilter({
      tags,
      categories,
      brands,
      inventories,
      orderStatus,
      paidStatus,
      executionStatus,
      products,
    });
    queries = { ...queries, ...newQueries };
    filter = filter.concat(newFilter);

    if (email) {
      if (searchFilters?.includes(SEARCH_FILTERS.USER_EMAIL)) {
        filter.push({ user: { email: { contains: email, mode: "insensitive" } } });
      }
      if (searchFilters?.includes(SEARCH_FILTERS.EMAIL)) {
        filter.push({ email: { contains: email, mode: "insensitive" } });
      }
      queries.email = email;
    }
    console.log("🚀 ~ file: filterDrawer.tsx ~ line 240 ~ onFinish ~SEARCH_FILTERS points", points);
    console.log(
      "🚀 ~ file: filterDrawer.tsx ~ line 246 ~ onFinish ~ points[SEARCH_FILTERS.EARNED_POINTS]",
      points[SEARCH_FILTERS.EARNED_POINTS],
    );
    console.log(
      "🚀 ~ file: filterDrawer.tsx ~ line 241 ~ onFinish ~ SEARCH_FILTERS.EARNED_POINTS",
      SEARCH_FILTERS.EARNED_POINTS,
    );

    if (points && page === "loyalty") {
      const haveEarned =
        points[SEARCH_FILTERS.EARNED_POINTS]?.low ||
        Number(points[SEARCH_FILTERS.EARNED_POINTS]?.high);
      const haveUsed =
        points[SEARCH_FILTERS.USED_POINTS]?.low || Number(points[SEARCH_FILTERS.USED_POINTS]?.high);
      filter.push({
        OR: [
          {
            points: haveEarned
              ? {
                  gte:
                    points[SEARCH_FILTERS.EARNED_POINTS].low != null
                      ? Number(points[SEARCH_FILTERS.EARNED_POINTS].low)
                      : undefined,

                  lte:
                    points[SEARCH_FILTERS.EARNED_POINTS].high != null
                      ? Number(points[SEARCH_FILTERS.EARNED_POINTS].high)
                      : undefined,
                }
              : undefined,
          },
          {
            points: haveUsed
              ? {
                  lte:
                    points[SEARCH_FILTERS.USED_POINTS].low != null
                      ? -Number(points[SEARCH_FILTERS.USED_POINTS].low)
                      : undefined,

                  gte:
                    points[SEARCH_FILTERS.USED_POINTS].high != null
                      ? -Number(points[SEARCH_FILTERS.USED_POINTS].high)
                      : undefined,
                }
              : undefined,
          },
        ],
      });
      haveEarned && (queries.earnedPoints = points[SEARCH_FILTERS.EARNED_POINTS]);
      haveUsed && (queries.usedPoints = points[SEARCH_FILTERS.USED_POINTS]);
    }
    if (userRole.length) {
      filter.push({ role: { in: userRole } });
      queries.userRole = userRole;
    }
    if (startDate !== null && endDate !== null) {
      let gte = startDate;
      let lte = endDate;
      lte = getLastOfDay(lte);
      gte = getFirstOfDay(gte);
      filter.push({ createdAt: { lte, gte } });
      queries.startDate = gte;
      queries.endDate = lte;
    }

    // const priceQueryString = page == "order" ? "total" : "price";
    const switchQuery = (page) => {
      switch (page) {
        case "order":
          return "total";
        case "loyalty":
          return "points";
        default:
          return "price";
      }
    };
    const priceQueryString = switchQuery(page);
    if (prices.highPrice !== null || prices.lowPrice !== null) {
      let priseArgs: any = { [priceQueryString]: {} };
      if (
        prices.highPrice !== null &&
        prices.highPrice !== undefined &&
        !isNaN(prices.highPrice) &&
        prices.highPrice.toString().length > 0
      ) {
        priseArgs = {
          [priceQueryString]: {
            ...priseArgs[priceQueryString],
            lte: Number(prices.highPrice),
          },
        };
        queries.heighPrice = prices.highPrice;
      }

      if (
        prices.lowPrice !== null &&
        prices.lowPrice !== undefined &&
        !isNaN(prices.lowPrice) &&
        prices.lowPrice.toString().length > 0
      ) {
        console.log(
          "🚀 ~ file: filterDrawer.tsx ~ line 236 ~ onFinish ~ !isNaN(prices.lowPrice)",
          !isNaN(prices.lowPrice),
        );
        console.log(
          "🚀 ~ file: filterDrawer.tsx ~ line 236 ~ onFinish ~ prices.lowPrice",
          prices.lowPrice,
        );
        console.log(
          "🚀 ~ file: filterDrawer.tsx ~ line 241 ~ onFinish ~ Number(prices.lowPrice)",
          Number(prices.lowPrice),
        );
        priseArgs = {
          [priceQueryString]: {
            ...priseArgs[priceQueryString],
            gte: Number(prices.lowPrice),
          },
        };
        queries.lowPrice = prices.lowPrice;
      }
      if (!isNaN(priseArgs[priceQueryString].gte) || !isNaN(priseArgs[priceQueryString].lte))
        filter.push(priseArgs);
    }

    if (inventoryStock) {
      const isOutOfStock = inventoryStock === InventoryFilters.outOfStock;
      console.log(
        "🚀 ~ file: filterDrawer.tsx ~ line 220 ~ onFinish ~ inventoryStock",
        inventoryStock,
      );

      switch (inventoryStock) {
        case InventoryFilters.Backorder:
          filter.push({
            OR: [
              {
                doContinueOutOfStock: { equals: true },
              },
              {
                variants: {
                  some: {
                    doContinueOutOfStock: { equals: true },
                  },
                },
              },
            ],
          });
          break;

        case InventoryFilters.notTracking:
          filter.push({
            AND: [
              { trackQuantity: { equals: false } },
              {
                variants: {
                  every: {
                    trackQuantity: {
                      equals: false,
                    },
                  },
                },
              },
            ],
          });
          break;

        default:
          const stockStatus =
            inventoryStock === InventoryFilters.inStock ? "gt" : isOutOfStock ? "equals" : "lt";
          const productFilter: any = {
            quantityInStock: {
              [stockStatus]: 0,
            },
          };
          const variantsFilter: any = {
            variants: {
              some: {
                quantityInStock: {
                  [stockStatus]: 0,
                },
              },
            },
          };
          if (isOutOfStock) {
            productFilter.trackQuantity = { equals: true };
            variantsFilter.variants.some.trackQuantity = { equals: true };
          }

          filter.push({
            OR: [productFilter, variantsFilter],
          });
          break;
      }
      queries.inventoryStock = inventoryStock;
    }
    if (country) {
      filter.push({ address: { country: { name: { equals: country } } } });
      queries.country = country;
    }
    if (region || regionCode) {
      filter.push({
        address: { OR: [{ state: { equals: region } }, { state: { equals: regionCode } }] },
      });
      queries.region = region;
      queries.regionCode = regionCode;
    }

    setDrawerFilter(filter);
    console.log("🚀 ~ file: filterDrawer.tsx ~ line 262 ~ onFinish ~ filter", filter);
    console.log("🚀 ~ file: filterDrawer.tsx ~ line 343 ~ onFinish ~ queries", queries);
    if (!isLoaded) handleParams({ ...queries, page: 1 });
    setIsLoaded(false);
    setVisible(false);
    setFilterLoaded(true);
  };

  // useEffect(() => {
  //   if (firstTab) return setFirstTab(false);
  //   handleParams("reset");
  //   resetAll();
  //   setDrawerFilter([]);
  // }, [currentTab]);

  useEffect(() => {
    console.log("~ file: orderFilterDrawer.tsx ~ line 75 ~ useEffect ~ categories", categories);
    console.log("~ file: orderFilterDrawer.tsx ~ line 75 ~ useEffect ~ tags", tags);
    console.log("~ file: orderFilterDrawer.tsx ~ line 75 ~ useEffect ~ brands", brands);
    console.log("~ file: orderFilterDrawer.tsx ~ line 75 ~ useEffect ~ inventories", inventories);
    console.log("~ file: orderFilterDrawer.tsx ~ line 75 ~ useEffect ~ price", prices);
    console.log("~ file: orderFilterDrawer.tsx ~ line 75 ~ useEffect ~ price", country);
    console.log("~ file: orderFilterDrawer.tsx ~ line 75 ~ useEffect ~ price", region);
    // console.log("~ file: orderFilterDrawer.tsx ~ line 330 ~ productOrCategory", productOrCategory);
  }, [categories, tags, brands, inventories, prices, productOrCategory, country, region]);

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    query?.orderStatus && setOrderStatus(getDataFromQuery(query?.orderStatus));
    query?.executionStatus && setExcitonStatus(getDataFromQuery(query?.executionStatus));
    query?.paidStatus && setPaidStatus(getDataFromQuery(query?.paidStatus));
    query?.tags && setTags(getDataFromQuery(query?.tags));
    query?.categories && setCategories(getQueryDataAsNumArr(query?.categories));
    query?.products && setProducts(getQueryDataAsNumArr(query?.products));
    query?.brands && setBrands(getQueryDataAsNumArr(query?.brands));
    query?.inventories && setInventories(getQueryDataAsNumArr(query?.inventories));
    query?.inventoryStock && setInventoryStock(query?.inventoryStock || null);

    query?.userRole && setUserRole(getDataFromQuery(query?.userRole) as any);
    query?.email && setEmail(query?.email || "");
    query?.points && setPoints(query?.points || {});
    // query?.earnedPoints && setPoints(query?.points || {});
    query?.usedPoints && setPoints({ ...points, [SEARCH_FILTERS.USED_POINTS]: query?.usedPoints });
    query?.earnedPoints &&
      setPoints({ ...points, [SEARCH_FILTERS.EARNED_POINTS]: query?.earnedPoints });

    query?.country && setCountry(query?.country || "");
    query?.region && setRegion(query?.region || "");
    query?.regionCode && setRegionCode(query?.regionCode || "");

    if (query.startDate) setStartDate(new Date(query.startDate));
    if (query.endDate) setEndDate(new Date(query.endDate));
    setPrices({ highPrice: query.heighPrice || null, lowPrice: query.lowPrice || null });
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (isLoaded)
      setTimeout(() => {
        onFinish();
      }, 1);
  }, [isLoaded]);

  const filterComponentsProps = {
    onClose,
    visible,
    currentTab,
    searchFilters,
    orderStatus,
    tags,
    tagsQuery,
    inventoriesData,
    inventories,
    brands,
    categories,
    products,
    paidStatus,
    executionStatus,
    startDate,
    endDate,
    prices,
    inventoryStock,
    productOrCategory,
    region,
    regionCode,
    country,
    email,
    userRole,
    setUserRole,
    setRegion,
    setRegionCode,
    setEmail,
    setCountry,
    reset,
    onFinish,
    setTagFilter,
    setTags,
    setBrands,
    setCategories,
    setStartDate,
    setEndDate,
    onChange,
    onChangePrices,
    setInventoryStock,
    setProducts,
    setProductOrCategory,
    moreFilters,
    setPoints,
    points,
  };
  return (
    <OrderFilterContext.Provider value={orderFilterStore}>
      <Container>
        <FilterComponents {...filterComponentsProps} />
      </Container>
    </OrderFilterContext.Provider>
  );
};

const Container = styled.div``;

export default FilterDrawer;
function getDataFromQuery(query: any) {
  return typeof query == "string" ? (query as string)?.split(",") || [] : query;
}

function getQueryDataAsNumArr(query: any): any {
  console.log("🚀 ~ file: filterDrawer.tsx ~ line 578 ~ getQueryDataAsNumArr ~ query", query);
  return (query as string)?.split(",").map((inv) => Number(inv)) || [];
}
