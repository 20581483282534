import { Button, Col, Row, Space } from "antd";
import { useResponsive } from "../../../lib/useResponsive";
import MyImage from "../../common/image";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import logo from "../../../assets/imgs/navBarLogo.svg";
import { useTranslation } from "react-i18next";
import { GlobalOutlined } from "@ant-design/icons";
import { b7rPlatformUrl } from "../../../constants";

const LanguageChanger = styled(Button)``;
const Navbar = () => {
  const { lg, md } = useResponsive();
  let history = useHistory();

  const { i18n } = useTranslation();

  const changeLanguageHandler = () => i18n.changeLanguage(i18n?.language === "ar" ? "en" : "ar");
  // return null;
  return (
    <Container>
      {/* <div className="row-container"> */}
      <Row gutter={[0, 23]}>
        <Col xs={{ span: 24 }}>
          <Space size="large">
            <MyImage
              // onClick={() => history.push("https://www.b7r.store/")}
              onClick={() => {
                window.location.replace(b7rPlatformUrl);
              }}
              // src="https://g.top4top.io/p_197000xqg1.png"
              src={logo}
              alt="logo"
              style={{
                width: lg ? 108 : 60,
                display: "unset",
                cursor: "pointer",
              }}
            />
            {/* <LanguageChanger icon={<GlobalOutlined />} type="text" onClick={changeLanguageHandler}>
              {i18n.language === "ar" ? "EN" : "العربية"}
            </LanguageChanger> */}
          </Space>
        </Col>
      </Row>
      {/* </div> */}
    </Container>
  );
};
const Container = styled.div`
  /* padding-top: 30px; */
  background: ${(props) => props.theme.backgroundColors.lightGray};
  .ant-image {
    text-align: ${(props) => (props.theme.isBreakpoints.lg ? "initial" : "center")};
  }
`;
export default Navbar;
