import { gql } from "@apollo/client";

export const CURRENT_USER = gql`
  query CURRENT_USER {
    me {
      id
      role
      email
      name
      phone

      permissions
      avatar {
        id
        sm
        lg
      }

      currentShop {
        id
        name
        domain
        status
        features
        isInTrial
        subscripeWillEndAt
        onBoardingFlag
        apps
        balance
        dueAmount
        plan {
          slug
        }
        currentInvoice {
          value
          status
          start
          end
        }
        StoreActivation {
          id
          productsNumber
          editTheming
          editShipping
          editPaymentGatways
          editCheckoutSettings
          connectDomain
          installAffiliate
          installB7rChat
          connectTaager
          connectFaceBook
          connectFaceBookPixel
          installKnawat
          installB7rApp
          connectGoogleAnalytic
          connectGTM
          editLocations
          addUsers
          installPopUpApp
          useCustomersDivider
        }
      }
      # currentShop {
      #   id
      #   domain
      #   status
      #   features
      #   isInTrial
      #   subscripeWillEndAt
      #   onBoardingFlag
      #   apps
      #   plan {
      #     slug
      #   }
      # }
      stores {
        id
        name
        domain
        status
      }
      # store {
      #   id
      #   domain
      #   name
      #   plan {
      #     id
      #     name {
      #       auto
      #     }
      #     number
      #     value
      #     priceList
      #     description {
      #       auto
      #     }
      #     slug
      #   }
      #   features
      # }
      # shop {
      #   apps
      #   features
      #   name
      #   invoicesNum
      #   status
      #   id
      #   onBoardingFlag
      #   domain
      #   name
      #   plan {
      #     id
      #     name {
      #       auto
      #     }
      #     number
      #     value
      #     priceList
      #     description {
      #       auto
      #     }
      #     slug
      #   }
      # }
    }
  }
`;
