import React, { useState, useEffect } from "react";

import { Table, Button, Space, Popconfirm, Row, Col, Typography, Input } from "antd";
import gql from "graphql-tag";
import qs from "qs";

import { useMutation, useQuery } from "@apollo/client";

import { DeleteOutlined, EditOutlined, PlusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import { Link, useHistory, useLocation } from "react-router-dom";
import MyImage from "../../../../components/common/image";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import { GetNewPageData } from "../../../../lib/GetNewPageData";
import styled from "styled-components";
import EmptyPage from "../../../../components/common/empty";
import noCategories from "../../../../assets/imgs/noCategories.svg";
import { id } from "date-fns/locale";
import CustomSearch from "../../../../components/common/customSearch";
import Loading from "../../../../components/common/Loading";
import trash from "../../../../assets/imgs/trash-2.svg";
const { Search } = Input;

export const CATEGORIES = gql`
  query CATEGORIES($skip: Int, $filter: CategoryWhereInput) {
    categories(skip: $skip, take: 10, orderBy: { id: desc }, where: $filter) {
      count
      nodes {
        id
        productsCount
        name {
          id
          auto
        }
        image {
          id
          auto {
            id
            sm
            md
          }
        }
        children {
          id
          productsCount
          name {
            id
            auto
          }
          image {
            id
            auto {
              id
              sm
              md
            }
          }
          children {
            id
            productsCount
            name {
              id
              auto
            }
            image {
              id
              auto {
                id
                sm
                md
              }
            }
          }
        }
      }
    }
  }
`;
export const DELETE_CATEGORY = gql`
  mutation DELETE_CATEGORY($where: CategoryWhereUniqueInput!) {
    deleteOneCategory(where: $where) {
      id
    }
  }
`;
export const initialCategoryVariables = { parent: null };
const DataTable = ({ type = "PRODUCT" }) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const route = useHistory();
  console.log("🚀 ~ file: index.js ~ line 84 ~ DataTable ~ params", params);

  const onSearch = (value) => {
    setPagination({ current: 1 });
    setSearchKeyword(value);
    // if (value.trim() !== "") {
    route.push({
      pathname: type === "POST" ? "/postsCategories" : "/categories",
      search: `?search=${value}`,
    });
    // }
  };

  let querySearch = useLocation().search;
  querySearch = querySearch.slice(8);

  const [pagination, setPagination] = useState({ current: 1 });
  const [filterSearch] = useState(initialCategoryVariables);
  const [searchKeyword, setSearchKeyword] = useState(querySearch || "");
  //{ owner: { name: { contains: search, mode: "insensitive" } } });
  // title: {
  //   OR: [
  //     { ar: { contains: search, mode: "insensitive" } },
  //     { en: { contains: search, mode: "insensitive" } },
  //   ],
  // },
  const variables = {
    skip: (pagination.current - 1) * 10,
    filter: {
      ...filterSearch,
      type: { equals: type },
      OR: [
        {
          name: {
            OR: [
              { ar: { contains: searchKeyword, mode: "insensitive" } },
              { en: { contains: searchKeyword, mode: "insensitive" } },
            ],
          },
        },
        {
          children: {
            some: {
              name: {
                OR: [
                  {
                    ar: {
                      contains: searchKeyword,
                      mode: "insensitive",
                    },
                  },
                  {
                    en: {
                      contains: searchKeyword,
                      mode: "insensitive",
                    },
                  },
                ],
              },
            },
          },
        },
      ],
    },
  };
  const [removeCategory] = useMutation(DELETE_CATEGORY, {
    onCompleted: () => {
      GetNewPageData(pagination, setPagination);
    },
    update(cache, { data: { deleteOneCategory } }) {
      const { categories } = cache.readQuery({
        query: CATEGORIES,
        variables: variables,
      });
      cache.writeQuery({
        query: CATEGORIES,
        variables: variables,

        data: {
          categories: {
            count: categories.count - 1,
            nodes: categories.nodes.filter(({ id }) => deleteOneCategory.id !== id),
          },
        },
      });
    },
  });
  const { data, loading } = useQuery(CATEGORIES, {
    variables: variables,
    fetchPolicy: "network-only",
  });
  console.log("🚀 ~ file: index.js ~ line 120 ~ DataTable ~ data", data);
  useEffect(() => {
    setPagination({
      ...pagination,
      total: data?.categories?.count,
    });
    console.log("search:", searchKeyword);
  }, [data]);

  const handleTableChange = (pagination) => {
    setPagination({ ...pagination, current: pagination.current });
    route.push({
      pathname: route.location.pathname,
      search: qs.stringify({
        page: pagination.current || 1,
      }),
    });
  };

  const columns = () => {
    const cols = [
      {
        title: `${t("products.tableName")}`,
        render: (record) => (
          <Space>
            <MyImage image={record.image?.auto} width={50} height={50} />
            {/* {
              <img
                style={{ width: 50, height: 50 }}
                src={record.mImage?.sm}
                alt="img"
              />
            } */}
            <Link to={`/${type == "PRODUCT" ? "category" : "postsCategory"}/${record.id}`}>
              {record.name.auto}
            </Link>
          </Space>
        ),
      },
      {
        title: `${type == "PRODUCT" ? t("products.productCounts") : t("products.postsCounts")}`,
        render: (record) => record?.productsCount,
      },
      {
        align: "center",
        width: "50px",
        render: (record) => (
          <Link to={`/${type == "PRODUCT" ? "category" : "postsCategory"}/${record.id}`}>
            <EditOutlined />
          </Link>
        ),
      },
      {
        align: "center",
        width: "50px",
        render: (record) => (
          // <Space style={{ marginRight: 20 }}>
          //   <Link to={`/${type == "PRODUCT" ? "category" : "postsCategory"}/${record.id}`}>
          //     <EditOutlined />
          //   </Link>

          <Popconfirm
            title={t("products.deleteCategory")}
            onConfirm={() => removeCategory({ variables: { where: { id: record.id } } })}
            okText={t("posts.yes")}
            cancelText={t("posts.no")}>
            <MyImage src={trash} width={16} />
          </Popconfirm>
          // </Space>
        ),
      },
    ];

    return cols;
  };

  const removeChildrenWhenEmptyArray = (array) => {
    return array?.map((data) =>
      data.children?.length
        ? { ...data, children: removeChildrenWhenEmptyArray(data.children) }
        : { ...data, children: undefined },
    );
  };

  const query = qs.parse(route.location.search, {
    ignoreQueryPrefix: true,
  });
  useEffect(() => {
    if (query?.page) setPagination((old) => ({ ...old, current: parseInt(query.page) }));
  }, [query?.page]);

  if (loading) return <Loading />;
  return (
    <>
      {data?.categories?.count == 0 && searchKeyword == "" ? (
        <EmptyPage
          buttonText={t("products.addCategory")}
          buttonLink={`/${type == "PRODUCT" ? "categories/new" : "postsCategories/new"}`}
          description={t("products.noCategories")}
          image={noCategories}
        />
      ) : (
        <>
         
          <Row style={{ height: "100px" }} justify="space-between">
            <Col>
              <Typography.Title level={3} style={{ lineHeight: "100%" }}>
                {t("products.categories")}
              </Typography.Title>
            </Col>
            <Col style={{ height: "100%", lineHeight: "100%" }}>
              <Button
                onClick={() =>
                  route.push(`/${type == "PRODUCT" ? "categories" : "postsCategories"}/new`)
                }
                type="primary"
                icon={<PlusOutlined />}
                style={{ marginBottom: 32 }}>
                {t("products.addCategory")}
              </Button>
            </Col>
          </Row>
          <Row style={{ marginBottom: "30px" }}>
            <Col xs={24} lg={12}>
              <SearchContainer>
                <CustomSearch
                  defaultValue={searchKeyword}
                  allowClear
                  onSearch={onSearch}
                  placeholder={t("products.findCategory")}
                />
              </SearchContainer>
            </Col>
          </Row>
          <Table
            scroll={{ x: 500 }}
            loading={loading}
            rowKey={(record) => record.id}
            columns={columns()}
            expandable={{ defaultExpandAllRows: true }}
            size="small"
            bordered
            dataSource={removeChildrenWhenEmptyArray(data?.categories.nodes)}
            onChange={handleTableChange}
            pagination={{
              ...pagination,
              showSizeChanger: false,
              hideOnSinglePage: true,
              position: ["bottomCenter"],
            }}
          />
        </>
      )}
    </>
  );
};

const SearchContainer = styled.div`
  .ant-input {
    background-color: #fff;
  }

  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button:not(.ant-btn-primary) {
    color: #1985a1;
  }
`;

export default DataTable;
