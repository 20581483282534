import { Button, Modal, Space, Typography } from "antd";
import { useState } from "react";
import { Logo, LogoContainer } from "../layout/sider/sider.styles";
import logo from "../../assets/imgs/NewUpdatedLogo.svg";
import { useTranslation } from "react-i18next";
import registerationModal from "../../assets/imgs/registeratio-modal.svg";
import MyImage from "./image";

const { Text } = Typography;

const RegisterationMessageModal = ({ handleClickStart }) => {
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [collapsed, setCollapsed] = useState(true);
  const { t } = useTranslation();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    handleClickStart();
    setIsModalVisible(false);
  };
  return (
    <>
      <Modal
        centered={true}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        closable={false}
        style={{
          borderRadius: 12,
        }}
        className="message_registeration_modal">
        <Space
          size={10}
          direction="vertical"
          style={{ width: "100%", justifyContent: "center", textAlign: "center" }}>
          <MyImage src={registerationModal} width={64} />
          <Text className="congrat_message">{t("common.congratMessage")}</Text>
          <Text className="congrat_desc">{t("common.congratDesc")}</Text>
        </Space>
      </Modal>
    </>
  );
};

export default RegisterationMessageModal;
