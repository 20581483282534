import React, { useState, useEffect } from "react";
import {
  Form,
  Select,
  Button,
  InputNumber,
  Divider,
  Checkbox,
  Table,
  Input,
  Typography,
  Space,
  message,
  Spin,
  Row,
  Col,
  Menu,
  Dropdown,
} from "antd";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { MinusCircleOutlined, PlusOutlined, DeleteOutlined, UndoOutlined } from "@ant-design/icons";
import { FormCard } from "../style";
import ColorPicker from "rc-color-picker";
import debounce from "lodash/debounce";
import { QuantityItem } from "./quantityCard";
// import { ADD_ATTR_VALUE } from "../../../settings/attributes";
import EditModal from "../../../settings/attributes/edit";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import MyImage from "../../../../../components/common/image";
import trash from "../../../../../assets/imgs/trash-2.svg";
import plus from "../../../../../assets/imgs/plus-2.svg";
import { MY_INVENTORIES } from "../../../../settings/locations";

const { Text } = Typography;

export const OPTIONS = gql`
  query OPTIONS {
    attributes(take: 20) {
      nodes {
        id
        name
        type
      }
    }
  }
`;
export const OPTIONS_VALUES = gql`
  query OPTIONS_VALUES($filter: String, $attr: Int) {
    optionValues(
      where: {
        name: {
          OR: [
            { ar: { contains: $filter, mode: insensitive } }
            { en: { contains: $filter, mode: insensitive } }
          ]
        }
        attribute: { id: { equals: $attr } }
      }
      take: 10
    ) {
      id
      name
    }
  }
`;

export const makeLabelOption = (options) =>
  options.map((option) => Object.values(option).map((opt) => opt.label)).toString();
/**
 * renderComponent
 * @component
 * @name addCompanyModal
 * @memberof PRODUCTS
 * @summary admin can add company
 * @return {React} React component view
 */
const VariantCard = ({ hasVariants, options, form, dataSource, setDataSource, currency }) => {
  const optionsQuery = useQuery(OPTIONS);
  console.log("optionsQueryoptionsQueryoptionsQueryoptionsQuery", optionsQuery);
  const [filter, setFilter] = useState("");
  const [attr, setAttr] = useState(null);
  // const [label, setLabel] = useState(null);
  const [attId, setAttId] = useState(null);
  const { data, loading } = useQuery(MY_INVENTORIES);

  const [option, setOption] = useState({});
  const currencyCode = currency?.code;
  const code = currencyCode || "$";

  const handleCancel = (e) => {
    setAttId(null);
  };
  const optionsValuesQuery = useQuery(OPTIONS_VALUES, {
    fetchPolicy: "network-only",
    skip: !attr,
    variables: {
      filter,
      attr,
    },
  });

  const { Option } = Select;

  const { t } = useTranslation();

  const Quant = () => {
    return (
      <>
        <Space className="branches" size={8}>
          <Text>{t("products.quantity")}</Text>
          <Select
            value={t("products.allBranches")}
            // onChange={(val) => setBest(val)}
            className="select">
            {data?.inventories?.map(({ id }, index) => (
              <Option value={true}>
                <Typography.Text>
                  {t("products.branch")} {index + 1}
                </Typography.Text>
              </Option>
            ))}
          </Select>
        </Space>
      </>
    );
  };

  const columns = [
    {
      title: t("products.variant"),
      align: "center",
      width: "30%",
      render: (text, record) => {
        console.log(
          "record",
          record.options.map((option) => Object.values(option).map((opt) => opt.label)),
        );
        if (!record.valid)
          return {
            children: t("products.notCreated"),
            // props: {
            //   colSpan: 2
            // }
          };

        return makeLabelOption(record.options);
      },
    },
    {
      title: t("products.price"),
      align: "center",
      width: "30%",
      render: (text, record) => {
        if (
          !form.getFieldValue("variants") ||
          !form.getFieldValue("variants")[makeLabelOption(record.options)]?.price
        )
          form.setFieldsValue({
            variants: {
              ...form.getFieldValue("variants"),
              [makeLabelOption(record.options)]: {
                price: form.getFieldValue("price"),
                quantities: form.getFieldValue("quantities"),
              },
            },
          });
        if (record.valid)
          return (
            <Form.Item
              style={{
                marginBottom: "0",
              }}
              name={["variants", makeLabelOption(record.options), "price"]}>
              <InputNumber
                // onChange={val => {
                //   console.log("AddProduct -> val", val);

                //   let d = dataSource;
                //   console.log("AddProduct -> dataSource", dataSource[index].price);
                //   d[index].price = val;
                //   setDataSource(d);
                // }}
                // // defaultValue={form.getFieldValue("price")}
                // value={dataSource[index].price}
                formatter={(value) => `${code} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                parser={(value) => value.replace(/([A-z])|\$\s?|(,*)/g, "")}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          );
      },
    },
    {
      title: <Quant />,
      align: "center",
      width: "30%",
      render: (text, record) => {
        if (record.valid)
          return (
            <QuantityItem inTable faildName={["variants", makeLabelOption(record.options)]} />
            //   <Form.Item
            //     style={{
            //       marginBottom: "0",
            //     }}
            //     name={["variants", makeLabelOption(record.options), "quantity"]}
            //   >
            //     <InputNumber
            //       parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            //       style={{
            //         width: "100%",
            //       }}
            //     />
            //   </Form.Item>
          );
      },
    },
    {
      align: "center",
      width: "10%",
      render: (text, record, index) => {
        return (
          <Button
            style={{ border: 0, background: "transparent", boxShadow: "none", display: "flex" }}
            icon={
              record.valid ? (
                <MyImage
                  src={trash}
                  width={18}
                  className="dynamic-delete-button"
                  style={{ margin: "0 8px", cursor: "pointer" }}
                />
              ) : (
                <UndoOutlined />
              )
            }
            onClick={() => {
              //                 let temp=[...dataSource]

              // temp[index].valid = !dataSource[index].valid;
              //                 console.log(
              //                   "AddProduct -> dataSource[index].valid",
              //                   dataSource[index].valid
              //                 );
              //                 !dataSource[index].valid && setDataSource([...temp ]);
              dataSource[index].valid = !dataSource[index].valid;

              setDataSource([...dataSource]);
            }}
          />
        );
      },
    },
  ];
  // const addOption = (attId) => {
  //   if (!option.name) {
  //     message.error("must input a name");
  //   } else {
  //     optionsMutation[0]({ variables: { ...option, attId } }).then(() =>
  //       setOption({})
  //     );
  //   }
  // };

  const search = debounce((val) => setFilter(val), 200);
  const attributes = optionsQuery.data?.attributes.nodes || [];
  console.log("🚀 ~ file: variants.js ~ line 260 ~ attributes", attributes);
  return (
    <Container>
      <FormCard>
        <Form.Item name="hasVariants">
          <Checkbox.Group>
            <Checkbox
              value="hasVariants"
              style={{
                lineHeight: "32px",
                width: "100%",
              }}>
              <Text className="header">{t("products.variants")}</Text>
              <span className="subTitle"> ({t("products.multiOptions")}) </span>
            </Checkbox>
          </Checkbox.Group>
        </Form.Item>

        {hasVariants && hasVariants.length > 0 && (
          <>
            {/* <Divider /> */}
            {/* <h3>{t("products.options")}</h3> */}
            <Form.List name="options">
              {(fields, { add, remove }) => {
                const addFunc = (option) => {
                  console.log("🚀 ~ file: variants.js ~ line 294 ~ addFunc ~ option", option);
                  add();
                  const optionsCopy = form.getFieldValue("options");

                  let nextOption = attributes.find(
                    (op) =>
                      !form.getFieldValue("options").some((optionSome) => {
                        return optionSome?.label == op.id;
                      }),
                  );
                  optionsCopy[fields.length] = {
                    label: option ? option.id : nextOption?.id,
                  };
                  form.setFieldsValue({ options: optionsCopy });
                };
                if (fields.length == 0) addFunc();

                return (
                  <div>
                    <Space
                      align="baseline"
                      style={{ justifyContent: "space-between", width: "100%" }}>
                      <Text className="variant">{t("products.variants")}</Text>
                      {fields.length < 3 ? (
                        <Form.Item>
                          <Dropdown
                            placement="bottomCenter"
                            overlayStyle={{
                              width: 155,
                              borderRadius: 4,
                              boxShadow: "0px 0px 8px rgba(51, 51, 51, 0.08)",
                            }}
                            overlay={
                              <>
                                {attributes.map((option, index) => (
                                  <Menu
                                    mode="vertical"
                                    popupClassName="drop-variants"
                                    onClick={() => {
                                      const optionsCopy = form.getFieldValue("options");
                                      const isOptionFound = optionsCopy?.find(
                                        (opt) => opt?.label === option.id,
                                      );

                                      if (isOptionFound) return;
                                      addFunc(option);
                                    }}>
                                    <Menu.Item key={index}>{option.name}</Menu.Item>
                                  </Menu>
                                  // <Space direction="vertical">
                                  // <Text
                                  //   // key={option.id}
                                  //   // value={option.id}
                                  //   // disabled={form
                                  //   //   .getFieldValue("options")
                                  //   //   .some((optionSome) => optionSome.label == option.id)}
                                  //   onClick={addFunc}>
                                  //   {option.name}
                                  // </Text>
                                  // </Space>
                                ))}
                              </>
                            }
                            trigger={["click"]}>
                            {fields.length < 3 ? (
                              <Form.Item>
                                <Button
                                  type="link"
                                  onClick={(e) => {
                                    e.preventDefault();
                                  }}>
                                  <PlusOutlined /> {t("products.addOption")}
                                </Button>
                              </Form.Item>
                            ) : null}
                          </Dropdown>
                        </Form.Item>
                      ) : null}
                    </Space>
                    {fields.map((field, index) => {
                      const labelId = form.getFieldValue("options")[index]?.label;
                      return (
                        <>
                          <Form.Item
                            // {...(index === 0
                            //   ? formItemLayout
                            //   : formItemLayoutWithOutLabel)}
                            // label={`${t("products.option")} ${index + 1}`}
                            required={false}
                            key={field.key}>
                            {/* <Form.Item
                              fieldKey={[field.fieldKey, "label"]}
                              name={[field.name, "label"]}
                              labelCol={{span:24}}
                              noStyle
                              rules={[{ required: true }]}>
                                {attributes.map((option) => (
                                  <Text>
                                    {option.name}
                                  </Text>
                                ))}
                            </Form.Item> */}

                            <Form.Item
                              fieldKey={[field.fieldKey, "label"]}
                              name={[field.name, "label"]}
                              labelCol={{ span: 24 }}
                              noStyle
                              rules={[{ required: true }]}>
                              <Select
                                loading={optionsQuery.loading}
                                bordered={false}
                                showArrow={false}
                                open={false}
                                style={
                                  {
                                    // width: "auto",
                                  }
                                }>
                                {attributes.map((option) => (
                                  <Option
                                    key={option.id}
                                    value={option.id}
                                    disabled={form
                                      .getFieldValue("options")
                                      .some((optionSome) => optionSome.label == option.id)}>
                                    {option.name}
                                  </Option>
                                ))}
                              </Select>

                              {/* label dropdown variants */}
                              {/* <Select
                                loading={optionsQuery.loading}
                                bordered={false}
                                showArrow={false}
                                style={
                                  {
                                    // width: "auto",
                                  }
                                }>
                                {attributes.map((option) => (
                                  <Option
                                    key={option.id}
                                    value={option.id}
                                    disabled={form
                                      .getFieldValue("options")
                                      .some((optionSome) => optionSome.label == option.id)}>
                                    {option.name}
                                  </Option>
                                ))}
                              </Select> */}
                            </Form.Item>

                            <Row justify="space-between">
                              <Col span={14}>
                                <Form.Item
                                  fieldKey={[field.fieldKey, "value"]}
                                  name={[field.name, "value"]}
                                  noStyle>
                                  <Select
                                    rules={[{ required: true }]}
                                    mode="multiple"
                                    loading={optionsValuesQuery.loading}
                                    // style={{ width: "65%" }}
                                    filterOption={false}
                                    showArrow={true}
                                    // notFoundContent={
                                    //   optionsValuesQuery.loading ? (
                                    //     <Spin size="small" />
                                    //   ) : null
                                    // }
                                    labelInValue
                                    onSearch={search}
                                    showSearch
                                    onFocus={(e) => {
                                      setAttr(labelId);
                                      setFilter(e.target.value || "");
                                      attr && optionsValuesQuery.refetch();
                                    }}
                                    placeholder={t("products.options")}>
                                    {optionsValuesQuery.data?.optionValues.map((value) => (
                                      <Option key={value.id} value={value.id}>
                                        {value.name}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={10}>
                                <Space
                                  size={24}
                                  style={{
                                    justifyContent: "flex-end",
                                    width: "100%",
                                    padding: "0 18px",
                                  }}>
                                  <MyImage
                                    src={plus}
                                    width={18}
                                    style={{ margin: "0 8px", cursor: "pointer" }}
                                    onClick={() => {
                                      setAttId(
                                        attributes.find(({ id }) => labelId == id),
                                        // form.getFieldValue("options")[index].label
                                      );
                                    }}
                                  />
                                  {fields.length > 1 ? (
                                    <MyImage
                                      src={trash}
                                      width={18}
                                      className="dynamic-delete-button"
                                      style={{ margin: "0 8px", cursor: "pointer" }}
                                      onClick={() => {
                                        remove(field.name);
                                      }}
                                      // <MinusCircleOutlined
                                      //   className="dynamic-delete-button"
                                      //   style={{ margin: "0 8px" }}
                                      //   onClick={() => {
                                      //     remove(field.name);
                                      //   }}
                                    />
                                  ) : null}
                                </Space>
                              </Col>
                            </Row>
                            {/* <PlusOutlined
                              style={{ margin: "0 8px" }}
                              onClick={() => {
                                setAttId(
                                  attributes.find(({ id }) => labelId == id),
                                  // form.getFieldValue("options")[index].label
                                );
                              }}
                            /> */}
                          </Form.Item>
                          {/* {label == labelId && (
                          <Space
                            direction="vertical"
                            style={{
                              backgroundColor: "#fafafa",
                              padding: "15px",
                              marginBottom: 10,
                            }}
                          >
                            <Space>
                              <Typography.Text>name</Typography.Text>
                              <Input
                                value={option.name}
                                onChange={(e) =>
                                  setOption({ ...option, name: e.target.value })
                                }
                              />
                            </Space>
                            {getOptionVal(
                              attributes.find(({ id }) => labelId == id)
                            ) && (
                              <Space>
                                <Typography.Text>value</Typography.Text>
                                {getOptionVal(
                                  attributes.find(({ id }) => labelId == id)
                                )}
                              </Space>
                            )}
                            <Button
                              style={{
                                margin: "0 0 0 auto",

                                display: "block",
                              }}
                              type="dashed"
                              onClick={() => addOption(labelId)}
                            >
                              <PlusOutlined /> Add
                            </Button>
                          </Space>
                        )} */}
                        </>
                      );
                    })}
                    {/* {fields.length < 3 ? (
                      <Form.Item>
                        <Button type="dashed" onClick={addFunc} style={{ width: "40%" }}>
                          <PlusOutlined /> {t("products.addOption")}
                        </Button>
                      </Form.Item>
                    ) : null} */}
                  </div>
                );
              }}
            </Form.List>

            {options && options.some((option) => option?.value?.length) && (
              <>
                <Divider />

                <Table dataSource={dataSource} columns={columns} pagination={false} />
              </>
            )}
          </>
        )}
        <EditModal
          handleCancel={handleCancel}
          // handleOk={handleOk}
          // loading={addAttrProps.loading}
          visible={attId}
          attr={attId}
        />
      </FormCard>
    </Container>
  );
};

const Container = styled.div`
  .header {
    font-size: 16px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.black3};
  }
  .subTitle {
    font-size: 14px;
    font-weight: 600;
    color: ${(props) => props.theme.colors.Gray1};
  }
  .variant {
    font-size: 16px;
    font-weight: 600;
    color: ${(props) => props.theme.colors.black3};
  }
  .ant-btn-link {
    border-color: ${(props) => props.theme.customColors.primaryHover};
    font-weight: 600;
    font-size: 14px;
  }
  .ant-table-thead > tr > th {
    background: ${(props) => props.theme.backgroundColors.gray4};
    font-size: 14px;
    font-weight: 600;
    color: ${(props) => props.theme.colors.black3};
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-right-radius: 8px;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-left-radius: 8px;
  }
  .ant-input-number-input {
    height: 40px;
  }
  .ant-input-number {
    border: 1px solid #e0e0e0;
  }
  .branches {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: 0;
      background: transparent;
    }
    .ant-select-selection-item {
      font-size: 14px;
      font-weight: 600;
      color: #1985a1;
    }
  }
`;

export default VariantCard;
