export enum SEARCH_FILTERS {
  TAGS,
  CATEGORIES,
  INVENTORIES,
  BRANDS,
  ORDER_STATUS,
  PAYOFF_STATUS,
  EXECUTION_STATUS,
  DATE_RANGE,
  PRICING,
  PRODUCTS,
  USER_EMAIL,
  INVENTORIES_STOCK,
  PRODUCT_OR_CATEGORY,
  COUNTRY_AND_STATES,
  EMAIL,
  USER_ROLES,
  EARNED_POINTS,
  USED_POINTS,
}
// export const SEARCH_FILTERS = {
//   TAGS: "TAGS",
//   CATEGORIES:"CATEGORIES",
//   INVENTORIES:"INVENTORIES",
//   BRANDS:"BRANDS",
//   ORDER_STATUS:"ORDER_STATUS",
//   PAYOFF_STATUS:"PAYOFF_STATUS",
//   EXECUTION_STATUS:"EXECUTION_STATUS",
//   DATE_RANGE:"DATE_RANGE",
//   PRICING:"PRICING",
//   PRODUCTS:"PRODUCTS",
//   USER_EMAIL:"USER_EMAIL",
//   INVENTORIES_STOCK:"INVENTORIES_STOCK",
//   PRODUCT_OR_CATEGORY:"PRODUCT_OR_CATEGORY",
//   COUNTRY_AND_STATES,
//   EMAIL,
//   USER_ROLES,
//   EARNED_POINTS,
//   USED_POINTS,
// };
