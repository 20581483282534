import { Statues } from "../pages/orders/hooks/useOrderFilter";

export function getOrderStatuses(t, notSelect: Statues[] = []) {
  const defaultStatues = [
    { value: Statues.pending, text: t("order.pending") },
    { value: Statues.open, text: t("order.open") },
    { value: Statues.archived, text: t("order.archived") },
    { value: Statues.completed, text: t("order.completed") },
    { value: Statues.canceled, text: t("order.canceled") },
  ];
  return defaultStatues.filter((state) => !notSelect.includes(state.value));
}
