import React from "react";
import { Switch, Route } from "react-router-dom";
import { checkoutRoutes, routes } from "./routes";
import IsAllow from "./isAllow";
import CommonError from "../pages/error";
import { useQuery } from "@apollo/client";
import { Spin } from "antd";
import { CURRENT_USER } from "./Me";
import Renew from "../pages/profile/renew";
import RenewSubscription from "../pages/profile/renew/cards/renewSubscription";
import { SETTINGS } from "../pages/settings/generals/card/defaultLanguage";

const ActiveStore = () => {
  const { data, loading } = useQuery(CURRENT_USER, { fetchPolicy: "cache-first" });
  const { loading: settingLoading } = useQuery(SETTINGS, { fetchPolicy: "cache-first" });
  console.log(
    "🚀 ~ file: storeActive.js ~ line 17 ~ ActiveStore ~ loading || settingLoading",
    loading || settingLoading,
  );
  if (loading || settingLoading)
    return <Spin size="large" className="large-loading absolute-loading" />;
  const me = data?.me;
  const newRoutes = [...checkoutRoutes, {}];

  console.log("🚀 ~ file: storeActive.js ~ line 15 ~ ActiveStore ~ me", me);
  const isStoreStopped = me?.currentShop?.status === "STOPPED";
  // const isStoreStopped = false;

  return (
    <Switch>
      {(isStoreStopped ? checkoutRoutes : routes).map((route, idx) => {
        return route.component ? (
          <IsAllow
            key={idx}
            route={route}
            path={route.path}
            exact={!route.notExact}
            render={(props) => <route.component {...props} />}
          />
        ) : null;
      })}
      <Route
        path=""
        component={
          !isStoreStopped ? CommonError : me?.shop?.invoicesNum === 1 ? RenewSubscription : Renew
        }
      />
    </Switch>
  );
};
export default ActiveStore;
