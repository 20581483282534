export const USER_ROLES = [
//   "CASHER",
//   "VENDOR",
  "CUSTOM",
  "CUSTOMER",
//   "BUYER",
//   "EMPLOYER",
//   "INVENTORY",
//   "ADMIN",
//   "SUPER_ADMIN",
];
