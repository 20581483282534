import { FileAddOutlined } from "@ant-design/icons";
import { gql, NetworkStatus, useMutation, useQuery } from "@apollo/client";
import { Button, Card, DatePicker, Form, Modal, Space, Spin, Upload } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import imagePlace from "../../../../../assets/imgs/imagePlace.svg";
import CustomSearch from "../../../../../components/common/customSearch";
import MyImage from "../../../../../components/common/image";
import { getFirstOfDay, getLastOfDay } from "../../../../../lib/getDayOf";
import { uploadImage, uploadVideo } from "../../../../../utlis/upload";
import { uploadButton } from "../../../../theme/slides/addEdit";
import UnsplashReact, {
  withDefaultProps,
  Base64Uploader,
  ExternalLocationUploader,
  BlobUploader,
  InsertIntoApplicationUploader,
} from "unsplash-react";
export const IMAGES = gql`
  query ($imagesSkip: Int = 0, $imagesTake: Int = 10, $imagesWhere: ImageWhereInput) {
    images(skip: $imagesSkip, take: $imagesTake, orderBy: { id: desc }, where: $imagesWhere) {
      count
      nodes {
        original
        id
        mediaType
      }
    }
  }
`;
export const ADD_IMAGE = gql`
  mutation ADD_IMAGE($image: ImageInput!, $type: String) {
    addImage(image: $image, type: $type) {
      id
      original
    }
  }
`;

const CustomDatePicker = styled(DatePicker)`
  * {
    color: #1985a1 !important;
  }
`;
const ChooseImg = ({
  setChossingImage,
  defaultImage,
  // addImage,
  firstImages,
}) => {
  console.log("🚀 ~ file: chooseImage.js ~ line 45 ~ defaultImage", defaultImage);
  const [loading, setLoading] = useState(false);
  const [gallery, setGallery] = useState(firstImages || []);
  const [image, setImage] = useState(defaultImage);
  const [imagesSkip, setImagesSkip] = useState(10);
  const [search, setSearch] = useState(null);
  const [searchVal, setSearchVal] = useState("");
  const [dateValue, setDateValue] = useState(null);
  const [mediaType, setMediaType] = useState("IMAGE");
  // const [searchVars, setSearchVars] = useState({
  //   searchText: null,
  //   skip: 0,
  // });

  console.log("defaultImage", image);
  const [visible, setVisible] = useState(false);
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const andArr = [];
  const orArr = [];
  if (dateValue) {
    let gte = getFirstOfDay(new Date(dateValue));
    let lte = getLastOfDay(new Date(dateValue));
    andArr.push({ createdAt: { lte, gte } });
  }

  if (search)
    orArr.push({
      products: {
        some: {
          OR: [
            {
              categories: {
                some: {
                  name: {
                    OR: [
                      {
                        ar: {
                          contains: search,
                          mode: "insensitive",
                        },
                      },
                      {
                        en: {
                          contains: search,
                          mode: "insensitive",
                        },
                      },
                    ],
                  },
                },
              },
            },
            {
              title: {
                OR: [
                  {
                    ar: {
                      contains: search,
                      mode: "insensitive",
                    },
                  },
                  {
                    en: {
                      contains: search,
                      mode: "insensitive",
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    });
  console.log("🚀 ~ file: chooseImage.js ~ line 55 ~ orArr", orArr);

  const searchWhere = {
    mediaType: {
      equals: mediaType,
    },
    // OR: orArr,
    AND: andArr.concat([{ OR: orArr }]),
  };

  const imagesVariables = {
    imagesWhere: searchWhere,
    // imagesSkip: searchVars.skip,
  };
  console.log("🚀 ~ file: chooseImage.js ~ line 114 ~ searchWhere", searchWhere);
  const {
    data,
    fetchMore,
    loading: imagesLoading,
    networkStatus,
  } = useQuery(IMAGES, {
    notifyOnNetworkStatusChange: true,
    variables: imagesVariables,
  });
  console.log("🚀 ~ file: chooseImage.js ~ line 130 ~ data", data);

  useEffect(() => {
    console.log("🚀 ~ file: chooseImage.js ~ line 130 ~ data", data);
  }, [data]);
  const [addImage] = useMutation(ADD_IMAGE);

  const addImageFunc = async (uploadedImage) => {
    console.log(
      "🚀 ~ file: chooseImage.js ~ line 156 ~ addImageFunc ~ uploadedImage",
      uploadedImage,
    );
    const { data } = await addImage({
      variables: { image: { ...uploadedImage.data, mediaType } },
    });
    const { original, id } = data?.addImage;
    return {
      uid: id,
      url: original,
    };
  };

  //   console.log("defaultImage", defaultImage);
  //   console.log("image", image);
  //   const handleImage = async ({ fileList }) => {
  //     const last = fileList.length - 1;
  //     if (!fileList[0].url && !fileList[last].preview) {
  //       fileList[last].preview = await getBase64(fileList[last].originFileObj);
  //     }
  //     setIamgeUrl(fileList[last].url || fileList[last].preview);
  //   };

  useEffect(() => {
    console.log("datasdcdsssss", data);

    data !== undefined &&
      setGallery([
        // ...gallery,
        ...data?.images?.nodes?.map(({ id, original }) => ({
          uid: id,
          url: original,
        })),
      ]);
  }, [data]);

  useEffect(() => {
    setImage(defaultImage);
  }, [defaultImage]);

  const onSearch = (value) => {
    setSearch(value);
    setImagesSkip(10);
    // setSearchVars({ searchText: value, skip: 0 });
  };
  function onChangeDate(date, dateString) {
    console.log("onChangeDate", date, dateString);
    setDateValue(date);
  }
  const onChange = async (file) => {
    setLoading(true);
    console.log("1111111111111111");
    const uploadedImage = await (mediaType === "IMAGE" ? uploadImage(file) : uploadVideo(file));
    console.log("🚀 ~ file: chooseImage.js ~ line 210 ~ onChange ~ uploadedImage", uploadedImage);
    console.log("2222222222222222");
    const comingImage = await addImageFunc(uploadedImage);
    console.log("333333333333333");

    console.log("🚀 ~ file: chooseImage.js ~ line 177 ~ onChange ~ comingImage", comingImage);
    setGallery((old) => [...old, comingImage]);
    setLoading(false);
  };
  //   const ChooseImgButtone = (
  //     <div className="ant-upload ant-upload-select ant-upload-select-picture-card">
  //       <Button
  //         type="text"
  //         className="ant-upload"
  //         onClick={() => setVisible(true)}
  //       >
  //         {loading ? (
  //           <Spin />
  //         ) : (
  //           <>
  //             <PlusOutlined />
  //             <div className="ant-upload-text">Upload</div>
  //           </>
  //         )}
  //       </Button>
  //     </div>
  //   );

  const uploaderProps = {
    listType: "picture-card",
    fileList: gallery,
    accept: mediaType === "VIDEO" ? "video/*" : "image/*",
    multiple: true,
    itemRender: (item, file, fileList, actions) => {
      console.log(
        "🚀 ~ file: chooseImage.js ~ line 386 ~ file, fileList, actions",
        file,
        fileList,
        actions,
      );
      console.log("🚀 ~ file: chooseImage.js ~ line 386 ~ item", item);
      return (
        <MyImage
          thumb
          onClick={() => {
            setImage(file);
            setChossingImage(file);
            setVisible(false);
          }}
          src={file.url}
          alt="avatar"
          wrapperStyle={{ width: "100%", height: "100%", cursor: "pointer" }}
          style={{ width: "100%", height: "100%" }}
        />
      );
    },
    // showUploadList: {
    //   showPreviewIcon: false,
    //   // downloadIcon: "download ",
    //   showRemoveIcon: true,
    //   removeIcon: <FileAddOutlined style={{ color: "#fff", fontSize: 20 }} onClick={() => {}} />,
    // },

    // onRemove: (file) => {
    //   setImage(file);
    //   setChossingImage(file);
    //   setVisible(false);

    //   return false;
    // },
    beforeUpload: (file) => {
      onChange(file);
      return false;
    },
  };
  const dateFormat = "YYYY/MM/DD";
  console.log(
    "🚀 ~ file: chooseImage.js ~ line 335 ~ imagesSkip * 10 < data?.images.count",
    imagesSkip,
    data?.images.count,
  );
  const uploadTabList = [
    {
      key: "IMAGE",
      tab: t("products.images"),
    },
    {
      key: "VIDEO",
      tab: t("products.videos"),
    },
  ];

  return (
    <>
      <Form.Item
        name={["image", "en"]}
        // valuePropName="fileList"
        // getValueFromEvent={normFile}
        // label="Image"
        // rules={[{ required: true }]}

        noStyle>
        <div
          style={{
            width: "100%",
            // height: "50vh",
            minHeight: 112,
            marginRight: 0,
            background: "rgba(110, 187, 197, 0.02)",
            border: "1px dashed rgba(22, 54, 99, 0.12)",
            height: "-webkit-fill-available",
            overflow: "hidden",
            // backgroundColor: "yellow",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="ant-upload ant-upload-select ant-upload-select-picture-card">
          <Button
            type="text"
            style={{
              width: "100%",
              //  backgroundColor: "green"
            }}
            className="ant-upload"
            onClick={() => setVisible(true)}>
            {loading ? (
              <Spin />
            ) : image?.url ? (
              <MyImage
                thumb
                src={image.url}
                alt="avatar"
                style={{
                  // width: "-webkit-fill-available",
                  maxWidth: "100%",
                  height: "-webkit-fill-available",
                  overflow: "hidden",
                }}
              />
            ) : (
              <Space
                size={5}
                direction="vertical"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  // backgroundColor: "red",
                  height: "100%",
                  width: "100%",
                }}>
                {/* {language === "en" && <PlusOutlined />} */}
                <MyImage thumb src={imagePlace} width={24} className="ant-upload-drag-icon" />
                <div className="ant-upload-text">{t("products.addmedia")}</div>
                {/* {language === "ar" && <PlusOutlined />} */}
              </Space>
            )}
          </Button>
        </div>
      </Form.Item>

      <Modal
        // title="Basic Modal"
        visible={visible}
        footer={null}
        bodyStyle={{ padding: "24px 0" }}
        onCancel={() => setVisible(false)}>
        <div style={{ padding: "0 24px" }}>
          <CustomSearch
            placeholder={t("home.imageSearch")}
            onSearch={onSearch}
            className="search-filter-input"
            value={searchVal}
            allowClear
            onChange={(val) => {
              setSearchVal(val.target.value);
            }}
            style={{ marginBottom: "20px", maxWidth: "300px" }}
          />

          <CustomDatePicker
            bordered={false}
            format={dateFormat}
            value={dateValue && moment(dateValue)}
            style={{
              color: "red",
            }}
            onChange={onChangeDate}
          />
        </div>
        <Card
          style={{ width: "100%" }}
          bodyStyle={{ padding: "24px 10px" }}
          bordered={false}
          tabList={uploadTabList}
          onTabChange={(key) => {
            setMediaType(key);
          }}>
          {/* <AjaxExample /> */}
          <Upload {...uploaderProps}>{uploadButton(loading, t, mediaType)}</Upload>

          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            {imagesSkip < data?.images.count && (
              <Button
                loading={
                  networkStatus === NetworkStatus.loading ||
                  networkStatus === NetworkStatus.fetchMore
                }
                onClick={() => {
                  fetchMore({
                    variables: { ...imagesVariables, imagesSkip },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      setImagesSkip((old) => old + 10);
                      if (!fetchMoreResult || fetchMoreResult?.images?.nodes.length === 0)
                        return prev;

                      const newData = Object.assign({}, prev, {
                        images: {
                          count: fetchMoreResult.images.count,
                          nodes: [...fetchMoreResult.images.nodes, ...prev.images.nodes],
                        },
                      });
                      console.log("🚀 ~ file: chooseImage.js ~ line 222 ~ newData", newData);
                      return newData;
                    },
                  });
                }}
                type="text"
                style={{ textAlign: "center" }}>
                {`${t("plans.showMore")}.....`}
              </Button>
            )}
          </div>
        </Card>
      </Modal>
    </>
  );
};

export default ChooseImg;

function Base64Example() {
  return (
    <form method="POST" action="https://httpbin.org/post" encType="multipart/form-encoded">
      <div style={{ height: "350px" }}>
        <UnsplashReact
          accessKey={process.env.UNSPLASH_ACCESS_KEY}
          applicationName="unsplash_react"
          Uploader={withDefaultProps(Base64Uploader, { name: "group[image]" })}
          columns={3}
          photoRatio={3 / 2}
          preferredSize={{ width: 1024, height: 768 }}
        />
      </div>

      <input
        type="submit"
        value="Send base64 data to httpbin.org"
        style={{ marginTop: "2rem", fontSize: "2em" }}
      />
    </form>
  );
}

class AWSExample extends React.Component {
  state = { uploadResponseJson: null };

  handleFinishedUploading = (response) => {
    response.json().then(() => this.setState({ uploadResponseJson: { done: true } }));
  };

  render() {
    const { uploadResponseJson } = this.state;
    return (
      <div>
        {uploadResponseJson && <pre>{JSON.stringify(uploadResponseJson, null, 4)}</pre>}
        <div style={{ height: "350px" }}>
          <UnsplashReact
            accessKey={process.env.UNSPLASH_ACCESS_KEY}
            applicationName="unsplash_react"
            Uploader={withDefaultProps(ExternalLocationUploader, {
              name: "file",
              uploadUrl: "https://httpbin.org/post",
            })}
            columns={4}
            photoRatio={4 / 2}
            onFinishedUploading={this.handleFinishedUploading}
          />
        </div>
      </div>
    );
  }
}

class AjaxExample extends React.Component {
  state = { uploadResponseJson: null };

  handleBlobLoaded = (blob) => {
    const formData = new FormData();
    formData.append("file", blob, "image.jpg");

    return fetch("https://httpbin.org/post", {
      method: "POST",
      body: formData,
    })
      .then((r) => r.json())
      .then(() => this.setState({ uploadResponseJson: { done: true } }));
  };

  render() {
    const { uploadResponseJson } = this.state;
    return (
      <div>
        {uploadResponseJson && <pre>{JSON.stringify(uploadResponseJson, null, 4)}</pre>}
        <div style={{ height: "350px" }}>
          <UnsplashReact
            accessKey={"kBFdzpXwweJLB8qFnvMVz4JZt1_N_uu9Fo--r0ELIb8"}
            applicationName="unsplash_react"
            Uploader={withDefaultProps(BlobUploader, {
              onBlobLoaded: this.handleBlobLoaded,
            })}
            columns={3}
            photoRatio={1}
          />
          {/* 563492ad6f917000010000019f066bf384b44327ad9e751d5e985e18 */}
        </div>
      </div>
    );
  }
}

class InsertIntoApplicationExample extends React.Component {
  state = { imageUrl: null };

  handleFinishedUploading = (imageUrl) => {
    this.setState({ imageUrl });
  };

  render() {
    const { imageUrl } = this.state;

    return (
      <div style={{ display: "flex" }}>
        <div style={{ height: "350px", width: "450px" }}>
          <UnsplashReact
            accessKey={process.env.UNSPLASH_ACCESS_KEY}
            applicationName="unsplash_react"
            Uploader={InsertIntoApplicationUploader}
            photoRatio={16 / 9}
            preferredSize={{ width: 800, height: 450 }}
            onFinishedUploading={this.handleFinishedUploading}
          />
        </div>

        <div>
          <img src={imageUrl} />
        </div>
      </div>
    );
  }
}

const EXAMPLE_TYPES = ["base64", "aws", "ajax", "insert_into_application"];

class ExampleComponent extends React.Component {
  state = {
    exampleType: "base64",
  };

  handleExampleTypeChange = (e) => {
    this.setState({ exampleType: e.target.value });
  };

  render() {
    const { exampleType } = this.state;
    return (
      <div style={{ width: exampleType === "insert_into_application" ? "" : "450px" }}>
        <div
          style={{ paddingBottom: "1rem", marginBottom: "1rem", borderBottom: "2px solid gray" }}>
          <label htmlFor="example-selector" style={{ marginRight: "1rem" }}>
            Example:
          </label>
          <select id="example-selector" onChange={this.handleExampleTypeChange}>
            {EXAMPLE_TYPES.map((e) => (
              <option key={e}>{e}</option>
            ))}
          </select>
        </div>
        {exampleType === "base64" ? (
          <Base64Example />
        ) : exampleType === "aws" ? (
          <AWSExample />
        ) : exampleType === "ajax" ? (
          <AjaxExample />
        ) : exampleType === "insert_into_application" ? (
          <InsertIntoApplicationExample />
        ) : (
          <div>Unknown example {this.state.exampleType}</div>
        )}
      </div>
    );
  }
}
