import { gql } from "@apollo/client";

export const GET_ROOMS = gql`
  query GET_ROOMS($where: RoomWhereInput, $take: Int = 20, $skip: Int = 0) {
    rooms(orderBy: { updatedAt: desc }, where: $where, take: $take, skip: $skip) {
      count
      nodes {
        id
        slug
        roomName
        notSeenNum
        lastMessage {
          id
          message
          createdAt
          user {
            id
            name
            # avatar {
            #   md
            # }
          }
        }
      }
    }
  }
`;

export const SEND_CHAT_MESSAGE = gql`
  mutation SEND_CHAT_MESSAGE($chatSlug: String, $message: String!) {
    sendMessageToChat(chatSlug: $chatSlug, message: $message) {
      id
      message
      createdAt
      user {
        id
        name
      }
    }
  }
`;
