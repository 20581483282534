import { Col, Form, Input, Row, Spin, Switch, Typography } from "antd";
import { FormCard } from "../../../../products/products/addEdit/style";
import { useTranslation } from "react-i18next";
import { AppLanguageSwitch } from "../../../../../components/common/websiteLanguage";
import { useState } from "react";
import styled from "styled-components";
import i18next from "i18next";
import { LangSpin } from "../../../../products/products/addEdit/cards/addCategoryModal";
const { TextArea } = Input;
const { Title } = Typography;

const ProfileForm = () => {
  const { t } = useTranslation();
  const infoForm = [
    {
      id: 1,
      label: t("settings.facebook"),
      name: "facebook",
    },
    {
      id: 2,
      label: t("settings.instagram"),
      name: "instagram",
    },
    {
      id: 3,
      label: t("settings.pinterest"),
      name: "pinterest",
    },
    {
      id: 4,
      label: t("settings.twitter"),
      name: "twitter",
    },
    {
      id: 5,
      label: t("settings.phone"),
      name: "phone",
    },
    {
      id: 6,
      label: t("settings.email"),
      name: "email",
    },
  ];
  return infoForm;
};

const ProfileCard = ({ type }) => {
  const { t, i18n } = useTranslation();
  const [isEnCardOne, setIsEnCardOne] = useState();
  const [isEnCardtwo, setIsEnCardTwo] = useState();
  const [firstLangLoad, setFirstLangLoad] = useState(false);
  const infoForm = ProfileForm();
  return (
    <>
      <FormCard>
        <Title level={3}>{t("settings.profile")}</Title>
        <Row gutter={[26, 26]}>
          {infoForm.map((fm) => (
            <Col span={12} key={fm.id}>
              <Form.Item label={fm.label} name={fm.name}>
                <Input />
              </Form.Item>
            </Col>
          ))}
        </Row>
        {type == "web" ? (
          <AppLanguageSwitchContainer>
            <AppLanguageSwitch
              input={true}
              isEn={isEnCardOne}
              setIsEn={setIsEnCardOne}
              firstLangLoad={firstLangLoad}
              setFirstLangLoad={setFirstLangLoad}
            />

            {!firstLangLoad ? (
              <LangSpin />
            ) : (
              <div style={{ zIndex: 10 }}>
                {isEnCardOne ? (
                  <>
                    <Form.Item label={t("settings.address")} name={["address", "en"]}>
                      <Input />
                    </Form.Item>
                  </>
                ) : (
                  <>
                    <Form.Item label={t("settings.address")} name={["address", "ar"]}>
                      <Input />
                    </Form.Item>
                  </>
                )}
              </div>
            )}
          </AppLanguageSwitchContainer>
        ) : null}
      </FormCard>
      {type == "web" ? (
        <AppLanguageSwitchContainerTwo>
          <AppLanguageSwitch
            isEn={isEnCardtwo}
            setIsEn={setIsEnCardTwo}
            firstLangLoad={firstLangLoad}
            setFirstLangLoad={setFirstLangLoad}
          />
          {!firstLangLoad ? (
            <LangSpin />
          ) : (
            <FormCard>
              <Row justify="space-between" align="middle">
                <Col style={{ textAlign: "right" }}>
                  <Typography.Title level={4}>{t("settings.storeDetails")}</Typography.Title>
                </Col>
                {/* <Switch
              style={{}}
              checkedChildren="AR"
              unCheckedChildren="EN"
              onChange={() =>  setIsEn(!isEn)}
              checked={isEn}
            /> */}
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  {isEnCardtwo ? (
                    <>
                      <Form.Item
                        label={t("title")}
                        name={["addressTitle", "en"]}
                        rules={[{ required: false }]}>
                        <Input />
                      </Form.Item>
                    </>
                  ) : (
                    <>
                      <Form.Item
                        label="العنوان"
                        name={["addressTitle", "ar"]}
                        rules={[{ required: false }]}>
                        <Input placeholder="" />
                      </Form.Item>
                    </>
                  )}

                  {isEnCardtwo ? (
                    <>
                      <Form.Item name={["addressDesc", "en"]} label="About Store">
                        <TextArea />
                      </Form.Item>
                    </>
                  ) : (
                    <>
                      <Form.Item name={["addressDesc", "ar"]} label="عن المتجر">
                        <TextArea />
                      </Form.Item>
                    </>
                  )}
                </Col>
              </Row>
            </FormCard>
          )}
        </AppLanguageSwitchContainerTwo>
      ) : null}{" "}
    </>
  );
};

export const AppLanguageSwitchContainer = styled.div`
  position: relative;
`;
const AppLanguageSwitchContainerTwo = styled.div`
  position: relative;
`;
export default ProfileCard;
