import { Button, Col, Divider, Row, Space, Tooltip, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import MyImage from "../../../../../components/common/image";
import { FormCard } from "../style";
import hint from "../../../../../assets/imgs/hint.svg";
import edit from "../../../../../assets/imgs/edit.svg";
import { CURRENT_USER } from "../../../../../utlis/Me";
import { useQuery } from "@apollo/client";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

const { Text } = Typography;
interface Props {
  pageType: "CATEGORY" | "PRODUCT" | "PAGE" | "POST";
  pageId: number;
  theme: any;
}
const EditThemeProduct = ({ theme, pageId, pageType }: Props) => {
  const { t } = useTranslation();
  const { data } = useQuery(CURRENT_USER, {
    fetchPolicy: "cache-first",
  });
  const getText = (isAdd?) => {
    switch (pageType) {
      case "PRODUCT":
        return isAdd
          ? t("products.addTheme", { pageName: "المنتج" })
          : t("products.editTheme", { pageName: "المنتج" });
      case "CATEGORY":
        return isAdd
          ? t("products.addTheme", { pageName: "الفئة" })
          : t("products.editTheme", { pageName: "الفئة" });
      case "PAGE":
        return isAdd
          ? t("products.addTheme", { pageName: "الصفحة" })
          : t("products.editTheme", { pageName: "الصفحة" });
      case "POST":
        return isAdd
          ? t("products.addTheme", { pageName: "المقالة" })
          : t("products.editTheme", { pageName: "المقالة" });

      default:
        return t("products.addTheme", { pageName: "المنتج" });
    }
  };
  const getTitle = () => {
    switch (pageType) {
      case "PRODUCT":
        return <CustomTitle pageName="المنتج" />;
      case "CATEGORY":
        return <CustomTitle pageName="الفئة" />;
      case "PAGE":
        return <CustomTitle pageName="الصفحة" />;
      case "POST":
        return <CustomTitle pageName="المقالة" />;

      default:
        return t("products.themeProduct");
    }
  };
  return (
    <FormCard>
      <Row justify="space-between" gutter={[16, 10]}>
        <Col md={16}>{getTitle()}</Col>
        <Col>
          <Button type="primary" className="editTheme-btn">
            {theme?.id ? (
              <a
                target="_blank"
                href={
                  process.env.NODE_ENV === "development"
                    ? `http://localhost:7777/theme/home/${theme?.id}`
                    : `https://${data?.me?.currentShop?.domain}/theme/home/${theme?.id}`
                }>
                <Space align="center" size={2}>
                  {getText()}
                  <Divider type="vertical" style={{ backgroundColor: "#fff" }} />
                  <MyImage src={edit} width={16} style={{ marginTop: 6 }} />
                </Space>
              </a>
            ) : (
              <a
                target="_blank"
                href={
                  process.env.NODE_ENV === "development"
                    ? `http://localhost:7777/theme/home?page=${pageType}&pageId=${pageId}`
                    : `https://${data?.me?.currentShop?.domain}/theme/home?page=${pageType}&pageId=${pageId}`
                }>
                <Space align="center" size={2}>
                  {getText(true)}
                  <Divider type="vertical" style={{ backgroundColor: "#fff" }} />
                  <MyImage src={edit} width={16} style={{ marginTop: 6 }} />
                </Space>
              </a>
            )}
          </Button>
        </Col>
      </Row>
    </FormCard>
  );
};

export default EditThemeProduct;

const CustomTitle = ({ pageName }) => {
  const { t } = useTranslation();
  const { sm } = useBreakpoint();
  return (
    <Space align="center">
      <Text className="editTheme-title">{t("products.themeProduct", { pageName })}</Text>
      <Tooltip
        placement="bottomRight"
        title={t("products.editThemeDesc", { pageName })}
        color="#FAFAFA"
        overlayInnerStyle={{ color: "#4F4F4F", width: sm ? 328 : 250, padding: "16px 12px" }}>
        <a>
          <MyImage src={hint} style={{ marginTop: 8 }} />
        </a>
      </Tooltip>
    </Space>
  );
};
