import { createGlobalStyle } from "styled-components";
import { Breakpoints } from "../../constants";

export const GlobalStyle = createGlobalStyle`

.ant-picker-dropdown {
  .ant-picker-panel-container {
    box-shadow: unset;
   
  }
}
.date__picker__schedule {
  .ant-picker-panel {
    margin: 0 auto;
    margin-top:  25px;
    
  }
  .ant-picker-panel-container {
    box-shadow: unset !important;
    

  }
  .ant-picker-footer {
    display: none !important;

  }
}

.ant-drawer .ant-drawer-content {
  @media screen and (max-width: 370px ) {
    width: 330px
  }
  @media screen and (max-width: ${Breakpoints.xs} ) {
    width: 310px
  }


}

.ant-drawer-content-wrapper{
  width: 0
}


@media screen and (max-width: ${Breakpoints.xs} ) {
   
.ant-drawer-left .ant-drawer-content-wrapper {
  left: -35px;
}

.ant-drawer-right .ant-drawer-content-wrapper {
  right: -44px
}
  }






/* .date__picker__schedule {
    @media screen and (max-width: 516px ) {
      width: 278px;
    }
    @media screen and (max-width: 516px ) {
      width: 265px;
    }
  } */

	body {
    margin:0;
    font-family: ${(props) =>
      props.theme.i18n.language == "ar" ? `'Cairo', sans-serif` : `'Roboto', sans-serif`};
	}

  * {
		box-sizing: border-box;
	}
::-webkit-scrollbar {
}
::-webkit-scrollbar-track {
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
}

:focus {
  outline: none;
}

.ant-drawer-close ,
.ant-drawer-rtl .ant-drawer-close{
  right: 0;
  left: auto;
  color: #000;
}

.ant-input {
  outline: none;
  box-shadow: none;
  text-shadow: none;
  background: none;
}

.ant-layout {
  background: ${(props) => props.theme.customColors.white};
}

.ant-btn {
  height: 36px;
  font-size:14px;
  border-radius: ${(props) => props.theme.borderRadius.borderRadiusBase};
  font-weight: 500;
  outline: none;
  &:after {
    display: none;
  }
}
.ant-btn-primary {
  background: ${(props) => props.theme.customColors.secondary};
  border-color: ${(props) => props.theme.customColors.secondary};
}
.ant-btn-primary:hover, .ant-btn-primary:focus {
  background: ${(props) => props.theme.customColors.primaryHover};
  border-color: ${(props) => props.theme.customColors.primaryHover};
}
.ant-btn-text {
  color: ${(props) => props.theme.customColors.primary}
}
.ant-btn-text:hover,
.ant-btn-text:active {
  background: none;
}

//datePicker Analytics style
.ant-picker-content th {
  color: #c2c2c2;
}
.ant-picker-cell-selected,
.ant-picker-cell-range-start-single {
  .ant-picker-cell-inner {
    border-radius: 50%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 32px;
    height: 32px;
    background-color: ${(props) => props.theme.customColors.secondary} !important;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
      display: none;
    }
  }
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    display: none;
  }
  .ant-picker-cell .ant-picker-cell-inner {
    &:before {
      display: none;
    }
  }
.ant-picker-cell-disabled::before {
  background: rgba(74, 148, 165, 0.09);
}
.ant-picker-panels {
  padding: 32px 50px 0px;
}

/* .ant-picker-footer {
  padding: 40px 32px;
} */
.ant-picker-panel,
.ant-picker-footer {
  border: 0;
}
.ant-form-item-label > label {
  width: 100%;
}

//content-container
.content-container {
  padding: ${(props) => (props.theme.isBreakpoints.lg ? "59px 59px 0" : "15px 15px 0")};
  min-height: calc(100vh - 115px);
  /* background: ${(props) => props.theme.backgroundColors.gray3}; */
  background: ${(props) => (props.theme.isBreakpoints.lg ? "#fbfbfb" : "#fff")};
}

.ant-notification-notice-success {
  border: 1px solid ${(props) => props.theme.borderColors.lightGreen};
  /* width: 320px; */
}
.ant-notification-notice-error {
  border: 1px solid ${(props) => props.theme.borderColors.darkRed};
}

// sidebar
.sidebar-nav {
  overflow: auto;
  height: 100vh;
  z-index: 999;
  position: fixed;
  /* left: ${(props) => (props.theme.i18n.language == "en" ? 0 : "unset")};
  right: ${(props) => (props.theme.i18n.language == "ar" ? 0 : "unset")}; */
  right: 0;

  &::-webkit-scrollbar {
    display: none;
  }

  .ant-layout-sider-children,.ant-menu {
    background: ${(props) => props.theme.customColors.primary};
  }

  .ant-drawer-body {
    background: ${(props) => props.theme.customColors.white};
      &::-webkit-scrollbar {
      display: none;
    }
  }

  .ant-drawer-close {
    position: fixed;
    color: #fff;
    right: ${(props) => (props.theme.i18n.language == "en" ? "0" : "auto")};
    left: ${(props) => (props.theme.i18n.language == "ar" ? "0" : "unset")};
  }
}

.ant-dropdown-menu-item-danger {
  span {
    margin-right: ${(props) => (props.theme.i18n.language == "ar" ? "10px" : "auto")};
    margin-left: ${(props) => (props.theme.i18n.language == "en" ? "10px" : "unset")};
    font-weight: 700;
    margin-top: -10px;
  }
}

.ant-popover-rtl .ant-popover-buttons {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-left {
  justify-content: flex-start;
}

.add_domain_modal {
  .ant-form-item-label > label {
    font-weight: 400;
    font-size: 15px;
    color: ${(props) => props.theme.colors.black};
  }
  .ant-input:placeholder-shown {
    height: 42px;
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.borderColors.gray};
  }
  .add {
    font-size: 15px;
    font-weight: 500;
    color: ${(props) => props.theme.colors.black3};
  }
  .recommend {
    font-weight: 700;
    font-size: 14px;
    color: ${(props) => props.theme.colors.Gray2};
  }
  .info {
    margin: 12px 0 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .title {
    font-weight: 400;
    font-size: 14px;
    color: ${(props) => props.theme.colors.Gray1};
  }
  .wrapper {
    background: ${(props) => props.theme.customColors.lightGray};
    padding: 12px 10px 13px;
    width: 100%;
    height: 73px;
  }
  .info-container {
    padding: 12px 10px 13px;
    width: 100%;
    .head {
      font-size: 14px;
      font-weight: 700;
      color: ${(props) => props.theme.colors.black3};
    }
    .text {
      font-size: 14px;
      font-weight: 400;
      color: ${(props) => props.theme.colors.black3};
    }
  }
  .footer {
    align-items: start;
    width: 100%;
    /* padding: 24px; */
    .second {
      font-size: 14px;
      font-weight: 700;
      color: ${(props) => props.theme.colors.black3};
    }
    .name {
      font-size: 12px;
      font-weight: 700;
      color: ${(props) => props.theme.colors.Gray2};
    }
    .user-hint {
      font-size: 12px;
      font-weight: 700;
      color: ${(props) => props.theme.colors.Gray1};
    }
    .strong {
      font-weight: 700;
      font-size: 12px;
    }
    .success-dns {
      border: 1px solid ${(props) => props.theme.customColors.lightGreen};;
      border-radius: 4px;
      width: 196px;
      padding: 8px 15px;
      .dns-name {
        font-size: 12px;
        font-weight: 400;
        color: ${(props) => props.theme.colors.Gray2};
      }
    }
    .ant-btn-primary {
      width: 105px;
    }
    .ant-btn-link {
      border: 1px solid ${(props) => props.theme.customColors.primaryHover};
    }
    .success {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 8px;
    }
  }
}

.regions-table {
  .ant-table-thead > tr > th {
    background: ${(props) => props.theme.backgroundColors.gray4};
  }
  .ant-table-thead > tr > th:first-child {
    border-top-right-radius: 8px;
  }
  .ant-table-thead > tr > th:last-child {
    border-top-left-radius: 8px;
  }
}

.variant-model {
  .ant-form-item-label > label::after {
    display: none;
  }
}

.filter-drawer {
  .ant-drawer-title {
    font-weight: 700;
    font-size: 18px;
    /* margin-bottom: 32px; */
  }
  /* .ant-divider-horizontal {
    margin: 15px 0;
  } */
  .ant-drawer-close {
    position: absolute;
    left: ${(props) => (props.theme.i18n.language == "ar" ? "0" : "unset")};
    right: ${(props) => (props.theme.i18n.language == "en" ? "0" : "unset")};
  }
  .submit {
    width: 82px;
    height: 32px;
  }
  .link {
    width: 114px;
    height: 36px;
    border: 1px solid ${(props) => props.theme.customColors.primaryHover}
  }
}

.payment-modal {
  .ant-modal-close {
    position: absolute;
    margin-bottom: 10px;
    left: ${(props) => (props.theme.i18n.language == "ar" ? "0" : "unset")};
    right: ${(props) => (props.theme.i18n.language == "en" ? "0" : "unset")};
  }
  .title {
    font-weight: 700;
    font-size: 18px;
    color: ${(props) => props.theme.colors.Gray2};
    margin-top: 15px;
    display: block;
  }
  .ant-form-item-label > label {
    font-weight: 600;
    font-size: 14px;
    color: ${(props) => props.theme.colors.Gray2};
  }
  .ant-input {
    border: 1px solid #e0e0e0;
    height: 40px;
  }
  .ant-modal-footer {
    border-top: 0;
    .ant-btn {
      height: 48px;
      font-weight: 600;
      font-size: 16px;
    }
  }
}

.payment_type_modal {
  .ant-checkbox-inner::after {
    left: 34%;
  }
  .ant-modal-close {
    position: absolute;
    margin-bottom: 10px;
    left: ${(props) => (props.theme.i18n.language == "ar" ? "0" : "unset")};
    right: ${(props) => (props.theme.i18n.language == "en" ? "0" : "unset")};
  }
  .title {
    font-weight: 700;
    font-size: 18px;
    color: ${(props) => props.theme.colors.Gray2};
    margin-top: 15px;
    display: block;
  }
  .ant-checkbox {
    margin-left: 16px;
    .ant-checkbox-inner {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }
  }
  .newPayment {
    padding: 0;
    color: ${(props) => props.theme.customColors.primaryHover};
    font-size: 14px;
    font-weight: 600;
  }
  .payment-type {
    font-weight: 400;
    font-size: 18px;
    color: ${(props) => props.theme.colors.black3};
    margin-top: -30px;
    display: block;
  }
}

.paymob_modal {
  .ant-modal-body {
    /* padding-right: 30px; */
    /* padding-left: 30px; */
  }
  .paymob {
    display: flex;
    gap: 12px;
    align-items: center;
    .text {
      font-size: 24px;
      color: ${(props) => props.theme.colors.black3};
      font-weight: 500;
    }
  }
  .info {
    font-size: 16px;
    font-weight: 600;
    color: ${(props) => props.theme.colors.Gray2};
    display: block;
    width: 300px;
  }
  .download {
    display: flex;
    align-items: center;
    height: 40px;
    gap: 2px;
    background: #6EBBC5;
    border-color: #6EBBC5;
  }
  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 121px;
    background: transparent;
    border-color: #6EBBC5;
    border-radius: 12px;
  }
  .send-order {
    height: 48px;
    font-size: 16px;
    font-weight: 600;
  }
}

.user-dropdown {
  .ant-dropdown-menu-item-danger span {
    /* margin-top: -23px;
    margin-right: 20px;
    display: block;
    margin-left: 20px; */
    margin-top: 0;
  }
}

.ant-upload-rtl.ant-upload.ant-upload-select-picture-card{
    margin: 0px 2px 8px 8px !important;
}

.ant-upload-list-rtl ,.ant-upload-list{
  /* background-color:red; */
  display:flex;
  flex-flow:wrap-reverse;
}

.ant-input:focus, .ant-input-focused {
  box-shadow: unset;
}

.user-dropdown {
  .ant-dropdown-menu-item-icon {
    margin-right: ${(props) => (props.theme.i18n.language == "en" ? "8px" : "unset")};
    margin-left: ${(props) => (props.theme.i18n.language == "ar" ? "8px" : "unset")};
  }
}

.pop_confirm_theme {
  .ant-popover-inner-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .ant-popover-buttons {
      justify-content: end;
      .ant-btn-dangerous {
        background: #FE5F55;
        color: #fff;
      }
    }
    .title {
      font-weight: 600;
      font-size: 18px;
      color: #333;
    }
    .text {
      font-weight: 400;
      font-size: 16px;
      color: #4f4f4f;
      line-height: 32px;
    }
  }
}

.add_category_modal {
  .ant-modal-content {
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(51, 51, 51, 0.08);
    border-radius: 10px;
    padding: 16px 16px;
    @media (max-width: ${Breakpoints.md}) {
      padding: 16px 0px;
    }
  }
  .ant-modal-title {
    font-weight: 700;
    font-size: 14px;
    color: #333;
  }
  .category_form {
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(51, 51, 51, 0.12);
    border-radius: 8px 0px 8px 8px;
    padding: 16px 16px 10px;
    z-index: 10;
    position: relative;
    margin-top: 30px;
  }
  .media_wrapper {
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(51, 51, 51, 0.12);
    border-radius: 8px;
    padding: 16px 16px;
    .img_wrapper, .selector_wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px; 
    }
    .img_wrapper {
      width: 60%;
    }
  }
  .add_category {
    text-align: end;
    .add_category_btn {
      margin-top: 24px;
    }
  }
}
.boarding-text {
  display: block;
  text-align: start;
  font-size: 14px;
  font-weight: 600;
  color: #333;
  line-height: 24px;
}

button[title="Close"] {
  right: ${(props) => (props.theme.i18n.language == "en" ? "0" : "unset")};
  left: ${(props) => (props.theme.i18n.language == "ar" ? "0" : "unset")};
}

.message_tour_modal {
  width: 631px !important;
  @media (max-width: ${Breakpoints.lg}) and (min-width: ${Breakpoints.sm}) {
    width: 531px !important;
  }
  @media (max-width: ${Breakpoints.sm}) {
    width: 400px !important;
  }
  .ant-modal-content {
    border-radius: 12px;
  }
  .welcome_tour_massage {
    font-size: 24px;
    font-weight: 700;
    color: #333;
    @media (max-width: ${Breakpoints.sm}) {
    font-size: 16px;
    }
  }
  .welcome_tour_desc {
    font-size: 16px;
    font-weight: 400;
    color: #4f4f4f;
    display: block;
    margin-bottom: 15px;
    @media (max-width: ${Breakpoints.sm}) {
    font-size: 14px;
    }
  }
}

.message_registeration_modal {
  width: 400px !important;
  .ant-modal-content {
    border-radius: 12px;
  }
  .ant-modal-body {
    padding: 15px 27px 25px;
  }
  .congrat_message {
    font-size: 24px;
    font-weight: 700;
    color: #333;
    @media (max-width: ${Breakpoints.sm}) {
    font-size: 16px;
    }
  }
  .congratDesc {
    font-size: 16px;
    font-weight: 400;
    color: #4f4f4f;
    display: block;
    margin-bottom: 15px;
    @media (max-width: ${Breakpoints.sm}) {
    font-size: 14px;
    }
  }
}

.react-joyride__tooltip {
  button[data-action="primary"] {
    background-color: #1985A1 !important;
    /* width: 73px; */
    height: 32px;
  }
}

.tags_modal {
  .ant-modal-title {
    font-size: 18px;
    font-weight: 700;
    color: #333;
  }
  .tag {
    font-size: 14px;
    font-weight: 600;
    color: #4f4f4f;
    display: block;
    margin-bottom: 8px;
  }
  .ant-input {
    margin-bottom: 16px;
    border-radius: 4px;
  }
}

// main container
.row-container {
  max-width: ${({ theme }) => theme.breakpoints.xxl};
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

//1
@media (min-width: ${({ theme }) => theme.breakpoints.xl}) and (max-width: ${({ theme }) =>
  theme.breakpoints.xxl}) {
  .row-container {
    max-width: ${({ theme }) => theme.breakpoints.xl};
  }
}

//2
@media (min-width: ${({ theme }) => theme.breakpoints.lg}) and (max-width: ${({ theme }) =>
  theme.breakpoints.xl}) {
  .row-container {
    max-width: ${({ theme }) => theme.breakpoints.xl};
  }
}

//3
@media (min-width: ${({ theme }) => theme.breakpoints.md}) and (max-width: ${({ theme }) =>
  theme.breakpoints.lg}) {
  .row-container {
    max-width: ${({ theme }) => theme.breakpoints.lg};
  }
}
//4
@media (max-width: ${({ theme }) => theme.breakpoints.md}) {
  .row-container {
    max-width: ${({ theme }) => theme.breakpoints.sm};
  }
}

  div[eventkey =tmp_key-sub4-1] {
    display: block !important;
    justify-content: unset !important;
    align-items: unset !important;
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected,
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range, 
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background: #6EBBC5;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    color: #fff !important;
  }
  .react-datepicker__week {
    display: flex;
    justify-content: space-between;
    .react-datepicker__day, .react-datepicker__time-name {
      font-size: 14px;
      font-weight: 400;
      line-height: 30px;
      text-align: center;
    }
  }
  .react-datepicker__day-names {
    display: flex;
    justify-content: space-around;
    .react-datepicker__day-name {
      font-size: 10px;
      font-weight: 400;
      color: #c2c2c2;
      line-height: 30px;
      text-align: center;
    }
  }
  .react-datepicker, .react-datepicker__month-container {
    width: 100%;
  }
  .react-datepicker {
    border: 0;
  }
  .react-datepicker__header {
    min-height:80px;
    border: 0;
  }
  .react-datepicker__day-names{
    margin-top:30px;
  }
  .react-datepicker__current-month{
    margin-top:10px;
    font-size: 24px;
    font-weight: 400;
    color: ${(props) => props.theme.colors.black3};
  }

  .react-datepicker__navigation {
    top: -6px
  }

  .react-datepicker__header {
    background: transparent;
  }

  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
    background: #6EBBC5;
    border-radius: 50%;
  }
  
  .search-filter-input{
    .ant-input-search .ant-input:hover,
    .ant-input-search .ant-input:focus {
      border-color: #e0e0e0 ;
      box-shadow: none;
    }
    .ant-input-group-addon {
      background: transparent;
      .anticon-search {
      color: #231f20;
    }
  }
  .ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
    border-color: #e0e0e0;
  }
}
.react-joyride__tooltip{
  div:not(:first-child)>div>button {
    /* background-color:red !important; */
    padding:0px !important;
  }
}

.ant-image-preview-img{
    width: 65%;
    @media (max-width: ${Breakpoints.md}){
      width: 80%;
    }
    @media (max-width: ${Breakpoints.sm}){
      width: 95%;
    }
  }
  .ant-modal-close-x {
    transition: transform 0.5s;
  }
  .ant-modal-close-x:hover {
    transform: rotate(0.5turn);
  }


`;
