import React, { lazy } from "react";
import FeaturesNames from "../enums/featuresNames";
// import Gateway from "../pages/settings/gateways";
const Gateway = lazy(() => import("../pages/settings/gateways"));
const ImportReports = lazy(() => import("../pages/settings/importReport"));
const Attributes = lazy(() => import("../pages/products/settings/attributes"));
const Analytics = lazy(() => import("../pages/analytics"));
const Payments = lazy(() => import("../pages/settings/paymentsDeprecated"));
const PaymentInfo = lazy(() => import("../pages/settings/gateways/paymentInfo"));
const SmsGatways = lazy(() => import("../pages/settings/smsGatways"));
const Domains = lazy(() => import("../pages/settings/domains"));
const Facebook = lazy(() => import("../pages/apps/facebook"));
const ConnectFacebook = lazy(() => import("../pages/apps/facebook/connect"));

const Bills = lazy(() => import("../pages/profile/bills"));
const EmailCompaign = lazy(() => import("../pages/marketing/emailCompaign"));
const NewCompaign = lazy(() => import("../pages/marketing/emailCompaign/addEdit/addNewCompaign"));
const NewTemplate = lazy(() => import("../pages/marketing/emailCompaign/addEdit/addNewTemplate"));

const SMSCompaign = lazy(() => import("../pages/marketing/smsCompaign"));
const AddSmsCompaign = lazy(() => import("../pages/marketing/smsCompaign/add/addSmsCompaign"));
// const NewCompaign = lazy(() => import("../pages/emailCompaign/addEdit/addNewCompaign"));
// const NewTemplate = lazy(() => import("../pages/emailCompaign/addEdit/addNewTemplate"));

const Backups = lazy(() => import("../pages/settings/dbBackups"));
const Inventory = lazy(() => import("../pages/products/inventory"));
const PushNotification = lazy(() => import("../pages/apps/app/notifications"));
const History = lazy(() => import("../pages/history"));
const EditUser = lazy(() => import("../pages/users/addEdit/edit"));
const AddUser = lazy(() => import("../pages/users/addEdit/add"));
const Users = lazy(() => import("../pages/users"));
const Profile = lazy(() => import("../pages/profile"));
const AddToBalance = lazy(() => import("../pages/profile/addToBalance"));

const GeneralSettings = lazy(() => import("../pages/settings/generals"));
const ShippingSetting = lazy(() => import("../pages/settings/shipping"));
const ShipGatway = lazy(() => import("../pages/settings/shipGatways"));

const Products = lazy(() => import("../pages/products/products"));
// const AddShop = lazy(() => import('../pages/shop/add'))
// const Shops = lazy(() => import('../pages/shop'))
const EditOrder = lazy(() => import("../pages/orders/edit"));
const EditOrderCart = lazy(() => import("../pages/orders/editOrderCart"));
const AddOrder = lazy(() => import("../pages/orders/add"));
const AddCustomer = lazy(() => import("../pages/customers/addEdit/add"));
const EditCustomer = lazy(() => import("../pages/customers/addEdit/edit"));

const Customers = lazy(() => import("../pages/customers"));
const Orders = lazy(() => import("../pages/orders"));
const AddVariant = lazy(() => import("../pages/products/products/variants/add"));
const EditVariant = lazy(() => import("../pages/products/products/variants/edit"));
const EditPage = lazy(() => import("../pages/pages/addEdit/edit"));
const AddPage = lazy(() => import("../pages/pages/addEdit/add"));
const EditPost = lazy(() => import("../pages/posts/addEdit/edit"));
const BeVendor = lazy(() => import("../pages/auth/beVendor"));
// const Shops = lazy(() => import("../pages/auth/shops"));

const AddPost = lazy(() => import("../pages/posts/addEdit/add"));

const EditDiscount = lazy(() => import("../pages/marketing/discounts/addEdit/edit"));
const AddDiscount = lazy(() => import("../pages/marketing/discounts/addEdit/add"));
const Discounts = lazy(() => import("../pages/marketing/discounts"));
const UpSells = lazy(() => import("../pages/marketing/upSell"));
const EditUpSell = lazy(() => import("../pages/marketing/upSell/addEdit/edit"));
const AddUpSell = lazy(() => import("../pages/marketing/upSell/addEdit/add"));

const Pages = lazy(() => import("../pages/pages"));
const posts = lazy(() => import("../pages/posts"));

const EditProduct = lazy(() => import("../pages/products/products/addEdit/edit"));
const AddProduct = lazy(() => import("../pages/products/products/addEdit/add"));
const AddLocation = lazy(() => import("../pages/settings/locations/addEdit"));
const EditLocation = lazy(() => import("../pages/settings/locations/addEdit"));

const Locations = lazy(() => import("../pages/settings/locations"));
const Settings = lazy(() => import("../pages/settings"));
const AppSettings = lazy(() => import("../pages/apps/app/settings"));
const Brands = lazy(() => import("../pages/products/settings/brands"));
// const Types = lazy(() => import("../pages/products/settings/types"));
const Tags = lazy(() => import("../pages/products/settings/tags"));
const Home = lazy(() => import("../pages"));
const AddCategory = lazy(() => import("../pages/products/categories/addEdit"));
const EditMobTheme = lazy(() => import("../pages/apps/app/theme/addEdit/edit"));
const NewAddThemeIndex = lazy(() => import("../pages/apps/app/theme/addTheme/AddThemeIndex"));
const AddMobTheme = lazy(() => import("../pages/apps/app/theme/addEdit/add"));
const Themes = lazy(() => import("../pages/apps/app/theme"));
const MobThemes = lazy(() => import("../pages/apps/app/theme/mobTheme"));

const Navigations = lazy(() => import("../pages/theme/navigations"));
const AppSlides = lazy(() => import("../pages/apps/app/theme/slides"));
const Slides = lazy(() => import("../pages/theme/slides"));
const Categories = lazy(() => import("../pages/products/categories"));
const ShipOrder = lazy(() => import("../pages/orders/edit/shipping/index"));
const Chat = lazy(() => import("../pages/chat"));
const ChangePlan = lazy(() => import("../pages/profile/upgrade"));
const Invoices = lazy(() => import("../pages/profile/invoices"));
const CheckoutSettings = lazy(() => import("../pages/settings/checkoutSettings"));
const ProductSetting = lazy(() => import("../pages/settings/productSettings"));
const PayInvoice = lazy(() => import("../pages/profile/invoices/pay"));
const CheckPayInvoice = lazy(() => import("../pages/profile/invoices/checkTransaction"));
const Error = lazy(() => import("../pages/error"));
const ReceiptOrder = lazy(() => import("../pages/orders/receipt"));
const FacebookSettings = lazy(() => import("../pages/apps/facebook/facebookSettings"));
const SellingChannel = lazy(() => import("../pages/apps/facebook/sellingChannels"));
const Renew = lazy(() => import("../pages/profile/renew"));
const RenewPlan = lazy(() => import("../pages/profile/renew/renewPlan"));
const IntegrationPage = lazy(() => import("../pages/marketing/integration"));
const Apps = lazy(() => import("../pages/apps"));
const ReviewsAndRates = lazy(() => import("../pages/apps/reviews/reviewsAndRates"));
const knawat = lazy(() => import("../pages/apps/knawat/knawat"));
const SendStrap = lazy(() => import("../pages/apps/sendStrap"));

const SingleProductReview = lazy(() => import("../pages/apps/reviews/cards/singleProductReviews"));
const SEO = lazy(() => import("../pages/apps/seo"));
const Popups = lazy(() => import("../pages/apps/popups/popups"));
const Popup = lazy(() => import("../pages/apps/popups/addEdit"));

const sendStrapSetting = lazy(() => import("../pages/apps/sendStrap/setting"));

const gameballSetting = lazy(() => import("../pages/apps/gameball/setting"));
const aliExpressSetting = lazy(() => import("../pages/apps/aliExpress/setting"));

const taagerSetting = lazy(() => import("../pages/apps/taager/setting"));
const taagerLogIn = lazy(() => import("../pages/apps/taager/logIn/index"));

const AppInfo = lazy(() => import("../pages/apps/info"));
// const Taager = lazy(() => import("../pages/apps/taager"));
const affiliate = lazy(() => import("../pages/affiliateMarketing/index"));
const MessageSetting = lazy(() => import("../pages/apps/message/setting"));

const customizeScript = lazy(() => import("../pages/settings/customizeScripts/index"));
const LiveReports = lazy(() => import("../pages/analytics/liveReports/index"));
const affiliateSetting = lazy(() => import("../pages/apps/affiliate/setting/index"));
const MarketerProfile = lazy(() => import("../pages/apps/affiliate/marketers/marketerProfile"));
const NewMarketer = lazy(() => import("../pages/apps/affiliate/marketers/new"));
const EditMarketer = lazy(() => import("../pages/apps/affiliate/marketers/edit"));
const UpgradePricing = lazy(() => import("../pages/profile/upgrade/upgradePricing"));
const upgrade = lazy(() => import("../pages/profile/renew/newPricing/index"));
const notifications = lazy(() => import("../pages/settings/notificationSystem"));
const loyalitySettings = lazy(() => import("../pages/apps/loyalityProgram/settings"));
const loyaltyRules = lazy(() => import("../pages/apps/loyalityProgram/settings/singleRules"));
const refundOrderItems = lazy(() => import("../pages/orders/edit/cards/refundOrder"));
const ThemeEditor = lazy(() => import("../pages/theme/theme"));
const abandCart = lazy(() => import("../pages/orders/aband-cart"));
const themeTemplate = lazy(() => import("../pages/apps/app/theme/webThemesTemplate/index"));
const Merchant = lazy(() => import("../pages/apps/googleMerchant/index"));

// const RenewCheckout = lazy(() => import("../pages/renew/renewCheckout"));

// receipt order
export const checkoutRoutes = [
  {
    path: "/invoices",
    component: Bills,
    roles: [], //!
    permission: "order",
  },
  {
    path: "/invoices/:id",
    component: PayInvoice,
    roles: [], //!
    permission: "order",
  },
  {
    path: "/invoice/check",
    component: CheckPayInvoice,
    roles: [], //!
    permission: "order",
  },
  // {
  //   path: "/changePlan",
  //   component: Renew,
  //   roles: ["all"],
  // },
  {
    path: "/changePlan",
    component: UpgradePricing,
    roles: ["all"],
  },
  {
    path: "/changePlan/plan/:num",
    component: RenewPlan,
    roles: ["all"],
  },
  {
    path: "/upgrade",
    component: upgrade,
    roles: ["all"],
  },
  {
    path: "/upgrade",
    component: upgrade,
    roles: ["all"],
  },

  {
    path: "/addToBalance",
    component: AddToBalance,
    roles: ["all"], //!
  },
];
export const settingsRoutes = [
  {
    path: "/settings/productSetting",
    component: ProductSetting,
    roles: [], //!
    permission: "updateSettings",
    // feature: FeaturesNames.productSetting,
  },
  {
    path: "/settings/checkoutSettings",
    component: CheckoutSettings,
    roles: [], //!
    permission: "updateSettings",
    feature: FeaturesNames.checkoutSetting,
  },
  {
    path: "/settings/gateways",
    component: Gateway,
    roles: [],
  },
  {
    path: "/app/settings",
    component: AppSettings,
    // roles: [],
    permission: "updateSettings",
    // roles: ["all"],
  },
  {
    path: "/settings",
    component: Settings,
    // roles: [],
    // permission: "updateSettings",
    roles: ["all"],
  },
  {
    path: "/settings/locations",
    component: Locations,
    roles: [],
    permission: "locations",
    feature: FeaturesNames.locations,
  },
  {
    path: "/settings/locations/new",
    component: AddLocation,
    roles: [],
    permission: "locations",
    feature: FeaturesNames.locations,
  },
  {
    path: "/settings/location/:id",
    component: EditLocation,
    roles: [],
    permission: "locations",
    feature: FeaturesNames.locations,
  },
  {
    path: "/settings/shipping",
    component: ShippingSetting,
    roles: [],
    permission: "shippingZones",
  },
  {
    path: "/settings/shipGatway",
    component: ShipGatway,
    roles: [],
    permission: "shippingZones",
  },
  {
    path: "/settings/general",
    component: GeneralSettings,
    roles: [],
    permission: "updateSettings",
  },

  {
    path: "/settings/backups",
    component: Backups,
    roles: [],
    permission: "updateSettings",
  },
  {
    path: "/settings/payments",
    component: Payments,
    roles: [],
    permission: "updateSettings",
  },
  {
    path: "/settings/gateways/:id",
    component: PaymentInfo,
    roles: [],
    permission: "updateSettings",
  },
  {
    path: "/settings/smsGatways",
    component: SmsGatways,
    roles: [],
    permission: "updateSettings",
  },
  {
    path: "/settings/domains",
    component: Domains,
    roles: [],
    permission: "domains",
    feature: FeaturesNames.changeDomain,
  },
  {
    path: "/settings/addScript",
    component: customizeScript,
    roles: [],
  },
  {
    path: "/settings/notifications",
    component: notifications,
    roles: [],
  },
];
const appsPagesRoutes = [
  {
    path: "/apps/:appName/info",
    component: AppInfo,
    roles: [], //!
  },
  {
    path: "/apps/Sendstrap/settings",
    component: sendStrapSetting,
    roles: [], //!
  },
  {
    path: "/apps/Taager/settings",
    component: taagerSetting,
    roles: [], //!
  },
  {
    path: "/apps/aliExpress/settings",
    component: aliExpressSetting,
    roles: [], //!
  },
  {
    path: "/apps/Taager/logIn",
    component: taagerLogIn,
    roles: [], //!
  },
  {
    path: "/apps/Gameball/settings",
    component: gameballSetting,
    roles: [], //!
  },
  {
    path: "/affiliate",
    component: affiliate,
    roles: [], //!
  },
  {
    path: "/affiliate/settings",
    component: affiliateSetting,
    roles: [], //!
  },
  {
    path: "/marketer/:id",
    component: MarketerProfile,
    roles: [],
    permission: "users",
  },
  {
    path: "/affiliate/marketer/edit/:id",
    component: EditMarketer,
    roles: [],
    permission: "users",
  },
  {
    path: "/affiliate/marketer/new",
    component: NewMarketer,
    roles: [],
    permission: "users",
  },
  // {
  //   path: "/apps/taager",
  //   component: Taager,

  //   roles: [], //!
  // },
  {
    path: "/apps/message/settings",
    component: MessageSetting,
    roles: [], //!
  },
  {
    path: "/apps/loyalty/settings",
    component: loyalitySettings,
    roles: [], //!
  },
  {
    path: "/apps/loyalty/:ruleName",
    component: loyaltyRules,
    roles: [], //!
  },
  {
    path: "/apps/merchant/settings",
    component: Merchant,
    roles: [], //!
  },
];

const analytics = [
  {
    path: "/liveReports",

    component: LiveReports,
    roles: [], //!
  },
];

export const routes = [
  ...analytics,
  ...appsPagesRoutes,
  {
    path: "/beVendor",

    component: BeVendor,
    roles: [], //!
  },
  // {
  //   path: "/shops",

  //   component: Shops,
  //   roles: [], //!
  // },
  {
    path: "/importReports",

    component: ImportReports,
    roles: [], //!
  },
  {
    path: "/error",

    component: Error,
    roles: [], //!
  },
  {
    path: "/chat",
    notExact: true,
    component: Chat,
    roles: [],
    permission: "chatScreen",
    feature: FeaturesNames.liveChat,
  },
  {
    path: "/abandonment",
    component: abandCart,
    roles: [],
  },
  {
    path: "/",
    component: Home,
    roles: ["all"],
    role: [],
  },
  {
    path: "/emailCompaign",
    component: EmailCompaign,
  },
  {
    path: "/emailCompaign/new",
    component: NewCompaign,
  },
  {
    path: "/emailCompaign/template/new",
    component: NewTemplate,
  },

  {
    path: "/smsCompaign",
    component: SMSCompaign,
  },
  {
    path: "/smsCompaign/new",
    component: AddSmsCompaign,
  },

  // // receipt order
  // {
  //   path: "/receiptOrder",
  //   component: ReceiptOrder,
  //   roles: [],
  //   notExact: true,
  // },
  {
    path: "/analytics",
    component: Analytics,
    roles: [],
    permission: "analytics",
    feature: FeaturesNames.analytics,
  },

  {
    path: "/inventory",
    component: Inventory,
    roles: ["BUYER"],
    permission: "inventories",
  },

  {
    path: "/pushNotification",
    component: PushNotification,
    roles: [], //!
  },
  {
    path: "/history",
    component: History,
    roles: [], //!
  },
  {
    path: "/users",
    component: Users,
    roles: [],
    permission: "users",
  },

  {
    path: "/users/new",
    component: AddUser,
    roles: [],
    permission: "users",
  },
  {
    path: "/user/:id",
    component: EditUser,
    roles: [],
    permission: "users",
  },
  {
    path: "/myProfile",
    component: Profile,
    roles: ["all"], //!
  },

  {
    path: "/discounts",
    component: Discounts,
    roles: [],
    permission: "discounts",
    feature: FeaturesNames.coupons,
  },
  {
    path: "/upSells",
    component: UpSells,
    roles: [],
  },
  {
    path: "/discounts/new",
    component: AddDiscount,
    roles: [],
    permission: "addDiscount",
    feature: FeaturesNames.coupons,
  },
  {
    path: "/discount/:id",
    component: EditDiscount,
    roles: [],
    permission: "updateDiscount",
    feature: FeaturesNames.coupons,
  },
  {
    path: "/upSell/:id",
    component: EditUpSell,
    roles: [],
  },
  {
    path: "/upSells/new",
    component: AddUpSell,
    roles: [],
  },

  {
    path: "/pages",
    component: Pages,
    roles: [],
    permission: "pages",
    feature: FeaturesNames.pages,
  },
  {
    path: "/pages/new",
    component: AddPage,
    roles: [],
    permission: "pages",
    feature: FeaturesNames.pages,
  },
  {
    path: "/page/:id",
    component: EditPage,
    roles: [],
    permission: "pages",
    feature: FeaturesNames.pages,
  },
  {
    path: "/posts",
    component: posts,
    roles: [],
    permission: "blog",
    feature: FeaturesNames.blog,
  },
  {
    path: "/posts/new",
    component: AddPost,
    roles: [],
    permission: "blog",
    feature: FeaturesNames.blog,
  },
  {
    path: "/post/:id",
    component: EditPost,
    roles: [],
    permission: "blog",
    feature: FeaturesNames.blog,
  },
  {
    path: "/products",
    component: Products,
    roles: [],
    permission: "addProduct",
  },
  {
    path: "/products/new",
    component: AddProduct,
    roles: [],
    permission: "addProduct",
  },
  {
    path: "/product/:id",
    component: EditProduct,
    roles: [],
    permission: "updateProduct",
  },
  {
    path: "/product/:id/variants/:vId",
    component: EditVariant,
    roles: [],
    permission: "updateProduct",
  },
  {
    path: "/product/:id/variant/new",
    component: AddVariant,
    roles: [],
    permission: "addProduct",
  },
  {
    path: "/orders",
    component: Orders,
    roles: [],
    permission: "orders",
  },
  {
    path: "/order/:id",
    component: EditOrder,
    roles: [],
    permission: "editOrder",
  },
  {
    path: "/order/editcart/:id",
    component: EditOrderCart,
    roles: [],
    permission: "editOrder",
  },
  {
    path: "/order/:id/fulfill",
    component: ShipOrder,
    roles: [],
    permission: "order",
  },
  {
    path: "/order/:id/refund",
    component: refundOrderItems,
    roles: [],
    permission: "order",
  },

  {
    path: "/orders/new",
    component: AddOrder,
    roles: [],
    permission: "addOrder",
  },
  {
    path: "/tags",
    component: Tags,
    roles: [],
    permission: "addProduct",
  },
  {
    path: "/brands",
    component: Brands,
    roles: [],
    permission: "addProduct",
  },
  // {
  //   path: "/types",
  //   component: Types,
  //   roles: [],
  // },
  {
    path: "/attributes",
    component: Attributes,
    roles: [],
    permission: "addProduct",
  },
  {
    path: "/categories",
    roles: [],
    permission: "addProduct",

    component: Categories,
  },
  {
    path: "/categories/new",
    component: AddCategory,
    roles: [],
    permission: "addProduct",
  },
  {
    path: "/postsCategories",
    roles: [],
    permission: "blog",
    component: () => <Categories type="POST" />,
  },
  {
    path: "/postsCategory/:id",
    component: () => <AddCategory type="POST" />,
    roles: [],
    permission: "blog",
  },
  {
    path: "/postsCategories/new",
    component: () => <AddCategory type="POST" />,

    roles: [],
    permission: "blog",
  },
  {
    path: "/category/:id",
    component: AddCategory,

    roles: [],
    permission: "blog",
  },
  // {
  //   path: '/shops',
  //   component: Shops,
  //   roles: [],
  // },
  // {
  //   path: '/shops/new',
  //   component: AddShop,
  //   roles: [],
  // },
  {
    path: "/theme/editor",
    component: ThemeEditor,
    roles: [], //!
    // permission: "order",
  },
  {
    path: "/app/themes",
    component: MobThemes,
    roles: [],
    permission: "theming",
  },
  {
    path: "/themes",
    component: () => <Themes type="WEB" />,
    roles: [],
    permission: "theming",
  },
  {
    path: "/themes/template",
    component: themeTemplate,
    roles: [],
    permission: "theming",
  },
  {
    path: "/themes/new",
    component: AddMobTheme,
    roles: [],
    permission: "theming",
  },
  {
    path: "/themes/dev/new",
    component: NewAddThemeIndex,
    roles: [],
    permission: "theming",
  },
  {
    path: "/themes/edit/:id",
    component: EditMobTheme,
    roles: [],
    permission: "theming",
  },
  {
    path: "/themes/slides",
    component: Slides,
    roles: [],
    permission: "theming",
  },
  {
    path: "/app/slides",
    component: AppSlides,
    roles: [],
    permission: "theming",
  },

  {
    path: "/themes/navigation",
    component: Navigations,
    roles: [],
    permission: "navigation",
  },
  {
    path: "/collections",
    component: Navigations,
    roles: [],
    permission: "navigation",
  },
  {
    path: "/customers",
    component: Customers,
    roles: [],
    permission: "users",
  },
  {
    path: "/customers/new",
    component: AddCustomer,
    roles: [],
    permission: "users",
  },
  {
    path: "/customer/:id",
    component: EditCustomer,
    roles: [],
    permission: "users",
  },

  {
    path: "/plugins/facebook",
    component: Facebook,
    roles: [], //!
  },
  {
    path: "/plugins/facebook/connectFacebook",
    component: ConnectFacebook,
    roles: [], //!
  },

  {
    path: "/plugins/facebook/facebookSettings",
    component: FacebookSettings,
    roles: [], //!
  },
  {
    path: "/seo",
    component: SEO,
    roles: [], //!
    feature: FeaturesNames.seo,
  },
  {
    path: "/plugins/facebook/sellingChannels",
    component: SellingChannel,
    roles: [], //!
  },
  {
    path: "/plugins/facebook/:tab?",
    component: Facebook,
    roles: [], //!
  },

  // {
  //   path: "/bills",
  //   component: Bills,
  //   roles: [], //!
  // },
  // {
  //   path: "/changePlan",
  //   component: ChangePlan,
  //   roles: [], //!
  // },

  {
    path: "/integration",
    component: IntegrationPage,
    roles: [],
  },
  {
    path: "/reviewsandrates",
    component: ReviewsAndRates,
    roles: [],
  },
  {
    path: "/apps/knawat",
    component: knawat,
    roles: [],
  },
  {
    path: "/apps/sendStrap",
    component: SendStrap,
    roles: [],
  },
  {
    path: "/singleProduct/:id",
    component: SingleProductReview,
    roles: [],
  },
  {
    path: "/apps",
    component: Apps,
    roles: [],
  },
  {
    path: "/apps/popups",
    component: Popups,
  },
  {
    path: "/apps/popup/:id?",
    component: Popup,
  },
  ...checkoutRoutes,
  ...settingsRoutes,

  // {
  //   path: "/invoices",
  //   component: Invoices,
  //   roles: [], //!
  //   permission: "order",
  // },
  // {
  //   path: "/invoices/:id",
  //   component: PayInvoice,
  //   roles: [], //!
  //   permission: "order",
  // },
  // {
  //   path: "/invoice/check",
  //   component: CheckPayInvoice,
  //   roles: [], //!
  //   permission: "order",
  // },
  // {
  //   path: "/changePlan",
  //   component: Renew,
  //   roles: ["all"],
  // },
  // {
  //   path: "/changePlan/plan/:num",
  //   component: RenewPlan,
  //   roles: ["all"],
  // },
  // {
  //   path: "/renew/checkout",
  //   component: RenewCheckout,
  //   roles: ["all"],
  // },
];
