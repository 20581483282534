import { Form, Input, Button, Space, Row, Col, Typography, message as notification } from "antd";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useResponsive } from "../../../lib/useResponsive";
import MyImage from "../../../components/common/image";
import resetImg from "../../../assets/imgs/reset.svg";
import { useTranslation } from "react-i18next";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { CURRENT_USER } from "../../../utlis/Me";
import Navbar from "../../../components/layout/navbar";

const { Text } = Typography;
const RESET_PASSWORD = gql`
  mutation RESET_PASSWORD($password: String!) {
    resetPassword(password: $password) {
      id
      email
    }
  }
`;

const ChangePassword = () => {
  const { xl, lg } = useResponsive();
  let history = useHistory();
  const { t } = useTranslation();
  const trans = "auth.reset";

  const { data, refetch } = useQuery(CURRENT_USER, {
    fetchPolicy: "cache-first",
  });

  // useEffect(() => {
  //   if (data?.me) history.push("/");
  // }, [data]);

  const [requestReset, { loading }] = useMutation(RESET_PASSWORD, {
    onCompleted: () => {
      notification.success(t(`${trans}.resetDone`));
      refetch().then(() => {
        history.push("/shops");
      });
    },
  });

  const onFinish = ({ password }: { password: string }) => {
    requestReset({
      variables: {
        password,
      },
    });
  };

  return (
    <Container>
      <div className="row-container">
        <Row gutter={[0, 23]}>
          <Col xs={{ span: 24 }}>
            <Row align="middle" gutter={xl ? [124, 30] : lg ? [30, 30] : ([0, 10] as any)}>
              <Col span={20} />
              <Col span={4}>
                <Navbar />
              </Col>
              <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                <div className="card-form">
                  <Form
                    name="basic"
                    labelCol={{ span: 24 }}
                    initialValues={{
                      remember: false,
                      password: "",
                      confirmPassword: "",
                    }}
                    onFinish={onFinish}>
                    <Form.Item className="text">
                      <Text className="header">{t(`${trans}.title`)}</Text>
                    </Form.Item>
                    <Form.Item
                      label={t(`${trans}.label1`)}
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: t(`${trans}.passwordError`),
                          min: 8,
                        },
                      ]}>
                      <Input.Password />
                    </Form.Item>

                    <Form.Item
                      label={t(`${trans}.label2`)}
                      name="confirmPassword"
                      rules={[
                        {
                          required: true,
                          message: t(`${trans}.passwordConfirmError`),
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error(t(`${trans}.passwordConfirmError`)));
                          },
                        }),
                      ]}>
                      <Input.Password />
                    </Form.Item>

                    <Form.Item>
                      <Button
                        htmlType="submit"
                        loading={loading}
                        disabled={loading}
                        block
                        type="primary">
                        {t(`${trans}.button`)}
                      </Button>
                    </Form.Item>
                  </Form>

                  <Space direction="vertical" size={24}>
                    <div
                      style={{
                        paddingBottom: "3.0rem",
                      }}></div>
                  </Space>
                </div>
              </Col>
              <Col lg={{ span: 12 }} xs={{ span: 0 }}>
                <MyImage width={535} src={resetImg} />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

const Container = styled.div`
  padding-top: 30px;
  background: ${(props) => props.theme.backgroundColors.lightGray};

  .ant-image {
    text-align: ${(props) => (props.theme.isBreakpoints.lg ? "initial" : "center")};
  }

  .card-form {
    background: ${(props) => props.theme.customColors.white};
    box-shadow: 0px 0px 16px rgba(51, 51, 51, 0.12);
    border-radius: 8px;
    padding: ${(props) => (props.theme.isBreakpoints.md ? "46px 40px" : "46px 24px")};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 80px;
    /* width: 550px; */
  }

  .text {
    text-align: ${(props) => (props.theme.isBreakpoints.lg ? "center" : "initial")};
    display: block;
    margin-bottom: 16px;
    .header {
      font-weight: 700;
      font-size: ${(props) => (props.theme.isBreakpoints.md ? "24px" : "20px")};
      color: ${(props) => props.theme.colors.black3};
    }
  }
  .ant-input {
    height: 48px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
  }
  /* #basic_password {
    height: 38px;
  } */
  .ant-input-password {
    padding: 0 11px;
  }
  .ant-form-item-label > label {
    font-weight: 700;
    font-size: 16px;
    color: ${(props) => props.theme.customColors.Gray2};
  }
  .ant-form-rtl .ant-form-item-label {
    text-align: right;
  }
  .ant-btn {
    height: 56px;
    font-size: 20px;
  }
  .forget {
    font-weight: 400;
    font-size: 16px;
    color: ${(props) => props.theme.colors.red};
    cursor: pointer;
  }
  .footer {
    text-align: center;
    .text {
      font-weight: 400;
      font-size: 16px;
      color: ${(props) => props.theme.colors.Gray2};
    }
    .ant-btn {
      border: 1px solid ${(props) => props.theme.customColors.primary};
      color: ${(props) => props.theme.customColors.primary};
      font-weight: 500;
      border-radius: 4px;
    }
    .ant-btn:hover {
      background: ${(props) => props.theme.customColors.primary};
      color: ${(props) => props.theme.customColors.white};
    }
  }
`;

export default ChangePassword;
