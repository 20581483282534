import React, { useState, useEffect, useMemo } from "react";

import { Col, Row, Select, Space, TreeSelect, Typography } from "antd";
import { gql, useQuery } from "@apollo/client";
import debounce from "lodash/debounce";
import MyImage from "../image";
import { getSearchOfproduct } from "../../../pages/orders/add/cards/productsSearch";
import formatMoney from "../../../utlis/formatMoney";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export const PRODUCTS_SEARCH = gql`
  query PRODUCTS_SEARCH($where: ProductWhereInput!, $take: Int = 20) {
    products(take: $take, orderBy: { id: desc }, where: $where) {
      count
      nodes {
        id
        slug
        # hasVariants
        title {
          id
          auto
        }
        quantityInStock
        price
        mainImage {
          id
          md
        }
        variants {
          id
          quantityInStock
          price
          slug

          title {
            id
            auto
          }

          selectedOptions {
            option: attribute {
              id
              name
            }
            optionValue {
              id
              name
            }
          }
          mainImage {
            id
            md
          }
        }
      }
    }
  }
`;

const ProductsTreeSelector = ({
  style = {},
  checkable,
  filterId,
  valueType,
  takePlus,
  noDetalis,
  removevariants,
  ...props
}) => {
  const [search, setSearch] = useState("");
  const [treeData, setTreeData] = useState([]);
  console.log("🚀 ~ file: productsTreeSelector.js ~ line 73 ~ treeData", treeData);
  const { t } = useTranslation();

  const { loading, previousData, data, ...rest } = useQuery(PRODUCTS_SEARCH, {
    fetchPolicy: "cache-first",
    variables: {
      take: takePlus > 20 ? takePlus : 20,

      where: props.value
        ? {
            AND: [
              {
                NOT: {
                  OR: [
                    {
                      id: { in: props.value },
                      // productType: { not: { equals: "VARIANT" } },
                    },
                    {
                      variants: { some: { id: { in: props.value } } },
                    },
                  ],
                },
              },

              getSearchOfproduct(search),
            ],
          }
        : getSearchOfproduct(search),
    },
  });

  // const data = rest.data ?? rest.previousData;

  const { loading: valuesLoading, ...valueRest } = useQuery(PRODUCTS_SEARCH, {
    fetchPolicy: "cache-first",
    skip: !props.value,
    variables: {
      where: {
        OR: [
          {
            id: props.value ? { in: props.value } : undefined,
            productType: { not: { equals: "VARIANT" } },
          },
          {
            variants: props.value ? { some: { id: { in: props.value } } } : undefined,
          },
        ],
      },
    },
  });
  const valueData = valueRest.data ?? valueRest.previousData;
  console.log("🚀 ~ file: productsTreeSelector.js ~ line 127 ~ valueData", valueData);

  const OptionComp = ({ mainImage, title, variants, selectedOptions, price, quantityInStock }) => (
    <Row align="middle" justify="space-between">
      <Col>
        <Space>
          <img
            src={mainImage?.md}
            style={{ maxWidth: 40, maxHeight: 40 }}
            className="removefromLabel"
            // alt="product sm"
          />
          <Space>
            <Typography.Text strong>{title.auto}</Typography.Text>
            <Typography.Text style={{ fontSize: 12 }}>
              {selectedOptions?.map(
                ({ optionValue }, i) => `${i ? "-" : ""}${optionValue?.name || ""}`,
              )}
            </Typography.Text>
          </Space>
        </Space>
      </Col>
      {(!variants?.length || removevariants) && !noDetalis ? (
        <Col className="removefromLabel">
          <Space>
            {!isNaN(parseInt(quantityInStock)) && `${quantityInStock} in stock`}
            {formatMoney({ price })}
          </Space>
        </Col>
      ) : null}
    </Row>
  );

  useEffect(() => {
    const treeData = [...(valueData?.products?.nodes || []), ...(data?.products.nodes || [])].map(
      ({ id, variants, title, slug, ...rest }) => ({
        value: valueType == "slug" ? slug : id,
        id,
        key: id,
        title: <OptionComp id={id} title={title} variants={variants} {...rest} />,
        // label: title.auto,

        children:
          !removevariants &&
          variants?.map(({ id, variants, title, slug, ...rest }) => ({
            value: valueType == "slug" ? slug : id,
            id,
            key: id,
            title: <OptionComp id={id} title={title} variants={variants} {...rest} />,
            // label: title.auto,

            ...rest,
          })),
        ...rest,
      }),
    );
    treeData && setTreeData(treeData);
  }, [data, valueData]);
  return (
    <Container>
      <TreeSelect
        {...props}
        style={{ width: "100%", minWidth: 150, ...style }}
        // treeDataSimpleMode
        onSearch={debounce((val) => setSearch(val), 200)}
        showSearch
        loading={loading || valuesLoading}
        treeCheckable={checkable}
        allowClear
        // treeNodeLabelProp="label"
        // showCheckedStrategy={SHOW_ALL}
        filterTreeNode={false}
        // treeCheckStrictly={checkable}
        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
        treeData={treeData}
        placeholder={t("order.searchProducts")}
      />
    </Container>
  );
};

const Container = styled.div`
  /* width: 100%; */
  .ant-select-selection-item {
    .removefromLabel {
      display: none;
    }
  }
  .ant-select-multiple .ant-select-selection-item {
    height: 35px;
  }
`;

export default React.memo(ProductsTreeSelector);
