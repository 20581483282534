import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
// import { Input, Space } from "antd";
import MyImage from "../../../../components/common/image";
// import { Input, Button } from "react-chat-elements";
import sendImg from "../../../../assets/imgs/sender2.svg";
// import { useRef } from "react";
import { Button, Input } from "antd";

// const { TextArea } = Input;

interface Props {
  chatInputVal: string;
  setChatInputVal: (NewVal: string) => void;
  sendMessage: () => void;
}

const ChatSender = ({ chatInputVal, setChatInputVal, sendMessage }: Props) => {
  console.log("🚀 ~ file: index.tsx ~ line 18 ~ ChatSender ~ chatInputVal", chatInputVal);
  const { t } = useTranslation();
  // const inp = useRef(null);
  let clearRef = () => {};

  // const inputReferance = useRef();
  // console.log("🚀 ~ file: index.tsx ~ line 23 ~ ChatSender ~ inputReferance", inputReferance);

  console.log("chatInputValchatInputValchatInputVal", chatInputVal.length);

  return (
    <Container>
      <Input
        // referance={inputReferance}
        // clear={(clear) => (clearRef = clear)}
        // multiline={true}
        // autoHeight={true}
        // maxHeight={90}
        size="large"
        placeholder={t("chat.message")}
        value={chatInputVal}
        className={`${chatInputVal.length < 100 ? " deleteScroll" : null}`}
        onChange={(e) => {
          setChatInputVal(e.target.value);
        }}
        onKeyUp={(k) => {
          console.log("🚀 ~ file: index.tsx ~ line 42 ~ k", k);
          if (k.code === "Enter") {
            sendMessage();
            // inputReferance?.current?.value = "";
            // clearRef();
          }
        }}
        addonAfter={
          <Button
            type="primary"
            // backgroundColor="#163663"
            // backgroundColor={theme.primary}
            // autoHeight={true}
            // minHeight={24}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              sendMessage();
              // inputReferance?.current?.clear();
              // clearRef();
            }}>
            <MyImage src={sendImg} width={16} />
          </Button>
        }
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 10px 20px;
  z-index: 999;

  .rce-container-input {
    background: ${(props) => props.theme.backgroundColors.lightGray};
    border-radius: 8px;
    height: 56px;

    .rce-input {
      border: 1px solid ${(props) => props.theme.borderColors.lightGray};
      border-radius: 8px;
      padding: 5px 11px;
      background: ${(props) => props.theme.backgroundColors.lightGray};
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .rce-button {
    width: 32px;
    height: 32px;
    background: linear-gradient(180deg, #163663 0%, #6ebbc5 100%);
    box-shadow: 0px 0px 12px rgba(22, 89, 62, 0.12);
    border-radius: 50%;
    line-height: 0px;
    span {
      transform: ${(props) => (props.theme.i18n.language == "en" ? "rotate(70deg)" : "unset")};
    }
  }

  .send-icon {
    transform: scaleX(-1);
  }

  .deleteScroll {
    .rce-input.rce-input-textarea {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  /* .rce-input.rce-input-textarea {
    &::-webkit-scrollbar {
      display: none;
    }
  } */
`;

export default ChatSender;

// user-select: text;
// white-space: pre-wrap;
// overflow-wrap: break-word;
// height: inherit;
// text-align: initial;
