import { Button, Modal, Space, Form, Input, Typography, Upload, Spin } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CommonSwitch from "./commonSwitch";
import ChooseImg from "../cards/chooseImage";
import makeSlug from "slug-arabic";
import CategoriesSelect from "../../../../../components/common/selectors/categorySelector";
import { useMutation, useQuery } from "@apollo/client";
import { ADD_CATEGORY, SINGLE_CATEGORY } from "../../../categories/addEdit";
import { useHistory, useParams } from "react-router";
import SuccessNotification from "../../../../../components/common/successNotification";
import { CATEGORIES, initialCategoryVariables } from "../../../categories/table";
import { uploadImage } from "../../../../../utlis/upload";
import JoditEditor from "../../../../../components/common/joditEditor";
import { getBase64, uploadButton } from "../../../../theme/slides/addEdit";
import { AppLanguageSwitch } from "../../../../../components/common/websiteLanguage";
import styled from "styled-components";

const { Text } = Typography;

const AddCategoryModal = ({ isModalVisible, handleOk, handleCancel, setIsModalVisible }) => {
  const { t } = useTranslation();
  const [isEn, setIsEn] = useState(true);
  const [mainImage, setMainImage] = useState(null);
  const [addCategory, categoryProps] = useMutation(ADD_CATEGORY, {
    refetchQueries: [{ query: CATEGORIES }],
  });
  console.log("addCategoryaddCategoryaddCategory", addCategory);

  const [slug, setSlug] = useState("");
  const [enImageUrl, setEnIamgeUrl] = useState(null);
  const [arImageUrl, setArIamgeUrl] = useState(null);
  const [images, setImages] = useState(null);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  console.log(
    "🚀 ~ file: addCategoryModal.js ~ line 32 ~ AddCategoryModal ~ description",
    description,
  );
  const [descriptionAr, setDescriptionAr] = useState("");

  const [form] = Form.useForm();
  const route = useHistory();
  const handleImage = async ({ fileList }, lang) => {
    const last = fileList.length - 1;
    if (!fileList[0].url && !fileList[last].preview) {
      fileList[last].preview = await getBase64(fileList[last].originFileObj);
    }
    lang === "ar"
      ? setArIamgeUrl(fileList[last].url || fileList[last].preview)
      : setEnIamgeUrl(fileList[last].url || fileList[last].preview);
  };

  const onFinish = async (variables) => {
    const { parent } = variables;

    setLoading(true);

    const arImage = images?.ar?.file ? await uploadImage(images?.ar?.file) : null;
    const enImage = images?.en?.file ? await uploadImage(images?.en?.file) : null;
    // if (!enImage && !arImage && !editableRecord) {
    //   setLoading(false);

    //   return message.error("must add image");
    // }
    try {
      const vars = {
        ...variables,
        name: form.getFieldValue("name"),
        // slug: form.getFieldValue('slug'),
        // type,
        parent: parent && { id: parent },
        description: {
          en: description,
          ar: descriptionAr,
        },
        image: {
          ar: arImage?.data || enImage?.data,
          en: enImage?.data || arImage?.data,
        },
      };
      console.log("🚀 ~ file: addCategoryModal.js ~ line 65 ~ onFinish ~ vars", vars);
      await addCategory({
        variables: vars,
      });

      SuccessNotification({
        // defaultTitle: t("products.successDefaultMessage"),
        title: t("products.successCategoryMessage"),
        type: "success",
      });

      // route.push(
      //   `/${type == "PRODUCT" ? "category" : "postsCategory"}/${category.data.addCategory.id}`,
      // );
    } catch (error) {
      // errorNotification({
      //   defaultTitle: t("products.errorDefaultMessage"),
      //   title: t("products.errorProductMessage"),
      //   // type: "error"
      // });

      console.log("AddProduct -> error", error);
    }
    setLoading(false);
    setIsModalVisible(false);
  };

  const getSlug = () => {
    form.setFieldsValue({
      slug: makeSlug(slug),
    });
  };

  const MemoizedEmailEditor = useMemo(
    () => <JoditEditor style={{ height: 500 }} content={description} update={setDescription} />,
    [description],
  );
  const MemoizedEmailEditorAr = useMemo(
    () => <JoditEditor style={{ height: 500 }} content={descriptionAr} update={setDescriptionAr} />,
    [descriptionAr],
  );
  const [firstLangLoad, setFirstLangLoad] = useState(false);
  return (
    <Modal
      title={t("products.addCategory")}
      className="add_category_modal"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      centered={true}
      width={628}
      footer={
        false
        // <Button
        //   type="primary"
        //   htmlType="submit"
        //   loading={categoryProps.loading || loading}
        //   disabled={categoryProps.loading || loading}>
        //   {t("products.save")}
        // </Button>
      }>
      <Space direction="vertical" size={20} style={{ width: "100%" }}>
        <Form
          name="basic"
          form={form}
          initialValues={{ available: 0 }}
          onFinish={onFinish}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          onValuesChange={(val) => {
            console.log("AddProduct -> val", val);

            val.name?.ar && setSlug(val.name.ar);
            val.name?.en && setSlug(val.name.en);

            val.slug && setSlug(val.slug);
          }}
          autoComplete="off">
          <AppLanguageSwitchContainer>
            <AppLanguageSwitch
              isEn={isEn}
              setIsEn={setIsEn}
              firstLangLoad={firstLangLoad}
              setFirstLangLoad={setFirstLangLoad}
            />
            {!firstLangLoad ? (
              <LangSpin />
            ) : (
              <div className="category_form">
                {/* {isEn ? (
              <Form.Item label="Category Name" name={["name", "en"]} rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            ) : (
              <Form.Item label="الاسم" name={["name", "ar"]} rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            )} */}
                {/* {isEn ? (
              <Form.Item label="Slug" rules={[{ required: true }]} name={["slug", "en"]}>
                <Input />
              </Form.Item>
            ) : (
              <Form.Item label="اسم مميز" rules={[{ required: true }]} name={["slug", "ar"]}>
                <Input />
              </Form.Item>
            )} */}
                {/* <Form.Item label={t("pages.slug")} name={"slug"} rules={[{ required: true }]}>
              <Input placeholder={t("products.uniqueName")} onBlur={getSlug} />
            </Form.Item> */}
                {/* {isEn ? (
              <Form.Item
                label="Description"
                name={["desc", "en"]}
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}>
                <Input.TextArea maxLength={160} />
              </Form.Item>
            ) : (
              <Form.Item
                label="الوصف"
                name={["desc", "ar"]}
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}>
                <Input.TextArea maxLength={160} />
              </Form.Item>
            )} */}
                {isEn ? (
                  <>
                    <Form.Item label={"Name"} name={["name", "en"]} rules={[{ required: true }]}>
                      <Input
                        placeholder="e.g. Summer collection, Under $100, Staff picks"
                        onBlur={getSlug}
                      />
                    </Form.Item>
                  </>
                ) : (
                  <>
                    <Form.Item label="اسم الفئة" name={["name", "ar"]} rules={[{ required: true }]}>
                      <Input placeholder="تي شيرت " onBlur={getSlug} />
                    </Form.Item>
                  </>
                )}

                <Form.Item label={t("pages.slug")} name={"slug"} rules={[{ required: true }]}>
                  <Input placeholder={t("products.uniqueName")} onBlur={getSlug} />
                </Form.Item>
                {isEn ? (
                  <>
                    <Form.Item label={t("pages.desc")}>{MemoizedEmailEditor}</Form.Item>
                  </>
                ) : (
                  <>
                    <Form.Item label="الوصف">{MemoizedEmailEditorAr}</Form.Item>
                  </>
                )}
              </div>
            )}
          </AppLanguageSwitchContainer>

          <Space className="media_wrapper" size={25} direction="vertical" style={{ width: "100%" }}>
            <div className="img_wrapper">
              <h3>{t("products.categoryImg")}</h3>
              {isEn ? (
                <>
                  <Form.Item
                    name={["image", "en"]}
                    // valuePropName="fileList"
                    // getValueFromEvent={normFile}
                    // label="Image"
                    // rules={[{ required: true }]}
                    noStyle>
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      beforeUpload={() => {
                        // handleImage(file, "en");
                        return false;
                      }}
                      onChange={(file) => {
                        setImages({ ...images, en: file });

                        handleImage(file, "en");
                      }}

                      // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    >
                      {enImageUrl ? (
                        <img
                          src={enImageUrl}
                          alt="avatar"
                          style={{ width: "100%", height: "100%" }}
                        />
                      ) : (
                        uploadButton(loading, t)
                      )}
                    </Upload>
                  </Form.Item>
                </>
              ) : (
                <>
                  <Form.Item
                    name={["image", "ar"]}
                    // valuePropName="fileList"
                    // getValueFromEvent={normFile}
                    noStyle>
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      beforeUpload={() => {
                        // handleImage(file, "ar");
                        return false;
                      }}
                      onChange={(file) => {
                        setImages({ ...images, ar: file });

                        handleImage(file, "ar");
                      }}

                      // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    >
                      {arImageUrl ? (
                        <img src={arImageUrl} alt="avatar" style={{ width: "100%" }} />
                      ) : (
                        uploadButton(loading, t)
                      )}
                    </Upload>
                  </Form.Item>
                </>
              )}
            </div>
            <div className="selector_wrapper">
              {/* <Text> {t("products.mainCategory")} </Text>
              <CategoriesSelect checkable /> */}
              <Form.Item label={t("products.parent")} name="parent">
                <CategoriesSelect type={"PRODUCT"} filterId={undefined} />
              </Form.Item>
            </div>
          </Space>
          <div className="add_category">
            {/* <Button
              type="primary"
              htmlType="submit"
              className="add_category_btn"
              loading={categoryProps.loading || loading}
              disabled={categoryProps.loading || loading}>
              {t("products.save")}
            </Button> */}
            <Button
              type="primary"
              htmlType="submit"
              className="add_category_btn"
              loading={loading}
              disabled={loading}>
              {t("products.save")}
            </Button>
          </div>
        </Form>
      </Space>
    </Modal>
  );
};

const AppLanguageSwitchContainer = styled.div`
  position: relative;
`;

export default AddCategoryModal;
export function LangSpin() {
  return (
    <Spin
      style={{
        // position: "absolute",
        marginTop: "50px",
        width: "100%",
        height: "100px",
        zIndex: 999,
        // background: "white",
      }}
    />
  );
}
