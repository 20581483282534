import { RightOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Select, Space, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const { Title, Paragraph } = Typography;
const { Option } = Select;

const CreateStoreStep2 = ({ setStep }) => {
  const { t } = useTranslation();
  const interestedList = [
    {
      value: "SPORTS",
      label: "رياضة/لياقة",
    },
    {
      value: "FURNITURE",
      label: "اثاث منزلي",
    },
    {
      value: "ELECTRICAL",
      label: "اجهزة كهربائية والكترونية",
    },
    {
      value: "ACCESSORIES",
      label: "اكسسوارات/أحذية",
    },
    {
      value: "COSMETICS",
      label: "صحة/جمال",
    },
    {
      value: "PETS",
      label: "رعاية الحيوانات",
    },
    {
      value: "JELWELRY",
      label: "مجوهرات",
    },
    {
      value: "ELECTROICS",
      label: "اجهزة كهربائية والكترونية",
    },
    {
      value: "OTHERS",
      label: "اخرى",
    },
  ];
  return (
    <Container>
      <Title level={4} style={{ fontWeight: 700 }}>
        {t("auth.createStore.tellAboutYou")}
      </Title>
      <Paragraph className="desc">{t("auth.createStore.thisPrivateInfo")}</Paragraph>
      <Form.Item
        label={<span className="form-label">{t("auth.createStore.whatYouSell")}</span>}
        name="whatISell">
        <Select allowClear onChange={(v) => console.log(v)}>
          {interestedList.map((items) => (
            <Option value={items.value}>{items.label}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label={<span className="form-label">{t("auth.createStore.haveEcommerceExp")}</span>}
        name="haveExp">
        <Select>
          <Option value={true}>نعم</Option>
          <Option value={false}>لا</Option>
        </Select>
      </Form.Item>
      <Row justify="space-between">
        <Col>
          <Button type="link" icon={<RightOutlined />} onClick={() => setStep(1)}>
            {t("auth.createStore.back")}
          </Button>
        </Col>
        <Col>
          <Space>
            <Button type="default" onClick={() => setStep(3)}>
              {t("auth.createStore.skip")}
            </Button>
            <Button type="primary" onClick={() => setStep(3)}>
              {t("auth.createStore.next")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateStoreStep2;

const Container = styled.div`
  .form-label {
    font-weight: 600;
    font-size: 16px;
  }
`;
