import { Button, Typography } from "antd";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import emptyOrder from "../../assets/imgs/emptyorder.svg";
import MyImage from "../../components/common/image";

const { Text } = Typography;

type EmptyProps = {
  image: string;
  description: string;
  buttonLink?: string;
  buttonText?: string;
  importText?: string;
  disableButton?: boolean;
  setVisible?: any;
};

const EmptyPage: React.FC<EmptyProps> = ({
  description,
  image,
  buttonLink,
  buttonText,
  disableButton,
  setVisible,
}: EmptyProps) => {
  const { push } = useHistory();
  return (
    <Container>
      <div style={{ margin: "0 auto", width: 314 }}>
        <MyImage src={image} width={emptyOrder ? 248 : 210} />
        <Text className="text">{description}</Text>
        {buttonLink && (
          <Button
            type="primary"
            block={true}
            disabled={disableButton}
            onClick={() => {
              setVisible ? setVisible(true) : push(`${buttonLink}`);
            }}>
            {buttonText}
          </Button>
        )}
      </div>
    </Container>
  );
};

const Container = styled.div`
  min-height: 60vh;
  display: flex;
  text-align: center;
  align-items: center;

  .ant-image {
    margin-bottom: 20px;
  }
  .text {
    font-size: 20px;
    font-weight: 600;
    color: ${(props) => props.theme.colors.Gray2};
    display: block;
    margin-bottom: 24px;
  }
  .ant-btn {
    height: 48px;
  }
  .ant-btn-link {
    border: 1px solid ${(props) => props.theme.customColors.primary};
  }
`;

export default EmptyPage;

// import { Button, Space, Typography } from "antd";
// import styled from "styled-components";
// import MyImage from "../../components/common/image";
// import noProduct from "../../assets/imgs/no-product.svg";
// import emptyOrder from "../../assets/imgs/emptyorder.svg";
// import noBills from "../../assets/imgs/nobills.svg";
// import { useTranslation } from "react-i18next";
// import { useHistory } from "react-router-dom";

// const { Text } = Typography;

// type EmptyProps = {
//   orderImg?: boolean;
//   orderText?: boolean;
//   billImg?: boolean;
//   billText?: boolean;
// };

// const EmptyPage: React.FC<EmptyProps> = ({
//   orderImg,
//   orderText,
//   billImg,
//   billText,
// }: EmptyProps) => {
//   const { t } = useTranslation();
//   const { push } = useHistory();
//   return (
//     <Container>
//       <Space direction="vertical" size={24} style={{ margin: "0 auto", width: 314 }}>
//         <MyImage
//           src={orderImg ? emptyOrder : billImg ? noBills : noProduct}
//           width={emptyOrder ? 248 : 210}
//         />
//         <Text className="text">
//           {orderText ? t("order.noOrder") : billText ? t("bills.noBills") : t("products.noProduct")}
//         </Text>
//         {!billText && (
//           <Button
//             type="primary"
//             block={true}
//             disabled={orderText ? true : false}
//             onClick={() => {
//               push(`/${orderText ? "orders" : "products"}/new`);
//             }}>
//             {orderText ? t("order.addOrder") : t("products.addProduct")}
//           </Button>
//         )}
//       </Space>
//     </Container>
//   );
// };

// const Container = styled.div`
//   display: flex;
//   text-align: center;
//   align-items: center;
//   height: 70vh;

//   .ant-image {
//     margin-bottom: 20px;
//   }
//   .text {
//     font-size: 20px;
//     font-weight: 600;
//     color: ${(props) => props.theme.colors.Gray2};
//     display: block;
//   }
//   .ant-btn {
//     height: 48px;
//   }
// `;

// export default EmptyPage;
