import { CheckOutlined, MinusOutlined, PlusOutlined, RightOutlined } from "@ant-design/icons";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Button, Card, Col, Row, Space, Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Breakpoints } from "../../../../constants";
import { CURRENT_USER } from "../../../../utlis/Me";

const MOVE_TO_PAYASUGO = gql`
  mutation Mutation {
    moveToPayAsUGo {
      id
    }
  }
`;

const { Text, Title } = Typography;

const PriceCards = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [storeFeature, setStoreFeature] = useState(true);
  const [moveToAsUGo, { loading }] = useMutation(MOVE_TO_PAYASUGO, {
    refetchQueries: [{ query: CURRENT_USER }],
  });
  const {
    data: { me },
  } = useQuery(CURRENT_USER, { fetchPolicy: "cache-first" });
  console.log("🚀 ~ file: priceCards.tsx ~ line 20 ~ PriceCards ~ me", me);
  console.log("🚀 ~ file: priceCards.tsx ~ line 20 ~ PriceCards ~ me", me.currentShop.plan.slug);
  //payOnce
  const priceData = [
    {
      signal: <Text className="signal-1">Pay as you go</Text>,
      price: t("plans.free"),
      priceDesc: t("plans.planeFreeDesc"),
      priceList: [t("plans.planeFreeList1"), t("plans.planeFreeList2")],
      free: true,
      btn: t("plans.startNow"),
      name: "payAsUGo",
    },
    {
      signal: <Text className="signal-2">Pay once</Text>,
      price: "50 دولار",
      priceDesc: t("plans.planeDesc"),
      priceList: [t("plans.planeList1"), t("plans.planeList2")],
      free: false,
      btn: t("plans.enrollNow"),
      name: "payOnce",
    },
  ];
  const featureStoreData = [
    {
      id: 1,
      title: t("plans.basicFeaturs"),
      featurs: [
        t("plans.profStore"),
        t("plans.multiLang"),
        t("plans.multiCurrency"),
        t("plans.fullShipping"),
        t("plans.unLimitedProduct"),
        t("plans.unLimitedCrow"),
        t("plans.discountCopoun"),
        t("plans.storeReports"),
        t("plans.inventManagement"),
        t("plans.unLimitedLocator"),
        t("plans.connectWithDomain"),
        t("plans.free3"),
        t("plans.favList"),
        t("plans.reveiwManagment"),
        t("plans.editTheming"),
        t("plans.printOrders"),
      ],
    },
    {
      id: 2,
      title: t("plans.advancedFeaturs"),
      featurs: [
        t("plans.productsCalss"),
        t("plans.specifyTasks"),
        t("plans.specifyOrder"),
        t("plans.impAndExpWithExl"),
        t("plans.supportProduct"),
        t("plans.advancedFilters"),
        t("plans.chatWithUsers"),
        t("plans.blog"),
        t("plans.customersGroup"),
        t("plans.additionalPages"),
        t("plans.seoShearing"),
        t("plans.acceptOrder"),
        t("plans.buyAsClient"),
      ],
    },
    {
      id: 3,
      title: t("plans.marketingsTools"),
      featurs: [
        t("plans.facebookPixel"),
        t("plans.googleAnalytics"),
        t("plans.facebookShop"),
        t("plans.restoreArcheive"),
        t("plans.emailCampgain"),
        t("plans.smsApp"),
        t("plans.giftsList"),
        t("plans.googleManager"),
        t("plans.advancedCopon"),
        t("plans.popupMarketing"),
        t("plans.specifyUsers"),
      ],
    },
    {
      id: 4,
      title: t("plans.services"),
      featurs: [t("plans.marketHelp"), t("plans.marketCampgain"), t("plans.socialMediaManage")],
    },
    {
      id: 5,
      title: t("plans.support"),
      featurs: [t("plans.supportByChat"), t("plans.supportByPhone")],
    },
  ];
  return (
    <PricingCardsContainer>
      <Container>
        <Row align="middle" gutter={[0, 25]} className="cards-container">
          {priceData.map((pl, i) => (
            <Col md={12} sm={24} xs={24} key={i}>
              <Card className={"price-card" + (pl.free ? " free" : "")}>
                <Space direction="vertical" size={25}>
                  {pl.signal}
                  <Space direction="vertical" style={{ padding: "0 10px" }}>
                    <Text className="price">
                      {pl.price}
                      {!pl.free && <Text className="month">{"  /" + t("plans.month")}</Text>}
                    </Text>
                    <Text className="price-desc">{pl.priceDesc}</Text>
                  </Space>
                  <Space direction="vertical" style={{ textAlign: "start", width: "100%" }}>
                    {pl.priceList.map((item) => (
                      <Space align="start">
                        <CheckOutlined className="right-check" />
                        <Text className="price-desc">{item}</Text>
                      </Space>
                    ))}
                  </Space>
                  <Button
                    type="primary"
                    disabled={loading || me?.currentShop?.plan?.slug === pl.name ? true : false}
                    loading={loading}
                    style={{ width: "80%" }}
                    onClick={() =>
                      pl.name === "payAsUGo" ? moveToAsUGo() : history.push("/changePlan")
                    }>
                    {me?.currentShop?.plan?.slug === pl.name ? t("plans.active") : pl.btn}
                  </Button>
                </Space>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <Row gutter={[0, 25]} align="middle">
        <Col span={24} style={{ textAlign: "center" }}>
          <Button
            type="text"
            className="btn-1"
            icon={storeFeature ? <MinusOutlined /> : <PlusOutlined />}
            onClick={() => setStoreFeature(!storeFeature)}>
            {t("plans.storeFeatues")}
          </Button>
        </Col>
        {storeFeature && (
          <Col span={24}>
            <Card className="price-table" title={t("plans.storeFeatues")}>
              {featureStoreData.map((item) => (
                <>
                  <div className="title-featue" key={item.id}>
                    {item.title}
                  </div>
                  <Row gutter={[24, 24]} style={{ padding: "0 16px", margin: "10px 0" }}>
                    {item.featurs.map((target) => (
                      <Col md={8} sm={12} xs={24}>
                        <Space>
                          <CheckOutlined style={{ color: "#2CDA9D" }} />
                          <Text className="feature-item">{target}</Text>
                        </Space>
                      </Col>
                    ))}
                  </Row>
                </>
              ))}
            </Card>
          </Col>
        )}
      </Row>
    </PricingCardsContainer>
  );
};

export default PriceCards;
const Container = styled.div`
  padding: 60px;
  max-width: 850px;
  margin: 0 auto;
  @media screen and (max-width: ${Breakpoints.sm}) {
    padding: 15px;
  }

  .cards-container {
    margin: 30px 0;
    text-align: center;
    .price-card {
      padding: 16px 11px;
      border: 1.5px solid ${(props) => props.theme.backgroundColors.gray5};
      border-radius: 9px;
      @media screen and (max-width: ${Breakpoints.md}) {
        max-width: 400px;
        margin: auto;
      }
      @media screen and (max-width: ${Breakpoints.xs}) {
        padding: 0;
      }
    }
    .price-desc {
      color: ${(props) => props.theme.colors.Gray2};
    }
    .signal-1 {
      color: #2cda9d;
      font-weight: 700;
      font-size: 16px;
    }
    .signal-2 {
      color: #00a2cc;
      font-weight: 700;
      font-size: 16px;
    }
    .price {
      color: ${(props) => props.theme.customColors.lightBlue};
      font-weight: 700;
      font-size: 30px;
    }
    .month {
      color: ${(props) => props.theme.colors.Gray1};
      font-weight: 600;
      font-size: 14px;
    }
    .btn-free {
      background-color: ${(props) => props.theme.customColors.white};
      color: ${(props) => props.theme.customColors.lightBlue};
    }
  }

  @media screen and (max-width: ${Breakpoints.sm}) {
    .ant-card-body {
      padding: 12px;
    }
  }

  .right-check {
    padding: 5px;
    border-radius: 50%;
    border: none;
    color: #2cda9d;
    background-color: rgba(44, 218, 157, 0.05);
  }
`;

const PricingCardsContainer = styled.div`
  margin-bottom: 20px;
  .btn-1 {
    font-weight: 700;
    font-size: 18px;
    color: ${(props) => props.theme.customColors.lightBlue};
  }
  .price-table {
    border-radius: 8px;
  }
  .ant-card-head {
    background-color: #6ebbc5;
    color: ${(props) => props.theme.customColors.white};
    border: none;
    font-weight: 700;
    font-size: 24px;
  }
  .ant-card-body {
    padding: 0;
  }
  .title-featue {
    padding: 8px 16px;
    margin-bottom: 10px;
    background-color: #e1f5fa63;
    color: ${(props) => props.theme.customColors.lightBlue};
    font-weight: 600;
    font-size: 18px;
  }
  .feature-item {
    color: ${(props) => props.theme.colors.Gray2};
    font-size: 18px;
  }
`;
