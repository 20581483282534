import React, { lazy, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";

import { createClient } from "./apollo";
import Page from "./components/layout";
import { GlobalStyle } from "./components/layout/global.styles";
import { ThemeProvider } from "styled-components";

import { ConfigProvider } from "antd";
import { Grid, Spin } from "antd";
import { varClient } from "./modules/common/defaults";
import { commonMutation } from "./modules/common/resolvers";
import "rc-color-picker/assets/index.css";
import "react-datepicker/dist/react-datepicker.css";

import { theme } from "./components/layout/style";
import Footer from "./components/layout/footer";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18n from "./i18n";
import ForgetPassword from "./pages/auth/forgetPassword";
import ChangePassword from "./pages/auth/ChangePassword";
import ValidateCode from "./pages/auth/validateCode";
import ActiveStore from "./utlis/storeActive";
import TagManager from "react-gtm-module";
import DigitalAuth from "./pages/auth/digitalAuth";
import CreateStore from "./pages/auth/createStore";
import Shops from "./pages/auth/shops";

ConfigProvider.config({
  theme: {
    primaryColor: "#2d6976",
    layoutTriggerBackground: "#2d6976",
    "@border-radius-base": "4px", // major border radius
    // "@border-color-base": "#d9d9d9", // major border color
    "@box-shadow-base": "0 2px 8px rgba(0, 0, 0, 0.15)", // major shadow for layers
    "@input-height-base": "48px",
    // "@text-color": "#786a64",
    // "@heading-color": "#786a64",
    // "@layout-body-background": "#fff ",
  },
});
// import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
const { useBreakpoint } = Grid;
// testlk
const Signin = lazy(() => import("./pages/auth/signin"));
const Signup = lazy(() => import("./pages/auth/signup"));
const BeVendor = lazy(() => import("./pages/auth/beVendor"));

const tagManagerArgs = {
  gtmId: "GTM-P764K2T",
};
!(process.env.NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV_STAGING === "dev") &&
  TagManager.initialize(tagManagerArgs);

export const loading = () => (
  <Spin
    style={{
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    }}
  />
);
const App = () => {
  const client = createClient();
  const { i18n: i18nConsumer } = useTranslation();
  useEffect(() => {
    varClient(client);
    commonMutation.getStart(client);
    const GetIsFirst = "NOT_FIRST";
    let value = localStorage.getItem(GetIsFirst);
    if (value) return;
    localStorage.setItem(GetIsFirst, "DONE");
    i18nConsumer.changeLanguage("ar");
  }, []);
  const sizes = useBreakpoint();
  // const { lang, t } = useTranslation();
  return (
    <ApolloProvider client={client}>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme({ i18n: i18nConsumer, sizes })}>
          <ConfigProvider direction={i18nConsumer.language == "ar" ? "rtl" : "ltr"}>
            {/* direction="rtl"> */}
            <GlobalStyle />
            {/* <Router basename="/admin"> */}
            <Router>
              {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
              <InsideRouter />
            </Router>
          </ConfigProvider>
        </ThemeProvider>
      </I18nextProvider>
    </ApolloProvider>
  );
};

export default App;

function usePageViews() {
  let location = useLocation();
  React.useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageView",
        pagePath: location.pathname,
      },
    });
  }, [location]);
}

const InsideRouter = () => {
  usePageViews();

  return (
    <Switch>
      <Route path="/shops">
        <Shops />
      </Route>
      <Route path="/digitalAuth">
        <DigitalAuth />
      </Route>
      <Route path="/createStore">
        <CreateStore />
      </Route>
      <Page>
        <React.Suspense fallback={loading()}>
          <Route path="/beVendor">
            <BeVendor />
          </Route>

          <Route path="/signup">
            <Signup />
            {/* <Footer /> */}
          </Route>

          <Route path="/signin">
            {/* <Navbar /> */}
            <Signin />
            {/* <Footer /> */}
          </Route>
          <Route path="/forgetpassword">
            {/* <Navbar /> */}
            <ForgetPassword />
            <Footer />
          </Route>
          <Route path="/validatecode">
            {/* <Navbar /> */}
            <ValidateCode />
            <Footer />
          </Route>
          <Route path="/reset">
            {/* <Navbar /> */}
            <ChangePassword />
            <Footer />
          </Route>
          <React.Suspense fallback={loading()}>{<ActiveStore />}</React.Suspense>
        </React.Suspense>
      </Page>
    </Switch>
  );
};
