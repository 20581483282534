import { Form, Input, Button, Row, Col, Typography } from "antd";
import forget from "../../../assets/imgs/forget.svg";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useResponsive } from "../../../lib/useResponsive";
import MyImage from "../../../components/common/image";
import { useTranslation } from "react-i18next";
import { gql, useMutation, useQuery } from "@apollo/client";
import { message as notification } from "antd";
import { CURRENT_USER } from "../../../utlis/Me";
import { useEffect, useState } from "react";
import Navbar from "../../../components/layout/navbar";

const { Text } = Typography;
const REQUEST_RESET = gql`
  mutation REQUEST_RESET($email: String!) {
    requestReset(email: $email, type: vendor)
  }
`;
const ForgetPassword = () => {
  const { xl, lg } = useResponsive();
  const { t } = useTranslation();
  let history = useHistory();
  const { data } = useQuery(CURRENT_USER, {
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (data?.me) history.push("/");
  }, [data]);

  const [email, setEmail] = useState("");
  const trans = "auth.forget";
  const [requestReset, { loading }] = useMutation(REQUEST_RESET, {
    onCompleted: () => {
      console.log("Done Reset");
      notification.success(t(`${trans}.validateSent`));
      history.push({
        pathname: "/validatecode",
        search: `?email=${email}`,
      });
    },
  });

  const onFinish = ({ email }: { email: string }) => {
    console.log("🚀 ~ file: index.tsx ~ line 35 ~ onFinish ~ email", email);

    // console.log(email);
    setEmail(email);

    requestReset({
      variables: {
        email: email.trim(),
      },
    });
  };
  return (
    <Container>
      <div className="row-container">
        <Row gutter={[0, 23]}>
          <Col xs={{ span: 24 }}>
            <Row align="middle" gutter={xl ? [124, 30] : lg ? [30, 30] : ([0, 10] as any)}>
              <Col span={20} />
              <Col span={4}>
                <Navbar />
              </Col>
              <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                <div className="card-form">
                  <Form
                    name="basic"
                    labelCol={{ span: 24 }}
                    initialValues={{
                      remember: true,
                      email: "",
                    }}
                    onFinish={onFinish}>
                    <Form.Item className="text">
                      <Text className="header">{t(`${trans}.title`)}</Text>
                    </Form.Item>

                    <Form.Item
                      name="email"
                      label={t(`${trans}.label`)}
                      rules={[
                        {
                          required: true,
                          message: t("auth.emailError"),
                        },
                      ]}>
                      <Input />
                    </Form.Item>

                    <Form.Item>
                      <Button
                        loading={loading}
                        disabled={loading}
                        htmlType="submit"
                        block
                        type="primary">
                        {t(`${trans}.button`)}
                      </Button>
                    </Form.Item>
                    <>
                      <p className="text return" onClick={() => history.push("/signin")}>
                        {t(`${trans}.return`)} {">"}
                      </p>
                    </>
                  </Form>
                </div>
              </Col>
              <Col lg={{ span: 12 }} xs={{ span: 0 }}>
                <MyImage width={585} src={forget} />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

const Container = styled.div`
  padding-top: 30px;
  background: ${(props) => props.theme.backgroundColors.lightGray};
  /* min-height: 70vh; */
  .ant-image {
    text-align: ${(props) => (props.theme.isBreakpoints.lg ? "initial" : "center")};
  }
  .return {
    color: #fe5f55;
  }
  .return:hover {
    cursor: pointer;
  }
  .card-form {
    background: ${(props) => props.theme.customColors.white};
    box-shadow: 0px 0px 16px rgba(51, 51, 51, 0.12);
    border-radius: 8px;
    padding: ${(props) => (props.theme.isBreakpoints.md ? "46px 40px" : "46px 24px")};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 80px;
  }

  .text {
    text-align: ${(props) => (props.theme.isBreakpoints.lg ? "center" : "initial")};
    display: block;
    margin-bottom: 16px;
    .header {
      font-weight: 700;
      font-size: ${(props) => (props.theme.isBreakpoints.md ? "24px" : "20px")};
      color: ${(props) => props.theme.colors.black3};
    }
  }
  .ant-input {
    height: 48px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
  }
  /* #basic_password {
    height: 38px;
  } */
  .ant-input-password {
    padding: 0 11px;
  }
  .ant-form-item-label > label {
    font-weight: 700;
    font-size: 16px;
    color: ${(props) => props.theme.customColors.Gray2};
  }
  .ant-form-rtl .ant-form-item-label {
    text-align: right;
  }
  .ant-btn {
    height: 56px;
    font-size: 20px;
  }
  .forget {
    font-weight: 400;
    font-size: 16px;
    color: ${(props) => props.theme.colors.red};
    cursor: pointer;
  }
  .footer {
    text-align: center;
    .text {
      font-weight: 400;
      font-size: 16px;
      color: ${(props) => props.theme.colors.Gray2};
    }
    .ant-btn {
      border: 1px solid ${(props) => props.theme.customColors.primary};
      color: ${(props) => props.theme.customColors.primary};
      font-weight: 500;
      border-radius: 4px;
    }
    .ant-btn:hover {
      background: ${(props) => props.theme.customColors.primary};
      color: ${(props) => props.theme.customColors.white};
    }
  }
`;

export default ForgetPassword;
