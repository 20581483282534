import { useTranslation } from "react-i18next";
import Currency from "./currency";
import styled from "styled-components";
import { Form, Input, Typography, Select } from "antd";
import DefaultCurrency from "./defaultCurrency";
import StoreLanguage from "./storeLanguage";
import DefaultLanguage from "./defaultLanguage";
import { useMemo, useState } from "react";
import CurrencySelect from "../../../../components/common/selectors/currencySelect";

const { Text } = Typography;
const { Option } = Select;
export const currenciesVals = [
  {
    val: "SAR",
    name: "ريال",
  },
  {
    val: "EGP",
    name: "جنيه",
  },
];
const CurrencyCard = ({ form, currentCurrencies = [], defaultsOnly }) => {
  console.log(
    "🚀 ~ file: currencyCard.tsx ~ line 14 ~ CurrencyCard ~ currentCurrencies",
    currentCurrencies,
  );
  const { t } = useTranslation();
  // const currentCurrencies = form.getFieldsValue().currencies || [];
  console.log(
    "🚀 ~ file: currencyCard.tsx ~ line 16 ~ CurrencyCard ~ currentCurrencies",
    currentCurrencies,
  );
  const [languages, setLanguages] = useState(null);

  return (
    <FormCard>
      <Text className="header"> {t("settings.lang&currency")} </Text>
      {
        <Form.Item name="currencies" label={t("settings.storeCurrency")}>
          {/* <Currency /> */}
          {/* <Select mode="multiple">
            <Option value="EGP">{t("settings.egp")}</Option>
            <Option value="SAR">{t("settings.sar")}</Option>
          </Select> */}
          <CurrencySelect mode="multiple" />
        </Form.Item>
      }
      <Form.Item name="currencyBase" label={t("settings.defaultCurrency")}>
        {/* <DefaultCurrency />
        <Select>
          {currenciesVals
            .filter((val) => currentCurrencies?.includes(val.val))
            .map((val) => (
              <Option value={val.val}>{val.name}</Option>
            ))}
          <Option value="SAR"> ريال </Option>
          <Option value="EGP"> جنيه </Option>
        </Select> */}
        <CurrencySelect />
      </Form.Item>
      <StoreLanguage languages={languages} setLanguages={setLanguages} />
      <DefaultLanguage languages={languages} setLanguages={setLanguages} />
    </FormCard>
  );
};

const FormCard = styled.div`
  background-color: ${(props) => props.theme.customColors.white};
  padding: 16px;
  box-shadow: 0px 0px 8px rgba(51, 51, 51, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 12px;

  .header {
    font-weight: 700;
    font-size: 16px;
    color: ${(props) => props.theme.colors.black3};
    margin-bottom: 20px;
    display: block;
  }

  .ant-form-item-label > label {
    font-weight: 600;
    font-size: 14px;
    color: ${(props) => props.theme.colors.Gray2};
  }
`;

export default CurrencyCard;
