import { useQuery } from "@apollo/client";
import React from "react";
import styled from "styled-components";
import { CURRENT_USER } from "../../../utlis/Me";
import Pricing from "./newPricing";
import BannerComponent from "./newPricing/banner";
import FinishTrialComponent from "./newPricing/finishTrial";
import PriceCards from "./newPricing/priceCards";

const NewPricingPage = () => {
  const {
    data: { me },
  } = useQuery(CURRENT_USER, { fetchPolicy: "cache-first" });
  if (me?.currentShop?.status === "STOPPED") return <FinishTrialComponent />;
  return <Pricing />;
};

export default NewPricingPage;

const Container = styled.div``;
