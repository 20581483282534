import {
  Modal,
  Button,
  Divider,
  Input,
  Select,
  Spin,
  Row,
  Col,
  Space,
  Tree,
  Typography,
} from "antd";
import React, { useState, useMemo, memo, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import debounce from "lodash.debounce";
import { TreeSelect } from "antd";
import formatMoney from "../../../../utlis/formatMoney";
import styled from "styled-components";
import ProductsTreeSelector from "../../../../components/common/selectors/productsTreeSelector";

const { Option } = Select;

const { SHOW_PARENT, TreeNode } = TreeSelect;

export const getSearchOfproduct = (filter) =>
  !filter
    ? { productType: { not: { equals: "VARIANT" } } }
    : {
        AND: [
          { productType: { not: { equals: "VARIANT" } } },
          {
            OR: [
              {
                title: {
                  OR: [
                    { ar: { contains: filter, mode: "insensitive" } },
                    { en: { contains: filter, mode: "insensitive" } },
                  ],
                },
              },
              {
                categories: {
                  some: {
                    name: {
                      OR: [
                        { ar: { contains: filter, mode: "insensitive" } },
                        { en: { contains: filter, mode: "insensitive" } },
                      ],
                    },
                  },
                },
              },
            ],
          },
        ],
      };
export const PRODUCTS_SEARCH = gql`
  query PRODUCTS_SEARCH($skip: Int, $where: ProductWhereInput!, $variantsWhere: ProductWhereInput) {
    products(skip: $skip, take: 10, orderBy: { id: asc }, where: $where) {
      count
      nodes {
        id
        hasVariants

        price
        title {
          id
          auto
        }
        quantityInStock
        gallery {
          id
          md
        }
        selectedOptions {
          option: attribute {
            id
            name
          }
          optionValue {
            id
            name
          }
        }
        variants(where: $variantsWhere) {
          id
          price
          title {
            id
            auto
          }

          quantityInStock
          gallery {
            id
            md
          }
          selectedOptions {
            option: attribute {
              id
              name
            }
            optionValue {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const CustomTree = styled.div`
  .ant-select-selection-item {
    align-items: center;
    height: 30px;
  }
  .ant-select-selection-item-content {
    margin-top: -4px;
  }
  .ant-select-multiple .ant-select-selection-item-remove > .anticon {
    vertical-align: -0.5em;
  }
`;
const { Search } = Input;

const ProductsSearch = ({ setSelected, selected, noAutoOpen }) => {
  console.log("🚀 ~ file: productsSearch.js ~ line 130 ~ ProductsSearch ~ selected", selected);
  const [visible, setVisible] = useState(!noAutoOpen);
  const [filter, setFilter] = useState("");
  const [products, setProducts] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);

  // const [expandedKeys, setExpandedKeys] = useState();
  // const [selectedProduct, setSelectedProducts] = useState([]);
  // console.log("ProductsSearch -> selectedProduct", selectedProduct);

  // const onExpand = (expandedKeys) => {
  //   console.log("onExpand", expandedKeys); // if not set autoExpandParent to false, if children expanded, parent can not collapse.
  //   // or, you can remove all expanded children keys.

  //   setExpandedKeys(expandedKeys);
  //   setAutoExpandParent(false);
  // };

  // const onCheck = (newCheckedKeys, { checked, node }) => {
  //   console.log("onCheck -> newCheckedKeys", newCheckedKeys);
  //   setCheckedKeys(newCheckedKeys);
  //   // if (checked) {
  //   //   setCheckedKeys([...checkedKeys, node.value]);
  //   // } else {
  //   //   const checkedKeysCopy = [...checkedKeys];
  //   //   setCheckedKeys(checkedKeysCopy.filter((id) => id !== node.value));
  //   // }

  //   // console.log("onCheck -> checkedKeys", checkedKeys);

  //   // setSelectedProducts(
  //   //   products.filter(({ id }) => checkedKeys.some((cId) => id == cId))
  //   // );
  // };

  // const { loading, data } = useQuery(PRODUCTS_SEARCH, {
  //   variables: {
  //     where: getSearchOfproduct(filter),
  //   },
  //   // skip: !filter,
  // });

  // const selectedProductsCount = useQuery(PRODUCTS_SEARCH, {
  //   variables: {
  //     where: {
  //       id: { in: checkedKeys },
  //       productType: { not: { equals: "HAS_VARIANT" } },
  //     },
  //   },
  // });
  // console.log(
  //   "selectedProductsCount",
  //   selectedProductsCount.data?.products,
  // );

  // const selectedProducts = useQuery(PRODUCTS_SEARCH, {
  //   variables: {
  //     where: { id: { in: checkedKeys }, isVariant: { not: true } },
  //     variantsWhere: { id: { in: checkedKeys } },
  //   },
  // });
  // console.log("selectedProducts", selectedProducts.data?.products);

  // useEffect(() => {
  //   data?.products?.nodes && setProducts(data?.products?.nodes);
  // }, [data]);

  useEffect(() => {
    console.log("🚀 ~ file: productsSearch.js ~ line 198 ~ useEffect ~ selected", selected);

    selected?.nodes && setCheckedKeys(selected.nodes.map(({ id }) => id));
  }, [selected]);

  // const onChange = debounce((e) => {
  //   setFilter(e.target.value);
  // }, 350);
  console.log("data?.products?.nodes", products);

  const takePlus = checkedKeys.length + 10;
  console.log("🚀 ~ file: productsSearch.js ~ line 200 ~ ProductsSearch ~ takePlus", takePlus);

  return (
    <CustomTree>
      <ProductsTreeSelector
        checkable
        onChange={(value, labels) => {
          setCheckedKeys(value);
          setSelected({
            count: labels.length,
            nodes: labels.map(({ props }) => props),
          });
        }}
        value={checkedKeys}
        takePlus={takePlus}
      />
      {/* <Search
        placeholder="input search text"
        // onFocus={() => setVisible(true)}
        onChange={(e) => {
          setVisible(true);
        }}
        onSearch={() => setVisible(true)}
      /> */}
      {/* 
      <Button type="primary" onClick={() => setVisible(true)}>
        Browse products
      </Button> */}
      {/* <Modal
        title="Select products"
        visible={visible}
        okText="Add"
        onOk={() => {
          setSelected(selectedProductsCount.data?.products);

          setVisible(false);
        }}
        okButtonProps={{ loading: selectedProductsCount.loading }}
        onCancel={() => setVisible(false)}
      >
        <Search
          placeholder="input search text"
          onChange={(e) => {
            e.persist();
            onChange(e);
          }}
          style={{ width: "100%" }}
        />
        <Divider />

        <CustomTree>
          <Tree
            checkable
            onCheck={onCheck}
            blockNode
            virtual={false}
            checkedKeys={checkedKeys}
            treeData={
              products.map(
                ({ title, id, gallery, variants, quantityInStock, price }) => ({
                  title: (
                    <Row align="middle" justify="space-between">
                      <Col>
                        <img
                          src={gallery[0]?.md}
                          style={{ width: 50 }}
                          // alt="product sm"
                        />
                        {title.auto}
                      </Col>
                      {!variants?.length ? (
                        <Col>
                          <Space>
                            {!isNaN(parseInt(quantityInStock)) &&
                              `${quantityInStock} in stock`}

                            {formatMoney({ price })}
                          </Space>
                        </Col>
                      ) : null}
                    </Row>
                  ),
                  value: id,
                  key: id,
                  children: variants.map(
                    ({
                      title,
                      gallery,
                      quantityInStock,
                      price,
                      id,
                      selectedOptions,
                    }) => ({
                      title: (
                        <Row align="middle" justify="space-between">
                          <Col>
                            <img
                              src={gallery[0]?.md}
                              style={{ width: 50 }}
                              // alt="product sm"
                            />
                            <Space size={-5} direction="vertical">
                              <Typography.Text strong>
                                {title.auto}
                              </Typography.Text>
                              <Typography.Text>
                                {selectedOptions.map(
                                  ({ optionValue }) => `${optionValue.name}  `
                                )}
                              </Typography.Text>
                            </Space>
                          </Col>
                          <Col>
                            <Space>
                              {!isNaN(parseInt(quantityInStock)) &&
                                `${quantityInStock} in stock`}

                              {formatMoney({ price })}
                            </Space>
                          </Col>
                        </Row>
                      ),
                      value: id,
                      key: id,
                    })
                  ),
                })
              ) || []
            }
          />
        </CustomTree>
        <Typography.Text>
          {checkedKeys.length} selected
        </Typography.Text>
      </Modal>
    */}
    </CustomTree>
  );
};

export default ProductsSearch;
// <Space size={100}>
// <div>
//   <img
//     src={gallery[0]?.sm}
//     style={{ width: 30 }}
//     // alt="product sm"
//   />
//   {mTitle}
// </div>
// <Space>
//   {!isNaN(parseInt(quantityInStock)) &&
//     `${quantityInStock} in stock`}

//   {formatMoney(price)}
// </Space>
// </Space>

// <TreeSelect
// // value={value}
// dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
// placeholder="Please select"
// allowClear
// onChange={(value) => setValue(value)}
// style={{ width: "100%" }}
// treeDataSimpleMode
// onSearch={(val) => {
//   onChange(val);
// }}
// // notFoundContent={loading ? <Spin size="small" /> : null}
// showSearch
// treeCheckable={true}
// showCheckedStrategy={SHOW_PARENT}
// // treeData={data?.products?.products.map(({ mTitle, id, parentId }) => ({ title: mTitle, value: id, pId: parentId, id })) || []}
// >
// {products.map(
//   ({ id, mTitle, gallery, variants, quantityInStock, price }) => (
//     <TreeNode
//       value={id}
//       key={id}
//       title={
//         <Row align="middle" justify="space-between">
//           <Col>
//             <img
//               src={gallery[0]?.sm}
//               style={{ width: 30 }}
//               // alt="product sm"
//             />
//             {mTitle}
//           </Col>
//           <Col>
//             <Space>
//               {!isNaN(parseInt(quantityInStock)) &&
//                 `${quantityInStock} in stock`}

//               {formatMoney(price)}
//             </Space>
//           </Col>
//         </Row>
//       }
//     >
//       {variants.map((variant) => (
//         <TreeNode
//           value={variant.id}
//           key={variant.id}
//           title={
//             <Row align="middle" justify="space-between">
//               <Col>
//                 <img
//                   src={
//                     variant.gallery[0]?.sm ||
//                     variant.gallery[0]?.sm
//                   }
//                   style={{ width: 30 }}
//                   // alt="product sm"
//                 />
//                 {variant.mTitle}
//               </Col>
//               <Col>
//                 <Space>
//                   {!isNaN(parseInt(quantityInStock)) &&
//                     `${variant.quantityInStock} in stock`}

//                   {formatMoney(variant.price)}
//                 </Space>
//               </Col>
//             </Row>
//           }
//         />
//       ))}
//     </TreeNode>
//   )
// )}
// </TreeSelect>
