import { Col, Form, Row, Space, Switch, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormCard } from "../style";

const PublishSwitchComponent = () => {
  const { t } = useTranslation();
  return (
    <FormCard style={{ padding: "16px 0" }}>
      <Space direction="vertical" size={16} style={{ width: "100%", padding: "0 16px" }}>
        <Typography.Title level={4}>{t("products.productStatue")}</Typography.Title>
        <Row justify="space-between">
          <Col>
            <Typography.Text>{t("products.publishonStore")}</Typography.Text>
          </Col>
          <Col>
            <Form.Item name="published" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
        </Row>
      </Space>
    </FormCard>
  );
};

export default PublishSwitchComponent;
