import React, { useState, useEffect, useMemo } from "react";

import { Button, Divider, Input, TreeSelect } from "antd";
import { gql, useQuery, useMutation } from "@apollo/client";
import debounce from "lodash/debounce";
import makeSlug from "slug-arabic"; // by B7r :)
import { PlusOutlined } from "@ant-design/icons";
export const ADD_BRAND = gql`
  mutation ADD_BRAND($name: String!, $slug: String!) {
    addBrand(name: $name, slug: $slug) {
      id
    }
  }
`;
export const BRANDS = gql`
  query BRANDS($filter: BrandWhereInput) {
    brands(where: $filter, take: 10) {
      nodes {
        id
        name
        slug
      }
    }
  }
`;

const BrandSelector = ({
  style = {},
  checkable,
  initialSlugs = undefined,
  canAdd = undefined,
  isValueId,
  ...props
}) => {
  const [search, setSearch] = useState("");
  const [brand, setBrand] = useState("");
  const variables = {
    filter: {
      OR: [{ ...initialSlugs }, { name: { contains: search, mode: "insensitive" } }],
    },
  };
  const [treeData, setTreeData] = useState([]);
  const [addBrand, brandprops] = useMutation(ADD_BRAND, {
    refetchQueries: [{ query: BRANDS, variables }],
  });
  const { data, loading } = useQuery(BRANDS, {
    fetchPolicy: "cache-and-network",
    variables,
  });
  useEffect(() => {
    const treeData = data?.brands.nodes.map(({ slug, id, name, ...rest }) => ({
      value: isValueId ? id : slug,
      label: name,
      key: slug,
      ...rest,
    }));
    treeData && setTreeData(treeData);
  }, [data]);

  return (
    <TreeSelect
      {...props}
      style={{ width: "100%", ...style }}
      // treeDataSimpleMode
      onSearch={debounce((val) => setSearch(val), 200)}
      showSearch
      loading={loading}
      treeCheckable={checkable}
      allowClear
      // showCheckedStrategy={SHOW_ALL}
      filterOption={false}
      treeCheckStrictly={checkable}
      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
      treeData={treeData}
      // placeholder="Search for Brand"
      placeholder="e.g. Nike"
      dropdownRender={(menu) => (
        <div>
          {menu}

          {canAdd ? (
            <>
              <Divider style={{ margin: "4px 0" }} />
              <div
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  padding: 8,
                }}>
                <Input
                  style={{ flex: "auto" }}
                  value={brand}
                  onChange={(e) => setBrand(e.target.value)}
                />
                <Button
                  // style={{
                  //   flex: "none",
                  //   padding: "8px",
                  //   display: "block",
                  //   cursor: "pointer"
                  // }}
                  loading={brandprops.loading}
                  icon={<PlusOutlined />}
                  // ghost
                  type="link"
                  onClick={() => {
                    brand &&
                      addBrand({
                        variables: { name: brand, slug: makeSlug(brand) },
                      });
                    setBrand("");
                  }}>
                  Add
                </Button>
              </div>
            </>
          ) : null}
        </div>
      )}
    />
  );
};

export default React.memo(BrandSelector);
