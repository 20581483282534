import { FileSearchOutlined, MobileOutlined } from "@ant-design/icons";
import { useQuery, useSubscription } from "@apollo/client";
import { Badge, notification } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import analytics from "../../../assets/imgs/analytics.svg";
import carbon_apps from "../../../assets/imgs/carbon_apps.svg";
import chat from "../../../assets/imgs/chat.svg";
import discount from "../../../assets/imgs/discount.svg";
import facebook from "../../../assets/imgs/facebook.svg";
import home from "../../../assets/imgs/home.svg";
import orders from "../../../assets/imgs/orders.svg";
import pages from "../../../assets/imgs/pages.svg";
import posts from "../../../assets/imgs/posts.svg";
import products from "../../../assets/imgs/products.svg";
import settings from "../../../assets/imgs/settings.svg";
import themeImg from "../../../assets/imgs/themeImg.svg";
import moneyArrow from "../../../assets/imgs/moneyArrow.svg";
import users from "../../../assets/imgs/users.svg";
import analysticImg from "../../../assets/imgs/ic_analytics.svg";

import { Permissions } from "../../../constants/permissions";
import FeaturesNames from "../../../enums/featuresNames";
import { COUNT_NOT_SEEN } from "../../../modules/common/queries";
import { S_NEW_MESSAGE } from "../../../modules/common/subscriptions";
import { getHasPermission } from "../../../utlis/getHasPermission";
import { CURRENT_USER } from "../../../utlis/Me";
import MyImage from "../../common/image";
import { varRoomsState } from "../../../pages/chat/chat";
import { makeArrayUniqe } from "../../../lib/makeArrayUniqe";
const MessagesBadge = styled(Badge)`
  .ant-badge-count {
    box-shadow: 0 0 0 0px #d9d9d9 inset;
  }
`;
const MessageLink = styled.span`
  width: 100%;
  padding: 0px 5px;
  color: blue;
  &:hover {
    cursor: pointer;
  }
`;
export const sideArray = ({ t, userCurrentApps }) =>
  [
    {
      label: t("home.home"),
      href: "/",
      icon: <MyImage src={home} width={18} />,
      permission: "",
      // target: "homee",
    },
    {
      label: <ChatLabel />,
      target: "chat-boarding",
      href: "/chat",
      icon: <MyImage src={chat} width={18} />,
      permission: "chatScreen",
      feature: FeaturesNames.liveChat,
      APP_NAME: "B7R_CHAT",
    },
    {
      label: t("home.orders"),
      icon: <MyImage src={orders} width={18} />,
      permission: "orders",
      href: "/orders",
      target: "orders-boarding",
      children: [
        { label: t("home.orders"), href: "/orders" },
        { label: "السلات المتروكة", href: "/abandonment" },
        // { label: t("home.leavedCarts"), href: "/orders" },
      ],
    },
    {
      label: t("home.customers"),
      target: "users-boarding",
      href: "/customers",
      icon: <MyImage src={users} width={18} />,
      permission: "users",
      // icon: <svg src={users} width={18} />,
      // children: [
      //   { label: t("home.users"), href: "/users" },
      //   // { label: 'Customers', href: '/customers' },
      // ],
    },
    {
      label: t("home.products"),
      target: "product-boarding",
      icon: <MyImage src={products} width={18} />,
      children: [
        { label: t("home.products"), href: "/products", permission: "addProduct" },
        { label: t("home.inventory"), href: "/inventory", permission: "inventories" },

        {
          label: t("home.collections"),
          href: "/collections",
          disabled: true,
          permission: "navigation",
        },
        { label: t("home.categories"), href: "/categories", permission: "addProduct" },

        {
          label: t("home.productSettings"),
          target: "childSubMenue",
          // icon: <TagOutlined />,
          children: [
            { label: t("home.tags"), href: "/tags", permission: "addProduct" },
            { label: t("home.brands"), href: "/brands", permission: "addProduct" },
            // { label: "Types", href: "/types" },
            { label: t("home.attributes"), href: "/attributes", permission: "addProduct" },
          ],
        },
      ],
    },
    // {
    //   label: t("home.analytics"),
    //   target: "analytics-boarding",
    //   icon: <MyImage src={analytics} width={18} />,
    //   href: "/analytics",
    //   permission: "analytics",
    //   feature: FeaturesNames.analytics,
    // },
    {
      label: t("home.analytic"),
      target: "analytics-boarding",
      icon: <MyImage src={analysticImg} width={18} />,
      children: [
        { label: t("home.analytics"), href: "/analytics", permission: "analytics" },
        { label: t("home.liveReport"), href: "/liveReports", permission: "analytics" },
      ],
    },

    // !!!!!!!!!!!!!!!!!!!
    {
      label: t("home.marketing"),
      target: "marketing-boarding",
      icon: <MyImage src={discount} width={18} />,
      children: [
        {
          label: t("home.discounts"),
          href: "/discounts",
          // icon: <MyImage src={discount} width={18} />,
          permission: "discounts",
          feature: FeaturesNames.coupons,
        },
        {
          label: t("home.upSells"),
          href: "/upSells",
        },
        {
          label: "مسوقين بالعموله",
          href: "/affiliate/settings",

          APP_NAME: "AFFILIATE",
        },
        {
          label: t("emailCompaign.title"),
          target: "childSubMenue",
          // icon: <MyImage src={discount} width={18} />,
          children: [
            {
              label: t("emailCompaign.email"),
              href: "/emailCompaign",

              // icon: <MyImage src={discount} width={18} />,
            },
            // {
            //   label: t("emailCompaign.sms"),
            //   href: "/smsCompaign",
            //   // icon: <MyImage src={discount} width={18} />,
            // },
          ],
        },
        {
          label: t("home.integration"),
          href: "/integration",
          // icon: <MyImage src={discount} width={18} />,
          // permission: "discounts",
          // feature: FeaturesNames.coupons,
        },
      ].filter(({ APP_NAME }) => (APP_NAME ? userCurrentApps?.includes(APP_NAME) : true)),
    },
    // {
    //   label: t("home.discounts"),
    //   href: "/discounts",
    //   icon: <MyImage src={discount} width={18} />,
    //   permission: "discounts",
    //   feature: FeaturesNames.coupons,
    // },
    {
      label: t("home.theme"),
      target: "theme-boarding",
      icon: <MyImage src={themeImg} width={18} />,
      permission: "theming",
      children: [
        { label: t("home.themes"), href: "/themes", permission: "theming" },
        // { label: 'Slides', href: '/themes/slides' },
        { label: t("home.navigation"), href: "/themes/navigation", permission: "navigation" },
      ],
    },
    {
      label: t("home.pages"),
      target: "pages-boarding",
      href: "/pages",
      icon: <MyImage src={pages} width={18} />,
      permission: "pages",
      feature: FeaturesNames.pages,
    },
    // {
    //   label: t("home.users"),
    //   target: "users-boarding",
    //   href: "/users",
    //   icon: <MyImage src={users} width={18} />,
    //   permission: "users",
    //   // icon: <svg src={users} width={18} />,
    //   // children: [
    //   //   { label: t("home.users"), href: "/users" },
    //   //   // { label: 'Customers', href: '/customers' },
    //   // ],
    // },
    {
      label: t("home.blog"),
      icon: <MyImage src={posts} width={18} />,
      permission: "blog",
      feature: FeaturesNames.blog,
      children: [
        { label: t("home.posts"), href: "/posts", permission: "blog", feature: FeaturesNames.blog },
        // { label: 'Slides', href: '/themes/slides' },
        {
          label: t("home.categories"),
          href: "/postsCategories",
          permission: "blog",
          feature: FeaturesNames.blog,
        },
      ],
    },
    // {
    //   // label: t("home.facebook"),
    //   label: "Facebook",
    //   target: "facebook-boarding",
    //   icon: <MyImage src={facebook} width={18} />,
    //   href: "/plugins/facebook",
    // },

    // {
    //   label: "SEO",
    //   icon: <FileSearchOutlined />,
    //   href: "/seo/",
    //   feature: FeaturesNames.seo,
    // },

    {
      label: t("home.apps"),
      target: "apps-boarding",
      icon: <MyImage src={carbon_apps} width={18} />,
      children: [
        {
          label: t("home.allApps"),
          href: "/apps",
          icon: <MyImage src={carbon_apps} width={18} />,
        },
        ...[
          {
            label: t("apps.appFacebook"),
            target: "facebook-boarding",
            // icon: <MyImage src={facebook} width={18} />,
            href: "/plugins/facebook",
            APP_NAME: "FACEBOOK",
          },
          {
            label: t("apps.taager"),
            // target: "facebook-boarding",
            // icon: <MyImage src={facebook} width={18} />,
            href: "/apps/Taager/settings",
            APP_NAME: "TAAGER",
          },
          {
            label: t("apps.aliExpress"),
            // target: "facebook-boarding",
            // icon: <MyImage src={facebook} width={18} />,
            href: "/apps/aliExpress/settings",
            APP_NAME: "ALI_EXPRESS",
          },
          {
            label: t("apps.seo"),
            icon: <FileSearchOutlined />,
            href: "/seo",
            feature: FeaturesNames.seo,
            APP_NAME: "SEO",
          },
          {
            label: t("apps.reviewsHeader"),
            icon: <FileSearchOutlined />,
            href: "/reviewsandrates",
            APP_NAME: "REVIEWS",
          },
          {
            label: t("apps.popupHeader"),
            icon: <FileSearchOutlined />,
            href: "/apps/popups",
            APP_NAME: "POPUPS",
          },
          {
            label: t("apps.knawat"),
            icon: <FileSearchOutlined />,
            href: "/apps/knawat",
            APP_NAME: "KNAWAT",
          },
          {
            label: "sendStrap",
            icon: <FileSearchOutlined />,
            href: "/apps/sendstrap/info",
            APP_NAME: "SENDSTRAP_CHAT",
          },
          {
            label: t("apps.mobileApp"),
            icon: <MobileOutlined />,
            APP_NAME: "APP",
            target: "childSubMenue",

            children: [
              { label: t("apps.appTheme"), href: "/app/themes" },
              { label: t("apps.slides"), href: "/app/slides" },
              {
                label: t("apps.notifications"),
                href: "/pushNotification",
              },
              { label: t("apps.settingApp"), href: "/app/settings" },
            ],
          },
          {
            label: t("apps.loyalty"),
            // icon: <FileSearchOutlined />,
            href: "/apps/loyalty/settings",
            APP_NAME: "B7R_LOYALTY_PROGRAM",
          },
        ].filter(({ APP_NAME }) => userCurrentApps?.includes(APP_NAME)),
      ],

      // [
      //   { label: t("home.posts"), href: "/posts", permission: "blog", feature: FeaturesNames.blog },
      //   // { label: 'Slides', href: '/themes/slides' },
      //   {
      //     label: t("home.categories"),
      //     href: "/postsCategories",
      //     permission: "blog",
      //     feature: FeaturesNames.blog,
      //   },
      // ],
    },
    // {
    //   label: t("home.apps"),
    //   href: "/apps",
    //   icon: <MyImage src={carbon_apps} width={18} />,
    //   // permission: "appsDa",
    //   // feature: "NOT_USE",
    // },
    {
      label: t("home.affiliate"),
      target: "",
      href: "/affiliate",
      icon: <MyImage src={moneyArrow} width={18} />,
      // permission: "updateSettings",
      permission: "",
    },
    {
      label: t("home.settings"),
      target: "settings-boarding",
      href: "/settings",
      icon: <MyImage src={settings} width={18} />,
      // permission: "updateSettings",
      permission: "",
    },
    // {
    //   label: t("home.facebook"),
    //   label: "Facebook",
    //   href: "/plugins/facebook/",
    //   icon: <Divider style={{ margin: 0 }} />,
    // },
    // {
    //   label: t("home.sellChannel"),
    //   href: "/plugins/facebook/sellingChannels",
    //   icon: <MyImage src={sellChannel} width={18} />,
    // },
    // {
    //   // label: t("home.facebook"),
    //   label: "Facebook",
    //   href: "/plugins/facebook/",
    //   icon: <MyImage src={facebook} width={18} />,
    // },
    // { label: "Chat", href: "/myChat" },

    // {
    //   label: "",
    //   href: "",
    //   disabled: true,
    //   // permission: "navigation",
    // },
    // {
    //   label: "",
    //   href: "",
    //   disabled: true,
    //   // permission: "navigation",
    // },
  ].filter(({ APP_NAME }) => (APP_NAME ? userCurrentApps?.includes(APP_NAME) : true));
const ChatLabel = () => {
  const {
    data: { me },
  } = useQuery(CURRENT_USER, {
    fetchPolicy: "cache-first",
  });
  const hasFeature = me?.currentShop?.features[FeaturesNames.liveChat];
  const hasPermission = getHasPermission(me, { permission: Permissions.chatScreen });

  const {
    loading: loadingUnSeenCount,
    error: errorUnSeenCount,
    data: dataUnSeenCount,
    refetch,
  } = useQuery(COUNT_NOT_SEEN, {
    skip: !hasFeature || !hasPermission,
  });
  console.log("🚀 ~ file: index.js ~ line 669 ~ ChatLabel ~ dataUnSeenCount", dataUnSeenCount);
  console.log(dataUnSeenCount?.chatNotSeenNum, "numbercountChat");
  const history = useHistory();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { data: subData } = useSubscription(S_NEW_MESSAGE);

  useEffect(() => {
    console.log(subData, "datasubscribtion");
    if (subData?.newMessageInRooms) {
      const room = varRoomsState().find((room) => {
        return room.slug == subData?.newMessageInRooms.room.slug;
      });
      console.log("🚀 ~ file: siderData.js ~ line 427 ~ room ~ room", room);
      if (room) {
        const filterdRooms = varRoomsState().filter((room) => {
          return room.slug !== subData?.newMessageInRooms.room.slug;
        });
        console.log(
          "🚀 ~ file: siderData.js ~ line 431 ~ filterdRooms ~ filterdRooms",
          filterdRooms,
        );
        varRoomsState(
          makeArrayUniqe(
            [
              {
                ...room,
                ...subData?.newMessageInRooms.room,
                messages: room.messages
                  ? makeArrayUniqe([...room.messages, subData?.newMessageInRooms])
                  : undefined,
                lastMessage: subData?.newMessageInRooms,
              },
              ...filterdRooms,
            ],
            "slug",
            false,
            false,
            // "lastMessage.createdAt",
          ),
        );
      } else {
        varRoomsState(
          makeArrayUniqe(
            [
              {
                ...subData?.newMessageInRooms.room,
                messages: [subData?.newMessageInRooms],
                lastMessage: subData?.newMessageInRooms,
              },
              ...varRoomsState(),
            ],
            "slug",
          ),
        );
      }
      if (!history.location.pathname.includes(subData?.newMessageInRooms.room.slug)) {
        notification.info({
          message: <MessageLink>{subData?.newMessageInRooms.message}</MessageLink>,
          onClick: () => {
            history.push(`/chat/${subData?.newMessageInRooms.room.slug}`);
          },
        });

        refetch();
      }
    }
  }, [subData]);

  return (
    <>
      {t("home.chat")}
      {!loadingUnSeenCount && !errorUnSeenCount && (
        <MessagesBadge
          style={{
            margin: `0px ${language === "ar" ? "3rem 0px 0px" : "0px 0px 4rem"}`,
            backgroundColor: "#FF0000",
          }}
          count={dataUnSeenCount?.chatNotSeenNum || 0}
        />
      )}
    </>
  );
};
