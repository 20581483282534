import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Spin } from "antd";
import { Route, Redirect, useHistory } from "react-router-dom";
import { CURRENT_USER } from "./Me";
import { isAdmin } from "./permission";
import Page from "../components/layout";
import { getHasPermission } from "./getHasPermission";
import NotAllows from "./notAllows";
import Loading from "../components/common/Loading";
import { varDomain } from "../modules/common/defaults";

const IsAllow = ({ route, path, exact, render }) => {
  const { data, loading } = useQuery(CURRENT_USER, {
    fetchPolicy: "cache-first",
  });
  const history = useHistory();
  const { push } = history;
  useEffect(() => {
    if (!loading) {
      const currentPath = history.location.pathname;
      if (!condition && !currentPath.includes("signin") && !currentPath.includes("beVendor")) {
        if (history.location.pathname == "/") {
          push(`/signin${history.location.search}`);
        } else {
          push(`/signin?routePath=${history.location.pathname}${history.location.search}`);
        }
      }
    }
  }, [route.roles]);
  useEffect(() => {
    if (!loading) {
      const currentPath = history.location.pathname;

      if (
        condition &&
        !varDomain() &&
        !currentPath.includes("createStore") &&
        !currentPath.includes("shops")
      ) {
        console.log("🚀 ~ file: isAllow.js ~ line 38 ~ useEffect ~ varDomain()", varDomain())

        console.log("🚀 ~ file: isAllow.js ~ line 40 ~ useEffect ~ includes");

        push(`/shops`);
      }
    }
  }, [loading]);
  if (loading) return <Loading />;
  const { me } = data;
  console.log("🚀 ~ file: isAllow.js ~ line 15 ~ IsAllow ~  me", me);
  const condition =
    me?.role &&
    (route.roles?.includes(me?.role) ||
      isAdmin(me?.role) ||
      route.roles?.includes("all") ||
      (me?.role === "CUSTOM" && me?.permissions.includes(route.permission)));

  const hasPermission = getHasPermission(me, route);
  const hasFeature = !route?.feature || me?.currentShop?.features[route.feature];

  console.log("🚀 ~ file: isAllow.js ~ line 17 ~ IsAllow ~ condition", condition);

  const permissions = {
    hasFeature,
    hasPermission,
  };

  if (loading)
    return (
      <Spin
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    );

  return condition && hasPermission && hasFeature ? (
    <Route path={path} exact={exact} render={render} />
  ) : (
    <NotAllows {...permissions} />
  );
};
export default IsAllow;
