import { gql, useMutation } from "@apollo/client";
import { Button, Col, Form, Menu, Modal, notification, Row, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ProductSetting from "../../../../settings/productSettings";
import ProductSwitchesComponent from "../../../../settings/productSettings/cards/productSwitches";
import { FormCard } from "../style";

const { Title, Text } = Typography;
const { SubMenu } = Menu;
const UPSERT_PRODUCT_SETTING = gql`
  mutation UPSERT_PRODUCT_SETTING($productId: Int, $data: ProductSettingUpdateInput!) {
    upsertProductSetting(data: $data, productId: $productId) {
      id
    }
  }
`;
const ProductSettingSubMenu = ({ id }) => {
  const { t } = useTranslation();
  const [visable, setVisable] = useState(false);
  const [updateProductSetting, { loading: UpdateLoading }] = useMutation(UPSERT_PRODUCT_SETTING, {
    variables: { productId: id },
  });
  const [form] = useForm();
  // const settings = data?.productSetting;
  const onFinish = async (variables) => {
    console.log("onFinish -> variables", variables);
    try {
      await updateProductSetting({
        variables: {
          productId: id,
          data: { ...variables },
        },
      });
      setVisable(false);
      notification.success({ message: "update settings successfully" });
    } catch (error) {
      notification.error({ message: "Not successfully" });
      console.log("AddProduct -> error", error);
    }
  };
  const showModal = () => {
    setVisable(true);
  };

  const handleCancel = () => {
    setVisable(false);
  };
  return (
    <Container>
      <FormCard>
        <Row justify="space-between">
          <Text className="productSetting-title">{t("settings.productSettings")}</Text>
          <Button type="link" className="show-btn" onClick={showModal}>
            {t("settings.productSettings")}
          </Button>
        </Row>
      </FormCard>
      <Modal
        title={t("settings.productSettings")}
        visible={visable}
        onOk={form.submit}
        okButtonProps={{ disabled: UpdateLoading, loading: UpdateLoading }}
        okText={t("settings.save")}
        onCancel={handleCancel}
        cancelText={t("settings.cancel")}
        width={600}>
        <Form
          name="ProductSetting"
          // initialValues={{ ...settings }}
          onFinish={onFinish}
          form={form}>
          <ProductSwitchesComponent form={form} id={id} />
        </Form>
      </Modal>
    </Container>
  );
};

export default ProductSettingSubMenu;

const Container = styled.div`
  .productSetting-title {
    font-weight: 700;
    font-size: 16px;
  }
  .show-btn {
    font-weight: 600;
    font-size: 14px;
    color: ${(props) => props.theme.customColors.lightBlue};
  }
  .ant-menu-item-group-list {
    > div {
      margin: 0;
    }
  }
  .ant-menu-rtl.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
    display: none;
  }
`;
