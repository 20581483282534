import { DownOutlined, EyeOutlined, PlusOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Col,
  Dropdown,
  Input,
  Menu,
  Modal,
  Popconfirm,
  Popover,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import gql from "graphql-tag";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import copy from "../../../assets/imgs/copy.svg";
import editImg from "../../../assets/imgs/editImg.svg";
import filterIcon from "../../../assets/imgs/filter.svg";
import noProduct from "../../../assets/imgs/no-product.svg";
import AddImage from "../../../assets/imgs/plus-square.svg";
import productSetting from "../../../assets/imgs/productsetting.svg";
import PublishImage from "../../../assets/imgs/publish.svg";
import trash from "../../../assets/imgs/trash-2.svg";
import unPublishImage from "../../../assets/imgs/unpublish.svg";
import RemoveImage from "../../../assets/imgs/x-square.svg";
import CustomLoading from "../../../components/common/customLoading";
import CustomSearch from "../../../components/common/customSearch";
import EmptyProductPage from "../../../components/common/emptyProductPage";
import MyImage from "../../../components/common/image";
import FilterDrawer from "../../../components/filter/filterDrawer";
import { Permissions } from "../../../constants/permissions";
import { SEARCH_FILTERS } from "../../../enums/searchFilters";
import { GenerateHandleParams } from "../../../lib/generateHandleParams";
import { GetNewPageData } from "../../../lib/GetNewPageData";
import { isAllowToDoAction } from "../../../utlis/isAllowToDoAction";
import { CURRENT_USER } from "../../../utlis/Me";
import { AddTags } from "../../orders/tag";
import { BULK_TAGS_ACTION } from "../../users/index";
import { PreviewButton } from "./addEdit/PreviewButton";
import ImportProducts, { PRODUCTS_CSV } from "./components/importProducts";
import useProductFilter from "./hooks/useProductFilter";

const { Search } = Input;

export const DELETE_PRODUCT = gql`
  mutation DELETE_PRODUCT($where: ProductWhereUniqueInput!) {
    deleteOneProduct(where: $where) {
      id
    }
  }
`;

export const DELETE_MANY_PRODUCT = gql`
  mutation DELETE_MANY_PRODUCT($ids: [Int!]!) {
    deleteManyProduct(ids: $ids)
  }
`;

// export const UPDATE_PRODUCT_PUBLISHED = gql`
//   mutation UPDATE_PRODUCT_PUBLISHED($updateProductId: Int!, $updateProductPublished: Boolean!) {
//     updateProduct(id: $updateProductId, published: $updateProductPublished) {
//       id
//       published
//     }
//   }
// `;
export const DUPLICATE_PRODUCT = gql`
  mutation DUPLICATE_PRODUCT($duplicateProductId: Int!) {
    duplicateProduct(id: $duplicateProductId) {
      id
    }
  }
`;

const PRODUCTS = gql`
  query PRODUCTS($skip: Int, $filter: ProductWhereInput) {
    products(skip: $skip, take: 10, orderBy: { id: desc }, where: $filter) {
      count
      nodes {
        # SKU
        published
        slug
        digitalLink
        # quantityInLocations {
        #   id
        #   quantity
        # }
        id
        title {
          id
          auto
          # ar
          # en
        }
        # description {
        #   id
        #   ar
        #   en
        # }
        # ratingCount
        # ratingsAvg
        # realPrice
        price
        # facebookPostId
        # comparingPrice
        # hasVariants
        quantityInStock
        holdedQuantity
        trackQuantity
        # tags {
        #   id
        #   name
        # }
        categories {
          id
          slug
          name {
            id
            auto
          }
        }
        mainImage {
          id
          # sm
          md
          # original
        }
        # type {
        #   id
        #   name
        # }
        brand {
          id
          name
        }
        # gallery {
        #   id
        #   original
        # }
        variants {
          # SKU
          # quantityInStock
          # trackQuantity
          # quantityInLocations {
          #   id
          #   quantity
          # }
          id
        }
        createdAt

        # options {
        #   attribute {
        #     name
        #     slug
        #   }
        #   values {
        #     name
        #     slug
        #     # value
        #   }
        # }
        # selectedOptions {
        #   attribute {
        #     name
        #     slug
        #   }
        #   value: optionValue {
        #     name
        #     slug
        #     # value
        #   }
        # }

        # parent {
        #   SKU
        # }
      }
    }
  }
`;

export const PBUBLISH_AND_UNPUBLISH = gql`
  mutation PBUBLISH_AND_UNPUBLISH(
    $bulkPublishActionIds: [Int!]!
    $bulkPublishActionType: TagType
    $bulkPublishActionPublished: Boolean
  ) {
    bulkPublishAction(
      ids: $bulkPublishActionIds
      type: $bulkPublishActionType
      published: $bulkPublishActionPublished
    )
  }
`;

const sort = (nodes, ids) => {
  const array = Array(ids.length);

  nodes.map((product) => {
    array[ids.findIndex((id) => id == product.id)] = product;
  });
  return array;
};
// handle the url params

function handleMenuClick(e) {
  console.log("click", e);
}

const removeTags = "remove";
const addTags = "add";
const ProductsTable = ({
  filterPlus = undefined,
  noAddBtn,
  noFilter,
  onVariablesChange,
  onProductsSelectChange,
  setEmptyTable,
}) => {
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [modelState, setModelState] = useState({ open: false, type: "" });
  const [newTags, setNewTags] = useState([]);
  const onClose = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    // setIsRemoveTagModalVisible(false);
    // setIsAddTagModalVisible(false);
    setModelState({ open: false, type: "" });
  };

  const onSelectChange = (selectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const [drawerFilter, setDrawerFilter] = useState([]);
  const [filterLoaded, setFilterLoaded] = useState(false);
  useEffect(() => {
    onVariablesChange && onVariablesChange(variables);
  }, [drawerFilter]);

  useEffect(() => {
    onProductsSelectChange && onProductsSelectChange(selectedRowKeys);
  }, [selectedRowKeys]);
  const { t } = useTranslation();

  const [deleteProducts] = useMutation(DELETE_MANY_PRODUCT, {
    onCompleted() {
      setSelectedRowKeys([]);
    },
  });
  const { data: meData, loading: loadingMe } = useQuery(CURRENT_USER, {
    fetchPolicy: "cache-first",
  });
  const domain = meData.me.currentShop?.domain;
  const isAllowToRemove = isAllowToDoAction(meData.me, Permissions.removeProduct);

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item
        style={{ lineHeight: "36px" }}
        key="1"
        onClick={() =>
          publishAndUnpublish({
            variables: {
              bulkPublishActionIds: selectedRowKeys,
              bulkPublishActionType: "PRODUCT",
              bulkPublishActionPublished: true,
            },
            refetchQueries: [{ query: PRODUCTS, variables: variables }],
          })
        }>
        <img src={PublishImage} /> &nbsp;&nbsp; {t("posts.publish")}
      </Menu.Item>
      <Menu.Item
        style={{ lineHeight: "36px" }}
        key="2"
        onClick={() =>
          publishAndUnpublish({
            variables: {
              bulkPublishActionIds: selectedRowKeys,
              bulkPublishActionType: "PRODUCT",
              bulkPublishActionPublished: false,
            },
            refetchQueries: [{ query: PRODUCTS, variables: variables }],
          })
        }>
        <img src={unPublishImage} /> &nbsp;&nbsp; {t("posts.unpublish")}
      </Menu.Item>
      <Menu.Item
        style={{ lineHeight: "36px" }}
        key="3"
        onClick={() =>
          //  setIsAddTagModalVisible(true)

          setModelState({ open: true, type: addTags })
        }>
        <img src={AddImage} /> &nbsp;&nbsp; {t("order.addTag")}
      </Menu.Item>
      <Menu.Item
        style={{ lineHeight: "36px" }}
        key="4"
        onClick={() =>
          //  setIsRemoveTagModalVisible(true)

          setModelState({ open: true, type: removeTags })
        }>
        <img src={RemoveImage} /> &nbsp;&nbsp; {t("order.removeTag")}
      </Menu.Item>
      <Menu.Item style={{ lineHeight: "36px" }} key="3">
        <Popconfirm
          title={t("analytics.deleteProduct")}
          disabled={!isAllowToRemove}
          onConfirm={() =>
            deleteProducts({
              variables: { ids: selectedRowKeys },
              refetchQueries: [{ query: PRODUCTS, variables }],
            })
          }
          okText={t("analytics.yes")}
          cancelText={t("analytics.no")}>
          <img src={RemoveImage} /> &nbsp;&nbsp; {t("order.remove")}
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  console.log("🚀 ~ file: index.js ~ line 89 ~ ProductsTable ~ filterPlus", filterPlus);
  const {
    location: { pathname, search: searchQ },
    push,
  } = useHistory();
  const query = qs.parse(searchQ, {
    ignoreQueryPrefix: true,
  });
  const [search, setSearch] = useState(query.search || null);
  const [searchVal, setSearchVal] = useState(query.search || "");
  const [firstLoadFinished, setFirstLoadFinished] = useState(false);

  const onSearch = (value) => {
    setSearch(value);
    handleParams({ search: value || null, page: 1 });
  };
  const { page } = query;

  const [pagination, setPagination] = useState({
    current: parseInt(page) || 1,
  });
  const handleParams = GenerateHandleParams(push, pathname, query);
  if (filterPlus?.id?.in)
    filterPlus.id.in = filterPlus.id.in.slice(
      (pagination.current - 1) * 10 || 0,
      (pagination.current - 1) * 10 + 10,
    );
  console.log("🚀 ~ file: index.js ~ line 358ssss ~ filterPlus", filterPlus);

  const [duplicateProduct] = useMutation(DUPLICATE_PRODUCT, {
    onCompleted({ duplicateProduct }) {
      history.push(`/product/${duplicateProduct.id}`);
    },
  });

  // const [updateProductPublished] = useMutation(UPDATE_PRODUCT_PUBLISHED, {
  //   update(cache, { data: { updateProduct } }) {
  //     const { products } = cache.readQuery({
  //       query: PRODUCTS,
  //       variables: variables,
  //     });
  //     cache.writeQuery({
  //       query: PRODUCTS,
  //       variables: variables,

  //       data: {
  //         products: {
  //           count: products.count,
  //           nodes: products.nodes.map((product) => {
  //             let newProduct = { ...product };
  //             if (newProduct.id === updateProduct.id) return { ...newProduct, ...updateProduct };
  //             return newProduct;
  //           }),
  //         },
  //       },
  //     });
  //   },
  // });

  const [publishAndUnpublish] = useMutation(PBUBLISH_AND_UNPUBLISH, {});

  const {
    // pagination,
    filterValues,
    columns,
    query: filterQuery,
    ResetAllFilters,
    // handleTableChange,
    // variables,
    // setPagination,
  } = useProductFilter(pagination, setPagination, publishAndUnpublish);
  const orArr = [];
  const searchId = Number(search);
  if (Number.isSafeInteger(searchId) && searchId < 999999999 && searchId)
    orArr.push({ id: { equals: searchId } });
  if (search?.length)
    orArr.push({
      title: {
        OR: [
          { ar: { contains: search, mode: "insensitive" } },
          { en: { contains: search, mode: "insensitive" } },
        ],
      },
    });

  const variables =
    // !filterPlus ?
    {
      skip: (pagination.current - 1) * 10,
      filter: {
        ...filterValues,
        ...filterPlus,
        AND: [{ OR: orArr }]
          .concat(drawerFilter)
          .concat([{ productType: !filterPlus?.id ? { not: { equals: "VARIANT" } } : undefined }]),
      },
    };
  // : {
  //     skip: (pagination.current - 1) * 10,
  //     filter: {
  //       ...filterValues,
  //       ...filterPlus,
  //       productType: !filterPlus?.id ? { not: { equals: "VARIANT" } } : undefined,
  //     },
  //   };

  // const variables = {
  //   skip: (pagination.current - 1) * 10,
  //   filter: {
  //     ...filterPlus,
  //     productType: !filterPlus?.id ? { not: { equals: "VARIANT" } } : undefined,
  //     ...filterValues,
  //   },
  // };

  // };

  const [removeProduct] = useMutation(DELETE_PRODUCT, {
    onCompleted: () => {
      GetNewPageData(pagination, setPagination);
    },
    update(cache, { data: { deleteOneProduct } }) {
      const { products } = cache.readQuery({
        query: PRODUCTS,
        variables: variables,
      });
      cache.writeQuery({
        query: PRODUCTS,
        variables: variables,

        data: {
          products: {
            count: products.count - 1,
            nodes: products.nodes.filter(({ id }) => deleteOneProduct.id !== id),
          },
        },
      });
    },
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination({
      ...pagination,
      current: pagination.current || 1,
    });
    push({
      pathname,
      search: qs.stringify(
        { ...filterQuery, page: pagination.current || 1 },
        { arrayFormat: "comma", encode: false },
      ),
    });
    // push({
    //   pathname: pathname,
    //   query: { ...urlQ, ...r.query, page: pagination.current },
    // });
  };

  const { data, loading } = useQuery(PRODUCTS, {
    variables: variables,
    fetchPolicy: "cache-and-network",
    skip: !filterLoaded,
    onCompleted() {
      !firstLoadFinished && setFirstLoadFinished(true);
      setSelectedRowKeys([]);
    },
  });
  console.log("🚀 ~ file: index.js ~ line 161 ~ ProductsTable ~ data", data);

  const history = useHistory();

  const dropDown = ({ id, slug }) => {
    console.log("🚀 ~ file: index.js ~ line 270 ~ dropDown ~ id", id);
    return (
      <Space direction="vertical">
        <Space style={{ width: "100%", color: "#163663" }} align="baseline">
          {/* <PreviewButton url={`/product/${id}`} /> */}

          <a href={`https://${domain}${`/product/${slug}`}`} target="_blank" rel="noreferrer">
            <EyeOutlined />
            <Button type="text">{t("products.preview")}</Button>
          </a>
        </Space>
        <Space
          align="baseline"
          style={{ width: "100%" }}
          onClick={() => history.push(`/product/${id}`)}>
          <MyImage src={editImg} width={13} style={{ margin: "-2px 0" }} />
          <Button type="text">{t("products.edit")}</Button>
        </Space>
        <Space
          style={{ width: "100%" }}
          align="baseline"
          onClick={() =>
            duplicateProduct({
              variables: { duplicateProductId: id },
            })
          }>
          <MyImage src={copy} width={13} style={{ margin: "-2px 0" }} />
          <Button type="text">{t("products.copy")}</Button>
        </Space>
      </Space>
    );
  };

  // const {
  //   data: { me },
  // } = useQuery(CURRENT_USER, {
  //   fetchPolicy: "cache-first",
  // });
  useEffect(() => {
    // if (!loading && data)
    setPagination({
      ...pagination,
      total: data?.products?.count,
    });
  }, [data]);
  useEffect(() => {
    if (query?.page) setPagination((old) => ({ ...old, current: parseInt(query.page) }));
  }, [query?.page]);

  const [updateProductsTags, { loading: updateTagsLoading }] = useMutation(BULK_TAGS_ACTION, {
    onCompleted() {
      setModelState({ open: false, type: "" });
    },
    update(cache, { data: { bulkTagsAction } }) {
      const { products } = cache.readQuery({
        query: PRODUCTS,
        variables: variables,
      });
      // cache.writeQuery({
      //   query: USERS,
      //   variables: variables,
      //   data: {
      //     users: {
      //       count: users.count,
      //       nodes: users.nodes.map((user) => {
      //         const { id } = user;
      //         if (!selectedRowKeys.includes(id)) return user;
      //         const newOrder = { ...order };
      //         newOrder.tags =
      //           modelState.type === "add"
      //             ? [...new Set([...newOrder.tags, ...newTags])]
      //             : newOrder.tags.filter((tag) => !newTags.includes(tag));
      //         return newOrder;
      //       }),
      //     },
      //   },
      // });
    },
  });

  const onFinishTags = (tags) => {
    console.log("🚀 ~ file: index.js ~ line 336 ~ onFinishTags ~ tags", tags);
    if (!tags || !modelState.type) return;

    updateProductsTags({
      variables: {
        bulkTagsActionIds: selectedRowKeys,
        bulkTagsActionAction: modelState.type,
        bulkTagsActionTags: tags,
        bulkTagsActionType: "PRODUCT",
      },
    });
  };

  const deleteColumn = {
    align: "center",

    render: (record) => (
      <Space style={{}}>
        {/* <FaceBookShareBtn
                  id={record.id}
                  variables={variables}
                  facebookPostId={record.facebookPostId}
                /> */}
        {/* <ShareButtons record={record} variables={variables} /> */}

        <Popconfirm
          title={t("analytics.deleteProduct")}
          disabled={!isAllowToRemove}
          onConfirm={() =>
            removeProduct({
              variables: { where: { id: record.id } },
            })
          }
          okText={t("analytics.yes")}
          cancelText={t("analytics.no")}>
          <MyImage src={trash} width={16} style={{ cursor: "pointer" }} />
          {/*  <DeleteOutlined
            style={{ color: isAllowToRemove ? "red" : "whitesmoke" }}
            disabled={!isAllowToRemove}
          /> */}
        </Popconfirm>

        <Popover
          overlayStyle={{ width: 138 }}
          placement="bottomLeft"
          content={dropDown(record)}
          trigger={["click"]}>
          <MyImage src={productSetting} width={16} style={{ cursor: "pointer" }} />
        </Popover>
      </Space>
    ),
  };

  const [loadAllRequests, { data: loadData, loading: loadLoading }] = useMutation(PRODUCTS_CSV, {
    // variables: variables,
  });

  console.log("path", window.location.pathname);
  // if (loading && !firstLoadFinished) return <Loading />;
  if (data?.products?.count <= 0 && !orArr.concat(drawerFilter).length) {
    if (window.location.pathname === "/analytics") {
      // setEmptyTable(false);
      return null;
    } else {
      return (
        <EmptyProductPage
          buttonText={t("products.addProduct")}
          buttonLink="/products/new"
          description={t("products.noProduct")}
          importText={t("products.importProduct")}
          image={noProduct}
        />
      );
    }
  }

  return (
    <Container>
      {/* {(loading || !filterLoaded) && !firstLoadFinished && <CustomLoading />} */}
      {!noFilter && (
        <FilterDrawer
          searchFilters={[
            SEARCH_FILTERS.BRANDS,
            SEARCH_FILTERS.CATEGORIES,
            SEARCH_FILTERS.DATE_RANGE,
            SEARCH_FILTERS.PRICING,
            SEARCH_FILTERS.INVENTORIES_STOCK,
            SEARCH_FILTERS.TAGS,
          ]}
          onClose={onClose}
          visible={visible}
          setVisible={setVisible}
          drawerFilter={drawerFilter}
          pagination={pagination}
          setDrawerFilter={setDrawerFilter}
          handleParams={handleParams}
          setFilterLoaded={setFilterLoaded}
        />
      )}

      {!noAddBtn && (
        <>
          <Row justify="space-between" style={{ marginBottom: 20 }}>
            <Col>
              <Typography.Title level={2}>{t("products.products")}</Typography.Title>
            </Col>
            <Col>
              <Row>
                <Col style={{ margin: "10px 0" }}>
                  <ImportProducts
                    selectedProducts={selectedRowKeys}
                    variables={variables}
                    productsCount={data?.products?.count || 0}
                    products={data?.products?.nodes}
                  />
                </Col>
                {/* <ExportProducts /> */}
                <Col style={{ margin: "10px 0" }}>
                  <Button
                    onClick={() => push("/products/new")}
                    type="primary"
                    icon={<PlusOutlined />}>
                    {t("analytics.addProduct")}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
      {!noFilter && (
        <Col span={24}>
          {/* <Row justify="space-between"> */}
          {/* <Col span={18}> */}
          <CustomSearch
            placeholder={t("products.search")}
            onSearch={onSearch}
            value={searchVal}
            allowClear
            onChange={(val) => {
              setSearchVal(val.target.value);
            }}
            style={{ marginBottom: "20px", maxWidth: "300px" }}
          />
          {/* </Col>
            <Col span={6}> */}
          <Button
            onClick={() => {
              setVisible(!visible);
            }}
            // type="primary"
            type="default"
            icon={<MyImage src={filterIcon} width={12} />}
            // icon={<PlusCircleOutlined />}
            style={{
              marginRight: "10px",
              marginLeft: "10px",
            }}>
            {/* {t("order.filter")} */}
            {t("sellChannel.filter")}
            {/* <PlusOutlined style={{ fontSize: 14 }} /> */}
          </Button>
          {/* </Col> */}
          {/* </Row> */}
        </Col>
      )}
      {/* {Object.keys(filterQuery).length > 1 && filterQuery.constructor === Object
        ? ResetAllFilters
        : null} */}
      <Row justify="end" style={{ marginBottom: "30px" }}>
        <Col>
          {selectedRowKeys.length > 0 && (
            <Row justify="end">
              <Col>
                <div style={{ lineHeight: "36px", color: "#8e8e8e", margin: "0 10px" }}>
                  {t("order.selectedNumber")} {selectedRowKeys.length > 0 && selectedRowKeys.length}
                </div>
              </Col>
              <Col>
                <Dropdown trigger={["click"]} placement="bottomCenter" overlay={menu}>
                  <Button type="text" style={{ color: "#4a9aca" }}>
                    {t("order.process")} <DownOutlined />
                  </Button>
                </Dropdown>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <Table
        scroll={{ x: 1000 }}
        loading={loading}
        rowSelection={rowSelection}
        bordered
        rowKey={(record) => record.id}
        columns={[
          ...columns(),
          // {
          //   align: "center",
          //   render: (record) => (
          //     <Link to={`/product/${record.id}`}>
          //       <EditOutlined />
          //     </Link>
          //   ),
          // },
          deleteColumn,
        ]}
        size="small"
        dataSource={
          filterPlus?.id?.in && data?.products.nodes.length
            ? sort(data?.products.nodes, filterPlus.id.in)
            : data?.products.nodes
        }
        onChange={handleTableChange}
        pagination={{
          ...pagination,
          showSizeChanger: false,
          position: ["bottomCenter"],
          hideOnSinglePage: true,
        }}
        // position="center"
      />
      <Modal
        title={t(modelState.type === addTags ? "order.addTag" : "order.removeTag")}
        visible={modelState.open}
        // onOk={onFinishTags}
        onCancel={handleCancel}
        footer={false}>
        <AddTags
          onFinish={onFinishTags}
          setNewTags={setNewTags}
          type="PRODUCT"
          loading={updateTagsLoading}
        />
        {/* <AddTags onFinish={onFinishTags} setNewTags={setNewTags} /> */}
      </Modal>
    </Container>
  );
};

const Container = styled.div`
  .ant-table-thead .ant-table-cell {
    background: ${(props) => props.theme.backgroundColors.gray4};
    height: 48px;
    font-weight: 600;
    font-size: 14px;
    color: ${(props) => props.theme.colors.black3};
  }
  .ant-input-search > .ant-input-group > .ant-input-affix-wrapper:hover,
  .ant-input-search > .ant-input-group > .ant-input-affix-wrapper-focused,
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused,
  .ant-input-search .ant-input:hover,
  .ant-input-search .ant-input:focus {
    border-color: #d9d9d9;
    box-shadow: unset;
  }

  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: none;
  }

  .ant-table-selection-column {
    text-align: center !important;
  }
`;

export default ProductsTable;

// return data?.products?.nodes.map((pId) => (
//   pId.id.length > 0 ? (
//     <NoProducts />
//   ) : (
//     <Container>
//       {!noAddBtn && (
//         <>
//           <Row justify="space-between" style={{ marginBottom: 20 }}>
//             <Col>
//               <Typography.Title level={2}>{t("products.products")}</Typography.Title>
//             </Col>
//             <Col>
//               <Space align="baseline" size={18}>
//                 <ImportProducts variables={variables} />
//                 <ExportProducts />
//                 <Button
//                   onClick={() => push("/products/new")}
//                   type="primary"
//                   icon={<PlusOutlined />}>
//                   {t("analytics.addProduct")}
//                 </Button>
//               </Space>
//             </Col>
//           </Row>
//         </>
//       )}

//       {Object.keys(query).length > 1 && query.constructor === Object ? ResetAllFilters : null}
//       <Table
//         scroll={{ x: 1000 }}
//         loading={loading}
//         bordered={false}
//         rowKey={(record) => record.id}
//         columns={[
//           ...columns(),
//           // {
//           //   align: "center",
//           //   render: (record) => (
//           //     <Link to={`/product/${record.id}`}>
//           //       <EditOutlined />
//           //     </Link>
//           //   ),
//           // },
//           deleteColumn,
//         ]}
//         size="small"
//         dataSource={
//           noAddBtn && data?.products.nodes
//             ? sort(data?.products.nodes, filterPlus.id.in)
//             : data?.products.nodes
//         }
//         onChange={handleTableChange}
//         pagination={{
//           ...pagination,
//           showSizeChanger: false,
//           position: ["bottomCenter"],
//           hideOnSinglePage: true,
//         }}
//         // position="center"
//       />
//     </Container>
//   )
// ))
