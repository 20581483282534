import { Statues } from "../pages/orders/hooks/useOrderFilter";

export enum ExecutionStatues {
  partiallyFulfilled = "PARTIALLY_FULFILLED",
  unFulfilled = "UN_FULFILLED",
  fulfilled = "FULFILLED",
}
export function getOrderExecutionStatuses(t) {
  return [
    { value: ExecutionStatues.unFulfilled, text: t("order.unFulfilled") },
    { value: ExecutionStatues.partiallyFulfilled, text: t("order.partiallyFulfilled") },
    { value: ExecutionStatues.fulfilled, text: t("order.fulfilled") },
  ];
}
