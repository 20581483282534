import { gql } from "@apollo/client";

export const S_NEW_MESSAGE = gql`
  subscription SUBSCRIPTION_NEW_MESSAGE {
    newMessageInRooms {
      room {
        id
        slug
        roomName
        notSeenNum
        # lastMessage {
        #   id
        #   message
        #   createdAt
        #   user {
        #     name
        #   }
        # }
      }

      id
      message
      createdAt
      user {
        id
        name
      }
    }
  }
`;
