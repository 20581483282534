import React, { useState, useMemo, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Upload,
  Button,
  InputNumber,
  Divider,
  Row,
  Col,
  Checkbox,
  Switch,
  TreeSelect,
  Spin,
  notification,
  Space,
  Breadcrumb,
} from "antd";
import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { InboxOutlined, PlusOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { FormCard } from "./style";
import JoditEditor from "../../../../components/common/joditEditor";

import { CATEGORIES, initialCategoryVariables } from "../table";
import makeSlug from "slug-arabic"; // by B7r :)
import { getBase64, uploadButton } from "../../../theme/slides/addEdit";
import { useHistory, useParams, Link } from "react-router-dom";
import { CATEGORIES_TREE } from "../../products/addEdit/cards/organization";
import { uploadImage } from "../../../../utlis/upload";
import { validateMessages } from "../../products/addEdit/add";
import CategoriesSelect from "../../../../components/common/selectors/categorySelector";
import { useTranslation } from "react-i18next";
import SuccessNotification from "../../../../components/common/successNotification";
import errorNotification from "../../../../components/common/errorNotification";
import CommonError from "../../../error";
import { AppLanguageSwitch } from "../../../../components/common/websiteLanguage";
import { SETTINGS } from "../../../settings/generals/card/defaultLanguage";
import Loading from "../../../../components/common/Loading";
import styled from "styled-components";
import { LangSpin } from "../../products/addEdit/cards/addCategoryModal";
import { CURRENT_USER } from "../../../../utlis/Me";
import EditThemeProduct from "../../products/addEdit/cards/editThemeProduct";
import SeoCard from "../../../../components/common/seoCard";
import AddEditStickyContainer from "../../../../components/layout/header/addEditSticky";

export const ADD_CATEGORY = gql`
  mutation ADD_CATEGORY(
    $name: MultiLangText!
    $parent: CategoryWhereUniqueInput
    $slug: String!
    $image: MultiLangImage
    $description: MultiLangText
    $type: CategoryType
    $seo: SeoItemArg
  ) {
    addCategory(
      name: $name
      slug: $slug
      parent: $parent
      image: $image
      description: $description
      type: $type
      seo: $seo
    ) {
      id
    }
  }
`;
const EDIT_CATEGORY = gql`
  mutation EDIT_CATEGORY(
    $name: MultiLangText
    $parent: CategoryWhereUniqueInput
    $slug: String
    $image: MultiLangImage
    $description: MultiLangText
    $id: Int!
    $seo: SeoItemArg
  ) {
    updateCategory(
      name: $name
      slug: $slug
      parent: $parent
      image: $image
      description: $description
      seo: $seo
      id: $id
    ) {
      id
    }
  }
`;
export const SINGLE_CATEGORY = gql`
  query SINGLE_CATEGORY($id: Int!) {
    category(id: $id) {
      id
      seo {
        id
        title {
          id
          ar
          en
        }
        slug {
          id
          ar
          en
        }
        body {
          id
          ar
          en
        }
      }
      name {
        id
        en
        ar
      }
      slug
      theme {
        id
      }

      description {
        id
        en
        ar
      }
      parent {
        id
        name {
          id
          auto
        }
      }
      image {
        id
        en {
          id
          md
        }
        ar {
          id
          md
        }
      }
    }
  }
`;

export const normFile = (e) => {
  console.log("Upload event:", e);
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};
/**
 * renderComponent
 * @component
 * @name addCompanyModal
 * @memberof PRODUCTS
 * @summary admin can add company
 * @return {React} React component view
 */
const AddCategory = ({ type = "PRODUCT" }) => {
  const [addCategory, categoryProps] = useMutation(ADD_CATEGORY);
  const [editCategory, editCategoryProps] = useMutation(EDIT_CATEGORY);
  const { t, i18n } = useTranslation();

  const [slug, setSlug] = useState("");
  const [isEn, setIsEn] = useState();
  // const [catFilter, setCatFilter] = useState("");
  const [enImageUrl, setEnIamgeUrl] = useState(null);
  const [arImageUrl, setArIamgeUrl] = useState(null);
  const [images, setImages] = useState(null);
  const [loading, setLoading] = useState(false);
  const [seoTitle, setSeoTitle] = useState({});

  const {
    data: { me },
  } = useQuery(CURRENT_USER, {
    fetchPolicy: "cache-first",
  });
  // const { data, loading, error } = useQuery(STATES);

  // const [variants, setVariants] = useState([]);

  const [description, setDescription] = useState("");
  console.log("🚀 ~ file: index.js ~ line 153 ~ AddCategory ~ description", description);
  const [descriptionAr, setDescriptionAr] = useState("");
  let { id } = useParams();
  const maxId = 2147483647;
  if ((id > maxId || isNaN(id)) && id !== undefined) id = maxId;

  console.log("AddCategory -> id", id);
  const { data, ...singleProduct } = useQuery(SINGLE_CATEGORY, {
    variables: {
      id: Number.isInteger(parseInt(id)) ? parseInt(id) : 2147483647,
    },
    skip: !id,
  });

  // const { data: defaultLanguage, loading: defaultLanguageLoading } = useQuery(SETTINGS);

  const [form] = Form.useForm();
  const route = useHistory();
  const [firstLangLoad, setFirstLangLoad] = useState(false);
  const handleImage = async ({ fileList }, lang) => {
    const last = fileList.length - 1;
    if (!fileList[0].url && !fileList[last].preview) {
      fileList[last].preview = await getBase64(fileList[last].originFileObj);
    }
    lang === "ar"
      ? setArIamgeUrl(fileList[last].url || fileList[last].preview)
      : setEnIamgeUrl(fileList[last].url || fileList[last].preview);
  };

  /**
   * @description admin can add new company from this modal
   */
  const onFinish = async (variables) => {
    const { parent } = variables;

    setLoading(true);

    const arImage = images?.ar?.file ? await uploadImage(images?.ar?.file) : null;
    const enImage = images?.en?.file ? await uploadImage(images?.en?.file) : null;
    // if (!enImage && !arImage && !editableRecord) {
    //   setLoading(false);

    //   return message.error("must add image");
    // }
    try {
      if (id) {
        const vars = {
          ...variables,
          id: parseInt(id),
          name: form.getFieldValue("name"),
          // slug: form.getFieldValue('slug'),
          description: {
            en: description,
            ar: descriptionAr,
          },
          parent: parent?.value ? { id: parent.value } : parent && { id: parent },
          image: (arImage?.data || enImage?.data) && {
            ar: arImage?.data,
            en: enImage?.data,
          },
        };
        await editCategory({
          variables: vars,
          refetchQueries: [
            { query: CATEGORIES, variables: initialCategoryVariables },
            { query: SINGLE_CATEGORY, variables: { id: parseInt(id) } },
          ],
        });

        SuccessNotification({
          // defaultTitle: t("products.successDefaultMessage"),
          title: t("products.successEditCategoryMessage"),
          type: "success",
        });
      } else {
        const vars = {
          ...variables,
          name: form.getFieldValue("name"),
          // slug: form.getFieldValue('slug'),
          type,
          parent: parent && { id: parent },
          description: {
            en: description,
            ar: descriptionAr,
          },
          image: {
            ar: arImage?.data || enImage?.data,
            en: enImage?.data || arImage?.data,
          },
        };
        console.log("🚀 ~ file: index.js ~ line 220 ~ onFinish ~ vars", vars);
        const category = await addCategory({
          variables: vars,
          refetchQueries: [{ query: CATEGORIES, variables: initialCategoryVariables }],
        });

        SuccessNotification({
          // defaultTitle: t("products.successDefaultMessage"),
          title: t("products.successCategoryMessage"),
          type: "success",
        });

        route.push(
          `/${type == "PRODUCT" ? "category" : "postsCategory"}/${category.data.addCategory.id}`,
        );
      }
    } catch (error) {
      // errorNotification({
      //   defaultTitle: t("products.errorDefaultMessage"),
      //   title: t("products.errorProductMessage"),
      //   // type: "error"
      // });

      console.log("AddProduct -> error", error);
    }
    setLoading(false);
  };

  const MemoizedEmailEditor = useMemo(
    () => <JoditEditor style={{ height: 500 }} content={description} update={setDescription} />,
    [description],
  );
  const MemoizedEmailEditorAr = useMemo(
    () => <JoditEditor style={{ height: 500 }} content={descriptionAr} update={setDescriptionAr} />,
    [descriptionAr],
  );

  const getSlug = () => {
    form.setFieldsValue({
      slug: makeSlug(slug),
    });
  };

  const validateMessages = {
    required: t("customers.required"),
  };

  useEffect(() => {
    if (data?.category) {
      const { name, image, slug, parent, description } = data.category;
      // const arSlugWithoutIdArray = slug.ar?.split('-') || []
      setSeoTitle(name);

      // arSlugWithoutIdArray.pop()
      // const enSlugWithoutIdArray = slug.en?.split('-') || []

      // enSlugWithoutIdArray.pop()

      form.setFieldsValue({
        name: { ar: name.ar, en: name.en },
        // slug: {
        //   ar: arSlugWithoutIdArray.join('-'),
        //   en: enSlugWithoutIdArray.join('-'),
        // },
        slug,
        parent: parent?.id && {
          label: parent?.name?.auto,
          value: parent?.id,
        },
      });
      setDescription(description?.en);
      setDescriptionAr(description?.ar);

      setSlug(slug);
      setArIamgeUrl(image?.ar?.md);
      setEnIamgeUrl(image?.en?.md);
    }
  }, [data]);

  if (singleProduct.loading)
    return (
      <Spin
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    );

  console.log("🚀 ~ file: index.js ~ line 324 ~ AddCategory ~ id", id);
  if (!data?.category && id) {
    return <CommonError />;
  }
  const { theme, seo } = data?.category || {};
  return (
    <Form
      validateMessages={validateMessages}
      name="addProduct"
      form={form}
      className="login-form"
      initialValues={{
        available: 0,
        seo: {
          title: { ar: seo?.title?.ar, en: seo?.title?.en },
          slug: { ar: seo?.slug?.ar, en: seo?.slug?.en },
          body: { ar: seo?.body?.ar, en: seo?.body?.en },
        },
      }}
      onFinish={onFinish}
      labelCol={{ span: 24 }}
      labelAlign="left"
      onValuesChange={(val) => {
        console.log("AddProduct -> val", val);
        val.title && setSeoTitle({ ...seoTitle, ...val.title });
        val.name?.ar && setSlug(val.name.ar);
        val.name?.en && setSlug(val.name.en);

        val.slug && setSlug(val.slug);
      }}>
      <AddEditStickyContainer
        children={
          <Row justify="space-between">
            <Col>
              <h2 style={{ marginLeft: "20px", fontWeight: "bold" }}>
                {id ? t("products.edit") : t("products.add")} &nbsp;
                {t("products.Category")}
              </h2>
              <Breadcrumb separator=">">
                <Breadcrumb.Item>
                  <Link to="/">الرئيسية</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={type === "POST" ? "/postsCategories" : "/categories"}>الفئات</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  {id ? t("products.edit") : t("products.add")} &nbsp;
                  {t("products.Category")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col style={{ textAlign: "right" }}>
              <div>
                <Button
                  type="primary"
                  icon={!id && <PlusOutlined />}
                  htmlType="submit"
                  loading={categoryProps.loading || loading || editCategoryProps.loading}
                  disabled={categoryProps.loading || loading || editCategoryProps.loading}>
                  {id ? t("settings.save") : t("products.add")}
                </Button>
              </div>

              {/* <Switch
            style={{ marginTop: "20px" }}
            checkedChildren="AR"
            unCheckedChildren="EN"
            onChange={() => setIsEn(!isEn)}
          /> */}
            </Col>
          </Row>
        }
      />
      <Row style={{ marginTop: 50 }} gutter={16}>
        <Col span={24} md={16}>
          <AppLanguageContainer>
            <AppLanguageSwitch
              isEn={isEn}
              setIsEn={setIsEn}
              firstLangLoad={firstLangLoad}
              setFirstLangLoad={setFirstLangLoad}
            />
            {!firstLangLoad ? (
              <LangSpin />
            ) : (
              <FormCard>
                {isEn ? (
                  <>
                    <Form.Item
                      label={t("products.productName")}
                      name={["name", "en"]}
                      rules={[{ required: true }]}>
                      <Input
                        placeholder="e.g. Summer collection, Under $100, Staff picks"
                        onBlur={getSlug}
                      />
                    </Form.Item>
                  </>
                ) : (
                  <>
                    <Form.Item label="اسم الفئة" name={["name", "ar"]} rules={[{ required: true }]}>
                      <Input placeholder="تي شيرت " onBlur={getSlug} />
                    </Form.Item>
                  </>
                )}

                <Form.Item label={t("pages.slug")} name={"slug"} rules={[{ required: true }]}>
                  <Input placeholder={t("products.uniqueName")} onBlur={getSlug} />
                </Form.Item>
                {isEn ? (
                  <>
                    <Form.Item label={t("pages.desc")}>{MemoizedEmailEditor}</Form.Item>
                  </>
                ) : (
                  <>
                    <Form.Item label="الوصف">{MemoizedEmailEditorAr}</Form.Item>
                  </>
                )}
              </FormCard>
            )}
          </AppLanguageContainer>
          <SeoCard
            form={form}
            type="category"
            defaultSeoValue={{
              title: seoTitle,
              body: { en: description, ar: descriptionAr },
              slug: { en: seo?.slug?.en, ar: seo?.slug?.ar },
            }}
            isEdit={Boolean(seo?.id)}
            seoItemId={seo?.id}
          />
        </Col>
        <Col span={24} md={8}>
          {id && (
            <EditThemeProduct pageId={data?.category?.slug} pageType={"CATEGORY"} theme={theme} />
          )}
          <FormCard style={{ margin: "20px 0" }}>
            <Form.Item label={t("products.parent")} name="parent">
              <CategoriesSelect type={type} filterId={id} />
            </Form.Item>
            <Divider />
          </FormCard>
          <FormCard>
            <h3>{t("products.collectionImage")}</h3>
            {isEn ? (
              <>
                <Form.Item
                  name={["image", "en"]}
                  // valuePropName="fileList"
                  // getValueFromEvent={normFile}
                  // label="Image"
                  // rules={[{ required: true }]}
                  noStyle>
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={() => {
                      // handleImage(file, "en");
                      return false;
                    }}
                    onChange={(file) => {
                      setImages({ ...images, en: file });

                      handleImage(file, "en");
                    }}

                    // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  >
                    {enImageUrl ? (
                      <img
                        src={enImageUrl}
                        alt="avatar"
                        style={{ width: "100%", height: "100%" }}
                      />
                    ) : (
                      uploadButton(loading, t)
                    )}
                  </Upload>
                </Form.Item>
              </>
            ) : (
              <>
                <Form.Item
                  name={["image", "ar"]}
                  // valuePropName="fileList"
                  // getValueFromEvent={normFile}
                  noStyle>
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={() => {
                      // handleImage(file, "ar");
                      return false;
                    }}
                    onChange={(file) => {
                      setImages({ ...images, ar: file });

                      handleImage(file, "ar");
                    }}

                    // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  >
                    {arImageUrl ? (
                      <img src={arImageUrl} alt="avatar" style={{ width: "100%" }} />
                    ) : (
                      uploadButton(loading, t)
                    )}
                  </Upload>
                </Form.Item>
              </>
            )}
          </FormCard>
          {/* {id ? (
            <FormCard>
              <Space direction="vertical">
                <h3>{t("products.categoryTheme")}</h3>

                <Button type="primary">
                  {theme?.id ? (
                    <a
                      target="_blank"
                      href={
                        process.env.NODE_ENV === "development"
                          ? `http://localhost:7777/theme/home/${theme?.id}`
                          : `https://${me.currentShop?.domain}/theme/home/${theme?.id}`
                      }>
                      {t("products.edit")}
                    </a>
                  ) : (
                    <a
                      target="_blank"
                      href={
                        process.env.NODE_ENV === "development"
                          ? `http://localhost:7777/theme/home?page=CATEGORY&pageId=${data?.category.slug}`
                          : `https://${me.currentShop?.domain}/theme/home?page=CATEGORY&pageId=${data?.category.slug}`
                      }>
                      {t("products.add")}
                    </a>
                  )}
                </Button>
              </Space>
            </FormCard>
          ) : null} */}
        </Col>
      </Row>
    </Form>
  );
};

const AppLanguageContainer = styled.div`
  position: relative;
`;
export default AddCategory;
