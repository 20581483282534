import { Button, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import MyImage from "../../common/image";
import langImg from "../../../assets/imgs/langImg.svg";
import mdImg from "../../../assets/imgs/lang_md_img.svg";
import { useResponsive } from "../../../lib/useResponsive";

const LanguageChanger = ({ disable }: { disable?: boolean }) => {
  const { t, i18n } = useTranslation();
  const { lg } = useResponsive();

  const changeLanguageHandler = () => {
    if (disable) return;
    i18n.changeLanguage(i18n.language === "ar" ? "en" : "ar");
  };
  return (
    <Container>
      <Button type="text" onClick={changeLanguageHandler}>
        <Space>
          <MyImage src={lg ? langImg : mdImg} width={12} />
          <Typography.Text className="change">{t("home.changeLang")}</Typography.Text>
        </Space>
      </Button>
    </Container>
  );
};

const Container = styled.div`
  .ant-btn {
    padding: 0;
  }
  .change {
    color: ${(props) => (props.theme.isBreakpoints.lg ? "#333" : "#fff")};
  }
`;

export default LanguageChanger;
