import React from "react";
import { Form, Upload, Modal, Popconfirm, Typography, Space } from "antd";
import { InboxOutlined, StarOutlined, DeleteOutlined } from "@ant-design/icons";
import { FormCard } from "../style";
import { useMutation, gql } from "@apollo/client";
import { uploadImage } from "../../../../../utlis/upload";
import { getBase64 } from "../../../../theme/slides/addEdit";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import imagePlace from "../../../../../assets/imgs/imagePlace.svg";
import MyImage, { convirtToVideoIfImg } from "../../../../../components/common/image";

export const ADD_IMAGE_TO_PRODUCT = gql`
  mutation ADD_IMAGE_TO_PRODUCT($id: Int!, $image: ImageInput!) {
    addImageToProduct(id: $id, image: $image) {
      id
      gallery {
        id
        md
      }
    }
  }
`;

const REMOVE_IMAGE_FROM_PRODUCT = gql`
  mutation REMOVE_IMAGE_FROM_PRODUCT($id: Int!) {
    deleteOneImage(where: { id: $id }) {
      id
    }
  }
`;

const Media = ({ images, id, setLoading, form, uploading }) => {
  console.log("Media -> imagesssssssss", images);
  const theme = useTheme();
  console.log("🚀 ~ file: media.js ~ line 34 ~ Media ~ theme", theme);
  const { t } = useTranslation();
  const [previewImage, setPreviewImage] = React.useState("");
  const [previewVisible, setPreviewVisible] = React.useState(false);
  const [previewTitle, setPreviewTitle] = React.useState("");
  const [addImageToProduct, imageProps] = useMutation(ADD_IMAGE_TO_PRODUCT, {
    onCompleted: ({ addImageToProduct }) => {
      console.log("Media -> addImageToProduct", addImageToProduct);
      form.setFieldsValue({
        gallery: addImageToProduct.gallery.map(({ id, md }) => ({
          uid: id,
          url: convirtToVideoIfImg(md),
        })),
      });
    },
  });
  const [removeImage, setRemoveImage] = React.useState(null);

  const [removeImageFromProduct, { loading }] = useMutation(REMOVE_IMAGE_FROM_PRODUCT, {
    onCompleted: ({ deleteOneImage }) => {
      console.log("Media -> deleteOneImage", deleteOneImage);

      form.setFieldsValue({
        gallery: form.getFieldValue("gallery").filter(({ uid }) => uid !== deleteOneImage.id),
      });
    },
  });

  const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
  };

  const onChange = async (file) => {
    console.log("Media -> fiaaaaaaaaaale", file);

    if (id) {
      setLoading(true);
      const uploadedImage = await uploadImage(file);
      console.log("Media -> uploaaaaaaaaaadedImage", uploadedImage);
      console.log("Media -> fiaaaaaaaaaaale", file);

      await addImageToProduct({
        variables: { id: parseInt(id), image: uploadedImage.data },
      });
      setLoading(false);
    }
  };

  const uploaderProps = {
    listType: "picture-card",
    name: "files",
    multiple: true,
    onPreview: handlePreview,
    accept: "image/*,video/*",
    showUploadList: {
      showDownloadIcon: true,
      downloadIcon: "download ",
      showRemoveIcon: !uploading,
      removeIcon: id ? (
        <Popconfirm
          title={t("products.undone")}
          onConfirm={async () => {
            if (id) {
              setLoading(true);

              await removeImageFromProduct({
                variables: { id: parseInt(removeImage.uid) },
              });
              setLoading(false);
            }
          }}
          okText={t("analytics.yes")}
          cancelText={t("analytics.no")}
          okType="danger">
          <DeleteOutlined />
        </Popconfirm>
      ) : (
        <DeleteOutlined />
      ),
    },

    onRemove: async (file) => {
      setRemoveImage(file);
      if (id) return false;
    },
    beforeUpload: (file) => {
      onChange(file);
      return false;
    },
    // itemRender: (item, file, fileList, actions) => {
    //   console.log("🚀 ~ file: media.js ~ line 140 ~ Media ~ file", file);
    //   return (
    //     <MyImage
    //       thumb
    //       src={file.thumbUrl}
    //       alt="avatar"
    //       wrapperStyle={{ width: "100%", height: "100%", cursor: "pointer" }}
    //       style={{ width: "100%", height: "100%" }}
    //     />
    //   );
    // },
  };

  const customStyle = {
    color: theme?.customColors?.primary,
  };
  return (
    <>
      <Space align="baseline" size={5}>
        <Typography.Title level={4}>{t("products.media")}</Typography.Title>
        <Typography.Text className="upload-text">{t("products.innerImages")}</Typography.Text>
      </Space>
      <Form.Item>
        <Form.Item name="gallery" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
          <Upload.Dragger
            {...uploaderProps}
            multiple
            style={{
              width: "100%",
              height: "112px",
              marginRight: 0,
              background: "rgba(110, 187, 197, 0.02)",
              border: "1px dashed rgba(22, 54, 99, 0.24)",
            }}>
            <p
              className="ant-upload-drag-icon"
              style={{
                marginBottom: "2px",
                ...customStyle,
              }}>
              {/* <InboxOutlined /> */}
              <MyImage src={imagePlace} width={24} className="ant-upload-drag-icon" />
              <>
                {/* <p className="ant-upload-text">{t("products.clickToUpload")}</p>
                <p className="ant-upload-hint">{t("products.support")}</p> */}
                <p className="ant-upload-hint" style={{ ...customStyle, fontSize: "12px" }}>
                  {t("products.addmedia")}
                </p>
              </>
            </p>
            {/* {(!images || images.length === 0) && (
            )} */}
          </Upload.Dragger>
        </Form.Item>
      </Form.Item>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={() => setPreviewVisible(false)}>
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

export default Media;
