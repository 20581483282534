import { makeVar, useReactiveVar } from "@apollo/client";
import { getCache, setCache } from "../../utlis/cacheService";
// import { getCache, setCache } from "../../lib/cacheService";

const initialCurrency = { code: "EGP" };

export const varCurrency = makeVar(initialCurrency);
export const varClient = makeVar(null);
const domain = makeVar("");
export const varThemeType = makeVar("");

///
export const varDomain = (newVal) => {
  if (typeof newVal !== "undefined") {
    setCache("domain", newVal);

    return domain(newVal);
  }
  return domain();
};
// export const varCurrency = (newVal) => {
//   if (typeof newVal !== "undefined") {
//     setCache("currency", newVal);

//     return currency(newVal);
//   }
//   return currency();
// };

//hooks to update data
// export const useVarTheme = () => {
//   const data = useReactiveVar(theme);
//   return data;
// };

export const setCommonDefaults = () => {
  console.log(
    "🚀 ~ file: defaults.js ~ line 37 ~ setCommonDefaults ~ getCache",
    getCache("domain"),
  );

  domain(getCache("domain"));
};
