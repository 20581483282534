import { useMutation } from "@apollo/client";
import { Button, Col, Row, Space, Typography } from "antd";
import { useHistory } from "react-router";
import styled from "styled-components";
import ImportProducts, {
  PRODUCTS_CSV,
} from "../../pages/products/products/components/importProducts";
import MyImage from "./image";
import { CSVLink } from "react-csv";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

type ProductProps = {
  image: string;
  description: string;
  buttonLink?: string;
  disableButton?: boolean;
  buttonText?: string;
  importText: string;
};

const EmptyProductPage: React.FC<ProductProps> = ({
  image,
  description,
  buttonLink,
  buttonText,
  disableButton,
  importText,
}) => {
  const { push } = useHistory();

  const [loadAllRequests, { data }] = useMutation(PRODUCTS_CSV);
  const { t } = useTranslation();
  return (
    <Container>
      <div className="content">
        <MyImage src={image} width={210} />
        <Text className="text">{description}</Text>
        <Row style={{ width: "100%", justifyContent: "center" }}>
          <Col>
            {buttonLink && (
              <Button
                type="primary"
                block={true}
                disabled={disableButton}
                onClick={() => {
                  push(`${buttonLink}`);
                }}>
                {buttonText}
              </Button>
            )}
          </Col>
          <Col>
            {
              //@ts-ignore
              <ImportProducts
                empty={true}
                // selectedProducts={selectedRowKeys}
                // variables={variables}
                // productsCount={data?.products?.count || 0}
              />
            }
          </Col>
          {/* <CSVLink filename="products.csv" data={data?.exportProducts || []}>
            <Button>{importText}</Button>
          </CSVLink> */}
        </Row>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;

  .content {
    width: fit-content;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    height: 300px;
    gap: 16px;
    margin-top: 100px;
  }
  height: 70vh;

  /* .ant-image {
    margin-bottom: 20px;
  } */
  .text {
    font-size: 20px;
    font-weight: 600;
    color: ${(props) => props.theme.colors.Gray2};
    display: block;
  }
  .ant-btn {
    height: 48px;
    width: 330px;
  }
  a {
    .ant-btn {
      border: 1px solid ${(props) => props.theme.customColors.primary};
      background: transparent;
      color: ${(props) => props.theme.customColors.primary};
    }
  }
`;
export default EmptyProductPage;
