export function getLastOfDay(toDate: Date) {
  toDate.setHours(23);
  toDate.setMinutes(59);
  toDate.setSeconds(59);
  console.log("🚀 ~ file: orderFilterDrawer.tsx ~ line 410 ~ getLastOfDay ~ toDate", toDate);
  return toDate;
}
export function getFirstOfDay(fromDate: Date) {
  console.log("🚀 ~ file: orderFilterDrawer.tsx ~ line 413 ~ getFirstOfDay ~ fromDate", fromDate);
  fromDate.setHours(0);
  fromDate.setMinutes(0);
  fromDate.setSeconds(0);
  return fromDate;
}
