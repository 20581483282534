import { Button, Form, Select } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { ORDER_TAGS } from "../../modules/common/queries";
import gql from "graphql-tag";
import styled from "styled-components";
import { useForm } from "antd/lib/form/Form";
import TagsSelectors from "../../components/common/selectors/tagsSelectors";
const { Option } = Select;

export const AddTags = ({ onFinish, setNewTags, type, loading }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  // const [tagFilter, setTagFilter] = useState("");
  const [form] = useForm();
  // const tagsQuery = useQuery(ORDER_TAGS, {
  //   variables: {
  //     name: tagFilter,
  //     type: "ORDER",
  //   },
  // });

  return (
    <Container>
      <Form
        form={form}
        onFinish={(vars) => {
          console.log("🚀 ~ file: tag.js ~ line 46 ~ AddTags ~ vars", vars);
          onFinish(vars.tags);
        }}
        onchan
        onValuesChange={(val) => {
          console.log("AddProduct -> val", val);
          setNewTags(val.tags);
        }}>
        <Form.Item name="tags">
          <TagsSelectors type={type} placeholder={t("order.addTagPlaceholder")} />

          {/* <Select
            showSearch
            onSearch={(val) => setTagFilter(val)}
            placeholder={t("order.addTagPlaceholder")}
            mode="tags">
            {tagsQuery.data?.tags.nodes.map((value) => (
              <Option key={value.id} value={value.name}>
                {value.name}
              </Option>
            ))}
          </Select> */}
        </Form.Item>
        <div className="btn-container">
          <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
            {t("order.save")}
          </Button>
        </div>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  .btn-container {
    margin-top: 20px;
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: -20px;
  }
`;
