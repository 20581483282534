import React from "react";
import { Image as AntdImage } from "antd";
// import useTranslation from "next-translate/useTranslation";
import defaultImageneed from "../../assets/imgs/defaultImage.jpg";

function isImage(url) {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
}
export function isVideo(url) {
  return /\.(mp4|webm|ogg|ogv|avi|mov|wmv|flv|m4v|mkv)$/.test(url);
}
export function convirtToVideoIfImg(url) {
  if (isVideo(url)) {
    return url.substr(0, url.lastIndexOf(".")) + ".jpg";
  } else {
    return url;
  }
}
const MyImage = ({
  style = {},
  image = {},
  quality = "md",
  // type = "antd",
  avatar = false,
  icon = "",
  rotate = "",
  fallback = "",
  wrapperStyle = {},
  thumb = true,

  ...props
}) => {
  const getIconWidth = (icon) => {
    switch (icon) {
      case "xs":
        return 20;
      case "sm":
        return 24;
      case "lg":
        return 34;
      case "xl":
        return 50;
      default:
        return 29;
    }
  };
  let src = image && image[quality] ? image[quality] : props.src || defaultImageneed;

  if (thumb && isVideo(src)) {
    var thumbImage = src.substr(0, src.lastIndexOf(".")) + ".jpg";
  }
  console.log("🚀 ~ file: image.js ~ line 54 ~ src", src);

  return isVideo(src) && !thumb ? ( // video tag for video
    <video controls>
      <source src={src} type="video/mp4" />
    </video>
  ) : (
    <AntdImage
      width={"100%"}
      {...props}
      src={thumbImage || src || "error"}
      wrapperStyle={{ ...wrapperStyle, display: icon && "block" }}
      style={{
        ...style,
        cursor: props.preview ? "pointer" : style.cursor,
        borderRadius: avatar ? "50%" : style.borderRadius ?? "unset",
        width: icon ? getIconWidth(icon) : style.width,
        // transform: rotate && rotateImg(rotate),
      }}
      // fallback={fallback || images.default}
      fallback={defaultImage}
      // placeholder={
      //   <AntdImage
      //     preivew={false}
      //     {...props}
      //     src={image?.sm || defaultImage}
      //   />
      // }
      preview={props.preview ? {} : false}
    />
  );
};
export const defaultImage = defaultImageneed;

export default MyImage;
