import { useMutation, useQuery } from "@apollo/client";
import { Drawer, Menu, Spin, Typography, Button } from "antd";
import { useTranslation } from "react-i18next";
import helpIcon from "../../../../assets/imgs/help-icon.svg";
import { useHistory } from "react-router-dom";
import helpIcon1 from "../../../../assets/imgs/help-center/icon1.svg";
import helpIcon2 from "../../../../assets/imgs/help-center/icon2.svg";
import helpIcon3 from "../../../../assets/imgs/help-center/icon3.svg";
import helpIcon4 from "../../../../assets/imgs/help-center/icon4.svg";
import { CURRENT_USER } from "../../../../utlis/Me";
import MyImage from "../../../common/image";
import styled from "styled-components";
import { useState } from "react";
import { UPDATE_ONBOARDING } from "../../sider/joyRideSider";

import SuggestModel from "./suggest";

const { SubMenu } = Menu;
const { Text } = Typography;

const HelpDrawerComp = ({ me, children, setCollapsed, collapsed, setVisible }) => {
  const { data = { me: {} } } = useQuery(CURRENT_USER, {
    fetchPolicy: "cache-first",
  });
  const [updateOnboarding, { loading }] = useMutation(UPDATE_ONBOARDING, {
    onCompleted(data) {
      console.log("🚀 ~ file: joyRideSider.tsx ~ line 39 ~ onCompleted ~ data", data);
    },
    onError(er) {
      console.log("🚀 ~ file: joyRideSider.tsx ~ line 40 ~ onError ~ er", er);
    },
  });
  const helpData = [
    {
      title: "تذاكر الدعم",
      desc: "إذا كنت تواجه أي مشكلة أرسل لنا وسنقوم بحلها بأسرع وقت",
      id: 1,
      src: helpIcon1,
      onClick: () => {
        window.open("https://www.b7r.store/helpCenter/ticket", "_blank");
        setDrawervisible(false)
      },
    },
    {
      title: "إقترح ميزة جديدة",
      desc: "ساعدنا في تحسين المنصة من خلال اقتراح ميزات جديدة",
      id: 2,
      src: helpIcon2,
      onClick: () => {
        // window.open("https://www.b7r.store/helpCenter/ticket", "_blank");
        showModal();
        setDrawervisible(false)
      },
    },
    {
      title: "إعادة تعيين المرشد التوجيهي.",
      desc: "إعادة عرض الخطوات الإرشادية للميزات الموجودة في بحر",
      id: 3,
      src: helpIcon3,
      loading,
      onClick: () => {
        updateOnboarding({
          variables: {
            onBoardingFlag: 1,
          },
        });
        setDrawervisible(false)
      },
    },
    {
      title: "مركز المساعدة",
      desc: "إذا كانت لديك استفسارات او اسئلة ",
      id: 4,
      src: helpIcon4,
      onClick: () => {
        window.open("https://www.b7r.store/helpCenter/", "_blank");
        setDrawervisible(false)
      },
    },
  ];

  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [drawerVisible, setDrawervisible] = useState(false);
  const showHelpDrawer = () => {
    setDrawervisible(true);
  };
  const onClose = () => {
    setDrawervisible(false);
  };

  //model funtions
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  return (
    <>
      <div onClick={showHelpDrawer} style={{ cursor: "pointer", marginRight: "10px" }}>
        <MyImage icon="xs" src={helpIcon} />
      </div>

      <SuggestModel setIsModalVisible={setIsModalVisible} isModalVisible={isModalVisible} />

      <Drawer
        footer={
          <Button
            onClick={() => {
              window.open("https://www.b7r.store/helpCenter/contact/", "_blank");
              setDrawervisible(false)
            }}
            style={{
              border: "1px solid #1985A1",
              color: "#1985A1",
            }}>
            تواصل معنا
          </Button>
        }
        title="المساعده"
        width={438}
        placement="left"
        onClose={onClose}
        visible={drawerVisible}>
        <HelpDrawer>
          {helpData.map((item) => {
            return (
              <Spin spinning={item.loading || false}>
                <div onClick={item.onClick} className="help-container">
                  <div>
                    <MyImage src={item.src} style={{ width: "54px", height: "54px" }} />
                  </div>
                  <div>
                    <Text className="help-title">{item.title}</Text>
                    <Text className="help-desc">{item.desc}</Text>
                  </div>
                </div>
              </Spin>
            );
          })}
        </HelpDrawer>
      </Drawer>
    </>
  );
};

const HelpDrawer = styled.div`
  .help-container {
    display: flex;
    align-items: center;
    padding: 13px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    gap: 14px;
    margin-bottom: 20px;
    cursor: pointer;
  }
  .help-title {
    font-size: 18px;
    font-weight: 600;
    display: block;
  }
  .help-desc {
    font-size: 12px;
    color: #828282;
  }
`;

export default HelpDrawerComp;
