export enum InventoryFilters {
  notTracking = "notTracking",
  inStock = "inStock",
  outOfStock = "outOfStock",
  Backorder = "Backorder",
}
export enum UserRole {
  SUPER_ADMIN = "SUPER_ADMIN",
  ADMIN = "ADMIN",
  INVENTORY = "INVENTORY",
  EMPLOYER = "EMPLOYER",
  BUYER = "BUYER",
  CUSTOMER = "CUSTOMER",
  CUSTOM = "CUSTOM",
  VENDOR = "VENDOR",
  CASHER = "CASHER",
}
