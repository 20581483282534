import { useMutation, useQuery, gql } from "@apollo/client";
import { Button, Space, Steps, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Joyride, { CallBackProps, EVENTS, STATUS, Step, StoreHelpers, ACTIONS } from "react-joyride";
import JoyrideTooltip from "react-joyride";
import styled from "styled-components";
// import { clearTimeout, setTimeout } from "timers";
import { useResponsive } from "../../../lib/useResponsive";
import { CURRENT_USER } from "../../../utlis/Me";
import MessageTourModal from "../../common/messageTourModal";
import RegisterationMessageModal from "../../common/registerationMessageModal";

const { Text } = Typography;
export const UPDATE_ONBOARDING = gql`
  mutation UPDATE_ONBOARDING($onBoardingFlag: Int) {
    updateAppsInStore(onBoardingFlag: $onBoardingFlag) {
      id
      onBoardingFlag
      apps
    }
  }
`;
export const BoardingJoyRide = ({ showDrawer }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { md } = useResponsive();
  const [isHide, setIsHide] = useState(true);

  const [updateOnboarding, { loading }] = useMutation(UPDATE_ONBOARDING, {
    onCompleted(data) {
      console.log("🚀 ~ file: joyRideSider.tsx ~ line 39 ~ onCompleted ~ data", data);
    },
    onError(er) {
      console.log("🚀 ~ file: joyRideSider.tsx ~ line 40 ~ onError ~ er", er);
    },
  });

  const { data = { me: {} }, loading: loadingMe } = useQuery(CURRENT_USER, {
    fetchPolicy: "cache-first",
  });
  const [runStep, setRunStep] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => setIsHide(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  let helpers: StoreHelpers;

  let getHelpers = (helpers: StoreHelpers) => {
    helpers = helpers;
  };

  let handleClickStart = (e) => {
    // e.preventDefault();
    setRunStep(true);
    console.log("workeddd");
    console.log("runSteprunStep", runStep);
  };

  let handleJoyrideCallback = (data) => {
    console.log("🚀 ~ file: joyRideSider.tsx ~ line 68 ~ handleJoyrideCallback ~ data", data);
    const { action, index, status, type } = data;
    if ((action === "close" || status === "finished") && !loading) {
      updateOnboarding({
        variables: {
          onBoardingFlag: 12,
        },
      });
    }
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
      // setStepIndex(11);
    }
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      console.log(
        "🚀 ~ file: joyRideSider.tsx ~ line 74 ~ handleJoyrideCallback ~ [STATUS.FINISHED, STATUS.SKIPPED].includes(status)",
        [STATUS.FINISHED, STATUS.SKIPPED].includes(status),
      );
      // setRunStep(true);
    }
    // console.groupCollapsed(type);
    // console.log(data);
    // console.log(status);
    // console.groupEnd();
  };

  const settingsList = [
    {
      text: t("common.customDomain"),
    },
    // {
    // text: t("common.settingsPassword"),
    // },
    {
      text: t("common.shippingOptions"),
    },
    // {
    // text: t("common.addGatways"),
    // },
    { text: t("common.paidOptions") },
    { text: t("common.orderSteps") },
    { text: t("common.mainSettings") },
    { text: t("common.addCrow") },
    {
      text: t("common.makeOrder"),
    },
    //  {
    //   text: t("common.makeOrder2"),
    // },
  ];
  // if (loadingMe || !me?.shop?.onBoardingFlag || me?.shop?.onBoardingFlag == 11) return null;
  if (isHide && data.me?.currentShop?.onBoardingFlag < 1)
    return <RegisterationMessageModal handleClickStart={() => console.log(".")} />;

  return (
    <>
      <>
        <Joyride
          callback={handleJoyrideCallback}
          continuous={true}
          getHelpers={getHelpers}
          run={runStep}
          scrollToFirstStep={true}
          disableOverlayClose
          disableCloseOnEsc
          spotlightPadding={0}
          showProgress={false}
          showSkipButton={true}
          hideBackButton={true}
          stepIndex={stepIndex}
          debug={true}
          locale={{
            last: <span>{t("common.lastBoarding")}</span>,
            next: <span>{t("common.nextBoard")}</span>,
            skip: (
              <span
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}>
                {`${10}/${stepIndex + 1}`}
              </span>
            ),
          }}
          styles={{
            options: {
              arrowColor: "#fff",
              backgroundColor: "#fff",
              overlayColor: "rgba(51, 51, 51, 0.32)",
              textColor: "#333",
              width: md ? 338 : 246,
              zIndex: 1000,
            },
            beaconInner: {
              animation: "joyride-beacon-inner 1.2s infinite ease-in-out",
              borderRadius: "50%",
              display: "block",
              height: "50%",
              left: "50%",
              opacity: 0.7,
              position: "absolute",
              top: "50%",
              transform: "translate(-50%, -50%)",
              width: "50%",
            },
            beaconOuter: {
              animation: "joyride-beacon-outer 1.2s infinite ease-in-out",
              borderRadius: "50%",
              boxSizing: "border-box",
              display: "block",
              height: "100%",
              left: 0,
              opacity: 0.9,
              position: "absolute",
              top: 0,
              transformOrigin: "center",
              width: "100%",
            },
            buttonClose: {
              right: "unset",
            },
          }}
          steps={[
            {
              content: (
                <>
                  <Text className="boarding-text"> {t("common.orderBoarding")} </Text>
                </>
              ),
              target: ".orders-boarding",
              placement: language == "ar" ? "left-start" : "right-start",
              placementBeacon: "top-end",
              disableBeacon: true,
            },
            {
              content: (
                <>
                  {/* <Text className="boarding-text"> {t("common.usersBoarding")} </Text> */}
                  <Text className="boarding-text"> {t("common.customersBoarding")} </Text>
                </>
              ),
              target: ".users-boarding",
              placement: language == "ar" ? "left-start" : "right-start",
              placementBeacon: "top-end",
              // disableBeacon: true
            },
            {
              content: (
                <>
                  <Text className="boarding-text"> {t("common.productBoarding")} </Text>
                </>
              ),
              target: ".product-boarding",
              placement: language == "ar" ? "left-start" : "right-start",
              placementBeacon: "top-end",
              // disableBeacon: true
            },
            {
              content: (
                <>
                  <Text className="boarding-text"> {t("common.analyticsBoarding")} </Text>
                </>
              ),
              target: ".analytics-boarding",
              placement: language == "ar" ? "left-start" : "right-start",
              placementBeacon: "top-end",
              // disableBeacon: true
            },
            {
              content: (
                <>
                  <Text className="boarding-text"> {t("common.marketingBoarding")} </Text>
                </>
              ),
              target: ".marketing-boarding",
              placement: language == "ar" ? "left-start" : "right-start",
              placementBeacon: "top-end",
              // disableBeacon: true
            },
            {
              content: (
                <>
                  <Text className="boarding-text"> {t("common.themeBoarding")} </Text>
                </>
              ),
              target: ".theme-boarding",
              placement: language == "ar" ? "left-start" : "right-start",
              placementBeacon: "top-end",
              // disableBeacon: true
            },
            // {
            //   content: (
            //     <>
            //       <Text className="boarding-text"> {t("common.pagesBoarding")} </Text>
            //     </>
            //   ),
            //   target: ".pages-boarding",
            //   placement: language == "ar" ? "left-start" : "right-start",
            //   placementBeacon: "top-end",
            //   // disableBeacon: true
            // },

            // {
            //   content: (
            //     <Space style={{ width: "100%", textAlign: "start" }}>
            //       <Text className="boarding-text"> {t("common.facebookBoarding")} </Text>
            //     </Space>
            //   ),
            //   target: ".facebook-boarding",
            //   // placement: language == "ar" ? "right-end" : "top-start",
            //   placement: language == "ar" ? "left-start" : "right-start",
            //   placementBeacon: "top-end",
            //   // disableBeacon: true
            // },
            // {
            //   content: (
            //     <>
            //       <Text className="boarding-text"> {t("common.chatBoarding")} </Text>
            //     </>
            //   ),
            //   target: ".chat-boarding",
            //   placement: language == "ar" ? "left-start" : "right-start",
            //   placementBeacon: "top-end",
            //   // disableBeacon: true
            // },
            {
              content: (
                <>
                  <Text className="boarding-text"> {t("common.appsBoarding")} </Text>
                </>
              ),
              target: ".apps-boarding",
              placement: language == "ar" ? "left-start" : "right-start",
              placementBeacon: "top-end",
              // disableBeacon: true
            },
            {
              content: (
                <Space direction="vertical" style={{ width: "100%", textAlign: "start" }}>
                  <Text className="boarding-text"> {t("common.settingsBoarding")} : </Text>
                  <Space direction="vertical">
                    {settingsList.map((list, index) => (
                      <Text key={index}> - {list.text} </Text>
                    ))}
                  </Space>
                </Space>
              ),
              target: ".settings-boarding",
              placement: language == "ar" ? "left-end" : "right-end",
              placementBeacon: "top",
              // disableBeacon: true
            },
            {
              content: (
                <>
                  <Text className="boarding-text"> {t("common.adminBoarding")} </Text>
                </>
              ),
              target: ".admin-boarding",
              placement: "right-start",
              placementBeacon: "right-start",
              // disableBeacon: true
            },
            {
              content: (
                <>
                  <Text className="boarding-text"> {t("common.viewStoreBoarding")} </Text>
                </>
              ),
              target: ".view-store-boarding",
              placement: "left-start",
              placementBeacon: "right-start",
              // disableBeacon: true
            },
          ]}
        />
        <MessageTourModal handleClickStart={handleClickStart} showDrawer={showDrawer} />
      </>
    </>
  );
};
