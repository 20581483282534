import React, { useState } from "react";

import { Select } from "antd";
import { gql, useQuery } from "@apollo/client";
import debounce from "lodash/debounce";
import { useTranslation } from "react-i18next";
const { Option } = Select;

export const CURRENCIES = gql`
  query CURRENCIES($where: CurrencyWhereInput!) {
    currencies(take: 10, orderBy: { id: asc }, where: $where) {
      id
      name
      code
    }
  }
`;

const CurrencySelect = ({ style = {}, mode, filterId, ...props }) => {
  const [search, setSearch] = useState("");
  const { t } = useTranslation();

  const { data, loading } = useQuery(CURRENCIES, {
    variables: {
      where: {
        name: { contains: search, mode: "insensitive" },
      },
    },
  });

  return (
    <Select
      {...props}
      style={{ width: "100%", minWidth: 150, ...style }}
      // treeDataSimpleMode
      onSearch={debounce((val) => setSearch(val), 200)}
      showSearch
      loading={loading}
      mode={mode}
      allowClear
      // showCheckedStrategy={SHOW_ALL}
      filterOption={false}
      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
      placeholder={t("settings.seachForCurrency")}>
      {data?.currencies.map(({ id, name, code }) => (
        <Option key={id} value={code}>
          {name}
        </Option>
      ))}
    </Select>
  );
};

export default React.memo(CurrencySelect);
