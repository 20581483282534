import React, { useMemo, useState } from "react";
import { Form, Upload, Modal, Input, Typography, Space, Switch, Button, Spin } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { FormCard } from "../style";
import { useMutation, gql } from "@apollo/client";
import makeSlug from "slug-arabic"; // by B7r :)
// import JoditEditor from "../../../../../components/common/joditEditor";
import DraftJsHtmlEditor from "../../../../../components/common/DraftJsHtmlEditor";


import { useTranslation } from "react-i18next";
import { AppLanguageSwitch } from "../../../../../components/common/websiteLanguage";
import styled from "styled-components";

const TitleCard = ({
  setDescriptionAr,
  setDescription,
  description,
  descriptionAr,
  form,
  // slug,
  pageName,
  isVariant,
  isPage,
}) => {
  // const MemoizedEmailEditor = useMemo(
  //   () => <JoditEditor style={{ height: 500 }} content={description} update={setDescription} />,
  //   [description],
  // );
  // const MemoizedEmailEditorArOld = useMemo(
  //   () => <JoditEditor style={{ height: 500 }} content={descriptionAr} update={setDescriptionAr} />,
  //   [descriptionAr],
  // );
  // const getSlug = () =>
  //   form.setFieldsValue({
  //     slug: {
  //       ar: makeSlug(slug.ar),
  //       en: makeSlug(slug.en),
  //     },
  //   });
  const MemoizedEmailEditor = useMemo(
    () => <DraftJsHtmlEditor content={description} update={setDescription} key="productDesc" />,
    [description],
  );
  const MemoizedEmailEditorAr = useMemo(
    () => <DraftJsHtmlEditor content={descriptionAr} update={setDescriptionAr} key="productDescAr" />,
    [descriptionAr],
  );

  // <DraftJsHtmlEditor content={descriptionAr} update={setDescriptionAr} />

  const { t } = useTranslation();
  const [isEn, setIsEn] = useState();
  const [firstLangLoad, setFirstLangLoad] = useState(false);
  return (
    <Container isEn={isEn}>
      <AppLanguageSwitch
        isEn={isEn}
        setIsEn={setIsEn}
        firstLangLoad={firstLangLoad}
        setFirstLangLoad={setFirstLangLoad}
      />
      {!firstLangLoad ? (
        <Spin
          style={{
            position: "absolute",
            marginTop: "50px",
            width: "100%",
            height: "100%",
            zIndex: 999,
            background: "white",
          }}
        />
      ) : (
        <FormCard>

          {isEn ? (
            <>
              <Space align="baseline" style={{ justifyContent: "space-between", width: "100%" }}>
                <Typography.Title level={3} className="form__title">
                  {pageName === "products"
                    ? t("pages.productDetails")
                    : pageName === "pages"
                      ? t("pages.pageDetails")
                      : pageName === "variants"
                        ? t("products.variantDetails")
                        : t("pages.postDetails")}
                </Typography.Title>
                {/* <Space size={15} align="end">
              <Typography.Text>
                {isEn ? t("products.switchEnDetail") : t("products.switchArDetail")}
              </Typography.Text>
              <Switch
                style={{ marginTop: "20px" }}
                checkedChildren="AR"
                unCheckedChildren="EN"
                onChange={() => setIsEn(!isEn)}
              />
            </Space> */}
              </Space>
              <Form.Item
                label={
                  pageName === "products"
                    ? t("pages.productName")
                    : pageName === "pages"
                      ? t("pages.pageName")
                      : pageName === "variants"
                        ? t("products.variantName")
                        : t("pages.postName")
                }
                name={["title", "en"]}
                rules={[{ required: true }]}>
                <Input
                  placeholder={t("products.shirt")}
                //  onBlur={getSlug}
                />
              </Form.Item>
              {/* <Form.Item
            label={t("pages.slug")}
            name={["slug", "en"]}
            rules={[{ required: true }]}>
            <Input placeholder="uniqe name" onBlur={getSlug} />
          </Form.Item> */}
              {!isVariant && <Form.Item label={t("pages.desc")}>{MemoizedEmailEditor}</Form.Item>}
            </>
          ) : (
            <>
              <Typography.Title level={3} className="form__title">
                {/* {t(`pages.${[isPage] ? "pageDetails" : "productDetails"}`)} */}
                {pageName === "products"
                  ? t("pages.productDetails")
                  : pageName === "pages"
                    ? t("pages.pageDetails")
                    : pageName === "variants"
                      ? t("products.variantDetails")
                      : t("pages.postDetails")}
              </Typography.Title>
              <Form.Item
                label={
                  pageName === "products"
                    ? t("pages.productName")
                    : pageName === "pages"
                      ? t("pages.pageName")
                      : pageName === "variants"
                        ? t("products.variantName")
                        : t("pages.postName")
                }
                name={["title", "ar"]}
                rules={[{ required: true }]}>
                <Input
                  placeholder="تيشرت "
                // onBlur={getSlug}
                />
              </Form.Item>
              {/* <Form.Item
            label="مميز"
            name={["slug", "ar"]}
            rules={[{ required: true }]}>
            <Input placeholder="اسم مميز" onBlur={getSlug} />
          </Form.Item> */}

              {!isVariant && <Form.Item label={t("pages.desc")}>{MemoizedEmailEditorAr}</Form.Item>}


            </>
          )}
        </FormCard>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

export default TitleCard;
