import { varClient, varDomain } from "../common/defaults";
import { varAuth } from "./defaults";

import { CURRENT_USER } from "../../utlis/Me";

export const authMutation = {
  asyncAuth: async ({ token, ...rest }) => {
    const client = rest.client ?? varClient();

    if (!token) {
      varAuth({}, true);
    }
    const auth = varAuth();
    varAuth({
      ...auth,
      token,
    });

    const {
      data: { me },
    } = await client.mutate({
      mutation: CURRENT_USER,
    });
    client.writeQuery({
      query: CURRENT_USER,
      data: {
        me,
      },
    });
    varAuth({
      ...auth,
      isLogin: me ? true : false,
      token: me ? token : null,
      user: me,
    });

    return "done";
  },
  signOut: async () => {
    // const client = rest.client ?? varClient();
    varAuth({}, true);
    window.location.href = window.location.origin + "/signin";
    setTimeout(() => {
      varDomain(null);
    }, 300);

    // const {
    //   data: { me },
    // } = await client.mutate({
    //   mutation: CURRENT_USER,
    // });
    // client.writeQuery({
    //   query: CURRENT_USER,
    //   data: {
    //     me,
    //   },
    // });

    return "done";
  },
};
