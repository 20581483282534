import React from "react";
import { Form, InputNumber, Divider, Row, Col, Space, Typography } from "antd";
import { FormCard } from "../style";
import { useTranslation } from "react-i18next";
import { useResponsive } from '../../../../../lib/useResponsive';
const PriceCard = ({ profit, profitMargin, currency }) => {
  const { t } = useTranslation();
  const { lg } = useResponsive();
  const currencyCode = currency?.code;
  const code = currencyCode || "$";
  return (
    <FormCard>
      <h2>{t("products.pricing")}</h2>

      <Row justify="space-between" gutter={[16, 15]}>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item
            label={t("products.price")}
            name="price"
            style={{
              display: "inline-block",
              width: lg ? "calc(100% - 5%)" : "100%",
              // width: "calc(50% - 8px)",
              margin: "0px 8px 0 8px",
            }}
            rules={[{ required: true }]}>
            <InputNumber
              onChange={(v) => {
                console.log("🚀 ~ file: priceCard.js ~ line 31 ~ PriceCard ~ v", v);
              }}
              formatter={(value) => `${code} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              parser={(value) => {
                console.log("🚀 ~ file: priceCard.js ~ line 31 ~ PriceCard ~ v", value);

                return value.replace(/([A-z])|\$\s?|(,*)/g, "");
              }}
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
        </Col>

        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item
            label={
              <Space size={5} align="baseline">
                <Typography.Text className="price"> {t("products.comparePrice")} </Typography.Text>
                <del className="deleting-price"> {t("products.deletingPrice")} </del>
              </Space>
            }
            name="comparingPrice"
            style={{
              display: "inline-block",
              // width: "calc(50% - 8px)",
              width: lg ? "calc(100% - 5%)" : "100%"
            }}>
            <InputNumber
              formatter={(value) => `${code} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              parser={(value) => value.replace(/([A-z])|\$\s?|(,*)/g, "")}
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
        </Col>

        <Divider />

        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item
            label={t("products.costPerItem")}
            name="realPrice"
            style={{
              display: "inline-block",
              // width: "calc(50% - 8px)",
              width: lg ? "calc(100% - 5%)" : "100%",
              margin: "0px 8px 0 8px",
            }}>
            <InputNumber
              placeholder="0.00"
              formatter={(value) => `${code} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              parser={(value) => value.replace(/([A-z])|\$\s?|(,*)/g, "")}
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
        </Col>
        <Col lg={{ span: 6 }} xs={{ span: 24 }}>
          <Form.Item
            label={t("products.margin")}
            style={{
              display: "inline-block",
              // width: "calc(25% - 8px)",
              width: lg ? "calc(100% - 5%)" : "100%"
            }}>
            <span className="ant-form-text">
              {profitMargin !== null ? `${profitMargin.toFixed(2)}%` : "-"}
            </span>
          </Form.Item>
        </Col>
        <Col lg={{ span: 6 }} xs={{ span: 24 }}>
          <Form.Item
            label={t("products.profit")}
            style={{
              display: "inline-block",
              // width: "calc(25% - 8px)",
              width: lg ? "calc(100% - 5%)" : "100%"
            }}>
            <span className="ant-form-text">
              {profit !== null ? `${code} ${profit}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-"}
            </span>
          </Form.Item>
        </Col>
      </Row>
    </FormCard>
  );
};

export default PriceCard;
