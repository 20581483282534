import { SettingOutlined } from "@ant-design/icons";
import { NetworkStatus } from "@apollo/client";
import { Divider, Input, List, Skeleton, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Breakpoints } from "../../../../constants";
import { Room as RoomType } from "../../../../types";
import Room from "./Room";
const { Search } = Input;

const { Text } = Typography;

interface Props {
  rooms: { nodes: RoomType[]; count: any };
  setCurrentRoom: (room: RoomType) => void;
  // currentRoom: RoomType;
  setSearch: (value: string) => void;
  search: string;
  onSearch: () => void;
  increaseRoomsSkip: () => void;
  loading: boolean;
  showLoadMore: boolean;
  networkStatus: NetworkStatus;
  showMessage?: () => void;
  loadMoreLoading: boolean;
  refetch?: () => void;
}

const FetchMoreContainer = styled.div`
  margin: 5px auto;
  width: 50%;
  .ant-btn {
    width: 100%;
    color: ${(props) => props.theme.customColors.primary};
    font-size: 12px;
    font-weight: 600;
  }
`;
const Rooms = ({
  rooms,
  setCurrentRoom,
  // currentRoom,
  setSearch,
  search,
  onSearch,
  loading,
  increaseRoomsSkip,
  showLoadMore: hasMoreRooms,
  // networkStatus,
  loadMoreLoading: isLoadingMoreRooms,
  showMessage,
  refetch,
}: Props) => {
  console.log("🚀 ~ file: index.tsx ~ line 55 ~ rooms", rooms);
  const roomsRef = useRef<HTMLDivElement | null>(null);
  const [isGetMoreRooms, setIsGetMoreRooms] = useState(false);
  useEffect(() => {
    if (!isGetMoreRooms) return;
    setIsGetMoreRooms(false);
    if (isLoadingMoreRooms || !hasMoreRooms) return;
    increaseRoomsSkip();
  }, [isGetMoreRooms]);

  useEffect(() => {
    if (!roomsRef?.current) return;
    if (roomsRef?.current?.getAttribute("hasScrollEvent") === "true") return;

    roomsRef.current.addEventListener("scroll", (e) => {
      const { scrollTop, scrollHeight, clientHeight } = roomsRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10 && !isLoadingMoreRooms) {
        console.log("Load Now");
        setIsGetMoreRooms(true);
      }
    });
    roomsRef.current.setAttribute("hasScrollEvent", "true");
    return () => {
      // roomsRef.current.removeEventListener("scroll", (e) => {
      //   console.log("removed");
      // });
    };
  }, [roomsRef.current]);

  const { t } = useTranslation();
  const [firstLoad, setFirstLoad] = useState(true);
  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      return;
    }

    const timeOutId = setTimeout(onSearch, 1000);
    return () => clearTimeout(timeOutId);
  }, [search]);
  return (
    <Container>
      <div className="chat-container">
        <div className="header">
          <Text className="header-text">{t("chat.messages")}</Text>
          <Link to="/apps/message/settings">
            <SettingOutlined style={{ fontSize: 20 }} />
          </Link>
        </div>
        <div className="search">
          <Search
            placeholder={t("chat.inputPlaceholder")}
            value={search}
            onChange={(e) => {
              setSearch(e.currentTarget.value);
            }}
            onSearch={() => {
              onSearch();
            }}
            size="large"
            loading={loading}
          />
        </div>

        <div
          id="scrollableDivRooms"
          style={{
            // height: "100vh",
            height: "60vh",

            overflow: "auto",
            // padding: "0 16px",
            // border: "1px solid rgba(140, 140, 140, 0.35)",
          }}>
          <InfiniteScroll
            pullDownToRefresh={true}
            refreshFunction={refetch}
            dataLength={rooms.nodes?.length || 20}
            next={increaseRoomsSkip}
            hasMore={loading || rooms.nodes?.length < rooms.count}
            loader={<Skeleton paragraph={{ rows: 1 }} active />}
            endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
            scrollableTarget="scrollableDivRooms">
            <List
              loading={loading}
              dataSource={rooms?.nodes}
              renderItem={(room: any, i) => (
                <Room
                  room={room}
                  key={room.id || i}
                  setCurrentRoom={setCurrentRoom}
                  // currentRoom={currentRoom}
                  showMessage={showMessage}
                />
              )}></List>
          </InfiniteScroll>
        </div>
        <div className="rooms" ref={roomsRef}>
          {/* {!loading &&
            rooms?.nodes?.map((room, i) => (
              <Room
                room={room}
                key={room.id || i}
                setCurrentRoom={setCurrentRoom}
                currentRoom={currentRoom}
                showMessage={showMessage}
              />
            ))} */}
          {/* {hasMoreRooms && (
            <FetchMoreContainer>
           
              {isLoadingMoreRooms && <p>{t("chat.loadMore")}</p>}
             
            </FetchMoreContainer>
          )} */}
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;

  .chat-container {
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .rooms {
    max-height: calc(100vh - 345px);
    overflow-y: scroll;
    overflow-x: hidden;
    @media (max-width: ${Breakpoints.lg}) {
      max-height: calc(100vh - 270px);
    }
  }

  .header {
    padding: 32px;
    border-bottom: 1px solid ${(props) => props.theme.borderColors.gray};
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    @media (max-width: ${Breakpoints.sm}) {
      padding: 20px 16px;
    }
    .header-text {
      font-weight: 700;
      font-size: ${(props) => (props.theme.isBreakpoints.lg ? "24px" : "20px")};
      color: ${(props) => props.theme.colors.black3};
    }
  }

  .search {
    padding: 0 16px;
    margin-bottom: 35px;
    .ant-input-group-wrapper {
      border-radius: 8px;
      background: ${(props) => props.theme.backgroundColors.gray1};
      border: 0;
      height: 40px;
    }
    .ant-input-search-button {
      background: none;
      border-radius: 8px;
      border-left: 0;
      border: 0;
    }
    .ant-btn-icon-only.ant-btn-lg {
      padding: 0;
    }
    .ant-input-search .ant-input-lg {
      border-color: #f8f8f8;
    }
  }
`;

export default Rooms;
