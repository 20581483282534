import { Divider, List, Skeleton, Space, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MyImage from "../../../../common/image";
import sms from "../../../../../assets/imgs/sms.svg";
import { formatDistanceToNow } from "date-fns";
import { gql, useMutation, useQuery } from "@apollo/client";
import InfiniteScroll from "react-infinite-scroll-component";

const { Text, Title, Paragraph } = Typography;

export const B7R_NOTIFICATIONS = gql`
  query B7R_NOTIFICATIONS(
    $where: B7rNotificationWhereInput
    $orderBy: B7rNotificationOrderByWithRelationInput
    $skip: Int
  ) {
    b7rNotifications(where: $where, orderBy: $orderBy, skip: $skip, take: 20) {
      count
      nodes {
        id
        title
        desc
        createdAt
        type
        # willAppearTo
        # isSeen
      }
    }
  }
`;
export const SEE_NOTIFICATIONS = gql`
  mutation SEE_NOTIFICATIONS {
    seeNotifications
  }
`;
const NotificationsTabComponent = ({ notSeenNum, refetchNotSeen }) => {
  const [notifications, setNotifications] = useState([]);
  const [page, setpage] = useState(0);

  const {
    loading,
    fetchMore,
    previousData,
    data = previousData,
    refetch,
  } = useQuery(B7R_NOTIFICATIONS, {
    onCompleted: (data) => {
      setNotifications([...notifications, ...data.b7rNotifications.nodes]);
    },
    variables: {
      skip: 0,
      where: {
        type: {
          equals: "NOTIFICATION",
        },
      },
      orderBy: {
        createdAt: "desc",
      },
    },
  });
  const [seeNotifications] = useMutation(SEE_NOTIFICATIONS);

  const loadMoreData = () => {
    console.log("🚀 ~ file: notificationTab.tsx ~ line 63 ~ loadMoreData ~ loadMoreData");

    !loading &&
      fetchMore({
        variables: {
          skip: (page + 1) * 20,
        },
      }).then(({ data }) => {
        setpage(page + 1);
        setNotifications([...notifications, ...data.b7rNotifications.nodes]);
      });
  };

  useEffect(() => {
    notSeenNum && seeNotifications().then(refetchNotSeen);
  }, [notSeenNum]);
  // if (loading) return <Spin />;
  console.log(
    "🚀 ~ file: notificationTab.tsx ~ line 27 ~ NotificationsTabComponent ~ data",
    loading || notifications?.length < data?.b7rNotifications?.count,
  );
  console.log(
    "🚀 ~ file: notificationTab.tsx ~ line 150 ~ NotificationsTabComponent ~ data?.b7rNotifications?.nodes?.count",
    data?.b7rNotifications?.count,
  );
  console.log(
    "🚀 ~ file: notificationTab.tsx ~ line 151 ~ NotificationsTabComponent ~ notifications?.length",
    notifications?.length,
  );

  return (
    <div
      id="scrollableDivFeatures"
      style={{
        height: "100vh",
        overflow: "auto",
        padding: "0 16px",
        // border: "1px solid rgba(140, 140, 140, 0.35)",
      }}>
      <InfiniteScroll
        pullDownToRefresh={true}
        refreshFunction={refetch}
        dataLength={notifications?.length || 20}
        next={loadMoreData}
        hasMore={loading || notifications?.length < data?.b7rNotifications?.count}
        loader={<Skeleton paragraph={{ rows: 1 }} active />}
        endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
        scrollableTarget="scrollableDivFeatures">
        {/* <List
          dataSource={data}
          renderItem={item => (
            <List.Item key={item.email}>
              <List.Item.Meta
                avatar={<Avatar src={item.picture.large} />}
                title={<a href="https://ant.design">{item.name.last}</a>}
                description={item.email}
              />
              <div>Content</div>
            </List.Item>
          )}
        /> */}

        <CustomeList
          loading={loading}
          dataSource={notifications}
          renderItem={(item: any) => (
            <List.Item>
              <List.Item.Meta
                avatar={<MyImage src={sms} />}
                title={
                  <Space direction="vertical" size={0}>
                    <Text className="date-time">
                      {formatDistanceToNow(new Date(item.createdAt), { addSuffix: true })}
                    </Text>
                    <Text className="title">{item.title}</Text>
                  </Space>
                }
                description={
                  <Paragraph
                    className="description"
                    ellipsis={{
                      rows: 3,
                      expandable: true,
                      symbol: "اقرأ المزيد",
                    }}>
                    {item.desc}
                  </Paragraph>
                }
              />
            </List.Item>
          )}></CustomeList>
      </InfiniteScroll>
    </div>
  );
};

export default NotificationsTabComponent;

const CustomeList = styled(List)`
  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }
  .date-time {
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    color: ${(props) => props.theme.colors.Gray1};
  }
  .description {
    margin: 0;
    font-size: 12px;
    line-height: 18px;
  }
`;
