import React, { useState, useEffect, useMemo } from "react";

import { Col, Form, Input, Row, Select, Space, TreeSelect } from "antd";
import CategorySelector from "./selectors/categorySelector";
import ProductsSelector from "./selectors/productsSelector";
import PagesSelector from "./selectors/pagesSelector";
import { useTranslation } from "react-i18next";

const { Option } = Select;
export const slideActions = ["category", "href", "product", "page"];

const GetAction = ({ action, setAction }) => {
  const { t } = useTranslation();
  return (
    <Row gutter={8}>
      <Col span={24}>
        <Form.Item label={t("theme.action")} name={"action"}>
          <Select>
            {slideActions.map((action) => (
              <Option key={action} value={action}>
                {action}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

      <Col span={24}>
        {action == "href" ? (
          <Form.Item label={t("theme.href")} name={"href"}>
            <Input placeholder="/shop" />
          </Form.Item>
        ) : action == "product" ? (
          <Form.Item label={t("products.product")} name={"href"}>
            <ProductsSelector valueType={"slug"} />
          </Form.Item>
        ) : action == "page" ? (
          <Form.Item label={t("theme.page")} name={"href"}>
            <PagesSelector valueType={"slug"} />
          </Form.Item>
        ) : (
          <Form.Item label={t("products.Category")} name={"href"}>
            <CategorySelector valueType={"slug"} />
          </Form.Item>
        )}
      </Col>
    </Row>
  );
};

export default GetAction;
