import { useQuery } from "@apollo/client";
import { CURRENT_CURRENCY } from "../../../../modules/common/queries";
import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Switch, Typography, Space } from "antd";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { ClockCircleOutlined, PlusCircleOutlined, RightOutlined } from "@ant-design/icons";
import { FormCard } from "./style";
import VariantCard, { makeLabelOption } from "./cards/variants";
// import { PRODUCTS } from "..";
import Organization from "./cards/organization";
import Media from "./cards/media";
import { uploadGallery } from "../../../../utlis/upload";
import TitleCard from "./cards/titleCard";
import PriceCard from "./cards/priceCard";
import QuantityCard from "./cards/quantityCard";
import ShippingCard from "./cards/shippingCard";
import ChooseImg from "./cards/chooseImage";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SuccessNotification from "../../../../components/common/successNotification";
import errorNotification from "../../../../components/common/errorNotification";
import SeoCard from "../../../../components/common/seoCard";
import { scrollOnError } from "../../../../lib/scrollOnError";
import MyImage from "../../../../components/common/image";
import productPreview from "../../../../assets/imgs/productpreview.svg";
import Schedular from "./cards/schedular";
import PublishSwitchComponent from "./cards/publishSwitch";
import useActivation from "../../../../hooks/useActivation";
import AddEditStickyContainer from "../../../../components/layout/header/addEditSticky";
import styled from "styled-components";
import BackButton from "../../../../components/common/backBtn";
import ProductisDigitalCard from "./cards/isDigitalCard";

const { Text } = Typography;

const ADD_PRODUCT = gql`
  mutation ADD_PRODUCT(
    $title: MultiLangText!
    # $slug: MultiLangText!
    $price: Float!
    $realPrice: Float
    $comparingPrice: Float
    $quantities: [quantitiesInput!]
    $barcode: String
    $SKU: String
    $description: MultiLangText
    $variants: [CustomVariantCreateWithoutProductInput!]
    $categories: [CategoryWhereUniqueInput!]
    $tags: [String!]
    # $type: ProductTypeWhereUniqueInput
    $publishAt: DateTime
    $published: Boolean
    $brand: BrandWhereUniqueInput
    $doContinueOutOfStock: Boolean
    $trackQuantity: Boolean
    $gallery: [ImageInput!]
    $options: [OptionsInput!]
    $isPhysical: Boolean
    $shipping: ShippingInput
    $mainImage: Int
    $seo: SeoItemArg
    $digitalLink: String
  ) {
    addProduct(
      title: $title
      mainImage: $mainImage
      seo: $seo
      quantities: $quantities
      isPhysical: $isPhysical
      shipping: $shipping
      price: $price
      trackQuantity: $trackQuantity
      realPrice: $realPrice
      comparingPrice: $comparingPrice
      description: $description
      SKU: $SKU
      gallery: $gallery
      # slug: $slug
      barcode: $barcode
      published: $published
      publishAt: $publishAt
      variants: $variants
      tags: $tags
      # type: $type
      brand: $brand
      categories: $categories
      doContinueOutOfStock: $doContinueOutOfStock
      options: $options
      digitalLink: $digitalLink
    ) {
      id
    }
  }
`;
/**graphql mutation for getting the signed URL from s3 */
export const SIGNED = gql`
  mutation SIGNED($data: [S3Input!]!) {
    signS3ForImages(data: $data) {
      url
      signedRequest
    }
  }
`;
/**
 * renderComponent
 * @component
 * @name addCompanyModal
 * @memberof PRODUCTS
 * @summary admin can add company
 * @return {React} React component view
 */
const AddProduct = () => {
  const [addProduct, ProductProps] = useMutation(ADD_PRODUCT);
  const { t } = useTranslation();

  const validateMessages = {
    required: t("customers.required"),
  };

  // const { data, loading, error } = useQuery(STATES);
  const [profit, setProfit] = useState(null);
  const [profitMargin, setProfitMargin] = useState(null);
  const [trackQuantity, setTrackQuantity] = useState([]);
  const [isPhysical, setIsPhysical] = useState(false);
  const [digital, setDigital] = useState(false);

  const [hasVariants, setHasVariants] = useState([]);
  const [options, setOptions] = useState([]);
  const [images, setImages] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEn, setIsEn] = useState(true);
  const [slug, setSlug] = useState({ ar: "", en: "" });
  const [mainImage, setMainImage] = useState(null);
  const [moment, setMoment] = useState(undefined);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { data: currencyData, loading: loadingCurrency } = useQuery(CURRENT_CURRENCY, {
    fetchPolicy: "cache-first",
  });
  const route = useHistory();

  // const [signS3, signProps] = useMutation(SIGNED);

  // const [variants, setVariants] = useState([]);
  const [seoTitle, setSeoTitle] = useState({});
  const [description, setDescription] = useState("");
  const [descriptionAr, setDescriptionAr] = useState("");
  const { updateActivation } = useActivation();
  const [form] = Form.useForm();

  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    if (options && options.some((option) => option?.value?.length)) {
      let dat = [];
      // const loop = (data, key, callback) => {
      //   for (let i = 0; i < data.length; i++) {
      //     if (data[i].key === key) {
      //       return callback(data[i], i, data);
      //     }
      //     if (data[i].children) {
      //       loop(data[i].children, key, callback);
      //     }
      //   }
      // };

      const cleanOptions = options.filter(({ value }) => {
        console.log("AddProduct -> value", value);

        return value && value.length > 0;
      });
      // const cleanOptions = [...options];
      // eslint-disable-next-line no-unused-expressions
      cleanOptions[0]?.value.map((option, i) =>
        cleanOptions[1]?.value?.length > 0
          ? cleanOptions[1].value.map((val, index) =>
              cleanOptions[2]?.value?.length > 0
                ? cleanOptions[2].value.map((val3, index3) =>
                    dat.push({
                      key: `${index}${i}${index3}`,
                      valid: true,
                      options: [
                        { [cleanOptions[0].label]: option },
                        { [cleanOptions[1].label]: val },
                        { [cleanOptions[2].label]: val3 },
                      ],
                    }),
                  )
                : dat.push({
                    key: `${index}${i}`,
                    valid: true,
                    options: [
                      { [cleanOptions[0].label]: option },
                      { [cleanOptions[1].label]: val },
                    ],
                  }),
            )
          : dat.push({
              key: i,
              valid: true,
              options: [{ [cleanOptions[0].label]: option }],
            }),
      );

      setDataSource(dat);
      // setVariants(
      //   new Array(
      //     options.reduce(
      //       (n, o) => (o?.value?.length ? o.value.length * n : n),

      //       1
      //     )
      //   ).fill({})
      // );
    }
  }, [options]);

  /**
   * @description admin can add new company from this modal
   */

  const onFinish = async (variables) => {
    const {
      gallery,
      // type,
      brand,
      categories,
      shipping,
      quantities = {},
    } = variables;
    console.log("🚀 ~ file: add.js ~ line 211 ~ onFinish ~ variables", variables);
    console.log("onFinish -> quantities", quantities);

    console.log("onFinish -> variables", variables);
    setLoading(true);

    if (gallery) {
      console.log("onFinish -> gallery", gallery);

      var finalGallery = await uploadGallery(gallery);
      console.log("onFinish -> finalGallery", finalGallery);
    }

    // if (gallery) {
    //   var finalGallery = [];

    //   const uploadToS3 = async (file, signedRequest, url, fileName) => {
    //     const options = {
    //       headers: {
    //         "Content-Type": file.type,
    //       },
    //     };

    //     await axios
    //       .put(signedRequest, file, options)
    //       .then((res) => {
    //         const ext = url.split(".").pop();
    //         const toJPEG = url.replace(ext, "jpeg");
    //         console.log("onFinish -> toJPEG", toJPEG);

    //         finalGallery.push({ image: toJPEG });
    //       })

    //       .catch((err) => {
    //         console.log("onFinish -> err", err);
    //       });
    //   };
    //   const prepareToSign = images.map((image) => {
    //     // console.log("onFinish -> image", image);
    //     // const imgExt = image.type.split("/").pop();
    //     // const filename = `image.${imgExt}`;
    //     return {
    //       filename: image.name,
    //       filetype: image.type,
    //     };
    //   });
    //   const response = await signS3({
    //     variables: { data: prepareToSign },
    //   });

    //   const signed = response.data.signS3ForImages;
    //   console.log("onFinish -> signed", signed);
    //   await Promise.all(
    //     signed.map(async ({ signedRequest, url }, index) => {
    //       await uploadToS3(images[index], signedRequest, url);
    //     })
    //   );
    // }
    if (variables.variants) {
      var variants = Object.keys(variables.variants).map((vari) => {
        // console.log("onFinish -> vari.spl);", vari.split(","));
        const { quantities, price } = variables.variants[vari];
        const selectedOption = dataSource.find((sou) => makeLabelOption(sou.options) == vari);

        const selectedOptions = selectedOption.options.map((op) => ({
          option: parseInt(Object.keys(op)[0]),
          value: Object.values(op)[0].value,
        }));

        return {
          quantities: Object.keys(quantities)
            .filter((location) => quantities[location])
            .map((location) => ({
              quantity: quantities[location],
              location: Number(location) || undefined,
            })),
          price,
          available: selectedOption.valid,
          selectedOptions,
        };
      });
    }
    const getShipping = () => {
      if (shipping) {
        const { weight, length, height, width } = shipping;
        return {
          ...shipping,
          weight: Number(weight),
          length: Number(length),
          height: Number(height),
          width: Number(width),
        };
      }
    };
    try {
      const product = await addProduct({
        variables: {
          ...variables,
          quantities: Object.keys(quantities)
            .filter((location) => quantities[location])
            .map((location) => ({
              quantity: quantities[location],
              location: Number(location) || undefined,
            })),
          mainImage: mainImage?.uid,
          publishAt: moment ? new Date(moment) : undefined,
          shipping: getShipping(),
          trackQuantity: variables.trackQuantity?.includes("trackQuantity"),
          title: form.getFieldValue("title"),
          slug: form.getFieldValue("slug"),
          options: variables.options
            ?.map((opt) => {
              console.log("opt", opt);
              return opt?.label && opt?.value?.length > 0
                ? { id: opt.label, values: opt.value.map(({ value }) => value) }
                : null;
            })
            .filter((opt) => opt),
          description: {
            en: description,
            ar: descriptionAr,
          },
          variants,
          brand: brand && { id: brand },
          // type: type && { id: type },
          categories: categories?.map(({ value }) => ({ id: value })),
          doContinueOutOfStock: variables.trackQuantity?.includes("outOfStock"),
          gallery: finalGallery,
          digitalLink: variables.digitalLink,
        },
        // refetchQueries: [{ query: PRODUCTS, variables: { filter: {} } }],
      });

      // notification.success({ message: "product added successfully" });
      route.push(`/product/${product.data.addProduct.id}`);
      updateActivation.increaseProduct();
      SuccessNotification({
        defaultTitle: t("products.successDefaultMessage"),
        title: t("products.successProductMessage"),
        type: "success",
      });
    } catch (error) {
      // errorNotification({
      //   defaultTitle: t("products.errorProductMessage"),
      //   title: t("products.errorProductMessage"),
      //   type: "error",
      // });
      console.log("AddProduct -> error", error);
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <Container>
      <Form
        validateMessages={validateMessages}
        name="addProduct"
        form={form}
        className="login-form"
        initialValues={{
          available: 0,
          quantities: { 0: 1 },
          published: true,

          shipping: {
            weight: 0,
            length: 0,
            height: 0,
            width: 0,

            weightUnit: "kg",
            distanceUnit: "m",
          },
        }}
        onFinish={onFinish}
        labelCol={{ span: 24 }}
        // labelAlign="left"
        onValuesChange={(val) => {
          console.log("AddProduct -> val", val);
          console.log("AddProduct -> valuess", form.getFieldsValue());
          val.title && setSeoTitle({ ...seoTitle, ...val.title });

          setImages(form.getFieldValue("gallery"));
          // val.title?.en &&
          //   form.setFieldsValue({
          //     slug: {
          //       ...form.getFieldValue("slug"),
          //       en: makeSlug(val?.title?.en),
          //     },
          //   });
          // val.title?.ar &&
          //   form.setFieldsValue({
          //     slug: {
          //       ...form.getFieldValue("slug"),
          //       ar: makeSlug(val?.title?.ar),
          //     },
          //   });
          val.title && setSlug({ ...slug, ...val.title });

          val.slug && setSlug({ ...slug, ...val.slug });
          // form.setFieldsValue({
          //   slug: {
          //     ...form.getFieldValue("slug"),
          //     en: makeSlug(val?.slug?.en),
          //   },
          // });
          // val.slug?.ar &&
          // form.setFieldsValue({
          //   slug: {
          //     ...form.getFieldValue("slug"),
          //     ar: makeSlug(val?.slug?.ar),
          //   },
          // });

          setOptions(form.getFieldValue("options"));
          setTrackQuantity(form.getFieldValue("trackQuantity"));
          setIsPhysical(form.getFieldValue("isPhysical"));

          setHasVariants(form.getFieldValue("hasVariants"));

          if (form.getFieldValue("price") && form.getFieldValue("realPrice")) {
            setProfit(form.getFieldValue("price") - form.getFieldValue("realPrice"));
            setProfitMargin(
              ((form.getFieldValue("price") - form.getFieldValue("realPrice")) /
                form.getFieldValue("price")) *
                100,
            );
          } else {
            setProfit(null);
            setProfitMargin(null);
          }
        }}>
        <AddEditStickyContainer
          children={
            <Row justify="space-between">
              <Col>
                <BackButton text={t("products.products")} url={"/products"} />
                <h2 style={{ marginLeft: "20px", fontWeight: "bold" }}>
                  {t("products.addProduct")}
                </h2>
              </Col>
              <Col style={{ textAlign: "right" }}>
                <Space size={23}>
                  {/* <Button
              type="text"
              htmlType="submit"
              style={{
                display: "flex",
                alignItems: "center",
                gap: 5,
                fontWeight: 600
              }}
              icon={<MyImage src={productPreview} width={14} />}>
              {t("products.productPreview")}
            </Button> */}

                  <Button type="text" onClick={() => setIsModalVisible(true)}>
                    {t("products.schedular")}
                    <ClockCircleOutlined />
                  </Button>
                  {isModalVisible && (
                    <Schedular
                      defaultMomentValue={moment}
                      setMoment={setMoment}
                      isEdit={false}
                      page="product"
                      currrentMoment={moment}
                      setIsModalVisible={setIsModalVisible}
                      isModalVisible={isModalVisible}
                    />
                  )}
                  <Button
                    type="primary"
                    loading={loading || loadingCurrency}
                    disabled={loading || loadingCurrency}
                    htmlType="submit"
                    onClick={() => {
                      scrollOnError();
                    }}>
                    {t("products.save")}
                  </Button>
                </Space>
                {/* <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={getTags}
          >
            getTags
          </Button> */}

                {/* <Typography.Text>
            {isEn ? t("products.switchEnDetail") : t("products.switchArDetail")}
          </Typography.Text>
          <Switch
            style={{ marginTop: "20px" }}
            checkedChildren="AR"
            unCheckedChildren="EN"
            onChange={() => setIsEn(!isEn)}
          /> */}
              </Col>
            </Row>
          }
        />
        {/* <Prompt
        when={true}
        
        message={(location) =>
          `Are you sure you want to go to ${location.pathname}`
        }
      /> */}
        <Row gutter={16}>
          <Col span={24} md={16}>
            <TitleCard
              isEn={isEn}
              description={description}
              setDescription={setDescription}
              descriptionAr={descriptionAr}
              setDescriptionAr={setDescriptionAr}
              slug={slug}
              form={form}
              pageName="products"
              setIsEn={setIsEn}
            />
            {!loadingCurrency && (
              <PriceCard
                profitMargin={profitMargin}
                profit={profit}
                currency={currencyData?.setting?.currencyBase}
              />
            )}
            <QuantityCard trackQuantity={trackQuantity} />
            <ProductisDigitalCard digital={digital} setDigital={setDigital} form={form} />
            {!digital && <ShippingCard isPhysical={isPhysical} />}
            {!loadingCurrency && (
              <VariantCard
                form={form}
                hasVariants={hasVariants}
                options={options}
                dataSource={dataSource}
                setDataSource={setDataSource}
                currency={currencyData?.setting?.currencyBase}
              />
            )}
            <SeoCard
              form={form}
              type="product"
              defaultSeoValue={{ title: seoTitle, body: { en: description, ar: descriptionAr } }}
            />
          </Col>
          <Col span={24} md={8}>
            <PublishSwitchComponent />
            <FormCard style={{ padding: "16px 0" }}>
              <Space direction="vertical" size={16} style={{ width: "100%", padding: "0 16px" }}>
                <h2>{t("products.mainImage")}</h2>
                <Typography.Title level={4} className="upload-img">
                  {t("products.productImage")}
                </Typography.Title>
                <ChooseImg
                  setChossingImage={setMainImage}
                  // defaultImage={mainImage}
                  //  mainImage={}
                  // addImage={addMainImage}
                  // visible,
                  // setVisible,
                  // firstImages,
                />
                <Media images={images} />
              </Space>
            </FormCard>
            <Organization mainImage={mainImage} form={form} />
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default AddProduct;

const Container = styled.div`
  .back-btn {
    font-size: 18px;
    font-weight: 600;
    color: ${(props) => props.theme.customColors.primary};
    padding: 0;
    margin-bottom: 14px;
  }
`;
