import { isNumber } from "lodash";
import { Tooltip } from "antd";
export const GetTextWithPlus = (text: string) =>
  isNumber(Number(text)) && Number(text) > 0 ? `${text}+` : text;

export const truncate = (textSource: string, size: number) => {
  return textSource.length > size ? textSource.slice(0, size - 1) + "…" : textSource;
};
export const withToolTip = (title: string, component: any, maxLength = 15) => {
  return title.length > maxLength ? (
    <Tooltip title={title} trigger="hover">
      {" "}
      {component}
    </Tooltip>
  ) : (
    component
  );
};

export const datesAreOnSameDay = (first, second) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();
