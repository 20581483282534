import {
  Button,
  Card,
  Divider,
  Space,
  Spin,
  Typography,
  Form,
  Input,
  Select,
  Row,
  Col,
  Tag,
  Badge,
  Avatar,
  Dropdown,
  Menu,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  RightOutlined,
  LeftOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  GlobalOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { from, gql, useMutation, useQuery } from "@apollo/client";
import { CURRENT_USER } from "../../../utlis/Me";
import { domain } from "process";
import { varDomain } from "../../../modules/common/defaults";
import { authMutation } from "../../../modules/auth/resolvers";
import { SIGN_OUT } from "../../../components/layout";

import { Breakpoints } from "../../../constants";
import { useResponsive } from "../../../lib/useResponsive";
import whiteLogo from "../../../assets/imgs/signup/whiteLogo.svg";
import bg from "../../../assets/imgs/signup/BG.png";
import homeIcon from "../../../assets/imgs/shops/home.png";

import MyImage from "../../../components/common/image";
import i18n from "../../../i18n";
import logo from "../../../assets/imgs/navBarLogo.svg";
import user from "../../../assets/imgs/user.svg";
import logImg from "../../../assets/imgs/log-img.svg";
import { changeShop } from "../../../utlis/changeShop";

const { Text, Paragraph } = Typography;
const { Option } = Select;
const DIGITAL_AUTH = gql`
  mutation DIGITAL_AUTH {
    digitalAuth {
      id
    }
  }
`;
const Shops = () => {
  const { data, loading, refetch } = useQuery(CURRENT_USER, {
    // fetchPolicy: "cache-first"
  });
  const router = useHistory();
  const { t } = useTranslation();

  const [signOut] = useMutation(SIGN_OUT, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: CURRENT_USER }],
  });
  const { xl, md } = useResponsive();
  useEffect(() => {
    if (data?.me?.stores[0]?.domain && !varDomain()) {
      varDomain(data?.me?.stores[0]?.domain);
      // router.push("/");
    }
    if (data?.me?.stores?.length == 0) {
      // varDomain(data?.me?.stores[0]?.domain);
      router.push("/createStore");
    }
    if (!loading && !data.me) {
      router.push("/signin");
    }
  }, [data]);
  const changeLanguageHandler = () => i18n.changeLanguage(i18n?.language === "ar" ? "en" : "ar");

  if (loading || !data.me) return <Spin />;
  const menu = (
    <Menu selectedKeys={[]}>
      <Menu.Item onClick={() => router.push("/myProfile")} icon={<MyImage src={user} width={12} />}>
        <Text style={{ margin: "0 10px", fontWeight: 600 }}>{t("home.profilPage")}</Text>
      </Menu.Item>

      <Menu.Item
        onClick={() =>
          signOut().then(() => {
            authMutation.signOut();
            // client.cache.reset();
            // router.push("/signin");
          })
        }
        danger
        icon={<MyImage src={logImg} width={12} />}>
        {t("home.logout")}
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <Container>
        <Row
          gutter={xl ? [124, 0] : md ? [30, 0] : [0, 10]}
          // gutter={[124, 0]}
          style={{ marginRight: 0, marginLeft: 0 }}
          // align="middle"
          justify="space-between">
          <Col md={{ span: 14 }} xs={{ span: 24 }} style={{ paddingTop: 70 }}>
            <Space
              direction="vertical"
              size={"large"}
              style={{
                width: "100%",
              }}>
              <MyImage
                // onClick={() => history.push("https://www.b7r.store/")}
                // onClick={() => {
                //   window.location.replace(b7rPlatformUrl);
                // }}
                // src="https://g.top4top.io/p_197000xqg1.png"
                src={logo}
                alt="logo"
                wrapperStyle={{ textAlign: "center" }}
                style={{
                  width: md ? 0 : 108,
                  display: "unset",
                  cursor: "pointer",
                }}
              />
              <Card style={{ maxWidth: 655, margin: "0 auto" }}>
                <Space direction="vertical" size={"large"} style={{ width: "100%" }}>
                  <Row justify="space-between">
                    <Col>
                      <Dropdown
                        overlay={menu}
                        trigger={["click"]}
                        overlayClassName="user-dropdown"
                        arrow={true}
                        overlayStyle={{ width: 200, height: 140 }}>
                        <Button type="text" style={{ padding: 0 }}>
                          <Space>
                            <Avatar src={data?.me?.avatar?.sm}>
                              {data?.me.name?.split(" ")[0]?.charAt(0).toUpperCase()}
                              {data?.me.name?.split(" ")[1]?.charAt(0).toUpperCase()}
                            </Avatar>
                            <DownOutlined style={{ fontSize: "12px" }} />
                          </Space>
                        </Button>
                      </Dropdown>
                      {/* <Button
                        danger
                        onClick={() => {
                          signOut().then(() => {
                            varDomain(null);
                            router.push("/signin");
                          });
                        }}>
                        <Space>sign out</Space>
                      </Button> */}
                    </Col>
                    {/* <Col>
                      <Button
                        icon={<GlobalOutlined />}
                        type="text"
                        style={{ padding: 0 }}
                        onClick={changeLanguageHandler}>
                        {i18n.language === "ar" ? "EN" : "العربية"}
                      </Button>
                    </Col> */}
                  </Row>
                  <Row style={{ marginBottom: 20 }} justify="space-between">
                    <Col>
                      <Text style={{ fontSize: 24 }} strong>
                        المتاجر
                      </Text>
                    </Col>
                    <Col>
                      <Button
                        type="primary"
                        onClick={() => {
                          // varDomain(domain);
                          router.push("/createStore");
                        }}>
                        <Space>
                          إضافة متجر جديد
                          <PlusOutlined />
                        </Space>
                      </Button>
                    </Col>
                  </Row>
                </Space>

                <Space style={{ width: "100%" }} direction="vertical">
                  {data?.me?.stores.map(({ name, domain, status }) => {
                    return (
                      <Card
                        onClick={async () => {
                          changeShop(domain);

                          // varDomain(domain);
                          // await refetch();
                          // router.push("/");
                        }}
                        style={{ width: "100%", cursor: "pointer" }}
                        bodyStyle={{ padding: "10px 10px" }}>
                        <Row align="middle" justify="space-between">
                          <Col>
                            {/* <MyImage
                            src={googleIcon}
                            alt="logo"
                            style={{
                              width: 50,
                              display: "unset",
                              cursor: "pointer",
                            }}
                          /> */}
                            <Space align="center">
                              <MyImage src={homeIcon} alt="logo" />
                              <Space size={0} direction="vertical">
                                <Text style={{ fontSize: 18 }} strong>
                                  {name}
                                </Text>
                                <a target={"_blank"} href={"https://" + domain}>
                                  <Text style={{ fontSize: 14 }}> {domain}</Text>
                                </a>
                              </Space>
                            </Space>
                          </Col>
                          <Col>
                            {status == "OPEN" ? (
                              <Tag style={{ borderRadius: "20px" }} color={"success"}>
                                نشط
                              </Tag>
                            ) : (
                              <Tag style={{ borderRadius: "20px" }}>غير نشط</Tag>
                            )}
                          </Col>
                        </Row>
                      </Card>
                    );
                  })}
                  {/* <Divider /> */}
                </Space>
              </Card>
            </Space>
          </Col>
          <Col
            md={{ span: 10 }}
            xs={{ span: 0 }}
            style={{
              paddingTop: 70,
              backgroundImage: `url(${bg})`,
              // paddingLeft: "162px",
              position: "fixed",
              left: i18n.language !== "ar" ? undefined : "0",
              right: i18n.language !== "en" ? undefined : "0",

              top: "0",
              height: "100%",
              width: "100%",
              backgroundPosition: "center" /* Center the image */,
              backgroundRepeat: "no-repeat" /* Do not repeat the image */,
              backgroundSize:
                "cover" /* Resize the background image to cover the entire container */,
            }}>
            <MyImage
              src={whiteLogo}
              alt="logo"
              wrapperStyle={{
                position: "absolute",
                top: "50%",
                transform: "translate(50%, -50%)",
                right: "50%",
                width: "200px",
              }}
              style={{
                // width: md ? 108 : 60,
                display: "unset",
                cursor: "pointer",
              }}
            />
            {/* <MyImage width={570} src="/static/images/signup/BG.png" /> */}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Shops;

const Container = styled.div``;
