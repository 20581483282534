import React, { useEffect, useRef } from "react";
// import JoditEditor from "jodit-react";
import styled from "styled-components";
import JoditEditor from "./DraftJsHtmlEditor";
// import JoditEditor from "jodit-react-ts";
// import "jodit/build/jodit.min.css";

// import { Editor } from "react-draft-wysiwyg";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import Dante, { defaultProps } from "Dante2";
// const Example = ({ content, update, ...props }) => {
//   //   const [content, update] = useState("");
//   const editor = useRef(null);

//   useEffect(() => {
//     if (editor) console.log("SSSeditor", { editor })
//   }, [editor])

//   return (
//     <Container>
//       {/* <Dante
//         {...defaultProps}
//         {...props}
//         // style={{ ...props?.style }}
//         ref={editor}
//         content={content}
//         tooltips={tooltips.filter((a, i) => i > 2)}
//         onChange={(editor) => {
//           update(editor);
//           console.log("editor content: ", editor.emitSerializedOutput());
//         }}
//       /> */}
//       {/* <Editor
//         onEditorStateChange={(a) => {
//           console.log("🚀 ~ file: joditEditor.js ~ line 31 ~ Example ~ a", a);
//         }}
//       /> */}

//       <JoditEditor
//         ref={editor}
//         value={content}
//         config={{
//           readonly: false, // all options from https://xdsoft.net/jodit/play.html
//           // buttons: ["source", "bold", "italic"]
//           // language: 'zh_cn',
//           toolbarButtonSize: "large",
//           uploader: {
//             url: "https://api.cloudinary.com/v1_1/dp5tdylzs/image/upload", //your upload api url
//             insertImageAsBase64URI: true, // not inster base64
//             imagesExtensions: ["jpg", "png", "jpeg", "gif"],

//             //headers: {"token":`${db.token}`},
//             filesVariableName: function (t) {
//               console.log("🚀 ~ file: joditEditor.js ~ line 48 ~ Example ~ t", t);
//               return "files[" + t + "]";
//             }, //"files",
//             // withCredentials: false,
//             // pathVariableName: "path",
//             // format: "json",
//             // method: "POST",
//             // prepareData: function (formdata) {
//             //   formdata.append("file", formdata);
//             //   formdata.append("upload_preset", "storeB7r");
//             //   formdata.append("multiple", true);
//             //   console.log("🚀 ~ file: joditEditor.js ~ line 55 ~ Example ~ formdata", formdata);
//             //   return formdata;
//             // },
//             // isSuccess: function (e) {
//             //   console.log("🚀 ~ file: joditEditor.js ~ line 58 ~ isSuccessisSuccess ~ e", e);
//             //   // debugger;
//             //   return e.success;
//             // },
//             // getMessage: function (e) {
//             //   console.log("🚀 ~ file: joditEditor.js ~ line 62 ~ getMessagegetMessage  ~ e", e);
//             //   return void 0 !== e.data.messages && Array.isArray(e.data.messages)
//             //     ? e.data.messages.join("")
//             //     : "";
//             // },
//             // process: function (resp) {
//             //   console.log("🚀 ~ file: joditEditor.js ~ line 67 ~ Example ~ resp", resp);

//             //   //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
//             //   let files = [];
//             //   files.unshift(resp.data);
//             //   return {
//             //     files: resp.data,
//             //     error: resp.msg,
//             //     msg: resp.msg,
//             //   };
//             // },
//             // error: function (thisIs, e) {
//             //   console.log("🚀 ~ file: joditEditor.js ~ line 94 ~ Example ~ e", e?.message);
//             //   console.log(
//             //     "🚀 ~ file: joditEditor.js ~ line 94 ~ Example ~ thisIs",
//             //     thisIs?.message,
//             //   );
//             //   thisIs?.j?.e.fire("errorMessage", e.message, "error", 4000);
//             // },
//             // defaultHandlerSuccess: function (resp) {
//             //   console.log("🚀 ~ file: joditEditor.js ~ line 94 ~ Example ~ resp", resp);

//             //   // `this` is the editor.
//             //   const j = editor;
//             //   // debugger;
//             //   if (resp.files && resp.files.length) {
//             //     const tagName = "img";
//             //     resp.files.forEach((filename, index) => {
//             //       //edetor insertimg function
//             //       const elm = j.createInside.element(tagName);
//             //       elm.setAttribute("src", filename);
//             //       j.s.insertImage(elm, null, j.o.imageDefaultWidth);
//             //     });
//             //   }
//             // },
//             // defaultHandlerError: function (thisIs, e) {
//             //   console.log("🚀 ~ file: joditEditor.js ~ line 94 ~ Example ~ e", e);
//             //   console.log("🚀 ~ file: joditEditor.js ~ line 94 ~ Example ~ thisIs", thisIs);
//             //   thisIs?.j?.e.fire("errorMessage", e?.message);
//             // },
//             // contentType: function (e) {
//             //   console.log("🚀 ~ file: joditEditor.js ~ line 97 ~ Example ~ e", e);
//             //   console.log("🚀 ~ file: joditEditor.js ~ line 100 ~ Example ~ editor", editor);

//             //   return (
//             //     (void 0 === editor?.ownerWindow?.FormData || "string" == typeof e) &&
//             //     "application/x-www-form-urlencoded; charset=UTF-8"
//             //   );
//             // },
//           },
//         }}
//         //onChange={updateContent}
//         onChange={(w) => { }}
//         onBlur={(newContent) => {
//           console.log("Exampsssssssssssssssssssle -> newContent", newContent);

//           update(newContent);
//         }} // preferred to use only this option to update the content for performance reasons
//       />
//     </Container>
//   );
// };

// const Container = styled.div`
//   .jodit-wysiwyg {
//     background-color: #fff;
//     max-height: 600px;
//   }
//   .jodit-status-bar-link {
//     display: none;
//   }
// `;

export default JoditEditor;
