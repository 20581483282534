import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import * as enTranslations from "./locales/en";
import * as arTranslations from "./locales/ar";

const resources: any = {
  ar: { translation: arTranslations },
  // en: { translation: enTranslations },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    // whitelist: ["ar", "en"],
    // fallbackLng: ["ar", "en"],
    whitelist: ["ar"],
    fallbackLng: ["ar"],
    interpolation: {
      escapeValue: false,
    },
    resources,
  });

export default i18n;
