import React, { useState, useEffect, useReducer } from "react";

import { Button, Space, Tag, Input, Radio } from "antd";

import { Link, useHistory } from "react-router-dom";
import qs from "qs";
import { SearchOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

export const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px",
};
export const handlePrismaFilter = (key, { filterVal, getValue, numperFilter }) => {
  switch (key) {
    case "arrayR":
      return getValue
        ? filterVal.some.id.in
        : filterVal?.length
        ? {
            some: { id: { in: filterVal.map((val) => Number(val)) } },
          }
        : undefined;
    case "singleR":
      return getValue
        ? filterVal.slug.in
        : filterVal?.length
        ? { slug: { in: filterVal } }
        : undefined;
    case "mText":
      if (!filterVal) return;

      return getValue
        ? filterVal.OR[0].ar.contains
        : {
            OR: [
              { ar: { contains: filterVal, mode: "insensitive" } },
              { en: { contains: filterVal, mode: "insensitive" } },
            ],
          };

    case "text":
      if (!filterVal) return;

      return getValue ? filterVal.contains : { contains: filterVal, mode: "insensitive" };
    case "enum":
      if (!filterVal) return;

      return getValue ? filterVal.equals : { equals: filterVal };

    case "inventory":
      if (!filterVal) return;
      if (filterVal == "notTracking") return { trackQuantity: { equals: false } };

      const stockStatus =
        filterVal == "inStock" ? "gt" : filterVal == "outOfStock" ? "equals" : "lt";
      return {
        OR: [
          {
            variants: {
              some: {
                quantityInLocations: {
                  some: {
                    quantity: {
                      [stockStatus]: 0,
                    },
                  },
                },
              },
            },
          },
          {
            quantityInLocations: {
              some: {
                quantity: {
                  [stockStatus]: 0,
                },
              },
            },
          },
        ],
      };

    default:
      break;
  }
};
export default function useFilter(pagination, setPagination, reducer, reducerMap) {
  const {
    location: { pathname, search },
    push,
  } = useHistory();
  const query = qs.parse(search, {
    ignoreQueryPrefix: true,
  });

  const handleParams = (q) => {
    if (q === "reset") {
      push({
        pathname,
      });
      return;
    }
    push({
      pathname,
      search: qs.stringify({ ...query, ...q, page: 1 }, { arrayFormat: "comma", encode: false }),
    });
  };

  const [queryFilter, dispatch] = useReducer(reducer(handleParams), {});

  const [filterValues, setFilterValues] = useState({});

  const { t } = useTranslation();

  useEffect(() => {
    setFilterValues(queryFilter);
  }, [queryFilter]);
  const handleSearch = (selectedKeys, confirm, type) => {
    console.log("🚀 ~ file: useFilter.js ~ line 112 ~ handleSearch ~ type", type);
    confirm();
    dispatch({
      type: reducerMap[type].name,
      selectedKeys,
    }); // push({
    //   pathname: r.route,
    //   query: { ...urlQ, page: 1 },
    // });
    // setCatSearch(selectedKeys);
  };
  const onResetButtonClick = () => {
    setPagination({ ...pagination, current: 1 });
    setFilterValues({});
    dispatch({ type: "RESET_FILTER" });
  };

  const handleReset = (clearFilters, type) => {
    clearFilters();
    // onResetButtonClick();

    dispatch({
      type: reducerMap[type].name,
      selectedKeys: undefined,
    });
  };

  const ResetAllFilters = (
    <Button style={{ marginBottom: 20 }} onClick={onResetButtonClick}>
      {t("analytics.resetFilters")}
    </Button>
  );

  const getSelectFilter = (FilterSelect, type) => {
    return {
      filteredValue: query[type],
      //queryFilter[type]
      // ? handlePrismaFilter(
      //     reducerMap[type].filterType,
      //     queryFilter[type],
      //     true
      //   )
      // : null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <FilterSelect
            // ref={(node) => {
            //   searchInput = node;
            // }}
            initialSlugs={queryFilter[type]}
            value={selectedKeys}
            style={{ width: 188, marginBottom: 8, display: "block" }}
            onChange={(val) => {
              setSelectedKeys(val || []);
            }}
            checkable
          />

          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, type)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}>
              {t("analytics.search")}
            </Button>
            <Button
              onClick={() => handleReset(clearFilters, type)}
              size="small"
              style={{ width: 90 }}>
              {t("analytics.reset")}
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    };
  };
  const getColumnSearchProps = (type, label) => ({
    ...getSelectFilter(),
    filteredValue: [query[type]],
    //  queryFilter[type]
    //   ? [
    //       handlePrismaFilter(
    //         reducerMap[type].filterType,
    //         queryFilter[type],
    //         true
    //       ),
    //     ]
    //   : null,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={(node) => {
          //   searchInput = node;
          // }}
          placeholder={`${t("analytics.search")}  ${label || type}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => {
            handleSearch(selectedKeys[0], confirm, type);
          }}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              handleSearch(selectedKeys[0], confirm, type);
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}>
            {t("analytics.search")}
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, type)}
            size="small"
            style={{ width: 90 }}>
            {t("analytics.reset")}
          </Button>
        </Space>
      </div>
    ),
  });

  const getColumnRadioProps = (type, statuses) => ({
    filteredValue: [query[type]],
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <Space direction="vertical" style={{ padding: 8 }}>
        <Radio.Group
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          value={selectedKeys[0]}>
          {statuses.map(({ text, value }) => (
            <Radio style={radioStyle} key={value} value={value}>
              {text}
            </Radio>
          ))}
        </Radio.Group>
        {/* <Input
        // ref={(node) => {
        //   searchInput = node;
        // }}
        placeholder={`Search ${type}`}
        value={selectedKeys[0]}
        onChange={(e) => {
          setSelectedKeys(e.target.value ? [e.target.value] : []);
        }}
        onPressEnter={() => {
          handleSearch(selectedKeys[0], confirm, type);
        }}
        style={{ width: 188, marginBottom: 8, display: "block" }}
      /> */}
        <Space>
          <Button
            type="primary"
            onClick={() => {
              handleSearch(selectedKeys[0], confirm, type);
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}>
            {t("analytics.search")}
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, type)}
            size="small"
            style={{ width: 90 }}>
            {t("analytics.reset")}
          </Button>
        </Space>
      </Space>
    ),
  });

  return {
    filterValues,
    query,
    ResetAllFilters,
    // queryFilter,
    dispatch,
    onResetButtonClick,
    // handleReset,
    // handleParams,
    // handleSearch,
    getColumnRadioProps,
    getColumnSearchProps,
    getSelectFilter,
  };
}
