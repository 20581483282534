import React from "react";
import styled from "styled-components";
import { Empty } from "antd";
import NoMessage from "../../../assets/imgs/nomessages.svg";
import WithMessages from "../../../assets/imgs/withmessages.svg";
import { useTranslation } from "react-i18next";
const Container = styled(Empty)`
  width: 100%;
  height: 83vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  border-left: ${(props) =>
      props.theme.i18n.language === "en"
        ? "1px solid #E0E0E0"
        : "unset"};
    border-right: ${(props) =>
      props.theme.i18n.language === "ar"
        ? "1px solid #E0E0E0"
        : "unset"};

    @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
      border-left: 0;
      border-right: 0;
    }
`;
const Des = styled.p<{ withMargin: boolean }>`
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #828282;
  margin-left: ${({ withMargin }) =>
    withMargin ? "-30px" : "unset"};
`;
interface Props {
  noRooms: boolean;
}
const NoSelectedRoom = ({ noRooms }: Props) => {
  const { t } = useTranslation();
  return (
    <Container
      style={{ border: `${noRooms && "0"}`}}
      image={noRooms ? NoMessage : WithMessages}
      imageStyle={{
        width: "300px",
        height: "280px",
      }}
      description={false}>
      <Des withMargin={!noRooms}>
        {noRooms ? t("chat.noMessages") : t("chat.chooseRoom")}
      </Des>
    </Container>
  );
};

export default NoSelectedRoom;
