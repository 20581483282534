import { useQuery } from "@apollo/client";
import React from "react";
import styled from "styled-components";
import { CURRENT_USER } from "../../../../utlis/Me";
import BannerComponent from "./banner";
import PriceCards from "./priceCards";

const Pricing = () => {
  return (
    <Container>
      <BannerComponent />
      <PriceCards />
    </Container>
  );
};

export default Pricing;

const Container = styled.div``;
