import { Button, Col, Form, Input, Row, Select, Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CountriesSelect from "../../../../components/common/selectors/countryRegionsSelectors";
import CurrencySelect from "../../../../components/common/selectors/currencySelect";
import StoreLanguage, { LangValues } from "../../../settings/generals/card/storeLanguage";

const { Title, Text } = Typography;
const { Option } = Select;

const CreateStoreStep1 = ({ domain, setStep, form }) => {
  const { t } = useTranslation();
  const [languages, setLanguages] = useState(LangValues);
  const [isValidate, setIsvalidte] = useState(false);
  console.log("🚀 ~ file: step1.tsx ~ line 15 ~ CreateStoreStep1 ~ isValidate", isValidate);

  const trans = "auth.signUp";

  console.log(
    "🚀 ~ file: step1.tsx ~ line 17 ~ CreateStoreStep1 ~ form.getFieldsValue()",
    form.getFieldValue("shop"),
  );
  return (
    <div>
      <Title level={4} style={{ fontWeight: 700 }}>
        {t("auth.createStore.startBuildYourStore")}
      </Title>
      <Title level={5} style={{ fontWeight: 600, padding: 0 }}>
        {t("auth.createStore.whatYourStoreName")}
      </Title>
      <Form.Item
        label={t("auth.createStore.writeStoreAsCustomerWantSee")}
        name="shop"
        rules={[
          {
            required: true,
            message: t(`${trans}.storeNameError`),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (value && /^[A-Za-z0-9][A-Za-z0-9\-_ ]*$/.test(value)) {
                setIsvalidte(true);
                return Promise.resolve();
              }
              setIsvalidte(false);
              return Promise.reject(new Error(t(`${trans}.storeNameNotValidError`)));
            },
          }),
        ]}>
        <Input />
      </Form.Item>

      <Text className="info">
        {t(`${trans}.useStoreName`)}
        <br />
      </Text>
      <Text className="info" strong style={{ display: "block", marginBottom: 25 }}>
        https://{domain || "myshop"}.b7r.store
      </Text>
      <Title
        level={5}
        style={{
          fontWeight: 600,
          padding: 0,
        }}>
        {t("auth.createStore.whatYourlangCountry")}
      </Title>
      <Form.Item name="shippingCountries" label={t("settings.country")}>
        {
          //@ts-ignore
          <CountriesSelect countriesOnly />
        }
      </Form.Item>

      <Row justify="space-between" gutter={12}>
        <Col sm={12} xs={24}>
          <StoreLanguage languages={languages} setLanguages={setLanguages} />
        </Col>
        <Col sm={12} xs={24}>
          {/* <DefaultLanguage languages={languages} setLanguages={setLanguages} /> */}

          <Form.Item label={t("settings.defaultLang")} name="defaultLanguage">
            <Select>
              {languages?.map((language) => (
                <Option key={language.value} value={language.value}>
                  {language.text}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row justify="space-between" gutter={12}>
        <Col sm={12} xs={24}>
          <Form.Item name="currencies" label={t("settings.storeCurrency")}>
            {
              //@ts-ignore
              <CurrencySelect mode="multiple" />
            }
          </Form.Item>
        </Col>
        <Col sm={12} xs={24}>
          <Form.Item name="currencyBase" label={t("settings.defaultCurrency")}>
            {
              //@ts-ignore
              <CurrencySelect />
            }
          </Form.Item>
        </Col>
      </Row>
      <Form.Item style={{ textAlign: "end" }}>
        <Button
          onClick={() => setStep(2)}
          className="sing-in"
          disabled={isValidate && form.getFieldValue("shop") ? false : true}
          type="primary">
          {t("auth.createStore.next")}
        </Button>
      </Form.Item>
    </div>
  );
};

export default CreateStoreStep1;
