import React, { useState, useRef } from "react";
import { RootContainer, UserAvatarImage, ChangeAvatarButton, AddImage } from "./userAvatar.styles";
import { Spin, Button } from "antd";
import { CURRENT_USER } from "../../../utlis/Me";
import { useMutation, gql } from "@apollo/client";
import { uploadImage } from "../../../utlis/upload";
import { UPDATE_MY_PROFILE } from "../userData";
import { useTranslation } from "react-i18next";
import AddImageBu from "../../../assets/imgs/addImageBu.svg";
import styled from "styled-components";

/**
 * renderComponent
 * @component
 * @summary user Avatar
 * @return {React} React component view
 */
const UserAvatar = ({ img }) => {
  //used ref for the input file

  const { t } = useTranslation();
  const inputRef = useRef();
  const [avatarLoading, setLoading] = useState(false);
  const [updateAvatar, updateProps] = useMutation(UPDATE_MY_PROFILE, {
    refetchQueries: [{ query: CURRENT_USER }],
  });

  // useEffect(() => {
  //   effect;
  // }, [img]);
  /**
   * uploadToS3
   * @param {file} file

   * @description uploading avatar to s3 then updating use avatar
   */
  const upload = async (file) => {
    setLoading(true);

    const image = file ? await uploadImage(file) : null;

    // if (!enImage && !arImage && !editableRecord) {
    //   setLoading(false);

    //   return message.error("must add image");
    // }

    await updateAvatar({
      variables: {
        data: { avatar: image.data },
      },
      refetchQueries: [{ query: CURRENT_USER }],
    });
  };

  /**
   *
   * @param {event} ev
   * @description when user pick a new image file -> get the signed url for uploading this file -> upload to s3 bucket
   */
  const handleUpload = async (ev) => {
    if (ev.target.files[0]) {
      setLoading(true);
      const image = ev.target.files[0];

      await upload(image);
    }
    setLoading(false);
  };
  const triggerClick = () => {
    inputRef.current.click();
  };
  return (
    <RootContainer>
      <UserAvatarImage
        type="upload"
        onClick={triggerClick}
        img={
          img
            ? img.lg + "?" + Math.random()
            : "https://hapigig-assets.s3.amazonaws.com/profile/testUpload"
        }
        avatarLoading={avatarLoading}>
        {(avatarLoading || updateProps.loading) && (
          <Spin style={{ alignSelf: "center", fontSize: 40 }} />
        )}
        {/* <ChangeAvatarButton type="upload" onClick={triggerClick}>
          {t("profile.changePhoto")}
        </ChangeAvatarButton> */}
        <input
          style={{ display: "none" }}
          id="selectImage"
          type="file"
          onChange={handleUpload}
          ref={inputRef}
        />
        <AddImage src={AddImageBu} alt="s" />
      </UserAvatarImage>
    </RootContainer>
  );
};

export default UserAvatar;
