import { useQuery } from "@apollo/client";
import React from "react";
import FeaturesNames from "../enums/featuresNames";
import { CURRENT_USER } from "../utlis/Me";

interface Props {
  children: any;
  feature: FeaturesNames;
}

const HasFeature = ({ children, feature }: Props) => {
  const { data: meData, loading: loadingMe } = useQuery(CURRENT_USER, {
    fetchPolicy: "cache-first",
  });
  if (loadingMe || !meData?.me?.currentShop?.features[feature]) return null;
  //   loadingMe || !meData.me.shop?.features?.seo;
  return <div>{children}</div>;
};

export default HasFeature;
