import styled from "styled-components";
import { Breakpoints } from "../../../../constants";

export const Container = styled.div`
  border-left: ${(props) => (props.theme.i18n.language === "en" ? "1px solid #E0E0E0" : "unset")};
  border-right: ${(props) => (props.theme.i18n.language === "ar" ? "1px solid #E0E0E0" : "unset")};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    border-left: 0;
    border-right: 0;
  }

  .header {
    width: 100%;
    padding: 32px;
    border-bottom: 1px solid ${(props) => props.theme.borderColors.gray};
    margin-bottom: 20px;
    @media (max-width: ${Breakpoints.sm}) {
      padding: 20px 0;
    }
    .header-text {
      font-weight: 700;
      font-size: ${(props) => (props.theme.isBreakpoints.lg ? "24px" : "20px")};
      color: ${(props) => props.theme.colors.black3};
    }
    .ant-image {
      transform: ${(props) => (props.theme.i18n.language == "en" ? "scaleX(-1)" : "unset")};
    }
  }

  .message-container {
    /* height: 100%; */
    height: ${(props) =>
      props.theme.isBreakpoints.lg ? "calc(100vh - 370px)" : "calc(100vh - 290px)"};
    overflow-y: scroll;
    overflow-x: hidden;
    /* overflow: scroll; */
    /* &::-webkit-scrollbar {
      display: none;
    } */
  }

  //client-message-style
  .rce-container-mbox {
    overflow: hidden;
    .rce-mbox {
      /* width: 127px; */
      border-radius: 8px;
      width: fit-content;
      min-width: 100px;
      /* max-width: 85%; */
      background: ${(props) => props.theme.backgroundColors.lightGray};
      height: fit-content;
      /* padding-top: 10px; */
      box-shadow: none;
    }

    .rce-mbox-text.left {
      font-weight: 400;
      font-size: 14px;
      color: ${(props) => props.theme.customColors.black};
    }

    .rce-mbox-left-notch,
    .rce-mbox-time {
      display: none;
    }
  }

  .rce-mbox-text:after {
    display: none;
  }

  //server-message-stlye
  .rce-container-mbox {
    margin-bottom: 24px;
    .rce-mbox.rce-mbox-right {
      border-radius: 8px;
      /* width: 715px; */
      min-width: 100px;
      width: fit-content;
      max-width: 70%;
      background: ${(props) => props.theme.customColors.secondary};
      height: fit-content;
      padding-top: 10px;
    }

    .rce-mbox-text.right {
      font-weight: 400;
      font-size: 14px;
      color: ${(props) => props.theme.customColors.white};
    }

    .rce-mbox-right-notch,
    .rce-mbox-time {
      display: none;
    }
  }

  .rce-mlist {
    @media (max-width: ${Breakpoints.md}) {
      overflow: hidden;
    }
  }
`;
