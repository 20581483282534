import { gql, useMutation, useQuery } from "@apollo/client";
import { CSVLink } from "react-csv";

import { Button, Dropdown, message, Modal, Radio, Space, Typography, Upload } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import exportImg from "../../../../assets/imgs/export.svg";
import importImg from "../../../../assets/imgs/import.svg";
import MyImage from "../../../../components/common/image";
import { Permissions } from "../../../../constants/permissions";
import FeaturesNames from "../../../../enums/featuresNames";
import { isAllowToDoAction } from "../../../../utlis/isAllowToDoAction";
import { CURRENT_USER } from "../../../../utlis/Me";
import ImportPopUp from "./importPopUp";
import { currentHugereqsVar, MY_HUGE_REQS } from "../../../../components/layout/common/hugeReqs";

const makeProductsJSON = (data) => {
  const omitTypename = (key, value) => (key === "__typename" ? undefined : value);
  const newPayload = JSON.parse(JSON.stringify(data), omitTypename);
  return newPayload;
};
export const makeProductsCSV = (data) => {
  // ,
  // ,
  // ,
  // ,
  // ,
  // ,
  // // ,
  // ,
  // weight,
  // ,
  // ,
  // ,
  // ,
  // ,
  // option2_name_ar,
  // option2_name_en,
  // option2_value_ar,
  // option2_value_en,
  // option3_name_ar,
  // option3_name_en,
  // option3_value_ar,
  // option3_value_en,
  // ,
  // ,
  // ,
  // ,
  // return data.map(
  //   ({
  //     SKU,
  //     hasVariants,
  //     isVariant,
  //     title,
  //     description,
  //     quantityInLocations,
  //     categories,
  //     published,
  //     tags,
  //     price,
  //     realPrice,
  //     comparingPrice,
  //     gallery,
  //     parent,
  //     variants,
  //     ...rest
  //   }) => {
  //     // console.log(
  //     //   "🚀 ~ file: importProducts.js ~ line 115 ~ makeProductsCSV ~ rest[hasVariants]",
  //     //   rest[hasVariants ? "options" : "selectedOptions"]
  //     // );

  //     // console.log(
  //     //   rest[hasVariants ? "options" : "selectedOptions"][0]?.values
  //     //     ?.map(({ name }) => name?.ar)
  //     //     .join()
  //     // );
  //     return {
  //       sku: SKU,
  //       has_variants: hasVariants ? "Yes" : "No",
  //       is_variant: isVariant ? "Yes" : "No",
  //       name_ar: title?.ar,
  //       name_en: title?.en,

  //       quantity: quantityInLocations[0]?.quantity,
  //       categories: `"${categories?.map(({ slug }) => slug).join()}"`,
  //       tags: `"${tags?.map(({ name }) => name).join()}"`,
  //       published: published ? "Yes" : "No",
  //       price: comparingPrice || price,
  //       sale_price: comparingPrice ? price : null,
  //       cost: realPrice,
  //       gallery: `"${gallery.map(({ lg }) => lg).join()}"`,
  //       option1_name_ar:
  //         rest[hasVariants ? "options" : "selectedOptions"][0]?.attribute?.name
  //           ?.ar,
  //       option1_name_en:
  //         rest[hasVariants ? "options" : "selectedOptions"][0]?.attribute?.name
  //           ?.en,
  //       option1_name_slug:
  //         rest[hasVariants ? "options" : "selectedOptions"][0]?.attribute?.slug,
  //       option1_value_ar: hasVariants
  //         ? `"${rest.options[0]?.values
  //             ?.map(({ name }) => name?.ar)
  //             .filter((val) => val)
  //             .join()}"`
  //         : rest.selectedOptions[0]?.value?.name?.ar,
  //       option1_value_en: hasVariants
  //         ? `"${rest.options[0]?.values
  //             ?.map(({ name }) => name?.en)
  //             .filter((val) => val)
  //             .join()}"`
  //         : rest.selectedOptions[0]?.value?.name?.en,
  //       option1_value_slug: hasVariants
  //         ? `"${rest.options[0]?.values
  //             ?.map(({ slug }) => slug)
  //             .filter((val) => val)
  //             .join()}"`
  //         : rest.selectedOptions[0]?.value?.slug,

  //       ///////////2222222

  //       option2_name_ar:
  //         rest[hasVariants ? "options" : "selectedOptions"][1]?.attribute?.name
  //           ?.ar,
  //       option2_name_en:
  //         rest[hasVariants ? "options" : "selectedOptions"][1]?.attribute?.name
  //           ?.en,
  //       option2_name_slug:
  //         rest[hasVariants ? "options" : "selectedOptions"][1]?.attribute?.slug,
  //       option2_value_ar: hasVariants
  //         ? rest.options[1]?.values
  //             ?.map(({ name }) => name?.ar)
  //             .filter((val) => val)
  //             .join()
  //         : rest.selectedOptions[1]?.value?.name?.ar,
  //       option2_value_en: hasVariants
  //         ? rest.options[1]?.values
  //             ?.map(({ name }) => name?.en)
  //             .filter((val) => val)
  //             .join()
  //         : rest.selectedOptions[1]?.value?.name?.en,
  //       option2_value_slug: hasVariants
  //         ? rest.options[1]?.values
  //             ?.map(({ slug }) => slug)
  //             .filter((val) => val)
  //             .join()
  //         : rest.selectedOptions[1]?.value?.slug,

  //       ///////////33333333333

  //       option3_name_ar:
  //         rest[hasVariants ? "options" : "selectedOptions"][2]?.attribute?.name
  //           ?.ar,
  //       option3_name_en:
  //         rest[hasVariants ? "options" : "selectedOptions"][2]?.attribute?.name
  //           ?.en,
  //       option3_name_slug:
  //         rest[hasVariants ? "options" : "selectedOptions"][2]?.attribute?.slug,
  //       option3_value_ar: hasVariants
  //         ? rest.options[2]?.values
  //             ?.map(({ name }) => name?.ar)
  //             .filter((val) => val)
  //             .join()
  //         : rest.selectedOptions[2]?.value?.name?.ar,
  //       option3_value_en: hasVariants
  //         ? rest.options[2]?.values
  //             ?.map(({ name }) => name?.en)
  //             .filter((val) => val)
  //             .join()
  //         : rest.selectedOptions[2]?.value?.name?.en,
  //       option3_value_slug: hasVariants
  //         ? rest.options[2]?.values
  //             ?.map(({ slug }) => slug)
  //             .filter((val) => val)
  //             .join()
  //         : rest.selectedOptions[2]?.value?.slug,
  //       parent: parent?.SKU,
  //       variants: `"${variants
  //         ?.map(({ SKU }) => SKU)
  //         .filter((val) => val)
  //         .join()}"`,
  //       // optiond_name_ar:options[1]?.attribute?.name?.ar,
  //       // option2_name_en:options[1]?.attribute?.name?.en,
  //       // option2_slug:options[1]?.attribute?.slug,
  //       // description_ar: description?.ar,
  //       // description_en: description?.en,
  //       description_en: `"${description?.en}"`,
  //       description_ar: `"${description?.ar}"`,
  //     };
  //   }
  // );
  return data.map(
    ({
      SKU,
      hasVariants,
      isVariant,
      title,
      description,
      quantityInStock,
      categories,
      published,
      tags,
      price,
      realPrice,
      comparingPrice,
      gallery = [],
      parent,
      variants,
      mainImage,
      ...rest
    }) => {
      const opt = rest[hasVariants ? "options" : "selectedOptions"];
      const op2 = rest[hasVariants ? "options" : "selectedOptions"];
      const opt3 = rest[hasVariants ? "options" : "selectedOptions"];
      // console.log(
      //   "🚀 ~ file: importProducts.js ~ line 115 ~ makeProductsCSV ~ rest[hasVariants]",
      //   rest[hasVariants ? "options" : "selectedOptions"]
      // );

      // console.log(
      //   rest[hasVariants ? "options" : "selectedOptions"][0]?.values
      //     ?.map(({ name }) => name?.ar)
      //     .join()
      // );
      return {
        sku: SKU,
        has_variants: hasVariants ? "Yes" : "No",
        is_variant: isVariant ? "Yes" : "No",
        name_ar: title?.ar,
        name_en: title?.en,
        mainImage: mainImage?.original,
        // quantity: quantityInLocations[0]?.quantity,
        quantity: quantityInStock,
        categories: categories?.map(({ slug }) => slug).join(),
        tags: tags?.map(({ name }) => name).join(),
        published: published ? "Yes" : "No",
        price: comparingPrice || price,
        sale_price: comparingPrice ? price : null,
        cost: realPrice,
        gallery: gallery.map(({ original }) => original).join(),
        option1_name_ar: opt ? opt[0]?.attribute?.name?.ar : "",
        option1_name_en: opt ? [0]?.attribute?.name?.en : "",
        option1_name_slug: opt ? [0]?.attribute?.slug : "",
        option1_value_ar: hasVariants
          ? rest?.options[0]?.values
              ?.map(({ name }) => name?.ar)
              ?.filter((val) => val)
              ?.join()
          : rest?.selectedOptions
          ? rest?.selectedOptions[0]?.value?.name?.ar
          : "",
        option1_value_en: hasVariants
          ? rest?.options[0]?.values
              ?.map(({ name }) => name?.en)
              ?.filter((val) => val)
              ?.join()
          : rest?.selectedOptions
          ? rest?.selectedOptions[0]?.value?.name?.en
          : "",
        option1_value_slug: hasVariants
          ? rest?.options[0]?.values
              ?.map(({ slug }) => slug)
              ?.filter((val) => val)
              ?.join()
          : rest?.selectedOptions
          ? rest?.selectedOptions[0]?.value?.slug
          : "",

        ///////////2222222

        option2_name_ar: op2 ? op2[1]?.attribute?.name?.ar : "",
        option2_name_en: op2 ? op2[1]?.attribute?.name?.en : "",
        option2_name_slug: op2 ? op2[1]?.attribute?.slug : "",
        option2_value_ar: hasVariants
          ? rest.options[1]?.values
              ?.map(({ name }) => name?.ar)
              .filter((val) => val)
              .join()
          : rest.selectedOptions
          ? rest.selectedOptions[1]?.value?.name?.ar
          : "",
        option2_value_en: hasVariants
          ? rest.options[1]?.values
              ?.map(({ name }) => name?.en)
              .filter((val) => val)
              .join()
          : rest.selectedOptions
          ? rest.selectedOptions[1]?.value?.name?.en
          : "",
        option2_value_slug: hasVariants
          ? rest.options[1]?.values
              ?.map(({ slug }) => slug)
              .filter((val) => val)
              .join()
          : rest.selectedOptions
          ? rest.selectedOptions[1]?.value?.slug
          : "",

        ///////////33333333333

        option3_name_ar: opt3 ? opt3[2]?.attribute?.name?.ar : "",
        option3_name_en: opt3 ? opt3[2]?.attribute?.name?.en : "",
        option3_name_slug: opt3 ? opt3[2]?.attribute?.slug : "",
        option3_value_ar: hasVariants
          ? rest.options[2]?.values
              ?.map(({ name }) => name?.ar)
              .filter((val) => val)
              .join()
          : rest.selectedOptions
          ? rest.selectedOptions[2]?.value?.name?.ar
          : "",
        option3_value_en: hasVariants
          ? rest.options[2]?.values
              ?.map(({ name }) => name?.en)
              .filter((val) => val)
              .join()
          : rest.selectedOptions
          ? rest.selectedOptions[2]?.value?.name?.en
          : "",
        option3_value_slug: hasVariants
          ? rest.options[2]?.values
              ?.map(({ slug }) => slug)
              .filter((val) => val)
              .join()
          : rest.selectedOptions
          ? rest.selectedOptions[2]?.value?.slug
          : "",
        parent: parent?.SKU,
        variants: variants
          ?.map(({ SKU }) => SKU)
          .filter((val) => val)
          .join(),
        // optiond_name_ar:options[1]?.attribute?.name?.ar,
        // option2_name_en:options[1]?.attribute?.name?.en,
        // option2_slug:options[1]?.attribute?.slug,
        description_en: description?.en?.replace(/"/g, '""'),
        description_ar: description?.ar?.replace(/"/g, '""'),
      };
    },
  );
};
// export const PRODUCTS_CSV = gql`
//   query PRODUCTS_CSV {
//     # ($skip: Int, $filter: ProductWhereInput!)
//     products(take: 99999999) {
//       # (skip: $skip, take: 100, orderBy: { id: desc }, where: $filter)
//       nodes {
//         id
//         SKU
//         hasVariants
//         isVariant
//         title {
//           ar
//           en
//         }
//         description {
//           ar
//           en
//         }
//         quantityInStock
//         # quantityInLocations {
//         #   quantity
//         #   # location {
//         #   #   id
//         #   # }
//         # }

//         categories {
//           slug
//         }
//         tags {
//           name
//         }
//         published
//         price
//         realPrice
//         comparingPrice
//         gallery {
//           original
//           # xl
//           # lg
//           # sm
//           # md
//         }

//         options {
//           attribute {
//             name: mName {
//               ar
//               en
//             }
//             slug
//           }
//           values {
//             name: mName {
//               ar
//               en
//             }
//             slug
//             # value
//           }
//         }
//         selectedOptions {
//           attribute {
//             name: mName {
//               ar
//               en
//             }
//             slug
//           }
//           value: optionValue {
//             name: mName {
//               ar
//               en
//             }
//             slug
//             # value
//           }
//         }

//         parent {
//           SKU
//         }
//         variants {
//           SKU
//         }
//         # available
//         # doContinueOutOfStock
//         # facebookPostId
//         # brandId
//         # deletedAt

//         # shortDescription {
//         #   ar
//         #   en
//         # }

//         # shipping {
//         #   height
//         #   weight
//         #   length
//         #   width
//         #   distanceUnit
//         #   weightUnit
//         # }
//         # isPhysical
//         # barcode
//         # brand {
//         #   name
//         # }

//         # productType
//         # trackQuantity

//         # type {
//         #   name
//         # }
//         # slug {
//         #   ar
//         #   en
//         # }

//         # parentId

//         # selectedOptions {
//         #   attributeId
//         #   optionValueId
//         # }

//         mainImage {
//           original
//           # xl
//           # lg
//           # sm
//           # md
//         }
//       }
//     }
//   }
// `;
export const PRODUCTS_CSV = gql`
  mutation PRODUCTS_CSV($exportProductsType: exportType, $exportProductsWhere: ProductWhereInput) {
    exportProducts(type: $exportProductsType, where: $exportProductsWhere)
  }
`;
export const IMPORT_PRODUCTS = gql`
  mutation IMPORT_PRODUCTS($url: String!) {
    importProducts(url: $url)
  }
`;

const ImportProducts = ({
  variables,
  selectedProducts,
  productsCount,
  //  products = [],
  empty,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);
  const [value, setValue] = useState(1);
  const [csvProducts, setCsvProducts] = useState(null);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setCsvProducts(false);
  };

  const handleCancel = () => {
    setCsvProducts(false);
    setIsModalVisible(false);
  };

  const onRadioChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  const SuccessMessage = () => {
    message.success(t("products.successMessage"));
  };

  const exportProducts = async () => {
    if (value == "all") {
      loadAllRequests({ variables: { exportProductsType: "all" } });
    } else if (value == "filtered") {
      loadAllRequests({
        variables: { exportProductsType: "filter", exportProductsWhere: variables?.filter },
      });
    } else if (value == "selected") {
      const csvData = await loadAllRequests({
        variables: {
          exportProductsType: "ids",
          exportProductsWhere: { id: { in: selectedProducts } },
        },
      });
      setCsvProducts(csvData.data.exportProducts);
      console.log("🚀 ~ file: importProducts.js ~ line 531 ~ exportProducts ~ csvData", csvData);
      console.log(
        "🚀 ~ file: importProducts.js ~ line 513 ~ exportProducts ~ selectedProducts",
        selectedProducts,
      );
    }
    value !== "selected" && handleCancel();
  };

  // const exportDropDown = () => {
  //   return (
  //     <Space direction="vertical">
  //       <Space
  //         size={-15}
  //         align="baseline"
  //         style={{ width: "100%" }}
  //         onClick={() => {
  //           loadAllRequests({ variables: { exportProductsType: "all" } });
  //         }}>
  //         <Button type="text">{t("products.all")}</Button>
  //       </Space>
  //       <Space
  //         size={-15}
  //         style={{ width: "100%" }}
  //         align="baseline"
  //         onClick={() => {
  //           loadAllRequests({
  //             variables: { exportProductsType: "filter", exportProductsWhere: variables.filter },
  //           });
  //         }}>
  //         <Button type="text">{t("products.filter")}</Button>
  //       </Space>
  //       {/* <Space
  //         size={-15}
  //         style={{ width: "100%" }}
  //         align="baseline"
  //         onClick={() => {
  //           loadAllRequests({ variables: { exportProductsType: "ids" } });
  //         }}>
  //         <Button type="text">{t("products.ids")}</Button>
  //       </Space> */}
  //     </Space>
  //   );
  // };

  const [loadAllRequests, { data, loading }] = useMutation(PRODUCTS_CSV, {
    // variables: variables,
    refetchQueries: [
      {
        query: MY_HUGE_REQS,
        variables: { currentHugereqs: currentHugereqsVar().map(({ id }) => id) },
      },
    ],
    onCompleted: () => {
      value !== "selected" && SuccessMessage();
    },
  });
  console.log("🚀 ~ file: importProducts.js ~ line 482 ~ ImportProducts ~ variables", variables);
  console.log("🚀 ~ file: importProducts.js ~ line 480 ~ ImportProducts ~ data", data);
  const [importProducts] = useMutation(IMPORT_PRODUCTS, {
    refetchQueries: [
      {
        query: MY_HUGE_REQS,
        variables: { currentHugereqs: currentHugereqsVar().map(({ id }) => id) },
      },
    ],
  });
  const [csvFile, setCsvFile] = useState(null);
  const { push } = useHistory();
  const { t } = useTranslation();

  const uploadProducts = async (csv) => {
    const data = new FormData();
    data.append("file", csvFile);
    data.append("resource_type", "auto");

    data.append("upload_preset", "jmvsrsep");

    const res = await fetch("https://api.cloudinary.com/v1_1/dp5tdylzs/upload", {
      method: "POST",
      body: data,
    });
    const file = await res.json();
    importProducts({
      variables: {
        url: file.secure_url,
      },
    }).then(() => {
      push("/importReports");
    });

    return false;
  };
  const props = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    accept: ".csv",
    headers: {
      authorization: "authorization-text",
    },
    // beforeUpload: uploadProducts,
    beforeUpload: async (csv) => {
      setCsvFile(csv);
    },
    onChange(info) {
      // if (info.file.status !== "uploading") {
      //   console.log(info.file, info.fileList);
      // }
      // if (info.file.status === "done") {
      //   message.success(`${info.file.name} file uploaded successfully`);
      // }
      // else if (info.file.status === "error") {
      //   message.error(`${info.file.name} file upload failed.`);
      // }
    },
  };
  const {
    data: { me },
  } = useQuery(CURRENT_USER, {
    fetchPolicy: "cache-first",
  });
  const isAllowToImport = isAllowToDoAction(me, Permissions.importProducts);
  const hasFeature = me.currentShop?.features[FeaturesNames.importProducts];
  console.log(
    "🚀 ~ file: importProducts.js ~ line 584 ~ ImportProducts ~ oadingData.data.exportPoducts",
    data,
  );
  let selectedProductCount = selectedProducts?.length;
  // const csvProducts = products?.filter(({ id }) => selectedProducts?.includes(id));

  // console.log(
  //   "🚀 ~ file: importProducts.js ~ line 639 ~ ImportProducts ~ csvProducts",
  //   csvProducts,
  // );

  const disableExport =
    (value === "selected" && selectedProducts?.length === 0) ||
    (productsCount <= 0 && value != "all") ||
    (variables?.filter?.AND.length == 2 && value === "filtered");
  const csvLink = (
    <CSVLink
      filename="products.csv"
      // enclosingCharacter={``}
      // data={makeProductsCSV(csvProducts) || []}
      data={csvProducts || []}>
      <Button type="default" disabled={disableExport}>
        {t("products.downloadFile")}
      </Button>
    </CSVLink>
  );
  const handelImportModal = () => setIsImportModalVisible(false);
  return (
    <Space direction="vertical">
      <Space>
        {/* {isAllowToImport && hasFeature && (
          <Upload {...props}>
            <Button
              type="text"
              // icon={<MyImage src={exportImg} width={16} />}
              // style={{ padding: 0 }}
            >
              <Space align="center">
                <MyImage src={exportImg} width={16} />
                <Typography.Text style={{ margin: "0 7px" }}>
                  {t("products.import")}
                </Typography.Text>
              </Space>
            </Button>
          </Upload>
        )} */}
        {/* ! STOP - TEMP */}
        {/* <MyImage src={productSetting} width={16} style={{ cursor: "pointer" }} /> */}
        {/* <Dropdown
          overlayStyle={{ width: 138 }}
          // overlay={exportDropDown()}
          trigger={["click"]}>
          <Button
            type="text"
            icon={<MyImage src={importImg} width={12} />}
            loading={loading}
            // onClick={loadAllRequests}
          >
            <Typography.Text style={{ margin: "0 7px" }}>{t("products.export")}</Typography.Text>
          </Button>
        </Dropdown> */}
        {/* ! STOP - TEMP */}

        {isAllowToImport && hasFeature && (
          <Button
            type="text"
            // icon={<MyImage src={exportImg} width={16} />}
            // style={{ padding: 0 }}
            onClick={() => {
              setIsImportModalVisible(true);
            }}>
            <Space align="center">
              <MyImage src={exportImg} width={16} />
              <Typography.Text style={{ margin: "0 7px" }}>{t("products.import")}</Typography.Text>
            </Space>
          </Button>
        )}
        {!empty && (
          <Button
            type="text"
            icon={<MyImage src={importImg} width={12} />}
            loading={loading}
            onClick={() => setIsModalVisible(true)}>
            <Typography.Text style={{ margin: "0 7px" }}>{t("products.export")}</Typography.Text>
          </Button>
        )}
      </Space>
      <ImportPopUp
        handleCancel={handelImportModal}
        handleOk={handelImportModal}
        isModalVisible={isImportModalVisible}
        uploadProps={props}
        uploadProducts={uploadProducts}
        csvFile={csvFile}
      />
      <Modal
        title={t("products.exportProducts")}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}>
        <Radio.Group onChange={onRadioChange} value={value}>
          <Space direction="vertical">
            <Radio value={"all"}>{t("products.all")}</Radio>
            <Radio value={"filtered"}>
              {variables?.filter?.AND?.length == 2
                ? t("products.noSuggestedProducts")
                : t("products.suggestedProducts", { productsCount })}
            </Radio>
            <Radio value={"selected"}>
              {t("products.existingProducts")} {selectedProductCount}
            </Radio>
          </Space>
        </Radio.Group>
        <div style={{ textAlign: "end", marginTop: "20px" }}>
          {!(csvProducts && value == "selected") ? (
            <Button
              onClick={exportProducts}
              type="primary"
              loading={loading}
              disabled={disableExport}>
              {t("products.exportProducts")}
            </Button>
          ) : (
            csvLink
          )}
        </div>
      </Modal>
      {/* {csvLink} */}
      {/* <div
        align="center"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}>
        {data && !loading && (
          // <Button
          //   href={`data:text/json;charset=utf-8,${encodeURIComponent(
          //     JSON.stringify(makeProductsJSON(data.products.nodes))
          //   )}`}
          //   download="Products.json"
          // >
          //   {`Download Json`}
          // </Button>
          // <CsvDownloader
          //   filename="products"
          //   //         separator=";"
          //   wrapColumnChar="'"
          //   // columns={columns}
          //   datas={makeProductsCSV(data.products.nodes)}
          //   text="DOWNLOAD"
          // />
          <CSVLink
            filename="products.csv"
            // enclosingCharacter={``}
            data={data.exportProducts || []}>
            <Button type="danger">{t("products.downloadFile")}</Button>
          </CSVLink>
        )}
      </div> */}
    </Space>
  );
};

export default ImportProducts;
