import React, { useState, useEffect, useMemo } from "react";

import { Select, Space, TreeSelect } from "antd";
import { gql, useQuery } from "@apollo/client";
import debounce from "lodash/debounce";
import { getSearchOfproduct } from "../../../pages/orders/add/cards/productsSearch";
import { useTranslation } from "react-i18next";
const { Option } = Select;

export const PRODUCTS_SEARCH = gql`
  query PRODUCTS_SEARCH($where: ProductWhereInput!) {
    products(take: 10, orderBy: { id: asc }, where: $where) {
      count
      nodes {
        id
        slug
        # hasVariants
        title {
          id
          auto
        }
        # mainImage {
        #   id
        #   md
        # }
      }
    }
  }
`;

const { SHOW_ALL } = TreeSelect;

const ProductsSelector = ({ style = {}, mode, checkable, filterId, valueType, ...props }) => {
  const [search, setSearch] = useState("");
  const { t } = useTranslation();

  const { data, loading } = useQuery(PRODUCTS_SEARCH, {
    variables: {
      where: getSearchOfproduct(search),
    },
  });

  return (
    <Select
      {...props}
      style={{ width: "100%", minWidth: 150, ...style }}
      // treeDataSimpleMode
      onSearch={debounce((val) => setSearch(val), 200)}
      showSearch
      loading={loading}
      mode={mode}
      allowClear
      treeCheckable={checkable}
      treeCheckStrictly={checkable}
      // showCheckedStrategy={SHOW_ALL}
      filterOption={false}
      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
      placeholder={t("order.searchProducts")}>
      {data?.products.nodes.map(({ id, title, slug, mainImage }) => (
        <Option key={id} value={valueType == "slug" ? slug : id}>
          {/* <Space>
            <MyImage
              image={mainImage}
              width={50}
              height={50}
              // style={{ width: "20px", height: "20px" }}
            /> */}
          {title.auto}
          {/* </Space> */}
        </Option>
      ))}
    </Select>
  );
};

export default React.memo(ProductsSelector);
