import React, { useState } from "react";
import { Avatar, Button, Space, Typography } from "antd";
import { SelectOutlined, UpOutlined, WalletOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import MyImage from "../../common/image";
import LanguageChanger from "./LanguageChanger";
import userProfile from "../../../assets/imgs/user-profile.svg";
import mdBill from "../../../assets/imgs/mdbill.svg";
import logout from "../../../assets/imgs/logout.svg";
import smallShopsIcon from "../../../assets/imgs/layout/shops2.svg";

import { varDomain } from "../../../modules/common/defaults";
import { useTranslation } from "react-i18next";
import { useResponsive } from "../../../lib/useResponsive";
import { authMutation } from "../../../modules/auth/resolvers";
import wallet from "../../../assets/imgs/wallet.svg";

const { Text } = Typography;

const HiderDrop = ({ me, signOut, client, toggle, hideDrop, closeSider }) => {
  const { t } = useTranslation();
  const history = useHistory();
  console.log("showDropshowDropshowDrop", toggle);

  return (
    <Container>
      <div className={`${toggle ? "show" : "hide"}`}>
        <Space className="avatar-space" onClick={hideDrop}>
          <Avatar src={me?.avatar?.sm}>
            {me.name?.split(" ")[0]?.charAt(0).toUpperCase()}
            {me.name?.split(" ")[1]?.charAt(0).toUpperCase()}
          </Avatar>
          <Space direction="vertical" size={4}>
            <Space>
              {me.name}
              <UpOutlined />
            </Space>
            <div onClick={closeSider}>
              <a
                href={`https://${me?.currentShop?.domain}`}
                target="_blank"
                style={{ color: "#fff", fontSize: 12, wordBreak: "break-all" }}>
                {me?.currentShop?.domain}
                <SelectOutlined style={{ margin: "0 4px" }} />
              </a>
            </div>
          </Space>
        </Space>

        <Space direction="vertical" style={{ padding: "24px 48px" }} className="user-options">
          <div onClick={closeSider}>
            <Button
              type="text"
              icon={<MyImage src={userProfile} width={12} />}
              onClick={() => history.push("/myProfile")}>
              <Text className="menu-text">{t("home.profilPage")}</Text>
            </Button>
          </div>

          <div onClick={closeSider}>
            <Button
              type="text"
              icon={<MyImage src={smallShopsIcon} width={16} />}
              onClick={() => history.push("/shops")}>
              <Text className="menu-text">{t("home.myStores")}</Text>
            </Button>
          </div>
          <div onClick={closeSider}>
            <Button
              type="text"
              // icon={<MyImage width={16} src={wallet} />}
              icon={<WalletOutlined style={{ color: "#fff" }} />}
              onClick={() => history.push("/addToBalance")}>
              <Text className="menu-text">{t("users.electronicWallet")}</Text>
            </Button>
          </div>

          <div onClick={closeSider}>
            <Button
              type="text"
              icon={<WalletOutlined style={{ fontSize: 15, color: "#fff" }} />}
              onClick={() => history.push("/addToBalance")}>
              <Text className="menu-text">{t("home.wallet")}</Text>
            </Button>
          </div>

          <div onClick={closeSider}>
            <Button
              type="text"
              icon={<MyImage src={mdBill} width={14} />}
              onClick={() => history.push("/invoices")}>
              <Text className="menu-text">{t("home.subscAndInvoices")}</Text>
            </Button>
          </div>

          {/* <LanguageChanger /> */}

          <div onClick={closeSider}>
            <Button
              type="text"
              icon={<MyImage src={logout} width={12} />}
              onClick={() =>
                signOut().then(() => {
                  authMutation.signOut();
                  // client.cache.reset();
                  // history.push("/signin");
                })
              }>
              <Text className="menu-text">{t("home.logout")}</Text>
            </Button>
          </div>
        </Space>
      </div>
    </Container>
  );
};

const Container = styled.div`
  .show,
  .hide {
    background: ${(props) => props.theme.customColors.primary};
    position: absolute;
    z-index: 999;
    top: 0;
    animation-duration: 0.45s;
    animation-iteration-count: ease-in;
    animation-fill-mode: both;
  }

  .show {
    animation-name: show;
  }
  .hide {
    animation-name: hide;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 0.33s, opacity 0.33s linear;
  }

  @keyframes show {
    0% {
      top: -228px;
    }
    100% {
      top: 0px;
    }
  }

  @keyframes hide {
    0% {
      top: 0;
      opacity: 1;
    }
    100% {
      top: -228px;
      opacity: 1;
    }
  }

  .avatar-space {
    width: 100%;
    padding: 16px;
    color: ${(props) => props.theme.customColors.white};
    border-bottom: 1px solid ${(props) => props.theme.borderColors.gray3};
  }
  .user-options {
    padding: 24px 48px;
    .ant-btn {
      padding: 0;
    }
    .menu-text {
      margin: 0 10px;
      font-weight: 700;
      color: ${(props) => props.theme.customColors.white};
    }
  }
`;

export default HiderDrop;
