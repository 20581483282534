import { GET_CURRENCY_SETTINGS } from "../../pages/settings/generals";
import { SETTINGS } from "../../pages/settings/generals/card/defaultLanguage";
import { CURRENT_USER } from "../../utlis/Me";
import { varClient, varCurrency, varDomain } from "./defaults";

export const commonMutation = {
  getStart: async (client) => {
    try {
      client = client || varClient();

      const { data } = await client.query({ query: CURRENT_USER });
      console.log("🚀 ~ file: resolvers.js ~ line 6 ~ getStart: ~ data", data);
      if (data.me?.currentShop?.domain) {
        await client.query({
          query: SETTINGS,
          context: {
            headers: {
              shop: data.me?.currentShop.domain,
            },
          },
        });
        // varDomain(data.me.store[0].domain);

        const currency = await client.query({
          query: GET_CURRENCY_SETTINGS,
          context: {
            headers: {
              shop: data.me?.currentShop.domain,
            },
          },
        });

        console.log("🚀 ~ file: resolvers.js ~ line 11 ~ data", currency);
        varCurrency(currency.data.setting.currencyBase);
      }
    } catch (error) {
      console.log("🚀 ~ file: resolvers.js ~ line 39 ~ getStart: ~ error", error);
    }
  },
};
