import React from "react";
import {
  Form,
  Input,
  InputNumber,
  Divider,
  Checkbox,
  Typography,
  Space,
  Select,
  Row,
  Col,
} from "antd";
import { FormCard } from "../style";
import { useTranslation } from "react-i18next";
const { Option } = Select;
const prefixWeight = (
  <Form.Item name={["shipping", "weightUnit"]} noStyle>
    <Select
      style={{
        width: 70,
      }}
    >
      <Option value="lb">lb</Option>
      <Option value="oz">oz</Option>
      <Option value="kg">kg</Option>
      <Option value="g">g</Option>
    </Select>
  </Form.Item>
);
const prefixLength = (
  <Form.Item name={["shipping", "distanceUnit"]} noStyle>
    <Select
      style={{
        width: 70,
      }}
    >
      <Option value="in">in</Option>

      <Option value="m">m</Option>
      <Option value="sm">sm</Option>
    </Select>
  </Form.Item>
);
const prefixHeight = (
  <Form.Item name={["shipping", "distanceUnit"]} noStyle>
    <Select
      style={{
        width: 70,
      }}
    >
      <Option value="in">in</Option>

      <Option value="m">m</Option>
      <Option value="sm">sm</Option>
    </Select>
  </Form.Item>
);
const prefixWidth = (
  <Form.Item name={["shipping", "distanceUnit"]} noStyle>
    <Select
      style={{
        width: 70,
      }}
    >
      <Option value="in">in</Option>

      <Option value="m">m</Option>
      <Option value="sm">sm</Option>
    </Select>
  </Form.Item>
);
const ShippingCard = ({ isPhysical }) => {
  const { t } = useTranslation();
  return (
    <FormCard>
      <h2>{t("products.shipping")}</h2>
      <Form.Item name="isPhysical" valuePropName={"checked"}>
        <Checkbox
          style={{
            lineHeight: "32px",
            width: "100%",
          }}
        >
          {t("products.physicalProduct")}
        </Checkbox>
      </Form.Item>

      {isPhysical && (
        <Space direction="vertical">
          <Typography.Text strong>{t("products.weight")}</Typography.Text>
          <Typography.Text>
            {t("products.calcShipping")}
          </Typography.Text>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item name={["shipping", "weight"]} label={t("products.weight")}>
                <Input type="number" addonBefore={prefixWeight} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["shipping", "length"]} label={t("products.length")}>
                <Input type="number" addonBefore={prefixLength} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["shipping", "height"]} label={t("products.height")}>
                <Input type="number" addonBefore={prefixHeight} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["shipping", "width"]} label={t("products.width")}>
                <Input type="number" addonBefore={prefixWidth} />
              </Form.Item>
            </Col>
          </Row>
        </Space>
      )}
    </FormCard>
  );
};

export default ShippingCard;
