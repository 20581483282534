import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Button, Col, Form, Input, Row, Switch, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FormCard } from "../../../orders/add/style";

const PRODUCT_SETTING = gql`
  query PRODUCT_SETTING($productId: Int) {
    productSetting(productId: $productId) {
      showQuantity
      showExpressCheckout
      showAddToCart
      stickyOnDesktop
      stickyOnMobile
      customerCanAddNote
      customerCanAddAttch
      showFrequentlyBoughtTogether
      showCustomersWhoViewedThisItemAlsoViewed
      showMayLike
    }
  }
`;

const { Title, Text, Paragraph } = Typography;

const ProductSwitchesComponent = ({ id = undefined, form }) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(PRODUCT_SETTING, { variables: { productId: id } });

  const settings = data?.productSetting;

  const leastIncrease = () =>
    form.setFieldsValue({
      leastQuantity: Number(form.getFieldValue("leastQuantity")) + 1,
    });
  const leastDecrease = () =>
    form.setFieldsValue({
      leastQuantity: Number(form.getFieldValue("leastQuantity")) - 1,
    });
  const mostIncrease = () =>
    form.setFieldsValue({
      mostQuantity: Number(form.getFieldValue("mostQuantity")) + 1,
    });
  const mostDecrease = () =>
    form.setFieldsValue({
      mostQuantity: Number(form.getFieldValue("mostQuantity")) - 1,
    });

  const productSettings = [
    {
      label: t("settings.showAddtoCart"),
      name: "showAddToCart",
      desc: t("settings.showAddtoCartInfo"),
    },
    {
      label: t("settings.showOrderDelivery"),
      name: "showExpressCheckout",
      desc: t("settings.showOrderDeliveryInfo"),
    },
    { label: t("settings.showPinPc"), name: "stickyOnDesktop", desc: t("settings.showPinPcInfo") },
    {
      label: t("settings.showPinMobile"),
      name: "stickyOnMobile",
      desc: t("settings.showPinMobileInfo"),
    },
    {
      label: t("settings.activeAffiliate"),
      // name: "affiliate",
      desc: t("settings.activeAffiliateInfo"),
    },
    {
      label: t("settings.customerCanAddNoteTitle"),
      name: "customerCanAddNote",
      desc: t("settings.customerCanAddNote"),
    },
    {
      label: t("settings.customerCanAddAttchTitle"),
      name: "customerCanAddAttch",
      desc: t("settings.customerCanAddAttch"),
    },
    {
      label: t("settings.showMayLike"),
      name: "showMayLike",
      desc: t("settings.showMayLikeDesc"),
    },
    {
      label: t("settings.showBoughtTogether"),
      name: "showFrequentlyBoughtTogether",
      desc: t("settings.showBoughtTogetherDesc"),
    },
    {
      label: t("settings.showCustomersViewed"),
      name: "showCustomersWhoViewedThisItemAlsoViewed",
      desc: t("settings.showCustomersViewedDesc"),
    },
  ];
  useEffect(() => {
    if (settings) form.setFieldsValue({ ...settings });
  }, [settings]);
  if (loading) return null;

  return (
    <Container>
      {/* <Title level={3}>{t("settings.howToFinishPay")}</Title> */}
      {productSettings.map(({ label, name, desc }) => (
        <>
          <Row justify="space-between" gutter={[24, 24]} wrap={false}>
            <Col>
              <Title level={5} className="settings-title">
                {label}
              </Title>
              <Paragraph className="settings-desc">{desc}</Paragraph>
            </Col>
            <Col>
              <Form.Item name={name}>
                <Switch defaultChecked={settings && settings[name]} />
              </Form.Item>
            </Col>
          </Row>
        </>
      ))}
      {/* <Title level={5} className="settings-title">
        {t("settings.productQuantity")}
      </Title>
      <Paragraph className="settings-desc">{t("settings.productQuantityDesc")}</Paragraph>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item
            name="leastQuantity"
            label={<Text className="settings-desc">{t("settings.leastQuantity")}</Text>}
            initialValue={leastValue}>
            <Input
              style={{ padding: 0 }}
              suffix={
                <Button
                  type="text"
                  icon={<PlusOutlined />}
                  className="increase-btn"
                  onClick={leastIncrease}
                />
              }
              prefix={<Button type="text" icon={<MinusOutlined />} onClick={leastDecrease} />}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="mostQuantity"
            label={<Text className="settings-desc">{t("settings.mostQuantity")}</Text>}
            initialValue={mostValue}>
            <Input
              style={{ padding: 0 }}
              suffix={
                <Button
                  type="text"
                  icon={<PlusOutlined />}
                  className="increase-btn"
                  onClick={mostIncrease}
                />
              }
              prefix={<Button type="text" icon={<MinusOutlined />} onClick={mostDecrease} />}
            />
          </Form.Item>
        </Col>
      </Row> */}
      {/* 
          <Space>
            <Typography.Text> </Typography.Text>
            <Form.Item noStyle name="maintenanceMode" label="">
              <Switch className="switch-store" defaultChecked={settings?.showQuantity} />
            </Form.Item>
          </Space> */}
    </Container>
  );
};

export default ProductSwitchesComponent;

const Container = styled.div`
  .increase-btn {
    background-color: ${(props) => props.theme.customColors.lightGray};
  }
`;
