import { Col, Form, Progress, Row, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
// import { RightOutlined, LeftOutlined, GlobalOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { CURRENT_USER } from "../../../utlis/Me";
// import { varDomain } from "../../../modules/common/defaults";
import makeSlug from "slug-arabic";
import { currenciesVals } from "../../settings/generals/card/currencyCard";
// import DefaultLanguage from "../../settings/generals/card/defaultLanguage";
// import CountriesSelect from "../../../components/common/selectors/countryRegionsSelectors";
import creatStoreBackGround from "../../../assets/imgs/shops/grayback.png";
// import Navbar from "../../../components/layout/navbar";
import MyImage from "../../../components/common/image";
import logo from "../../../assets/imgs/navBarLogo.svg";
// import i18n from "../../../i18n";
// import StoreLanguage, { LangValues } from "../../settings/generals/card/storeLanguage";
// import { useResponsive } from "../../../lib/useResponsive";
import { changeShop } from "../../../utlis/changeShop";
import TagManager from "react-gtm-module";
import CreateStoreStep1 from "./steps/step1";
import CreateStoreStep2 from "./steps/step2";
import CreateStoreStep4 from "./steps/step4";
import { Breakpoints } from "../../../constants";
import ProgressStepsComponent from "./steps/progressSteps";
import CreateStoreStep3 from "./steps/step3";
// import CurrencySelect from "../../../components/common/selectors/currencySelect";

const { Text, Title } = Typography;
// const { Option } = Select;
const CREATE_STORE = gql`
  mutation CREATE_STORE(
    $defaultLanguage: LanguageEnum!
    $currencyBase: String!
    $shippingCountries: String!
    $shop: String!
    $currencies: [String!]
    $languages: [String!]
    $haveExp: Boolean
    $whatISell: [String!]
    $themeSlug: String
  ) {
    createStore(
      shop: $shop
      shippingCountries: $shippingCountries
      currencyBase: $currencyBase
      defaultLanguage: $defaultLanguage
      currencies: $currencies
      languages: $languages
      haveExp: $haveExp
      whatISell: $whatISell
      themeSlug: $themeSlug
    ) {
      id
      domain
    }
  }
`;
const UPDATE_CHECKOUT_SETTINGS = gql`
  mutation UPDATE_APPS($apps: [AppsEnum!]!) {
    updateAppsInStore(apps: $apps) {
      apps
    }
  }
`;
// const Langs = ["AR", "EN"];

const CreateStore = () => {
  const [createStore, { loading }] = useMutation(CREATE_STORE, {
    awaitRefetchQueries: true,
  });
  const [updateApps, { loading: updateAppLoading }] = useMutation(UPDATE_CHECKOUT_SETTINGS, {
    refetchQueries: [{ query: CURRENT_USER }],
  });

  const { data: medata, loading: loadingData } = useQuery(CURRENT_USER, {
    // fetchPolicy: "cache-first",
  });
  const [form] = Form.useForm();
  const [step, setStep] = useState(1);
  const { push } = useHistory();
  // const trans = "auth.signUp";
  const { t } = useTranslation();
  const [domain, setDomain] = useState();
  const [currentCurrencies, setCurrentCurrencies] = useState(currenciesVals);
  const [myapps, setMyApps] = useState([]);
  // const [languages, setLanguages] = useState(LangValues);
  // const { md } = useResponsive();
  const shop = domain + ".b7r.store";
  useEffect(() => {
    if (!medata?.me && !loadingData) push("/signin");
  }, [medata]);
  const onFinish = async (values) => {
    step === 4
      ? await createStore({
          variables: values,
          awaitRefetchQueries: true,

          refetchQueries: [
            {
              query: CURRENT_USER,
              context: {
                headers: {
                  shop,
                },
              },
            },
          ],

          // context: {
          //   headers: {
          //     shop,
          //   },
          // },
        })
          .then(({ data }) => {
            changeShop(data?.createStore?.domain || shop);
            TagManager.dataLayer({
              dataLayer: {
                event: "createStore",
                userId: medata?.me?.id || "0",
                shopId: data?.createStore?.id || "0",
                name: medata?.me.name,
                phone: medata?.me.phone,
                email: medata?.me.email,
                shop: data?.createStore?.domain || shop,
                phonePrefix: medata?.me?.phonePrefix,
              },
            });
            // varDomain(data?.createStore?.domain || shop);
            // setStep(1);
            // push("/");
            updateApps({ variables: { apps: myapps } });
          })
          .catch((err) => {
            setStep(1);
            console.log("onFinish -> err", err);
          })
      : setStep(step + 1);
  };
  const onValuesChange = (val) => {
    console.log("editProduct -> val", val);
    typeof val.shop !== "undefined" && setDomain(makeSlug(val.shop));

    const fieldsValues = form.getFieldsValue();
    if (val.languages) {
      if (!val.languages.includes(fieldsValues.defaultLanguage)) {
        form.setFieldsValue({ defaultLanguage: val.languages[0] });
      }
    }
    if (val?.currencies) {
      if (!val?.currencies?.length) {
        const current = [fieldsValues.currencyBase];
        setCurrentCurrencies(
          currentCurrencies.filter(({ val }) => val === fieldsValues.currencyBase),
        );
        return form.setFieldsValue({ currencies: current });
      } else {
        setCurrentCurrencies(currenciesVals);
      }
      if (!val.currencies.includes(fieldsValues.currencyBase)) {
        form.setFieldsValue({ currencyBase: val.currencies[0] });
      }
    }
    if (val?.currencyBase) {
      if (!fieldsValues?.currencies?.includes(val.currencyBase)) {
        form.setFieldsValue({ currencyBase: fieldsValues?.currencies[0] });
      }
    }
    console.log("editProduct -> valuess", fieldsValues);
  };
  // const ref = useRef();
  // const handleKeyUp = (event) => {
  //   if (event.keyCode === 13) {
  //     // @ts-ignore
  //     step === 3 ? ref.current.submit() : setStep(step + 1);
  //   }
  // };

  // const changeLanguageHandler = () => i18n.changeLanguage(i18n?.language === "ar" ? "en" : "ar");
  return (
    <Container>
      <div className="row-container">
        <Form
          // ref={ref}
          // onKeyUp={handleKeyUp}
          name="basic"
          labelCol={{ span: 24 }}
          form={form}
          initialValues={{
            defaultLanguage: "AR",
            languages: ["AR", "EN"],

            shippingCountries: "Egypt",
            currencyBase: "EGP",
            currencies: ["EGP", "SAR"],
            phonePrefix: "20",
            themeSlug: "electropower",
          }}
          onFinish={onFinish}
          onValuesChange={onValuesChange}>
          <div style={step < 3 ? { display: "block" } : { display: "none" }}>
            <Row gutter={[0, 30]} justify="space-between">
              <Col lg={8} md={24}>
                <Space style={{ width: "100%" }} direction="vertical">
                  <MyImage
                    onClick={() => push("/")}
                    src={logo}
                    alt="logo"
                    width={78}
                    height={35}
                    style={{
                      display: "unset",
                      cursor: "pointer",
                    }}
                  />
                  <Title level={2} style={{ fontSize: 24, fontWeight: 700, margin: 0 }}>
                    اكمل إنشاء متجرك
                  </Title>
                  <Text style={{ fontSize: 18, fontWeight: 600 }}>
                    لننهي بعض التفاصيل لاعداد متجرك والانطلاق بسرعه
                  </Text>
                </Space>
              </Col>
              <Col lg={15} md={24}>
                <div className="form-container">
                  <ProgressStepsComponent step={step} />
                  <div style={step === 1 ? { display: "block" } : { display: "none" }}>
                    <CreateStoreStep1 domain={domain} setStep={setStep} form={form} />
                  </div>
                  <div style={step === 2 ? { display: "block" } : { display: "none" }}>
                    <CreateStoreStep2 setStep={setStep} />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div
            className="lunch-step"
            style={step >= 3 ? { display: "inline-flex" } : { display: "none" }}>
            <div>
              <div className="last-steps">
                <ProgressStepsComponent step={step} />
              </div>
            </div>
            <div style={step === 3 ? { display: "block" } : { display: "none" }}>
              <CreateStoreStep3 setStep={setStep} medata={medata} />
            </div>
            <div style={step === 4 ? { display: "block" } : { display: "none" }}>
              <CreateStoreStep4
                setStep={setStep}
                setMyApps={setMyApps}
                loading={loading}
                updateAppLoading={updateAppLoading}
              />
            </div>
          </div>
        </Form>
      </div>
    </Container>
  );
  // return (
  //   <Container>
  //     <div className="row-container">
  //       <Row justify="space-between" style={{ marginBottom: 30 }}>
  //         <Col>
  //           <MyImage
  //             onClick={() => push("/")}
  //             // onClick={() => {
  //             //   // window.location.replace(b7rPlatformUrl);
  //             // }}
  //             // src="https://g.top4top.io/p_197000xqg1.png"
  //             src={logo}
  //             alt="logo"
  //             style={{
  //               width: md ? 108 : 60,
  //               display: "unset",
  //               cursor: "pointer",
  //             }}
  //           />
  //         </Col>
  //         {/* <Col>
  //           <Button icon={<GlobalOutlined />} type="text" onClick={changeLanguageHandler}>
  //             {i18n.language === "ar" ? "EN" : "العربية"}
  //           </Button>
  //         </Col> */}
  //       </Row>
  //       <Row gutter={[0, 20]} justify="space-between">
  //         <Col md={8}>
  //           <Space direction="vertical">
  //             <Typography.Title level={2} style={{ fontSize: 24, fontWeight: 700, margin: 0 }}>
  //               اكمل إنشاء متجرك{" "}
  //             </Typography.Title>
  //             <Typography.Text style={{ fontSize: 18, fontWeight: 600 }}>
  //               لننهي بعض التفاصيل لاعداد متجرك والانطلاق بسرعه
  //             </Typography.Text>
  //           </Space>
  //         </Col>
  //         <Col md={15}>
  //           <Card style={{ maxWidth: 769, margin: "0 auto" }}>
  //             <Form
  //               name="basic"
  //               labelCol={{ span: 24 }}
  //               form={form}
  //               initialValues={{
  //                 defaultLanguage: "AR",
  //                 languages: ["AR", "EN"],

  //                 shippingCountries: "Egypt",
  //                 currencyBase: "EGP",
  //                 currencies: ["EGP", "SAR"],
  //                 phonePrefix: "20",
  //               }}
  //               onFinish={onFinish}
  //               onValuesChange={(val) => {
  //                 console.log("editProduct -> val", val);
  //                 typeof val.shop !== "undefined" && setDomain(makeSlug(val.shop));

  //                 const fieldsValues = form.getFieldsValue();
  //                 if (val.languages) {
  //                   if (!val.languages.includes(fieldsValues.defaultLanguage)) {
  //                     form.setFieldsValue({ defaultLanguage: val.languages[0] });
  //                   }
  //                 }
  //                 if (val?.currencies) {
  //                   if (!val?.currencies?.length) {
  //                     const current = [fieldsValues.currencyBase];
  //                     setCurrentCurrencies(
  //                       currentCurrencies.filter(({ val }) => val == fieldsValues.currencyBase),
  //                     );
  //                     return form.setFieldsValue({ currencies: current });
  //                   } else {
  //                     setCurrentCurrencies(currenciesVals);
  //                   }
  //                   if (!val.currencies.includes(fieldsValues.currencyBase)) {
  //                     form.setFieldsValue({ currencyBase: val.currencies[0] });
  //                   }
  //                 }
  //                 if (val?.currencyBase) {
  //                   if (!fieldsValues?.currencies?.includes(val.currencyBase)) {
  //                     form.setFieldsValue({ currencyBase: fieldsValues?.currencies[0] });
  //                   }
  //                 }
  //                 console.log("editProduct -> valuess", fieldsValues);
  //               }}>
  //               {/* <Typography.Text
  //               style={{
  //                 fontSize: 12,
  //                 color: "#BDBDBD",
  //                 marginBottom: 10,
  //                 display: "block",
  //                 fontWeight: 600,
  //               }}>
  //               الخطوة 0{step ? 2 : 1}/02
  //             </Typography.Text>
  //             <div
  //               style={{
  //                 display: "flex",
  //                 justifyContent: "space-between",
  //               }}>
  //               <span
  //                 style={{
  //                   height: 5,
  //                   display: "inline-block",
  //                   width: "49%",
  //                   backgroundColor: "#2CB87A",
  //                   borderRadius: 8,
  //                 }}
  //               />
  //               <span
  //                 style={{
  //                   height: 5,
  //                   display: "inline-block",
  //                   width: "50%",
  //                   backgroundColor: step ? "#2CB87A" : "#DBF0E7",
  //                   borderRadius: 8,
  //                 }}
  //               />
  //             </div> */}

  //               <Space style={{ width: "100%", marginBottom: 20 }}>
  //                 <div style={{ height: 2, width: "50%", backgroundColor: "#000" }} />
  //                 <div style={{ height: 2, width: "50%" }} />
  //               </Space>
  //               <Typography.Title
  //                 level={2}
  //                 style={{ fontSize: 20, fontWeight: 700, margin: "0 0 20px 0" }}>
  //                 انطلق وابدأ في بناء متجرك الالكتروني{" "}
  //               </Typography.Title>

  //               {!step ? (
  //                 <>
  //                   <Typography.Title
  //                     level={2}
  //                     style={{ fontSize: 16, fontWeight: 600, marginBottom: -5, padding: 0 }}>
  //                     ماهو اسم متجرك؟{" "}
  //                   </Typography.Title>
  //                   <Form.Item
  //                     // label={t(`${trans}.storeName`)}
  //                     label={"اكتب اسم المتجر بالطريقة التي تحب أن يراها جمهورك"}
  //                     name="shop"
  //                     rules={[
  //                       {
  //                         required: true,
  //                         message: t(`${trans}.storeNameError`),
  //                       },
  //                       ({ getFieldValue }) => ({
  //                         validator(_, value) {
  //                           if (value && /^[A-Za-z0-9][A-Za-z0-9\-_ ]*$/.test(value)) {
  //                             return Promise.resolve();
  //                           }
  //                           return Promise.reject(new Error(t(`${trans}.storeNameNotValidError`)));
  //                         },
  //                       }),
  //                     ]}>
  //                     <Input />
  //                   </Form.Item>

  //                   <Text className="info">
  //                     {t(`${trans}.useStoreName`)}
  //                     <br />
  //                   </Text>
  //                   <Text className="info" strong style={{ display: "block", marginBottom: 25 }}>
  //                     https://{domain || "myshop"}.b7r.store
  //                   </Text>
  //                   <Typography.Title
  //                     level={2}
  //                     style={{
  //                       fontSize: 16,
  //                       fontWeight: 600,
  //                       marginBottom: -5,
  //                       padding: 0,
  //                     }}>
  //                     ماهي دولتك ولغتك وعملتك؟{" "}
  //                   </Typography.Title>
  //                   <Form.Item name="shippingCountries" label={t("settings.country")}>
  //                     {
  //                       //@ts-ignore
  //                       <CountriesSelect countriesOnly />
  //                     }
  //                   </Form.Item>

  //                   <Row justify="space-between" gutter={12}>
  //                     <Col sm={12} xs={24}>
  //                       <StoreLanguage languages={languages} setLanguages={setLanguages} />
  //                     </Col>
  //                     <Col sm={12} xs={24}>
  //                       {/* <DefaultLanguage languages={languages} setLanguages={setLanguages} /> */}

  //                       <Form.Item label={t("settings.defaultLang")} name="defaultLanguage">
  //                         <Select>
  //                           {languages?.map((language) => (
  //                             <Option key={language.value} value={language.value}>
  //                               {language.text}
  //                             </Option>
  //                           ))}
  //                         </Select>
  //                       </Form.Item>
  //                     </Col>
  //                   </Row>

  //                   <Row justify="space-between" gutter={12}>
  //                     <Col sm={12} xs={24}>
  //                       <Form.Item name="currencies" label={t("settings.storeCurrency")}>
  //                         <CurrencySelect mode="multiple" />
  //                         {/* <Currency /> */}
  //                         {/* <Select mode="multiple">
  //                           <Option value="EGP">{t("settings.egp")}</Option>
  //                           <Option value="SAR">{t("settings.sar")}</Option>
  //                         </Select> */}
  //                       </Form.Item>
  //                     </Col>
  //                     <Col sm={12} xs={24}>
  //                       <Form.Item name="currencyBase" label={t("settings.defaultCurrency")}>
  //                         <CurrencySelect />
  //                         {/* <DefaultCurrency />
  //                         <Select>
  //                           {currentCurrencies.map((val) => (
  //                             <Option value={val.val}>{val.name}</Option>
  //                           ))}
  //                           <Option value="SAR"> ريال </Option>
  //                           <Option value="EGP"> جنيه </Option>
  //                         </Select> */}
  //                       </Form.Item>
  //                     </Col>
  //                   </Row>

  //                   <Form.Item style={{ textAlign: "end" }}>
  //                     <Button
  //                       htmlType="submit"
  //                       loading={loading}
  //                       disabled={loading}
  //                       // onClick={login}
  //                       // block
  //                       className="sing-in"
  //                       type="primary">
  //                       التالي
  //                     </Button>
  //                   </Form.Item>
  //                 </>
  //               ) : null}
  //             </Form>
  //           </Card>
  //         </Col>
  //       </Row>
  //     </div>
  //   </Container>
  // );
};

const Container = styled.div`
  background: url(${creatStoreBackGround});
  min-height: 100vh;
  padding: 50px 0;

  .form-container {
    padding: 25px 40px;
    background: #ffffff;
    box-shadow: 0px 0px 28px rgba(51, 51, 51, 0.05);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .ant-progress-steps-item {
    width: 25% !important;
    border-radius: 3px;
  }

  .desc {
    font-size: 18px;
    color: ${(props) => props.theme.colors.Gray1};
    margin-bottom: 10px;
    @media (max-width: ${Breakpoints.sm}) {
      font-size: 14px;
    }
  }
  .lunch-step {
    padding: 0 30px;
    display: inline-flex;
    flex-direction: column;
    gap: 30px;
    text-align: center;
  }
  .steps-guid {
    margin-bottom: 0;
    color: ${(props) => props.theme.colors.Gray6};
  }
  label:lang(en) {
    text-align: right;
  }
  .last-steps {
    max-width: 25%;
    margin: 0 auto;
    @media (max-width: ${Breakpoints.md}) {
      max-width: 100%;
    }
  }
`;

export default CreateStore;
