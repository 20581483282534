import theme1 from "../../../../assets/imgs/theme1.png";
import theme2 from "../../../../assets/imgs/theme2.png";
import theme3 from "../../../../assets/imgs/theme3.png";
import theme4 from "../../../../assets/imgs/theme4.png";
import theme5 from "../../../../assets/imgs/theme5.png";
import theme6 from "../../../../assets/imgs/theme6.png";
import theme7 from "../../../../assets/imgs/theme7.png";
import theme8 from "../../../../assets/imgs/theme8.png";
import theme9 from "../../../../assets/imgs/theme9.png";
import theme10 from "../../../../assets/imgs/theme10.png";
import theme11 from "../../../../assets/imgs/theme11.png";
import theme12 from "../../../../assets/imgs/theme12.png";
import theme13 from "../../../../assets/imgs/theme13.png";
import theme14 from "../../../../assets/imgs/theme14.png";
import theme15 from "../../../../assets/imgs/theme15.png";
import theme16 from "../../../../assets/imgs/theme16.png";

export const themes = [
  {
    img: theme1,
    themeName: "أوريسون",
    // show: showTheme,
    // handleShowTheme: showFirstTheme,
    url: "https://flexfurnitures.b7r.store/ar",
    template: "flexFurnitures",
    // statu: "inProgress",
  },
  {
    img: theme2,
    themeName: "فيجو",
    template: "fashionClub",
    // statu: "inProgress",
    url: "https://fashionClub.b7r.store/ar",
  },
  {
    img: theme3,
    themeName: "أميريجو",
    template: "electropower",
    // statu: "inProgress",
    url: "https://electropower.b7r.store/ar",
  },
  {
    img: theme4,
    themeName: "إيفر جرين",
    statu: "inProgress",
    template: "blueOcean",
    url: "https://blueOcean.b7r.store/ar",
  },
  {
    img: theme5,
    themeName: "فوديز",
    template: "resturantTheme",
    // statu: "inProgress",
    url: "https://resturantTheme.b7r.store/ar",
  },
  {
    img: theme6,
    themeName: "رونق",
    template: "rebeauty",
    statu: "inProgress",
    url: "https://rebeauty.b7r.store/ar",
  },
  {
    img: theme7,
    themeName: "تكنو",
    template: "sports-theme",
    // statu: "inProgress",
    url: "https://sports-theme.b7r.store/ar",
  },
  {
    img: theme8,
    themeName: "طازة",
    template: "elbaraka-theme",
    // statu: "inProgress",
    url: "https://elbaraka-theme.b7r.store/ar",
  },
  {
    img: theme9,
    themeName: "اورجانيك",
    url: "https://skin-care-theme.b7r.store/",
    template: "skin-care-theme",
    statu: "inTrial",
  },
  {
    img: theme10,
    themeName: "ناتشورال",
    template: "birds",
    // statu: "inProgress",
    url: "https://birds.b7r.store/ar",
  },
  {
    img: theme11,
    themeName: "فرنتشر",
    template: "home-furniture",
    statu: "inProgress",
    url: "https://home-furniture.b7r.store/ar",
  },
  {
    img: theme12,
    themeName: "نيو لوك",
    template: "newLook",
    statu: "inTrial",
    url: "https://newLook.b7r.store/ar",
  },
  {
    img: theme13,
    themeName: "مودرن",
    template: "fashion-identity",
    statu: "inTrial",
    url: "https://fashion-identity.b7r.store",
  },
  {
    img: theme14,
    themeName: "وايت بورد",
    template: "bookStore",
    statu: "inTrial",
    url: "https://bookStore.b7r.store",
  },
  {
    img: theme15,
    themeName: "ستوك",
    template: "stock-theme",
    statu: "inTrial",
    url: "https://stock-theme.b7r.store",
  },
  {
    img: theme16,
    themeName: "sheapparel",
    template: "sheapparel",
    statu: "inTrial",
    url: "https://sheapparel.b7r.store",
  },
];
