import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Alert } from "antd";

const AlertMessage = ({ message, type = "warning", ...props }) => {
  const renderIcon = (type) => {
    switch (type) {
      case "warning":
        return <ExclamationCircleOutlined style={{ fontSize: 25, color: "#EF8D32" }} />;
      case "success":
        return <CheckCircleOutlined style={{ fontSize: 25, color: "#0EB200" }} />;
      case "info":
        return <ExclamationCircleOutlined style={{ fontSize: 25, color: "#1890FF" }} />;
      case "error":
        return <CloseCircleOutlined style={{ fontSize: 25, color: "#FF4D4F" }} />;
      case "alert":
        return <WarningOutlined style={{ fontSize: 25, color: "#FF4D4F" }} />;
      default:
        break;
    }
  };
  const renderColors = (type) => {
    switch (type) {
      case "warning":
        return "#FFE58F";
      case "success":
        return "#B7EB8F";
      case "info":
        return "#91D5FF";
      case "error":
      case "alert":
        return "#FE5F55";
      default:
        break;
    }
  };
  return (
    <Alert
      message={message}
      showIcon
      style={{ marginBottom: 20, border: `1px solid ${renderColors(type)}`, width: "100%" }}
      icon={renderIcon(type)}
      type={type === "alert" ? "error" : type}
      {...props}
    />
  );
};

export default AlertMessage;
