import { Form, Button, Row, Col, Switch, Spin, Alert, message } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { LeftOutlined, PlusCircleOutlined, RightOutlined } from "@ant-design/icons";
import ProfileCard from "../../apps/app/settings/cards/profile";
import SettingsCard from "./card/SettingsCard";
import CurrencyCard from "./card/currencyCard";
import { varCurrency } from "../../../modules/common/defaults";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import AddEditStickyContainer from "../../../components/layout/header/addEditSticky";
import SuccessNotification from "../../../components/common/successNotification";
import ChangeStoreNameComponent from "./card/storeName";
import { useHistory } from "react-router-dom";
import BackButton from "../../../components/common/backBtn";

// import SettingsCard from "./cards/settings";

const EDIT_SETTINGS = gql`
  mutation EDIT_SETTINGS(
    $email: String
    $facebook: String
    $instagram: String
    $phone: String
    $pinterest: String
    $twitter: String
    $currencyBase: String
    $address: MultiLangText
    $about: MultiLangText
    $title: MultiLangText
    $copyright: MultiLangText
    $shippingCountries: [String!]
    # $id: Int!
    $maintenanceMode: Boolean!
    $defaultLanguage: LanguageEnum
    $languages: [LanguageEnum]
    $currencies: [String!]
  ) {
    updateSettings(
      # where: { id: $id }
      data: {
        copyright: $copyright
        address: $address
        twitter: $twitter
        pinterest: $pinterest
        phone: $phone
        instagram: $instagram
        facebook: $facebook
        email: $email
        shippingCountries: $shippingCountries
        currencyBase: $currencyBase
        maintenanceMode: $maintenanceMode
        defaultLanguage: $defaultLanguage
        languages: $languages
        about: $about
        title: $title
        currencies: $currencies
      }
    ) {
      id
    }
  }
`;
export const SETTINGS = gql`
  query SETTINGS {
    setting(where: { name: { equals: "web" } }) {
      id
      title {
        auto
        ar
        en
        id
      }
      about {
        auto
        ar
        en
        id
      }
      languages
      defaultLanguage
      address {
        id
        en
        ar
      }
      copyright {
        id
        ar
        en
      }
      email
      facebook
      instagram
      phone
      pinterest
      twitter
      maintenanceMode
      currencyBase {
        id
        code
        name
      }
      currencies {
        id
        code
        base
        name
        rate
      }
      shippingCountries {
        id
        name
      }
    }
  }
`;
export const GET_CURRENCY_SETTINGS = gql`
  query GET_CURRENCY_SETTINGS {
    setting(where: { name: { equals: "web" } }) {
      id
      currencyBase {
        id
        code
        name
      }
    }
  }
`;
/**
 * renderComponent
 * @component
 * @name addCompanyModal
 * @memberof PRODUCTS
 * @summary admin can add company
 * @return {React} React component view
 */

const Settings = () => {
  const history = useHistory();
  const [editSettings, SettingsProps] = useMutation(EDIT_SETTINGS, {
    refetchQueries: [{ query: SETTINGS }, { query: GET_CURRENCY_SETTINGS }],
    onCompleted() {
      SuccessNotification({
        defaultTitle: t("products.successDefaultEditMessage"),
        title: t("products.successDefaultEditMessage"),
        type: "success",
      });
    },
  });
  const [currentCurrencies, setCurrentCurrencies] = useState([]);

  const { data, loading } = useQuery(SETTINGS);
  console.log("editProduct -> datasssssss", data);

  const [error, setError] = useState(false);

  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [isEn, setIsEn] = useState(language === "en");

  const validateMessages = {
    required: t("customers.required"),
  };

  const [form] = Form.useForm();
  useEffect(() => {
    console.log(
      "🚀 ~ file: index.js ~ line 179 ~ onFinish ~ data.setting.currencyBase",
      data?.setting?.currencyBase,
    );
    data?.setting && varCurrency(data.setting.currencyBase);
    setCurrentCurrencies(data?.setting?.currencies?.map((c) => c.code));
  }, [data]);
  const onFinish = async (variables) => {
    console.log("var", variables);
    const { shippingCountries } = variables;
    const aboutTi = form.getFieldValue("addressTitle");
    console.log("🚀 ~ file: index.js ~ line 151 ~ onFinish ~ aboutTi", aboutTi);
    console.log("🚀 ~ file: index.js ~ line 121 ~ onFinish ~ variables", variables);
    console.log("🚀 ~ file: index.js ~ line 91 ~ onFinish ~ shippingCountries", shippingCountries);
    // console.log("onFinish -> variables", variables);
    // setLoading(true);

    console.log(
      "🚀 ~ file: index.js ~ line 161 ~ onFinish ~ variables.addressTitle",
      variables.addressTitle,
    );
    if (variables.languages.length > 0) {
      try {
        await editSettings({
          variables: {
            ...variables,
            about: form.getFieldValue("addressDesc"),
            title: form.getFieldValue("addressTitle"),
            defaultLanguage:
              variables.languages.length == 1 ? variables.languages[0] : variables.defaultLanguage,
            id: data.setting.id,
            shippingCountries: shippingCountries?.map(
              ({ value }) => value,
              // .split('-')[0],
            ),
            // gallery: finalGallery,
          },
        });
        console.log(
          "🚀 ~ file: index.js ~ line 179 ~ onFinish ~ data.setting.currencyBase",
          data.setting.currencyBase,
        );
      } catch (error) {
        console.log("editProduct -> error", error);
        // setLoading(false);
      }
    } else {
      console.log("you must add one");
      setError(true);
      message.error(t("settings.languageError"));
    }
    // setLoading(false);
  };

  //   useEffect(() => {
  //     if (data?.setting) {
  //       const { ...rest } = data?.setting;
  //     }
  //   }, [data]);

  if (loading || !data?.setting)
    return (
      <Spin
        style={{
          position: "absolute",

          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    );

  const { title, about, address, copyright, shippingCountries, currencyBase, currencies, ...rest } =
    data.setting;

  let currenciesCode;

  currenciesCode = currencies.map((c) => c.code);
  console.log("currenciesCode", currenciesCode);

  return (
    <Form
      validateMessages={validateMessages}
      name="editProduct"
      form={form}
      className="login-form"
      onFinish={onFinish}
      initialValues={{
        ...rest,
        address: { ar: address?.ar, en: address?.en },
        copyright: { ar: copyright?.ar, en: copyright?.en },
        currencyBase: currencyBase?.code,
        currencies: currenciesCode,
        addressTitle: { ar: title?.ar || "", en: title?.en || "" },
        addressDesc: { ar: about?.ar || "", en: about?.en || "" },

        //  currencyBase

        shippingCountries: shippingCountries.map(({ name }) => ({
          label: name,
          key: `${name}`,
          value: `${name}`,
          // key: `${name}-country`,
          // value: `${name}-country`,
        })),
      }}
      labelCol={{ span: 24 }}
      labelAlign="left"
      onValuesChange={(val) => {
        console.log("editProduct -> val", val);
        const fieldsValues = form.getFieldsValue();
        if (val.languages) {
          if (!val.languages.includes(fieldsValues.defaultLanguage)) {
            form.setFieldsValue({ defaultLanguage: val.languages[0] });
          }
        }
        if (val?.currencies) {
          if (!val?.currencies?.length) {
            const current = [fieldsValues.currencyBase];
            setCurrentCurrencies(current);
            return form.setFieldsValue({ currencies: current });
          } else {
            setCurrentCurrencies(val?.currencies);
          }
          if (!val.currencies.includes(fieldsValues.currencyBase)) {
            form.setFieldsValue({ currencyBase: val.currencies[0] });
          }
        }
        if (val?.currencyBase) {
          if (!fieldsValues?.currencies?.includes(val.currencyBase)) {
            form.setFieldsValue({ currencyBase: fieldsValues?.currencies[0] });
          }
        }
        console.log("editProduct -> valuess", fieldsValues);
      }}>
      <AddEditStickyContainer
        children={
          <Row justify="space-between">
            <Col>
              <BackButton text={t("settings.settings")} url={'/settings'}/>
              <h2 style={{ marginLeft: "20px", fontWeight: "bold" }}>
                {t("settings.editSetting")}
              </h2>
            </Col>
            <Col style={{ textAlign: "right" }}>
              <div>
                <Button
                  type="primary"
                  loading={loading || SettingsProps.loading}
                  disabled={loading || SettingsProps.loading}
                  icon={<PlusCircleOutlined />}
                  htmlType="submit">
                  {t("settings.save")}
                </Button>
              </div>

              {/* <Switch
            style={{ marginTop: "20px" }}
            checkedChildren="AR"
            unCheckedChildren="EN"
            onChange={() => setIsEn(!isEn)}
            checked={isEn}
          /> */}
            </Col>
          </Row>
        }
      />

      <Row gutter={[35, 0]}>
        <Col span={24} md={{ span: 16 }}>
          <Row>
            <Col span={24}>
              <ChangeStoreNameComponent />
            </Col>
            <Col span={24}>
              <ProfileCard type="web" />
            </Col>
          </Row>
        </Col>

        <Col span={24} md={{ span: 8 }}>
          <Row gutter={[0, 12]}>
            <Col span={24}>
              <SettingsCard isEn={isEn} initialValueMaintenanceMode={rest.maintenanceMode} />
            </Col>
            <Col span={24}>
              <CurrencyCard form={form} currentCurrencies={currentCurrencies} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default Settings;
