import { Upload } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import imagePlace from "../../../../assets/imgs/imagePlace.svg";
import upImage from "../../../../assets/imgs/upImage.svg";
import MyImage from "../../../../components/common/image";
interface Props {
  uploadProps;
  // uploadProducts;
  // setCsvFile
}

const UploadCsv = ({ uploadProps,  }: Props) => {
  const { t } = useTranslation();
  const uploaderProps = {
    // listType: "picture-card",
    name: "files",
    // onPreview: handlePreview,
    accept: "csv",
    // showUploadList: {
    //   showDownloadIcon: true,
    //   downloadIcon: "download ",
    //   showRemoveIcon: !uploading,
    //   removeIcon: id ? (
    //     <Popconfirm
    //       title={t("products.undone")}
    //       onConfirm={async () => {
    //         if (id) {
    //           setLoading(true);

    //           await removeImageFromProduct({
    //             variables: { id: parseInt(removeImage.uid) },
    //           });
    //           setLoading(false);
    //         }
    //       }}
    //       okText={t("analytics.yes")}
    //       cancelText={t("analytics.no")}
    //       okType="danger">
    //       <DeleteOutlined />
    //     </Popconfirm>
    //   ) : (
    //     <DeleteOutlined />
    //   ),
    // },

    onRemove: async (file) => {
      //   setRemoveImage(file);
      //   if (id) return false;
    },
    beforeUpload: (file) => {
      //   onChange(file);
      //   return false;
    },
  };
  const theme: any = useTheme();
  const customStyle = {
    color: theme?.customColors?.primary,
  };
  return (
    <div>
      <Upload.Dragger
        // {...uploaderProps}
        {...uploadProps}
        accept="*"
        // listType="text"
        multiple={false}
        maxCount={1}
        style={{
          width: "100%",
          minHeight: "212px",
          marginRight: 0,
          marginTop: "10px",
          // background: "#eceaea78",
          border: "2px dashed #4A94A5",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <p
          className="ant-upload-drag-icon"
          style={{
            marginBottom: "2px",
            ...customStyle,
          }}>
          {/* <InboxOutlined /> */}
          <MyImage src={upImage} width={44} className="ant-upload-drag-icon" />
          <>
            {/* <p className="ant-upload-text">{t("products.clickToUpload")}</p>
                <p className="ant-upload-hint">{t("products.support")}</p> */}
            <p className="ant-upload-hint" style={{ ...customStyle, fontSize: "12px" }}>
              {t("settings.upload")}
            </p>
          </>
        </p>
        {/* {(!images || images.length === 0) && (
            )} */}
      </Upload.Dragger>
    </div>
  );
};

export default UploadCsv;
