import { Button, Card, Space, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import endTrial from "../../../../assets/imgs/endTrial.svg";
import MyImage from "../../../../components/common/image";

const { Title, Text } = Typography;

const FinishTrialComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <Container>
      <Title level={3} className="title">
        {t("plans.renewPlan")}
      </Title>
      <Card className="endTrial-card">
        <Space direction="vertical" style={{ maxWidth: 450 }}>
          <MyImage src={endTrial} width={178} height={109} />
          <Title level={4} className="title">
            {t("plans.finishTrail")}
          </Title>
          {/* <Text>{t("plans.finishTrailDesc")}</Text> */}
          <Text>{t("plans.renewToUseShop")}</Text>
          <Space>
            <Button type="primary" ghost onClick={() => history.push("/upgrade")}>
              {t("plans.paymentPlans")}
            </Button>
            <Button type="primary" onClick={() => history.push("/changePlan")}>
              {t("plans.renewPlan")}
            </Button>
          </Space>
        </Space>
      </Card>
    </Container>
  );
};

export default FinishTrialComponent;

const Container = styled.div`
  .endTrial-card {
    text-align: center;
    background: rgba(110, 187, 197, 0.12);
    border-radius: 8px;
  }
  .title {
    font-weight: 700;
  }
`;
