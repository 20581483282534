import { useMutation, useQuery } from "@apollo/client";
import { Button, InputNumber, notification, Select, Space, Spin, Table, Typography } from "antd";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import MyImage from "../../../components/common/image";
import { Permissions } from "../../../constants/permissions";
import { CURRENT_USER } from "../../../utlis/Me";
import { isAdmin } from "../../../utlis/permission";
import { MY_INVENTORIES } from "../../settings/locations";
import { SINGLE_PRODUCT } from "../products/addEdit/edit";
import defaultImageneed from "../../../assets/imgs/defaultImage.jpg";
import EmptyPage from "../../../components/common/empty";
import noInventory from "../../../assets/imgs/noinventory.svg";
import Loading from "../../../components/common/Loading";
import { DownOutlined } from "@ant-design/icons";

const { Option } = Select;
const Container = styled.div`
  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }
`;
const NewSelector = styled(Select)`
  max-width: 180px;
  font-weight: 600;
  font-size: 16px;

  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }
  .selct-container > div > div > span {
    padding-right: unset !important;
  }

  /* line-height: 34px; */
  .ant-select-selector {
    background-color: transparent !important;
    border: 0px !important;
    border-radius: 0px !important;
    color: white !important;
  }
  .ant-select-selection-item {
    /* color: ${({ theme }) => theme.customColors.secondary}; */
    color: white !important;
    width: 110px;
    text-align: center !important;
    font-weight: 400 !important;
    display: block;
    padding: 0 5px;
  }
  .ant-select-arrow {
    display: none !important;
  }

  .ant-select-open .ant-select-selection {
    outline: 0 !important;
    box-shadow: unset !important;

    border: none !important;
  }

  .ant-select-selection-search {
    background-color: #4a94a5;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    color: white;
    display: inline-block;
    padding: 0 10px !important;
    font-weight: 400 !important;
  }
  .ant-select-selection-item {
    padding-right: 15px !important;
  }
`;
export const INVENTORY = gql`
  query INVENTORY($skip: Int, $id: Int!) {
    products(
      skip: $skip
      take: 10
      orderBy: { id: desc }
      where: {
        productType: { not: { equals: HAS_VARIANT } }
        quantityInLocations: { some: { location: { id: { equals: $id } } } }
        trackQuantity: { equals: true }
      }
    ) {
      count
      nodes {
        id
        title {
          id
          auto
        }
        productType
        parentId
        selectedOptions {
          id
          optionValue {
            id
            name
          }
        }
        mainImage {
          id
          sm
        }
        parent {
          id
          mainImage {
            id
            sm
          }
        }
        quantityInLocation(addressId: $id)
      }
    }
  }
`;
const INVENTORY_LOGS = gql`
  query INVENTORY_LOGS($skip: Int, $where: QuantityInLocationWhereInput) {
    inventoryLogsOfProduct(skip: $skip, take: 10, orderBy: { id: desc }, where: $where) {
      count
      nodes {
        id
        quantity
        # location
        by {
          id
          name
        }
        shippingLineItem {
          id
          orderLineItem {
            id
            orderId
          }
        }
      }
    }
  }
`;
export const CHANGE_QUANTITY = gql`
  mutation CHANGE_QUANTITY($id: Int!, $quantity: Int!, $addressId: Int!) {
    updateProductQuantity(id: $id, quantity: $quantity, addressId: $addressId) {
      id
    }
  }
`;
export const REQUEST_CHANGE_QUANTITY = gql`
  mutation REQUEST_CHANGE_QUANTITY($id: Int!, $quantity: Int!) {
    requestToUpdateProductQuantity(id: $id, quantity: $quantity) {
      id
    }
  }
`;

const ExpandedRowRender = ({ attr }) => {
  const [pagination, setPagination] = useState({ current: 1 });

  const variables = {
    skip: (pagination.current - 1) * 10,
    where: {
      productId: { equals: attr.id },
    },
  };

  const { data, loading } = useQuery(INVENTORY_LOGS, {
    variables,
    onCompleted: (data) => {
      console.log("🚀 ~ file: index.js ~ line 171 ~ Inventory ~ data", data);
      setPagination({
        ...pagination,
        total: data?.inventoryLogsOfProduct?.count || 0,
      });
    },
  });

  const { t } = useTranslation();

  const handleTableChange = (pagination) => {
    setPagination({ ...pagination, current: pagination.current });
  };

  if (loading) return <Spin />;
  const columns = [
    {
      title: "الوصف",
      key: "desc",

      // dataIndex: "quantity",
      render: ({ shippingLineItem, quantity, by }) => (
        <>
          {` تم ${quantity < 0 ? "سحب" : "اضافة"} ${quantity} عن طريق `}{" "}
          {shippingLineItem?.orderLineItem?.orderId ? (
            <Link to={`/order/${shippingLineItem?.orderLineItem?.orderId}`}>
              طلب رقم {shippingLineItem?.orderLineItem?.orderId}
            </Link>
          ) : (
            by?.name || "غير معروف"
          )}
        </>
      ),
    },
    {
      title: t("products.quantity"),
      key: "quantity",

      dataIndex: "quantity",
    },
    {
      title: "رقم الطلب",
      key: "orderId",
      render: ({ shippingLineItem }) => (
        <Link to={`/order/${shippingLineItem?.orderLineItem?.orderId}`}>
          {shippingLineItem?.orderLineItem?.orderId}
        </Link>
      ),
    },
    {
      title: "عن طريق",
      key: "orderId",
      render: ({ by }) => by?.name,
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        onChange={handleTableChange}
        loading={loading}
        dataSource={data?.inventoryLogsOfProduct?.nodes || []}
        pagination={pagination}
      />
    </>
  );
};

export const QuantityChanger = ({
  record,
  variables,
  updateQuantitiesCache,
  selectedInventory,
}) => {
  const [val, setVal] = useState(0);

  const { t } = useTranslation();

  const { data } = useQuery(CURRENT_USER, { fetchPolicy: "cache-first" });
  const refetchQuires = [{ query: INVENTORY, variables }];
  if (data.me?.role !== "CUSTOM" || data.me?.permissions.includes(Permissions.addProduct))
    refetchQuires.push({
      query: SINGLE_PRODUCT,
      variables: {
        id: record.id,
      },
    });
  const [change, { loading: changeLoading }] = useMutation(CHANGE_QUANTITY, {
    refetchQueries: refetchQuires,
  });
  const [request, { loading: requestLoading }] = useMutation(REQUEST_CHANGE_QUANTITY);
  const loading = requestLoading || changeLoading;
  const productTitle = typeof record.title === "string" ? record.title : record.title.auto || "";

  return (
    <Container>
      <Space>
        <InputNumber value={val} onChange={(v) => setVal(v)} />
        <Button
          loading={loading}
          disabled={loading || !val}
          onClick={() => {
            isAdmin(data?.me.role)
              ? change({
                  variables: {
                    id: record.id,
                    quantity: val,
                    addressId: variables.id,
                  },
                }).then((data) => {
                  notification.success({
                    message: t("products.addQuantityToProduct", {
                      val,
                      productTitle,
                      inventoryName: selectedInventory?.name,
                    }),
                    style: {
                      width: "fit-content",
                    },
                  });
                  setVal(0);
                  updateQuantitiesCache &&
                    updateQuantitiesCache({
                      quantity: val,
                      locationId: variables.id,
                    });
                })
              : request({
                  variables: {
                    id: record.id,
                    quantity: val,
                  },
                }).then(() => {
                  notification.success({
                    message: `you requested to add ${val} to ${productTitle}`,
                  });
                  setVal(0);
                });
          }}>
          {t("products.addInventoryValue")}
        </Button>
      </Space>
    </Container>
  );
};

const Inventory = () => {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState({ current: 1 });
  console.log("🚀 ~ file: index.js ~ line 145 ~ Inventory ~ pagination", pagination);
  const [selectedInventory, setSelectedInventory] = useState(null);
  const { data: addressData, loading: addressLoading } = useQuery(MY_INVENTORIES, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setSelectedInventory(
        data.inventories.find((add) => {
          return add.default;
        }),
      );
    },
  });
  const variables = {
    skip: (pagination.current - 1) * 10,
    id: selectedInventory?.id,
  };
  console.log("🚀 ~ file: index.js ~ line 141 ~ Inventory ~ variables", variables);

  const { data, loading } = useQuery(INVENTORY, {
    variables: variables,
    skip: !selectedInventory,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      !firstLoadFinished && setFirstLoadFinished(true);
      console.log("🚀 ~ file: index.js ~ line 171 ~ Inventory ~ data", data);
      setPagination({
        ...pagination,
        total: data?.products?.count || 0,
      });
    },
  });
  console.log("Inventory -> data", data);

  // useEffect(() => {
  //   if (!loading && data)

  // }, [data]);

  const handleTableChange = (pagination) => {
    setPagination({ ...pagination, current: pagination.current });
  };

  console.log("🚀 ~ file: index.js ~ line 255 ~ columns ~ selectedInventory", selectedInventory);
  const columns = () => {
    const cols = [
      {
        title: t("products.product"),
        // align: "center",
        // ...getColumnSearchProps("Companies"),
        render: (record) => (
          <Space>
            <img
              src={record.mainImage?.sm || record.parent?.mainImage?.sm || defaultImageneed}
              width={50}
              height={50}
              style={{
                borderRadius: "50%",
              }}
              alt={record.id}
            />

            <Space direction="vertical" size={0}>
              <Typography.Text strong>
                <Link
                  to={
                    record.productType == "VARIANT"
                      ? `/product/${record.parentId}/variants/${record.id}`
                      : `/product/${record.id}`
                  }>
                  {record.title.auto}
                </Link>
              </Typography.Text>
              <Typography.Text secondary>
                {record?.selectedOptions.map(
                  ({ optionValue }, i) => `${i ? "/" : ""}${optionValue?.name}`,
                )}
              </Typography.Text>
            </Space>
          </Space>
        ),
      },
      {
        title: t("products.inventoryTable"),
        align: "center",

        // ...getColumnSearchProps("Companies"),
        render: (record) => (
          <span
            style={{
              color: record.quantityInLocation > 0 ? "#219653" : "#EB5757",
            }}>{`${record.quantityInLocation} ${t("products.inStock")}`}</span>
        ),
      },
      {
        title: t("products.inventoryValueCol"),
        align: "center",

        // ...getColumnSearchProps("Companies"),
        render: (record) => {
          return (
            <QuantityChanger
              record={record}
              variables={variables}
              selectedInventory={selectedInventory}
            />
          );
        },
      },
    ];

    return cols;
  };
  // const [inventory, setInventory] = useState("Mansoura");
  const [firstLoadFinished, setFirstLoadFinished] = useState(false);

  if (!firstLoadFinished) return <Loading />;

  const isEmpty = data?.products?.count <= 0;

  return (
    <>
      <Space
        style={{
          width: "100%",
          justifyContent: "space-between",
        }}
        align="top">
        <Typography.Title
          level={2}
          style={{
            fontWeight: "bold",
            fontSize: "24px",
            lineHight: "34px",
          }}>
          {t("products.inventory")}
        </Typography.Title>

        {selectedInventory?.id && addressData?.inventories?.length > 1 && (
          <div
            className="selct-container"
            style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <NewSelector
              bordered={false}
              dropdownStyle={{ width: "80%", outline: "unset", border: "unset" }}
              options={
                addressData?.inventories.map((add) => ({
                  ...add,
                  label: add.name,
                  value: add.id,
                })) || []
              }
              value={selectedInventory?.id}
              // style={{ width: 200 }}
              onChange={(val, option) => {
                setPagination({ ...pagination, current: 1 });
                setSelectedInventory(option);
              }}
            />
            <DownOutlined
              className="arrow-icon"
              style={{
                marginRight: "-26px",
                backgroundColor: "#4A94A5",
                padding: "9px 10px",
                color: "white",
                borderTopLeftRadius: "4px",
                borderBottomLeftRadius: "4px",
                fontWeight: "600",
              }}
            />
          </div>
        )}
      </Space>

      {isEmpty ? (
        <EmptyPage
          image={noInventory}
          buttonLink="/products/"
          description={t("products.noInventory")}
          buttonText={t("products.goToProducts")}
        />
      ) : (
        <Table
          scroll={{ x: 1000 }}
          loading={loading || addressLoading}
          rowKey={(record) => record.id}
          columns={columns()}
          size="small"
          bordered
          dataSource={data?.products.nodes}
          onChange={handleTableChange}
          expandable={{
            expandedRowRender: (attr) => <ExpandedRowRender attr={attr} />,
          }}
          pagination={{
            ...pagination,
            showSizeChanger: false,
            hideOnSinglePage: true,
            position: ["bottomCenter"],
          }}
        />
      )}
    </>
  );
};

export default Inventory;
