import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import AlertMessage from "../../common/alertMessage";
import { useLocation } from "react-router-dom";
const ActevationBarAlert = () => {
  const { pathname } = useLocation();
  console.log(pathname, "sdsdsd");
  const { t } = useTranslation();
  return (
    <Container>
      <AlertMessage
        type="alert"
        message={
          <span className="description">
            {pathname !== "/abandonment" ? t("home.avoidFakeStores") : t("home.activateYourStore")}
            <span className="hint-icon">
              <Link to="/addToBalance">
                <span className="add-balance">{" إضافة الرصيد "}</span>
              </Link>
              <Tooltip
                placement="bottomRight"
                title={t("home.addToWallet")}
                color="#FAFAFA"
                overlayInnerStyle={{ color: "#4F4F4F", width: 280, padding: "16px 12px" }}>
                <InfoCircleOutlined className="add-balance" style={{ margin: "0 5px" }} />
              </Tooltip>
            </span>
          </span>
        }
      />
    </Container>
  );
};

export default ActevationBarAlert;

const Container = styled.div`
  .description {
    font-weight: 600;
    color: ${(props) => props.theme.colors.black3};
  }
  .add-balance {
    text-decoration: underline;
    font-weight: 500;
    color: ${(props) => props.theme.customColors.lightBlue};
  }
  .hint-icon {
    cursor: pointer;
    white-space: nowrap;
  }
`;
