import { gql, useMutation } from "@apollo/client";
import { Button, Form, Input, message, Rate, Space, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import MyImage from "../../../../common/image";
import check from "../../../../../assets/imgs/Done.svg";
import send from "../../../../../assets/imgs/sent.svg";

const { Text } = Typography;

const ADD_REVIEW_TO_B7R_NOTIFICATIONS = gql`
  mutation Mutation($addReviewToB7RNotificationId: Int!, $rate: Float!, $comment: String) {
    addReviewToB7rNotification(id: $addReviewToB7RNotificationId, rate: $rate, comment: $comment) {
      goodComment
      badComment
      id
    }
  }
`;

const AddReviewComponent = ({ id }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [addReview, { loading: addReviewLoading }] = useMutation(ADD_REVIEW_TO_B7R_NOTIFICATIONS);
  const onFinish = async (variables) => {
    await addReview({
      variables: {
        ...variables,
        addReviewToB7RNotificationId: parseInt(id),
      },
    });
    message.success({
      icon: <MyImage width={23} height={23} src={check} />,
      content: <Text style={{ margin: "0 4px" }}>{t("home.thanks")}</Text>,
    });
    console.log("🚀 ~ file: addReview.tsx ~ line 32 ~ onFinish ~ variables", variables);
  };
  return (
    <Form onFinish={onFinish} form={form}>
      <Form.Item
        name="rate"
        rules={[{ required: true, message: t("home.notificationRateRequired") }]}>
        <Rate style={{ fontSize: 30 }} />
      </Form.Item>
      <Form.Item name="comment" htmlFor="submit">
        <Input placeholder={t("home.addNotes")} />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" disabled={addReviewLoading} type="text" style={{ float: "left" }}>
          <Space>
            {t("auth.forget.button")}
            <MyImage src={send} width={14} height={14} />
          </Space>
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddReviewComponent;
