import { notification } from "antd";

const SuccessNotification = ({ defaultTitle, title, type }) => {
  switch (type) {
    case "success":
      notification.success({ message: title, style: { minWidth: 320, minHeight: 63 } });
      break;
    default:
      notification.success({ message: defaultTitle, style: { minWidth: 320, minHeight: 63 } });
  }
};

export default SuccessNotification;
