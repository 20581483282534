import { Form, Input, Switch } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FormCard } from "../style";

const ProductisDigitalCard = ({ digital, setDigital, form }) => {
  const { t } = useTranslation();
  const onChange = (checked: boolean) => {
    setDigital(checked);
    // if (checked) {
    //   form.setFieldValue("isPhysical", false);
    // }
  };

  return (
    <Container>
      <FormCard>
        <span className="flex">
          <h2 className="switch-label">{t("products.digitalProduct")} : </h2>
          <Switch onChange={onChange} checked={digital} />
        </span>

        {digital && (
          <Form.Item
            name="digitalLink"
            label={
              <span className="input-label">
                {t("products.productFile")}
                <span className="desc"> {t("products.downloadDigitalProduct")}</span>
              </span>
            }>
            <Input />
          </Form.Item>
        )}
      </FormCard>
    </Container>
  );
};

export default ProductisDigitalCard;

const Container = styled.div`
  .flex {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px 0;
  }
  .switch-label {
    font-weight: 700;
    font-size: 16px;
  }
  .input-label {
    font-weight: 600;
    .desc {
      color: ${(props) => props.theme.colors.Gray1};
    }
  }
`;
