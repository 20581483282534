// import { useEffect, useState } from "react";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
// import { useMediaQuery } from "react-responsive";

export const useResponsive = () => {
  // const xxl = useMediaQuery({
  //   query: "(min-width: 1600px)",
  // });
  // const xl = useMediaQuery({
  //   query: "(min-width: 1200px)",
  // });
  // const lg = useMediaQuery({
  //   query: "(min-width: 992px)",
  // });
  // const md = useMediaQuery({
  //   query: "(min-width: 768px)",
  // });
  // const sm = useMediaQuery({
  //   query: "(min-width: 576px)",
  // });
  // const xs = useMediaQuery({
  //   query: "(max-width: 576px)",
  // });
  //   const [sizes, setSizes] = useState({ xxl, xl, lg, md, sm, xs });
  //   console.log(
  //     "🚀 ~ file: useResponsive.js ~ line 24 ~ useResponsive ~ sizes",
  //     sizes
  //   );

  //   useEffect(() => {
  //     setSizes({ xxl, xl, lg, md, sm, xs });
  //   }, [xxl, xl, lg, md, sm, xs]);
  const data = useBreakpoint();
  return data;
};
