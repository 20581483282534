import React from "react";
import linkCh from "../../../../assets/imgs/linkCh.svg";
import { Button } from "antd";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import MyImage from "../../../../components/common/image";
import { CURRENT_USER } from "../../../../utlis/Me";
import { EyeOutlined } from "@ant-design/icons";

export function PreviewButton({ url }) {
  const { data: meData, loading: loadingMe } = useQuery(CURRENT_USER, {
    fetchPolicy: "cache-first",
  });
  const { t } = useTranslation();
  const domain = meData?.me?.currentShop?.domain;
  console.log("🚀 ~ file: edit.js ~ line 278 ~ EditProduct ~ domain", domain);
  return (
    <Button
      type="text"
      style={
        {
          //  backgroundColor: "red"
        }
      }>
      <a
        href={`https://${domain}${url}`}
        target="_blank"
        rel="noreferrer"
        style={{
          color: "#163663",
          // backgroundColor:"green"
        }}>
        {t("products.preview")} <EyeOutlined />
      </a>
    </Button>
  );
}
