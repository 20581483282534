import React, { useState } from "react";
import { Form, Typography, Switch, Divider, Button } from "antd";
import { FormCard } from "../../../products/products/addEdit/style";
import CountriesSelect from "../../../../components/common/selectors/countryRegionsSelectors";
import CurrencySelect from "../../../../components/common/selectors/currencySelect";
import { useTranslation } from "react-i18next";
import Checkbox from "antd/lib/checkbox/Checkbox";
import styled from "styled-components";
import DeleteStoreModal from "./deleteStoreModal";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  .switch-store {
  }
`;
const TextWrapper = styled.div`
  max-width: 85%;
`;
const CustomText = styled(Typography.Text)`
  color: #828282;
  font-size: 12px;
`;
const SettingsCard = ({ isEn, initialValueMaintenanceMode }) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Container>
      <DeleteStoreModal handleCancel={handleCancel} isModalOpen={isModalOpen} />
      <FormCard>
        <Typography.Title level={4}>{t("settings.settings")}</Typography.Title>
        <Form.Item name="shippingCountries" label={t("settings.shippingLocation")}>
          <CountriesSelect countriesOnly checkable />
        </Form.Item>
        <Wrapper>
          <Typography.Text>{t("settings.activeStore")}: </Typography.Text>
          <Form.Item noStyle name="maintenanceMode">
            <Switch className="switch-store" defaultChecked={initialValueMaintenanceMode} />
          </Form.Item>
        </Wrapper>
        <TextWrapper>
          <CustomText>{t("settings.activeStoreDesc")}</CustomText>
        </TextWrapper>
        <Divider />
        <Wrapper>
          <div>
            <Typography.Title level={5}>{t("settings.deleteStore")}</Typography.Title>
            <Typography.Text>{t("settings.deleteStoreCompletely")}</Typography.Text>
          </div>

          <Button
            onClick={showModal}
            style={{ borderColor: "#1985A1", color: "#1985A1" }}
            size="large">
            {t("settings.deleteStore")}
          </Button>
        </Wrapper>
      </FormCard>
    </Container>
  );
};

export default SettingsCard;
const Container = styled.div``;
