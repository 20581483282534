import { gql } from "@apollo/client";

export const TAGS = gql`
  query TAGS($filter: String) {
    tags(where: { name: { contains: $filter } }, take: 20) {
      nodes {
        id
        name
      }
    }
  }
`;
