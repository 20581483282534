import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Layout,
  Switch,
  Skeleton,
  Space,
  List,
  Typography,
  Popconfirm,
  Row,
  Col,
  Dropdown,
  Menu,
} from "antd";
import { useMutation, gql, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import {
  PlusCircleOutlined,
  RightOutlined,
  LeftOutlined,
  PlusOutlined,
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import MyImage from "../../../components/common/image";
import DisplayAddress from "../../orders/add/cards/displayAddress";
import { useTranslation } from "react-i18next";
import locationImg from "../../../assets/imgs/location-img.svg";
import trash from "../../../assets/imgs/trash.svg";
import styled from "styled-components";
import { Breakpoints } from "../../../constants";
import BackButton from "../../../components/common/backBtn";

export const MY_INVENTORIES = gql`
  query MY_INVENTORIES {
    inventories(orderBy: { id: asc }, take: 20) {
      id
      default
      name
      address {
        state
        country
        city
        phone
      }
    }
  }
`;

export const DELETE_INVENTORY = gql`
  mutation DELETE_INVENTORY($id: Int!) {
    deleteOneInventory(where: { id: $id }) {
      id
    }
  }
`;
export const SET_DEFAULT_INVENTORY = gql`
  mutation SET_DEFAULT_INVENTORY($id: Int!) {
    setDefaultInventory(id: $id) {
      id
    }
  }
`;
const CustomSpace = styled(Space)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  @media (min-width: ${Breakpoints.md}) {
    flex-direction: row;
  }
`;

function useWindowResize(callback: () => void) {
  useEffect(() => {
    callback();
    window.addEventListener("resize", callback);
    return () => {
      window.removeEventListener("resize", () => {
        console.log("event removed");
      });
    };
  }, []);
}

const Locations = () => {
  const [isSmallSize, setIsSmallSize] = useState(false);

  const { data, loading } = useQuery(MY_INVENTORIES);
  console.log("Locations -> data", data);
  const [setDefault] = useMutation(SET_DEFAULT_INVENTORY);
  const [deleteAddress, { loading: deleteLoading }] = useMutation(DELETE_INVENTORY, {
    refetchQueries: [{ query: MY_INVENTORIES }],
  });

  const { t } = useTranslation();
  const router = useHistory();

  const smallResizeCallback = () => {
    const sm = parseInt(Breakpoints.sm.slice(0, -2));
    window.innerWidth >= sm ? setIsSmallSize(true) : setIsSmallSize(false);
  };
  useWindowResize(smallResizeCallback);

  const {
    i18n: { language },
  } = useTranslation();

  return (
    <Container>
      <BackButton text={t('settings.settings')} url='/settings'/>
      <Row justify="space-between" style={{ margin: "10px 0" }}>
        <Col>
          <Typography.Title level={3}>{t("settings.locations")}</Typography.Title>
        </Col>
        <Col>
          <Button
            style={{ width: "100%" }}
            onClick={() => router.push("/settings/locations/new")}
            type="primary"
            icon={<PlusOutlined />}>
            {t("settings.addLocation")}
          </Button>
        </Col>
      </Row>
      <CardContainer>
        <div className="list-container">
          <List
            itemLayout="vertical"
            // size="large"
            grid={{ column: 1 }}
            loading={loading}
            dataSource={data?.inventories || []}
            renderItem={(item: any) => {
              console.log("Locations -> item", item);

              return (
                <>
                  <List.Item>
                    <Card
                      className="location-card"
                      // onClick={() => router.push(`/settings/location/${item.id}`)}
                    >
                      <Row justify="space-between" wrap={false}>
                        <Col>
                          <Space align="start" size={16}>
                            {/* location image */}
                            <MyImage
                              src={locationImg}
                              // width={87}
                              className="location-img"
                              onClick={() => router.push(`/settings/location/${item.id}`)}
                            />
                            {/* space contain the name and default button and adress */}
                            <Space direction="vertical" size={15}>
                              <Space>
                                <Button
                                  onClick={() => router.push(`/settings/location/${item.id}`)}
                                  type="text"
                                  className="location-name">
                                  <Typography.Text className="item-name">
                                    {item.name}
                                  </Typography.Text>
                                </Button>
                                <Col md={0} sm={2} xs={2}>
                                  <Button disabled={item.default} className="disabled-btn">
                                    {item.default ? t("settings.defaultStr") : null}
                                  </Button>
                                </Col>
                              </Space>
                              <div
                                className="info"
                                onClick={() => router.push(`/settings/location/${item.id}`)}>
                                <DisplayAddress
                                  settingLocationHorizontal={isSmallSize}
                                  address={item.address}
                                />
                              </div>
                            </Space>
                          </Space>
                        </Col>
                        <Col md={8} sm={0} xs={0}>
                          <div className="btns">
                            <Button
                              disabled={item.default}
                              // style={{ margin: "0 55px" }}
                              className={item.default ? "disabled-btn" : "default-btn"}
                              onClick={() => {
                                setDefault({
                                  variables: { id: item.id },
                                  refetchQueries: [{ query: MY_INVENTORIES }],
                                });
                              }}>
                              {item.default ? t("settings.defaultStr") : t("settings.setDefault")}
                            </Button>
                            <Popconfirm
                              title={t("settings.deleteLocation")}
                              onConfirm={() =>
                                deleteAddress({
                                  variables: { id: item.id },
                                })
                              }
                              okText={t("posts.yes")}
                              cancelText={t("posts.no")}
                              placement={language == "ar" ? "right" : "left"}
                              disabled={deleteLoading}>
                              <MyImage
                                src={trash}
                                width={28}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  console.log("ssssss");
                                }}
                              />
                            </Popconfirm>
                          </div>
                        </Col>
                        <Col md={0} sm={2} xs={2} style={{ textAlign: "end" }}>
                          <Dropdown
                            overlay={
                              <Menu>
                                <Menu.Item key="0">
                                  <Button
                                    disabled={item.default}
                                    // style={{ margin: "0 55px" }}
                                    className="menu-btn"
                                    type="text"
                                    icon={<EditOutlined />}
                                    onClick={() => {
                                      setDefault({
                                        variables: { id: item.id },
                                        refetchQueries: [{ query: MY_INVENTORIES }],
                                      });
                                    }}>
                                    {item.default
                                      ? t("settings.defaultStr")
                                      : t("settings.setDefault")}
                                  </Button>
                                </Menu.Item>
                                <Menu.Item key="1">
                                  <Popconfirm
                                    title={t("settings.deleteLocation")}
                                    onConfirm={() =>
                                      deleteAddress({
                                        variables: { id: item.id },
                                      })
                                    }
                                    okText={t("posts.yes")}
                                    cancelText={t("posts.no")}
                                    placement={language == "ar" ? "right" : "left"}
                                    disabled={deleteLoading}>
                                    <Button type="text" icon={<DeleteOutlined />}>
                                      {t("settings.delete")}
                                    </Button>
                                  </Popconfirm>
                                </Menu.Item>
                              </Menu>
                            }
                            trigger={["click"]}>
                            <MoreOutlined style={{ fontSize: 24 }} />
                          </Dropdown>
                        </Col>
                      </Row>
                    </Card>
                  </List.Item>
                </>
              );
            }}
          />
        </div>
      </CardContainer>
    </Container>
  );
};

const Container = styled.div`
  .settings {
    font-size: 18px;
    font-weight: 600;
    color: ${(props) => props.theme.customColors.primary};
  }
  /* .list-container {
    background: ${(props) => props.theme.customColors.white};
    border-radius: 8px;
    padding: 23px 0;
  } */
  /* .location-card {
    border: 1px solid ${(props) => props.theme.borderColors.Gray6};
    &:hover {
      background: ${(props) => props.theme.backgroundColors.lightGray};
      cursor: pointer;
    }
    .ant-card-body {
      padding: 27px 28px;
    }
  } */
  /* .top-card {
    background: transparent;
    .ant-card-head {
      border: 0;
    }
    .ant-card-head-title {
      font-weight: 700;
      font-size: 24px;
      color: ${(props) => props.theme.colors.black3};
    }
  } */
  .location-name {
    color: ${(props) => props.theme.colors.black3};
    white-space: pre-line;
    padding: 0;
    font-size: 22px;
    font-size: 600;
    height: 100%;
  }
  .btns {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 20px;
  }
  .info {
    cursor: pointer;
    .address-info {
      .ant-typography {
        font-weight: 400;
        font-size: 18px;
        color: ${(props) => props.theme.colors.Gray2};
      }
    }
  }
  .disabled-btn {
    border-radius: 100px;
    border: 0;
  }
  .default-btn {
    border: 1px solid ${(props) => props.theme.customColors.lightBlue};
    border-radius: 4px;
    color: ${(props) => props.theme.customColors.lightBlue};
    padding: 9px 24px;
  }
  .menu-btn {
  }
  .location-img {
    width: 87px;
    cursor: pointer;
    @media screen and (max-width: ${Breakpoints.sm}) {
      width: 50px;
    }
  }
`;

const CardContainer = styled.div`
  padding: 27px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.customColors.white};
  @media screen and (max-width: ${Breakpoints.sm}) {
    padding: 12px;
    .ant-card-body {
      padding: 12px;
    }
  }
`;

const AddLocationContainer = styled.div`
  @media screen and (max-width: ${Breakpoints.sm}) {
    display: block;
  }
`;

export default Locations;
