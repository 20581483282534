import styled from "styled-components";

export const UpgradeContainer = styled.div`
  /* width: 144px; */
  /* width: 90px; */
  .upgrade {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 9px;
    background: #2cb87a;
    color: ${(props) => props.theme.customColors.white};
    font-size: 14px;
    font-weight: 600;
    border-radius: 4px;
    border: 1px solid #2cb87a;
    .ant-image {
      margin: -1px 5px;
      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        margin: unset;
      }
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      /* width: 80px; */
      height: 26px;
      font-size: 12px;
      font-weight: 400;
      /* padding: 0; */
    }
  }
`;

export const AdvancedContainer = styled.div`
  width: 144px;
  .advanced {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 9px;
    border: 1px solid #2CB87A;
    color: #2cb87a;
    font-weight: 600;
    font-size: 14px;
  }
`;
export const SilverContainer = styled.div`
  width: 144px;
  .silver {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 9px;
    border: 1px solid ${(props) => props.theme.colors.Gray1};
    color: ${(props) => props.theme.colors.Gray1};
    font-weight: 600;
    font-size: 14px;
  }
`;
export const GoldContainer = styled.div`
  width: 144px;
  .gold {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 9px;
    border: 1px solid #ffb025;
    color: #ffb025;
    font-weight: 600;
    font-size: 14px;
  }
`;

export const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: ${(props) => (props.theme.isBreakpoints.lg ? "9px" : "0px")};
  padding-bottom: ${(props) => (props.theme.isBreakpoints.lg ? "15px" : "0px")};
  background: #fff;
`;

export const Logo = styled.img`
  /* height: ${(props) => (props.collapsed ? "23px" : "50px")}; */
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  width: 88px;
`;

export const MdLogo = styled.img`
  /* height: ${(props) => (props.collapsed ? "23px" : "50px")}; */
  width: 72px;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
`;

export const MenuItemContainer = styled.div`
  display: flex;

  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  line-height: 30px;
  padding: 2px 5px;
`;

export const AvatarContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const UserName = styled.span`
  font-size: 16px;
  margin: 5px 0 0 20px;
  color: white;
`;

export const Atag = styled.a`
  color: white !important;
  text-decoration: none;
  text-transform: uppercase;
  &:visited {
    color: white;
  }
`;

export const Label = styled.span`
  line-height: 1.5;
  font-size: 12px;
  font-weight: 400;
  margin-left: 15px;
  text-transform: uppercase;
`;

export const MenuContainer = styled.div`
  .ant-image-img {
    filter: ${(props) => (props.theme.isBreakpoints.lg ? "unset" : "brightness(0%)")};
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: ${(props) => props.theme.customColors.opacityPrimaryHover};
  }
  .ant-menu-item,
  .ant-menu-submenu-title {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .ant-menu-rtl.ant-menu-inline,
  .ant-menu-rtl.ant-menu-vertical,
  .ant-menu.ant-menu-inline,
  .ant-menu-rtl.ant-menu-vertical {
    border: 0;
    background: ${(props) => (props.theme.isBreakpoints.lg ? "#163663" : "#fff")};
    font-weight: 700;
  }
  .ant-menu-item a,
  .ant-menu-item-icon,
  .ant-menu-submenu-title,
  .ant-menu-submenu-arrow {
    color: ${(props) => (props.theme.isBreakpoints.lg ? "#fff" : "#333")};
  }
  .ant-menu-item-disabled a,
  .ant-menu-submenu-disabled a {
    color: ${(props) => props.theme.colors.Gray1} !important;
  }
  .ant-menu-item-selected .ant-menu-item-icon {
    filter: sepia(12) saturate(145) hue-rotate(133deg) brightness(0.6);
  }
  .ant-menu-item-selected a,
  .ant-menu-item-selected a:hover {
    color: ${(props) => props.theme.customColors.primaryHover};
  }
  .ant-menu-submenu-open{
    /* li {
      padding-right: 24px !important;
      div{
        padding-right: 24px !important;
        .ant-menu-submenu-title{
        padding-right: 0 !important;
      }
      }
    } */
    .childSubMenue{
      li{padding-right: 48px !important;}
    }
  }
  /* ant-menu-submenu ant-menu-submenu-inline ant-menu-submenu-open */
  /* ant-menu-submenu ant-menu-submenu-inline apps-boarding ant-menu-submenu-open */
`;

export const IntrialContainer = styled.div`
width: 254px;
display: flex;
.upgrade{
color: ${(props) => props.theme.customColors.lightBlue};
border: 1px solid ${(props) => props.theme.customColors.lightBlue};
border-radius: 4px;
}
`