import React, { useEffect, useState } from "react";
import { Form, Input, Typography, Menu, Space, Col, Spin, Row, Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { FormCard } from "../../pages/products/products/addEdit/style";
import { useQuery } from "@apollo/client";
import { CURRENT_USER } from "../../utlis/Me";
import FeaturesNames from "../../enums/featuresNames";
import styled from "styled-components";
import { LinkOutlined, DownOutlined } from "@ant-design/icons";
import pageTitleImg from "../../assets/imgs/pagetitleimg.svg";
import { AppLanguageSwitch } from "./websiteLanguage";
import MyImage from "./image";
import i18next from "i18next";
import HasFeature from "../hasFuture";
import MetaTags from "../../pages/posts/addEdit/metaTags";
import { Breakpoints } from "../../constants";

const { Title, Text } = Typography;
const { SubMenu } = Menu;

// type DefaultValue = {
//   title: string;
//   body: string;
// };
// interface Props {
//   type;
//   form;
//   defaultSeoValue: DefaultValue;
// }
function convertHtmlToTextUsingDom(html) {
  var divContainer = document.createElement("div");
  divContainer.innerHTML = html;
  return divContainer.textContent || divContainer.innerText || "";
}
const SeoCard = ({ type = "product", form, defaultSeoValue, seoItemId = null, isEdit = false }) => {
  console.log("🚀 ~ file: seoCard.tsx ~ line 32 ~ SeoCard ~ isEdit", isEdit);
  console.log("🚀 ~ file: seoCard.js ~ line 29 ~ SeoCard ~ defaultSeoValue", defaultSeoValue);
  const { t, i18n } = useTranslation();
  console.log("formformform", defaultSeoValue);
  const [firstLangLoad, setFirstLangLoad] = useState(false);
  const [isEn, setIsEn] = useState();
  const [lang, setLang] = useState("en");

  const [formData, setFormData] = useState({
    slug: "",
    title: "",
    body: "",
  });
  const [visable, setVisable] = useState(false);
  // useEffect(() => {
  //   console.log("🚀 ~ file: seoCard.js ~ line 45 ~ useEffect ~ defaultSeoValue", defaultSeoValue);
  //   setFormData({
  //     slug: defaultSeoValue?.slug && defaultSeoValue?.slug[lang],
  //     body: defaultSeoValue?.body && defaultSeoValue?.body[lang],
  //     title: defaultSeoValue?.title && defaultSeoValue?.title[lang],
  //   });
  // }, [defaultSeoValue]);
  useEffect(() => {
    if (isEn) {
      setLang("en");
    } else {
      setLang("ar");
    }
    setFormData({
      slug: form?.getFieldValue("seo")?.slug && form?.getFieldValue("seo")?.slug[lang],
      body: form?.getFieldValue("seo")?.body && form?.getFieldValue("seo")?.body[lang],
      title: form?.getFieldValue("seo")?.title && form?.getFieldValue("seo")?.title[lang],
    });
  }, [lang, isEn]);

  const convertToText =
    defaultSeoValue?.body && defaultSeoValue?.body[lang] ? (
      <p
        dangerouslySetInnerHTML={{
          __html: convertHtmlToTextUsingDom(defaultSeoValue?.body[lang] || ""),
        }}
      />
    ) : (
      <p> {` وصف صفحة هنا  ${type == "blog" ? "المنشور هنا" : "المنتج هنا"}`} </p>
    );

  const defaultTitle = defaultSeoValue?.title && defaultSeoValue?.title[lang];

  const { data } = useQuery(CURRENT_USER, {
    fetchPolicy: "cache-first",
  });

  // const hasFeature = data?.me?.currentShop?.features[FeaturesNames.seo];
  // if (!hasFeature) return null;
  // if () return <Spin />;
  return (
    <>
      <AppSwitchContainer>
        <AppLanguageSwitch
          isEn={isEn}
          setIsEn={setIsEn}
          firstLangLoad={firstLangLoad}
          setFirstLangLoad={setFirstLangLoad}
        />
        {!firstLangLoad ? (
          <Spin
            style={{
              position: "absolute",
              marginTop: "50px",
              width: "100%",
              height: "100%",
              zIndex: 999,
              background: "white",
            }}
          />
        ) : (
          <Container language={i18n.language}>
            {/* <AppLanguageSwitchContainer language={i18next.language}> */}
            {/* </AppLanguageSwitchContainer> */}
            <Menu
              style={{ border: 0, background: "transparent" }}
              defaultSelectedKeys={["1"]}
              inlineIndent={0}
              // defaultOpenKeys={["sub1"]}
              mode="inline">
              <SubMenu key="sub1" title={<Title level={2}>{t("common.seo")}</Title>}>
                <Menu.ItemGroup key="g1">
                  <Form.Item
                    label={type == "blog" ? t("pages.blogTitle") : t("common.title")}
                    name={["seo", "title", lang]}>
                    <Input
                      addonBefore={<MyImage src={pageTitleImg} width={20} />}
                      maxLength={77}
                      placeholder={t("common.productTitle")}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          title: e.target?.value,
                        }));
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label={type == "blog" ? t("pages.blogSlug") : t("common.slug")}
                    name={["seo", "slug", lang]}>
                    <Input
                      addonBefore={<LinkOutlined style={{ fontSize: 20 }} />}
                      maxLength={75}
                      placeholder={t("common.url")}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          slug: e.target?.value,
                        }));
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label={type == "blog" ? t("pages.blogBody") : t("common.body")}
                    name={["seo", "body", lang]}>
                    <Input.TextArea
                      maxLength={160}
                      placeholder={t("common.descPage")}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          body: e.target?.value,
                        }));
                      }}
                    />
                  </Form.Item>
                </Menu.ItemGroup>
                <Form.Item>
                  <div className="form-info">
                    <Space direction="vertical" size={8} style={{ width: "100%" }}>
                      <Text className="page-url">{`https://${
                        data?.me?.currentShop?.domain
                      }/${type}/${
                        formData.slug || ` ${type == "blog" ? "BlogSlug" : "ProductSlug"}`
                      }`}</Text>
                      <Text className="product-name">
                        {formData.title ||
                          defaultTitle ||
                          ` فساتين طفلة صغيرة تصميم أبليكوي  ${
                            type == "blog" ? "(عنوان صفحة المنشور)" : "(عنوان صفحة المنتج)"
                          }`}
                      </Text>
                      <Text className="product-desc">{formData.body || convertToText}</Text>
                    </Space>
                  </div>
                </Form.Item>
              </SubMenu>
            </Menu>
          </Container>
        )}

        {seoItemId && (
          <Container>
            <Row justify="space-between">
              <Text className="productSetting-title">{"Meta tags"}</Text>
              <Button type="link" className="show-btn" onClick={() => setVisable(true)}>
                {t("settings.metaTagsSettings")}
              </Button>
            </Row>
            <Modal
              title={t("settings.metaTagsSettings")}
              visible={visable}
              footer={null}
              onCancel={() => setVisable(false)}>
              {isEdit && (
                <HasFeature feature={FeaturesNames.seo}>
                  <Col span={24} md={16}>
                    <MetaTags
                      seoId={seoItemId}
                      closeModal={() => setVisable(false)}
                      // {...{ setFaceBookImage, facebookImage, setTwitterImage, twitterImage }}
                    />
                  </Col>
                </HasFeature>
              )}
            </Modal>
          </Container>
        )}
      </AppSwitchContainer>
    </>
  );
};

const Container = styled.div`
  background-color: ${(props) => props.theme.customColors.white};
  padding: 16px;
  box-shadow: 0px 0px 8px rgba(51, 51, 51, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  margin-top: 60px;
  position: relative;
  z-index: 10;

  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: ${(props) => props.theme.colors.black3};

    /* @media screen and (max-width: ${Breakpoints.md}) {
      font-size: 11px;
      font-weight: 500;
    } */
  }
  .ant-menu-sub.ant-menu-inline,
  .ant-menu-item:active,
  .ant-menu-submenu-title:active {
    background: transparent;
  }
  .ant-input-group-addon,
  .ant-input-group-addon:first-child {
    padding-top: 5px;
    border: 0;
  }
  .ant-input-group .ant-input {
    border-radius: 4px;
    height: 40px;
    padding: 0 17px;
  }

  .form-info {
    background: #f0f0f0;
    border-radius: 4px;
    padding: 14px 12px;
    text-align: start;
    .page-url {
      font-size: 12px;
      font-weight: 600;
      color: #528968;
    }
    .product-name {
      font-size: 14px;
      font-weight: 600;
      color: #1c1076;
    }
    .product-desc {
      font-size: 12px;
      font-weight: 600;
      color: ${(props) => props.theme.colors.black3};
    }
  }

  .ant-menu-submenu-arrow {
    position: absolute;
    top: 15px;
    left: ${(props) => (props.language == "en" ? "280px" : "100px")};
  }

  .ant-menu-rtl .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
    right: ${(props) => (props.language == "ar" ? "180px" : "0")};
  }
  .productSetting-title {
    font-weight: 700;
    font-size: 16px;
  }
  .show-btn {
    font-weight: 600;
    font-size: 14px;
    color: ${(props) => props.theme.customColors.lightBlue};
  }
`;

const AppSwitchContainer = styled.div`
  position: relative;
`;

export default SeoCard;
