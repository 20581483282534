import { gql } from "@apollo/client";

export const GET_ROOM = gql`
  query GET_ROOM($chatSlug: String!) {
    room(chatSlug: $chatSlug) {
      id
      slug
      notSeenNum
      messages(take: 1) {
        user {
          id
          name
        }
      }
    }
  }
`;
export const GET_MESSAGES = gql`
  query GET_MESSAGES($take: Int = 100, $skip: Int = 0, $chatSlug: String!) {
    messages(
      orderBy: { createdAt: desc }
      take: $take
      skip: $skip
      where: { room: { slug: { equals: $chatSlug } } }
    ) {
      count
      roomName
      nodes {
        id
        message
        seen
        createdAt
        user {
          id
          name
        }
      }
    }
  }
`;

export const SEE_CHAT = gql`
  mutation SEE_CHAT($roomSlug: String!) {
    seeChat(roomSlug: $roomSlug)
  }
`;
