import { useEffect } from "react";

import { Popconfirm, Space, Tag } from "antd";
import styled from "styled-components";
import { Link } from "react-router-dom";
// import MyImage from "../../../components/common/image";
import useFilter, { handlePrismaFilter } from "../../../components/common/hooks/useFilter";
import formatMoney from "../../../utlis/formatMoney";
import { useTranslation } from "react-i18next";
import MyImage from "../../../components/common/image";
import moment from "moment";
import { getOrderStatuses } from "../../../lib/getOrderStatuses";
import { PaidStatues } from "../../../lib/getOrderPaidStatuses";
import { ExecutionStatues } from "../../../lib/getOrderExecutionStatuses";
import trash from "../../../assets/imgs/trash-2.svg";

const reducerMap = {
  owner: {
    name: "CUSTOMER",
    filterType: "text",
  },
  status: {
    name: "STATUS",
    filterType: "enum",
  },
};

export enum Statues {
  // notReady = "NOT_READY",
  // ready = "READY",
  // onWay = "ON_WAY",
  // delivered = "delivered",
  // beRetrieved = "BE_RETRIEVED",
  // retrieved = "RETRIEVED",
  canceled = "CANCELED",
  pending = "PENDING",
  archived = "ARCHIVED",
  completed = "COMPLETED",
  open = "OPEN",
}

const StatueText = styled.div<{ background: string; color: string }>`
  color: ${({ color }) => color};
  background-color: ${({ background }) => background};
  width: fit-content;
  text-align: center;
  font-weight: 700;
  margin: auto;
  border-radius: 100px;
  padding: 0px 12px;
`;

export default function useOrderFilter(pagination, setPagination) {
  const { t } = useTranslation();

  const orderStatuses = getOrderStatuses(t);

  const reducer = (handleParams) => (state, action) => {
    switch (action.type) {
      case reducerMap.owner.name:
        const owner = action.selectedKeys;

        handleParams({ owner });
        return {
          ...state,
          owner: {
            name: handlePrismaFilter(reducerMap.owner.filterType, {
              filterVal: owner,
            } as any),
          },
        };
      case reducerMap.status.name:
        const status = action.selectedKeys;

        handleParams({ status });
        return {
          ...state,
          status: handlePrismaFilter(reducerMap.status.filterType, {
            filterVal: status,
          } as any),
        };
      case "SET_INIT":
        return {
          ...state,
          ...action.params,
        };

      case "RESET_FILTER":
        handleParams("reset");
        return {};
      default:
        return state;
    }
  };
  const { query, dispatch, getColumnRadioProps, getColumnSearchProps, ...restFilters } = useFilter(
    pagination,
    setPagination,
    reducer,
    reducerMap,
  );
  // ProductTypeSelector;

  const columns = () => {
    const customerColumn = {
      title: t("order.client"),
      // align: "center",
      // ...getColumnSearchProps("Companies"),
      align: "center",
      render: (record) => (
        <Space
          style={{
            justifyContent: "start",
            // margin: "auto",
            width: "80%",
          }}>
          <MyImage
            image={record.owner?.avatar || null}
            width={50}
            height={50}
            style={{ borderRadius: "50px" }}
          />
          {record.owner?.id ? (
            <Link to={`/customer/${record.owner?.id}`}>{record.owner?.name}</Link>
          ) : (
            "-"
          )}
        </Space>
      ),

      // ...getColumnSearchProps("owner", "customer"),
    };
    const renderStatus = ({ status }: { status: Statues }) => {
      switch (status) {
        // case Statues.notReady:
        //   return (
        //     <StatueText background="rgba(79, 79, 79, 0.05)" color="#4F4F4F">
        //       {t("order.notReady")}
        //     </StatueText>
        //   );
        // case Statues.ready:
        //   return (
        //     <StatueText background="rgba(217, 110, 66, 0.05)" color="#D96E42">
        //       {t("order.ready")}
        //     </StatueText>
        //   );
        // case Statues.onWay:
        //   return (
        //     <StatueText background="rgba(0, 150, 199, 0.05)" color="#0096C7">
        //       {t("order.onWay")}
        //     </StatueText>
        //   );
        // case Statues.delivered:
        //   return (
        //     <StatueText background="rgba(2, 195, 154, 0.05)" color="#02C39A">
        //       {t("order.delivered")}
        //     </StatueText>
        //   );
        // case Statues.beRetrieved:
        //   return (
        //     <StatueText background="rgba(248, 157, 22, 0.05)" color="#F89D16">
        //       {t("order.beRetrieved")}
        //     </StatueText>
        //   );
        // case Statues.retrieved:
        //   return (
        //     <StatueText background="rgba(55, 71, 79, 0.05)" color="#37474F">
        //       {t("order.retrieved")}
        //     </StatueText>
        //   );
        case Statues.archived:
          return (
            <StatueText background="(130, 130, 130, 0.05)" color="#828282">
              {t("order.archived")}
            </StatueText>
          );
        case Statues.canceled:
          return (
            <StatueText background="(230, 57, 70, 0.05)" color="#E63946">
              {t("order.canceled")}
            </StatueText>
          );

        case Statues.completed:
          return (
            <StatueText background="rgba(33, 150, 83, 0.04) " color="#219653">
              {t("order.completed")}
            </StatueText>
          );
        case Statues.pending:
          return (
            <StatueText background="rgba(242, 153, 74, 0.04)" color="#F2994A">
              {t("order.pending")}
            </StatueText>
          );
        default:
          return (
            <StatueText background="rgba(47, 128, 237, 0.04)" color="#2F80ED">
              {t("order.open")}
            </StatueText>
          );
      }
    };
    const statusColumn = {
      title: t("order.orderStatus"),
      align: "center",
      // dataIndex: "status",
      render: renderStatus,
      // ...getColumnRadioProps("status", orderStatuses),
    };

    const renderPaid = ({ paymentStatus }: { paymentStatus: PaidStatues }) => {
      switch (paymentStatus) {
        case PaidStatues.paid:
          return t("order.paid");
        default:
          return t("order.notPaid");
      }
    };
    const paidColumn = {
      title: t("order.payingOff"),
      align: "center",

      render: renderPaid,
    };
    const renderExecution = ({ fulfillmentStatus }: { fulfillmentStatus: ExecutionStatues }) => {
      switch (fulfillmentStatus) {
        case ExecutionStatues.fulfilled:
          return t("order.fulfilled");
        case ExecutionStatues.partiallyFulfilled:
          return t("order.partiallyFulfilled");
        default:
          return t("order.unFulfilled");
      }
    };

    const cols = [
      {
        title: t("order.orderNumber"),
        align: "center",

        render: (record) => <Link to={`/order/${record.id}`}>#{record.id}</Link>,
      },
      {
        title: t("order.date"),
        align: "center",
        dataIndex: "createdAt",

        // render: (record) =>  moment("arabic", "HH:mm:ss").format("LT"),
        render: (record) => moment(record).format("DD/MM/YYYY h:mmA"),
      },
      customerColumn,
      {
        title: t("order.totalOrder"),
        align: "center",

        render: (record) => formatMoney({ price: record.total }),
      },
      statusColumn,
      {
        title: t("order.shipping"),
        align: "center",
        render: renderExecution,
      },
      paidColumn,
      // {
      //   title: `Delivery method	`,
      //   align: "center",

      //   // render: (record) => (
      //   //   <Link to={`/editcompany/${record.id}`}>#{record.id}</Link>
      //   // ),
      // },
      {
        title: `Tags`,
        align: "center",
        render: (record) => record.tags.map(({ name, id }) => <Tag key={id}>{name} </Tag>),
      },
      // {
      //   align: "center",
      //   render: () => (
      //     <Popconfirm
      //       title={t("analytics.deleteProduct")}
      //       // disabled={!isAllowToRemove}
      //       // onConfirm={() =>
      //       //   removeorder({
      //       //     variables: { where: { id: record.id } },
      //       //   })
      //       // }
      //       okText={t("analytics.yes")}
      //       cancelText={t("analytics.no")}>
      //       <MyImage src={trash} width={16} />
      //     </Popconfirm>
      //   ),
      // },
    ];

    return cols;
  };

  useEffect(() => {
    //Initial dates
    let initData = {
      owner: {
        name: handlePrismaFilter(reducerMap.owner.filterType, {
          filterVal: query.owner,
        } as any),
      },
      status: handlePrismaFilter(reducerMap.status.filterType, {
        filterVal: query.status,
      } as any),
    };

    (dispatch as any)({ type: "SET_INIT", params: initData });

    return () => {
      console.log("🚀 ~ file: useOrderFilter.js ~ line 419 ~ return ~ restFilters", restFilters);

      restFilters.onResetButtonClick();
    };
  }, []);

  return {
    columns,
    query,
    ...restFilters,
  };
}
