import { Button, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import MyImage from "../../../../components/common/image";
import renewPic from "../../../../assets/imgs/renewpic.svg";
import { useHistory } from "react-router";

const { Text } = Typography;

const RenewSubscription = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Container>
      <Text className="header"> {t("bills.sub")} </Text>
      <Space direction="vertical" size={18} className="info-container">
        <MyImage src={renewPic} width={180} />
        <Text className="title"> {t("renew.finish")} </Text>
        <Text className="text"> {t("renew.finishInfo")} </Text>
        <Button type="primary" onClick={() => history.push("/changePlan")}>
          {" "}
          {t("renew.subNow")}{" "}
        </Button>
      </Space>
    </Container>
  );
};

const Container = styled.div`
  .header {
    font-weight: 700;
    font-size: 24px;
    color: ${(props) => props.theme.colors.black3};
    display: block;
    margin-bottom: 33px;
  }
  .info-container {
    background: rgba(110, 187, 197, 0.12);
    border-radius: 8px;
    padding: 32px 0;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .title {
    font-size: 24px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.black3};
  }
  .text {
    font-size: 16px;
    font-weight: 400;
    color: ${(props) => props.theme.colors.Gray2};
    width: 500px;
    display: block;
    margin-bottom: 0px;
  }
  .ant-btn {
    font-size: 16px;
    font-weight: 600;
    height: 48px;
  }
`;

export default RenewSubscription;
