import { PhoneOutlined, WhatsAppOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import vodafoneCash from "../../../assets/imgs/layout/vodafoneCash.png";
import MyImage from "../../../components/common/image";
import { Breakpoints } from "../../../constants";

const VodafoneCashHintComponent = () => {
  const { t } = useTranslation();
  const [hintVisiable, setHintVisiable] = useState(false);
  return (
    <Container>
      <div className="flex">
        <span onClick={() => setHintVisiable(!hintVisiable)}>
          <MyImage src={vodafoneCash} alt="vodafoneCash" width={38} height={75} />
        </span>
        {hintVisiable && (
          <div className="hint-card">
            <h3 className="title">{t("users.transferByVodafone")}</h3>
            <article className="desc">
              <p style={{ marginBottom: 4 }}>{t("users.transferOnNumberVodafone")}</p>
              <span className="number-flex">
                <PhoneOutlined />
                {"01026462262"}
              </span>
            </article>
            <article className="desc">
              <p style={{ marginBottom: 4 }}>{t("users.sendTransferImg")}</p>
              <a
                className="number-flex"
                href="https://api.whatsapp.com/send?phone=20 155 090 7414&text=sendTransfer"
                rel="noreferrer"
                target={"_blank"}>
                <WhatsAppOutlined />
                {"01550907414"}
              </a>
            </article>
          </div>
        )}
      </div>
    </Container>
  );
};

export default VodafoneCashHintComponent;

const Container = styled.div`
  position: absolute;
  top: 60%;
  left: 0;

  .hint-card {
    box-shadow: 0px 0px 8px 0px #33333317;
    border-radius: 6px;
    z-index: 99;
    max-width: 335px;
    padding: 12px 24px;
    background: ${(props) => props.theme.customColors.white};
    display: flex;
    flex-direction: column;
    gap: 6px;
    @media (max-width: ${Breakpoints.sm}) {
      padding: 12px;
    }
  }
  .title {
    font-weight: 600;
  }
  .desc {
    color: ${(props) => props.theme.colors.Gray2};
  }
  .flex {
    display: flex;
  }
  .number-flex {
    display: flex;
    gap: 6px;
    font-weight: 600;
    align-items: center;
    color: ${(props) => props.theme.colors.Gray1};
  }
`;
