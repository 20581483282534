import React, { useState, useEffect, useMemo } from "react";

import { TreeSelect } from "antd";
import { gql, useQuery } from "@apollo/client";
import debounce from "lodash/debounce";
import { useTranslation } from "react-i18next";

export const CATEGORIES_TREE = gql`
  query CATEGORIES_TREE($filter: CategoryWhereInput = {}, $parentFilter: CategoryWhereInput = {}) {
    categories(where: { AND: [$filter, $parentFilter] }, take: 100) {
      nodes {
        id
        name {
          id
          auto
        }
        slug
        children(where: $filter) {
          id
          name {
            id
            auto
          }
          slug
          children(where: $filter) {
            id
            name {
              id
              auto
            }
            slug
          }
        }
      }
    }
  }
`;
const { SHOW_ALL } = TreeSelect;

const CategorySelector = ({
  style = {},
  checkable,
  filterId = undefined,
  valueType,
  type = "PRODUCT",
  multiple = false,
  ...props
}) => {
  const [search, setSearch] = useState("");
  console.log("🚀 ~ file: categorySelector.js ~ line 49 ~ search", search);
  const [treeData, setTreeData] = useState([]);
  console.log("CategorySelector -> checkable", checkable);
  const { t } = useTranslation();

  const { data, loading, refetch } = useQuery(CATEGORIES_TREE, {
    fetchPolicy: "cache-and-network",
    variables: {
      parentFilter: { parentId: { equals: null } },
      filter: {
        name: {
          OR: [
            { ar: { contains: search, mode: "insensitive" } },
            { en: { contains: search, mode: "insensitive" } },
          ],
        },
        id: filterId ? { not: { equals: parseInt(filterId) } } : undefined,
        type: { equals: type },
      },
    },
  });
  useEffect(() => {
    const treeData = data?.categories.nodes.map(({ id, children, name, slug, ...rest }) => ({
      value: valueType == "slug" ? slug : id,
      id,
      key: valueType == "slug" ? slug : id,
      title: name.auto,

      children: children.map(({ id, children, name, slug, ...rest }) => ({
        value: valueType == "slug" ? slug : id,
        id,
        key: valueType == "slug" ? slug : id,
        title: name.auto,

        children: children.map(({ id, slug, name, ...rest }) => ({
          value: valueType == "slug" ? slug : id,
          id,
          key: valueType == "slug" ? slug : id,
          title: name.auto,
          ...rest,
        })),
        ...rest,
      })),
      ...rest,
    }));
    treeData && setTreeData(treeData);
  }, [data]);

  return (
    <TreeSelect
      {...props}
      style={{ width: "100%", ...style }}
      // treeDataSimpleMode
      onSearch={debounce((val) => setSearch(val), 200)}
      showSearch
      multiple={multiple}
      loading={loading}
      treeCheckable={checkable}
      allowClear
      onFocus={() => refetch()}
      // showCheckedStrategy={SHOW_ALL}
      filterTreeNode={false}
      treeCheckStrictly={checkable}
      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
      treeData={treeData}
      placeholder={t("products.searchForCategory")}
    />
  );
};

export default React.memo(CategorySelector);
