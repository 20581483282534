import React, { useState, useEffect } from "react";
import {
  Form,
  // Select,
  Button,
  // InputNumber,
  Divider,
  // Checkbox,
  // Table,
  // Input,
  // TreeSelect,
  message,
  Space,
  // Tooltip,
  Typography,
  // Modal,
} from "antd";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import {
  // MinusCircleOutlined,
  PlusOutlined,
  // DeleteOutlined,
  // UndoOutlined,
  // RobotOutlined,
} from "@ant-design/icons";
import { FormCard } from "../style";
import CategoriesSelect from "../../../../../components/common/selectors/categorySelector";
// import makeSlug from "slug-arabic"; // by B7r :)
import BrandSelector from "../../../../../components/common/selectors/BrandSelector";
import { useTranslation } from "react-i18next";
// import FeaturesNames from "../../../../../enums/featuresNames";
// import { CURRENT_USER } from "../../../../../utlis/Me";
import TagsSelectors from "../../../../../components/common/selectors/tagsSelectors";
// import MyImage from "../../../../../components/common/image";
// import lamb from "../../../../../assets/imgs/lamb.svg";
import AddCategoryModal from "./addCategoryModal";
// import ProductTypeSelector from "../../../../../components/common/ProductTypeSelector";

// const { Option } = Select;
// const { SHOW_PARENT } = TreeSelect;
const { Text } = Typography;

// const TYPES = gql`
//   query TYPES($filter: String) {
//     productTypes(where: { name: { contains: $filter } }, take: 10) {
//       nodes {
//         id
//         name
//       }
//     }
//   }
// `;

// export const ADD_TYPE = gql`
//   mutation ADD_TYPE($name: String!, $slug: String!) {
//     addProductType(name: $name, slug: $slug) {
//       id
//     }
//   }
// `;

// const BRANDS = gql`
//   query BRANDS($filter: String) {
//     brands(where: { name: { contains: $filter } }, take: 10) {
//       nodes {
//         id
//         name
//       }
//     }
//   }
// `;

const GET_AI_TAGGING = gql`
  query GET_AI_TAGGING($imageUrl: String!) {
    getAiTags(imageUrl: $imageUrl)
  }
`;
/**
 * renderComponent
 * @component
 * @name addCompanyModal
 * @memberof PRODUCTS
 * @summary admin can add company
 * @return {React} React component view
 */
const Organization = ({ mainImage, form }) => {
  const { t } = useTranslation();
  const [tagFilter, setTagFilter] = useState("");
  const [getTagging, { loading }] = useLazyQuery(GET_AI_TAGGING, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const tags = new Set(form.getFieldValue("tags"));
      data?.getAiTags?.map((tag) => tags.add(tag));
      form.setFieldsValue({ tags: [...tags] });
    },
  });
  const getTags = async () => {
    console.log("🚀 ~ file: add.js ~ line 131 ~ AddProduct ~ mainImage", mainImage);
    if (mainImage?.url) {
      const imageUrl = mainImage?.url;

      try {
        getTagging({ variables: { imageUrl } });
      } catch (error) {
        console.log(error.response.body);
      }
    } else {
      message.warning("must add main image");
    }
  };
  // const [brandFilter, setBrandFilter] = useState("");
  // const [catFilter, setCatFilter] = useState("");

  // const [typeFilter, setTypeFilter] = useState("");
  // const [typeName, setTypeName] = useState("");
  // const [brand, setBrand] = useState("");

  // const {
  //   data: { me },
  // } = useQuery(CURRENT_USER, {
  //   fetchPolicy: "cache-first",
  // });
  // const hasFeature = me?.currentShop?.features[FeaturesNames.ai];
  // const brandsQuery = useQuery(BRANDS, {
  //   variables: {
  //     filter: brandFilter,
  //   },
  // });

  // const typesQuery = useQuery(TYPES, {
  //   variables: {
  //     filter: typeFilter,
  //   },
  // });

  // const [addBrand, brandprops] = useMutation(ADD_BRAND, {
  //   refetchQueries: [{ query: BRANDS, variables: { filter: brandFilter } }],
  // });
  // const [addType, typeprops] = useMutation(ADD_TYPE, {
  //   refetchQueries: [{ query: TYPES, variables: { filter: typeFilter } }],
  // });

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const categoryLabel = (
    <Space align="baseline" style={{ width: "100%", justifyContent: "space-between" }}>
      <Text className="label-text">{t("products.category")}</Text>
      <Button type="text" className="label-btn" onClick={showModal}>
        {t("products.addCategory")}
        <PlusOutlined />
      </Button>
    </Space>
  );

  return (
    <FormCard>
      <h2>{t("products.organization")}</h2>

      {/* <Form.Item label="Product type" name="type">
        <ProductTypeSelector canAdd isValueId /> */}

      {/* <Select
          showSearch
          placeholder="e.g. Shirts"
          onSearch={(val) => setTypeFilter(val)}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{ margin: "4px 0" }} />
              <div
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  padding: 8,
                }}
              >
                <Input
                  style={{ flex: "auto" }}
                  value={typeName}
                  onChange={(e) => setTypeName(e.target.value)}
                />
                <Button
                  icon={<PlusOutlined />}
                  loading={typeprops.loading}
                  type="link"
                  onClick={() => {
                    typeName &&
                      addType({
                        variables: { name: typeName, slug: makeSlug(typeName) },
                      });

                    setTypeName("");
                  }}
                >
                  Add
                </Button>
              </div>
            </div>
          )}
        >
          {typesQuery.data?.productTypes.nodes.map((value) => (
            <Option key={value.id} value={value.id}>
              {value.name}
            </Option>
          ))}
        </Select>
     */}
      {/* </Form.Item> */}

      <Form.Item label={t("products.brand")} name="brand">
        <BrandSelector canAdd isValueId />
        {/* <Select
          showSearch
          placeholder="e.g. Nike"
          onSearch={(val) => setBrandFilter(val)}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{ margin: "4px 0" }} />
              <div
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  padding: 8,
                }}
              >
                <Input
                  style={{ flex: "auto" }}
                  value={brand}
                  onChange={(e) => setBrand(e.target.value)}
                />
                <Button
                  // style={{
                  //   flex: "none",
                  //   padding: "8px",
                  //   display: "block",
                  //   cursor: "pointer"
                  // }}
                  loading={brandprops.loading}
                  icon={<PlusOutlined />}
                  // ghost
                  type="link"
                  onClick={() => {
                    brand &&
                      addBrand({
                        variables: { name: brand, slug: makeSlug(brand) },
                      });
                    setBrand("");
                  }}
                >
                  Add
                </Button>
              </div>
            </div>
          )}
        >
          {brandsQuery.data?.brands.nodes.map((value) => (
            <Option key={value.id} value={value.id}>
              {value.name}
            </Option>
          ))}
        </Select> */}
      </Form.Item>

      <Divider />

      <Form.Item label={categoryLabel} name="categories">
        <CategoriesSelect checkable />
      </Form.Item>
      <Divider />

      <Form.Item
        label={
          <Space>
            {t("home.tags")}
            {/* {hasFeature && (
              <Tooltip title="get Tags by AI">
                <Button
                  loading={loading}
                  disabled={loading}
                  type={"text"}
                  icon={<MyImage src={lamb} width={21} />}
                  onClick={getTags}
                />
              </Tooltip>
            )} */}
          </Space>
        }
        name="tags">
        <TagsSelectors />
      </Form.Item>
      <AddCategoryModal
        isModalVisible={isModalVisible}
        setIsModa
        handleOk={handleOk}
        handleCancel={handleCancel}
        setIsModalVisible={setIsModalVisible}
      />
    </FormCard>
  );
};

export default Organization;

// import { TreeSelect } from 'antd';

// class Demo extends React.Component {
//   state = {
//     value: undefined,
//     treeData: [
//       { id: 1, pId: 0, value: '1', title: 'Expand to load' },
//       { id: 2, pId: 0, value: '2', title: 'Expand to load' },
//       { id: 3, pId: 0, value: '3', title: 'Tree Node', isLeaf: true },
//     ],
//   };

//   genTreeNode = (parentId, isLeaf = false) => {
//     const random = Math.random()
//       .toString(36)
//       .substring(2, 6);
//     return {
//       id: random,
//       pId: parentId,
//       value: random,
//       title: isLeaf ? 'Tree Node' : 'Expand to load',
//       isLeaf,
//     };
//   };

//   onLoadData = treeNode =>
//     new Promise(resolve => {
//       const { id } = treeNode.props;
//       setTimeout(() => {
//         this.setState({
//           treeData: this.state.treeData.concat([
//             this.genTreeNode(id, false),
//             this.genTreeNode(id, true),
//           ]),
//         });
//         resolve();
//       }, 300);
//     });

//   render() {
//     const { treeData } = this.state;
//     return (
//       <TreeSelect
//         treeDataSimpleMode
//         style={{ width: '100%' }}
//         value={this.state.value}
//         dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
//         placeholder="Please select"
//         onChange={this.onChange}
//         loadData={this.onLoadData}
//         treeData={treeData}
//       />
//     );
//   }
// }
