import { Spin } from "antd";

const CustomLoading = () => (
  <div
    style={{
      position: "fixed",
      minHeight: "100vh",
      minWidth: "100vw",
      backgroundColor: "white",
      zIndex: 22,
    }}>
    <Spin
      size="large"
      style={{
        position: "fixed",
        right: "50%",
        top: "50%",
      }}
    />
  </div>
);

export default CustomLoading;
