import { DownOutlined, EyeOutlined } from "@ant-design/icons";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Avatar, Button, Drawer, Layout, Menu, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import logout from "../../../assets/imgs/logout.svg";
import logo from "../../../assets/imgs/NewUpdatedLogo.svg";
import star from "../../../assets/imgs/star.svg";
import { sideArray } from "./siderData";
import closeImg from "../../../assets/imgs/x.svg";
import { useResponsive } from "../../../lib/useResponsive";
import { getHasPermission } from "../../../utlis/getHasPermission";
import { CURRENT_USER } from "../../../utlis/Me";
import { isAdmin } from "../../../utlis/permission";
import { routes } from "../../../utlis/routes";
import MyImage from "../../common/image";
import HiderDrop from "./hiderDrop";
import CustomHeader from "../header";
import { Logo, LogoContainer, MenuContainer, UpgradeContainer } from "./sider.styles";
import { BoardingJoyRide } from "./joyRideSider";
const { SubMenu } = Menu;
const { Sider } = Layout;
export const SIGN_OUT = gql`
  mutation SIGN_OUT {
    signOut
  }
`;

const CustomSider = ({ children }) => {
  const { sm, lg } = useResponsive();

  const { data = { me: {} }, loading: loadingMe } = useQuery(CURRENT_USER, {
    fetchPolicy: "cache-first",
  });
  const userCurrentApps = data.me?.currentShop?.apps;
  const me = data.me;

  const [signOut, { client }] = useMutation(SIGN_OUT, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: CURRENT_USER }],
  });

  const { t, i18n } = useTranslation();
  const { push } = useHistory();
  const [collapsed, setCollapsed] = useState(true);
  const [toggle, setToggle] = useState(false);
  const showSiderDrop = () => {
    setToggle(true);
  };
  const hideDrop = () => {
    setToggle(false);
  };

  useEffect(() => {
    if (!lg && !collapsed) setCollapsed(true);
    else if (lg && collapsed) setCollapsed(false);
  }, [lg]);

  const filterSideBar = ({ href }) => {
    if (!href) return true;
    const roles = routes?.find((route) => route.path == href)?.roles;
    const condition = roles?.includes(me?.role) || isAdmin(me?.role) || roles?.includes("all");
    return condition;
  };
  const makeMenuItem = (
    {
      target,
      label,
      icon,
      href,
      children,
      disabled,
      permission: parentPermission,
      feature: parentFeature,
    },
    i,
  ) => {
    children = children?.filter(filterSideBar);
    const isParentHasPermission = getHasPermission(me, { permission: parentPermission });
    const isParentHasFeature = !parentFeature || me.currentShop?.features[parentFeature];

    console.log(
      "🚀 ~ file: index.js ~ line 418 ~ CustomSider ~ me.shop?.features",
      me.currentShop?.features,
    );
    console.log(
      "🚀 ~ file: index.js ~ line 344 ~ CustomSider ~ me.shop?.features[parentFeature]",
      parentPermission,
    );
    console.log(
      "🚀 ~ file: index.js ~ line 333 ~ CustomSider ~ isParentHasFeature",
      isParentHasFeature,
    );
    const isActive = me?.currentShop?.status !== "STOPPED";

    return children ? (
      <SubMenu key={i} icon={icon} title={label} disabled={!children?.length} className={target}>
        {children.map(
          (
            {
              target,
              label,
              children,
              href,
              disabled,
              permission: childPermission,
              feature: childFeature,
            },
            index,
          ) => {
            const isChildHasPermission = getHasPermission(me, { permission: childPermission });
            const isChildHasFeature = !childFeature || me.currentShop?.features[childFeature];
            return children ? (
              makeMenuItem({ target, label, children, href, disabled }, `${i}-${index}`)
            ) : (
              <Menu.Item
                disabled={
                  disabled ||
                  (!isChildHasPermission && childPermission) ||
                  !isChildHasFeature ||
                  !isActive
                }
                // className={target}
                className={`${pathname == href && "ant-menu-item-selected"} ${target}`}
                key={`${i}-${index}`}>
                <Link
                  style={{
                    fontSize:
                      href === "/emailCompaign"
                        ? "10px"
                        : href === "/smsCompaign"
                        ? "10px"
                        : "unset",
                  }}
                  to={href}>
                  {label}
                </Link>
              </Menu.Item>
            );
          },
        )}
      </SubMenu>
    ) : (
      <Menu.Item
        disabled={
          disabled ||
          (!isParentHasPermission && parentPermission) ||
          !isParentHasFeature ||
          !isActive
        }
        key={i}
        icon={icon}
        // target={target}
         // className={pathname == href && "ant-menu-item-selected"}
        className={`${pathname == href && "ant-menu-item-selected"} ${target}`}>
        <Link to={href}>{label}</Link>
      </Menu.Item>
    );
  };

  const { pathname } = useLocation();

  const Sidebar = lg ? Sider : Drawer;

  // {isHide ? <RegisterationMessageModal handleClickStart={undefined} /> : <BoardingJoyRide showDrawer={showDrawer} />}

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const siderSettings = {
    trigger: null,
    collapsible: true,
    defaultCollapsed: true,
    collapsedWidth: 0,
    collapsed: collapsed,
  };

  const drawerSettings = {
    closable: <MyImage src={closeImg} width={28} />,
    onClose: onClose,
    visible: visible,
    contentWrapperStyle: { width: sm ? 300 : 250 },
    bodyStyle: { padding: sm ? 0 : "0 24px 24px 0" },
    placement: i18n.language == "ar" ? "right" : "left",
  };

  const dataSettings = lg ? siderSettings : drawerSettings;

  const isValuedToShowOnBoarding =
    !loadingMe &&
    // me?.shop?.onBoardingFlag &&
    (me?.currentShop?.onBoardingFlag < 12 || me?.currentShop?.onBoardingFlag == null);

  return (
    <>
      <Layout>
        {isValuedToShowOnBoarding && <BoardingJoyRide showDrawer={showDrawer} />}
        <Sidebar {...dataSettings} className="sidebar-nav">
          {lg ? (
            <Link to={"/"}>
              <LogoContainer>
                <Logo
                  collapsed={collapsed}
                  src={
                    logo
                    // collapsed
                    //   ? logo
                    //   : logo
                  }
                  alt="logo"
                />
              </LogoContainer>
            </Link>
          ) : (
            <>
              <UserDropContainer>
                {/* <Dropdown
                overlay={menu}
                trigger={["click"]}
                arrow
                icon={<DownOutlined />}>
                <Button type="text" style={{ height: "unset" }}>
                  <Space>
                    <Avatar src={me?.avatar?.sm}>
                      {me.name.split(" ")[0]?.charAt(0).toUpperCase()}
                      {me.name.split(" ")[1]?.charAt(0).toUpperCase()}
                    </Avatar>
                    {me.name}
                  </Space>
                </Button>
              </Dropdown> */}
                <Space style={{ justifyContent: "space-between", width: "100%" }}>
                  <Space className="admin-boarding" onClick={showSiderDrop}>
                    <Avatar src={me?.avatar?.sm}>
                      {me.name?.split(" ")[0]?.charAt(0).toUpperCase()}
                      {me.name?.split(" ")[1]?.charAt(0).toUpperCase()}
                    </Avatar>
                    {/* {me.name} */}
                    <DownOutlined />
                  </Space>
                  {/* <UpgradeContainer>
                    <Button block={true} className="upgrade" onClick={onClose}>
                      <Link to={"/changePlan"}>
                        {t("home.upgrade")} <MyImage src={star} width={12} />
                      </Link>
                    </Button>
                  </UpgradeContainer> */}
                  <UpgradeContainer>
                    <Button block={true} type="link" className="view-store-boarding">
                      <a
                        href={"https://" + me.currentShop?.domain}
                        rel="noreferrer"
                        target="_blank">
                        {t("home.showStore")} <EyeOutlined />
                      </a>
                    </Button>
                  </UpgradeContainer>
                </Space>
              </UserDropContainer>
              <HiderDrop
                me={me}
                signOut={signOut}
                client={client}
                logout={logout}
                showDrop={showSiderDrop}
                toggle={toggle}
                hideDrop={hideDrop}
                closeSider={onClose}
              />
            </>
          )}
          {!lg && (
            <SubContainer>
              <UpgradeContainer>
                {me?.currentShop?.plan?.slug === "payAsUGo" ? (
                  <Button block={true} className="upgrade" onClick={onClose}>
                    <Link to={"/upgrade"}>
                      {"Pay As You Go"} <MyImage src={star} width={12} />
                    </Link>
                  </Button>
                ) : me?.currentShop?.plan?.slug === "payOnce" ? (
                  <Button block={true} className="upgrade" onClick={onClose}>
                    <Link to={"/upgrade"}>
                      {"Pay Once"} <MyImage src={star} width={12} />
                    </Link>
                  </Button>
                ) : (
                  <Button block={true} className="upgrade" onClick={onClose}>
                    <Link to={"/upgrade"}>
                      {t("home.upgrade")} <MyImage src={star} width={12} />
                    </Link>
                  </Button>
                )}
              </UpgradeContainer>

              <Button
                type="primary"
                ghost
                onClick={() => {
                  push("/addToBalance");
                  setVisible(false);
                }}>
                <Space align="center">
                  {t("home.myBalance")}
                  {me?.currentShop?.balance}&#36;
                </Space>
              </Button>
            </SubContainer>
          )}

          <MenuContainer>
            <Menu mode="inline" selectedKeys={0} onClick={onClose}>
              {sideArray({ t, userCurrentApps })
                ?.filter(filterSideBar)
                .map((item, i) => makeMenuItem(item, i))}
              <div
                style={{
                  padding: "50px 0px",
                }}
              />
            </Menu>
          </MenuContainer>
        </Sidebar>
        <CustomHeader
          me={data.me}
          children={children}
          setCollapsed={setCollapsed}
          collapsed={collapsed}
          setVisible={setVisible}
        />
      </Layout>
      {/* <HomeJoyRide /> */}
      {/* <OrderJoyRide /> */}
    </>
  );
};

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  padding: 16px 24px 26px;
  background: ${(props) => (props.theme.isBreakpoints.lg ? "#163663" : "#fff")};
`;

const UserDropContainer = styled.div`
  padding: 16px 17px;
  border-bottom: 0.5px solid ${(props) => props.theme.borderColors.gray2};
`;
export default CustomSider;
