import { CaretDownOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Button, ConfigProvider, Form, Image, Input, Modal, Select, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import phoneNote from "../../assets/imgs/phoneNumber.svg";
import { Breakpoints } from "../../constants";
import { UPDATE_MY_PROFILE } from "../../pages/profile/userData";
import { CURRENT_USER } from "../../utlis/Me";
import PhoneNumberInput from "../common/phoneInput";

const { Paragraph, Text } = Typography;
const { Option } = Select;

function useWindowResize(callback: () => void) {
  useEffect(() => {
    callback();
    window.addEventListener("resize", callback);
    return () => {
      window.removeEventListener("resize", () => {
        console.log("event removed");
      });
    };
  }, []);
}

const PhoneNumberPopup = () => {
  const [visible, setVisible] = useState(true);
  const [isMediumSize, setIsMediumSize] = useState(false);

  const { t } = useTranslation();
  const [edit, editProp] = useMutation(UPDATE_MY_PROFILE, {
    refetchQueries: [{ query: CURRENT_USER }],
  });

  const mediumResizeCallback = () => {
    const md = parseInt(Breakpoints.md.slice(0, -2));
    window.innerWidth >= md ? setIsMediumSize(true) : setIsMediumSize(false);
  };
  useWindowResize(mediumResizeCallback);

  const selectAfter = (
    <>
      <Form.Item name="phonePrefix" noStyle>
        <Select
          defaultValue="20"
          suffixIcon={<CaretDownOutlined className="down-arrow" />}
          className="select-after">
          <Option value="20">+20</Option>
          <Option value="966">+966</Option>
        </Select>
      </Form.Item>
    </>
  );
  const onFinish = (values: any) => {
    console.log("phoneNumberSuccess:", values);
    edit({ variables: { data: { ...values } } }).then(() => {
      setVisible(false);
    });
  };
  return (
    <Container>
      <Modal
        centered
        keyboard={false}
        closable={false}
        visible={visible}
        footer={null}
        width={isMediumSize ? 465 : 343}
        className="modal-body"
        bodyStyle={{ textAlign: "center", padding: "32px 40px 38px 39px" }}>
        <FirstTitle>
          <Image preview={false} src={phoneNote} width={58} />
          <Paragraph className="description">{t("home.addPhoneNum")}</Paragraph>
        </FirstTitle>
        <FormContainer>
          <ConfigProvider direction="ltr">
            <Form
              onFinish={onFinish}
              layout="vertical"
              initialValues={{ phonePrefix: "20" }}
              requiredMark="optional">
              <Space direction="vertical" className="phoneNumber">
                {/* <Form.Item
                label={<Text>{t("home.phoneNum")}</Text>}
                name="phone"
                rules={[{ required: true, message: t("home.pleaseInputNumber") }]}>
                <Input type="tel" addonAfter={selectAfter} className="input-number" />
              </Form.Item> */}
                <PhoneNumberInput
                  label={<Text>{t("home.phoneNum")}</Text>}
                  name="phone"
                  rules={[{ required: true, message: t("home.pleaseInputNumber") }]}
                />

                <Button
                  type="primary"
                  htmlType="submit"
                  loading={editProp.loading}
                  disabled={editProp.loading}
                  className="send-btn">
                  {t("home.send")}
                </Button>
              </Space>
            </Form>
          </ConfigProvider>
        </FormContainer>
      </Modal>
    </Container>
  );
};

export default PhoneNumberPopup;

const Container = styled.div``;

const FirstTitle = styled.div`
  .description {
    width: 327px;
    margin: 16px auto 13px;
    font-size: 16px;
    font-weight: 600;
    color: ${(props) => props.theme.colors.Gray2};
    @media (max-width: ${Breakpoints.md}) {
      width: 212px;
      font-size: 14px;
    }
  }
`;

const FormContainer = styled.div`
  .phoneNumber {
    width: 100%;
  }
  .phoneNumber-label {
    color: ${(props) => props.theme.colors.Gray1};
    font-weight: 600;
  }
  .input-number {
    width: 385px;
    @media (max-width: ${Breakpoints.md}) {
      width: 278px;
    }
  }
  .send-btn {
    width: 100%;
    height: 48px;
    padding: 12px 24px;
    background-color: ${(props) => props.theme.customColors.lightBlue};
    font-size: 16px;
    font-weight: 600;
  }
  .select-after {
    width: 74px;
    direction: ltr;
  }
  .down-arrow {
    color: ${(props) => props.theme.colors.Gray2};
  }
  .ant-input-group-addon:last-child {
    border: none;
    border-radius: 4px;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    box-shadow: none;
    background-color: ${(props) => props.theme.customColors.lightGray};
    border-color: ${(props) => props.theme.customColors.lightGray};
  }
  .ant-input-group > .ant-input:last-child .ant-select .ant-select-selector,
  .ant-input-group-addon:last-child .ant-select .ant-select-selector {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
`;
