import { gql } from "@apollo/client";

export const COUNT_NOT_SEEN = gql`
  query COUNT_NOT_SEEN {
    chatNotSeenNum
  }
`;

export const CURRENT_CURRENCY = gql`
  query SETTINGS {
    setting(where: { name: { equals: "web" } }) {
      id
      currencyBase {
        id
        code
        name
      }
    }
  }
`;
export const SEO_ITEM = gql`
  query SEO_ITEM($seoItemWhere: SeoItemWhereInput!) {
    seoItem(where: $seoItemWhere) {
      id
      title {
        id
        en
        ar
        auto
      }
      slug {
        id
        en
        ar
        auto
      }
      body {
        en
        id
        ar
        auto
      }
      fivicon {
        id
        original
        xl
        lg
      }
      follow
      indexed
      socialItems {
        id
        name
        enable
        image {
          id
          original
          xl
          lg
        }
        title {
          id
          en
          ar
          auto
        }
        body {
          id
          en
          ar
          auto
        }
      }
      extendTitle
    }
  }
`;

// export const ORDER_TAGS = gql`
//   query ORDER_TAGS($name: String, $type: TagType) {
//     tags(where: { name: { contains: $name }, type: { equals: $type } }, take: 10) {
//       nodes {
//         id
//         name
//       }
//     }
//   }
// `;
