import { EyeOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Col, Form, Radio, Row, Space } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import MyImage from "../../../../components/common/image";
import { Breakpoints } from "../../../../constants";
import { themes } from "../../../apps/app/theme/themes";

const CreateStoreStep3 = ({ setStep, medata }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState();

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  return (
    <Container>
      <article style={{ marginBottom: 12 }}>
        <h2 className="theming-title">{t("auth.createStore.selectTemplete")}</h2>
        <span className="theming-desc">{t("auth.createStore.useThemeWithStore")}</span>
      </article>
      <Form.Item name="themeSlug">
        <Radio.Group onChange={onChange} value={value}>
          <Row gutter={[20, 20]}>
            {/* <Col lg={6} md={12} sm={24} className="flex-col">
              <div
                className="add-theme"
                onClick={() => {
                  const addThemeUrl = "https://" + medata.me.currentShop?.domain + "/theme/home";
                  window.open(addThemeUrl, "_blank");
                }}>
                <PlusOutlined style={{ fontSize: 60, color: "#B8B8B8" }} />
              </div>
              <span className="app-title">{t("theme.addTheme")}</span>
            </Col> */}
            {themes.map((theme, index) => (
              <Col lg={6} md={12} key={index}>
                <div className="theme-card">
                  <Radio value={theme.template} disabled={!theme.template}>
                    <Space direction="vertical">
                      <div className="theme-preview">
                        <MyImage src={theme.img} />
                        <Button
                          type="primary"
                          className="show-templet-btn"
                          icon={<EyeOutlined />}
                          disabled={!theme.template}
                          href={theme.url}
                          target="_blank">
                          {"عرض القالب"}
                        </Button>
                      </div>
                      <span className="app-title">{theme.themeName}</span>
                    </Space>
                  </Radio>
                </div>
              </Col>
            ))}
          </Row>
        </Radio.Group>
      </Form.Item>
      <Row justify="space-between">
        <Col>
          <Button type="link" icon={<RightOutlined />} onClick={() => setStep(2)}>
            {t("auth.createStore.back")}
          </Button>
        </Col>
        <Col>
          <Button type="primary" onClick={() => setStep(4)}>
            {t("auth.createStore.next")}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateStoreStep3;

const Container = styled.div`
  overflow: hidden;
  .theming-title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 6px;
  }
  .theming-desc {
    font-size: 18px;
    font-weight: 600;
    color: ${(props) => props.theme.colors.Gray1};
  }
  .app-title {
    font-size: 16px;
    font-weight: 600;
  }
  @media (max-width: ${Breakpoints.sm}) {
    .theming-title {
      font-size: 20px;
    }
    .theming-desc {
      font-size: 14px;
    }
  }
  .theme-preview {
    max-height: 270px;
    overflow: hidden;
    position: relative;
    img {
      opacity: 1;
    }
    :hover {
      img {
        opacity: 0.3;
        transition: opacity 0.5s ease;
      }
    }
  }
  .show-templet-btn {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
  }
  .theme-preview:hover .show-templet-btn {
    display: block;
    opacity: 1;
  }
  .theme-card {
    height: 310px;
    max-height: 100%;
  }
  .ant-radio {
    align-self: flex-start;
  }
  a.ant-btn-disabled {
    color: #fff;
    background: var(--ant-primary-color-hover);
  }
  /* .add-theme {
    background: ${(props) => props.theme.customColors.white};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 270px;
    cursor: pointer;
  }
  .flex-col {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
  } */
`;
