import { Progress, Typography } from "antd";
import React from "react";

const ProgressStepsComponent = ({ step }) => {
  return (
    <div>
      <Typography.Paragraph className="steps-guid">{`الخطوة ${step}/4`}</Typography.Paragraph>
      <Progress
        showInfo={false}
        steps={4}
        percent={step === 1 ? 25 : step === 2 ? 50 : step === 3 ? 75 : 100}
        strokeLinecap="round"
        strokeColor="#2CB87A"
        trailColor="#DBF0E7"
        style={{ width: "100%" }}
      />
    </div>
  );
};

export default ProgressStepsComponent;
