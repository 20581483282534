import { CaretDownOutlined } from "@ant-design/icons";
import { gql, useQuery } from "@apollo/client";
import { Form, Input, Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Loading from "./Loading";

const COUNTRIES_PHONE = gql`
  query Query {
    countries(take: 100) {
      phone
    }
  }
`;

const { Option } = Select;

const PhoneNumberInput = (props) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(COUNTRIES_PHONE);
  if (loading) return <Loading />;
  const selectAfter = (
    <>
      <Form.Item name="phonePrefix" noStyle>
        <Select
          size="large"
          dropdownStyle={{ minWidth: 60 }}
          suffixIcon={<CaretDownOutlined className="down-arrow" />}
          className="select-after">
          {data?.countries.map((ct) => (
            <Option value={ct.phone}>{`+${ct.phone}`}</Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
  return (
    <Form.Item style={{ width: "100%" }} name={props.name} {...props}>
      <CustomInput
        placeholder={t("users.phone")}
        type="tel"
        addonAfter={selectAfter}
        className="input-number"
      />
    </Form.Item>
  );
};

export default PhoneNumberInput;

const CustomInput = styled(Input)`
  width: 100%;
  .ant-input-group-addon:last-child {
    border-radius: 4px 0 0 4px;
  }
  .ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-left: none;
    :hover {
      border-color: #d9d9d9;
      box-shadow: none;
    }
    :focus {
      border-color: #d9d9d9;
      box-shadow: none;
    }
  }
  .ant-input-group-rtl .ant-input-group-addon:first-child,
  .ant-input-group > .ant-input-rtl:first-child {
    border-radius: 0 4px 4px 0;
  }
`;
