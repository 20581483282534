import { getBase64 } from "../pages/theme/slides/addEdit";
import imageCompression from "browser-image-compression";
import { isVideo } from "../components/common/image";

const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

// const contentType = 'image/png';
// const b64Data = 'iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg==';

// const blob = b64toBlob(b64Data, contentType);
// const blobUrl = URL.createObjectURL(blob);

// const img = document.createElement('img');
// img.src = blobUrl;
// document.body.appendChild(img);

async function handleImageUpload(imageFile) {
  // const imageFile = event.target.files[0];
  console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
  console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

  const options = {
    maxSizeMB: 4,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  try {
    const compressedFile = await imageCompression(imageFile, options);
    console.log("compressedFile instanceof Blob", compressedFile instanceof Blob); // true
    console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

    return compressedFile; // write your own logic
  } catch (error) {
    console.log(error);
    return imageFile;
  }
}
export const uploadVideo = async (video) => {
  console.log("videovideo", { video });

  try {
    // let reader = new FileReader();
    // reader.readAsText(video);

    const data = new FormData();
    data.append("file", video);
    data.append("upload_preset", "storeB7r");
    data.append("multiple", true);
    console.log("dataaaa", { data });

    const res = await fetch("https://api.cloudinary.com/v1_1/dp5tdylzs/video/upload", {
      method: "POST",
      body: data,
    });
    console.log("resres", { res });
    const file = await res.json();
    console.log("uploadImage -> file", file);
    const image = {
      data: {
        original: file.secure_url,
        xl: file.eager[0].secure_url,
        sm: file.eager[1].secure_url,
        lg: file.eager[2].secure_url,
        md: file.eager[3].secure_url,
        cloudId: file.public_id,
        mediaType: "VIDEO",
      },
    };
    return image;
  } catch (error) {
    console.log("ERRRR", error.message);
  }
};
export const uploadImage = async (imageBase64) => {
  const compressedImage = await getBase64(await handleImageUpload(imageBase64));
  if (compressedImage.includes("video")) return await uploadVideo(compressedImage);

  const data = new FormData();
  data.append("file", compressedImage);
  data.append("upload_preset", "storeB7r");
  data.append("multiple", true);

  const res = await fetch("https://api.cloudinary.com/v1_1/dp5tdylzs/image/upload", {
    method: "POST",
    body: data,
  });
  const file = await res.json();
  console.log("uploadImage -> file", file);
  const image = {
    data: {
      original: file.secure_url,
      xl: file.eager[0].secure_url,
      sm: file.eager[1].secure_url,
      lg: file.eager[2].secure_url,
      md: file.eager[3].secure_url,
      cloudId: file.public_id,
    },
  };
  return image;
};

export const handleCompressImage = async data => {
  const compressedImage = await getBase64(await handleImageUpload(data));
  return compressedImage
}

export const uploadGallery = async (gallery) => {
  console.log("🚀 ~ file: upload.js ~ line 118 ~ uploadGallery ~ gallery", gallery);
  const arrayOfImageBase64 = (
    await Promise.allSettled(
      gallery.map(async (img) => {
        // if (img.type.includes("video")) return img;
        return await getBase64(await handleImageUpload(img.originFileObj));
      }),
    )
  ).map((final) => final.value);
  console.log("uploadGallery -> arrayOfImageBase64", arrayOfImageBase64);

  // res_promises will be an array of promises
  let res_promises = arrayOfImageBase64.map(
    (file) =>
      new Promise(async (resolve, reject) => {
        if (file.includes("video")) return resolve((await uploadVideo(file)).data);

        let data = new FormData();
        data.append("file", `${file}`);

        data.append("upload_preset", "storeB7r");
        try {
          const res = await fetch("https://api.cloudinary.com/v1_1/dp5tdylzs/image/upload", {
            method: "POST",
            body: data,
          });
          const file = await res.json();
          console.log("uploadGallery -> file", file);
          const image = {
            original: file.secure_url,
            xl: file.eager[0].secure_url,
            sm: file.eager[1].secure_url,
            lg: file.eager[2].secure_url,
            md: file.eager[3].secure_url,
            cloudId: file.public_id,
          };
          resolve(image);
        } catch (error) {
          console.log("error: ", error);
          if (error) reject(error);
        }
      }),
  );
  // Promise.all will fire when all promises are resolved
  return Promise.all(res_promises);
};
