import { Button, Space, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Breakpoints } from "../../../../constants";

const { Text, Title } = Typography;

const BannerComponent = () => {
  const { t } = useTranslation();
  return (
    <Conatiner>
      <Title level={3} className="hero-title">
        {t("plans.enrollManagement")}
      </Title>
      <Space direction="vertical" className="contents">
        <Text className="content-item">{t("plans.firstContent")}</Text>
        <Text className="content-item">{t("plans.secondContent")}</Text>
      </Space>
    </Conatiner>
  );
};

export default BannerComponent;

const Conatiner = styled.div`
  padding: 0 5px;
  .content-item {
    color: ${(props) => props.theme.colors.Gray2};
    font-weight: 500;
    font-size: 16px;
  }
  .contents {
    max-width: 740px;
  }

  .hero-title {
    color: ${(props) => props.theme.customColors.dark};
    font-weight: 700;
    margin-bottom: 20px;
    @media screen and (max-width: ${Breakpoints.sm}) {
      font-size: 18px;
    }
  }
`;
