import styled from "styled-components";

export const FormCard = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 15px;
  box-shadow: 0 0 10px 0 rgba(136, 152, 170, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  /* margin: 20px; */
  z-index: 10;
  position: relative;
  .ant-form-rtl .ant-form-item-label {
    text-align: right;
  }
  .ant-form-item-label > label {
    width: 100%;
  }
  .ant-upload-rtl.ant-upload.ant-upload-select-picture-card {
    overflow: auto;
  }
`;
