import { Typography } from "antd";

import { gql, useMutation, useQuery } from "@apollo/client";
import { UPDATE_ONBOARDING } from "../components/layout/sider/joyRideSider";
import TagManager from "react-gtm-module";
import { CURRENT_USER } from "../utlis/Me";

export const STORE_ACTIVATION = gql`
  query STORE_ACTIVATION {
    me {
      id

      currentShop {
        onBoardingFlag
        StoreActivation {
          id
          productsNumber
          editTheming
          editShipping
          editPaymentGatways
          editCheckoutSettings
          connectDomain
          installAffiliate
          installB7rChat
          connectTaager
          connectFaceBook
          connectFaceBookPixel
          installKnawat
          installB7rApp
          connectGoogleAnalytic
          connectGTM
          editLocations
          addUsers
          installPopUpApp
          useCustomersDivider
        }
      }
    }
  }
`;

const UPDATE_TORE_ACTIVATION = gql`
  mutation UPDATE_TORE_ACTIVATION(
    $productsNumber: Int
    $editTheming: Boolean
    $editShipping: Boolean
    $editPaymentGatways: Boolean
    $editCheckoutSettings: Boolean
    $connectDomain: Boolean
    $installAffiliate: Boolean
    $installB7rChat: Boolean
    $connectTaager: Boolean
    $connectFaceBook: Boolean
    $installKnawat: Boolean
    $installB7rApp: Boolean
    $installPopUpApp: Boolean
    $addUsers: Boolean
    $editLocations: Boolean
    $connectGTM: Boolean
    $connectGoogleAnalytic: Boolean
    $useCustomersDivider: Boolean
    $connectFaceBookPixel: Boolean
  ) {
    updateStoreActivation(
      editTheming: $editTheming
      productsNumber: $productsNumber
      editShipping: $editShipping
      editPaymentGatways: $editPaymentGatways
      editCheckoutSettings: $editCheckoutSettings
      connectDomain: $connectDomain
      installAffiliate: $installAffiliate
      installB7rChat: $installB7rChat
      connectTaager: $connectTaager
      connectFaceBook: $connectFaceBook
      installKnawat: $installKnawat
      installB7rApp: $installB7rApp
      installPopUpApp: $installPopUpApp
      addUsers: $addUsers
      editLocations: $editLocations
      connectGTM: $connectGTM
      useCustomersDivider: $useCustomersDivider
      connectGoogleAnalytic: $connectGoogleAnalytic
      connectFaceBookPixel: $connectFaceBookPixel
    ) {
      id
    }
  }
`;
const useActivation = () => {
  const { data, loading } = useQuery(CURRENT_USER, { fetchPolicy: "cache-first" });
  const [updateOnboarding, { loading: skipActivationLoading }] = useMutation(UPDATE_ONBOARDING, {
    refetchQueries: [{ query: STORE_ACTIVATION }],
  });
  const [updateStoreActivation] = useMutation(UPDATE_TORE_ACTIVATION, {
    refetchQueries: [{ query: STORE_ACTIVATION }],
  });

  //   updateOnboarding({
  //     variables: {
  //       onBoardingFlag: 0,
  //     },
  //   });

  const updateActivation = {
    increaseProduct: () => {
      TagManager.dataLayer({
        dataLayer: {
          event: "increaseProduct",
          productsNum: (data?.me?.currentShop?.StoreActivation?.productsNumber || 0) + 1,
        },
      });
      updateStoreActivation({
        variables: {
          productsNumber: (data?.me?.currentShop?.StoreActivation?.productsNumber || 0) + 1,
        },
      });
    },
    editTheming: () => {
      if (data?.me?.currentShop?.StoreActivation?.editTheming) return;
      TagManager.dataLayer({
        dataLayer: {
          event: "editTheming",
        },
      });
      updateStoreActivation({
        variables: {
          editTheming: true,
        },
      });
    },
    editShipping: () => {
      console.log(
        "🚀 ~ file: useActivation.ts ~ line 130 ~ useActivation ~ data?.me?.currentShop?.StoreActivation?.editShipping",
        data?.me?.currentShop?.StoreActivation?.editShipping,
      );

      if (data?.me?.currentShop?.StoreActivation?.editShipping) return;
      TagManager.dataLayer({
        dataLayer: {
          event: "editShipping",
        },
      });
      updateStoreActivation({
        variables: {
          editShipping: true,
        },
      });
    },
    editPaymentGatways: () => {
      console.log(
        "🚀 ~ file: useActivation.ts ~ line 130 ~ useActivation ~ data?.me?.currentShop?.StoreActivation?.editPaymentGatways",
        data?.me?.currentShop?.StoreActivation,
      );

      if (data?.me?.currentShop?.StoreActivation?.editPaymentGatways) return;
      TagManager.dataLayer({
        dataLayer: {
          event: "editPaymentGatways",
        },
      });
      updateStoreActivation({
        variables: {
          editPaymentGatways: true,
        },
      });
    },
    editCheckoutSettings: () => {
      if (data?.me?.currentShop?.StoreActivation?.editCheckoutSettings) return;
      TagManager.dataLayer({
        dataLayer: {
          event: "editCheckoutSettings",
        },
      });
      updateStoreActivation({
        variables: {
          editCheckoutSettings: true,
        },
      });
    },
    connectFaceBook: () => {
      if (data?.me?.currentShop?.StoreActivation?.connectFaceBook) return;
      TagManager.dataLayer({
        dataLayer: {
          event: "connectFaceBook",
        },
      });
      updateStoreActivation({
        variables: {
          connectFaceBook: true,
        },
      });
    },
    connectFaceBookPixel: () => {
      if (data?.me?.currentShop?.StoreActivation?.connectFaceBookPixel) return;
      TagManager.dataLayer({
        dataLayer: {
          event: "connectFaceBookPixel",
        },
      });
      updateStoreActivation({
        variables: {
          connectFaceBookPixel: true,
        },
      });
    },
    connectGoogleAnalytic: () => {
      if (data?.me?.currentShop?.StoreActivation?.connectGoogleAnalytic) return;
      TagManager.dataLayer({
        dataLayer: {
          event: "connectGoogleAnalytic",
        },
      });
      updateStoreActivation({
        variables: {
          connectGoogleAnalytic: true,
        },
      });
    },
    connectGTM: () => {
      if (data?.me?.currentShop?.StoreActivation?.connectGTM) return;
      TagManager.dataLayer({
        dataLayer: {
          event: "connectGTM",
        },
      });
      updateStoreActivation({
        variables: {
          connectGTM: true,
        },
      });
    },
    editLocations: () => {
      if (data?.me?.currentShop?.StoreActivation?.editLocations) return;
      TagManager.dataLayer({
        dataLayer: {
          event: "editLocations",
        },
      });
      updateStoreActivation({
        variables: {
          editLocations: true,
        },
      });
    },
    useCustomersDivider: () => {
      if (data?.me?.currentShop?.StoreActivation?.useCustomersDivider) return;
      TagManager.dataLayer({
        dataLayer: {
          event: "useCustomersDivider",
        },
      });
      updateStoreActivation({
        variables: {
          useCustomersDivider: true,
        },
      });
    },
    addUsers: () => {
      if (data?.me?.currentShop?.StoreActivation?.addUsers) return;
      TagManager.dataLayer({
        dataLayer: {
          event: "addUsers",
        },
      });
      updateStoreActivation({
        variables: {
          addUsers: true,
        },
      });
    },
    installPopUpApp: () => {
      if (data?.me?.currentShop?.StoreActivation?.installPopUpApp) return;
      TagManager.dataLayer({
        dataLayer: {
          event: "installPopUpApp",
        },
      });
      updateStoreActivation({
        variables: {
          installPopUpApp: true,
        },
      });
    },
    installB7rApp: () => {
      if (data?.me?.currentShop?.StoreActivation?.installB7rApp) return;
      TagManager.dataLayer({
        dataLayer: {
          event: "installB7rApp",
        },
      });
      updateStoreActivation({
        variables: {
          installB7rApp: true,
        },
      });
    },
    connectTaager: () => {
      if (data?.me?.currentShop?.StoreActivation?.connectTaager) return;
      TagManager.dataLayer({
        dataLayer: {
          event: "connectTaager",
        },
      });
      updateStoreActivation({
        variables: {
          connectTaager: true,
        },
      });
    },
    installKnawat: () => {
      if (data?.me?.currentShop?.StoreActivation?.installKnawat) return;
      TagManager.dataLayer({
        dataLayer: {
          event: "installKnawat",
        },
      });
      updateStoreActivation({
        variables: {
          installKnawat: true,
        },
      });
    },
    installAffiliate: () => {
      if (data?.me?.currentShop?.StoreActivation?.installAffiliate) return;
      TagManager.dataLayer({
        dataLayer: {
          event: "installAffiliate",
        },
      });
      updateStoreActivation({
        variables: {
          installAffiliate: true,
        },
      });
    },
    installB7rChat: () => {
      if (data?.me?.currentShop?.StoreActivation?.installB7rChat) return;
      TagManager.dataLayer({
        dataLayer: {
          event: "installB7rChat",
        },
      });
      updateStoreActivation({
        variables: {
          installB7rChat: true,
        },
      });
    },
    connectDomain: (record) => {
      if (data?.me?.currentShop?.StoreActivation?.connectDomain) return;
      TagManager.dataLayer({
        dataLayer: {
          event: "connectDomain",
          domain: record.domain,
        },
      });
      updateStoreActivation({
        variables: {
          connectDomain: true,
        },
      });
    },
  };

  return {
    ...(data?.me?.currentShop?.StoreActivation || {}),
    loading,
    onBoardingFlag: data?.me?.currentShop?.onBoardingFlag,
    skipActivationLoading,
    updateActivation,
    skipActivation: async () =>
      await updateOnboarding({
        variables: {
          onBoardingFlag: 13,
        },
      }),
  };
};

export default useActivation;
