import React, { useState, useEffect } from "react";

import { Input, TreeSelect } from "antd";
import { gql, useQuery } from "@apollo/client";
import debounce from "lodash/debounce";

// write a GraphQL query that asks for names and codes for all countries
const LIST_COUNTRIES = gql`
  query LIST_COUNTRIES($filter: CountryWhereInput) {
    countries(where: $filter, take: 100) {
      name
      code
      states {
        id
        name
        longCode
        cities {
          id
          name
        }
      }
    }
  }
`;
const LIST_CITIES = gql`
  query LIST_CITIES($filter: CityWhereInput, $state: String!, $country: String!) {
    myCityZone(where: $filter, take: 50, state: $state, country: $country) {
      count
      nodes {
        id
        name
      }
    }
  }
`;
const { SHOW_PARENT } = TreeSelect;
const CountriesSelect = ({
  style = {},
  whiteList,
  countriesOnly,
  checkable,
  statesOnly,
  getPerfix,
  cityOnly,
  state = undefined,
  country = undefined,
  ...props
}) => {
  const [search, setSearch] = useState("");
  const [visible, setVisible] = useState("");
  console.log("🚀 ~ file: countryRegionsSelectors.js ~ line 30 ~ whiteList", whiteList);
  useEffect(() => {
    if (visible) return;
    setSearch("");
  }, [visible]);

  const {
    previousData,
    data = previousData,
    loading,
  } = useQuery(LIST_COUNTRIES, {
    skip: statesOnly && whiteList?.length == 0,
    variables: {
      filter: {
        AND: [
          {
            name: whiteList?.length > 0 ? { in: whiteList } : undefined,
          },
          {
            OR: [
              {
                states: {
                  some: {
                    name: { contains: search, mode: "insensitive" },
                    // OR: [
                    //   { cities: { some: { contains: search, mode: "insensitive" } } },
                    //   { name: { contains: search, mode: "insensitive" } },
                    // ],
                  },
                },
              },
              { name: { contains: search, mode: "insensitive" } },
            ],
          },
        ],
      },
    },
  });
  const {
    previousData: cityPreviousData,
    data: cityData = cityPreviousData,
    loading: cityLoading,
  } = useQuery(LIST_CITIES, {
    skip: !country || !state,
    variables: {
      country,
      state,
      filter: {
        name: { contains: search, mode: "insensitive" },
      },
    },
  });
  console.log("data", data);

  const getTree = () => {
    console.log(
      "🚀 ~ file: countryRegionsSelectors.js ~ line 128 ~ data?.countries.reduce ~ data?.countries",
      statesOnly && data?.countries,
    );

    if (cityOnly)
      return (
        cityData?.myCityZone.nodes.map(({ name }) => {
          return {
            title: name,
            key: getPerfix ? `${name}-city` : `${name}`,
            value: getPerfix ? `${name}-city` : `${name}`,
          };
        }, []) || []
      );
    if (statesOnly)
      return (
        data?.countries.reduce((prev, { states }) => {
          return [
            ...prev,
            ...states?.map(({ name, longCode }) => ({
              title: name,
              key: getPerfix ? `${longCode || name}-state` : `${longCode || name}`,
              value: getPerfix ? `${longCode || name}-state` : `${longCode || name}`,
            })),
          ];
        }, []) || []
      );
    return (
      data?.countries.map(({ name, states }) => ({
        title: name,

        key: getPerfix ? `${name}-country` : `${name}`,
        value: getPerfix ? `${name}-country` : `${name}`,
        children:
          !countriesOnly &&
          states?.map(({ name, cities, longCode }) => ({
            title: name,
            key: getPerfix ? `${longCode || name}-state` : `${longCode || name}`,
            value: getPerfix ? `${longCode || name}-state` : `${longCode || name}`,
            children: cities?.map(({ name }) => ({
              title: name,
              key: getPerfix ? `${name}-city` : `${name}`,
              value: getPerfix ? `${name}-city` : `${name}`,
            })),
          })),
      })) || []
    );
  };
  const tree = getTree();
  console.log("🚀 ~ file: countryRegionsSelectors.js ~ line 175 ~ tree.length", tree);
  console.log("🚀 ~ file: countryRegionsSelectors.js ~ line 176 ~ loading", loading);

  console.log("🚀 ~ file: countryRegionsSelectors.js ~ line 178 ~ cityLoading", cityLoading);
  console.log("🚀 ~ file: countryRegionsSelectors.js ~ line 179 ~ search", search);

  return (
    <>
      {tree.length || loading || cityLoading || countriesOnly || search ? (
        <TreeSelect
          {...props}
          style={{ width: "100%", ...style }}
          // treeDataSimpleMode
          onSearch={debounce((val) => setSearch(val), 200)}
          showSearch
          treeCheckable={checkable}
          treeCheckStrictly={checkable}
          showCheckedStrategy={SHOW_PARENT}
          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
          treeData={tree}
          loading={loading || cityLoading}

          // autocomplete="off"
          // autoFill="off"
          // disabled={!data?.myCountryZone.nodes.length}
        />
      ) : (
        <Input {...props} style={{ width: "100%", ...style }} />
      )}
    </>
    // <TreeSelect
    //   {...props}
    //   style={{ width: "100%", ...style }}
    //   // treeDataSimpleMode
    //   onDropdownVisibleChange={setVisible}
    //   onSearch={debounce((val) => setSearch(val), 200)}
    //   showSearch
    //   treeCheckable={checkable}
    //   treeCheckStrictly={checkable}
    //   showCheckedStrategy={SHOW_PARENT}
    //   dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
    //   filterTreeNode={false}
    //   treeData={tree}
    //   // placeholder="Search in Zones"
    // />
  );
};

export default CountriesSelect;
