import styled from "styled-components";


export const SubMenuContainer = styled.div`
  .ant-menu-sub.ant-menu-inline,
  .ant-menu-item,
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
  .ant-menu-item:active,
  .ant-menu-submenu-title:active {
    background: transparent;
    &:after {
      display: none;
    }
  }
  .ant-menu-item-group-title {
    display: none;
  }
  .ant-menu-submenu-title,
  .ant-menu-submenu-arrow {
    font-weight: 600;
    font-size: 16px;
    color: ${(props) => props.theme.colors.black3};
  }
  .ant-checkbox-wrapper {
    align-items: center;
  }
  .ant-checkbox + span {
    font-size: 16px;
    font-weight: 400;
    color: ${(props) => props.theme.colors.Gray2};
  }
  .reset-btn {
    text-align: end;
  }
`;
