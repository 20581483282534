import styled from "styled-components";

export const RootContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const UserAvatarImage = styled.div`
  width: 200px;
  height: 200px;
  position: relative;
  border-radius: 50%;
  border: 1px solid grey;
  display: flex;
  justify-content: center;
  background-image: url(${(props) => props.img});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  /* overflow: hidden; */
  &:hover {
    .image {
      opacity: 0.8;
    }
    cursor: pointer;
    /* button {
      opacity: 0.85;
      display: block;
    } */
  }
`;
export const AddImage = styled.img`
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 5%;
  right: 0%;
  z-index: 999;
  &:hover,
  ${UserAvatarImage}:hover & {
    cursor: pointer;
    filter: brightness(95%);
  }
`;

export const ChangeAvatarButton = styled.button`
  position: absolute;
  display: none;
  text-align: center;
  bottom: -5px;
  font-weight: bolder;
  background-color: #2c3e50;
  color: white;
  opacity: 0.7;
  width: 100%;
  height: 70px;
  border-radius: 0 0 50% 50%;
`;
