function setToValue(obj, value, path) {
  var i;
  path = path.split(".");
  for (i = 0; i < path.length - 1; i++) obj = obj[path[i]];

  obj[path[i]] = value;
}
export function getValueOfNestedObject(obj, path) {
  var i;
  path = path.split(".");
  for (i = 0; i < path.length - 1; i++) obj = obj[path[i]];

  return obj[path[i]];
}
export const makeArrayUniqe = (array, id = "id", sortPath?, reverseOverRide?) => {
  console.log("🚀 ~ file: makeArrayUniqe.ts ~ line 16 ~ makeArrayUniqe ~ sortPath", sortPath);
  const obj = {};

  array.map((item, indexNum) => {
    obj[item[id]] = {
      indexNum,
      ...(obj[item[id]] || {}),
      ...item,
      ...(reverseOverRide ? obj[item[id]] || {} : {}),
    };
  });
  let arr = Object.values(obj);
  if (sortPath) {
    arr = arr.sort((a, b) => {
      console.log(
        "🚀 ~ file: makeArrayUniqe.ts ~ line 26 ~ makeArrayUniqe ~ getValueOfNestedObject(a, sortPath)",
        getValueOfNestedObject(a, sortPath),
      );

      return getValueOfNestedObject(a, sortPath) - getValueOfNestedObject(b, sortPath);
    });
    console.log("🚀 ~ file: makeArrayUniqe.ts ~ line 31 ~ arr.sort ~ arr", arr);
  }
  console.log("🚀 ~ file: makeArrayUniqe.ts ~ line 31 ~ arr.sort ~ arrno", arr);

  return arr;
};
