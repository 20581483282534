import { gql, useQuery } from "@apollo/client";
import { Select, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomSearch from "../../../../components/common/customSearch";
// import { INVOICES } from "../../invoices";
import TransferOrderDetailsPopup from "./transferOrderDetails";
import { typeRender } from "./usableUtils";

const { Option } = Select;

const INVOICES = gql`
  query INVOICES(
    $skip: Int = 0
    $orderBy: InvoiceOrderByWithRelationInput
    $where: InvoiceWhereInput
  ) {
    invoices(skip: $skip, take: 10, orderBy: $orderBy, where: $where) {
      count
      nodes {
        id
        type
        value
        createdAt
        method
        plan
        start
        end
        service {
          name {
            auto
          }
        }
        orders {
          id
          dueAmount
          createdAt
          total
        }
      }
    }
  }
`;

const BalanceTabComponent = () => {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState({ current: 1, total: 0 });
  const [search, setSearch] = useState(0);
  const [selectMethod, setSelectMethod] = useState("");

  const orArr = [];
  const searchId = Number(search);
  if (Number.isSafeInteger(searchId) && searchId < 999999999 && searchId)
    orArr.push({ id: { equals: searchId } });
  if (selectMethod?.length) orArr.push({ type: { equals: selectMethod } });

  const variables = {
    skip: (pagination.current - 1) * 10,
    orderBy: { createdAt: "desc" },
    where: {
      AND: [{ AND: orArr }, { status: { equals: "PAID" } }],
    },
  };
  const { data, loading: invoicesLoading } = useQuery(INVOICES, {
    variables: variables,
  });
  console.log("🚀 ~ file: balance.tsx ~ line 17 ~ BalanceTabComponent ~ data", data);
  useEffect(() => {
    setPagination({
      ...pagination,
      total: data?.invoices?.count,
    });
  }, [data]);
  const balanceCol = [
    {
      title: t("users.operationNum"),
      align: "center",
      dataIndex: "id",
      render: (record) => `#${record}`,
    },
    {
      title: t("users.operationType"),
      align: "center",
      dataIndex: "type",
      render: (record) => typeRender(record),
    },
    {
      title: t("discounts.amount"),
      align: "center",
      dataIndex: "value",
    },
    {
      title: t("users.operationDate"),
      align: "center",
      dataIndex: "createdAt",
      render: (record) => moment(record).format("DD/MM/YYYY h:mm A"),
    },
    {
      align: "center",
      render: (record) => <TransferOrderDetailsPopup data={record} key={record.id} />,
    },
  ];

  const metodData = [
    {
      value: "PLAN",
      label: "تجديد الاشتراك",
    },
    {
      value: "SERVICE",
      label: "شراء خدمة",
    },
    {
      value: "ADD_TO_BALANCE",
      label: "إضافة رصيد",
    },
    {
      value: "DUE_AMOUNT",
      label: "سحب المبالغ المستحقة",
    },
  ];
  const handleTableChange = (pagination) => {
    setPagination({ ...pagination, current: pagination.current });
  };
  const onSearch = (value) => {
    setSearch(value);
  };
  const handleChange = (value: string) => {
    setSelectMethod(value);
  };
  return (
    <div>
      <div className="search-col">
        <Select allowClear style={{ width: 160 }} placeholder="نوع العملية" onChange={handleChange}>
          {metodData.map((item) => (
            <Option value={item.value}>{item.label}</Option>
          ))}
        </Select>
        <CustomSearch
          placeholder="ابحث عن رقم العملية"
          onSearch={onSearch}
          allowClear
          style={{ maxWidth: 395 }}
        />
      </div>
      <Table
        bordered
        scroll={{ x: 500 }}
        className="operation-table"
        columns={balanceCol}
        dataSource={data?.invoices.nodes}
        onChange={handleTableChange}
        loading={invoicesLoading}
        pagination={{ ...pagination, hideOnSinglePage: true, position: ["bottomCenter"] }}
      />
    </div>
  );
};

export default BalanceTabComponent;
