//@ts-nocheck
import { useQuery } from "@apollo/client";
import { Switch, Button } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { SETTINGS } from "../../pages/settings/generals";

export const AppLanguageSwitch = ({
  isEn,
  setIsEn,
  input,
  setFirstLangLoad,
  firstLangLoad,
}: any) => {
  console.log("🚀 ~ file: websiteLanguage.js ~ line 9 ~ AppLanguageSwitch ~ isEn", isEn);
  const { t, i18n } = useTranslation();
  const { data, loading } = useQuery(SETTINGS, { fetchPolicy: "cache-first" });
  console.log("🚀 ~ file: websiteLanguage.js ~ line 11 ~ AppLanguageSwitch ~ loading", loading);
  const [firstLoad, setFirstLoaded] = useState(false);
  console.log(`asdasd: `, data);

  // !firstLoad && setIsEn(data?.setting?.defaultLanguage !== "AR");
  // !firstLoad && setFirstLoaded(true);
  useEffect(() => {
    if (data?.setting?.defaultLanguage) {
      setIsEn(data?.setting?.defaultLanguage !== "AR");
      !firstLangLoad && setFirstLangLoad && setFirstLangLoad(true);
    }
  }, [data]);

  if (loading) return null;

  if (data?.setting?.languages.length > 1) {
    return (
      <Container input={input} language={i18n.language} isEn={isEn}>
        <Button type="primary" className="arabic-btn" onClick={() => setIsEn(false)}>
          {input ? "ع" : " عربي"}
        </Button>
        <Button className="english-btn" onClick={() => setIsEn(true)}>
          {input ? "En" : "English"}
        </Button>
        {/* <Switch
          style={{ marginTop: "20px" }}
          checkedChildren="EN"
          unCheckedChildren="AR"
          onChange={() => setIsEn(!isEn)}
          defaultChecked={data?.setting.defaultLanguage && data?.setting.defaultLanguage !== "AR"}
        /> */}
      </Container>
    );
  }

  if (data?.setting?.languages.length == 1) {
    if (data?.setting.languages[0] == "AR") {
      setIsEn(false);
      return null;
    } else {
      setIsEn(true);
      return null;
    }
  } else {
    if (data?.setting?.defaultLanguage == "AR") {
      setIsEn(false);
      return null;
    } else {
      setIsEn(true);
      return null;
    }
  }
};

const Container = styled.span`
  .arabic-btn,
  .english-btn {
    position: absolute;
    height: ${(props) => (props.input ? "20px" : "50px")};
    width: ${(props) => (props.input ? "42px" : "100px")};
    border-radius: ${(props) => (props.input ? "8px 8px 0 0" : "8px")};
    z-index: ${(props) => (props.input ? 10 : 0)};
    padding-bottom: 26px;
    color: #000;
    border-color: transparent;
    box-shadow: -1px 0px 6px 0px #f3f3f3;
    transform: translateY(-9px);
  }

  .ant-btn:hover {
    background-color: #fff;
    border-color: #fff;
  }

  .arabic-btn {
    top: ${(props) => (props.input ? "8px" : "-30px")};
    left: ${(props) => (props.language === "ar" ? "8px" : "unset")};
    right: ${(props) => (props.language === "en" ? "8px" : "unset")};
    background: ${(props) => (props.isEn ? "#f0f0f0" : "#fff")};
    color: ${(props) => (props.isEn ? "#BDBDBD" : "#6EBBC5")};
  }

  .english-btn {
    top: ${(props) => (props.input ? "8px" : "-30px")};
    left: ${(props) => (props.language === "en" ? "unset" : props.input ? "56px" : "116px")};
    right: ${(props) => (props.language === "ar" ? "unset" : props.input ? "56px" : "116px")};

    background: ${(props) => (props.isEn ? "#fff" : "#f0f0f0")};
    color: ${(props) => (props.isEn ? "#6EBBC5" : "#BDBDBD")};
  }

  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    background-color: #fff;
    border-color: #fff;
  }
`;
