import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Typography,
} from "antd";

import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import TagManager from "react-gtm-module";

import { useHistory } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { MeType } from "../../../types/METype";
import { CURRENT_USER } from "../../../utlis/Me";
import { useTranslation } from "react-i18next";
import { DollarOutlined, PlusOutlined, WalletOutlined } from "@ant-design/icons";
import dollars from "../../../assets/imgs/dollars.svg";
import MyImage from "../../../components/common/image";
import moment from "moment";
import CustomSearch from "../../../components/common/customSearch";
import { Breakpoints } from "../../../constants";
import { INVOICES } from "../invoices";
import AlertMessage from "../../../components/common/alertMessage";
import BalanceRecord from "./balanceRecord";
import VodafoneCashHintComponent from "./vodafoneCashHint";
import Loading from "../../../components/common/Loading";

const { Text, Title } = Typography;

interface PlanContextProps {
  currentUserPlan: number;
  loading: boolean;
  period: number;
  plan: number;
  setPeriod: (period: number) => void;
  setPlan: (plan: number) => void;
  addInvoice: () => Promise<void>;
}
const PlanContext = React.createContext<Partial<PlanContextProps>>({});

export const ADD_TO_BALANCE = gql`
  mutation ADD_TO_BALANCE($value: Float!) {
    addToBalance(value: $value) {
      id
      value
    }
  }
`;
const { Option } = Select;
const RenewPlan = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  // const [pagination, setPagination] = useState({ current: 1, total: 0 });

  const { data, loading: meLoading } = useQuery(CURRENT_USER);
  const [addToBalanceMutation, { loading }] = useMutation(ADD_TO_BALANCE);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const variables = {
  //   skip: (pagination.current - 1) * 10,
  // };
  // const { data: invoicesData, loading: invoicesLoading } = useQuery(INVOICES, {
  //   variables: variables,
  // });
  // useEffect(() => {
  //   setPagination({
  //     ...pagination,
  //     total: invoicesData?.invoices?.count,
  //   });
  // }, [data]);
  const me = data?.me as MeType;

  const [value, setValue] = useState(5);
  // const handleTableChange = (pagination) => {
  //   setPagination({ ...pagination, current: pagination.current });
  // };
  const addInvoice = async () => {
    const { data: newInvoice } = await addToBalanceMutation({
      variables: {
        value,
      },
    });

    const id = newInvoice.addToBalance.id;
    if (id) {
      TagManager.dataLayer({
        dataLayer: {
          event: "needToAddToBalance",
          value,
        },
      });
      push(`/invoices/${id}`);
    }
  };

  // const balanceCol = [
  //   {
  //     title: t("users.operationNum"),
  //     dataIndex: "id",
  //   },
  //   {
  //     title: t("users.operationType"),
  //     dataIndex: "type",
  //   },
  //   {
  //     title: t("discounts.amount"),
  //     dataIndex: "price",
  //   },
  //   {
  //     title: t("users.operationDate"),
  //     dataIndex: "createdAt",
  //     render: (record) => moment(record).format("DD/MM/YYYY h:mmA"),
  //   },
  // ];

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const onFinish = (values: any) => {
    console.log("succes", values);
    setIsModalVisible(false);
  };
  // if (meLoading) return <Loading />;
  return (
    <Container>
      <Row gutter={[0, 30]}>
        <Col span={24}>
          <Title level={3} style={{ fontWeight: 700 }}>
            {t("users.electronicWallet")}
          </Title>
        </Col>
        <Col span={24}>
          <AlertMessage banner={true} message={t("users.plansInformation")} type="info" />
          <Card>
            <div className="balance-header">
              <div className="balance-title">
                {/* <MyImage src={dollars} width={24} /> */}
                <DollarOutlined color="#4F4F4F" style={{ fontSize: 20, color: "#4F4F4F" }} />
                <span className="balance">{t("apps.amonut")}</span>
              </div>
              <Text strong className="balance-desc">
                {t("users.balanceDesc")}
              </Text>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                loading={loading}
                disabled={loading}
                className="addBalance-btn"
                onClick={() => setIsModalVisible(true)}>
                {t("users.addBalance")}
              </Button>
            </div>
            <Text className="balance-amount">{me?.currentShop?.balance} &#36;</Text>
            <Divider />
            <Space direction="vertical" style={{ width: "100%" }}>
              <div className="balance-title">
                <WalletOutlined color="#4F4F4F" style={{ fontSize: 20, color: "#4F4F4F" }} />
                <span className="balance">{t("users.deserveMoney")}</span>
                <span className="balance-desc">{t("users.deserveDiscountWallet")}</span>
              </div>
              <Text className="balance-amount">
                {Math.round((me?.currentShop?.dueAmount + Number.EPSILON) * 100) / 100} &#36;
              </Text>
            </Space>
            <VodafoneCashHintComponent />
          </Card>
        </Col>
        <Col span={24}>
          <Title level={4}>{t("users.operationRecord")}</Title>
          <BalanceRecord />
          {/* <div className="search-col">
            <Select style={{ width: 130 }}>
              <Option value="addBalance">{t("users.addBalance")}</Option>
              <Option value="affiliate">{t("home.affiliate")}</Option>
            </Select>
            <CustomSearch
              placeholder={t("users.searchForOperation")}
              // onSearch={onSearch}
              // value={searchVal}
              allowClear
              // onChange={(val) => {
              //   setSearchVal(val.target.value);
              // }}
              style={{ maxWidth: 395 }}
            />
          </div> */}
          {/* <Table
            bordered
            scroll={{ x: 500 }}
            className="operation-table"
            columns={balanceCol}
            dataSource={invoicesData?.invoices.nodes}
            onChange={handleTableChange}
            loading={invoicesLoading}
            pagination={{ ...pagination, hideOnSinglePage: true, position: ["bottomCenter"] }}
          /> */}
        </Col>
      </Row>
      <Modal
        title={<Title level={5}>{t("users.addBalance")}</Title>}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}>
        <Form onFinish={onFinish} layout="vertical">
          <Form.Item label={t("users.addAmount")}>
            <InputNumber
              prefix="&#36;"
              disabled={loading}
              value={value}
              min={5}
              max={10000}
              onChange={(val) => setValue(val)}
              style={{ width: "100%", direction: "ltr", padding: "0 15px" }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              icon={<PlusOutlined />}
              loading={loading}
              disabled={loading}
              type="primary"
              onClick={addInvoice}
              style={{ float: "left" }}>
              {t("order.next")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {/* <Card
        style={{ maxWidth: 500, margin: "0 auto" }}
        headStyle={{ textAlign: "center" }}
        title={"اشحن محفظتك"}>
        <Row align="middle" justify="center">
          <Col>
            <Space align="center">
              <InputNumber
                style={{ width: "100%" }}
                disabled={loading}
                value={value}
                min={5}
                onChange={(val) => setValue(val)}
              />
              <Button
                style={{ height: 48 }}
                loading={loading}
                disabled={loading}
                onClick={addInvoice}>
                اشحن
              </Button>
            </Space>
          </Col>
        </Row>
        <Divider />
        <Space align="center">
          رصيدي الحالي
          {me.balance}$
        </Space>
      </Card> */}
    </Container>
  );
};
const Container = styled.div`
  position: relative;
  .col-container {
    background-color: ${(props) => props.theme.customColors.white};
    border-radius: 4px;
    padding: 8px 12px;
  }
  .balance {
    font-weight: 600;
    font-size: 18px;
  }
  .balance-amount {
    font-size: 25px;
    font-weight: 700;
    color: ${(props) => props.theme.customColors.primary};
  }
  .operation-table {
    border-radius: 12px;
    margin-top: 25px;
  }
  .search-col {
    display: flex;
    gap: 8px;
    flex-wrap: nowrap;
  }
  .balance-header {
    display: grid;
    grid-template-columns: 85px 5fr;
    grid-template-rows: 40px 1fr;
    grid-auto-flow: row;
    align-items: baseline;
    grid-template-areas:
      "title desc btn"
      ". . .";
    @media (max-width: ${Breakpoints.sm}) {
      grid-template-areas:
        "title . btn"
        "desc desc desc";
    }
  }

  .addBalance-btn {
    grid-area: btn;
  }

  .balance-title {
    grid-area: title;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
    color: ${(props) => props.theme.colors.Gray2};
  }

  .balance-desc {
    grid-area: desc;
    margin: 0 4px;
    color: ${(props) => props.theme.colors.Gray2};
  }
`;

export default RenewPlan;

export const usePlanContext = () => useContext(PlanContext);
