import React, { useState, useEffect, useReducer } from "react";

import { Button, Space, Tag, Input, Radio, Switch } from "antd";
import format from "date-fns/format";
import { SearchOutlined } from "@ant-design/icons";
import CategoriesSelect from "../../../../components/common/selectors/categorySelector";

import { Link } from "react-router-dom";
import formatMoney from "../../../../utlis/formatMoney";
import MyImage from "../../../../components/common/image";
import ProductTypeSelector from "../../../../components/common/selectors/ProductTypeSelector";
import qs from "qs";
import BrandSelector from "../../../../components/common/selectors/BrandSelector";
import useFilter, {
  handlePrismaFilter,
  radioStyle,
} from "../../../../components/common/hooks/useFilter";
import { useTranslation } from "react-i18next";
import moment from "moment";

const reducerMap = {
  categories: {
    name: "CATS",
    filterType: "arrayR",
  },
  type: {
    name: "TYPES",
    filterType: "singleR",
  },
  brand: {
    name: "BRANDS",
    filterType: "singleR",
  },
  title: {
    name: "TITLE",
    filterType: "mText",
  },
  inventory: {
    name: "INVENTORY",
    filterType: "inventory",
  },
};
export default function useProductFilter(pagination, setPagination, updateProductPublished) {
  const { t } = useTranslation();
  const [publish, setBublish] = useState(true);

  function onChange(checked) {
    console.log(`switch to ${checked}`);
  }
  const reducer = (handleParams) => (state, action) => {
    switch (action.type) {
      case reducerMap.categories.name:
        const categories = action.selectedKeys?.length
          ? action.selectedKeys.map(({ value }) => value)
          : undefined;
        console.log("🚀 ~ file: useProductFilter.js ~ line 49 ~ reducer ~ categories", categories);
        handleParams({ categories });
        console.log(
          "🚀 ~ file: useProductFilter.js ~ line 54 ~ reducer ~ selectedKeys",
          action.selectedKeys,
        );
        return {
          ...state,
          categories: handlePrismaFilter("arrayR", {
            filterVal: categories,
          }),
        };
      case reducerMap.type.name:
        const type = action.selectedKeys?.length
          ? action.selectedKeys.map(({ value }) => value)
          : undefined;
        handleParams({ type });
        return {
          ...state,
          type: handlePrismaFilter("singleR", { filterVal: type }),
        };
      case reducerMap.brand.name:
        const brand = action.selectedKeys?.length
          ? action.selectedKeys.map(({ value }) => value)
          : undefined;

        handleParams({ brand });
        return {
          ...state,
          brand: handlePrismaFilter("singleR", { filterVal: brand }),
        };

      case reducerMap.title.name:
        const title = action.selectedKeys;

        handleParams({ title });
        return {
          ...state,
          title: handlePrismaFilter("mText", { filterVal: title }),
        };

      case reducerMap.inventory.name:
        const inventory = action.selectedKeys;

        handleParams({ inventory });
        return {
          ...state,
          quantityInLocations: undefined,
          trackQuantity: undefined,
          ...handlePrismaFilter("inventory", {
            filterVal: inventory,
          }),
        };

      case "SET_INIT":
        return {
          ...state,
          ...action.params,
        };

      case "RESET_FILTER":
        handleParams("reset");
        return {};
      default:
        return state;
    }
  };

  const {
    // handleReset,
    // handleSearch,
    query,
    dispatch,
    queryFilter,
    getColumnSearchProps,
    getSelectFilter,
    getColumnRadioProps,
    ...restFilters
  } = useFilter(pagination, setPagination, reducer, reducerMap);
  // ProductTypeSelector;
  const columns = () => {
    // const getSelectFilter = (FilterSelect, type) => {
    //   return {
    //     filteredValue: query[type],
    //     //queryFilter[type]
    //     // ? handlePrismaFilter(
    //     //     reducerMap[type].filterType,
    //     //     queryFilter[type],
    //     //     true
    //     //   )
    //     // : null,
    //     filterDropdown: ({
    //       setSelectedKeys,
    //       selectedKeys,
    //       confirm,
    //       clearFilters,
    //     }) => (
    //       <div style={{ padding: 8 }}>
    //         <FilterSelect
    //           // ref={(node) => {
    //           //   searchInput = node;
    //           // }}
    //           initialSlugs={queryFilter[type]}
    //           value={selectedKeys}
    //           style={{ width: 188, marginBottom: 8, display: "block" }}
    //           onChange={(val) => {
    //             setSelectedKeys(val || []);
    //           }}
    //           checkable
    //         />

    //         <Space>
    //           <Button
    //             type="primary"
    //             onClick={() => handleSearch(selectedKeys, confirm, type)}
    //             icon={<SearchOutlined />}
    //             size="small"
    //             style={{ width: 90 }}
    //           >
    //             Search
    //           </Button>
    //           <Button
    //             onClick={() => handleReset(clearFilters, type)}
    //             size="small"
    //             style={{ width: 90 }}
    //           >
    //             Reset
    //           </Button>
    //         </Space>
    //       </div>
    //     ),
    //     filterIcon: (filtered) => (
    //       <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    //     ),
    //   };
    // };

    const catsColumn = {
      title: t("analytics.categories"),
      dataIndex: "categories",
      // ellipsis: true,
      // width: 200,
      // colSpan: 1,
      // width: "10%",
      align: "center",
      render: (categories) => (
        <div
          size="small"
          style={{
            maxWidth: 200,
            wordWrap: "break-word",
            wordBreak: "break-word",
          }}>
          {categories?.map(({ name }) => (
            <Tag>{name?.auto} </Tag>
          ))}
        </div>
      ),
      // ...getSelectFilter(CategoriesSelect, "categories"),
    };
    // const typesColumn = {
    //   title: t("analytics.type"),
    //   align: "center",
    //   dataIndex: "type",
    //   render: (record) => record?.name,
    //   ...getSelectFilter(ProductTypeSelector, "type"),
    // };
    const brandColumn = {
      title: t("analytics.brand"),
      dataIndex: "brand",
      align: "center",
      // width: "15%",
      render: (record) => record?.name,
      // ...getSelectFilter(BrandSelector, "brand"),
    };

    // const getColumnSearchProps = (type) => ({
    //   ...getSelectFilter(),
    //   filteredValue: [query[type]],
    //   //  queryFilter[type]
    //   //   ? [
    //   //       handlePrismaFilter(
    //   //         reducerMap[type].filterType,
    //   //         queryFilter[type],
    //   //         true
    //   //       ),
    //   //     ]
    //   //   : null,
    //   filterDropdown: ({
    //     setSelectedKeys,
    //     selectedKeys,
    //     confirm,
    //     clearFilters,
    //   }) => (
    //     <div style={{ padding: 8 }}>
    //       <Input
    //         // ref={(node) => {
    //         //   searchInput = node;
    //         // }}
    //         placeholder={`Search ${type}`}
    //         value={selectedKeys[0]}
    //         onChange={(e) => {
    //           setSelectedKeys(e.target.value ? [e.target.value] : []);
    //         }}
    //         onPressEnter={() => {
    //           handleSearch(selectedKeys[0], confirm, type);
    //         }}
    //         style={{ width: 188, marginBottom: 8, display: "block" }}
    //       />
    //       <Space>
    //         <Button
    //           type="primary"
    //           onClick={() => {
    //             handleSearch(selectedKeys[0], confirm, type);
    //           }}
    //           icon={<SearchOutlined />}
    //           size="small"
    //           style={{ width: 90 }}
    //         >
    //           Search
    //         </Button>
    //         <Button
    //           onClick={() => handleReset(clearFilters, type)}
    //           size="small"
    //           style={{ width: 90 }}
    //         >
    //           Reset
    //         </Button>
    //       </Space>
    //     </div>
    //   ),
    // });
    const titleColumn = {
      title: t("analytics.product"),
      align: "start",
      width: "270px",
      // ...getColumnSearchProps("Companies"),
      render: (record) => (
        <Space>
          <MyImage
            image={record.mainImage}
            width={50}
            height={50}
            style={{
              borderRadius: "50px",
            }}
          />
          <Link to={`/product/${record.id}`}>{record.title?.auto}</Link>
        </Space>
      ),

      // ...getColumnSearchProps("title"),
    };
    const inventoryColumn = {
      title: t("analytics.inventory"),
      align: "center",
      width: "20%",
      render: (record) => {
        // console.log(
        //   "🚀 ~ file: useProductFilter.js ~ line 319 ~ columns ~ record.quantityInStock",
        //   record.id,
        //   ">>>>>",
        //   record.quantityInStock,
        // );
        // let variantNumber = 0;
        // if (
        //   record.variants.length &&
        //   !record.variants.find((variant) => variant.trackQuantity === true)
        // ) {
        //   if (
        //     (variantNumber =
        //       record.variants.reduce((prv, cur) => cur.quantityInStock + prv, 0) +
        //         record.quantityInStock ===
        //       0)
        //   ) {
        //     return t("analytics.notTracked");
        //   }
        // }

        return record.trackQuantity
          ? //  || record.variants.length
            `${
              // record.variants?.length
              //   ? record.variants.reduce((prv, cur) => cur.quantityInStock + prv, 0) +
              //     record.quantityInStock
              //   :
              record.quantityInStock
            }${
              record.holdedQuantity ? ` (+${record.holdedQuantity} ${t("analytics.holded")})` : ""
            }   ${t("analytics.inStock")}  ${
              record.variants.length
                ? `${t("analytics.for")} ${record.variants.length} ${t("analytics.variants")}`
                : ""
            } `
          : t("analytics.notTracked");
      },
      // : `${t("analytics.notTracked")}`,

      // ...getColumnRadioProps("inventory", stockStatuses),
    };

    const cols = [
      {
        // title: t("analytics.id"),
        title: "ID",
        align: "center",
        width: "8%",

        render: (record) => <Link to={`/product/${record.id}`}>#{record.id}</Link>,
      },
      titleColumn,
      inventoryColumn,
      // typesColumn,
      brandColumn,
      {
        title: t("analytics.price"),
        align: "center",
        render: (record) =>
          record.variants.length ? `${t("from")} ${formatMoney(record)}` : formatMoney(record),
      },
      catsColumn,
      {
        title: t("analytics.createdAt"),
        align: "center",
        dataIndex: "createdAt",
        render: (record) => moment(record).format("DD/MM/YYYY h:mmA"),
      },

      {
        title: t("posts.publish"),
        align: "center",
        className: "publishCell",
        render: (record) => {
          return <PublishSwitch updateProductPublished={updateProductPublished} record={record} />;
        },
      },
    ];

    return cols;
  };

  useEffect(() => {
    //Initial dates
    let initData = {
      type: handlePrismaFilter("singleR", {
        filterVal: query.type?.split(","),
      }),
      categories: handlePrismaFilter("arrayR", {
        filterVal: query.categories?.split(","),
      }),
      brand: handlePrismaFilter("singleR", {
        filterVal: query.brand?.split(","),
      }),
      title: handlePrismaFilter("mText", { filterVal: query.title }),
      ...handlePrismaFilter("inventory", {
        filterVal: query.inventory,
      }),
    };

    dispatch({ type: "SET_INIT", params: initData });

    return () => {
      console.log("🚀 ~ file: useProductFilter.js ~ line 419 ~ return ~ restFilters", restFilters);

      restFilters.onResetButtonClick();
    };
  }, []);

  const stockStatuses = [
    {
      text: t("analytics.inStock"),
      value: "inStock",
    },
    {
      text: t("analytics.outOfStock"),
      value: "outOfStock",
    },
    {
      text: t("analytics.backOrder"),
      value: "Backorder",
    },
    {
      text: t("analytics.notTracking"),
      value: "notTracking",
    },
  ];

  return {
    columns,
    query,
    ...restFilters,
  };
}

const PublishSwitch = ({ record, updateProductPublished }) => {
  const [checked, setChecked] = useState(record.published);
  useEffect(() => {
    setChecked(record.published);
  }, [record]);
  return (
    <Switch
      checked={checked}
      onChange={(published) => {
        console.log("🚀 ~ file: useProductFilter.js ~ line 358 ~ columns ~ published", record);
        setChecked(published);
        updateProductPublished({
          variables: {
            bulkPublishActionIds: [record.id],
            bulkPublishActionType: "PRODUCT",
            bulkPublishActionPublished: published,
          },
          // refetchQueries: [{ query: PRODUCTS, variables: variables }],
        });
        // updateProductPublished({
        //   variables: {
        //     updateProductPublished:published ,
        //     updateProductId: record.id,
        //   },
        // });
      }}
    />
  );
};
