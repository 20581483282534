import { RightOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Row, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import chat from "../../../../assets/imgs/createStore/chat.png";
import aliExpress from "../../../../assets/imgs/createStore/aliExpress.png";
import kanawat from "../../../../assets/imgs/createStore/kanawat.png";
import taager from "../../../../assets/imgs/createStore/taager.png";
import socialMedia from "../../../../assets/imgs/createStore/socialMedia.png";
import affiliate from "../../../../assets/imgs/createStore/affiliate.png";
import MyImage from "../../../../components/common/image";
import styled from "styled-components";

const { Title, Paragraph, Text } = Typography;

const CreateStoreStep4 = ({ setStep, setMyApps, loading, updateAppLoading }) => {
  const { t } = useTranslation();

  const onboardingApps = [
    {
      id: 1,
      title: t("auth.createStore.sellInSocialMedia"),
      desc: t("auth.createStore.sellInSocialMediaDesc"),
      img: socialMedia,
      value: "FACEBOOK",
    },
    {
      id: 2,
      title: t("auth.createStore.LiveChat"),
      desc: t("auth.createStore.LiveChatDesc"),
      img: chat,
      value: "B7R_CHAT",
    },
    {
      id: 3,
      title: t("auth.createStore.affiliate"),
      desc: t("auth.createStore.affiliateDesc"),
      img: affiliate,
      value: "AFFILIATE",
    },
    {
      id: 4,
      title: t("apps.knawat"),
      desc: t("apps.knawatDesc"),
      img: kanawat,
      value: "KNAWAT",
    },
    {
      id: 5,
      title: t("apps.taager"),
      desc: t("apps.taagerDesc"),
      img: taager,
      value: "TAAGER",
    },
    {
      id: 6,
      title: t("apps.aliExpress"),
      desc: t("apps.aliExpressDesc"),
      img: aliExpress,
      value: "ALI_EXPRESS",
    },
  ];
  const onChange = (checkedValues) => {
    setMyApps(checkedValues);
  };
  return (
    <Container>
      <Title level={4} style={{ fontWeight: 700 }}>
        {t("auth.createStore.whatAppsInstall")}
      </Title>
      <Paragraph className="desc">{t("auth.createStore.installAppsLater")}</Paragraph>
      <Checkbox.Group style={{ width: "100%" }} onChange={onChange}>
        <Row gutter={20} align="middle" justify="center">
          {onboardingApps.map((item) => (
            <Col lg={8} md={12} key={item.id}>
              <Checkbox value={item.value}>
                <Card
                  cover={<MyImage src={item.img} />}
                  bodyStyle={{ padding: 12, textAlign: "center" }}>
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Text className="app-title">{item.title}</Text>
                    <Text className="app-desc">{item.desc}</Text>
                  </Space>
                </Card>
              </Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
      <Row justify="space-between">
        <Col>
          <Button type="link" icon={<RightOutlined />} onClick={() => setStep(3)}>
            {t("auth.createStore.back")}
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            htmlType="submit"
            loading={updateAppLoading || loading}
            disabled={updateAppLoading || loading}>
            {t("auth.createStore.finishAndGoDashboard")}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateStoreStep4;

const Container = styled.div`
  .ant-checkbox-wrapper {
    display: block;
    position: relative;
  }
  .ant-checkbox {
    position: absolute;
    top: 40px;
    right: 15px;
    z-index: 99;
  }
  .app-title {
    font-weight: 700;
    font-size: 16px;
  }
  .app-desc {
    max-width: 225px;
    height: 50px;
    display: block;
    margin: auto;
    font-size: 12px;
  }
`;
