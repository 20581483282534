import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
  useSubscription,
} from "@apollo/client";
import { Space, Spin, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { MessageList } from "react-chat-elements";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import { varRoomsState } from "..";
import back from "../../../../assets/imgs/back.svg";
import MyImage from "../../../../components/common/image";
import { makeArrayUniqe } from "../../../../lib/makeArrayUniqe";
import { useResponsive } from "../../../../lib/useResponsive";
import { COUNT_NOT_SEEN } from "../../../../modules/common/queries";
import { Message as MessageType, Room as RoomType } from "../../../../types";
import { CURRENT_USER } from "../../../../utlis/Me";
import { SEND_CHAT_MESSAGE } from "../queries";
import ChatSender from "../senderInput/index";
import { GET_MESSAGES, GET_ROOM, SEE_CHAT } from "./queries";
import { Container } from "./styled";
import { MessagesType, Props } from "./types";

const { Text } = Typography;

const Messages = ({
  // setCurrentRoomSlug,
  backToChat,
}: // CurrentRoomMessages,
// subNewMessageData,
Props) => {
  const { t } = useTranslation();
  const { lg } = useResponsive();
  const [elm, setElm] = useState<HTMLDivElement>(null);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [hasMore, setHasMore] = useState(true);
  console.log("🚀 ~ file: index.tsx ~ line 41 ~ hasMore", hasMore);
  const messageListReferance = useRef();
  console.log("🚀 ~ file: index.tsx ~ line 43 ~ messageListReferance", messageListReferance);

  const [isLoadMoreMessages, setIsLoadMoreMessages] = useState(false);
  const { params }: any = useRouteMatch();
  const { roomSlug } = params;
  const takeNum = 30;
  const [skip, setSkip] = useState(0);
  console.log("🚀 ~ file: index.tsx ~ line 45 ~ skip", skip);
  // const [isLoadingMoreMessages, setIsLoadingMoreMessages] = useState(false);
  const messagesContainerRef = useRef(null);
  const [chatInput, setChatInput] = useState("");
  const rooms = useReactiveVar(varRoomsState);
  console.log("🚀 ~ file: index.tsx ~ line 49 ~ rooms", rooms);
  const room = rooms.find(({ slug }) => {
    return roomSlug == slug;
  });

  // useEffect(()=>{
  //   if (!room) {
  //     varRoomsState([...rooms, { slug: roomSlug, messages: [] }]);
  //   }
  // },[])
  console.log("🚀 ~ file: index.tsx ~ line 51 ~ room ~ room", room);
  const updateRoomState = (newRoom) => {
    console.log("🚀 ~ file: index.tsx ~ line 58 ~ updateRoomState ~ newRoom 12112", newRoom);
    varRoomsState(
      varRoomsState().map((room) => {
        console.log(room.slug == roomSlug ? newRoom : room, "newroooom");
        return room.slug == roomSlug ? newRoom : room;
      }),
    );
  };
  // const { data, client } = useQuery<{
  //   room: RoomType;
  // }>(GET_ROOM, { variables: { chatSlug: roomSlug } });
  const chatMessages = room?.messages;
  const [lastMessageId, setLastMessageId] = useState();

  console.log("🚀 ~ file: index.tsx ~ line 73 ~ room", room);
  useEffect(() => {
    if (!hasMore) {
      setIsLoadMoreMessages(false);
    }
  }, [hasMore, isLoadMoreMessages]);
  const {
    data: messagesData,
    fetchMore: fetchMoreMessages,
    client,
    subscribeToMore,
  } = useQuery<{
    messages: MessagesType;
  }>(GET_MESSAGES, {
    variables: { chatSlug: roomSlug, take: takeNum, skip: skip * takeNum },
    skip: !hasMore,
    onCompleted: (res) => {
      console.log("🚀 ~ file: index.tsx ~ line 75 ~ res", res);

      // varRoomsState([
      //   ...varRoomsState(),
      //   { slug: roomSlug, messages: makeArrayUniqe([...res.messages.nodes, ...room.messages]) },
      // ]);
      if (!room) {
        return varRoomsState([
          ...rooms,
          {
            slug: roomSlug,
            messages: makeArrayUniqe([...(room.messages || []), ...res.messages.nodes]),
          },
        ]);
      }
      console.log("🚀 ~ file: index.tsx ~ line 58 ~ updateRoomState ~ newRoom1");
      setHasMore(res?.messages?.count > (chatMessages?.length || 0));
      updateRoomState({
        ...room,
        messages: makeArrayUniqe([...(room.messages || []), ...res.messages.nodes]),
      });

      setIsLoadMoreMessages(false);
      // subscribeToMore({
      //   document: COMMENTS_SUBSCRIPTION,
      //   variables: { postID: params.postID },
      //   updateQuery: (prev, { subscriptionData }) => {
      //     if (!subscriptionData.data) return prev;
      //     const newFeedItem = subscriptionData.data.commentAdded;
      //     return Object.assign({}, prev, {
      //       post: {
      //         comments: [newFeedItem, ...prev.post.comments]
      //       }
      //     });
      //   }
      // })
    },
    onError: (err) => {
      setIsLoadMoreMessages(false);
    },
  });
  console.log("🚀 ~ file: index.tsx ~ line 97 ~ chatMessages", chatMessages);

  // const [
  //   getMessages,
  //   { data: messagesData, fetchMore: fetchMoreMessages, client, subscribeToMore },
  // ] = useLazyQuery<{
  //   messages: MessagesType;
  // }>(GET_MESSAGES, {
  //   onCompleted: (res) => {
  //     setIsLoadMoreMessages(false);
  //     // subscribeToMore({
  //     //   document: COMMENTS_SUBSCRIPTION,
  //     //   variables: { postID: params.postID },
  //     //   updateQuery: (prev, { subscriptionData }) => {
  //     //     if (!subscriptionData.data) return prev;
  //     //     const newFeedItem = subscriptionData.data.commentAdded;
  //     //     return Object.assign({}, prev, {
  //     //       post: {
  //     //         comments: [newFeedItem, ...prev.post.comments]
  //     //       }
  //     //     });
  //     //   }
  //     // })
  //   },
  //   onError: (err) => {
  //     setIsLoadMoreMessages(false);
  //   },
  // });
  // const { data: messagesData } = useSubscription<{
  //   messages: MessagesType;
  // }>(GET_MESSAGES, {
  //   variables: {
  //     chatSlug: roomSlug,
  //     take: takeNum,
  //     skip: (skip + 1) * takeNum,
  //   },
  //   skip: !roomSlug,
  //   // onCompleted: (res) => {
  //   //   setIsLoadMoreMessages(false);
  //   // },
  //   // onError: (err) => {
  //   //   setIsLoadMoreMessages(false);
  //   // },
  // });
  const [sendMessageMut] = useMutation(SEND_CHAT_MESSAGE, {
    variables: {
      message: chatInput,
      chatSlug: roomSlug,
    },
    // onCompleted(data) {},
  });
  const sendMessage = () => {
    chatInput.trim() &&
      sendMessageMut().then(({ data }) => {
        updateRoomState({
          ...room,
          messages: makeArrayUniqe([{ ...data.sendMessageToChat }, ...(room.messages || [])]),
          lastMessage: data.sendMessageToChat,
        });
        setChatInput("");
        elm.scrollIntoView({
          block: "end",
        });
      });
  };

  const { data: userData } = useQuery(CURRENT_USER, {
    fetchPolicy: "cache-first",
  });
  const [countNotSeen] = useLazyQuery(COUNT_NOT_SEEN);

  useEffect(() => {
    if (elm && chatMessages && chatMessages[chatMessages?.length - 1]?.id !== lastMessageId) {
      setLastMessageId(chatMessages[chatMessages?.length - 1]?.id);
      elm.scrollIntoView({
        block: "end",
      });
    }
  }, [chatMessages]);

  const [seeChat] = useMutation(SEE_CHAT, {
    onCompleted: (data) => {
      countNotSeen();
    },
  });

  // const roomMessages = data?.room?.messages;
  const messages = Array.isArray(chatMessages)
    ? chatMessages?.map(({ user, message, createdAt }) => ({
        position: user?.id === userData.me.id ? "right" : "left",
        type: "text",
        text: message,
        date: new Date(createdAt),
      }))
    : // .reverse()
      [];
  console.log("🚀 ~ file: index.tsx ~ line 202 ~ messages", messages);

  // const hasMoreMassages = messagesData?.messages?.count > chatMessages?.length;

  const loadMoreMessages = () => {
    console.log("loading", hasMore);
    if (hasMore) {
      setSkip((val) => val + 1);

      setIsLoadMoreMessages(true);
      // fetchMoreMessages({
      //   query: GET_MESSAGES,
      //   variables: {
      //     chatSlug: roomSlug,
      //     take: takeNum,
      //     skip: (skip + 1) * takeNum,
      //   },
      // }).then(() => {
      //   setIsLoadMoreMessages(false);
      // });
    }
  };

  // useEffect(() => {
  //   if (
  //     subNewMessageData?.newMessageInRooms &&
  //     !isFirstLoad &&
  //     subNewMessageData?.newMessageInRooms?.room?.slug === roomSlug
  //   ) {
  //     const newSubData = { ...subNewMessageData?.newMessageInRooms };
  //     delete newSubData?.room;

  //     client.writeQuery({
  //       query: GET_MESSAGES,
  //       variables: { chatSlug: roomSlug, take: takeNum, skip },
  //       data: {
  //         messages: {
  //           ...messagesData.messages,
  //           nodes: [
  //             ...messagesData?.messages?.nodes,
  //             {
  //               __typename: "Message",
  //               seen: false,
  //               createdAt: newSubData.createdAt,
  //               id: newSubData.id,
  //               message: newSubData.message,
  //               user: { ...newSubData?.user },
  //             } as MessageType,
  //           ],
  //         },
  //       },
  //     });
  //   }
  // }, [subNewMessageData]);
  useEffect(() => {
    const elem = document.getElementsByClassName("rce-mlist");
    const elem2 = document.getElementsByClassName("message-container");
    const messagesElement = elem[0] as HTMLDivElement;
    const messagesElement2 = elem2[0] as HTMLDivElement;
    messagesContainerRef.current = messagesElement2;
    if (messagesElement) {
      setElm(messagesElement);
      (messagesContainerRef.current as HTMLDivElement).addEventListener("scroll", (e) => {
        // console.log("add", "add");
        const { scrollTop, scrollHeight, clientHeight } = messagesContainerRef.current;
        console.log(
          "🚀 ~ file: index.tsx ~ line 117 ~ ).addEventListener ~ { scrollTop, scrollHeight, clientHeight }",
          { scrollTop, scrollHeight, clientHeight, isLoadMoreMessages },
        );
        // if (scrollTop === 0 && !isLoadingMoreMessages) {
        if (scrollTop === 0 && !isLoadMoreMessages) {
          console.log("Load Now");
          // setIsLoadingMoreMessages(true);
          loadMoreMessages();
        }
      });
    }
    return () => {
      messagesContainerRef.current.removeEventListener("scroll", (e) => {
        console.log("removed");
      });
    };
  }, []);

  // useEffect(() => {
  //   console.log("loading");

  //   if (!isLoadingMoreMessages) return;
  //   setIsLoadingMoreMessages(false);
  //   console.log("loading22222 ", hasMoreMassages);
  //   console.log("loading>>> ", isLoadMoreMessages);
  //   if (isLoadMoreMessages || !hasMoreMassages) return;
  //   loadMoreMessages();
  // }, [isLoadingMoreMessages]);

  useEffect(() => {
    // getMessages({
    //   variables: { chatSlug: roomSlug, take: takeNum, skip: 0 },
    // });
    // setCurrentRoomSlug(roomSlug);
    setSkip(0);
    setHasMore(true);
    setIsFirstLoad(true);
  }, [roomSlug]);

  useEffect(() => {
    console.log("🚀 ~ file: index.tsx ~ line 267 ~ useEffect ~ room", room);

    if (room?.notSeenNum) {
      updateRoomState({ ...room, notSeenNum: 0 });

      seeChat({
        variables: {
          roomSlug,
        },
      });
    }
  }, [room]);

  useEffect(() => {
    if (elm && isFirstLoad && chatMessages?.length > 0) {
      elm.scrollIntoView({
        block: "end",
      });
      setIsFirstLoad(false);
      setIsLoadMoreMessages(false);
    }
  }, [chatMessages]);

  return (
    <Container>
      <Space size={16} align="end" className="header">
        {!lg && (
          <div onClick={backToChat}>
            <MyImage src={back} width={9} />
          </div>
        )}
        <Text className="header-text">
          {/* {(roomMessages && roomMessages[0]?.user?.name) || t("chat.userName")} */}
          {
            // (CurrentRoomMessages && CurrentRoomMessages.roomName)
            // messagesData?.messages?.roomName || t("chat.userName")
            room?.roomName || t("chat.userName")
          }
        </Text>
      </Space>
      {/* {hasMoreMassages && (
        <Button
          loading={isLoadMoreMessages}
          disabled={isLoadMoreMessages}
          type="text"
          onClick={loadMoreMessages}>
          {t("chat.loadMoreMessages")}
        </Button>
      )} */}
      <Space
        direction="vertical"
        size={0}
        style={{ width: "100%", justifyContent: "space-between" }}>
        <div className="message-container">
          {/* {isLoadMoreMessages && (
            <div style={{ textAlign: "center" }}>
              <Spin />
            </div>
          )} */}
          <MessageList
            className="message-list"
            // lockable={true}
            referance={messageListReferance}
            toBottomHeight={"100%"}
            dataSource={messages || []}
          />
        </div>
        <ChatSender
          chatInputVal={chatInput}
          setChatInputVal={setChatInput}
          sendMessage={() => {
            console.log("Send>>>.....");
            sendMessage();
          }}
        />
      </Space>
    </Container>
  );
};

export default Messages;
