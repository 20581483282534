import { EditOutlined } from "@ant-design/icons";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Button, Col, Form, Input, Modal, Row, Space, Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { AppLanguageSwitch } from "../../../../components/common/websiteLanguage";
import { varDomain } from "../../../../modules/common/defaults";
import { CURRENT_USER } from "../../../../utlis/Me";
import { AppLanguageSwitchContainer } from "../../../apps/app/settings/cards/profile";
import { FormCard } from "../../../products/products/addEdit/style";

const { Text, Title } = Typography;

const UPDATE_STORE_INFO = gql`
  mutation UPDATE_STORE_INFO($name: String!) {
    updateStoreInfo(name: $name) {
      id
      domain
    }
  }
`;
const trans = "auth.signUp";

const ChangeStoreNameComponent = () => {
  const { t } = useTranslation();
  const [visiable, setVisable] = useState(false);

  const [updateStoreInfo, { loading }] = useMutation(UPDATE_STORE_INFO);
  const { data, refetch } = useQuery(CURRENT_USER, { fetchPolicy: "cache-first" });
  console.log("🚀 ~ file: storeName.tsx ~ line 27 ~ ChangeStoreNameComponent ~ data", data);
  const storename = data?.me?.currentShop?.name;

  const showModal = () => {
    setVisable(true);
  };
  const handleCancel = () => {
    setVisable(false);
  };
  const onFinish = async (values: any) => {
    const store = await updateStoreInfo({ variables: values });
    varDomain(store.data.updateStoreInfo.domain);
    refetch();
    handleCancel();
  };
  return (
    <Container>
      <FormCard>
        <Row justify="space-between" align="middle">
          <Col>
            <Title level={4} className="storeName-title">
              {t("settings.storeName")}
            </Title>
            <Text className="storeName">{storename}</Text>
          </Col>
          <Col>
            <span className="edit-btn" onClick={showModal}>
              {t("settings.edit")}
              <EditOutlined />
            </span>
          </Col>
        </Row>
      </FormCard>
      <Modal
        title={t("settings.editStoreName")}
        visible={visiable}
        onCancel={handleCancel}
        footer={null}>
        <Form onFinish={onFinish} initialValues={{ name: storename }} layout="vertical">
          {/* <AppLanguageSwitchContainer> */}
          {/* <AppLanguageSwitch
              input={true}
              isEn={isEnCardOne}
              setIsEn={setIsEnCardOne}
              firstLangLoad={firstLangLoad}
              setFirstLangLoad={setFirstLangLoad}
            /> */}
          <Form.Item
            label={t("settings.storeName")}
            name="name"
            rules={[
              {
                required: true,
                message: t(`${trans}.storeNameError`),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value && /^[A-Za-z0-9][A-Za-z0-9\-_ ]*$/.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t(`${trans}.storeNameNotValidError`)));
                },
              }),
            ]}
            //   labelCol={{ style: { paddingTop: 10 } }}
          >
            <Input size="large" placeholder={storename} />
          </Form.Item>
          {/* </AppLanguageSwitchContainer> */}
          <Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              disabled={loading}
              loading={loading}
              style={{ float: "left" }}>
              {t("settings.save")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Container>
  );
};

export default ChangeStoreNameComponent;

const Container = styled.div`
  .storeName-title {
    font-weight: 700;
    color: ${(props) => props.theme.colors.black3};
  }
  .storeName {
    color: ${(props) => props.theme.colors.Gray2};
  }
  .edit-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${(props) => props.theme.customColors.lightBlue};
  }
`;
