import { Button, Modal, Space, Typography } from "antd";
import { useState } from "react";
import { Logo, LogoContainer } from "../layout/sider/sider.styles";
import logo from "../../assets/imgs/NewUpdatedLogo.svg";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

const MessageTourModal = ({ handleClickStart, showDrawer }) => {
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [collapsed, setCollapsed] = useState(true);
  const { t } = useTranslation();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    handleClickStart();
    setIsModalVisible(false);
    showDrawer();
  };
  return (
    <>
      <Modal
        centered={true}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        style={{
          borderRadius: 12,
        }}
        className="message_tour_modal">
        <Space
          size={10}
          direction="vertical"
          style={{ width: "100%", justifyContent: "center", textAlign: "center" }}>
          <LogoContainer>
            <Logo src={logo} alt="logo" />
          </LogoContainer>
          <Text className="welcome_tour_massage">{t("common.welcomeText")}</Text>
          <Text className="welcome_tour_desc">{t("common.welcomeDesc")}</Text>
          <Button type="primary" onClick={handleCancel}>
            {t("common.startNow")}
          </Button>
        </Space>
      </Modal>
    </>
  );
};

export default MessageTourModal;
