import { Button, Space } from "antd";
import styled from "styled-components";

const CommonSwitch = ({ setIsEn, isEn }) => {
  return (
    <Container>
      <Space className="switch-btns" size={0}>
        <Button className="ar" onClick={() => setIsEn(!isEn)} type="text" disabled={!isEn}>
          عربي
        </Button>
        <Button className="en" onClick={() => setIsEn(!isEn)} type="text" disabled={isEn}>
          EN
        </Button>
      </Space>
    </Container>
  );
};

const Container = styled.div`
margin-bottom: -14px;
  .switch-btns {
    width: 100%;
    height: 23px;
    .ant-btn {
      width: 98px;
      height: 35px;
      color: #6ebbc5;
      font-weight: 600;
      font-size: 14px;
      background: #fff;
      border-radius: 8px 8px 0 0 !important;
      box-shadow: 0px 0px 4px rgba(51, 51, 51, 0.12);
    }
    .ar {
      border-radius: ${(props) => (props.theme.i18n.language == "ar" ? " 0 4px 0 0" : "4px 0 0 0")};
      border-left: ${(props) => (props.theme.i18n.language == "ar" ? " 0" : "0.2px solid #ecebeb")};
      font-family: "Cairo", sans-serif;
      padding-top: 0;
    }
    .en {
      border-radius: ${(props) => (props.theme.i18n.language == "ar" ? " 4px 0 0 0" : "0 4px 0 0")};
      border-left: ${(props) => (props.theme.i18n.language == "en" ? " 0" : "0.2px solid #ecebeb")};
      font-family: "Roboto", sans-serif;
    }
    .ant-btn-text[disabled] {
      width: 98px;
      height: 35px;
      color: #bdbdbd;
      font-weight: 600;
      font-size: 14px;
      background: #e6e6e6;
    }
  }
`;

export default CommonSwitch;
