import { Button, Modal, Space, Typography } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import MyImage from "../../../components/common/image";
import lock from "../../../assets/imgs/lockedStore.svg";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { ADD_TO_BALANCE } from ".";
import { useHistory } from "react-router-dom";

const { Text } = Typography;

const ChargeWalletModalComponent = ({ shouldPay }) => {
  const [visible, setVisible] = useState(true);
  const { push } = useHistory();
  const [addToBalanceMutation, { loading }] = useMutation(ADD_TO_BALANCE);
  const { t } = useTranslation();
  const value = Math.abs(parseFloat(shouldPay));
  const addInvoice = async () => {
    const { data: newInvoice } = await addToBalanceMutation({
      variables: {
        value,
      },
    });
    const id = newInvoice.addToBalance.id;
    if (id) push(`/invoices/${id}`);
    setVisible(false);
  };
  return (
    <ModalContainer
      centered
      keyboard={false}
      closable={false}
      visible={visible}
      footer={null}
      bodyStyle={{ textAlign: "center", padding: "32px 40px 38px 39px" }}>
      <Space direction="vertical">
        <MyImage src={lock} height={54} width={54} />
        <Text className="desc">{t("home.sorryShouldPay")}</Text>
        <Text className="value">{t("home.shouldPayIS") + " $ " + value}</Text>
        <Button
          type="primary"
          style={{ marginTop: 20 }}
          loading={loading}
          disabled={loading}
          onClick={addInvoice}>
          {t("home.chargeWallet")}
        </Button>
      </Space>
    </ModalContainer>
  );
};

export default ChargeWalletModalComponent;

const ModalContainer = styled(Modal)`
  .desc {
    font-weight: 600;
    font-size: 16px;
  }
  .value {
    font-weight: 700;
    font-size: 16px;
  }
`;
