import React from "react";
import { Select, Space, Typography } from "antd";

const { Option } = Select;
const { Text } = Typography;

// ;
// landlinePrefix;
// ;
// landline;
// nearestLandmark;
// streetNo;
// buildingNo;
// floorNo;
// apartmentNo;
// note;

const DisplayAddress = ({ address, settingLocationHorizontal }) => {
  console.log("DisplayAddress -> address", address);
  return (
    <Space
      className="address-info"
      direction={settingLocationHorizontal ? "horizontal" : "vertical"}
      size={settingLocationHorizontal ? 5 : -5}>
      <Text strong>
        {address?.name} {address?.firstName} {address?.lastName}
      </Text>
      {address?.country && <Text strong>{address.country}</Text>}
      {address?.state && <Text strong>{address.state}</Text>}
      {address?.city && <Text strong>{address.city}</Text>}
      {address?.area && <Text strong>{address.area}</Text>}
      {address?.address && <Text strong>{address.address}</Text>}
      {address?.address2 && <Text strong>{address.address2}</Text>}
      {address?.streetNo && <Text strong>{address.streetNo}</Text>}
      {address?.buildingNo && <Text strong>{address.buildingNo}</Text>}
      {address?.apartmentNo && <Text strong>{address.apartmentNo}</Text>}
      {address?.phone && (
        <Text strong>
          {address?.phonePrefix} {address.phone}
        </Text>
      )}
      {/* {address?.extraFields
        ? !address?.extraFields?.length
          ? Object.values(address?.extraFields)?.map((value) => <Text strong>{value}</Text>)
          : address?.extraFields?.map(({ value }) => <Text strong>{value}</Text>)
        : undefined} */}
      {address?.extraFields?.map(({ value }) => (
        <Text strong>{value}</Text>
      ))}
    </Space>
  );
  // <Space direction="vertical" size={-5}>
  //   <Typography.Text strong>
  //     {address?.name} {address?.firstName} {address?.lastName}
  //   </Typography.Text>
  //   {address?.country && (
  //     <Typography.Text strong>{address.country}</Typography.Text>
  //   )}
  //   {address?.state && (
  //     <Typography.Text strong>{address.state}</Typography.Text>
  //   )}
  //   {address?.city && (
  //     <Typography.Text strong>{address.city}</Typography.Text>
  //   )}
  //   {address?.area && (
  //     <Typography.Text strong>{address.area}</Typography.Text>
  //   )}
  //   {address?.address && (
  //     <Typography.Text strong>{address.address}</Typography.Text>
  //   )}
  //   {address?.address2 && (
  //     <Typography.Text strong>{address.address2}</Typography.Text>
  //   )}
  //   {address?.streetNo && (
  //     <Typography.Text strong>{address.streetNo}</Typography.Text>
  //   )}
  //   {address?.buildingNo && (
  //     <Typography.Text strong>{address.buildingNo}</Typography.Text>
  //   )}
  //   {address?.apartmentNo && (
  //     <Typography.Text strong>{address.apartmentNo}</Typography.Text>
  //   )}
  //   {address?.phone && (
  //     <Typography.Text strong>
  //       {address?.phonePrefix} {address.phone}
  //     </Typography.Text>
  //   )}
  // </Space>
};

export default DisplayAddress;
