export const MainProducts = [
  {
    __typename: "Product",
    SKU: " bfx628bfv",
    published: true,
    quantityInLocations: [
      {
        __typename: "QuantityInLocation",
        id: 108,
        quantity: 46,
      },
      {
        __typename: "QuantityInLocation",
        id: 126,
        quantity: -1,
      },
      {
        __typename: "QuantityInLocation",
        id: 128,
        quantity: -1,
      },
      {
        __typename: "QuantityInLocation",
        id: 137,
        quantity: -2,
      },
      {
        __typename: "QuantityInLocation",
        id: 143,
        quantity: -1,
      },
      {
        __typename: "QuantityInLocation",
        id: 3649,
        quantity: -2,
      },
      {
        __typename: "QuantityInLocation",
        id: 4127,
        quantity: -6,
      },
    ],
    id: 112,
    title: {
      __typename: "Text",
      id: 307,
      auto: "Contemporary sofa",
      ar: null,
      en: "Contemporary sofa",
    },
    description: {
      __typename: "Text",
      id: 306,
      ar: "",
      en: "",
    },
    ratingCount: null,
    ratingsAvg: null,
    realPrice: 55,
    price: 66,
    facebookPostId: null,
    comparingPrice: 80,
    hasVariants: false,
    quantityInStock: 8,
    holdedQuantity: 25,
    trackQuantity: true,
    tags: [],
    categories: [
      {
        __typename: "Category",
        id: 198,
        slug: "اثاث-منزلي",
        name: {
          __typename: "Text",
          id: 8641,
          auto: "furniture ",
        },
      },
    ],
    mainImage: {
      __typename: "Image",
      id: 2074,
      sm: "https://res.cloudinary.com/dp5tdylzs/image/upload/c_scale,q_auto,w_60/v1625483218/bgo2igcjbaxulxecizt8.jpg",
      md: "https://res.cloudinary.com/dp5tdylzs/image/upload/c_scale,q_auto,w_300/v1625483218/bgo2igcjbaxulxecizt8.jpg",
      original: "https://res.cloudinary.com/dp5tdylzs/image/upload/v1625483218/bgo2igcjbaxulxecizt8.jpg",
    },
    type: null,
    brand: {
      __typename: "Brand",
      id: 4,
      name: "Gucci",
    },
    gallery: [],
    variants: [],
    createdAt: "2021-05-10T23:10:48.467Z",
    options: [],
    selectedOptions: [],
    parent: null,
  },
  {
    __typename: "Product",
    SKU: "bgf561vfg",
    published: true,
    quantityInLocations: [
      {
        __typename: "QuantityInLocation",
        id: 107,
        quantity: 76,
      },
      {
        __typename: "QuantityInLocation",
        id: 142,
        quantity: -1,
      },
    ],
    id: 111,
    title: {
      __typename: "Text",
      id: 304,
      auto: "Colling sun Birtz",
      ar: null,
      en: "Colling sun Birtz",
    },
    description: {
      __typename: "Text",
      id: 303,
      ar: "",
      en: "",
    },
    ratingCount: null,
    ratingsAvg: null,
    realPrice: 100,
    price: 160,
    facebookPostId: null,
    comparingPrice: 300,
    hasVariants: false,
    quantityInStock: 82,
    holdedQuantity: 3,
    trackQuantity: true,
    tags: [
      {
        __typename: "Tag",
        id: 7,
        name: "Cosmetic",
      },
    ],
    categories: [
      {
        __typename: "Category",
        id: 7,
        slug: "مستحضرات-تجميل",
        name: {
          __typename: "Text",
          id: 34,
          auto: "cosmetics",
        },
      },
    ],
    mainImage: {
      __typename: "Image",
      id: 2075,
      sm: "https://res.cloudinary.com/dp5tdylzs/image/upload/c_scale,q_auto,w_60/v1625483474/rhyhvscblrtwxlaha2oo.jpg",
      md: "https://res.cloudinary.com/dp5tdylzs/image/upload/c_scale,q_auto,w_300/v1625483474/rhyhvscblrtwxlaha2oo.jpg",
      original: "https://res.cloudinary.com/dp5tdylzs/image/upload/v1625483474/rhyhvscblrtwxlaha2oo.jpg",
    },
    type: null,
    brand: {
      __typename: "Brand",
      id: 3,
      name: "Hermes",
    },
    gallery: [],
    variants: [],
    createdAt: "2021-05-10T23:09:21.308Z",
    options: [],
    selectedOptions: [],
    parent: null,
  },
  {
    __typename: "Product",
    SKU: "vf863vf",
    published: true,
    quantityInLocations: [
      {
        __typename: "QuantityInLocation",
        id: 4129,
        quantity: -4,
      },
      {
        __typename: "QuantityInLocation",
        id: 106,
        quantity: 36,
      },
      {
        __typename: "QuantityInLocation",
        id: 140,
        quantity: -1,
      },
      {
        __typename: "QuantityInLocation",
        id: 5908,
        quantity: -1,
      },
      {
        __typename: "QuantityInLocation",
        id: 3166,
        quantity: -1,
      },
      {
        __typename: "QuantityInLocation",
        id: 3719,
        quantity: -1,
      },
    ],
    id: 110,
    title: {
      __typename: "Text",
      id: 301,
      auto: "Target Acne Spot Treatment",
      ar: null,
      en: "Target Acne Spot Treatment",
    },
    description: {
      __typename: "Text",
      id: 300,
      ar: "",
      en: "",
    },
    ratingCount: null,
    ratingsAvg: null,
    realPrice: 260,
    price: 400,
    facebookPostId: null,
    comparingPrice: null,
    hasVariants: false,
    quantityInStock: 16,
    holdedQuantity: 12,
    trackQuantity: true,
    tags: [
      {
        __typename: "Tag",
        id: 7,
        name: "Cosmetic",
      },
    ],
    categories: [
      {
        __typename: "Category",
        id: 7,
        slug: "مستحضرات-تجميل",
        name: {
          __typename: "Text",
          id: 34,
          auto: "cosmetics",
        },
      },
    ],
    mainImage: {
      __typename: "Image",
      id: 56,
      sm: "https://res.cloudinary.com/dp5tdylzs/image/upload/c_scale,q_auto,w_60/v1620688029/ycb3uknxmsse8vofolfh.jpg",
      md: "https://res.cloudinary.com/dp5tdylzs/image/upload/c_scale,q_auto,w_300/v1620688029/ycb3uknxmsse8vofolfh.jpg",
      original: "https://res.cloudinary.com/dp5tdylzs/image/upload/v1620688029/ycb3uknxmsse8vofolfh.jpg",
    },
    type: null,
    brand: {
      __typename: "Brand",
      id: 3,
      name: "Hermes",
    },
    gallery: [],
    variants: [],
    createdAt: "2021-05-10T23:07:56.195Z",
    options: [],
    selectedOptions: [],
    parent: null,
  },
  {
    __typename: "Product",
    SKU: "vgf279bf",
    published: true,
    quantityInLocations: [
      {
        __typename: "QuantityInLocation",
        id: 3397,
        quantity: 46,
      },
      {
        __typename: "QuantityInLocation",
        id: 3648,
        quantity: -3,
      },
      {
        __typename: "QuantityInLocation",
        id: 3720,
        quantity: -4,
      },
    ],
    id: 109,
    title: {
      __typename: "Text",
      id: 298,
      auto: "fridge freezer",
      ar: "",
      en: "fridge freezer",
    },
    description: {
      __typename: "Text",
      id: 297,
      ar: "",
      en: "",
    },
    ratingCount: null,
    ratingsAvg: null,
    realPrice: 80,
    price: 3000,
    facebookPostId: null,
    comparingPrice: 100,
    hasVariants: false,
    quantityInStock: 35,
    holdedQuantity: 4,
    trackQuantity: true,
    tags: [],
    categories: [
      {
        __typename: "Category",
        id: 9,
        slug: "electronics",
        name: {
          __typename: "Text",
          id: 38,
          auto: "Electronics",
        },
      },
    ],
    mainImage: {
      __typename: "Image",
      id: 2078,
      sm: "https://res.cloudinary.com/dp5tdylzs/image/upload/c_scale,q_auto,w_60/v1625483704/kv3df5r1iwmn3pyct5rd.jpg",
      md: "https://res.cloudinary.com/dp5tdylzs/image/upload/c_scale,q_auto,w_300/v1625483704/kv3df5r1iwmn3pyct5rd.jpg",
      original: "https://res.cloudinary.com/dp5tdylzs/image/upload/v1625483704/kv3df5r1iwmn3pyct5rd.jpg",
    },
    type: null,
    brand: {
      __typename: "Brand",
      id: 2,
      name: "Louis Vuitton\t",
    },
    gallery: [],
    variants: [],
    createdAt: "2021-05-10T23:03:21.167Z",
    options: [],
    selectedOptions: [],
    parent: null,
  },
  {
    __typename: "Product",
    SKU: "nj9n62nh9j",
    published: true,
    quantityInLocations: [
      {
        __typename: "QuantityInLocation",
        id: 3394,
        quantity: 10,
      },
    ],
    id: 107,
    title: {
      __typename: "Text",
      id: 293,
      auto: "Wooden simple sofa ",
      ar: "",
      en: "Wooden simple sofa ",
    },
    description: {
      __typename: "Text",
      id: 292,
      ar: "",
      en: "",
    },
    ratingCount: null,
    ratingsAvg: null,
    realPrice: 8000,
    price: 10000,
    facebookPostId: null,
    comparingPrice: 13000,
    hasVariants: false,
    quantityInStock: 10,
    holdedQuantity: 0,
    trackQuantity: true,
    tags: [],
    categories: [
      {
        __typename: "Category",
        id: 198,
        slug: "اثاث-منزلي",
        name: {
          __typename: "Text",
          id: 8641,
          auto: "furniture ",
        },
      },
    ],
    mainImage: {
      __typename: "Image",
      id: 2079,
      sm: "https://res.cloudinary.com/dp5tdylzs/image/upload/c_scale,q_auto,w_60/v1625483820/jtkpl24iztkniedk519f.jpg",
      md: "https://res.cloudinary.com/dp5tdylzs/image/upload/c_scale,q_auto,w_300/v1625483820/jtkpl24iztkniedk519f.jpg",
      original: "https://res.cloudinary.com/dp5tdylzs/image/upload/v1625483820/jtkpl24iztkniedk519f.jpg",
    },
    type: null,
    brand: {
      __typename: "Brand",
      id: 7,
      name: "Samsung ",
    },
    gallery: [],
    variants: [
      {
        __typename: "Product",
        quantityInStock: 17,
        quantityInLocations: [
          {
            __typename: "QuantityInLocation",
            id: 104,
            quantity: 25,
          },
          {
            __typename: "QuantityInLocation",
            id: 115,
            quantity: -3,
          },
          {
            __typename: "QuantityInLocation",
            id: 132,
            quantity: 0,
          },
          {
            __typename: "QuantityInLocation",
            id: 133,
            quantity: -1,
          },
          {
            __typename: "QuantityInLocation",
            id: 134,
            quantity: -1,
          },
          {
            __typename: "QuantityInLocation",
            id: 2564,
            quantity: -1,
          },
        ],
        id: 108,
        SKU: "nj9n62nh9j-0",
      },
    ],
    createdAt: "2021-05-10T23:01:09.373Z",
    options: [
      {
        __typename: "ProductOption",
        attribute: {
          __typename: "Attribute",
          name: "Size",
          slug: "size",
        },
        values: [
          {
            __typename: "OptionValue",
            name: "43 Inch",
            slug: "43 inch",
          },
        ],
      },
    ],
    selectedOptions: [],
    parent: null,
  },
  {
    __typename: "Product",
    SKU: "vfrf297btd",
    published: true,
    quantityInLocations: [
      {
        __typename: "QuantityInLocation",
        id: 3393,
        quantity: 56,
      },
    ],
    id: 105,
    title: {
      __typename: "Text",
      id: 288,
      auto: "Toshiba",
      ar: "",
      en: "Toshiba",
    },
    description: {
      __typename: "Text",
      id: 287,
      ar: "",
      en: "",
    },
    ratingCount: null,
    ratingsAvg: null,
    realPrice: 3000,
    price: 3400,
    facebookPostId: null,
    comparingPrice: 5000,
    hasVariants: false,
    quantityInStock: 56,
    holdedQuantity: 0,
    trackQuantity: true,
    tags: [
      {
        __typename: "Tag",
        id: 5,
        name: "electronic",
      },
      {
        __typename: "Tag",
        id: 120,
        name: "smart-tv",
      },
    ],
    categories: [
      {
        __typename: "Category",
        id: 9,
        slug: "electronics",
        name: {
          __typename: "Text",
          id: 38,
          auto: "Electronics",
        },
      },
      {
        __typename: "Category",
        id: 8,
        slug: "شاشات-ذكيه",
        name: {
          __typename: "Text",
          id: 36,
          auto: "Smart TV screens",
        },
      },
    ],
    mainImage: {
      __typename: "Image",
      id: 1392,
      sm: "https://res.cloudinary.com/dp5tdylzs/image/upload/v1620687421/z3jgsmlumz9vr9xcurqq.jpg",
      md: "https://res.cloudinary.com/dp5tdylzs/image/upload/v1620687421/z3jgsmlumz9vr9xcurqq.jpg",
      original: null,
    },
    type: null,
    brand: {
      __typename: "Brand",
      id: 15,
      name: "Toshiba",
    },
    gallery: [],
    variants: [
      {
        __typename: "Product",
        quantityInStock: 57,
        quantityInLocations: [
          {
            __typename: "QuantityInLocation",
            id: 102,
            quantity: 56,
          },
          {
            __typename: "QuantityInLocation",
            id: 3063,
            quantity: 1,
          },
        ],
        id: 106,
        SKU: "vfrf297btd-0",
      },
    ],
    createdAt: "2021-05-10T22:58:41.740Z",
    options: [
      {
        __typename: "ProductOption",
        attribute: {
          __typename: "Attribute",
          name: "Size",
          slug: "size",
        },
        values: [
          {
            __typename: "OptionValue",
            name: "32 Inch",
            slug: "32-inch",
          },
        ],
      },
    ],
    selectedOptions: [],
    parent: null,
  },
  {
    __typename: "Product",
    SKU: "rbht9hg",
    published: true,
    quantityInLocations: [
      {
        __typename: "QuantityInLocation",
        id: 3392,
        quantity: 156,
      },
    ],
    id: 102,
    title: {
      __typename: "Text",
      id: 281,
      auto: "LG smart ",
      ar: "",
      en: "LG smart ",
    },
    description: {
      __typename: "Text",
      id: 280,
      ar: "",
      en: "",
    },
    ratingCount: null,
    ratingsAvg: null,
    realPrice: 3500,
    price: 4000,
    facebookPostId: null,
    comparingPrice: null,
    hasVariants: false,
    quantityInStock: 156,
    holdedQuantity: 0,
    trackQuantity: true,
    tags: [
      {
        __typename: "Tag",
        id: 5,
        name: "electronic",
      },
      {
        __typename: "Tag",
        id: 120,
        name: "smart-tv",
      },
    ],
    categories: [
      {
        __typename: "Category",
        id: 9,
        slug: "electronics",
        name: {
          __typename: "Text",
          id: 38,
          auto: "Electronics",
        },
      },
      {
        __typename: "Category",
        id: 8,
        slug: "شاشات-ذكيه",
        name: {
          __typename: "Text",
          id: 36,
          auto: "Smart TV screens",
        },
      },
    ],
    mainImage: {
      __typename: "Image",
      id: 1391,
      sm: "https://res.cloudinary.com/dp5tdylzs/image/upload/v1620687303/oiwnxa2sdxihunxo0p0n.jpg",
      md: "https://res.cloudinary.com/dp5tdylzs/image/upload/v1620687303/oiwnxa2sdxihunxo0p0n.jpg",
      original: null,
    },
    type: null,
    brand: {
      __typename: "Brand",
      id: 8,
      name: "LG ",
    },
    gallery: [],
    variants: [
      {
        __typename: "Product",
        quantityInStock: 78,
        quantityInLocations: [
          {
            __typename: "QuantityInLocation",
            id: 99,
            quantity: 78,
          },
        ],
        id: 103,
        SKU: "rbht9hg-0",
      },
      {
        __typename: "Product",
        quantityInStock: 77,
        quantityInLocations: [
          {
            __typename: "QuantityInLocation",
            id: 100,
            quantity: 78,
          },
          {
            __typename: "QuantityInLocation",
            id: 3646,
            quantity: -1,
          },
        ],
        id: 104,
        SKU: "rbht9hg-1",
      },
    ],
    createdAt: "2021-05-10T22:56:09.297Z",
    options: [
      {
        __typename: "ProductOption",
        attribute: {
          __typename: "Attribute",
          name: "Size",
          slug: "size",
        },
        values: [
          {
            __typename: "OptionValue",
            name: "32 Inch",
            slug: "32-inch",
          },
          {
            __typename: "OptionValue",
            name: "43 Inch",
            slug: "43-inch",
          },
        ],
      },
    ],
    selectedOptions: [],
    parent: null,
  },
  {
    __typename: "Product",
    SKU: "ndsu8602mn",
    published: true,
    quantityInLocations: [
      {
        __typename: "QuantityInLocation",
        id: 3391,
        quantity: 184,
      },
    ],
    id: 97,
    title: {
      __typename: "Text",
      id: 270,
      auto: "HUAWEI",
      ar: "",
      en: "HUAWEI",
    },
    description: {
      __typename: "Text",
      id: 269,
      ar: "",
      en: "",
    },
    ratingCount: null,
    ratingsAvg: null,
    realPrice: 6000,
    price: 7000,
    facebookPostId: null,
    comparingPrice: 8900,
    hasVariants: false,
    quantityInStock: 184,
    holdedQuantity: 0,
    trackQuantity: true,
    tags: [
      {
        __typename: "Tag",
        id: 3,
        name: "mobile",
      },
      {
        __typename: "Tag",
        id: 5,
        name: "electronic",
      },
    ],
    categories: [
      {
        __typename: "Category",
        id: 10,
        slug: "جوال",
        name: {
          __typename: "Text",
          id: 40,
          auto: "mobile",
        },
      },
      {
        __typename: "Category",
        id: 9,
        slug: "electronics",
        name: {
          __typename: "Text",
          id: 38,
          auto: "Electronics",
        },
      },
    ],
    mainImage: {
      __typename: "Image",
      id: 1390,
      sm: "https://res.cloudinary.com/dp5tdylzs/image/upload/v1620687109/nn7iiq3bem2a0dqi68iv.jpg",
      md: "https://res.cloudinary.com/dp5tdylzs/image/upload/v1620687109/nn7iiq3bem2a0dqi68iv.jpg",
      original: null,
    },
    type: null,
    brand: null,
    gallery: [],
    variants: [
      {
        __typename: "Product",
        quantityInStock: 46,
        quantityInLocations: [
          {
            __typename: "QuantityInLocation",
            id: 96,
            quantity: 46,
          },
        ],
        id: 100,
        SKU: "ndsu8602mn-3",
      },
      {
        __typename: "Product",
        quantityInStock: 37,
        quantityInLocations: [
          {
            __typename: "QuantityInLocation",
            id: 94,
            quantity: 46,
          },
          {
            __typename: "QuantityInLocation",
            id: 3663,
            quantity: -9,
          },
        ],
        id: 98,
        SKU: "ndsu8602mn-0",
      },
      {
        __typename: "Product",
        quantityInStock: 46,
        quantityInLocations: [
          {
            __typename: "QuantityInLocation",
            id: 97,
            quantity: 46,
          },
        ],
        id: 101,
        SKU: "ndsu8602mn-1",
      },
      {
        __typename: "Product",
        quantityInStock: 46,
        quantityInLocations: [
          {
            __typename: "QuantityInLocation",
            id: 95,
            quantity: 46,
          },
        ],
        id: 99,
        SKU: "ndsu8602mn-2",
      },
    ],
    createdAt: "2021-05-10T22:54:13.086Z",
    options: [
      {
        __typename: "ProductOption",
        attribute: {
          __typename: "Attribute",
          name: "Color",
          slug: "color",
        },
        values: [
          {
            __typename: "OptionValue",
            name: "Blue",
            slug: "blue",
          },
          {
            __typename: "OptionValue",
            name: "Red",
            slug: "red",
          },
          {
            __typename: "OptionValue",
            name: "White",
            slug: "white",
          },
          {
            __typename: "OptionValue",
            name: "Black",
            slug: "black",
          },
        ],
      },
    ],
    selectedOptions: [],
    parent: null,
  },
  {
    __typename: "Product",
    SKU: "vs68vbdfg",
    published: true,
    quantityInLocations: [
      {
        __typename: "QuantityInLocation",
        id: 3389,
        quantity: 264,
      },
    ],
    id: 89,
    title: {
      __typename: "Text",
      id: 252,
      auto: "Apple I phone",
      ar: "",
      en: "Apple I phone",
    },
    description: {
      __typename: "Text",
      id: 251,
      ar: "",
      en: "",
    },
    ratingCount: null,
    ratingsAvg: null,
    realPrice: 10000,
    price: 12000,
    facebookPostId: null,
    comparingPrice: 15000,
    hasVariants: false,
    quantityInStock: 264,
    holdedQuantity: 0,
    trackQuantity: true,
    tags: [
      {
        __typename: "Tag",
        id: 3,
        name: "mobile",
      },
      {
        __typename: "Tag",
        id: 5,
        name: "electronic",
      },
    ],
    categories: [
      {
        __typename: "Category",
        id: 10,
        slug: "جوال",
        name: {
          __typename: "Text",
          id: 40,
          auto: "mobile",
        },
      },
      {
        __typename: "Category",
        id: 9,
        slug: "electronics",
        name: {
          __typename: "Text",
          id: 38,
          auto: "Electronics",
        },
      },
    ],
    mainImage: {
      __typename: "Image",
      id: 1388,
      sm: "https://res.cloudinary.com/dp5tdylzs/image/upload/v1620686697/v4rags2ipditvehswlos.jpg",
      md: "https://res.cloudinary.com/dp5tdylzs/image/upload/v1620686697/v4rags2ipditvehswlos.jpg",
      original: null,
    },
    type: null,
    brand: null,
    gallery: [],
    variants: [
      {
        __typename: "Product",
        quantityInStock: 88,
        quantityInLocations: [
          {
            __typename: "QuantityInLocation",
            id: 88,
            quantity: 88,
          },
        ],
        id: 92,
        SKU: "vs68vbdfg-1",
      },
      {
        __typename: "Product",
        quantityInStock: 88,
        quantityInLocations: [
          {
            __typename: "QuantityInLocation",
            id: 86,
            quantity: 88,
          },
        ],
        id: 90,
        SKU: "vs68vbdfg-0",
      },
      {
        __typename: "Product",
        quantityInStock: 88,
        quantityInLocations: [
          {
            __typename: "QuantityInLocation",
            id: 87,
            quantity: 88,
          },
        ],
        id: 91,
        SKU: "vs68vbdfg-2",
      },
    ],
    createdAt: "2021-05-10T22:46:46.130Z",
    options: [
      {
        __typename: "ProductOption",
        attribute: {
          __typename: "Attribute",
          name: "Color",
          slug: "color",
        },
        values: [
          {
            __typename: "OptionValue",
            name: "Blue",
            slug: "blue",
          },
          {
            __typename: "OptionValue",
            name: "White",
            slug: "white",
          },
          {
            __typename: "OptionValue",
            name: "Black",
            slug: "black",
          },
        ],
      },
    ],
    selectedOptions: [],
    parent: null,
  },
  {
    __typename: "Product",
    SKU: "mtih2054",
    published: true,
    quantityInLocations: [
      {
        __typename: "QuantityInLocation",
        id: 3388,
        quantity: 80,
      },
    ],
    id: 86,
    title: {
      __typename: "Text",
      id: 245,
      auto: "Jacket",
      ar: "",
      en: "Jacket",
    },
    description: {
      __typename: "Text",
      id: 244,
      ar: "",
      en: "",
    },
    ratingCount: null,
    ratingsAvg: null,
    realPrice: 90,
    price: 99,
    facebookPostId: null,
    comparingPrice: 344,
    hasVariants: false,
    quantityInStock: 80,
    holdedQuantity: 0,
    trackQuantity: true,
    tags: [
      {
        __typename: "Tag",
        id: 2,
        name: "women",
      },
    ],
    categories: [
      {
        __typename: "Category",
        id: 3,
        slug: "clothes",
        name: {
          __typename: "Text",
          id: 22,
          auto: "Clothes",
        },
      },
      {
        __typename: "Category",
        id: 5,
        slug: "ملابس-نسائيه",
        name: {
          __typename: "Text",
          id: 30,
          auto: "Womens",
        },
      },
    ],
    mainImage: {
      __typename: "Image",
      id: 1387,
      sm: "https://res.cloudinary.com/dp5tdylzs/image/upload/v1620686580/zfisa7lzestp4eqae5yg.jpg",
      md: "https://res.cloudinary.com/dp5tdylzs/image/upload/v1620686580/zfisa7lzestp4eqae5yg.jpg",
      original: null,
    },
    type: null,
    brand: {
      __typename: "Brand",
      id: 5,
      name: "H&M",
    },
    gallery: [],
    variants: [
      {
        __typename: "Product",
        quantityInStock: 40,
        quantityInLocations: [
          {
            __typename: "QuantityInLocation",
            id: 83,
            quantity: 40,
          },
        ],
        id: 87,
        SKU: "mtih2054-0",
      },
      {
        __typename: "Product",
        quantityInStock: 39,
        quantityInLocations: [
          {
            __typename: "QuantityInLocation",
            id: 84,
            quantity: 40,
          },
          {
            __typename: "QuantityInLocation",
            id: 3647,
            quantity: -1,
          },
        ],
        id: 88,
        SKU: "mtih2054-1",
      },
    ],
    createdAt: "2021-05-10T22:44:02.571Z",
    options: [
      {
        __typename: "ProductOption",
        attribute: {
          __typename: "Attribute",
          name: "Size",
          slug: "size",
        },
        values: [
          {
            __typename: "OptionValue",
            name: "Medium",
            slug: "medium",
          },
          {
            __typename: "OptionValue",
            name: "Small",
            slug: "small",
          },
        ],
      },
      {
        __typename: "ProductOption",
        attribute: {
          __typename: "Attribute",
          name: "Color",
          slug: "color",
        },
        values: [
          {
            __typename: "OptionValue",
            name: "Blue",
            slug: "blue",
          },
        ],
      },
    ],
    selectedOptions: [],
    parent: null,
  },
  {
    __typename: "Product",
    SKU: " bfx628bfv",
    published: true,
    quantityInLocations: [
      {
        __typename: "QuantityInLocation",
        id: 108,
        quantity: 46,
      },
      {
        __typename: "QuantityInLocation",
        id: 126,
        quantity: -1,
      },
      {
        __typename: "QuantityInLocation",
        id: 128,
        quantity: -1,
      },
      {
        __typename: "QuantityInLocation",
        id: 137,
        quantity: -2,
      },
      {
        __typename: "QuantityInLocation",
        id: 143,
        quantity: -1,
      },
      {
        __typename: "QuantityInLocation",
        id: 3649,
        quantity: -2,
      },
      {
        __typename: "QuantityInLocation",
        id: 4127,
        quantity: -6,
      },
    ],
    id: 112,
    title: {
      __typename: "Text",
      id: 307,
      auto: "Contemporary sofa",
      ar: null,
      en: "Contemporary sofa",
    },
    description: {
      __typename: "Text",
      id: 306,
      ar: "",
      en: "",
    },
    ratingCount: null,
    ratingsAvg: null,
    realPrice: 55,
    price: 66,
    facebookPostId: null,
    comparingPrice: 80,
    hasVariants: false,
    quantityInStock: 8,
    holdedQuantity: 25,
    trackQuantity: true,
    tags: [],
    categories: [
      {
        __typename: "Category",
        id: 198,
        slug: "اثاث-منزلي",
        name: {
          __typename: "Text",
          id: 8641,
          auto: "furniture ",
        },
      },
    ],
    mainImage: {
      __typename: "Image",
      id: 2074,
      sm: "https://res.cloudinary.com/dp5tdylzs/image/upload/c_scale,q_auto,w_60/v1625483218/bgo2igcjbaxulxecizt8.jpg",
      md: "https://res.cloudinary.com/dp5tdylzs/image/upload/c_scale,q_auto,w_300/v1625483218/bgo2igcjbaxulxecizt8.jpg",
      original: "https://res.cloudinary.com/dp5tdylzs/image/upload/v1625483218/bgo2igcjbaxulxecizt8.jpg",
    },
    type: null,
    brand: {
      __typename: "Brand",
      id: 4,
      name: "Gucci",
    },
    gallery: [],
    variants: [],
    createdAt: "2021-05-10T23:10:48.467Z",
    options: [],
    selectedOptions: [],
    parent: null,
  },
  {
    __typename: "Product",
    SKU: "bgf561vfg",
    published: true,
    quantityInLocations: [
      {
        __typename: "QuantityInLocation",
        id: 107,
        quantity: 76,
      },
      {
        __typename: "QuantityInLocation",
        id: 142,
        quantity: -1,
      },
    ],
    id: 111,
    title: {
      __typename: "Text",
      id: 304,
      auto: "Colling sun Birtz",
      ar: null,
      en: "Colling sun Birtz",
    },
    description: {
      __typename: "Text",
      id: 303,
      ar: "",
      en: "",
    },
    ratingCount: null,
    ratingsAvg: null,
    realPrice: 100,
    price: 160,
    facebookPostId: null,
    comparingPrice: 300,
    hasVariants: false,
    quantityInStock: 82,
    holdedQuantity: 3,
    trackQuantity: true,
    tags: [
      {
        __typename: "Tag",
        id: 7,
        name: "Cosmetic",
      },
    ],
    categories: [
      {
        __typename: "Category",
        id: 7,
        slug: "مستحضرات-تجميل",
        name: {
          __typename: "Text",
          id: 34,
          auto: "cosmetics",
        },
      },
    ],
    mainImage: {
      __typename: "Image",
      id: 2075,
      sm: "https://res.cloudinary.com/dp5tdylzs/image/upload/c_scale,q_auto,w_60/v1625483474/rhyhvscblrtwxlaha2oo.jpg",
      md: "https://res.cloudinary.com/dp5tdylzs/image/upload/c_scale,q_auto,w_300/v1625483474/rhyhvscblrtwxlaha2oo.jpg",
      original: "https://res.cloudinary.com/dp5tdylzs/image/upload/v1625483474/rhyhvscblrtwxlaha2oo.jpg",
    },
    type: null,
    brand: {
      __typename: "Brand",
      id: 3,
      name: "Hermes",
    },
    gallery: [],
    variants: [],
    createdAt: "2021-05-10T23:09:21.308Z",
    options: [],
    selectedOptions: [],
    parent: null,
  },
  {
    __typename: "Product",
    SKU: "vf863vf",
    published: true,
    quantityInLocations: [
      {
        __typename: "QuantityInLocation",
        id: 4129,
        quantity: -4,
      },
      {
        __typename: "QuantityInLocation",
        id: 106,
        quantity: 36,
      },
      {
        __typename: "QuantityInLocation",
        id: 140,
        quantity: -1,
      },
      {
        __typename: "QuantityInLocation",
        id: 5908,
        quantity: -1,
      },
      {
        __typename: "QuantityInLocation",
        id: 3166,
        quantity: -1,
      },
      {
        __typename: "QuantityInLocation",
        id: 3719,
        quantity: -1,
      },
    ],
    id: 110,
    title: {
      __typename: "Text",
      id: 301,
      auto: "Target Acne Spot Treatment",
      ar: null,
      en: "Target Acne Spot Treatment",
    },
    description: {
      __typename: "Text",
      id: 300,
      ar: "",
      en: "",
    },
    ratingCount: null,
    ratingsAvg: null,
    realPrice: 260,
    price: 400,
    facebookPostId: null,
    comparingPrice: null,
    hasVariants: false,
    quantityInStock: 16,
    holdedQuantity: 12,
    trackQuantity: true,
    tags: [
      {
        __typename: "Tag",
        id: 7,
        name: "Cosmetic",
      },
    ],
    categories: [
      {
        __typename: "Category",
        id: 7,
        slug: "مستحضرات-تجميل",
        name: {
          __typename: "Text",
          id: 34,
          auto: "cosmetics",
        },
      },
    ],
    mainImage: {
      __typename: "Image",
      id: 56,
      sm: "https://res.cloudinary.com/dp5tdylzs/image/upload/c_scale,q_auto,w_60/v1620688029/ycb3uknxmsse8vofolfh.jpg",
      md: "https://res.cloudinary.com/dp5tdylzs/image/upload/c_scale,q_auto,w_300/v1620688029/ycb3uknxmsse8vofolfh.jpg",
      original: "https://res.cloudinary.com/dp5tdylzs/image/upload/v1620688029/ycb3uknxmsse8vofolfh.jpg",
    },
    type: null,
    brand: {
      __typename: "Brand",
      id: 3,
      name: "Hermes",
    },
    gallery: [],
    variants: [],
    createdAt: "2021-05-10T23:07:56.195Z",
    options: [],
    selectedOptions: [],
    parent: null,
  },
  {
    __typename: "Product",
    SKU: "vgf279bf",
    published: true,
    quantityInLocations: [
      {
        __typename: "QuantityInLocation",
        id: 3397,
        quantity: 46,
      },
      {
        __typename: "QuantityInLocation",
        id: 3648,
        quantity: -3,
      },
      {
        __typename: "QuantityInLocation",
        id: 3720,
        quantity: -4,
      },
    ],
    id: 109,
    title: {
      __typename: "Text",
      id: 298,
      auto: "fridge freezer",
      ar: "",
      en: "fridge freezer",
    },
    description: {
      __typename: "Text",
      id: 297,
      ar: "",
      en: "",
    },
    ratingCount: null,
    ratingsAvg: null,
    realPrice: 80,
    price: 3000,
    facebookPostId: null,
    comparingPrice: 100,
    hasVariants: false,
    quantityInStock: 35,
    holdedQuantity: 4,
    trackQuantity: true,
    tags: [],
    categories: [
      {
        __typename: "Category",
        id: 9,
        slug: "electronics",
        name: {
          __typename: "Text",
          id: 38,
          auto: "Electronics",
        },
      },
    ],
    mainImage: {
      __typename: "Image",
      id: 2078,
      sm: "https://res.cloudinary.com/dp5tdylzs/image/upload/c_scale,q_auto,w_60/v1625483704/kv3df5r1iwmn3pyct5rd.jpg",
      md: "https://res.cloudinary.com/dp5tdylzs/image/upload/c_scale,q_auto,w_300/v1625483704/kv3df5r1iwmn3pyct5rd.jpg",
      original: "https://res.cloudinary.com/dp5tdylzs/image/upload/v1625483704/kv3df5r1iwmn3pyct5rd.jpg",
    },
    type: null,
    brand: {
      __typename: "Brand",
      id: 2,
      name: "Louis Vuitton\t",
    },
    gallery: [],
    variants: [],
    createdAt: "2021-05-10T23:03:21.167Z",
    options: [],
    selectedOptions: [],
    parent: null,
  },
  {
    __typename: "Product",
    SKU: "nj9n62nh9j",
    published: true,
    quantityInLocations: [
      {
        __typename: "QuantityInLocation",
        id: 3394,
        quantity: 10,
      },
    ],
    id: 107,
    title: {
      __typename: "Text",
      id: 293,
      auto: "Wooden simple sofa ",
      ar: "",
      en: "Wooden simple sofa ",
    },
    description: {
      __typename: "Text",
      id: 292,
      ar: "",
      en: "",
    },
    ratingCount: null,
    ratingsAvg: null,
    realPrice: 8000,
    price: 10000,
    facebookPostId: null,
    comparingPrice: 13000,
    hasVariants: false,
    quantityInStock: 10,
    holdedQuantity: 0,
    trackQuantity: true,
    tags: [],
    categories: [
      {
        __typename: "Category",
        id: 198,
        slug: "اثاث-منزلي",
        name: {
          __typename: "Text",
          id: 8641,
          auto: "furniture ",
        },
      },
    ],
    mainImage: {
      __typename: "Image",
      id: 2079,
      sm: "https://res.cloudinary.com/dp5tdylzs/image/upload/c_scale,q_auto,w_60/v1625483820/jtkpl24iztkniedk519f.jpg",
      md: "https://res.cloudinary.com/dp5tdylzs/image/upload/c_scale,q_auto,w_300/v1625483820/jtkpl24iztkniedk519f.jpg",
      original: "https://res.cloudinary.com/dp5tdylzs/image/upload/v1625483820/jtkpl24iztkniedk519f.jpg",
    },
    type: null,
    brand: {
      __typename: "Brand",
      id: 7,
      name: "Samsung ",
    },
    gallery: [],
    variants: [
      {
        __typename: "Product",
        quantityInStock: 17,
        quantityInLocations: [
          {
            __typename: "QuantityInLocation",
            id: 104,
            quantity: 25,
          },
          {
            __typename: "QuantityInLocation",
            id: 115,
            quantity: -3,
          },
          {
            __typename: "QuantityInLocation",
            id: 132,
            quantity: 0,
          },
          {
            __typename: "QuantityInLocation",
            id: 133,
            quantity: -1,
          },
          {
            __typename: "QuantityInLocation",
            id: 134,
            quantity: -1,
          },
          {
            __typename: "QuantityInLocation",
            id: 2564,
            quantity: -1,
          },
        ],
        id: 108,
        SKU: "nj9n62nh9j-0",
      },
    ],
    createdAt: "2021-05-10T23:01:09.373Z",
    options: [
      {
        __typename: "ProductOption",
        attribute: {
          __typename: "Attribute",
          name: "Size",
          slug: "size",
        },
        values: [
          {
            __typename: "OptionValue",
            name: "43 Inch",
            slug: "43 inch",
          },
        ],
      },
    ],
    selectedOptions: [],
    parent: null,
  },
  {
    __typename: "Product",
    SKU: "vfrf297btd",
    published: true,
    quantityInLocations: [
      {
        __typename: "QuantityInLocation",
        id: 3393,
        quantity: 56,
      },
    ],
    id: 105,
    title: {
      __typename: "Text",
      id: 288,
      auto: "Toshiba",
      ar: "",
      en: "Toshiba",
    },
    description: {
      __typename: "Text",
      id: 287,
      ar: "",
      en: "",
    },
    ratingCount: null,
    ratingsAvg: null,
    realPrice: 3000,
    price: 3400,
    facebookPostId: null,
    comparingPrice: 5000,
    hasVariants: false,
    quantityInStock: 56,
    holdedQuantity: 0,
    trackQuantity: true,
    tags: [
      {
        __typename: "Tag",
        id: 5,
        name: "electronic",
      },
      {
        __typename: "Tag",
        id: 120,
        name: "smart-tv",
      },
    ],
    categories: [
      {
        __typename: "Category",
        id: 9,
        slug: "electronics",
        name: {
          __typename: "Text",
          id: 38,
          auto: "Electronics",
        },
      },
      {
        __typename: "Category",
        id: 8,
        slug: "شاشات-ذكيه",
        name: {
          __typename: "Text",
          id: 36,
          auto: "Smart TV screens",
        },
      },
    ],
    mainImage: {
      __typename: "Image",
      id: 1392,
      sm: "https://res.cloudinary.com/dp5tdylzs/image/upload/v1620687421/z3jgsmlumz9vr9xcurqq.jpg",
      md: "https://res.cloudinary.com/dp5tdylzs/image/upload/v1620687421/z3jgsmlumz9vr9xcurqq.jpg",
      original: null,
    },
    type: null,
    brand: {
      __typename: "Brand",
      id: 15,
      name: "Toshiba",
    },
    gallery: [],
    variants: [
      {
        __typename: "Product",
        quantityInStock: 57,
        quantityInLocations: [
          {
            __typename: "QuantityInLocation",
            id: 102,
            quantity: 56,
          },
          {
            __typename: "QuantityInLocation",
            id: 3063,
            quantity: 1,
          },
        ],
        id: 106,
        SKU: "vfrf297btd-0",
      },
    ],
    createdAt: "2021-05-10T22:58:41.740Z",
    options: [
      {
        __typename: "ProductOption",
        attribute: {
          __typename: "Attribute",
          name: "Size",
          slug: "size",
        },
        values: [
          {
            __typename: "OptionValue",
            name: "32 Inch",
            slug: "32-inch",
          },
        ],
      },
    ],
    selectedOptions: [],
    parent: null,
  },
  {
    __typename: "Product",
    SKU: "rbht9hg",
    published: true,
    quantityInLocations: [
      {
        __typename: "QuantityInLocation",
        id: 3392,
        quantity: 156,
      },
    ],
    id: 102,
    title: {
      __typename: "Text",
      id: 281,
      auto: "LG smart ",
      ar: "",
      en: "LG smart ",
    },
    description: {
      __typename: "Text",
      id: 280,
      ar: "",
      en: "",
    },
    ratingCount: null,
    ratingsAvg: null,
    realPrice: 3500,
    price: 4000,
    facebookPostId: null,
    comparingPrice: null,
    hasVariants: false,
    quantityInStock: 156,
    holdedQuantity: 0,
    trackQuantity: true,
    tags: [
      {
        __typename: "Tag",
        id: 5,
        name: "electronic",
      },
      {
        __typename: "Tag",
        id: 120,
        name: "smart-tv",
      },
    ],
    categories: [
      {
        __typename: "Category",
        id: 9,
        slug: "electronics",
        name: {
          __typename: "Text",
          id: 38,
          auto: "Electronics",
        },
      },
      {
        __typename: "Category",
        id: 8,
        slug: "شاشات-ذكيه",
        name: {
          __typename: "Text",
          id: 36,
          auto: "Smart TV screens",
        },
      },
    ],
    mainImage: {
      __typename: "Image",
      id: 1391,
      sm: "https://res.cloudinary.com/dp5tdylzs/image/upload/v1620687303/oiwnxa2sdxihunxo0p0n.jpg",
      md: "https://res.cloudinary.com/dp5tdylzs/image/upload/v1620687303/oiwnxa2sdxihunxo0p0n.jpg",
      original: null,
    },
    type: null,
    brand: {
      __typename: "Brand",
      id: 8,
      name: "LG ",
    },
    gallery: [],
    variants: [
      {
        __typename: "Product",
        quantityInStock: 78,
        quantityInLocations: [
          {
            __typename: "QuantityInLocation",
            id: 99,
            quantity: 78,
          },
        ],
        id: 103,
        SKU: "rbht9hg-0",
      },
      {
        __typename: "Product",
        quantityInStock: 77,
        quantityInLocations: [
          {
            __typename: "QuantityInLocation",
            id: 100,
            quantity: 78,
          },
          {
            __typename: "QuantityInLocation",
            id: 3646,
            quantity: -1,
          },
        ],
        id: 104,
        SKU: "rbht9hg-1",
      },
    ],
    createdAt: "2021-05-10T22:56:09.297Z",
    options: [
      {
        __typename: "ProductOption",
        attribute: {
          __typename: "Attribute",
          name: "Size",
          slug: "size",
        },
        values: [
          {
            __typename: "OptionValue",
            name: "32 Inch",
            slug: "32-inch",
          },
          {
            __typename: "OptionValue",
            name: "43 Inch",
            slug: "43-inch",
          },
        ],
      },
    ],
    selectedOptions: [],
    parent: null,
  },
  {
    __typename: "Product",
    SKU: "ndsu8602mn",
    published: true,
    quantityInLocations: [
      {
        __typename: "QuantityInLocation",
        id: 3391,
        quantity: 184,
      },
    ],
    id: 97,
    title: {
      __typename: "Text",
      id: 270,
      auto: "HUAWEI",
      ar: "",
      en: "HUAWEI",
    },
    description: {
      __typename: "Text",
      id: 269,
      ar: "",
      en: "",
    },
    ratingCount: null,
    ratingsAvg: null,
    realPrice: 6000,
    price: 7000,
    facebookPostId: null,
    comparingPrice: 8900,
    hasVariants: false,
    quantityInStock: 184,
    holdedQuantity: 0,
    trackQuantity: true,
    tags: [
      {
        __typename: "Tag",
        id: 3,
        name: "mobile",
      },
      {
        __typename: "Tag",
        id: 5,
        name: "electronic",
      },
    ],
    categories: [
      {
        __typename: "Category",
        id: 10,
        slug: "جوال",
        name: {
          __typename: "Text",
          id: 40,
          auto: "mobile",
        },
      },
      {
        __typename: "Category",
        id: 9,
        slug: "electronics",
        name: {
          __typename: "Text",
          id: 38,
          auto: "Electronics",
        },
      },
    ],
    mainImage: {
      __typename: "Image",
      id: 1390,
      sm: "https://res.cloudinary.com/dp5tdylzs/image/upload/v1620687109/nn7iiq3bem2a0dqi68iv.jpg",
      md: "https://res.cloudinary.com/dp5tdylzs/image/upload/v1620687109/nn7iiq3bem2a0dqi68iv.jpg",
      original: null,
    },
    type: null,
    brand: null,
    gallery: [],
    variants: [
      {
        __typename: "Product",
        quantityInStock: 46,
        quantityInLocations: [
          {
            __typename: "QuantityInLocation",
            id: 96,
            quantity: 46,
          },
        ],
        id: 100,
        SKU: "ndsu8602mn-3",
      },
      {
        __typename: "Product",
        quantityInStock: 37,
        quantityInLocations: [
          {
            __typename: "QuantityInLocation",
            id: 94,
            quantity: 46,
          },
          {
            __typename: "QuantityInLocation",
            id: 3663,
            quantity: -9,
          },
        ],
        id: 98,
        SKU: "ndsu8602mn-0",
      },
      {
        __typename: "Product",
        quantityInStock: 46,
        quantityInLocations: [
          {
            __typename: "QuantityInLocation",
            id: 97,
            quantity: 46,
          },
        ],
        id: 101,
        SKU: "ndsu8602mn-1",
      },
      {
        __typename: "Product",
        quantityInStock: 46,
        quantityInLocations: [
          {
            __typename: "QuantityInLocation",
            id: 95,
            quantity: 46,
          },
        ],
        id: 99,
        SKU: "ndsu8602mn-2",
      },
    ],
    createdAt: "2021-05-10T22:54:13.086Z",
    options: [
      {
        __typename: "ProductOption",
        attribute: {
          __typename: "Attribute",
          name: "Color",
          slug: "color",
        },
        values: [
          {
            __typename: "OptionValue",
            name: "Blue",
            slug: "blue",
          },
          {
            __typename: "OptionValue",
            name: "Red",
            slug: "red",
          },
          {
            __typename: "OptionValue",
            name: "White",
            slug: "white",
          },
          {
            __typename: "OptionValue",
            name: "Black",
            slug: "black",
          },
        ],
      },
    ],
    selectedOptions: [],
    parent: null,
  },
  {
    __typename: "Product",
    SKU: "vs68vbdfg",
    published: true,
    quantityInLocations: [
      {
        __typename: "QuantityInLocation",
        id: 3389,
        quantity: 264,
      },
    ],
    id: 89,
    title: {
      __typename: "Text",
      id: 252,
      auto: "Apple I phone",
      ar: "",
      en: "Apple I phone",
    },
    description: {
      __typename: "Text",
      id: 251,
      ar: "",
      en: "",
    },
    ratingCount: null,
    ratingsAvg: null,
    realPrice: 10000,
    price: 12000,
    facebookPostId: null,
    comparingPrice: 15000,
    hasVariants: false,
    quantityInStock: 264,
    holdedQuantity: 0,
    trackQuantity: true,
    tags: [
      {
        __typename: "Tag",
        id: 3,
        name: "mobile",
      },
      {
        __typename: "Tag",
        id: 5,
        name: "electronic",
      },
    ],
    categories: [
      {
        __typename: "Category",
        id: 10,
        slug: "جوال",
        name: {
          __typename: "Text",
          id: 40,
          auto: "mobile",
        },
      },
      {
        __typename: "Category",
        id: 9,
        slug: "electronics",
        name: {
          __typename: "Text",
          id: 38,
          auto: "Electronics",
        },
      },
    ],
    mainImage: {
      __typename: "Image",
      id: 1388,
      sm: "https://res.cloudinary.com/dp5tdylzs/image/upload/v1620686697/v4rags2ipditvehswlos.jpg",
      md: "https://res.cloudinary.com/dp5tdylzs/image/upload/v1620686697/v4rags2ipditvehswlos.jpg",
      original: null,
    },
    type: null,
    brand: null,
    gallery: [],
    variants: [
      {
        __typename: "Product",
        quantityInStock: 88,
        quantityInLocations: [
          {
            __typename: "QuantityInLocation",
            id: 88,
            quantity: 88,
          },
        ],
        id: 92,
        SKU: "vs68vbdfg-1",
      },
      {
        __typename: "Product",
        quantityInStock: 88,
        quantityInLocations: [
          {
            __typename: "QuantityInLocation",
            id: 86,
            quantity: 88,
          },
        ],
        id: 90,
        SKU: "vs68vbdfg-0",
      },
      {
        __typename: "Product",
        quantityInStock: 88,
        quantityInLocations: [
          {
            __typename: "QuantityInLocation",
            id: 87,
            quantity: 88,
          },
        ],
        id: 91,
        SKU: "vs68vbdfg-2",
      },
    ],
    createdAt: "2021-05-10T22:46:46.130Z",
    options: [
      {
        __typename: "ProductOption",
        attribute: {
          __typename: "Attribute",
          name: "Color",
          slug: "color",
        },
        values: [
          {
            __typename: "OptionValue",
            name: "Blue",
            slug: "blue",
          },
          {
            __typename: "OptionValue",
            name: "White",
            slug: "white",
          },
          {
            __typename: "OptionValue",
            name: "Black",
            slug: "black",
          },
        ],
      },
    ],
    selectedOptions: [],
    parent: null,
  },
  {
    __typename: "Product",
    SKU: "mtih2054",
    published: true,
    quantityInLocations: [
      {
        __typename: "QuantityInLocation",
        id: 3388,
        quantity: 80,
      },
    ],
    id: 86,
    title: {
      __typename: "Text",
      id: 245,
      auto: "Jacket",
      ar: "",
      en: "Jacket",
    },
    description: {
      __typename: "Text",
      id: 244,
      ar: "",
      en: "",
    },
    ratingCount: null,
    ratingsAvg: null,
    realPrice: 90,
    price: 99,
    facebookPostId: null,
    comparingPrice: 344,
    hasVariants: false,
    quantityInStock: 80,
    holdedQuantity: 0,
    trackQuantity: true,
    tags: [
      {
        __typename: "Tag",
        id: 2,
        name: "women",
      },
    ],
    categories: [
      {
        __typename: "Category",
        id: 3,
        slug: "clothes",
        name: {
          __typename: "Text",
          id: 22,
          auto: "Clothes",
        },
      },
      {
        __typename: "Category",
        id: 5,
        slug: "ملابس-نسائيه",
        name: {
          __typename: "Text",
          id: 30,
          auto: "Womens",
        },
      },
    ],
    mainImage: {
      __typename: "Image",
      id: 1387,
      sm: "https://res.cloudinary.com/dp5tdylzs/image/upload/v1620686580/zfisa7lzestp4eqae5yg.jpg",
      md: "https://res.cloudinary.com/dp5tdylzs/image/upload/v1620686580/zfisa7lzestp4eqae5yg.jpg",
      original: null,
    },
    type: null,
    brand: {
      __typename: "Brand",
      id: 5,
      name: "H&M",
    },
    gallery: [],
    variants: [
      {
        __typename: "Product",
        quantityInStock: 40,
        quantityInLocations: [
          {
            __typename: "QuantityInLocation",
            id: 83,
            quantity: 40,
          },
        ],
        id: 87,
        SKU: "mtih2054-0",
      },
      {
        __typename: "Product",
        quantityInStock: 39,
        quantityInLocations: [
          {
            __typename: "QuantityInLocation",
            id: 84,
            quantity: 40,
          },
          {
            __typename: "QuantityInLocation",
            id: 3647,
            quantity: -1,
          },
        ],
        id: 88,
        SKU: "mtih2054-1",
      },
    ],
    createdAt: "2021-05-10T22:44:02.571Z",
    options: [
      {
        __typename: "ProductOption",
        attribute: {
          __typename: "Attribute",
          name: "Size",
          slug: "size",
        },
        values: [
          {
            __typename: "OptionValue",
            name: "Medium",
            slug: "medium",
          },
          {
            __typename: "OptionValue",
            name: "Small",
            slug: "small",
          },
        ],
      },
      {
        __typename: "ProductOption",
        attribute: {
          __typename: "Attribute",
          name: "Color",
          slug: "color",
        },
        values: [
          {
            __typename: "OptionValue",
            name: "Blue",
            slug: "blue",
          },
        ],
      },
    ],
    selectedOptions: [],
    parent: null,
  },
];
