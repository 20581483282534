import React, { useState } from "react";
import {
  Avatar,
  Badge,
  Button,
  Col,
  Dropdown,
  Layout,
  List,
  Menu,
  Popover,
  Row,
  Space,
  Typography,
} from "antd";

import { Link, useHistory } from "react-router-dom";

import { gql, useMutation, useQuery } from "@apollo/client";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
const REQUESTS = gql`
  query REQUESTS {
    quantityRequests(where: { status: { equals: PENDING } }, orderBy: { id: desc }, take: 20) {
      id
      quantity
      product {
        id
        title {
          id
          auto
        }
      }
      requester {
        id
        name
        role
      }
      createdAt
    }
  }
`;
export const ACCEPT_CHANGE_QUANTITY = gql`
  mutation ACCEPT_CHANGE_QUANTITY($id: Int!) {
    acceptRequestToUpdateProductQuantity(id: $id) {
      id
    }
  }
`;
export const CANCELL_CHANGE_QUANTITY = gql`
  mutation CANCELL_CHANGE_QUANTITY($id: Int!) {
    cancelRequestToUpdateProductQuantity(id: $id) {
      id
    }
  }
`;

const Notifi = ({ id }) => {
  const [accept, { loading: acceptLoading }] = useMutation(ACCEPT_CHANGE_QUANTITY, {
    refetchQueries: [{ query: REQUESTS }],
  });
  const [cancel, { loading: cancelLoading }] = useMutation(CANCELL_CHANGE_QUANTITY, {
    refetchQueries: [{ query: REQUESTS }],
  });
  const loading = acceptLoading || cancelLoading;
  return (
    <Space size="small">
      <Button
        type="primary"
        disabled={loading}
        loading={acceptLoading}
        onClick={() => accept({ variables: { id } })}>
        Accept
      </Button>
      <Button
        onClick={() => cancel({ variables: { id } })}
        loading={cancelLoading}
        disabled={loading}>
        Cancel
      </Button>
    </Space>
  );
};

const AdminNotifications = ({ isAdmin }) => {
  const { data, loading } = useQuery(REQUESTS, {
    fetchPolicy: "network-only",
    pollInterval: 10000,
  });
  if (isAdmin && !data?.quantityRequests.length) return null;
  return null;
  return (
    <List
      itemLayout="horizontal"
      loading={loading}
      dataSource={data?.quantityRequests || []}
      renderItem={({ quantity, requester, createdAt, product, id }) => {
        const { name, role } = requester || {};

        return (
          <List.Item actions={[formatDistanceToNow(new Date(createdAt), { addSuffix: true })]}>
            <Space direction="vertical">
              <Typography.Text>
                {name} as {role} request to add {quantity} to product quantity with id {product.id}{" "}
                ({product.title.auto})
              </Typography.Text>

              <Notifi id={id} />
            </Space>
          </List.Item>
        );
      }}
    />
  );
};

export default AdminNotifications;
