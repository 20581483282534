interface Props {
  tags: string[];
  categories: number[];
  brands: number[];
  inventories: number[];
  orderStatus: string[];
  paidStatus: string[];
  executionStatus: string[];
  products: string[];
}

export function addQueriesToFilter({
  tags,
  categories,
  brands,
  inventories,
  orderStatus,
  paidStatus,
  executionStatus,
  products,
}: Props) {
  const newFilter = [];
  let newQueries: any = {};
  if (tags.length) {
    newFilter.push({ tags: { some: { name: { in: tags } } } });
    newQueries.tags = tags;
  }
  if (categories.length) {
    newFilter.push({ categories: { some: { id: { in: categories } } } });
    newQueries.categories = categories;
  }
  if (products.length) {
    newFilter.push({ lineItems: { some: { product: { id: { in: products } } } } });
    newQueries.products = products;
  }
  if (brands.length) {
    newFilter.push({ brandId: { in: brands } });
    newQueries.brands = brands;
  }
  if (inventories.length) {
    newFilter.push({ quantityInLocations: { some: { locationId: { in: inventories } } } });
    newQueries.inventories = inventories;
  }
  if (orderStatus.length) {
    newFilter.push({ status: { in: orderStatus } });
    newQueries.orderStatus = orderStatus;
  }
  if (paidStatus.length) {
    newFilter.push({ paymentStatus: { in: paidStatus } });
    newQueries.paidStatus = paidStatus;
  }

  if (executionStatus.length) {
    newFilter.push({ fulfillmentStatus: { in: executionStatus } });
    newQueries.executionStatus = executionStatus;
  }
  return { newFilter, newQueries };
}
