export const Permissions = {
  orders: "orders",
  addOrder: "addOrder",
  removeOrder: "removeOrder", 
  order: "order",
  editOrder: "editOrder",
  users: "users",
  pages: "pages",
  chatScreen: "chatScreen",
  bestSelling: "bestSelling",
  analytics: "analytics",
  discounts: "discounts",
  addDiscount: "addDiscount",
  updateDiscount: "updateDiscount",
  removeDiscount: "removeDiscount", 
  addProduct: "addProduct",
  importProducts: "importProducts",
  updateProduct: "updateProduct",
  removeProduct: "removeProduct", 
  inventories: "inventories",
  theming: "theming",
  navigation: "navigation",
  blog: "blog",
  updateSettings: "updateSettings",
  locations: "locations",
  shippingZones: "shippingZones",
  domains: "domains",
};
