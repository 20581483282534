import React, { useEffect, useMemo, useState } from "react";
import {
  Form,
  Upload,
  Modal,
  Input,
  InputNumber,
  Divider,
  Checkbox,
  Row,
  Col,
  Typography,
  Space,
  Spin,
} from "antd";
import { FormCard } from "../style";
import { useQuery } from "@apollo/client";

import { MY_INVENTORIES } from "../../../../settings/locations";
import { QuantityChanger } from "../../../inventory";
import { useTranslation } from "react-i18next";
import trash from "../../../../../assets/imgs/trash-2.svg";
import { useResponsive } from "../../../../../lib/useResponsive";

export const QuantityItem = ({ inTable, faildName = [], form }) => {
  console.log("🚀 ~ file: quantityCard.js ~ line 22 ~ QuantityItem ~ faildName", faildName);
  const { data, loading } = useQuery(MY_INVENTORIES);

  console.log("datadatadatadatadatadatadata", data);

  const { t } = useTranslation();

  return (
    <Spin spinning={loading}>
      {data?.inventories.length == 0 ||
      form?.getFieldValue("quantities")["0"] ||
      (faildName[0] && form?.getFieldValue(faildName[0])[faildName[1]]["quantities"]["0"]) ? (
        <Form.Item
          name={[...faildName, "quantities", "0"]}
          style={{ marginBottom: 0 }}
          label={!inTable && "Available"}>
          <InputNumber parser={(value) => value.replace(/\$\s?|(,*)/g, "")} min={0} />
        </Form.Item>
      ) : data?.inventories.length == 1 ? (
        <Form.Item
          name={[...faildName, "quantities", data?.inventories[0].id.toString()]}
          style={{ marginBottom: 0 }}
          label={!inTable && "Available"}>
          <InputNumber
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            min={0}
            style={{ width: "50%" }}
          />
        </Form.Item>
      ) : (
        <>
          {!inTable && (
            <>
              <Row align="middle" justify="space-between">
                <Col>
                  <Space direction="vertical">
                    <Typography.Text strong> {t("products.locationName")} </Typography.Text>
                  </Space>
                </Col>

                <Col>
                  <Typography.Text strong> {t("products.available")} </Typography.Text>
                </Col>
              </Row>
              <Divider style={{ marginTop: 10 }} />
            </>
          )}
          {data?.inventories?.map(({ name, id }, index) => (
            <>
              <Space size={15}>
                {/* <Typography.Text>
                  {t("products.branch")} {index + 1}
                </Typography.Text> */}
                {/* <Form.Item
                  name={[...faildName, "quantities", id.toString()]}
                  style={{ marginBottom: 0 }}>
                  <InputNumber parser={(value) => value.replace(/\$\s?|(,*)/g, "")} min={0} />
                </Form.Item> */}
              </Space>
              <Row align="middle" justify="space-between" key={id}>
                <Col>
                  <Space direction="vertical">
                    <Typography.Text> {name}</Typography.Text>
                  </Space>
                </Col>

                <Col>
                  <Form.Item
                    name={[...faildName, "quantities", id.toString()]}
                    style={{ marginBottom: 0 }}>
                    <InputNumber parser={(value) => value.replace(/\$\s?|(,*)/g, "")} min={0} />
                  </Form.Item>
                </Col>
              </Row>
              <Divider style={{ border: 0 }} />
            </>
          ))}
        </>
      )}
    </Spin>
  );
};
export const QuantityItemEdit = ({ form, product }) => {
  const { data, loading } = useQuery(MY_INVENTORIES);

  const [quantities, setQantities] = useState("");

  useEffect(() => {
    setQantities(form?.getFieldValue("quantities"));
  }, [data]);

  const updateQuantitiesCache = ({ locationId, quantity }) => {
    if (quantities.some((quant) => quant.locationId == locationId)) {
      setQantities(
        quantities.map((quant) =>
          quant.locationId == locationId
            ? {
                ...quant,
                locationId,
                quantity: Number(quant.quantity) + quantity,
              }
            : quant,
        ),
      );
    } else {
      setQantities([
        ...quantities,
        {
          locationId,
          quantity: quantity,
        },
      ]);
    }
  };
  if (loading) return <Spin />;
  return (
    <>
      {
        <>
          {data?.inventories?.map(({ name, id }) => (
            <>
              <Row align="middle" justify="space-between" key={id}>
                <Col>
                  <Space direction="vertical">
                    <Typography.Text> {name} </Typography.Text>
                  </Space>
                </Col>

                <Col>
                  <Space>
                    <Typography.Text>
                      {quantities.find(({ locationId }) => locationId == id)?.quantity}
                    </Typography.Text>
                    <QuantityChanger
                      record={{ ...product, title: product.title.auto }}
                      variables={{ id }}
                      updateQuantitiesCache={updateQuantitiesCache}
                      selectedInventory={{ name }}
                    />
                  </Space>
                </Col>
              </Row>
              <Divider />
            </>
          ))}
        </>
      }
    </>
  );
};
const QuantityCard = ({ trackQuantity, form, isEditing, product }) => {
  console.log(
    "🚀 ~ file: quantityCard.js ~ line 157 ~ QuantityCard ~ trackQuantity",
    trackQuantity,
  );
  const { t } = useTranslation();
  const { lg } = useResponsive();

  return (
    <FormCard>
      <h2>{t("products.inventory")}</h2>

      <Row gutter={[0, 15]}>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item
            label={t("products.sku")}
            name="SKU"
            style={{
              display: "inline-block",
              width: lg ? "calc(100% - 5%)" : "100%",
              margin: "0px 8px 0 8px",
            }}>
            <Input />
          </Form.Item>
        </Col>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item
            label={t("products.barcode")}
            name="barcode"
            style={{
              display: "inline-block",
              width: lg ? "calc(100% - 5%)" : "100%",
            }}>
            <Input />
          </Form.Item>
        </Col>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}></Col>
      </Row>

      <Form.Item name="trackQuantity">
        <Checkbox.Group>
          <Checkbox
            value="trackQuantity"
            style={{
              lineHeight: "32px",
              width: "100%",
            }}>
            {t("products.trackQuantity")}
          </Checkbox>

          <Checkbox
            value="outOfStock"
            style={{
              margin: 0,
              // display: trackQuantity && trackQuantity.includes("trackQuantity") ? "unset" : "none",
              // visibility:
              //   trackQuantity && trackQuantity.includes("trackQuantity") ? "visible" : "hidden",
              lineHeight: "32px",
            }}>
            {t("products.continue")}
          </Checkbox>
        </Checkbox.Group>
      </Form.Item>

      {trackQuantity && trackQuantity.includes("trackQuantity") && (
        <>
          <Divider />
          <h3 style={{ marginBottom: 24 }}>{t("products.quantity")}</h3>
          {product?.holdedQuantity ? (
            <h3 style={{ marginBottom: 24 }}>
              {t("analytics.holded")} {product?.holdedQuantity}
            </h3>
          ) : null}
          {isEditing ? (
            <QuantityItemEdit form={form} product={product} />
          ) : (
            <QuantityItem form={form} />
          )}
        </>
      )}
    </FormCard>
  );
};

export default QuantityCard;
