import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Switch, Spin, Space } from "antd";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import {
  ClockCircleOutlined,
  LeftOutlined,
  PlusCircleOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { POSTS } from "..";
import { useParams } from "react-router-dom";
import TitleCard from "../../products/products/addEdit/cards/titleCard";
import { FormCard } from "../../products/products/addEdit/style";
import CategoriesSelect from "../../../components/common/selectors/categorySelector";
import ChooseImg from "../../products/products/addEdit/cards/chooseImage";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import SeoCard from "../../../components/common/seoCard";
import TagsSelectors from "../../../components/common/selectors/tagsSelectors";
import CommonError from "../../error";
import { SETTINGS } from "../../settings/generals/card/defaultLanguage";
import { SEO_ITEM } from "../../../modules/common/queries";
import MetaTags from "./metaTags";
import HasFeature from "../../../components/hasFuture";
import FeaturesNames from "../../../enums/featuresNames";
import Schedular from "../../products/products/addEdit/cards/schedular";
import EditThemeProduct from "../../products/products/addEdit/cards/editThemeProduct";
import AddEditStickyContainer from "../../../components/layout/header/addEditSticky";
import SuccessNotification from "../../../components/common/successNotification";

const EDIT_POST = gql`
  mutation EDIT_POST(
    $id: Int!
    $title: MultiLangText
    $description: MultiLangText
    $imageId: Int
    $categoryId: Int
    $seo: SeoItemArg
    $tags: [String!]
    $publishAt: DateTime
  ) {
    updatePost(
      id: $id
      title: $title
      description: $description
      imageId: $imageId
      publishAt: $publishAt
      categoryId: $categoryId
      seo: $seo
      tags: $tags
    ) {
      id
    }
  }
`;

export const SINGLE_POST = gql`
  query SINGLE_POST($id: Int!) {
    post(where: { id: $id }) {
      publishAt
      title {
        id
        ar
        en
      }
      theme {
        id
      }
      tags {
        id
        name
      }
      description {
        id
        ar
        en
      }
      categoryId
      image {
        id
        md
      }
      seo {
        id
        title {
          id
          en
          ar
          auto
        }
        slug {
          id
          en
          ar
          auto
        }
        body {
          en
          id
          ar
          auto
        }
        fivicon {
          id
          original
          xl
          lg
        }
        follow
        indexed
        socialItems {
          id
          name
          enable
          image {
            id
            original
            xl
            lg
          }
          title {
            id
            en
            ar
            auto
          }
          body {
            id
            en
            ar
            auto
          }
        }
        extendTitle
        # id
        # title {
        #   id
        #   ar
        #   en
        # }
        # slug {
        #   id
        #   ar
        #   en
        # }
        # body {
        #   id
        #   ar
        #   en
        # }
      }
    }
  }
`;

/**
 * renderComponent
 * @component
 * @name addCompanyModal
 * @memberof PostS
 * @summary admin can add company
 * @return {React} React component view
 */

const EditPost = () => {
  const [editPost, postProps] = useMutation(EDIT_POST);

  let { id } = useParams();

  if (id > 2147483647) {
    id = 2147483647;
  }
  const {
    t,
    i18n: { language },
  } = useTranslation();

  console.log("editPost -> id", id);
  const { data, ...singlePost } = useQuery(SINGLE_POST, {
    variables: {
      id: Number.isInteger(parseInt(id)) ? parseInt(id) : 2147483647,
    },
  });

  const { data: defaultLanguage, loading: defaultLanguageLoading } = useQuery(SETTINGS);
  console.log("editPost -> data", data);

  const [isEn, setIsEn] = useState();
  // const [slug, setSlug] = useState({ ar: "", en: "" });

  const [seoTitle, setSeoTitle] = useState({});
  const [description, setDescription] = useState("");
  const [descriptionAr, setDescriptionAr] = useState("");
  const [image, setImage] = useState(null);
  const [moment, setMoment] = useState(data?.post?.publishAt);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [form] = Form.useForm();
  const router = useHistory();
  useEffect(() => {
    console.log("🚀 ~ file: edit.js ~ line 129 ~ EditPost ~ data", data);
  }, [data]);
  const validateMessages = {
    required: t("customers.required"),
  };

  /**
   * @description admin can add new company from this modal
   */

  const onFinish = async (variables) => {
    console.log("🚀 ~ file: edit.js ~ line 113 ~ onFinish ~ variables", variables);
    console.log(
      "🚀 ~ file: edit.js ~ line 125 ~ onFinish ~ form.getFieldValue)",
      form.getFieldValue("seo"),
    );

    // setLoading(true);
    try {
      await editPost({
        variables: {
          ...variables,
          id: parseInt(id),
          imageId: image?.uid,
          title: form.getFieldValue("title"),
          seo: form.getFieldValue("seo"),
          publishAt: moment,
          // slug: slug.en,
          description: {
            en: description,
            ar: descriptionAr,
          },
        },
        refetchQueries: [{ query: POSTS, variables: { filter: {} } }],
      });
      SuccessNotification({
        defaultTitle: t("products.successDefaultEditMessage"),
        title: t("products.successDefaultEditMessage"),
        type: "success",
      });
    } catch (error) {
      console.log("editPost -> error", error);
      // setLoading(false);
    }
    // setLoading(false);
  };
  console.log("moment", moment);
  useEffect(() => {
    if (data?.post) {
      const {
        description,
        title,
        ...rest
        // trackQuantity,
      } = data?.post;

      setDescription(description.en);
      setDescriptionAr(description.ar);
      setSeoTitle(title);
      setImage({ uid: rest.image?.id, url: rest.image?.md });
      // if (rest.slug.includes("-")) {
      //   const enSlugWithoutIdArray = rest.slug.split("-");
      //   console.log("enSlugWithoutIdArray", enSlugWithoutIdArray);

      //   setSlug({
      //     ar: "",
      //     en: enSlugWithoutIdArray.join("-"),
      //   });
      //   form.setFieldsValue({
      //     slug: {
      //       ar: "",
      //       en: enSlugWithoutIdArray.join("-"),
      //     },
      //   });
      // } else {
      //   const enSlugWithoutIdArray = rest.slug;
      //   console.log("enSlugWithoutIdArray", enSlugWithoutIdArray);

      //   setSlug({
      //     ar: "",
      //     en: enSlugWithoutIdArray,
      //   });

      //   form.setFieldsValue({
      //     slug: {
      //       ar: "",
      //       en: enSlugWithoutIdArray,
      //     },
      //   });
      // }
    }
    // form.setFieldsValue(data?.Post);
  }, [data]);

  if (singlePost.loading || defaultLanguageLoading)
    return (
      <Spin
        style={{
          position: "absolute",

          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    );

  if (!data.post) {
    return <CommonError />;
  }

  const { title, seo, theme, ...rest } = data.post;

  return (
    <>
      <Row>
        <Col span={24}>
          <Button
            icon={language == "ar" ? <RightOutlined /> : <LeftOutlined />}
            onClick={() => router.push("/posts")}
            className="back"
            type="link"
            style={{ width: "fit-content", padding: "0 0 0 10px" }}>
            {t("apps.blog")}
          </Button>
        </Col>
      </Row>
      <Form
        validateMessages={validateMessages}
        name="editPost"
        form={form}
        className="login-form"
        onFinish={onFinish}
        initialValues={{
          ...rest,

          title: { ar: title.ar, en: title.en },
          seo: {
            title: { ar: seo?.title?.ar, en: seo?.title?.en },
            slug: { ar: seo?.slug?.ar, en: seo?.slug?.en },
            body: { ar: seo?.body?.ar, en: seo?.body?.en },
          },
          tags: rest.tags?.map(({ name }) => name),
        }}
        labelCol={{ span: 24 }}
        labelAlign="left"
        onValuesChange={(val) => {
          console.log("editPost -> val", val);
          val.title && setSeoTitle({ ...seoTitle, ...val.title });

          // val.title && setSlug({ ...slug, ...val.title });

          // val.slug && setSlug({ ...slug, ...val.slug });
        }}>
        <AddEditStickyContainer
          children={
            <Row justify="space-between">
              <Col>
                <h2 style={{ marginLeft: "20px", fontWeight: "bold" }}>{t("posts.updatePost")}</h2>
              </Col>
              <Col style={{ textAlign: "right" }}>
                <Space size={8}>
                  <Button type="text" onClick={() => setIsModalVisible(true)}>
                    {t("products.schedular")}
                    <ClockCircleOutlined />
                  </Button>
                  {isModalVisible && (
                    <Schedular
                      defaultMomentValue={data?.post?.publishAt}
                      setMoment={setMoment}
                      currrentMoment={moment}
                      isEdit={true}
                      setIsModalVisible={setIsModalVisible}
                      isModalVisible={isModalVisible}
                      page="post"
                    />
                  )}
                  <div>
                    <Button
                      type="primary"
                      loading={postProps.loading}
                      disabled={postProps.loading}
                      // icon={<PlusCircleOutlined />}
                      htmlType="submit">
                      {t("settings.save")}
                    </Button>
                  </div>
                </Space>

                {/* <Switch
            style={{ marginTop: "20px" }}
            checkedChildren="AR"
            unCheckedChildren="EN"
            onChange={() => setIsEn(!isEn)}
          /> */}
              </Col>
            </Row>
          }
        />
        <Row gutter={16}>
          <Col span={24} md={16}>
            <TitleCard
              isEn={isEn}
              description={description}
              setDescription={setDescription}
              descriptionAr={descriptionAr}
              setDescriptionAr={setDescriptionAr}
              // slug={slug}
              pageName="posts"
              form={form}
              isVariant={false}
            />

            <SeoCard
              form={form}
              type="blog"
              lang={isEn ? "en" : "ar"}
              isEdit
              seoItemId={data.post?.seo?.id}
              defaultSeoValue={{
                title: seoTitle,
                body: { en: description, ar: descriptionAr },
                slug: { en: data?.post?.seo?.slug?.en, ar: data?.post?.seo?.slug?.ar },
              }}
            />
          </Col>

          <Col span={24} md={8}>
            <EditThemeProduct pageId={id} pageType="POST" theme={theme} />

            <FormCard style={{ display: "grid" }}>
              <h2>{t("posts.image")}</h2>

              <ChooseImg setChossingImage={setImage} defaultImage={image} />
            </FormCard>
            <FormCard>
              <Form.Item label={t("posts.category")} name="categoryId">
                <CategoriesSelect type="POST" />
              </Form.Item>
              <Form.Item label={t("home.tags")} name="tags">
                <TagsSelectors type="POST" />
              </Form.Item>
            </FormCard>
          </Col>
        </Row>
      </Form>
      {/* <HasFeature feature={FeaturesNames.seo}>
        <Col span={24} md={16}>
          <MetaTags
            seoId={data.post?.seo?.id}
            // {...{ setFaceBookImage, facebookImage, setTwitterImage, twitterImage }}
          />
        </Col>
      </HasFeature> */}
    </>
  );
};

export default EditPost;
