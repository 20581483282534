import React, { useEffect } from "react";
import { CustomFormItem, CustomRow } from "../editProfile.styles";
import {
  Form,
  Select,
  Icon,
  Input,
  Button,
  Row,
  Col,
  Upload,
  Divider,
  notification,
  Typography,
  Space,
} from "antd";
import UserAvatar from "../userAvatar/userAvatar";
import { FieldTitle } from "../editProfile.styles";

import { useMutation, gql } from "@apollo/client";
import { CURRENT_USER } from "../../../utlis/Me";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import SuccessNotification from "../../../components/common/successNotification";
import PhoneNumberInput from "../../../components/common/phoneInput";

const { Option } = Select;
const { Title } = Typography;

export const UPDATE_MY_PROFILE = gql`
  mutation UPDATE_MY_PROFILE($data: updateMyProfileInput!) {
    updateMyProfile(data: $data) {
      id
    }
  }
`;

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    lg: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    lg: { span: 18 },
  },
};

export const UserData = ({ loading, data }) => {
  const [edit, editProp] = useMutation(UPDATE_MY_PROFILE, {
    refetchQueries: [{ query: CURRENT_USER }],
  });

  const { t } = useTranslation();

  const handleEditData = (values) => {
    edit({
      variables: {
        data: {
          ...values,
        },
      },
    })
      .then(() => {
        notification["success"]({
          message: t("profile.done"),
          description: t("profile.updatedSuccessfully"),
        });
      })
      .catch((err) => console.log("TCL: PersonalInformation -> err", err));
  };
  const [form] = Form.useForm();
  useEffect(() => {
    if (data && data.me) {
      const { name, email, phone } = data.me;
      form.setFieldsValue({
        email,
        name,
        phone,
      });
    }
  }, [loading]);

  return (
    <Container>
      <UserAvatar uid={data && data.me.id} img={data && data.me.avatar} />
      <Title className="title" level={4}>
        {t("profile.personalData")}
      </Title>

      <Form
        form={form}
        onFinish={handleEditData}
        initialValues={{ phonePrefix: "20" }}
        {...formItemLayout}
        layout="horizontal">
        <CustomRow justify="center" align="middle">
          {/* <Col span={5}>
            <FieldTitle>{t("settings.name")}</FieldTitle>
          </Col> */}
          <Col span={24}>
            <CustomFormItem
              // labelCol={{ span: 24 }}
              label={t("settings.name")}
              style={{
                height: "fit-content",
                margin: "0px",
              }}
              // label={t("settings.name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: `${t("users.requiredMessage")}`,
                },
              ]}>
              <Input placeholder={t("settings.name")} />
            </CustomFormItem>
          </Col>
        </CustomRow>

        <CustomRow justify="center" align="middle">
          {/* <Col span={5}>
            <FieldTitle>{t("settings.email")}</FieldTitle>
          </Col > */}
          <Col span={24}>
            <CustomFormItem
              // labelCol={{ span: 5 }}
              label={t("settings.email")}
              name="email"
              rules={[
                {
                  type: "email",
                  message: `${t("users.requiredMessage")}`,
                },
                {
                  required: true,
                  message: `${t("users.requiredMessage")}`,
                },
              ]}>
              <Input placeholder={t("settings.email")} />
            </CustomFormItem>
          </Col>
        </CustomRow>
        <CustomRow justify="center" align="middle">
          {/* <Col span={5}>
            <FieldTitle>{t("settings.phone")}</FieldTitle>
          </Col> */}
          <Col span={24}>
            <PhoneNumberInput name={"phone"} hasFeedback label={t("settings.phone")} />
            {/* <CustomFormItem
              label={t("settings.phone")}
              // labelCol={{ span: 5 }}
              name={"phone"}
              // rules={[
              //   {
              //     pattern: new RegExp(
              //       "^(1s?)?((([0-9]{3}))|[0-9]{3})[s-]?[\0-9]{3}[s-]?[0-9]{4}$"
              //     ),
              //     message: "This Field Is Required!",
              //   },
              // ]}
              hasFeedback>
              <Input placeholder={t("settings.phone")} />
            </CustomFormItem> */}
          </Col>
        </CustomRow>

        {/* <Row justify="end" >
          <Col>
            <Button loading={editProp.loading} htmlType="submit" type="primary" block>
              {t("profile.update")}
            </Button>
          </Col>
        </Row> */}
        <div className="btn-container">
          <Button loading={editProp.loading} htmlType="submit" type="primary">
            {t("profile.update")}
          </Button>
        </div>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  .title {
    font-weight: 700;
    font-size: ${(props) => (props.theme.isBreakpoints.md ? "18px" : "16px")};
    color: ${(props) => props.theme.colors.black};
    text-align: ${(props) => (props.theme.isBreakpoints.md ? "initial" : "center")};
    margin: 18px 0 40px;
  }
  .btn-container {
    width: 100%;
    text-align: end;
    .ant-btn {
      width: ${(props) => (props.theme.isBreakpoints.md ? "auto" : "100%")};
    }
  }
`;

export default UserData;
