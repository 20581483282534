import { CloudUploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";
import { Modal, Typography, Button, Input, Divider, Select, Upload, message } from "antd";
import { useState } from "react";
import styled from "styled-components";
import { uploadGallery } from "../../../../utlis/upload";
const { Text } = Typography;
const { Option } = Select;
const { Dragger } = Upload;
export const ADD_SUGGEST = gql`
  mutation ADD_SUGGEST($title: String!, $desc: String!, $files: [ImageInput!]) {
    addComplaint(desc: $desc, title: $title, type: Suggest, files: $files) {
      id
    }
  }
`;
const suggestions = [
  { value: "إدارة المنتجات أو المخزون" },
  { value: "طرق الدفع" },
  { value: "طرق الشحن" },
  { value: "التسويق" },
  { value: "واجهة المتجر " },
  { value: "التقارير " },
  { value: "أخري" },
];
const Suggest = ({ setIsModalVisible, isModalVisible }) => {
  const [yourSuggest, setYourSuggest] = useState("");
  const [suggestType, setSuggestType] = useState("");
  const [imageUplaodLoading, setLoading] = useState(false);

  const [addSuggest, { loading: loadingMutate }] = useMutation(ADD_SUGGEST);

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const loading = imageUplaodLoading || loadingMutate;

  const [files, setFiles] = useState([]);
  const props = {
    disabled: loading,
    fileList: files,
    multiple: true,
    onChange(info) {
      return setFiles(info.fileList);
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },

    name: "files",
    showUploadList: {
      removeIcon: <DeleteOutlined />,
    },

    beforeUpload: (file) => {
      return false;
    },
  };
  return (
    <>
      <Modal
        onCancel={handleCancel}
        footer={null}
        title="أهلا بك في ملف اقتراحات خواص ومزايا بحر"
        visible={isModalVisible}>
        <ModelContainer>
          <Text style={{ display: "block" }} className="label">
            بما يتعلق اقتراحك؟
          </Text>
          <Select
           size='large'
            className="select"
            showSearch
            value={suggestType}
            placeholder="Select a person"
            onSelect={(value) => {
              console.log(value,'val')
              setSuggestType(value);
             
            }}
            optionFilterProp="children">
            {suggestions.map((suggest) => {
              return <Option value={suggest.value}>{suggest.value}</Option>;
            })}
          </Select>
         {suggestType === 'أخري' ?  <Input className='other-field' /> : null}
          <Text className="label margin">ماهو إقتراحك؟ </Text>
          <Input.TextArea
            value={yourSuggest}
            onChange={(e) => {
              
              setYourSuggest(e.target.value);
            }}
          />
          <Text className="label margin">هل يمكنك إضافة ملف يوضح اقتراحك أكثر ؟</Text>
          <Dragger className="upload-image" {...props}>
            <div className="upload-container">
              <CloudUploadOutlined className="cloud-icon" />

              <Text className="ant-upload-text">إضافة الملف </Text>
            </div>
          </Dragger>
          <Divider />
          <Button
            loading={loading}
            disabled={loading}
            onClick={async () => {
              if (!yourSuggest || !suggestType) {
                message.error("يرجى إدخال جميع البيانات");
                return;
              }

              setLoading(true);

              if (files) {
                console.log("onFinish -> files", files);

                var finalFiles = await uploadGallery(files);
                console.log("onFinish -> finalfiles", finalFiles);
              }
              setLoading(false);

              await addSuggest({
                variables: { title: suggestType, desc: yourSuggest, files: finalFiles },
                onCompleted: (data) => {
                  console.log("onCompleted -> data", data);
                  setYourSuggest("");
                  setFiles([]);
                  setSuggestType("");
                  setIsModalVisible(false);
                },
              });
            }}
            className="send-btn"
            type="primary"
            block>
            إرسال
          </Button>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default Suggest;

const ModelContainer = styled.div`
  .label {
    display: block;
    font-size: 16px;
    margin-bottom: 10px;
  }
  .ant-select {
    width: 100%;
  }

  .upload-image {
    height: 121px;
    border-radius: 12px;
    border: 1px dashed #6ebbc5;
    background-color: unset;
  }

  .ant-upload-text {
    color: #6ebbc5;
  }
  .cloud-icon {
    color: #6ebbc5 !important;
    font-size: 30px !important;
  }

  .upload-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .margin {
    margin: 20px 0 10px 0;
  }
  .other-field{
    margin: 12px 0;
    height: 48px;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector{
    height: 48px;
  }
`;
