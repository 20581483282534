import { ApolloClient, from, HttpLink, InMemoryCache, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { varClient } from "../modules/common/defaults";

import { CURRENT_USER } from "../utlis/Me";
// import { WebSocketLink } from "@apollo/client/link/ws";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { notification } from "antd";
// import { endpoint, prodEndpoint } from "../config";
// import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import fetch from "isomorphic-unfetch";
import {
  endpoint,
  endpointSub,
  prodEndpoint,
  prodEndpointSub,
  stageEndpoint,
  stageEndpointSub,
} from "./config";
import { varAuth } from "../modules/auth/defaults";
import { varDomain } from "../modules/common/defaults";
import { setDefaults } from "./defaults";
import { createClient as createWSClient } from "graphql-ws";

const uri =
  process.env.NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV_STAGING === "dev"
    ? endpoint
    : process.env.REACT_APP_NODE_ENV_STAGING === "staging"
    ? stageEndpoint
    : prodEndpoint;
const subUri =
  process.env.NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV_STAGING === "dev"
    ? endpointSub
    : process.env.REACT_APP_NODE_ENV_STAGING === "staging"
    ? stageEndpointSub
    : prodEndpointSub;
console.log(
  "🚀 ~ file: index.js ~ line 28 ~ process.env.REACT_APP_NODE_ENV_STAGING",
  process.env.REACT_APP_NODE_ENV_STAGING,
);

const httpLink = new HttpLink({
  credentials: "include",
  fetch, // Switches between unfetch & node-fetch for client & server.

  uri,
});
const wsLink = new GraphQLWsLink(
  createWSClient({
    url: subUri,
    lazy: true,
    reconnect: true,
    timeout: 30000,
    connectionParams: () => {
      const { token } = varAuth();

      return {
        shop: varDomain() || undefined,
        lang: "ar",
        headers: {
          authorization: token ? "Bearer " + token : "",
        },
      };
    },
  }),
);

const contextLink = setContext(async () => {
  const { token } = varAuth();

  const headers = { lang: "ar", authorization: token ? "Bearer " + token : "" };
  if (varDomain()) headers.shop = varDomain();
  console.log("🚀 ~ file: index.js ~ line 69 ~ contextLink ~ varDomain()", varDomain());
  return {
    headers,
  };
});
const splitLink = split(
  //only create the split in the browser
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  httpLink,
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    console.log("TCL: createClient -> graphQLErrors", graphQLErrors);
    graphQLErrors.map(async (error) => {
      console.log("🚀 ~ file: index.js ~ line 82 ~ graphQLErrors.map ~ error", error);

      const client = varClient();
      const { message: msg } = error;
      notification.error({
        message: msg,
      });
      if (msg.includes("Not Auth")) {
        const { data } = await client.query({ query: CURRENT_USER });
        console.log("🚀 ~ file: index.js ~ line 94 ~ graphQLErrors.map ~ data", data);
        if (data.me?.role === "CUSTOMER") window.location = "/signin";
      }
    });
  }
  if (networkError) {
    console.log("TCL: createClient -> networkError", networkError);

    // message.error(networkError.message);
    notification.error({
      message: networkError.message,
    });
  }
});
let link = from([contextLink, errorLink, splitLink]);

export const createClient = () => {
  setDefaults();
  return new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          // fields: {
          //   rooms: {
          //     keyArgs: false,
          //     merge(existing, incoming, { args }) {
          //       if (args.skip === 0) return incoming;
          //       if (!incoming) return existing;
          //       else if (!existing) return incoming;
          //       return {
          //         ...existing,
          //         ...incoming,
          //         nodes: [...existing.nodes, ...incoming.nodes],
          //       };
          //     },
          //   },
          //   messages: {
          //     keyArgs: false,
          //     merge(existing, incoming, { args }) {
          //       console.log("🚀 ~ file: index.js ~ line 113 ~ merge ~ args", args);
          //       console.log(
          //         "🚀 ~ file: index.js ~ line 114 ~ merge ~ existing, incoming",
          //         existing,
          //         incoming,
          //       );
          //       if (args.skip === 0) return incoming;
          //       if (!incoming) return existing;
          //       else if (!existing) return incoming;
          //       return {
          //         ...existing,
          //         ...incoming,
          //         nodes: [...incoming.nodes, ...existing.nodes],
          //       };
          //     },
          //   },
          // },
        },
      },
    }),
    // credentials: "same-origin",
    defaultOptions: {
      query: {
        fetchPolicy: "network-only",
      },
      watchQuery: {
        fetchPolicy: "network-only",
      },
    },
    link,
  });
};

export const createStoreClientApolloClient = () => {
  // const endpointClient = `http://localhost:4000/graphqlClient`;

  const endpointClient = "https://b7r-back-store-client.herokuapp.com/graphqlClient";

  //  const endpointCliet = "https://api.b7r.store/store-client/graphqlClient";

  const prodEndpointClient = "https://api.b7r.store/store-client/graphqlClient";

  const httpLink = new HttpLink({
    credentials: "include",
    fetch, // Switches between unfetch & node-fetch for client & server.

    uri:
      process.env.NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV_STAGING === "dev"
        ? endpointClient
        : prodEndpointClient,
  });
  let link = from([contextLink, errorLink, httpLink]);

  return new ApolloClient({
    cache: new InMemoryCache(),

    link,
  });
};
