import { Button, Spin, Typography } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { from, gql, useMutation, useQuery } from "@apollo/client";
import { CURRENT_USER } from "../../../utlis/Me";
import { varDomain } from "../../../modules/common/defaults";

const { Text } = Typography;
const DIGITAL_AUTH = gql`
  mutation DIGITAL_AUTH {
    digitalAuth {
      id
      stores {
        id
        domain
      }
    }
  }
`;
const DigitalAuth = () => {
  const [auth] = useMutation(DIGITAL_AUTH, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: CURRENT_USER }],
  });
  const router = useHistory();

  useEffect(() => {
    (async () => {
      auth().then(({ data }) => {
        console.log("🚀 ~ file: index.tsx ~ line 33 ~ auth ~ data", data);
        if (data?.digitalAuth?.stores?.length == 1) {
          varDomain(data?.digitalAuth?.stores[0].domain);
          router.push("/");
        } else if (data?.digitalAuth?.stores?.length) {
          router.push("/shops");
        } else {
          router.push("/createStore");
        }
      });
      //   router.push("/shops");
    })();
  }, []);
  return <Spin />;
};

const Container = styled.div``;

export default DigitalAuth;
