import { Button, Space, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import error404 from "../../assets/imgs/404.svg";
import { Breakpoints } from "../../constants";
import MyImage from "../../components/common/image";
import { CURRENT_USER } from "../../utlis/Me";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";

const { Text } = Typography;

const CommonError = () => {
  const { t } = useTranslation();

  const { data } = useQuery(CURRENT_USER, {
    fetchPolicy: "cache-first",
  });
  const me = data?.me;
  const history = useHistory();
  console.log("🚀 ~ file: index.tsx ~ line 25 ~ CommonError ~ history.location", history.location);
  const mainRoutes = [
    "signin",
    "reset",
    "validatecode",
    "forgetpassword",
    "signin",
    "signup",
    "beVendor",
  ];
  const currentLocation = history.location.pathname;
  const isNotValidUrl = () => {
    console.log("🚀 ~ file: index.tsx ~ line 35 ~ CommonError ~ currentLocation", currentLocation);
    const isValid = mainRoutes.some(
      (route) => `/${route}` === currentLocation || currentLocation.includes(`/${route}/`),
    );
    return isValid;
  };

  console.log("🚀 ~ file: index.tsx ~ line 44 ~ CommonError ~ isNotValidUrl()", isNotValidUrl());
  if (!me && isNotValidUrl()) return null;

  return (
    <Container>
      <div className="row-container">
        <Space direction="vertical" align="center" size={50} style={{ width: "100%" }}>
          <MyImage width={"auto"} src={error404} className="error-img" />
          <div className="title">
            <Text className="text">{t("common.errorText")}</Text>
            <Text className="info">{t("common.errorInfo")}</Text>
          </div>
          <Link to="/">
            <Button type="primary"> {t("common.backToHome")}</Button>
          </Link>
        </Space>
      </div>
    </Container>
  );
};

const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  .error-img {
    width: 444px;
    @media (max-width: ${Breakpoints.sm}) {
      width: 251px;
    }
  }
  .title {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;
    width: 515px;
    @media (max-width: ${Breakpoints.sm}) {
      width: auto;
    }
    .text {
      font-size: 36px;
      font-weight: 700;
      color: ${(props) => props.theme.colors.black2};
      @media (max-width: ${Breakpoints.sm}) {
        font-size: 24px;
      }
    }
    .info {
      font-size: 20px;
      font-weight: 600;
      color: ${(props) => props.theme.colors.Gray1};
      @media (max-width: ${Breakpoints.sm}) {
        font-size: 16px;
      }
    }
  }
  .ant-btn {
    height: 48px;
    width: 230px;
    font-size: 16px;
    font-weight: 600;
  }
`;

export default CommonError;
