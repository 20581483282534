import { Button } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import Headroom from "react-headroom";
import { useResponsive } from "../../../../lib/useResponsive";

const AddEditStickyContainer = ({ children }: any) => {
  const [pinned, setPinned] = useState(false);
  const { xxl, md, sm, xs, lg } = useResponsive();

  return (
    <div>
      <Headroom
        onPin={() => setPinned(true)}
        onUnfix={() => setPinned(false)}
        style={{
          zIndex: 99,
          background: pinned && "#fff",
          width: lg && (pinned ? "calc(100vw - 210px)" : "100%"),
          padding: 10,
        }}
        pinStart={150}>
        {children}
      </Headroom>
    </div>
  );
};

export default AddEditStickyContainer;

const AddEditContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 999999;

  /* position: fixed;
    top: 0;
    margin: 0 -35px 0 0;
    z-index: 99;
    background: #fff;
    box-shadow: 0px 0px 8px 0px #33333314;
    padding: 10px;
    width: calc(100vw - 225px); */
`;
