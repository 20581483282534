import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
const BackButton = ({ text = undefined, url = undefined }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  return (
    <Container>
    <Row>
      <Col span={24} style={{ marginBottom: "20px" }}>
        <Button
          icon={i18n.language == "ar" ? <RightOutlined /> : <LeftOutlined />}
          onClick={() => { 
            if(url){
              history.push(url)
            } else {
              history.goBack()
            }
          }}
          className="back"
          type="link"
          style={{ width: "fit-content", padding: "0 0 0 10px" }}>
          {text || t("theme.back")}
        </Button>
      </Col>
    </Row>
    </Container>
  );
};

export default BackButton;

const Container = styled.div`
  .back {
    font-size: 18px;
    font-weight: 600;
    color: ${(props) => props.theme.customColors.primary};
  }
`;
