import { Badge, Divider, Drawer, Tabs, Typography } from "antd";
import React, { useState } from "react";
import MyImage from "../../../common/image";
import notificationImg from "../../../../assets/imgs/notification.svg";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import NotificationsTabComponent from "./notificationTabs/notificationTab";
import FeatursTabComponent from "./notificationTabs/featursTab";
import { useResponsive } from "../../../../lib/useResponsive";
import { Breakpoints } from "../../../../constants";
import { gql, useQuery } from "@apollo/client";
import { useVarAuth } from "../../../../modules/auth/defaults";
import { CURRENT_USER } from "../../../../utlis/Me";

export const B7R_NOTIFICATIONS = gql`
  query Query(
    $orderBy: B7rNotificationOrderByWithRelationInput
    $where: B7rNotificationWhereInput
  ) {
    b7rNotifications(orderBy: $orderBy, where: $where) {
      count
    }
  }
`;

const { TabPane } = Tabs;
const { Text } = Typography;

const Notifications = () => {
  const [visible, setVisible] = useState(false);
  const {
    data: { me },
  } = useQuery(CURRENT_USER, { fetchPolicy: "cache-first" });
  const { data, refetch } = useQuery(B7R_NOTIFICATIONS, {
    variables: {
      where: { usersSeen: { none: { id: { equals: me.id } } } },
      orderBy: {
        createdAt: "asc",
      },
    },
  });
  const { t } = useTranslation();
  const { md, sm, xs } = useResponsive();
  const currNotiNum = data?.b7rNotifications?.count;
  console.log("🚀 ~ file: index.tsx ~ line 39 ~ Notifications ~ currNotiNum", currNotiNum);
  return (
    <Container>
      <Badge count={data?.b7rNotifications?.count} showZero size="small">
        <span className="notification-icon" onClick={() => setVisible(true)}>
          <MyImage src={notificationImg} width={15} />
        </span>
      </Badge>
      <NotificationsDrawer
        placement="left"
        bodyStyle={{ padding: "10px 20px" }}
        onClose={() => setVisible(false)}
        visible={visible}
        width={(sm && 410) || (xs && 325) || 410}>
        <Tabs centered type="card">
          <TabPane tab={t("home.notifications")} key="1">
            <Divider style={{ margin: 0 }} />
            <NotificationsTabComponent refetchNotSeen={refetch} notSeenNum={currNotiNum} />
          </TabPane>
          <TabPane tab={t("home.featursUpdates")} key="2">
            <FeatursTabComponent />
          </TabPane>
        </Tabs>
      </NotificationsDrawer>
    </Container>
  );
};

export default Notifications;

const Container = styled.div`
  .notification-icon {
    cursor: pointer;
  }
`;

const NotificationsDrawer = styled(Drawer)`
  .ant-drawer-header-title {
    justify-content: end;
  }
  .ant-drawer-close {
    transition: transform 0.5s;
  }
  .ant-drawer-close:hover {
    transform: rotate(0.5turn);
  }
  .ant-tabs-tab-btn {
    color: ${(props) => props.theme.colors.Gray6};
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    padding: 10px 50px;
    @media (max-width: ${Breakpoints.sm}) {
      padding: 7px 30px;
    }
    @media (max-width: ${Breakpoints.xs}) {
      padding: 7px 25px;
    }
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
    background-color: ${(props) => props.theme.customColors.lightBlue};
    border-radius: 6px;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 6px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => props.theme.customColors.lightGray};
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
    display: none;
  }
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    display: none;
  }
`;
