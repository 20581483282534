import React, { useState, useEffect } from "react";
import { GetNewPageData } from "../../../../lib/GetNewPageData";
import {
  Table,
  Button,
  Space,
  Popconfirm,
  Modal,
  Input,
  Badge,
  Spin,
  Col,
  Row,
  Typography,
} from "antd";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import { EditOutlined, DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import EditModal from "./edit";
import makeSlug from "slug-arabic"; // by B7r :)
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Container } from "../../categories/style";
import CustomSearch from "../../../../components/common/customSearch";
import EmptyPage from "../../../../components/common/empty";
import attributesEmptyPage from "../../../../assets/imgs/attributesEmptyPage.svg";
import Loading from "../../../../components/common/Loading";
import { useHistory, useLocation } from "react-router-dom";

export const OPTIONS = gql`
  query OPTIONS($skip: Int, $filter: AttributeWhereInput) {
    attributes(skip: $skip, take: 100, orderBy: { id: desc }, where: $filter) {
      count
      nodes {
        id
        name
        mName {
          id
          ar
          en
        }
        type
        slug
        optionValue {
          id
          value
          name
        }
      }
    }
  }
`;

export const DELETE_ATTR = gql`
  mutation DELETE_ATTR($where: AttributeWhereUniqueInput!) {
    deleteOneAttribute(where: $where) {
      id
    }
  }
`;
export const DELETE_ATTR_VALUE = gql`
  mutation DELETE_ATTR_VALUE($where: OptionValueWhereUniqueInput!) {
    deleteOneOptionValue(where: $where) {
      id
    }
  }
`;
export const OPTIONS_VALUES = gql`
  query OPTIONS_VALUES($attributeId: Int) {
    optionValues(take: 100, where: { attribute: { id: { equals: $attributeId } } }) {
      id
      name
      slug
      value
      mName {
        id
        ar
        en
      }
    }
  }
`;

const ExpandedRowRender = ({ attr }) => {
  const [editableRecord, setEditableRecord] = useState(null);
  const [visible, setVisible] = useState(null);
  const variables = { attributeId: attr.id };
  const { data, loading } = useQuery(OPTIONS_VALUES, {
    variables,
  });

  const { t } = useTranslation();

  const [removeValue] = useMutation(DELETE_ATTR_VALUE, {
    update(cache, { data: { deleteOneOptionValue } }) {
      const { optionValues } = cache.readQuery({
        query: OPTIONS_VALUES,
        variables,
      });
      cache.writeQuery({
        query: OPTIONS_VALUES,
        variables,
        data: {
          optionValues: optionValues.filter(({ id }) => deleteOneOptionValue.id !== id),
        },
      });
    },
  });

  const handleCancel = (e) => {
    setVisible(false);
    setEditableRecord(null);
  };

  if (loading) return <Spin />;
  const columns = [
    { title: t("products.valueName"), dataIndex: "name", key: "name" },
    { title: t("products.slug"), dataIndex: "slug", key: "slug" },
    {
      // align: "end",
      title: t("products.action"),

      render: (record) => (
        <Space style={{ marginRight: 20 }}>
          <EditOutlined
            onClick={() => {
              setEditableRecord({
                ...record,
                name: { ar: record.mName.ar, en: record.mName.en },
              });
              setVisible(true);
            }}
          />
          <Popconfirm
            title={t("products.deleteAttribute")}
            onConfirm={() => removeValue({ variables: { where: { id: record.id } } })}
            okText={t("posts.yes")}
            cancelText={t("posts.no")}>
            <DeleteOutlined style={{ color: "red" }} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Button onClick={() => setVisible(true)} type="primary" icon={<PlusCircleOutlined />}>
        {t("products.addValue")}
      </Button>
      <Table columns={columns} dataSource={data?.optionValues || []} pagination={false} />
      <EditModal
        handleCancel={handleCancel}
        editableRecord={editableRecord}
        visible={visible}
        attr={attr}
        variables={variables}
      />
    </>
  );
};

const Attributes = () => {
  const [pagination, setPagination] = useState({ current: 1 });
  const [filterSearch] = useState({});
  const [editableRecord, setEditableRecord] = useState(null);
  const [visible, setVisible] = useState(null);
  const route = useHistory();
  const onSearch = (value) => {
    setPagination({ current: 1 });
    setSearchKeyword(value);
    if (value.trim() !== "") {
      route.push({
        pathname: "/attributes",
        search: `?search=${value}`,
      });
    }
  };

  let querySearch = useLocation().search;
  querySearch = querySearch.slice(8);
  console.log(querySearch);

  const [searchKeyword, setSearchKeyword] = useState(querySearch || "");

  const { t } = useTranslation();

  const variables = {
    skip: (pagination.current - 1) * 10,
    filter: {
      ...filterSearch,
      OR: [
        {
          name: {
            OR: [
              { ar: { contains: searchKeyword, mode: "insensitive" } },
              { en: { contains: searchKeyword, mode: "insensitive" } },
            ],
          },
        },
        {
          optionValue: {
            some: {
              name: {
                OR: [
                  {
                    ar: {
                      contains: searchKeyword,
                      mode: "insensitive",
                    },
                  },
                  {
                    en: {
                      contains: searchKeyword,
                      mode: "insensitive",
                    },
                  },
                ],
              },
            },
          },
        },
      ],
    },
  };

  const [removeAttr] = useMutation(DELETE_ATTR, {
    onCompleted: () => {
      GetNewPageData(pagination, setPagination);
    },
    update(cache, { data: { deleteOneAttribute } }) {
      const { attributes } = cache.readQuery({
        query: OPTIONS,
        variables: variables,
      });
      cache.writeQuery({
        query: OPTIONS,
        variables: variables,
        data: {
          attributes: {
            count: attributes.count - 1,
            nodes: attributes.nodes.filter(({ id }) => deleteOneAttribute.id !== id),
          },
        },
      });
    },
  });

  const { data, loading } = useQuery(OPTIONS, {
    fetchPolicy: "cache-and-network",
    variables: {
      ...variables,
    },
  });
  console.log("DataTable -> data", data);
  useEffect(() => {
    setPagination({
      ...pagination,
      current:
        pagination.current - 1 == data?.attributes?.count / 10 && pagination.current - 1 !== 0
          ? pagination.current - 1
          : pagination.current,
      total: data?.attributes?.count,
    });
  }, [data]);

  const handleTableChange = (pagination) => {
    setPagination({ ...pagination, current: pagination.current });
  };

  const handleCancel = (e) => {
    setVisible(false);
    setEditableRecord(null);
  };

  const columns = () => {
    const cols = [
      {
        title: t("products.Name"),
        dataIndex: `name`,
      },
      {
        title: t("products.type"),
        dataIndex: `type`,
      },
      {
        title: t("products.slug"),
        dataIndex: `slug`,
      },
      // {
      //   title: `Product Counts`,
      //   render: (record) => record.products.count,
      // },
      {
        // align: "end",
        title: t("products.action"),

        render: (record) => (
          <Space style={{ marginRight: 20 }}>
            <EditOutlined
              onClick={() => {
                setEditableRecord({
                  ...record,
                  name: { ar: record.mName.ar, en: record.mName.en },
                });
                setVisible(true);
              }}
            />
            <Popconfirm
              title={t("products.deleteAttribute")}
              onConfirm={() =>
                removeAttr({
                  variables: { where: { id: record.id } },
                })
              }
              okText={t("posts.yes")}
              cancelText={t("posts.no")}>
              <DeleteOutlined style={{ color: "red" }} />
            </Popconfirm>
          </Space>
        ),
      },
    ];

    return cols;
  };
  if (loading) return <Loading />;
  if (data?.attributes.count <= 0 && searchKeyword == "") {
    return (
      <EmptyPageContainer>
        <EmptyPage
          buttonLink="/discounts/new?type=code"
          description={t("products.noAttributes")}
          image={attributesEmptyPage}
          // disableButton
        />
        <Button
          style={{ marginTop: window.innerHeight > 750 ? "-80px" : "-20px" }}
          onClick={() => setVisible(true)}
          type="primary"
          icon={<PlusCircleOutlined />}>
          {t("products.addAttribute")}
        </Button>
        <EditModal
          handleCancel={handleCancel}
          editableRecord={editableRecord}
          visible={visible}
          isAttr
          variables={variables}
        />
      </EmptyPageContainer>
    );
  }

  return (
    <Container>
      <Row justify="space-between">
        <Col>
          <Typography.Title level={3}>{t("products.attributes")}</Typography.Title>
        </Col>
        <Col>
          <Button onClick={() => setVisible(true)} type="primary" icon={<PlusCircleOutlined />}>
            {t("products.addAttribute")}
          </Button>
        </Col>
      </Row>
      <Row style={{ margin: "20px 0 30px 0" }}>
        <Col xs={24} lg={12}>
          <SearchContainer>
            <CustomSearch
              allowClear
              placeholder={t("products.findAttribute")}
              onSearch={onSearch}
              defaultValue={searchKeyword}
            />
          </SearchContainer>
        </Col>
      </Row>

      <Table
        scroll={{ x: 1000 }}
        loading={loading}
        rowKey={(record) => record.id}
        columns={columns()}
        size="small"
        bordered
        dataSource={data?.attributes.nodes}
        onChange={handleTableChange}
        pagination={{ ...pagination, hideOnSinglePage: true, position: ["bottomCenter"] }}
        expandable={{
          expandedRowRender: (attr) => <ExpandedRowRender attr={attr} />,
          defaultExpandAllRows: true,
        }}
      />
      <EditModal
        handleCancel={handleCancel}
        editableRecord={editableRecord}
        visible={visible}
        isAttr
        variables={variables}
      />
    </Container>
  );
};

const SearchContainer = styled.div`
  .ant-input {
    background-color: #fff;
  }

  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button:not(.ant-btn-primary) {
    color: #1985a1;
  }
`;

const EmptyPageContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .ant-btn {
    height: 48px;
  }
`;

export default Attributes;
