import { Col, Divider, Row, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { b7rPlatformUrl } from "../../../constants";

const { Text } = Typography;
const Footer = () => {
  let history = useHistory();
  let {
    i18n: { language },
    t,
  } = useTranslation();
  const fullUrl = `${b7rPlatformUrl + language}/`;
  return (
    <Conatiner>
      <div className="row-container">
        <Divider />
        <Row justify="space-between">
          <Col>
            <Space size="small">
              <Text
                onClick={() => {
                  window.location.href = `${fullUrl}privacy`;
                }}
                className="privacy">
                {t("common.privacyPolicy")}
              </Text>
              <Text
                onClick={() => {
                  window.location.href = `${fullUrl}terms`;
                }}
                className="privacy">
                {t("common.terms")}
              </Text>
            </Space>
          </Col>
          <Col>
            <Text className="copyright">© 2021 B7R</Text>
          </Col>
        </Row>
      </div>
    </Conatiner>
  );
};
const Conatiner = styled.div`
  padding-top: 30px;
  padding-bottom: 40px;
  /* background: ${(props) => props.theme.backgroundColors.lightGray}; */

  .footer {
    width: 100%;
    text-align: center;
    .text {
      font-weight: 400;
      font-size: 16px;
      color: ${(props) => props.theme.colors.Gray2};
    }
    .ant-btn {
      border: 1px solid ${(props) => props.theme.customColors.primary};
      color: ${(props) => props.theme.customColors.primary};
      font-weight: 500;
      border-radius: 4px;
    }
    .ant-btn:hover {
      background: ${(props) => props.theme.customColors.primary};
      color: ${(props) => props.theme.customColors.white};
    }
  }
  .copyright,
  .privacy {
    font-size: 14px;
    font-weight: 400;
    color: ${(props) => props.theme.customColors.Gray2};
    cursor: pointer;
  }
  .ant-divider-horizontal {
    border-top: 1px solid #4f4f4f;
    margin-top: 140px;
  }
`;
export default Footer;
