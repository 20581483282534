import styled from "styled-components";

export const RootContainer = styled.div`
  width: 100%;

  padding: 20px;
  box-shadow: 0 0 10px 0 rgba(136, 152, 170, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 20px;
`;

export const Container = styled.div`
  .ant-input-search > .ant-input-group > .ant-input-affix-wrapper:hover,
  .ant-input-search > .ant-input-group > .ant-input-affix-wrapper-focused,
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused,
  .ant-input-search .ant-input:hover,
  .ant-input-search .ant-input:focus {
    border-color: #d9d9d9;
    box-shadow: unset;
  }
`;
